import React from 'react';
import { withTabItems } from '../common/RoutableTabContainer';
import CurrencyConversionRequestList from './CurrencyConversionRequestList';
import UserProvisionRequestList from './UserProvisionRequestList';
import ActionLogList from '../user/ActionLogList';
import BrokerageCreditList from '../account/BrokerageCreditList';
import ReportList from '../account/ReportList';
import UserSubscriptionList from '../user/UserSubscriptionList';
import MFADeactivationRequestList from './MFADeactivationRequestList';
import withSuspense from '../common/table/Suspention';

const BankAccountList = withSuspense(React.lazy(
  () => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./au/BankAccountList') : import('./uk/BankAccountList')),
));

export default withTabItems(
  [
    {
      label: 'Bank Accounts',
      url: 'bank-accounts',
      comp: BankAccountList,
      permission: ['BAC'],
    },
    {
      label: 'FX Requests',
      url: 'fx-requests',
      comp: CurrencyConversionRequestList,
      permission: ['FXO'],
    },
    {
      label: 'User Provision Requests',
      url: 'user-provision-requests',
      comp: UserProvisionRequestList,
      permission: ['USR', 'ACC'],
    },
    {
      label: 'Action Logs',
      url: 'action-logs',
      comp: ActionLogList,
      permission: ['USR', 'ACC'],
    },
    {
      label: 'Brokerage Credits',
      url: 'brokerage-credits',
      comp: BrokerageCreditList,
      permission: ['ACC'],
    },
    {
      label: 'Reports',
      url: 'reports',
      comp: ReportList,
      permission: ['RPT', 'ACC'],
    },
    {
      label: 'User Subscription',
      url: 'user-subscription',
      comp: UserSubscriptionList,
      permission: ['USR'],
    },
    // UserSubscriptionList
    {
      label: 'MFA Deactivation Request',
      url: 'mfa-deactivation-requests',
      comp: MFADeactivationRequestList,
      permission: ['USR'],
    },
  ],
);
