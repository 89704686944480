/**
 * @flow
 * @relayHash 2f9d4808b50020371fdc2a62c604feea
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type UpdateUserProvisionRequestStatusMutationVariables = {|
  userProvisionRequestId: string,
  status: ApprovableStatus,
|};
export type UpdateUserProvisionRequestStatusMutationResponse = {|
  +updateUserProvisionRequestStatus: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +userProvisionRequest: ?{|
      +id: string,
      +userModel: {|
        +id: string,
        +username: string,
        +email: string,
      |},
      +clientAccount: ?{|
        +id: string,
        +name: ?string,
      |},
      +status: string,
      +approvals: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +approvedBy: {|
              +id: string,
              +username: string,
            |},
            +approvedAt: any,
          |}
        |}>
      |},
      +hasCompleteApprovals: ?boolean,
    |},
  |}
|};
export type UpdateUserProvisionRequestStatusMutation = {|
  variables: UpdateUserProvisionRequestStatusMutationVariables,
  response: UpdateUserProvisionRequestStatusMutationResponse,
|};
*/


/*
mutation UpdateUserProvisionRequestStatusMutation(
  $userProvisionRequestId: ID!
  $status: ApprovableStatus!
) {
  updateUserProvisionRequestStatus(userProvisionRequestId: $userProvisionRequestId, status: $status) {
    errors {
      field
      messages
    }
    userProvisionRequest {
      id
      userModel {
        id
        username
        email
      }
      clientAccount {
        id
        name
      }
      status
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userProvisionRequestId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "ApprovableStatus!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUserProvisionRequestStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "userProvisionRequestId",
        "variableName": "userProvisionRequestId"
      }
    ],
    "concreteType": "UpdateUserProvisionRequestStatus",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userProvisionRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "UserProvisionRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userModel",
            "storageKey": null,
            "args": null,
            "concreteType": "UserModelNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientAccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvals",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approvedAt",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasCompleteApprovals",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserProvisionRequestStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserProvisionRequestStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserProvisionRequestStatusMutation",
    "id": null,
    "text": "mutation UpdateUserProvisionRequestStatusMutation(\n  $userProvisionRequestId: ID!\n  $status: ApprovableStatus!\n) {\n  updateUserProvisionRequestStatus(userProvisionRequestId: $userProvisionRequestId, status: $status) {\n    errors {\n      field\n      messages\n    }\n    userProvisionRequest {\n      id\n      userModel {\n        id\n        username\n        email\n      }\n      clientAccount {\n        id\n        name\n      }\n      status\n      approvals {\n        edges {\n          node {\n            id\n            approvedBy {\n              id\n              username\n            }\n            approvedAt\n          }\n        }\n      }\n      hasCompleteApprovals\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4493b13decb32e92b25ae10f1371d9d3';
module.exports = node;
