import React from 'react';
import TableToggleButtonGroup from '../common/table/TableToggleButtonGroup';


function VerbModifier(props) {
  const {
    resource,
    verb: inVerb,
    onSetVerb,
  } = props;
  return (
    <TableToggleButtonGroup
      groupKey={resource}
      toggleOptions={[
        [null, '∅'],
        ['READ', 'Read'],
        ['MUTATE', 'Mutate'],
      ]}
      initialValue={inVerb}
      onSetValue={(newValue, groupKey) => (onSetVerb && onSetVerb(groupKey, newValue))}
    />
  );
}

export default VerbModifier;
