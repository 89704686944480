/**
 * @flow
 * @relayHash 2eeb603bcb231e1353513ba5e901bc1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VueDetails_min$ref = any;
export type InvestmentTimeFrame = "FROM_1_TO_3" | "FROM_3_TO_5" | "GT_3" | "GT_5" | "LT_1" | "%future added value";
export type PortfolioType = "HALO" | "NONE" | "SHARESIGHT" | "%future added value";
export type SharingStrategy = "DEEP" | "EXTERNAL" | "SELF" | "%future added value";
export type VueTrivialMutationInput = {|
  id: string,
  name: string,
  description?: ?string,
  investmentObjective?: ?string,
  investmentStrategy?: ?string,
  investorProfile?: ?string,
  sharingStrategy?: ?SharingStrategy,
  timeFrame?: ?InvestmentTimeFrame,
  visible?: ?boolean,
  riskFactor?: ?any,
  sharesightPortfolioId?: ?number,
  haloPortfolioId?: ?number,
  portfolioType?: ?PortfolioType,
  clientMutationId?: ?string,
|};
export type VueTrivialUpdateMutationVariables = {|
  input: VueTrivialMutationInput
|};
export type VueTrivialUpdateMutationResponse = {|
  +vueTrivial: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +obj: ?{|
      +$fragmentRefs: VueDetails_min$ref
    |},
  |}
|};
export type VueTrivialUpdateMutation = {|
  variables: VueTrivialUpdateMutationVariables,
  response: VueTrivialUpdateMutationResponse,
|};
*/


/*
mutation VueTrivialUpdateMutation(
  $input: VueTrivialMutationInput!
) {
  vueTrivial(input: $input) {
    errors {
      field
      messages
    }
    obj {
      ...VueDetails_min
      id
    }
  }
}

fragment VueDetails_min on VueNode {
  id
  name
  description
  investmentObjective
  investmentStrategy
  investorProfile
  timeFrame
  sharingStrategy
  portfolioType
  riskFactor
  visible
  published
  createdAt
  updatedAt
  sharesightportfolio {
    id
  }
  haloportfolio {
    id
  }
  vuecontractSet {
    edges {
      node {
        id
        weight
        description
        researchPdfUrl
        contract {
          id
          symbol
          name
          currency
          exchange {
            symbol
            country
            name
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "VueTrivialMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "ErrorType",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "field",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "messages",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = [
  (v3/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueTrivialUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueTrivial",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VueTrivialMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "obj",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "VueDetails_min",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueTrivialUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueTrivial",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "VueTrivialMutationPayload",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "obj",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "investmentObjective",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "investmentStrategy",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "investorProfile",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timeFrame",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "sharingStrategy",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "portfolioType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "riskFactor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "visible",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "published",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "sharesightportfolio",
                "storageKey": null,
                "args": null,
                "concreteType": "SharesightPortfolioNode",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "haloportfolio",
                "storageKey": null,
                "args": null,
                "concreteType": "HaloPortfolioNode",
                "plural": false,
                "selections": (v6/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vuecontractSet",
                "storageKey": null,
                "args": null,
                "concreteType": "VueContractNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueContractNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueContractNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "weight",
                            "args": null,
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "researchPdfUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v7/*: any*/),
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "currency",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exchange",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ExchangeNode",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "country",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v4/*: any*/),
                                  (v3/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VueTrivialUpdateMutation",
    "id": null,
    "text": "mutation VueTrivialUpdateMutation(\n  $input: VueTrivialMutationInput!\n) {\n  vueTrivial(input: $input) {\n    errors {\n      field\n      messages\n    }\n    obj {\n      ...VueDetails_min\n      id\n    }\n  }\n}\n\nfragment VueDetails_min on VueNode {\n  id\n  name\n  description\n  investmentObjective\n  investmentStrategy\n  investorProfile\n  timeFrame\n  sharingStrategy\n  portfolioType\n  riskFactor\n  visible\n  published\n  createdAt\n  updatedAt\n  sharesightportfolio {\n    id\n  }\n  haloportfolio {\n    id\n  }\n  vuecontractSet {\n    edges {\n      node {\n        id\n        weight\n        description\n        researchPdfUrl\n        contract {\n          id\n          symbol\n          name\n          currency\n          exchange {\n            symbol\n            country\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fb35303aa7fc440e35e613383da56c0';
module.exports = node;
