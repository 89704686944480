import React from 'react';
import PropTypes from 'prop-types';
import DataTableWidget from '../DataTableWidget';
import { EnumValues } from '../EnumValue';

export default function EnumListerModifierDataTable(props) {
  const {
    enumType,
    title,
    columnMeta,
    keyFinder,
    keyInitiator,
    dataNodes,
  } = props;
  return (
    <EnumValues
      enumType={enumType}
      render={enumMeta => (
        <DataTableWidget
          xs={12}
          title={title}
          columnMeta={columnMeta}
          dataNodes={Object.keys(enumMeta)
            .map((metaKey) => {
              const found = dataNodes.find((e, i) => keyFinder(e, metaKey, i));
              if (found !== undefined) return found;
              return keyInitiator(metaKey);
            })}
        />
      )}
    />
  );
}

EnumListerModifierDataTable.propTypes = {
  enumType: PropTypes.string.isRequired,
  keyFinder: PropTypes.func.isRequired,
  keyInitiator: PropTypes.func.isRequired,
  columnMeta: PropTypes.array.isRequired,
  dataNodes: PropTypes.array.isRequired,
};

EnumListerModifierDataTable.defaultProps = {
};
