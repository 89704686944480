import * as React from 'react';
import { Link, Typography } from '@material-ui/core';
import { recursiveRefer } from './helpers';
import { fromGlobalId, toLocal } from '../helpers/ID';
import Price from '../components/common/Price';
import DateTime, { DateComp } from '../components/common/DateTimeMoment';
import MoreButton from '../components/common/buttons/MoreButton';
import AccountLink from '../components/common/links/AccountLink';
import EnumValue from '../components/common/EnumValue';
import TableButton from '../components/common/buttons/TableButton';
import NoteButton from '../components/common/notes/NoteButton';

const rightAlignStyle = { style: { textAlign: 'right' } };
const leftAlignStyle = { style: { textAlign: 'left' } };
const centerAlignStyle = { style: { textAlign: 'center' } };

export default class TableFieldMeta {}


export function Gid(props) {
  const { gid } = props;
  return (
    <Typography variant="caption" style={{ fontSize: '0.5rem' }}>
      {gid }
    </Typography>
  );
}

TableFieldMeta.localId = {
  accessor: node => (node.id ? toLocal(node.id) : null),
  Header: 'ID',
  id: 'id',
  width: 70,
  ...rightAlignStyle,
};


TableFieldMeta.processedBy = {
  id: 'processedBy',
  accessor: node => (node.processedBy ? node.processedBy.fullName : null),
  Header: 'Processed By',
  width: 120,
};


TableFieldMeta.moreButtonLink = (url, globalId) => ({
  id: 'moreButtonLink',
  accessor: node => node,
  Cell: row => (
    <MoreButton
      key={`morebutton${row.value.id}`}
      href={`/${url}/${fromGlobalId(globalId(row.value))[1]}`}
    />
  ),
  Header: '',
});

TableFieldMeta.notes = notesOwnerAccessor => ({
  id: 'notes',
  accessor: node => node,
  Cell: row => (
    <NoteButton
      internalDrawer
      notesOwner={notesOwnerAccessor(row)}
      align="center"
    />
  ),
  Header: 'Notes',
  ...centerAlignStyle,
});


TableFieldMeta.rightAlignStyle = rightAlignStyle;
TableFieldMeta.leftAlignStyle = leftAlignStyle;
TableFieldMeta.centerAlignStyle = centerAlignStyle;


export const priceFigureComp = (
  priceVarPath, currencyVarPath = null, decimalPlaces = 4,
) => node => (
  (
    <Price
      currency={currencyVarPath && recursiveRefer(currencyVarPath, node)}
      decimalPlaces={decimalPlaces}
    >
      {recursiveRefer(priceVarPath, node)}
    </Price>
  )
);

TableFieldMeta.priceField = (id, header, priceVarPath, currencyVarPath, decimalPlaces = 4,
  { reducer } = {}) => (
  {
    id,
    accessor: priceFigureComp(priceVarPath, currencyVarPath, decimalPlaces),
    Header: header,
    reducer,
    style: {
      textWrap: 'nowrap',
      textAlign: 'right',
    },
  }
);

TableFieldMeta.quantityField = (varPath, header) => (
  {
    id: (typeof varPath === 'string' || varPath instanceof String) ? varPath : varPath.reduce((a, v) => a + v, ''),
    accessor: node => recursiveRefer(varPath, node),
    Header: header,
    ...rightAlignStyle,
  }
);

TableFieldMeta.account = (accessor, header, accountProps) => (
  {
    id: 'account',
    accessor: node => node,
    Header: header || 'Account',
    Cell: row => <AccountLink account={accessor(row.value)} {...accountProps} />,
  }
);


TableFieldMeta.enum = (enumType, header, accessor) => (
  {
    id: enumType,
    accessor: node => node,
    Header: header,
    Cell: (row) => {
      const accessed = accessor(row.value);

      if (accessed === null) {
        return null;
      }
      if (Array.isArray(accessed)) {
        return (
          <EnumValue
            key={accessed[1]}
            enumValue={accessed[0]}
            enumType={enumType}
          />
        );
      }

      if (typeof accessed === 'object') {
        return (
          <EnumValue
            key={accessed.key}
            enumValue={accessed.value}
            enumType={enumType}
          />
        );
      }

      return <EnumValue enumValue={accessed} enumType={enumType} />;
    },
  }
);


TableFieldMeta.field = (id, header, props = {}) => {
  const { width } = props;
  const { align, style, accessor } = props;
  let newStyle = style || {};
  if (align) newStyle = { textAlign: align, ...newStyle };
  return {
    id,
    Header: header,
    accessor: accessor || id,
    width: width || 100,
    style: newStyle,
  };
};

TableFieldMeta.fieldCell = (id, header, cell, props = {}) => {
  const { width } = props;
  const { align, style, accessor } = props;
  let newStyle = style || {};
  if (align) newStyle = { textAlign: align, ...newStyle };
  return {
    id,
    Header: header,
    accessor: accessor || id,
    width: width || 100,
    style: newStyle,
    Cell: cell,
  };
};

TableFieldMeta.linkField = (accessor, linkPrefix, header) => TableFieldMeta.field(
  (node) => {
    let localId = 0;
    if (typeof accessor == 'number') {
      localId = accessor;
    } else if (typeof accessor == 'string') {
      // eslint-disable-next-line prefer-destructuring
      localId = fromGlobalId(accessor)[1];
    } else if (typeof accessor == 'function') {
      // eslint-disable-next-line prefer-destructuring
      localId = fromGlobalId(accessor(node))[1];
    }
    if (!localId) {
      return null;
    }
    return (
      <Link href={`/${linkPrefix}/${localId}`}>{localId}</Link>
    );
  },
  header,
  { align: 'right' },
);

TableFieldMeta.customCell = (id, header, cellRenderer, props = {}) => {
  const { width } = props;
  const { align, style } = props;
  let newStyle = style || {};
  if (align) newStyle = { textAlign: align, ...newStyle };

  return {
    id,
    accessor: node => node,
    Header: header,
    Cell: cellRenderer,
    width: width || 100,
    style: newStyle,
  };
};

TableFieldMeta.dateTimeField = (id, header, accessor) => TableFieldMeta.field(
  id,
  header,
  {
    align: 'center',
    accessor: node => (
      <DateTime>
        {accessor ? accessor(node) : node[id]}
      </DateTime>
    ),
    width: 180,
  },
);

TableFieldMeta.dateField = (id, header) => TableFieldMeta.field(
  id,
  header,
  {
    align: 'center',
    accessor: node => <DateComp>{node[id]}</DateComp>,
    width: 100,
  },
);

TableFieldMeta.createdByField = (id, header) => TableFieldMeta.field(
  id,
  header,
  {
    align: 'center',
    accessor: node => (id in node && node[id].fullName),
    width: 100,
  },
);

TableFieldMeta.processedAt = TableFieldMeta.dateTimeField('processedAt', 'Processed At');
TableFieldMeta.processedAt = TableFieldMeta.dateTimeField('processedAt', 'Processed At');
TableFieldMeta.createdAt = TableFieldMeta.dateTimeField('createdAt', 'Created At');
TableFieldMeta.createdBy = TableFieldMeta.createdByField('createdBy', 'Created By');
TableFieldMeta.updatedAt = TableFieldMeta.dateTimeField('updatedAt', 'Updated At');
TableFieldMeta.approvedAt = TableFieldMeta.dateTimeField('approvedAt', 'Approved At');


TableFieldMeta.boolViewer = fieldVarPath => (node) => {
  if (!fieldVarPath) {
    return '';
  }
  const displayValue = recursiveRefer(fieldVarPath, node);
  if (displayValue === true) {
    return 'True';
  }

  if (displayValue === false) {
    return 'False';
  }

  return '';
};

TableFieldMeta.selectItem = onSelectItems => ({
  Header: '',
  id: 'action',
  accessor: node => node,
  Cell: (row) => {
    if (onSelectItems) {
      return (
        <TableButton
          key={row.value.id}
          color="primary"
          onClick={() => onSelectItems([row.value])}
        >
          Select
        </TableButton>
      );
    }
    return null;
  },
});
