/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountStatus = "ACCOUNT_APPROVED" | "ACCOUNT_CLOSED" | "ACCOUNT_CLOSING" | "ACCOUNT_CONTINUED_ISA" | "ACCOUNT_DECEASED" | "ACCOUNT_NOT_INITIATED" | "ACCOUNT_PENDING" | "ACCOUNT_SUBMITTED" | "ACCOUNT_SUSPENDED" | "ACCOUNT_TRADING_SUSPENDED" | "ACCOUNT_VOIDED_ISA" | "%future added value";
export type AccountType = "FISA" | "GIACorporate" | "GIAIndividual" | "GIAJoint" | "GIATrust" | "JISA" | "SIPP" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountList_viewer$ref: FragmentReference;
declare export opaque type AccountList_viewer$fragmentType: AccountList_viewer$ref;
export type AccountList_viewer = {|
  +clientAccounts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +referenceNumber: string,
        +accountStatus: AccountStatus,
        +accountType: ?AccountType,
        +createdAt: any,
        +updatedAt: any,
        +userModels: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +username: string,
            |}
          |}>
        |},
        +tenant: {|
          +id: string,
          +name: string,
        |},
        +accountPtr: {|
          +id: string,
          +actualCashBase: ?number,
          +availableCashBase: ?number,
          +stockValueBase: ?number,
          +actualCashBalances: ?$ReadOnlyArray<?{|
            +currency: ?Currency,
            +balance: ?number,
          |}>,
          +availableCashBalances: ?$ReadOnlyArray<?{|
            +currency: ?Currency,
            +balance: ?number,
          |}>,
          +holdings: ?$ReadOnlyArray<?{|
            +portfolio: {|
              +id: string,
              +trackingVue: ?{|
                +name: string
              |},
            |},
            +contract: {|
              +id: string,
              +symbol: string,
              +name: ?string,
            |},
            +quantity: number,
          |}>,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: AccountList_viewer$ref,
|};
export type AccountList_viewer$data = AccountList_viewer;
export type AccountList_viewer$key = {
  +$data?: AccountList_viewer$data,
  +$fragmentRefs: AccountList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "AccountList_viewer",
  "type": "Query",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "clientAccounts"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "tenantId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "adviserId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[AccountStatus]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "clientAccounts",
      "name": "__Accounts_clientAccounts_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountType",
          "variableName": "accountType"
        },
        {
          "kind": "Variable",
          "name": "adviser",
          "variableName": "adviserId"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "statusInV2Typed",
          "variableName": "statusIn"
        },
        {
          "kind": "Variable",
          "name": "tenant",
          "variableName": "tenantId"
        }
      ],
      "concreteType": "ClientAccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ClientAccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ClientAccountNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "referenceNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountStatus",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "userModels",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserModelNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "UserModelNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "UserModelNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "username",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tenant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "accountPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "actualCashBase",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "availableCashBase",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "stockValueBase",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "actualCashBalances",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MultiCurrencyNode",
                      "plural": true,
                      "selections": (v2/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "availableCashBalances",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "MultiCurrencyNode",
                      "plural": true,
                      "selections": (v2/*: any*/)
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "holdings",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "HoldingBalanceNode",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "portfolio",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PortfolioNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "trackingVue",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "VueNode",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ]
                            }
                          ]
                        },
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "contract",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ContractNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "symbol",
                              "args": null,
                              "storageKey": null
                            },
                            (v1/*: any*/)
                          ]
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "quantity",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '29a4b95acffaeee272e39f6cae9edad7';
module.exports = node;
