import { graphql } from 'react-relay';

const updateVueContractMutation = graphql`
  mutation UpdateVueContractMutation (
    $vueContractId: ID!
    $weight: Decimal
    $description: String
    $researchPdf: Upload
  ) {
    updateVueContract(
      vueContractId: $vueContractId
      description: $description
      weight: $weight
      researchPdf: $researchPdf
    ) {
      
      vueContract {
        id
        weight
        description
        researchPdfUrl
      }
    }
  }
`;

export default updateVueContractMutation;
