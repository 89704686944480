/**
 * @flow
 * @relayHash 9a721d98d55edf6aacc87558939fb887
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type CreateRangeReconciliationMutationVariables = {|
  fromDate: any,
  toDate: any,
|};
export type CreateRangeReconciliationMutationResponse = {|
  +createRangeReconciliation: ?{|
    +rangeReconciliation: ?{|
      +createdAt: any,
      +updatedAt: any,
      +excelFile: ?string,
      +excelFileName: ?string,
      +excelFileUrl: ?string,
      +fromDate: ?any,
      +toDate: ?any,
      +ibHash: ?string,
      +mvHash: ?string,
      +noOfDays: ?number,
      +ibrangereconciliationendbalanceSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +quantity: number,
            +currency: Currency,
          |}
        |}>
      |},
      +ibrangereconciliationdeltaSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +quantity: number,
            +currency: Currency,
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateRangeReconciliationMutation = {|
  variables: CreateRangeReconciliationMutationVariables,
  response: CreateRangeReconciliationMutationResponse,
|};
*/


/*
mutation CreateRangeReconciliationMutation(
  $fromDate: Date!
  $toDate: Date!
) {
  createRangeReconciliation(fromDate: $fromDate, toDate: $toDate) {
    rangeReconciliation {
      createdAt
      updatedAt
      excelFile
      excelFileName
      excelFileUrl
      fromDate
      toDate
      ibHash
      mvHash
      noOfDays
      ibrangereconciliationendbalanceSet {
        edges {
          node {
            id
            quantity
            currency
          }
        }
      }
      ibrangereconciliationdeltaSet {
        edges {
          node {
            id
            quantity
            currency
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "fromDate",
    "type": "Date!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "toDate",
    "type": "Date!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fromDate",
    "variableName": "fromDate"
  },
  {
    "kind": "Variable",
    "name": "toDate",
    "variableName": "toDate"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "excelFile",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "excelFileName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "excelFileUrl",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fromDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "toDate",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibHash",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mvHash",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "noOfDays",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v13 = [
  (v12/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  }
],
v14 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ibrangereconciliationendbalanceSet",
  "storageKey": null,
  "args": null,
  "concreteType": "IBRangeReconciliationEndBalanceNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "IBRangeReconciliationEndBalanceNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "IBRangeReconciliationEndBalanceNode",
          "plural": false,
          "selections": (v13/*: any*/)
        }
      ]
    }
  ]
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "ibrangereconciliationdeltaSet",
  "storageKey": null,
  "args": null,
  "concreteType": "IBRangeReconciliationDeltaNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "IBRangeReconciliationDeltaNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "IBRangeReconciliationDeltaNode",
          "plural": false,
          "selections": (v13/*: any*/)
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateRangeReconciliationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRangeReconciliation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRangeReconciliation",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rangeReconciliation",
            "storageKey": null,
            "args": null,
            "concreteType": "IBRangeReconciliationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateRangeReconciliationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createRangeReconciliation",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateRangeReconciliation",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rangeReconciliation",
            "storageKey": null,
            "args": null,
            "concreteType": "IBRangeReconciliationNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v12/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateRangeReconciliationMutation",
    "id": null,
    "text": "mutation CreateRangeReconciliationMutation(\n  $fromDate: Date!\n  $toDate: Date!\n) {\n  createRangeReconciliation(fromDate: $fromDate, toDate: $toDate) {\n    rangeReconciliation {\n      createdAt\n      updatedAt\n      excelFile\n      excelFileName\n      excelFileUrl\n      fromDate\n      toDate\n      ibHash\n      mvHash\n      noOfDays\n      ibrangereconciliationendbalanceSet {\n        edges {\n          node {\n            id\n            quantity\n            currency\n          }\n        }\n      }\n      ibrangereconciliationdeltaSet {\n        edges {\n          node {\n            id\n            quantity\n            currency\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c45b123b5eaac6fde97df9932cc8dd5e';
module.exports = node;
