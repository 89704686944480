/**
 * @flow
 * @relayHash 975b1b8642382393d5d40b78cc2e0f51
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TestVueCommunicationMutationVariables = {|
  communicationId: string
|};
export type TestVueCommunicationMutationResponse = {|
  +testVueCommunication: ?{|
    +vueCommunication: ?{|
      +id: string,
      +published: boolean,
      +publishedAt: ?any,
      +message: ?string,
      +heading: string,
    |},
    +message: ?string,
  |}
|};
export type TestVueCommunicationMutation = {|
  variables: TestVueCommunicationMutationVariables,
  response: TestVueCommunicationMutationResponse,
|};
*/


/*
mutation TestVueCommunicationMutation(
  $communicationId: ID!
) {
  testVueCommunication(communicationId: $communicationId) {
    vueCommunication {
      id
      published
      publishedAt
      message
      heading
    }
    message
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "communicationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "message",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "testVueCommunication",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "communicationId",
        "variableName": "communicationId"
      }
    ],
    "concreteType": "TestVueCommunication",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueCommunication",
        "storageKey": null,
        "args": null,
        "concreteType": "VueCommunicationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "published",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "publishedAt",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "heading",
            "args": null,
            "storageKey": null
          }
        ]
      },
      (v1/*: any*/)
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TestVueCommunicationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TestVueCommunicationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "TestVueCommunicationMutation",
    "id": null,
    "text": "mutation TestVueCommunicationMutation(\n  $communicationId: ID!\n) {\n  testVueCommunication(communicationId: $communicationId) {\n    vueCommunication {\n      id\n      published\n      publishedAt\n      message\n      heading\n    }\n    message\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '340c9405d7dc7054139e03088e51c115';
module.exports = node;
