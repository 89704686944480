import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import notiStack from '../../common/notiStack';
import FileUpload from '../common/FileUpload';
import { doCommitPromise } from '../../common/commit';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemSubGroupHeading from '../common/drawer/DrawerGridItemSubGroupHeading';
import createVueResearchMutation from '../../mutations/CreateVueResearch';
import { AddButton } from '../common/buttons/BaseButton';

function VueResearchAddForm(props) {
  const {
    vue, onClose, onAddVueResearch,
  } = props;
  const classes = useCommonStyles();
  const [title, setTitle] = React.useState(null);
  const [researchPdf, setResearchPdf] = React.useState(null);

  const doAddVueResearch = () => {
    const uploadables = researchPdf ? {
      map: '{ "0": ["variables.researchPdf"]}',
      0: researchPdf,
    } : null;

    doCommitPromise(
      createVueResearchMutation,
      {
        vueId: vue.id,
        title,
      },
      () => {
        if (onAddVueResearch) onAddVueResearch();
        notiStack.success('Vue research details added');
        onClose();
      },
      () => null,
      () => null,
      uploadables,
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Add Vue Research
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-number"
            label="Title"
            onChange={e => setTitle(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Grid>
        <DrawerGridItemSubGroupHeading heading="Research" />
        <Grid item xs={12} sm={12} md={12}>
          <FileUpload
            onChange={(filesIn) => {
              setResearchPdf(filesIn[0]);
            }}
            singleFile
            displayList
            acceptedMimeTypes={['application/pdf']}
          />
        </Grid>
      </Grid>
      <div className={classes.bottomButtonContainer}>
        <AddButton
          onClickPromise={doAddVueResearch}
          buttonText="Add"
        />
      </div>
    </div>
  );
}

export default VueResearchAddForm;
