export const MVA_AUTH_TOKEN_TAG = 'jwtToken';
export const MVA_LOGGED_IN_USER = 'user';
export const MOMENT_TIME_FORMAT = 'MMM D, YYYY h:mm:ss a';
export const MOMENT_DATE_FORMAT = 'MMM D, YYYY';
export const MOMENT_MONTH_FORMAT = 'MMM YYYY';
export const MOMENT_DATE_FORMAT_GRAPHQL = 'YYYY-MM-DD';
export const ROWS_PER_PAGE = 20;
export const FETCH_TIMEOUT = 10000;

export const COLOR_UNDEFINED = '#d9d9d9';
export const COLOR_MATCHED = '#005a00';
export const COLOR_LIGHT_GREEN = '#00aa00';
export const COLOR_ORANGE = '#faa222';

export const COLOR_FILLED = COLOR_LIGHT_GREEN;
export const COLOR_EXCHANGE_OPEN = COLOR_LIGHT_GREEN;
export const COLOR_EXCHANGE_TO_OPEN = COLOR_ORANGE;

export const COLOR_HALO_DARK = '#435E76';
export const COLOR_HALO_LIGHT = '#55c3ef';

export const orderStatusColorMap = {
  FIL: COLOR_FILLED,
  INM: '#6e6e6e',
  PEX: '#9a8aaa',
  REJ: '#c70202',
  CAN: 'orange',
  POS: '#442273',
  INI: '#2b728c',
  PFL: '#82ab8a',
};

export const orderStatusForeColorMap = {
  INM: 'white',
  PEX: 'white',
  REJ: 'white',
  CAN: 'white',
  FIL: 'white',
  QUE: 'dark-grey',
};

export const CSV_MIME_TYPES = [
  '.csv',
  'text/csv',
  'application/vnd.ms-excel',
  'application/csv',
  'text/x-csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
];

export const muiGridSizes = ['xs', 'sm', 'md', 'lg', 'xl'];
