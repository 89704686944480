import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { VerticalTableWidget } from '../common/VerticalTableWidget';
import { isRecurNull } from '../../common/helpers';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';


function PricingContractDetails(props) {
  const { viewer } = props;
  const contract = isRecurNull(viewer, ['contracts', 'edges', 0, 'node']) || {};
  const latestPrice = isRecurNull(contract,
    ['latestContractFinancialData', 'closingPriceLocal']);
  return (
    <VerticalTableWidget
      xs={12}
      md={5}
      columnMeta={[
        ['Exchange', node => isRecurNull(node, ['exchange', 'symbol'])],
        ['Exchange name', node => isRecurNull(node, ['exchange', 'name'])],
        ['Currency', 'currency'],
        ['Current Market Price', node => latestPrice],
      ]}
      dataNode={contract}
      showHeadings={false}
      title="Contract Details"
    />
  );
}


const query = graphql`
  query PricingContractDetailsQuery(
    $contractId: ID
  )  {
    viewer {
      ...PricingContractDetails_viewer
      @arguments(
        contractId: $contractId
      )
    }
  }
`;

const container = createFragmentContainer(
  PricingContractDetails,
  {
    viewer: graphql`
      fragment PricingContractDetails_viewer on Query
      @argumentDefinitions(
        contractId: {type: ID}
      )
      { 
        contracts(id: $contractId) {
          edges {
            node {
              id
              currency
              symbol
              latestContractFinancialData {
                closingPriceLocal
                closingPriceLocal
              }
              
              exchange {
                name
                symbol
                id
              }
            }
          }
        }
      }
    `,
  },
  query,
);


export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ contractId }) => ({ contractId }),
);
