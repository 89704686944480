/**
 * @flow
 * @relayHash 03bfa7b0ef8b2115f0771efe1e7e1629
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrderProposalList_viewer$ref = any;
export type OrderProposalListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  type?: ?string,
|};
export type OrderProposalListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: OrderProposalList_viewer$ref
  |}
|};
export type OrderProposalListQuery = {|
  variables: OrderProposalListQueryVariables,
  response: OrderProposalListQueryResponse,
|};
*/


/*
query OrderProposalListQuery(
  $first: Int
  $offset: Int
  $type: String
) {
  viewer {
    ...OrderProposalList_viewer_3HzLAO
    id
  }
}

fragment OrderProposalList_viewer_3HzLAO on Query {
  orderProposals(first: $first, offset: $offset, type: $type, orderBy: "-createdAt") {
    edges {
      node {
        id
        type
        createdAt
        createdBy {
          fullName
          id
        }
        stocks {
          symbol
          id
        }
        action
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "type",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "type",
  "variableName": "type"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderProposalListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrderProposalList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderProposalListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orderProposals",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v3/*: any*/)
            ],
            "concreteType": "OrderProposalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderProposalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderProposalNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "stocks",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "action",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrderProposalListQuery",
    "id": null,
    "text": "query OrderProposalListQuery(\n  $first: Int\n  $offset: Int\n  $type: String\n) {\n  viewer {\n    ...OrderProposalList_viewer_3HzLAO\n    id\n  }\n}\n\nfragment OrderProposalList_viewer_3HzLAO on Query {\n  orderProposals(first: $first, offset: $offset, type: $type, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        type\n        createdAt\n        createdBy {\n          fullName\n          id\n        }\n        stocks {\n          symbol\n          id\n        }\n        action\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '705032caed47055ede85365834b1d869';
module.exports = node;
