import React from 'react';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'react-relay';
import { useCommonStyles } from '../common/Styles';
import PositionTransferPurchase from './PositionTransferPurchase';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton, RemoveButton, UpdateButton } from '../common/buttons/BaseButton';
import updatePositionTransfer from '../../mutations/UpdatePositionTransfer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { doCommitPromise } from '../../common/commit';


function PositionTransferUpdateForm(props) {
  const classes = useCommonStyles();
  const { viewer, onClose, onUpdate } = props;
  const transferRequest = viewer.positionTransferRequests.edges[0].node;
  const brokerTransfer = transferRequest.ibTransfer;
  const preFill = transferRequest.positionTransferPurchases.edges.map(
    (purchaseEdge, purchaseIndex) => ({
      id: purchaseEdge.node.id,
      quantity: purchaseEdge.node.quantity,
      purchaseDate: purchaseEdge.node.reportDate,
      index: purchaseIndex,
      currency: purchaseEdge.node.contract.currency,
      costBasis: purchaseEdge.node.costBasis,
      commission: purchaseEdge.node.commission,
      dummy: purchaseEdge.node.dummy,
    }),
  );

  const [purchases, setPurchases] = React.useState(preFill);

  const addPurchase = () => {
    const newPurchase = {
      index: purchases.length,
      quantity: 0,
      purchaseDate: brokerTransfer.reportDate,
      currency: brokerTransfer.currency,
      costBasis: 0,
      commission: 0,
      dummy: false,
    };
    const newPurchases = [...purchases, newPurchase];
    setPurchases(newPurchases);
  };

  const removePurchase = () => {
    purchases.pop();
    const newPurchases = [...purchases];
    setPurchases(newPurchases);
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading="Update Position Transfer Costbasis"
        />
        {
          purchases.map(
            (purchaseItem, index) => (
              <PositionTransferPurchase
                heading={purchases.length > 1 ? `Purchase ${index + 1} Details` : 'Purchase Details'}
                key={`${purchaseItem.contractId}-${purchaseItem.quantity}`}
                purchase={purchaseItem}
                currency={purchaseItem.currency}
                isTransferOut={false}
                updatePurchase={(purchase, newPurchase) => {
                  purchases[purchase.index] = newPurchase;
                }}
              />
            ),
          )
        }

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <AddButton
                onClick={() => addPurchase()}
                buttonText="Purchase"
              />
            </Grid>
            <Grid item>
              <RemoveButton
                disabled={purchases.length === 0}
                onClick={() => removePurchase()}
                buttonText="Purchase"
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <UpdateButton
                onClickPromise={() => doCommitPromise(
                  updatePositionTransfer,
                  {
                    transferInput: {
                      accountId: null,
                      status: 'PENDING',
                      transferId: null,
                      id: transferRequest.id,
                      purchases: Array.from(
                        purchases,
                        purchase => ({
                          portfolioId: null,
                          purchaseDate: purchase.purchaseDate,
                          quantity: purchase.quantity ? Number(purchase.quantity) : 0,
                          costBasis: purchase.costBasis ? Number(purchase.costBasis) : 0,
                          commission: purchase.commission ? Number(purchase.commission) : 0,
                          dummy: purchase.dummy,
                        }),
                      ),
                    },
                  },
                  () => {
                    onUpdate && onUpdate();
                    onClose && onClose();
                  },
                )}
                buttonText="Update"
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </Grid>
    </div>
  );
}

const query = graphql`
  query PositionTransferUpdateFormQuery (
    $transferRequestId: ID!
  ) {
    viewer {
      positionTransferRequests(id: $transferRequestId) {
        edges {
          node {
            id
            createdAt
            updatedAt
            ibTransfer {
              reportDate
              currency
            }
            positionTransferPurchases {
              edges {
                node {
                  id
                  createdAt
                  updatedAt
                  reportDate
                  quantity
                  contract {
                    id
                    name
                    symbol
                    currency
                  }
                  costBasis
                  commission
                  dummy
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createQueryRendererProgress(
  query,
  PositionTransferUpdateForm,
  (viewer, outerProps) => ({
    onClose: outerProps.onClose,
    onUpdate: outerProps.onUpdate,
  }),
  props => ({
    transferRequestId: props.transferRequest.id,
  }),
);
