/**
 * @flow
 * @relayHash b6d13742404376db0779faaa1b1879d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SetInterestPaymentsMutationVariables = {|
  clientAccountId: string,
  enable: boolean,
|};
export type SetInterestPaymentsMutationResponse = {|
  +setInterestPayments: ?{|
    +clientAccount: ?{|
      +id: string,
      +accruesInterest: boolean,
    |}
  |}
|};
export type SetInterestPaymentsMutation = {|
  variables: SetInterestPaymentsMutationVariables,
  response: SetInterestPaymentsMutationResponse,
|};
*/


/*
mutation SetInterestPaymentsMutation(
  $clientAccountId: ID!
  $enable: Boolean!
) {
  setInterestPayments(clientAccountId: $clientAccountId, enable: $enable) {
    clientAccount {
      id
      accruesInterest
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "enable",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setInterestPayments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "enable",
        "variableName": "enable"
      }
    ],
    "concreteType": "SetInterestPayments",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clientAccount",
        "storageKey": null,
        "args": null,
        "concreteType": "ClientAccountNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accruesInterest",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SetInterestPaymentsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SetInterestPaymentsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SetInterestPaymentsMutation",
    "id": null,
    "text": "mutation SetInterestPaymentsMutation(\n  $clientAccountId: ID!\n  $enable: Boolean!\n) {\n  setInterestPayments(clientAccountId: $clientAccountId, enable: $enable) {\n    clientAccount {\n      id\n      accruesInterest\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0eab0a224dcf1f2d038f7a77e0bcf718';
module.exports = node;
