import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableFieldMeta from '../../common/TableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import TableButton from '../common/buttons/TableButton';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import {
  AdviserRoleAddForm,
  AdviserRoleDrawerContainer,
  AdviserRoleModifyForm,
} from './AdviserRoleForm';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';

function AdviserRoleList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();

  const [addAdviserRoleOpen, setAddAdviserRoleOpen] = React.useState(false);
  const [updateAdviserRoleOpen, setUpdateAdviserRoleOpen] = React.useState(false);
  const [adviserRoleForUpdate, setAdviserRoleForUpdate] = React.useState(null);

  const columns = [ 
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.createdAt,
    TableFieldMeta.updatedAt,
    TableFieldMeta.customCell('', '', row => (
      <TableButton
        color="primary"
        onClick={() => {
          setAdviserRoleForUpdate(row.value);
          setUpdateAdviserRoleOpen(true);
        }}
      >
      Update
      </TableButton>
    )),
  ];

  return (
    <div className={classes.innerTabRoot}>

      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Drawer
            anchor="right"
            open={addAdviserRoleOpen}
            onClose={() => {
              setAddAdviserRoleOpen(false);
            }}
          >
            <AdviserRoleDrawerContainer>
              <AdviserRoleAddForm
                close={() => {
                  setAddAdviserRoleOpen(false);
                }}
                onAddAdviserRole={() => filter.update()}
              />
            </AdviserRoleDrawerContainer>
          </Drawer>

          <Drawer
            anchor="right"
            open={updateAdviserRoleOpen}
            onClose={() => { setUpdateAdviserRoleOpen(false); }}
          >
            <AdviserRoleDrawerContainer>
              <AdviserRoleModifyForm
                adviserRole={adviserRoleForUpdate}
                close={() => setUpdateAdviserRoleOpen(false)}
              />
            </AdviserRoleDrawerContainer>
          </Drawer>

          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.adviserRoles : null)}
          />

        </div>
      </Paper>
      <BottomGridContainer>
        <Grid item>
          <AddButton
            onClick={() => setAddAdviserRoleOpen(true)}
            buttonText="Adviser Role"
          />
        </Grid>
      </BottomGridContainer>
    </div>
  );
}

const query = graphql`
  query AdviserRoleListQuery (
    $first: Int
    $offset: Int
  ) {
    viewer {
      ...AdviserRoleList_viewer
      @arguments (
        first: $first
        offset: $offset
      )
    }
  }
`;

const container = createRefetchContainer(
  AdviserRoleList,
  {
    viewer: graphql`
      fragment AdviserRoleList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
      ) {
        adviserRoles(
          first: $first,
          offset: $offset,
          orderBy: "-id"
        ) {
          edges {
            node {
              id
              name
              createdAt
              updatedAt
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }      
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
));
