import * as React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { fromGlobalId } from '../../../helpers/ID';

const ContractLink = (props) => {
  const { contract } = props;
  const intId = fromGlobalId(contract.id)[1];
  return (
    <Link href={`/contract/${intId}`}>
      {intId}
    </Link>
  );
};

ContractLink.propTypes = {
  contract: PropTypes.object.isRequired,
};

ContractLink.defaultProps = {
};

export default ContractLink;
