/**
 * @flow
 * @relayHash a1c8731799aaad8dff2417313ad65646
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
export type GenerateTradeInvoiceMutationVariables = {|
  portfolioOrderId?: ?string,
  stockOrderId?: ?string,
|};
export type GenerateTradeInvoiceMutationResponse = {|
  +generateTradeInvoice: ?{|
    +tradeInvoice: ?{|
      +id: string,
      +pdfUrl: ?string,
      +pdf: ?string,
      +state: ReportState,
      +createdAt: any,
      +updatedAt: any,
      +portfolioOrder: ?{|
        +id: string
      |},
    |}
  |}
|};
export type GenerateTradeInvoiceMutation = {|
  variables: GenerateTradeInvoiceMutationVariables,
  response: GenerateTradeInvoiceMutationResponse,
|};
*/


/*
mutation GenerateTradeInvoiceMutation(
  $portfolioOrderId: ID
  $stockOrderId: ID
) {
  generateTradeInvoice(portfolioOrderId: $portfolioOrderId, stockOrderId: $stockOrderId) {
    tradeInvoice {
      id
      pdfUrl
      pdf
      state
      createdAt
      updatedAt
      portfolioOrder {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "portfolioOrderId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "stockOrderId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateTradeInvoice",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "portfolioOrderId",
        "variableName": "portfolioOrderId"
      },
      {
        "kind": "Variable",
        "name": "stockOrderId",
        "variableName": "stockOrderId"
      }
    ],
    "concreteType": "GenerateTradeInvoice",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tradeInvoice",
        "storageKey": null,
        "args": null,
        "concreteType": "TradeInvoiceNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pdfUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "pdf",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "state",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "portfolioOrder",
            "storageKey": null,
            "args": null,
            "concreteType": "PortfolioOrderNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateTradeInvoiceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateTradeInvoiceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateTradeInvoiceMutation",
    "id": null,
    "text": "mutation GenerateTradeInvoiceMutation(\n  $portfolioOrderId: ID\n  $stockOrderId: ID\n) {\n  generateTradeInvoice(portfolioOrderId: $portfolioOrderId, stockOrderId: $stockOrderId) {\n    tradeInvoice {\n      id\n      pdfUrl\n      pdf\n      state\n      createdAt\n      updatedAt\n      portfolioOrder {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acf0c234b36b1e93e4c5f0abf61c84cf';
module.exports = node;
