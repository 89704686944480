/**
 * @flow
 * @relayHash 8b6e3889c21522ff4d8d47b8c11c4bc2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BrokerActivityGroupRemoveFromMutationVariables = {|
  brokerActivityId: string
|};
export type BrokerActivityGroupRemoveFromMutationResponse = {|
  +removeFromBrokerActivityGroup: ?{|
    +brokerActivity: ?{|
      +id: string,
      +group: ?{|
        +id: string,
        +description: ?string,
      |},
    |}
  |}
|};
export type BrokerActivityGroupRemoveFromMutation = {|
  variables: BrokerActivityGroupRemoveFromMutationVariables,
  response: BrokerActivityGroupRemoveFromMutationResponse,
|};
*/


/*
mutation BrokerActivityGroupRemoveFromMutation(
  $brokerActivityId: ID!
) {
  removeFromBrokerActivityGroup(brokerActivityId: $brokerActivityId) {
    brokerActivity {
      id
      group {
        id
        description
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerActivityId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeFromBrokerActivityGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerActivityId",
        "variableName": "brokerActivityId"
      }
    ],
    "concreteType": "RemoveFromBrokerActivityGroup",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerActivity",
        "storageKey": null,
        "args": null,
        "concreteType": "BrokerActivityNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "group",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerActivityGroupNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityGroupRemoveFromMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityGroupRemoveFromMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityGroupRemoveFromMutation",
    "id": null,
    "text": "mutation BrokerActivityGroupRemoveFromMutation(\n  $brokerActivityId: ID!\n) {\n  removeFromBrokerActivityGroup(brokerActivityId: $brokerActivityId) {\n    brokerActivity {\n      id\n      group {\n        id\n        description\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '41e9352ab04ba6f6ec3e240fc8dafff5';
module.exports = node;
