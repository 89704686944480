import { graphql } from 'react-relay';

// language=GraphQL
const cancelCashTransferRequests = graphql`
  mutation CancelCashTransferRequestMutation (
    $transferRequestId: ID
  ) {
    cancelCashTransfer(
      cashTransferRequestId: $transferRequestId
    ) {
      cashTransferRequest {
        id
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export default cancelCashTransferRequests;
