import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemFixedCurrencyCashField from '../common/drawer/DrawerGridItemFixedCurrencyCashField';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { ProcessButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { offMarketCashTransferMutation } from '../../mutations/OffMarket';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';

export default function AccountCashTransferForm(props) {
  const classes = useCommonStyles();
  const {
    currency,
    amount,
    accountId,
    onClose,
  } = props;
  const [counterPartyAccount, setCounterPartyAccount] = React.useState(null);
  const [ucVar] = React.useState({
    currency,
    amount,
  });

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Cash Transfer"
        />
        <DrawerGridItemFixedCurrencyCashField
          label="Amount"
          defaultValue={amount}
          currency={currency}
          onChange={(e) => { ucVar.amount = e.target.value; }}
        />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            caption="Counter Party Account"
            fullWidth
            onSelect={a => setCounterPartyAccount(a)}
          />
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <ProcessButton
              onClickPromise={() => doCommitPromise(
                offMarketCashTransferMutation,
                {
                  accountId,
                  counterPartyAccountId: counterPartyAccount.id,
                  currency,
                  amount: `${ucVar.amount}`,
                },
                () => (onClose && onClose()),
                () => null,
              )}
            />
          </BottomGridContainer>
        </Grid>

      </DrawerGridItemContainer>
    </div>
  );
}
