import { graphql } from 'react-relay';

// language=GraphQL
const generateRangeReconciliation = graphql`
  mutation GenerateRangeReconciliationMutation (
    $rangeReconciliationId: ID!
  ) {
    generateRangeReconciliation(
      rangeReconciliationId: $rangeReconciliationId
    ){
      rangeReconciliation {
        id
        noOfDays
        createdAt
        excelFileName
        excelFileUrl
        fromDate
        toDate
        noOfDays
        
        ibrangereconciliationendbalanceSet {
          edges {
            node{
              id
              currency
              quantity
            }
          }
        }
        
        ibrangereconciliationdeltaSet {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
        
      }
    }
  }
`;

export default generateRangeReconciliation;
