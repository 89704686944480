import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import ProcessAdd from '../../icons/ProcessAdd';
import ProcessRemove from '../../icons/ProcessRemove';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import { doCommitPromise } from '../../common/commit';
import processBrokerTransactionMutation from '../../mutations/ProcessBrokerTransaction';


export default function BrokerTransactionActions(props) {
  const {
    msgRouter,
    brokerTransaction,
  } = props;
  const refreshKey = `${brokerTransaction.id}-${brokerTransaction.updatedAt}`;
  const [selection, setSelection] = React.useState(msgRouter.state.selection || []);
  if (brokerTransaction.ignore || brokerTransaction.matched) {
    return null;
  }

  React.useEffect(() => {
    const onPublishState = (client, state) => {
      let { selection: updatedSelection } = state;
      updatedSelection = updatedSelection || [];
      setSelection(updatedSelection);
    };
    msgRouter.addStateListener(onPublishState);
    return function cleanup() {
      msgRouter.removeStateListener(onPublishState);
    };
  }, []);

  const inSelection = selection.reduce(
    (x, y) => x || y.id === brokerTransaction.id,
    false,
  );
  const matchWithSelection = selection.every(x => x.conId === brokerTransaction.conId);

  return (
    <Grid
      key={refreshKey}
      container
      spacing={1}
      justify="center"
    >
      <TableCompactGridItem>
        <TableIconButton
          key={0}
          tooltip="Process"
          color="secondary"
          disabled={selection.length > 0 && !inSelection}
          iconClass={SendIcon}
          onClickPromise={() => doCommitPromise(
            processBrokerTransactionMutation,
            {
              allowSweepGroups: selection.length > 1,
              brokerTransactionIds: selection.length > 1
                ? selection.map(x => x.id) : [brokerTransaction.id],
            },
            () => null,
            () => null,
          )}
        />
      </TableCompactGridItem>

      { brokerTransaction.instrumentType === 'CASH' && !inSelection && (
        <TableCompactGridItem>
          <TableIconButton
            key={0}
            tooltip="Add to Process"
            disabled={!matchWithSelection}
            iconClass={ProcessAdd}
            onClick={() => {
              const newSelection = [...selection, brokerTransaction];
              setSelection(newSelection);
              msgRouter.publishState(this, { selection: newSelection });
            }}
          />
        </TableCompactGridItem>
      )}

      {brokerTransaction.instrumentType === 'CASH' && inSelection && (
        <TableCompactGridItem>
          <TableIconButton
            key={0}
            tooltip="Remove from Process"
            iconClass={ProcessRemove}
            onClick={() => {
              const newSelection = selection.filter(x => x.id !== brokerTransaction.id);
              setSelection(newSelection);
              msgRouter.publishState(this, { selection: newSelection });
            }}
          />
        </TableCompactGridItem>
      )}

    </Grid>
  );
}


BrokerTransactionActions.propTypes = {
  msgRouter: PropTypes.object.isRequired,
};

BrokerTransactionActions.defaultProps = {
};
