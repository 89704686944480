import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Typography from '@material-ui/core/Typography';
import DateTime from '../DateTimeMoment';
import { useCommonStyles } from '../Styles';

export default function NoteEntry(props) {
  const { noteEntry } = props;
  const classes = useCommonStyles();
  return (
    <div className={classes.tableHolderRootNoFooter}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {noteEntry.text}
        </Grid>
        {
          noteEntry.noteentryattachmentSet.edges.map(attachmentEdge => (
            <Grid item xs={12}>
              <Chip
                size="small"
                icon={<AttachmentIcon />}
                label={attachmentEdge.node.name}
                clickable
                component="a"
                href={attachmentEdge.node.fileUrl}
                color="primary"
                // onDelete={handleDelete}
                // deleteIcon={<DoneIcon/>}
              />
            </Grid>
          ))
        }

        <Grid item xs={12}>
          <Typography variant="caption">
            {noteEntry.createdBy.fullName}
            &nbsp;&bull;&nbsp;
            <DateTime>
              {noteEntry.updatedAt}
            </DateTime>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
