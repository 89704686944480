/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BatchDetailsFormBase_viewer$ref: FragmentReference;
declare export opaque type BatchDetailsFormBase_viewer$fragmentType: BatchDetailsFormBase_viewer$ref;
export type BatchDetailsFormBase_viewer = {|
  +cashTransferRequestBatches: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +transactionInitiationSupported: ?boolean,
        +createdAt: any,
        +updatedAt: any,
        +custodian: Custodian,
        +inverseBatch: ?{|
          +id: string,
          +name: string,
        |},
        +contraBatch: ?{|
          +id: string,
          +name: string,
        |},
        +cashtransferrequestSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +status: ?CashTransferStatus,
              +amount: number,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: BatchDetailsFormBase_viewer$ref,
|};
export type BatchDetailsFormBase_viewer$data = BatchDetailsFormBase_viewer;
export type BatchDetailsFormBase_viewer$key = {
  +$data?: BatchDetailsFormBase_viewer$data,
  +$fragmentRefs: BatchDetailsFormBase_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "kind": "Fragment",
  "name": "BatchDetailsFormBase_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "batchId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "cashTransferRequestBatches",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "batchId"
        }
      ],
      "concreteType": "CashTransferRequestBatchNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CashTransferRequestBatchNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CashTransferRequestBatchNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "transactionInitiationSupported",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "custodian",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "inverseBatch",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferRequestBatchNode",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contraBatch",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferRequestBatchNode",
                  "plural": false,
                  "selections": (v2/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "cashtransferrequestSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "CashTransferRequestNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "CashTransferRequestNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "CashTransferRequestNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "status",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "amount",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf5a83dcea42bd4fbfea135a11e55156';
module.exports = node;
