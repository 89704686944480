import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../buttons/BaseButton';

function ActionOnlyModalDlgForm(props) {
  const {
    onClose,
    title,
    buttonOptions,
  } = props;
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => (onClose && onClose())}
          color="primary"
          buttonText="cancel"
        />
        {
          buttonOptions.map(actionItem => (
            <BaseButton
              onClick={actionItem.onClick}
              onClickPromise={actionItem.onClickPromise}
              color={actionItem.color}
              buttonText={actionItem.label}
            />
          ))
        }
      </DialogActions>

    </React.Fragment>
  );
}

ActionOnlyModalDlgForm.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  buttonOptions: PropTypes.arrayOf(PropTypes.object),
};

ActionOnlyModalDlgForm.defaultProps = {
  onClose: null,
  title: '',
  buttonOptions: [{
    label: 'Sample',
    color: 'primary',
    onClick: () => null,
    onClickPromise: () => null,
  }],
};

export default ActionOnlyModalDlgForm;
