/**
 * @flow
 * @relayHash aea763ebcbc96ac1a0bb55e0087a0a0f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VueCreateNewMutationVariables = {|
  name: string,
  tenantId: string,
|};
export type VueCreateNewMutationResponse = {|
  +createNewVue: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +vue: ?{|
      +name: string,
      +id: string,
    |},
  |}
|};
export type VueCreateNewMutation = {|
  variables: VueCreateNewMutationVariables,
  response: VueCreateNewMutationResponse,
|};
*/


/*
mutation VueCreateNewMutation(
  $name: String!
  $tenantId: ID!
) {
  createNewVue(name: $name, tenantId: $tenantId) {
    errors {
      field
      messages
    }
    vue {
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createNewVue",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "CreateNewVue",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vue",
        "storageKey": null,
        "args": null,
        "concreteType": "VueNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueCreateNewMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "VueCreateNewMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "VueCreateNewMutation",
    "id": null,
    "text": "mutation VueCreateNewMutation(\n  $name: String!\n  $tenantId: ID!\n) {\n  createNewVue(name: $name, tenantId: $tenantId) {\n    errors {\n      field\n      messages\n    }\n    vue {\n      name\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bef47f51fb30538dd3d521e5d9abe15e';
module.exports = node;
