import { graphql } from 'react-relay';

// language=GraphQL
const createCashTransferRequestContraBatch = graphql`
  mutation CreateCashTransferRequestContraBatchMutation (
    $batchId: ID!
    $name: String
    $custodian: Custodian
  ) {
    createCashTransferRequestContraBatch(
      batchId: $batchId
      name: $name
      custodian: $custodian
    ) {
      batch {
        contraBatch {
          id
          name
          cashtransferrequestSet {
            edges {
              node {
                id
              }
            }
          }
        }
        
        id
        name
        
        cashtransferrequestSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export default createCashTransferRequestContraBatch;
