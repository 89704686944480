/**
 * @flow
 * @relayHash c19bab020a9bb3d708dca5c9d3c46e3b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RangeReconcileList_viewer$ref = any;
export type RangeReconcileListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
|};
export type RangeReconcileListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: RangeReconcileList_viewer$ref
  |}
|};
export type RangeReconcileListQuery = {|
  variables: RangeReconcileListQueryVariables,
  response: RangeReconcileListQueryResponse,
|};
*/


/*
query RangeReconcileListQuery(
  $first: Int
  $offset: Int
) {
  viewer {
    ...RangeReconcileList_viewer_21Sbsv
    id
  }
}

fragment RangeReconcileList_viewer_21Sbsv on Query {
  ibRangeReconciliations(first: $first, offset: $offset) {
    count
    edges {
      node {
        id
        createdAt
        updatedAt
        excelFile
        excelFileName
        excelFileUrl
        fromDate
        toDate
        noOfDays
        ibrangereconciliationendbalanceSet {
          edges {
            node {
              id
              createdAt
              updatedAt
              currency
              quantity
            }
          }
        }
        ibrangereconciliationdeltaSet {
          edges {
            node {
              id
              createdAt
              updatedAt
              currency
              quantity
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RangeReconcileListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "RangeReconcileList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RangeReconcileListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibRangeReconciliations",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "IBRangeReconciliationNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBRangeReconciliationNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBRangeReconciliationNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "excelFile",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "excelFileName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "excelFileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fromDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "toDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "noOfDays",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibrangereconciliationendbalanceSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBRangeReconciliationEndBalanceNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IBRangeReconciliationEndBalanceNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "IBRangeReconciliationEndBalanceNode",
                                "plural": false,
                                "selections": (v5/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibrangereconciliationdeltaSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBRangeReconciliationDeltaNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IBRangeReconciliationDeltaNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "IBRangeReconciliationDeltaNode",
                                "plural": false,
                                "selections": (v5/*: any*/)
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "RangeReconcileListQuery",
    "id": null,
    "text": "query RangeReconcileListQuery(\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...RangeReconcileList_viewer_21Sbsv\n    id\n  }\n}\n\nfragment RangeReconcileList_viewer_21Sbsv on Query {\n  ibRangeReconciliations(first: $first, offset: $offset) {\n    count\n    edges {\n      node {\n        id\n        createdAt\n        updatedAt\n        excelFile\n        excelFileName\n        excelFileUrl\n        fromDate\n        toDate\n        noOfDays\n        ibrangereconciliationendbalanceSet {\n          edges {\n            node {\n              id\n              createdAt\n              updatedAt\n              currency\n              quantity\n            }\n          }\n        }\n        ibrangereconciliationdeltaSet {\n          edges {\n            node {\n              id\n              createdAt\n              updatedAt\n              currency\n              quantity\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bf7a48f2c5007b491f435a770afa1e8';
module.exports = node;
