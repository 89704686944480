import React from 'react';
import PropTypes from 'prop-types';
import CommentIcon from '@material-ui/icons/Comment';
import { Drawer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TableIconButton from '../table/TableIconButton';
import NotesDrawer from './NotesDrawer';
import TableCompactGridItem from '../table/TableCompactGridItem';

export default function NoteButton(props) {
  const {
    internalDrawer,
    align,
    notesOwner: notesOwnerIn,
    ownButtonComp,
  } = props;
  const OwnButtonComp = ownButtonComp;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [notesOwner, setNotesOwner] = React.useState(notesOwnerIn);
  const justify = {
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
  }[align];
  return (
    <>

      {
        ownButtonComp ? (
          <OwnButtonComp
            color={notesOwner.notes ? 'primary' : 'default'}
            onClickNotes={() => setShowDrawer(true)}
          />
        ) : (
          <Grid container spacing={1} justify={justify}>
            <TableCompactGridItem>
              <TableIconButton
                tooltip="Notes"
                color={notesOwner.notes ? 'primary' : 'default'}
                iconClass={CommentIcon}
                onClick={() => setShowDrawer(true)}
              />

            </TableCompactGridItem>
          </Grid>
        )
      }


      {
        internalDrawer && (
          <Drawer
            anchor="right"
            open={showDrawer}
            onClose={() => {
              setShowDrawer(false);
            }}
          >
            <NotesDrawer
              notesOwner={notesOwner}
              onAddNote={() => null}
              onClose={() => setShowDrawer(false)}
              onAddUpdateNoteEntry={(notes) => {
                setNotesOwner({
                  notes,
                  id: notesOwner.id,
                });
              }}
            />
          </Drawer>
        )
      }

    </>
  );
}


NoteButton.propTypes = {
  internalDrawer: PropTypes.bool,
  notesOwner: PropTypes.object.isRequired,
  align: PropTypes.string,
};

NoteButton.defaultProps = {
  internalDrawer: false,
  align: 'right',
};
