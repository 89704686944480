/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StockPriceDetails_viewer$ref: FragmentReference;
declare export opaque type StockPriceDetails_viewer$fragmentType: StockPriceDetails_viewer$ref;
export type StockPriceDetails_viewer = {|
  +contract: ?{|
    +id: string,
    +currency: Currency,
    +contractFinancialData: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +date: any,
          +closingPriceLocal: ?number,
          +scaleFactor: number,
        |}
      |}>
    |},
  |},
  +$refType: StockPriceDetails_viewer$ref,
|};
export type StockPriceDetails_viewer$data = StockPriceDetails_viewer;
export type StockPriceDetails_viewer$key = {
  +$data?: StockPriceDetails_viewer$data,
  +$fragmentRefs: StockPriceDetails_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StockPriceDetails_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "contractId",
      "type": "ID!",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "contractId"
        }
      ],
      "concreteType": "ContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contractFinancialData",
          "storageKey": "contractFinancialData(last:1000,orderBy:\"date\")",
          "args": [
            {
              "kind": "Literal",
              "name": "last",
              "value": 1000
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "date"
            }
          ],
          "concreteType": "ContractFinancialDataNodeConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "edges",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractFinancialDataNodeEdge",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "node",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractFinancialDataNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "date",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "closingPriceLocal",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "scaleFactor",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f09c10afd4fca736d5898fb6d1b924e3';
module.exports = node;
