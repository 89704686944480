import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import { isRecurNull } from '../../common/helpers';


function UserSubscriptionInvoices(props) {
  const {
    relay,
    viewer,
    filter,
  } = props;
  filter.setRelay(relay);
  const pathSpec = ['userSubscriptions', 'edges', 0, 'node', 'subscriptionPtr', 'subscriptioninvoiceSet'];
  const invoices = isRecurNull(viewer, pathSpec) || [];
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.dateField('billStart', 'Bill Start', node => node.billStart),
    TableFieldMeta.dateField('billEnd', 'Bill End', node => node.billEnd),
    TableFieldMeta.account(node => node.billTo, 'To Account', { showNumber: true }),
    TableFieldMeta.priceField('chargeAmount', 'Amount', 'chargeAmount'),
  ];

  return (
    <>
      <Grid
        item
        xs={12}
        lg={5}
      >
        <TablePaginator
          title="Invoices"
          filter={filter}
          columns={columns}
          getDataNode={() => invoices}
        />
      </Grid>
    </>
  );
}

const query = graphql`
  query UserSubscriptionInvoicesSetQuery (
    $first: Int
    $offset: Int
    $userSubscriptionId: ID
  ) {
    viewer {
      ...UserSubscriptionInvoices_viewer
      @arguments(
        first: $first
        offset: $offset
        userSubscriptionId: $userSubscriptionId
      ) 
    }
  }
`;

const container = createRefetchContainer(
  UserSubscriptionInvoices,
  {
    viewer: graphql`
      fragment UserSubscriptionInvoices_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        userSubscriptionId: {type: ID}
      ){
        userSubscriptions(
          id: $userSubscriptionId
        ) {
          count
          edges {
            node {
              id
              
              subscriptionPtr {
                subscriptioninvoiceSet (
                  first: $first
                  offset: $offset
                  orderBy: "-billStart"
                ) {
                  
                  edges {
                    node {
                      id
                      subscription {
                        id
                      }
                      chargeAmount
                      billEnd
                      billStart
                      billTo {
                        id
                        name
                      }
                    }
                  }
                  count
                }
              }
            
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    userSubscriptionId: props.userSubscriptionId,
  }),
));
