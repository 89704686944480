import { toGlobalId } from '../../helpers/ID';
import { withTabItems } from '../common/RoutableTabContainer';
import ContractDetails from './ContractDetails';
import StockPriceDetails from './StockPriceDetails';
import StockOrderList from '../order/StockOrderList';
import ContractHoldings from './ContractHoldings';

const contractIdPass = props => ({
  contractId: toGlobalId('ContractNode', props.match.params.contractId),
});

export default withTabItems(
  [
    {
      label: 'details',
      url: 'details',
      comp: ContractDetails,
      propCopyFn: contractIdPass,
    },
    {
      label: 'prices',
      url: 'prices',
      comp: StockPriceDetails,
      propCopyFn: contractIdPass,
    },
    {
      label: 'stock orders',
      url: 'stock-orders',
      comp: StockOrderList,
      propCopyFn: contractIdPass,
    },
    {
      label: 'holdings',
      url: 'holdings',
      comp: ContractHoldings,
      propCopyFn: contractIdPass,
    },
  ],
);
