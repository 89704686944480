import React from 'react';
import Grid from '@material-ui/core/Grid';
import DrawerGridItemButtonContainer from './drawer/DrawerGridItemButtonContainer';
import { ApproveButton, CloseButton } from './buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import cancelPortfolioOrderRequestMutation from '../../mutations/CancelPortfolioOrderRequest';
import approvePortfolioOrderRequestMutation from '../../mutations/ApprovePortfolioOrderRequest';
import ApprovalList from '../approvals/ApprovalList';

export default function ApprovalDrawerWidget(props) {
  const {
    request,
    onCancelSuccess,
    onApproveSuccess,
    approvals,
  } = props;
  return (
    <Grid container>
      <DrawerGridItemButtonContainer>
        <Grid item>
          <CloseButton
            buttonText="Cancel"
            color="secondary"
            disabled={request.status !== 'PENDING'}
            onClickPromise={() => doCommitPromise(
              cancelPortfolioOrderRequestMutation,
              { approvableId: request.id },
              () => onCancelSuccess && onCancelSuccess(),
            )}
          />
        </Grid>

        <Grid item>
          <ApproveButton
            buttonText="Approve"
            disabled={request.status !== 'PENDING'}
            onClickPromise={() => doCommitPromise(
              approvePortfolioOrderRequestMutation,
              { approvableId: request.id },
              () => onApproveSuccess && onApproveSuccess(),
            )}
          />
        </Grid>
      </DrawerGridItemButtonContainer>

      <Grid item xs={12}>
        <ApprovalList
          approvals={approvals.edges}
        />
      </Grid>
    </Grid>
  );
}
