import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import ProcessForm from './forms/ProcessForm';
import BrokerActivityDetails from './BrokerActivityDetails';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import notiStack from '../../common/notiStack';
import BrokerActivityActions from './BrokerActivityActions';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import BaseGridContainer from '../common/BaseGridContainer';
import { ROWS_PER_PAGE } from '../../common/constants';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import MsgRouter from '../../common/MsgRouter';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import RTTableRenderer from '../common/table/RTTableRenderer';
import BrokerActivityGroupActions from './BrokerActivityGroupActions';
import DividendDefForm from './forms/DividendDefForm';
import InnerTabButton from '../common/innerTab/InnerTabButton';
import DividendPayoutForm from './DividendPayoutForm';

const processedAtIsnullDefault = null;
const ignoredDefault = null;

function CashTransactionList(props) {
  const {
    viewer,
    relay,
    prefixColumns,
    postConIdColumns,
    postReportDateColumns,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [msgRouter] = React.useState(new MsgRouter());
  const [transTypeIn, setTransTypeIn] = React.useState(filter.params.transTypeIn);
  const [divDefDrawerOpen, setDivDefDrawerOpen] = React.useState(false);
  const columns = [
    ...prefixColumns,
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    BrokerActivityTableFieldMeta.conId,
    ...postConIdColumns,
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.field('description', 'Description', { width: 600 }),
    BrokerActivityTableFieldMeta.isin,
    BrokerActivityTableFieldMeta.symbol,
    TableFieldMeta.field('transactionDate', 'Transaction Date'),
    BrokerActivityTableFieldMeta.reportDate,
    ...postReportDateColumns,
    TableFieldMeta.field('transactionId', 'Transaction Id'),
    TableFieldMeta.field('transType', 'Trans Type'),
    TableFieldMeta.field('fxRateToBase', 'Fx Rate To Base'),
    TableFieldMeta.field('assetCategory', 'Asset Category'),
    TableFieldMeta.field('amount', 'Amount'),
    {
      id: 'actions',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={(be) => {
            if (be.transType === 'Deposits/Withdrawals') {
              notiStack.warning('Deposits/Withdrawals must be processed in transfers page');
              return;
            }
            setBrokerActivity(be);
            setDrawerOpen(true);
          }}
          onShowDetails={(be) => {
            setBrokerActivity(be);
            setDetailsOpen(true);
          }}
        />
      ),
    },
    BrokerActivityTableFieldMeta.ignore(msgRouter),
    {
      id: 'groupActions',
      accessor: node => node,
      Header: 'Div Group',
      width: 250,
      Cell: row => (
        <BrokerActivityGroupActions
          brokerActivity={row.value.brokeractivityPtr}
          msgRouter={msgRouter}
          groupName={`${row.value.symbol}: ${row.value.transactionDate}`}
          onClickGroup={(e) => {
            setBrokerActivity(row.value);
            setDivDefDrawerOpen(true);
          }}
          extraParams={() => ({
            dividendDef: {
              payDate: row.value.reportDate,
              exDate: row.value.reportDate,
              currency: row.value.currency,
            },
          })}
        />
      ),
    },
    TableFieldMeta.notes(row => row.value),
  ];

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [divPayoutOpen, setDivPayoutOpen] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = React.useState(null);

  return (
    <div className={classes.innerTabRoot}>

      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <ProcessForm
          key={brokerActivity ? brokerActivity.id : 'procForm'}
          brokerActivity={brokerActivity}
          onClose={() => setDrawerOpen(false)}
          showSetAccrual={brokerActivity}
          showValidateHoldings
          showForceNoSiblings
          showToAccount={brokerActivity && brokerActivity.transType === 'Other Fees'}
          quantityDescription="Responsible Quantity"
          brokerActivityCashDeltaVarName="amount"
          checkQuantitySum={false}
          heading="Process Cash Transaction"
          nodeName="IBCashTransactionNode"
        />
      </Drawer>

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails brokerActivity={brokerActivity} />
        </div>
      </Drawer>

      <Drawer anchor="right" open={divPayoutOpen} onClose={() => setDivPayoutOpen(false)}>
        <DividendPayoutForm />
      </Drawer>

      <Drawer
        anchor="right"
        open={divDefDrawerOpen}
        onClose={() => setDivDefDrawerOpen(false)}
      >
        <div className={classes.drawerRootExtended}>
          <DividendDefForm
            key={brokerActivity ? brokerActivity.id : 'activityDetail'}
            brokerActivityGroup={brokerActivity && brokerActivity.group}
            brokerActivityId={brokerActivity && brokerActivity.id}
            cashTransaction={brokerActivity}
            onClose={() => setDivDefDrawerOpen(false)}
          />
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="con-id-filter"
          label="Con ID"
          type="number"
          defaultValue={filter.params.ibConId}
          onChange={e => filter.updateVar('ibConId', (e.target.value && Number(e.target.value)) || null)}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          halfWidth
          defaultValue={filter.params.symbol}
          onChange={e => filter.updateVar('symbol', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="Currency"
          label="Currency"
          halfWidth
          value={filter.params.currency}
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="IBCashTransactionTypeMeta"
          multiple
          withEmpty={false}
          value={transTypeIn}
          valueDescriber={(enumMeta, enumKey) => enumMeta[enumKey].description}
          onChange={(e) => {
            setTransTypeIn(e.target.value);
            filter.updateVar('transTypeIn', e.target.value);
          }}
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.reportDate}
          onChange={value => filter.updateVar('reportDate', value)}
          label="Report Date"
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.transactionDate}
          onChange={value => filter.updateVar('transactionDate', value)}
          label="Transact. Date"
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: false, description: 'Yes' },
            { name: true, description: 'No' },
          ]}
          helperText="Processed?"
          defaultValue={filter.params.processedAt_Isnull}
          onChange={newVal => filter.updateVar('processedAt_Isnull', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Ignored?"
          defaultValue={filter.params.ignored}
          onChange={newVal => filter.updateVar('ignored', newVal)}
        />

        <InnerTabButton
          onClick={e => setDivPayoutOpen(true)}
        >
          Div. Payout
        </InnerTabButton>

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.ibCashTransactions : null)}
            tableRender={RTTableRenderer}
          />
        </Grid>

      </BaseGridContainer>
    </div>
  );
}

CashTransactionList.propTypes = {
  prefixColumns: PropTypes.arrayOf(PropTypes.object),
  postConIdColumns: PropTypes.arrayOf(PropTypes.object),
  postReportDateColumns: PropTypes.arrayOf(PropTypes.object),
};

CashTransactionList.defaultProps = {
  prefixColumns: [],
  postConIdColumns: [],
  postReportDateColumns: [],
};

const query = graphql`
  query CashTransactionListQuery (
    $first: Int
    $offset: Int
    $transType: String
    $search: String
    $reportDate: Date
    $transactionDate: Date
    $symbol: String
    $currency: String
    $ibConId: Int
    $processedAt_Isnull: Boolean
    $ignored: Boolean
    $transTypeIn: [String]
  ) {
    viewer {
      ...CashTransactionList_viewer
      @arguments (
        first: $first
        offset: $offset
        transType: $transType
        transactionDate: $transactionDate
        search: $search
        reportDate: $reportDate
        symbol: $symbol
        currency: $currency
        ibConId: $ibConId
        processedAt_Isnull: $processedAt_Isnull
        ignored: $ignored
        transTypeIn: $transTypeIn
      )
    }
  }
`;

const container = createRefetchContainer(
  CashTransactionList,
  {
    viewer: graphql`
      fragment CashTransactionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        transType: {type: String}
        search: {type: String}
        reportDate: {type: Date}
        transactionDate: {type: Date}
        symbol: {type: String}
        currency: {type: String}
        ibConId: {type: Int}
        processedAt_Isnull: {type: Boolean}
        ignored: {type: Boolean}
        transTypeIn: {type: "[String]", defaultValue: null}
      ) {
        ibCashTransactions(
          first: $first,
          offset: $offset,
          transType: $transType,
          transactionDate: $transactionDate,
          search: $search,
          reportDate: $reportDate,
          symbol: $symbol
          currency: $currency
          ibConId: $ibConId
          processedAt_Isnull: $processedAt_Isnull
          ignored: $ignored
          transTypeIn: $transTypeIn
        ) {
          edges {
            node {
              ...IBCashTransaction_all @relay(mask: false)
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  query,
);
export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ rowsPerPage }) => ({
    first: rowsPerPage || ROWS_PER_PAGE,
    search: null,
    reportDate: null,
    transactionDate: null,
    symbol: null,
    ibConId: null,
    currency: null,
    processedAt_Isnull: processedAtIsnullDefault,
    transTypeIn: [],
    ignored: ignoredDefault,
  }),
  {
    debounceFields: ['search', 'symbol', 'conId', 'currency'],
  },
));
