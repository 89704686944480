/**
 * @flow
 * @relayHash d953299045fd97aa284abdfcaeed7532
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DummyTransactionMutationVariables = {|
  transactionId: string,
  dummy?: ?boolean,
|};
export type DummyTransactionMutationResponse = {|
  +dummyTransaction: ?{|
    +transaction: ?{|
      +id: string,
      +dummy: boolean,
      +updatedAt: any,
    |}
  |}
|};
export type DummyTransactionMutation = {|
  variables: DummyTransactionMutationVariables,
  response: DummyTransactionMutationResponse,
|};
*/


/*
mutation DummyTransactionMutation(
  $transactionId: ID!
  $dummy: Boolean
) {
  dummyTransaction(transactionId: $transactionId, dummy: $dummy) {
    transaction {
      id
      dummy
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transactionId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dummy",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "dummyTransaction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dummy",
        "variableName": "dummy"
      },
      {
        "kind": "Variable",
        "name": "transactionId",
        "variableName": "transactionId"
      }
    ],
    "concreteType": "DummyTransaction",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "transaction",
        "storageKey": null,
        "args": null,
        "concreteType": "TransactionNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dummy",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DummyTransactionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "DummyTransactionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "DummyTransactionMutation",
    "id": null,
    "text": "mutation DummyTransactionMutation(\n  $transactionId: ID!\n  $dummy: Boolean\n) {\n  dummyTransaction(transactionId: $transactionId, dummy: $dummy) {\n    transaction {\n      id\n      dummy\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b59500b99fc1db2c70152e4c4fc01621';
module.exports = node;
