/**
 * @flow
 * @relayHash 7f74d985ad7faf3e1953cc8222d07ecc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
export type CreateWithdrawalRequestMutationVariables = {|
  accountId: string,
  amount: number,
  currency: string,
  bankAccountBsb?: ?string,
  bankAccountId?: ?string,
  bankAccountName?: ?string,
  bankAccountNumber?: ?string,
  custodian?: ?Custodian,
|};
export type CreateWithdrawalRequestMutationResponse = {|
  +createWithdrawalRequest: ?{|
    +cashTransferRequest: ?{|
      +id: string,
      +amount: number,
      +currency: Currency,
      +status: ?CashTransferStatus,
      +blocked: boolean,
      +account: {|
        +id: string
      |},
    |}
  |}
|};
export type CreateWithdrawalRequestMutation = {|
  variables: CreateWithdrawalRequestMutationVariables,
  response: CreateWithdrawalRequestMutationResponse,
|};
*/


/*
mutation CreateWithdrawalRequestMutation(
  $accountId: ID!
  $amount: Float!
  $currency: String!
  $bankAccountBsb: String
  $bankAccountId: ID
  $bankAccountName: String
  $bankAccountNumber: String
  $custodian: Custodian
) {
  createWithdrawalRequest(accountId: $accountId, amount: $amount, currency: $currency, bankAccountBsb: $bankAccountBsb, bankAccountId: $bankAccountId, bankAccountName: $bankAccountName, bankAccountNumber: $bankAccountNumber, custodian: $custodian) {
    cashTransferRequest {
      id
      amount
      currency
      status
      blocked
      account {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "amount",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bankAccountBsb",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bankAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bankAccountName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "bankAccountNumber",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "custodian",
    "type": "Custodian",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createWithdrawalRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "bankAccountBsb",
        "variableName": "bankAccountBsb"
      },
      {
        "kind": "Variable",
        "name": "bankAccountId",
        "variableName": "bankAccountId"
      },
      {
        "kind": "Variable",
        "name": "bankAccountName",
        "variableName": "bankAccountName"
      },
      {
        "kind": "Variable",
        "name": "bankAccountNumber",
        "variableName": "bankAccountNumber"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "custodian",
        "variableName": "custodian"
      }
    ],
    "concreteType": "CreateWithdrawalRequestAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "blocked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateWithdrawalRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateWithdrawalRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateWithdrawalRequestMutation",
    "id": null,
    "text": "mutation CreateWithdrawalRequestMutation(\n  $accountId: ID!\n  $amount: Float!\n  $currency: String!\n  $bankAccountBsb: String\n  $bankAccountId: ID\n  $bankAccountName: String\n  $bankAccountNumber: String\n  $custodian: Custodian\n) {\n  createWithdrawalRequest(accountId: $accountId, amount: $amount, currency: $currency, bankAccountBsb: $bankAccountBsb, bankAccountId: $bankAccountId, bankAccountName: $bankAccountName, bankAccountNumber: $bankAccountNumber, custodian: $custodian) {\n    cashTransferRequest {\n      id\n      amount\n      currency\n      status\n      blocked\n      account {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'adb03aefdf771287e480af27531544b2';
module.exports = node;
