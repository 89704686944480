/**
 * @flow
 * @relayHash 73b03658652cc37c4b6232805bc7f223
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdminPanel_userNode$ref = any;
export type AdminPanelQueryVariables = {|
  userId?: ?string
|};
export type AdminPanelQueryResponse = {|
  +viewer: ?{|
    +adminMeta: ?{|
      +isPaper: ?boolean,
      +systemName: ?string,
      +deploymentCode: ?string,
      +versionInfo: ?{|
        +commitAt: ?any,
        +commitHash: ?string,
        +commitMessage: ?string,
        +versionTags: ?$ReadOnlyArray<?string>,
        +latestVersion: ?string,
      |},
      +baseCurrency: ?string,
    |},
    +authUsers: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: AdminPanel_userNode$ref
        |}
      |}>
    |},
  |}
|};
export type AdminPanelQuery = {|
  variables: AdminPanelQueryVariables,
  response: AdminPanelQueryResponse,
|};
*/


/*
query AdminPanelQuery(
  $userId: ID
) {
  viewer {
    adminMeta {
      isPaper
      systemName
      deploymentCode
      versionInfo {
        commitAt
        commitHash
        commitMessage
        versionTags
        latestVersion
      }
      baseCurrency
    }
    authUsers(id: $userId) {
      edges {
        node {
          ...AdminPanel_userNode
          id
        }
      }
    }
    id
  }
}

fragment AdminPanel_userNode on UserNode {
  id
  fullName
  adminpermissionSet {
    edges {
      node {
        id
        resource
        verb
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "adminMeta",
  "storageKey": null,
  "args": null,
  "concreteType": "AdminMeta",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isPaper",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "systemName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deploymentCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "versionInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "VersionInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "commitAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "commitHash",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "commitMessage",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "versionTags",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "latestVersion",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "baseCurrency",
      "args": null,
      "storageKey": null
    }
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdminPanelQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "authUsers",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "UserNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "AdminPanel_userNode",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdminPanelQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "authUsers",
            "storageKey": null,
            "args": (v2/*: any*/),
            "concreteType": "UserNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "adminpermissionSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdminPermissionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AdminPermissionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AdminPermissionNode",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "resource",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "verb",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdminPanelQuery",
    "id": null,
    "text": "query AdminPanelQuery(\n  $userId: ID\n) {\n  viewer {\n    adminMeta {\n      isPaper\n      systemName\n      deploymentCode\n      versionInfo {\n        commitAt\n        commitHash\n        commitMessage\n        versionTags\n        latestVersion\n      }\n      baseCurrency\n    }\n    authUsers(id: $userId) {\n      edges {\n        node {\n          ...AdminPanel_userNode\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AdminPanel_userNode on UserNode {\n  id\n  fullName\n  adminpermissionSet {\n    edges {\n      node {\n        id\n        resource\n        verb\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b1cf0713b87bdac344b780ed18c3f62';
module.exports = node;
