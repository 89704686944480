/**
 * @flow
 * @relayHash fdfacf6bf896515804c9fe7f5e498f5a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type CreateCurrencyConversionRequestStatusMutationVariables = {|
  accountId: string,
  baseCurrency: Currency,
  quoteCurrency: Currency,
  quantity: any,
|};
export type CreateCurrencyConversionRequestStatusMutationResponse = {|
  +createCurrencyConversionRequest: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +currencyConversionRequest: ?{|
      +id: string,
      +baseCurrency: string,
      +quoteCurrency: string,
      +quantity: number,
      +status: string,
      +approvals: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +approvedBy: {|
              +id: string,
              +username: string,
            |},
            +approvedAt: any,
          |}
        |}>
      |},
      +hasCompleteApprovals: ?boolean,
    |},
  |}
|};
export type CreateCurrencyConversionRequestStatusMutation = {|
  variables: CreateCurrencyConversionRequestStatusMutationVariables,
  response: CreateCurrencyConversionRequestStatusMutationResponse,
|};
*/


/*
mutation CreateCurrencyConversionRequestStatusMutation(
  $accountId: ID!
  $baseCurrency: Currency!
  $quoteCurrency: Currency!
  $quantity: Decimal!
) {
  createCurrencyConversionRequest(accountId: $accountId, baseCurrency: $baseCurrency, quoteCurrency: $quoteCurrency, quantity: $quantity) {
    errors {
      field
      messages
    }
    currencyConversionRequest {
      id
      baseCurrency
      quoteCurrency
      quantity
      status
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "baseCurrency",
    "type": "Currency!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "quoteCurrency",
    "type": "Currency!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "quantity",
    "type": "Decimal!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCurrencyConversionRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "baseCurrency",
        "variableName": "baseCurrency"
      },
      {
        "kind": "Variable",
        "name": "quantity",
        "variableName": "quantity"
      },
      {
        "kind": "Variable",
        "name": "quoteCurrency",
        "variableName": "quoteCurrency"
      }
    ],
    "concreteType": "CreateCurrencyConversionRequest",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currencyConversionRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CurrencyConversionRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "baseCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quoteCurrency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvals",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approvedAt",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasCompleteApprovals",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCurrencyConversionRequestStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCurrencyConversionRequestStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCurrencyConversionRequestStatusMutation",
    "id": null,
    "text": "mutation CreateCurrencyConversionRequestStatusMutation(\n  $accountId: ID!\n  $baseCurrency: Currency!\n  $quoteCurrency: Currency!\n  $quantity: Decimal!\n) {\n  createCurrencyConversionRequest(accountId: $accountId, baseCurrency: $baseCurrency, quoteCurrency: $quoteCurrency, quantity: $quantity) {\n    errors {\n      field\n      messages\n    }\n    currencyConversionRequest {\n      id\n      baseCurrency\n      quoteCurrency\n      quantity\n      status\n      approvals {\n        edges {\n          node {\n            id\n            approvedBy {\n              id\n              username\n            }\n            approvedAt\n          }\n        }\n      }\n      hasCompleteApprovals\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be598843254db18898b3863a844966c8';
module.exports = node;
