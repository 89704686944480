import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import BaseButton from '../common/buttons/BaseButton';
import FeeTemplateLoader from '../common/fieldLoaders/FeeTemplateLoader';
import { useCommonStyles } from '../common/Styles';


export default function TenantFeeTemplateDlg(props) {
  const {
    feeTemplate: currentFeeTemplate,
    onClose,
    onFeeTemplate,
  } = props;
  const [feeTemplate, setFeeTemplate] = React.useState(currentFeeTemplate);
  const classes = useCommonStyles();

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          Change fee template
        </DialogContentText>
        <FeeTemplateLoader
          key={feeTemplate ? feeTemplate.id : 'null'}
          className={classes.fromControlDrawer}
          label="Fee Template"
          onChange={(instance, b) => setFeeTemplate(instance)}
          defaultValue={feeTemplate}
        />
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => onClose()}
          color="primary"
          buttonText="cancel"
        />
        <BaseButton
          key={feeTemplate ? feeTemplate.id : 'null'}
          onClick={() => onFeeTemplate(feeTemplate)}
          color="primary"
          buttonText="change"
        />
      </DialogActions>
    </React.Fragment>
  );
}
