/**
 * @flow
 * @relayHash dc11a8e753d799751ed722a9b16a5152
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BrokerTransactionIgnoreMutationVariables = {|
  brokerTransactionId?: ?string,
  ignore: boolean,
|};
export type BrokerTransactionIgnoreMutationResponse = {|
  +ignoreBrokerTransaction: ?{|
    +brokerTransaction: ?{|
      +id: string,
      +matched: boolean,
      +ignore: boolean,
    |}
  |}
|};
export type BrokerTransactionIgnoreMutation = {|
  variables: BrokerTransactionIgnoreMutationVariables,
  response: BrokerTransactionIgnoreMutationResponse,
|};
*/


/*
mutation BrokerTransactionIgnoreMutation(
  $brokerTransactionId: ID
  $ignore: Boolean!
) {
  ignoreBrokerTransaction(brokerTransactionId: $brokerTransactionId, ignore: $ignore) {
    brokerTransaction {
      id
      matched
      ignore
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerTransactionId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignore",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "ignoreBrokerTransaction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerTransactionId",
        "variableName": "brokerTransactionId"
      },
      {
        "kind": "Variable",
        "name": "ignore",
        "variableName": "ignore"
      }
    ],
    "concreteType": "IgnoreBrokerTransaction",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerTransaction",
        "storageKey": null,
        "args": null,
        "concreteType": "IBBrokerTransactionNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "matched",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ignore",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerTransactionIgnoreMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerTransactionIgnoreMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerTransactionIgnoreMutation",
    "id": null,
    "text": "mutation BrokerTransactionIgnoreMutation(\n  $brokerTransactionId: ID\n  $ignore: Boolean!\n) {\n  ignoreBrokerTransaction(brokerTransactionId: $brokerTransactionId, ignore: $ignore) {\n    brokerTransaction {\n      id\n      matched\n      ignore\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '595016319011eb6114391ecdb620be31';
module.exports = node;
