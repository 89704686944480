import React, { useEffect, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import withRouter from 'react-router-dom/es/withRouter';
import { Grid } from '@material-ui/core';
import TableButton from '../common/buttons/TableButton';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import TenantTreeView from './TenantTreeView';
import MUITableRenderer from '../common/table/MUITableRenderer';
import withSuspense from '../common/table/Suspention';

const TenantAddForm = withSuspense(React.lazy(
  () => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./au/TenantAddForm') : import('./uk/TenantAddForm')),
));


function TenantList(props) {
  const { viewer, relay, filter } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTenant, setActiveTenant] = useState(-1);
  filter.setRelay(relay);

  useEffect(() => {
    if (activeTenant !== -1) {
      setDrawerOpen(true);
    }
  }, [activeTenant]);
  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    { accessor: 'name', Header: 'Name', id: 'name' },
    {
      accessor: node => node,
      Header: 'Parent',
      id: 'parent',
      Cell: row => row.value.hierarchy.map(
        (parent, i) => (
          <React.Fragment>
            {i !== 0 && ' / '}
            <Link href={`/tenant/${parent.tenantId}`}>
              {parent.name}
            </Link>
          </React.Fragment>
        ),
      ),
    },
    TableFieldMeta.field('baseCurrency', 'Base Currency'),
    // TableFieldMeta.field('subdomain', 'Sub Domain'),
    TableFieldMeta.boolViewer('amlExempted'),
    {
      accessor: node => (node.amlExempted ? 'True' : 'False'),
      Header: 'AML Exempted',
      id: 'amlExempted',
    },
    {
      accessor: node => node,
      Header: '',
      id: 'createTenant',
      Cell: row => (
        <TableButton
          key={`createTenant${row.value.id}`}
          onClick={() => {
            setActiveTenant([row.value.id, row.value.name]);
          }}
        >
          Add Sub Tenant
        </TableButton>
      ),
    },
    TableFieldMeta.moreButtonLink('tenant', node => node.id),
  ];

  return (
    <div className={classes.innterTabRoot}>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <TenantAddForm
          key={activeTenant && activeTenant.id}
          activeTenant={activeTenant}
          onClose={() => setDrawerOpen(false)}
          onAddTenant={() => filter.update()}
        />
      </Drawer>

      <BaseGridContainer>
        <Grid item xs={12}>
          <Grid container>
            <InnerTabFilterGridItemTextField
              id="search-filter"
              label="Search"
              helperText="name"
              onChange={e => filter.updateVar('search', e.target.value)}
            />
          </Grid>
        </Grid>


        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={8}
              xl={9}
            >
              <TablePaginator
                filter={filter}
                columns={columns}
                getDataNode={() => (viewer ? viewer.tenants : null)}
                tableRender={MUITableRenderer}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              xl={3}
            >
              <div
                style={{
                  height: '100%',
                }}
              >
                <TenantTreeView />
              </div>
            </Grid>
          </Grid>

        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query TenantListContainerQuery (
    $first: Int,
    $offset: Int,
    $search: String,
  ) {
    viewer {
      ...TenantList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search,
      )
    }
  }
`;

const container = createRefetchContainer(
  TenantList,
  {
    viewer: graphql`
      fragment TenantList_viewer on Query
      @argumentDefinitions(
        offset: {type: Int, defaultValue: 10}
        first: {type: Int, defaultValue: 0}
        search: {type: String, defaultValue: null}
      ) {
        tenants(
          offset: $offset
          first: $first
          orderBy: "name"
          search: $search
        ) @connection(key: "Tenants_tenants") {
          edges {
            node {
              id
              name
              createdAt
              updatedAt
              parent {
                id
                name
              }
              baseCurrency
              hierarchy {
                name
                tenantId
              }
              subdomain
              logoUrl
              amlExempted
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(
  createQueryRendererProgressV2(query, container),
);
