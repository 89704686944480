import { graphql } from 'react-relay';

// language=GraphQL
const emailTradeInvoiceMutation = graphql`
  mutation EmailTradeInvoiceMutation (
    $tradeInvoiceId: ID!
  ) {
    emailTradeInvoice(
      tradeInvoiceId: $tradeInvoiceId
    ){
      success
    }
  }
`;

export default emailTradeInvoiceMutation;
