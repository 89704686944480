import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FileDropZone } from 'mui-dropzone';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Papa from 'papaparse';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerSubGroupHeading from '../common/drawer/DrawerSubGroupHeading';
import { CSV_MIME_TYPES } from '../../common/constants';
import { fromGlobalId, toGlobalId } from '../../helpers/ID';
import notiStack from '../../common/notiStack';
import { getColumnIndices } from '../../common/csv';
import createWithdrawalRequestsMutation from '../../mutations/CreateWithdrawalRequestsMutation';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { ProcessButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';


function BulkAddWithdrawalForm(props) {
  const { onAdd, onClose, custodian } = props;
  const classes = useCommonStyles();
  const [withdrawalEntries, setWithdrawalEntries] = React.useState([]);
  const [doBatch, setDoBatch] = React.useState(true);

  const batchNameRef = React.useRef();
  const [members] = React.useState({
    batchName: null,
  });
  
  const importEntries = (files) => {
    files.map(file => Papa.parse(file, {
      complete: (results) => {
        if (results.errors.length > 0) {
          results.errors.map(error => notiStack.error(error));
          return;
        }
        const importWithdrawalEntries = [];
        const splits = file.name.split('.');
        if (splits.length > 0) {
          [batchNameRef.current.value] = splits;
          [members.batchName] = splits;
        }

        const [
          accountIdIndex,
          bankAccountIdIndex,
          currencyIndex,
          amountIndex,
        ] = getColumnIndices(results, [
          [['Account ID'], true],
          [['Bank Account ID'], true],
          [['Currency'], true],
          [['Amount'], true],
        ]);

        const dataOnlyEntries = results.data.slice(1);
        dataOnlyEntries.forEach((dataRow, idx) => {
          if (!dataRow.some(x => !!x)) {
            return;
          }
          importWithdrawalEntries.push({
            rowIndex: idx,
            accountId: toGlobalId('AccountNode', dataRow[accountIdIndex]),
            bankAccountId: toGlobalId('BankAccountNode', dataRow[bankAccountIdIndex]),
            currency: dataRow[currencyIndex],
            amount: dataRow[amountIndex],
          });
        });
        setWithdrawalEntries(importWithdrawalEntries);
      },
    }));
  };

  const doCreateWithdrawalRequests = () => {
    if (withdrawalEntries.length === 0) {
      notiStack.error('Please upload a valid csv');
      return null;
    }

    const variables = {
      entries: withdrawalEntries.map(entry => ({
        accountId: entry.accountId,
        bankAccountId: entry.bankAccountId,
        currency: entry.currency,
        amount: `-${entry.amount}`,
      })),
      batchName: doBatch ? members.batchName : null,
      custodian,
    };

    return doCommitPromise(
      createWithdrawalRequestsMutation,
      variables,
      (response, errors) => {
        const responseErrors = response.createWithdrawalRequests.errors;
        if (!responseErrors) {
          const numRequests = response.createWithdrawalRequests.cashTransferRequests.length;
          notiStack.success(`${numRequests} withdrawal request(s) created`);
          if (onAdd) onAdd();
          if (onClose) onClose();
        }
      },
      () => null,
      () => null,
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add Withdrawals"
        />

        <Grid item xs={12}>
          <DrawerSubGroupHeading>
            Import file
          </DrawerSubGroupHeading>
          <FileDropZone
            acceptedMimeTypes={CSV_MIME_TYPES}
            onFilesAdded={files => importEntries(files)}
            onFilesRejected={() => null}
            elevation={2}
            dragOverElevation={10}
            className="file-drop-zone"
          >
            My Text
          </FileDropZone>
        </Grid>
        <DrawerGridItemTextField
          sm={6}
          disabled={!doBatch}
          label="Batch Name"
          onChange={(e) => {
            members.batchName = e.target.value;
          }}
          inputRef={batchNameRef}
        />
        <DrawerGridItemCheckBox
          label="Batch"
          value={doBatch}
          onChange={(e) => {
            setDoBatch(e.target.checked);
          }}
        />

        <Grid item xs={12}>
          <DrawerSubGroupHeading>
            Withdrawal Entries
          </DrawerSubGroupHeading>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Row Index</TableCell>
                <TableCell>Account ID</TableCell>
                <TableCell>Bank Account ID</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                withdrawalEntries.map(entry => (
                  <TableRow>
                    <TableCell>{entry.rowIndex}</TableCell>
                    <TableCell>{fromGlobalId(entry.accountId)[1]}</TableCell>
                    <TableCell>{fromGlobalId(entry.bankAccountId)[1]}</TableCell>
                    <TableCell>{entry.currency}</TableCell>
                    <TableCell align="right">{entry.amount}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <ProcessButton
                onClickPromise={doCreateWithdrawalRequests}
                buttonText="Process"
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </DrawerGridItemContainer>
    </div>
  );
}

export default BulkAddWithdrawalForm;
