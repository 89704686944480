import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import TableFieldMeta from '../../common/TableFieldMeta';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';
import VerticalTable from '../common/VerticalTable';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ApproveButton, CloseButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import ApprovalList from '../approvals/ApprovalList';
import createApproval from '../../mutations/CreateApproval';
import updateUserProvisionRequestStatus from '../../mutations/UpdateUserProvisionRequestStatus';
import EnumValue from '../common/EnumValue';
import mvMutationErrors from '../../common/errorReport';
import MoreButton from '../common/buttons/MoreButton';
import { toLocal } from '../../helpers/ID';
import UserRoleLoader from '../common/fieldLoaders/UserRoleLoader';


function UserProvisionRequestListFormData(props) {
  const { userProvisionRequest, onProvisionSuccess } = props;
  const [request, setRequest] = React.useState(userProvisionRequest);

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Provision User"
        />
        <Grid item xs={12}>
          <VerticalTable
            columnMeta={[
              ['Request ID', TableFieldMeta.localId.accessor],
              ['Account ID', node => toLocal(node.clientAccount.id)],
              ['Account Name', node => node.clientAccount.name],
              ['User ID', node => toLocal(node.userModel.id)],
              ['Email', node => node.userModel.email],
              ['Roles', (node) => {
                const currentUserClientAccountRoles = (
                  node.userModel.userclientaccountroleSet.edges.map(edge => edge.node.userRole)
                );
                return (
                  <UserRoleLoader
                    multiple
                    defaultValue={currentUserClientAccountRoles}
                  />
                );
              }],
              ['Status', node => (
                <EnumValue enumType="ApprovableStatus" enumValue={node.status} />
              )],
            ]}
            dataNode={request}
            showHeadings={false}
            valueAlignment="left"
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <CloseButton
              buttonText="Cancel"
              color="secondary"
              disabled={request.status !== 'PENDING'}
              onClickPromise={() => doCommitPromise(
                updateUserProvisionRequestStatus,
                {
                  userProvisionRequestId: request.id,
                  status: 'CANCELLED',
                },
                () => (onProvisionSuccess && onProvisionSuccess()),
              )}
            />
          </Grid>

          <Grid item>
            <ApproveButton
              buttonText="Provision"
              disabled={request.status !== 'PENDING' || !request.hasCompleteApprovals}
              onClickPromise={() => doCommitPromise(
                updateUserProvisionRequestStatus,
                {
                  userProvisionRequestId: request.id,
                  status: 'APPROVED',
                },
                () => (onProvisionSuccess && onProvisionSuccess()),
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <Grid item xs={12}>
          <ApprovalList approvals={request.approvals.edges} />
        </Grid>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ApproveButton
              buttonText="Approve"
              onClickPromise={() => doCommitPromise(
                createApproval,
                { approvableId: request.id },
                (response) => {
                  if (!mvMutationErrors(response, 'createApproval')) {
                    const approvalEdges = [
                      ...request.approvals.edges,
                      { node: response.createApproval.approval },
                    ];
                    const { hasCompleteApprovals } = response.createApproval;
                    const newRequest = {
                      ...request,
                      approvals: { edges: approvalEdges },
                      hasCompleteApprovals,
                    };
                    setRequest(newRequest);
                  }
                },
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}


const query = graphql`
  query UserProvisionRequestListFormQuery(
    $userProvisionRequestId: ID!
    $clientAccountId: ID!
  ) {
    viewer {
      id
      userProvisionRequests(
        id: $userProvisionRequestId,
        clientAccountId: $clientAccountId
      ) {
        edges {
          node {
            ...UserProvisionRequestListForm_viewer
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  UserProvisionRequestListFormData,
  graphql`
    fragment UserProvisionRequestListForm_viewer on UserProvisionRequestNode {
      id
      status
      clientAccount {
        id
        name
      }
      userModel {
        id
        email
        userclientaccountroleSet(clientAccountId: $clientAccountId) {
          edges {
            node {
              userRole {
                id
                name
              }
            }
          }
        }
      }
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  `,
);

const modifiedComp = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({ 
    userProvisionRequest: viewer && viewer.userProvisionRequests.edges[0].node,
    onProvisionSuccess: outerProps.onProvisionSuccess,
  }),
  ({ userProvisionRequestId, clientAccountId }) => ({ userProvisionRequestId, clientAccountId }),
);


function UserProvisionRequestListForm(props) {
  const { userProvisionRequest, onProvisionSuccess } = props;
  const UserProvisionRequestFormComp = modifiedComp;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();
  return (
    <div>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <UserProvisionRequestFormComp
            userProvisionRequestId={userProvisionRequest.id}
            clientAccountId={userProvisionRequest.clientAccount.id}
            onProvisionSuccess={(e) => {
              if (onProvisionSuccess) onProvisionSuccess();
              setDrawerOpen(false);
            }}
            
          />
        </div>
      </Drawer>
      <MoreButton
        onClick={() => setDrawerOpen(true)}
      />
    </div>
  );
}

export default UserProvisionRequestListForm;
