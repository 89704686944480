import { graphql } from 'react-relay';

const updateTenantFeeTemplateMutation = graphql`
  mutation UpdateTenantFeeTemplateMutation(
    $tenantId: ID!
    $feeTemplateId: ID
  ){
    updateTenantFeeTemplate(
      tenantId: $tenantId
      feeTemplateId: $feeTemplateId
    ){
      tenant {
        id
        feeTemplate {
          id
        }
      }
    }
  }
`;


export default updateTenantFeeTemplateMutation;
