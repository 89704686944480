/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SubscriberType = "ADV" | "CLIENT" | "%future added value";
export type SubscriptionCode = "RTP_CU" | "RTP_CX" | "RTP_OC" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSubscriptionList_viewer$ref: FragmentReference;
declare export opaque type UserSubscriptionList_viewer$fragmentType: UserSubscriptionList_viewer$ref;
export type UserSubscriptionList_viewer = {|
  +userSubscriptions: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +subscriberType: SubscriberType,
        +id: string,
        +createdAt: any,
        +updatedAt: any,
        +subscriptionTemplate: {|
          +code: SubscriptionCode,
          +name: string,
        |},
        +user: {|
          +id: string
        |},
        +billPeriodStart: any,
        +billPeriodEnd: any,
        +pendingCancel: boolean,
        +active: boolean,
        +paymentAccount: {|
          +id: string,
          +name: ?string,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: UserSubscriptionList_viewer$ref,
|};
export type UserSubscriptionList_viewer$data = UserSubscriptionList_viewer;
export type UserSubscriptionList_viewer$key = {
  +$data?: UserSubscriptionList_viewer$data,
  +$fragmentRefs: UserSubscriptionList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserSubscriptionList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "subscriptionCode",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "active",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "userSubscriptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "active",
          "variableName": "active"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Variable",
          "name": "subscriptionCode",
          "variableName": "subscriptionCode"
        }
      ],
      "concreteType": "UserSubscriptionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserSubscriptionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "UserSubscriptionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "subscriberType",
                  "args": null,
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "subscriptionTemplate",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SubscriptionTemplateNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "code",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "user",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "billPeriodStart",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "billPeriodEnd",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "pendingCancel",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "active",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "paymentAccount",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '1bd8e6ed2563b1fc7d2abcad938d4b9c';
module.exports = node;
