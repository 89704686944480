import { graphql } from 'react-relay';

const updateUserProvisionRequestStatus = graphql`
  mutation UpdateUserProvisionRequestStatusMutation(
    $userProvisionRequestId: ID!,
    $status: ApprovableStatus!
  ) {
    updateUserProvisionRequestStatus (
      userProvisionRequestId: $userProvisionRequestId,
      status: $status
    ) {
      errors {
        field
        messages
      }
      userProvisionRequest {
        id
        userModel {
          id
          username
          email
        }
        clientAccount {
          id
          name
        }
        status
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    }
  }
`;

export default updateUserProvisionRequestStatus;
