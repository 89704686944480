import React from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import TableFieldMeta from '../../../common/TableFieldMeta';
import PaginatedTable from '../PaginatedTable';
import { useCommonStyles } from '../Styles';
import { createQueryRendererProgress } from '../QueryRendererProgress';
import RelayFilter from '../../../common/RelayFilter';
import InnerTabFilterGridItemTextField from '../innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../BaseGridContainer';
import TenantLink from '../links/TenantLink';

function TenantSearch(props) {
  const {
    relay,
    viewer,
    onSelectItems,
    closeOnSingleSelect,
    postColumns,
  } = props;

  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
  }, ['search']));

  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.customCell('tenant', 'Parent', row => (
      <TenantLink tenant={row.value.parent} />
    )),
  ];

  if (postColumns) columns.push(...postColumns);
  if (onSelectItems) columns.push(TableFieldMeta.selectItem(onSelectItems));

  if (viewer && viewer.contracts && viewer.advisers.edges.length === 1 && closeOnSingleSelect) {
    if (onSelectItems) {
      onSelectItems([viewer.advisers.edges[0].node]);
    }
  }

  return (
    <div>
      <div className={classes.formControlContainer}>
        <BaseGridContainer>
          <InnerTabFilterGridItemTextField
            id="search-filter"
            label="Custom Search"
            helperText="Name"
            onChange={e => relayFilter.updateVar('search', e.target.value)}
          />
        </BaseGridContainer>
      </div>

      <PaginatedTable
        columns={columns}
        ref={relayFilter.tableRef}
        viewer={viewer}
        getDataNode={() => (viewer ? viewer.tenants : null)}
        getFetchParams={relayFilter.fetchParams}
        relay={relay}
      />
    </div>
  );
}


const query = graphql`
  query TenantSearchQuery (
    $first: Int
    $offset: Int
    $search: String
  ) {
    viewer {
      ...TenantSearch_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
      )
    }
  }
`;

const container = createRefetchContainer(
  TenantSearch,
  {
    viewer: graphql`
      fragment TenantSearch_viewer on Query      
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
      ) {
        tenants(
          first: $first
          offset: $offset
          search: $search
        ) {
          count
          edges {
            node {
              id
              name
              parent {
                id
                name
              }
            }
          }
        }
      }
    `,
  },
  query,
);

TenantSearch.propTypes = {
  closeOnSingleSelect: PropTypes.bool,
  onSelectItems: PropTypes.func,
  postColumns: PropTypes.array,
};

TenantSearch.defaultProps = {
  closeOnSingleSelect: false,
  onSelectItems: null,
  postColumns: [],
};

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { onSelectItems, closeOnSingleSelect, postColumns }) => ({
    onSelectItems,
    closeOnSingleSelect,
    postColumns,
  }),
  () => ({}),
);
