import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { commitMutation } from 'react-relay';
import { useCommonStyles } from '../common/Styles';
import PortfolioLoader from '../common/fieldLoaders/PortfolioLoader';
import environment from '../../Environment';
import notiStack from '../../common/notiStack';
import Console from '../../common/log';
import PositionTransferPurchase from './PositionTransferPurchase';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton, RemoveButton } from '../common/buttons/BaseButton';
import createPositionTransfer from '../../mutations/CreatePositionTransfer';
import PositionTransferCashQuantity from './PositionTransferCashQuantity';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';


export default function PositionTransferProcessForm(props) {
  const classes = useCommonStyles();
  const { brokerTransfer, onClose } = props;
  const [portfolio, setPortfolio] = React.useState(null);
  const [account, setAccount] = React.useState(null);
  const isTransferOut = brokerTransfer && brokerTransfer.direction === 'OUT';

  const preFillPurchases = brokerTransfer ? [
    {
      quantity: brokerTransfer.quantity,
      purchaseDate: brokerTransfer.reportDate,
      index: 0,
      currency: brokerTransfer.currency,
      costBasis: isTransferOut ? 0 : -brokerTransfer.positionAmount,
      commission: 0,
      dummy: false,
    },
  ] : [
    {
      quantity: 0,
      purchaseDate: null,
      index: 0,
      costs: [],
    },
  ];

  const preFillCashQuantities = brokerTransfer && brokerTransfer.cashQuantity !== 0
    ? [
      {
        currency: brokerTransfer.currency,
        quantity: brokerTransfer.cashQuantity,
      },
    ] : [];

  const [purchases, setPurchases] = React.useState(preFillPurchases);
  const [cashQuantities, setCashQuantities] = React.useState(preFillCashQuantities);

  const addPurchase = () => {
    const newPurchase = {
      index: purchases.length,
      quantity: 0,
      purchaseDate: brokerTransfer.reportDate,
      portfolio: portfolio ? portfolio.id : null,
      currency: brokerTransfer.currency,
      costBasis: 0,
      commission: 0,
      dummy: false,
    };
    const newPurchases = [...purchases, newPurchase];
    setPurchases(newPurchases);
  };

  const addCashQuantity = () => {
    const newCashQuantity = {
      index: cashQuantities.length,
      quantity: 0,
      currency: 'AUD',
    };
    const newCashQuantities = [...cashQuantities, newCashQuantity];
    setCashQuantities(newCashQuantities);
  };

  const removePurchase = () => {
    purchases.pop();
    const newPurchases = [...purchases];
    setPurchases(newPurchases);
  };

  const removeCashQuantity = () => {
    cashQuantities.pop();
    const newCashQuantities = [...cashQuantities];
    setCashQuantities(newCashQuantities);
  };

  const createTransfer = () => {
    if (!account) {
      notiStack.warning('Account not selected');
      return;
    }

    if (!portfolio && purchases.length > 0) {
      notiStack.warning('Portfolio not selected');
      return;
    }

    const variables = {
      accountId: account.id,
      status: 'PENDING',
      transferId: brokerTransfer.id,
      purchases: Array.from(
        purchases,
        purchase => ({
          portfolioId: portfolio.id,
          purchaseDate: purchase.purchaseDate,
          quantity: purchase.quantity,
          costBasis: purchase.costBasis && Number(purchase.costBasis),
          commission: purchase.commission && Number(purchase.commission),
          dummy: purchase.dummy,
        }),
      ),
      cashQuantities: Array.from(
        cashQuantities,
        cashQuantity => ({
          currency: cashQuantity.currency,
          quantity: cashQuantity.quantity,
        }),
      ),
    };

    commitMutation(environment, {
      mutation: createPositionTransfer,
      variables: {
        transferRequest: variables,
      },
      onCompleted: (response, errors) => {
        if (response.createPositionTransfer) {
          notiStack.success('Position transfer created and applied');
          if (onClose) {
            onClose();
          }
        }
      },
      onError: (err) => {
        Console.error(err);
      },
    });
  };

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <Grid item xs={12}>
          <div>
            <Typography variant="h6" gutterBottom>
              Position Transfer
            </Typography>
          </div>
          {
            brokerTransfer ? [`description: ${brokerTransfer.description}`,
              `symbol: ${brokerTransfer.symbol},  quantity: ${brokerTransfer.quantity}`] : [].map(
              x => (
                <div>
                  <Typography variant="caption" gutterBottom>
                    {x}
                  </Typography>
                </div>
              ),
            )
          }
        </Grid>
        <Grid item xs={12}>
          <PopupAccountSelectorField
            label="Account"
            fullWidth
            onSelect={inAccount => setAccount(inAccount)}
          />
        </Grid>
        {
          account && (
            <Grid item xs={12}>
              <PortfolioLoader
                className={classes.fromControlDrawer}
                id="portfolio"
                label="Portfolio"
                type="text"
                onChange={option => setPortfolio(option)}
                InputLabelProps={{
                  shrink: true,
                }}
                account={account}
              />
            </Grid>
          )
        }
        {
          purchases.map(
            (purchaseItem, index) => (
              <PositionTransferPurchase
                heading={purchases.length > 1 ? `Purchase ${index + 1} Details` : 'Purchase Details'}
                key={`${purchaseItem.index}`}
                purchase={purchaseItem}
                currency={brokerTransfer ? brokerTransfer.currency : 'AUD'}
                isTransferOut={isTransferOut}
                updatePurchase={(purchase, newPurchase) => {
                  purchases[purchase.index] = newPurchase;
                }}
              />
            ),
          )
        }

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <AddButton
                onClick={() => addPurchase()}
                buttonText="Purchase"
              />
            </Grid>
            <Grid item>
              <RemoveButton
                disabled={purchases.length === 0}
                onClick={() => removePurchase()}
                buttonText="Purchase"
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

        {
          cashQuantities.map(
            (cashQuantityItem, index) => (
              <PositionTransferCashQuantity
                heading={purchases.length > 1 ? `Cash ${index + 1} Details` : 'Cash Details'}
                key={`${cashQuantityItem.currency}-${cashQuantityItem.quantity}`}
                cashQuantity={cashQuantityItem}
                updateCashQuantity={(cashQuantity, newCashQuantity) => {
                  cashQuantities[cashQuantity.index] = newCashQuantity;
                }}
              />
            ),
          )
        }

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <AddButton
                onClick={() => addCashQuantity()}
                buttonText="Cash"
              />
            </Grid>
            <Grid item>
              <RemoveButton
                disabled={cashQuantities.length === 0}
                onClick={() => removeCashQuantity()}
                buttonText="Cash"
              />
            </Grid>
          </BottomGridContainer>
        </Grid>


        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <AddButton
                onClick={() => createTransfer()}
                buttonText="Transfer"
                disabled={(purchases.length > 0 && !portfolio) || !account}
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </DrawerGridItemContainer>
    </div>
  );
}
