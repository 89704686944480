import React from 'react';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemTextArea from '../common/drawer/DrawerGridItemTextArea';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';


export default function CronOutputForm(props) {
  const { cronOutput } = props;
  const classes = useCommonStyles();

  React.useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      // onTimeout();
    }, 4000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className={classes.drawerBottom}>
      <DrawerGridItemContainer>
        <DrawerGridItemTextArea
          disabled
          label="Cron output"
          defaultValue={cronOutput}
          maxRows={10}
          minRows={10}
          variant="filled"
        />
      </DrawerGridItemContainer>
    </div>
  );
}
