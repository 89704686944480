import { graphql } from 'react-relay';

export const ignoreBrokerActivity = graphql`
  mutation BrokerActivityIgnoreMutation (
    $brokerActivityId: ID
    $ignore: Boolean!
    $brokerActivityIds: [ID]
  ) {
    ignoreBrokerActivity(
      brokerActivityId: $brokerActivityId
      ignore: $ignore
      brokerActivityIds: $brokerActivityIds
    ) {
      transfer: brokerActivity {
        ... on IBTransferNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
      }
      
      cashTransfer: brokerActivity {
        ... on IBCashTransactionNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
      }
      
      corporateAction: brokerActivity {
        ... on IBCorporateActionNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
      }


      brokerActivities {
        ... on IBCorporateActionNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
        
        ... on IBTransferNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
        
        ... on IBCashTransactionNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
          
        ... on IBTransactionTaxNode {
          id
          ignored
          processedAt
          updatedAt
          processedBy {
            id
            fullName
          }
        }
          
          
        
      }
      
      
    }
  }
`;

export const unprocessBrokerActivity = graphql`
  mutation BrokerActivityUnprocessMutation (
    $brokerActivityId: ID
  ) {
    unprocessBrokerActivity(
      brokerActivityId: $brokerActivityId
    ) {
      corporateAction: brokerActivity {
        ... on IBCorporateActionNode {
          ...IBCorporateAction_all 
        }
      }
    }
  }
`;


export const createStockDividend = graphql`
  mutation BrokerActivityCreateStockDividendMutation(
  $corporateActionId: ID
  $exDate: Date
  $payDate: Date
  $reinvestmentPrice: Float
  ) {
    vbCreateDividend(
      corporateActionId: $corporateActionId 
      exDate: $exDate 
      payDate: $payDate 
      reinvestmentPrice: $reinvestmentPrice
    ) {
      dividend {
        ...VBDividend_all
      }
    }
  }
`;
