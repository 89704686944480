/**
 * @flow
 * @relayHash ddeb288a5a813805e63d660e13984e61
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FxOrderList_viewer$ref = any;
export type FxOrderListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  accountId?: ?string,
  brokerOrderId?: ?string,
  search?: ?string,
|};
export type FxOrderListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: FxOrderList_viewer$ref
  |}
|};
export type FxOrderListQuery = {|
  variables: FxOrderListQueryVariables,
  response: FxOrderListQueryResponse,
|};
*/


/*
query FxOrderListQuery(
  $first: Int
  $offset: Int
  $accountId: ID
  $brokerOrderId: ID
  $search: String
) {
  viewer {
    ...FxOrderList_viewer_1pO47t
    id
  }
}

fragment FxOrderList_viewer_1pO47t on Query {
  fxOrders(accountId: $accountId, first: $first, offset: $offset, orderBy: "-id", brokerOrderId: $brokerOrderId, search: $search) {
    edges {
      node {
        account {
          name
          id
        }
        quantityToDate
        brokerOrder {
          id
          side
          status
          quantity
          quantityFilled
          quantityLeft
          matched
        }
        orderPtr {
          price
          id
        }
        id
        price
        quantity
        createdAt
        updatedAt
        contract {
          currency
          localSymbol
          symbol
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerOrderId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "Variable",
  "name": "brokerOrderId",
  "variableName": "brokerOrderId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FxOrderListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FxOrderList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FxOrderListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fxOrders",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-id"
              },
              (v5/*: any*/)
            ],
            "concreteType": "FxOrderNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FxOrderNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FxOrderNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantityToDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokerOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerOrderNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "side",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "quantityFilled",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "quantityLeft",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "matched",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "orderPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderNode",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FxContractNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "currency",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "localSymbol",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FxOrderListQuery",
    "id": null,
    "text": "query FxOrderListQuery(\n  $first: Int\n  $offset: Int\n  $accountId: ID\n  $brokerOrderId: ID\n  $search: String\n) {\n  viewer {\n    ...FxOrderList_viewer_1pO47t\n    id\n  }\n}\n\nfragment FxOrderList_viewer_1pO47t on Query {\n  fxOrders(accountId: $accountId, first: $first, offset: $offset, orderBy: \"-id\", brokerOrderId: $brokerOrderId, search: $search) {\n    edges {\n      node {\n        account {\n          name\n          id\n        }\n        quantityToDate\n        brokerOrder {\n          id\n          side\n          status\n          quantity\n          quantityFilled\n          quantityLeft\n          matched\n        }\n        orderPtr {\n          price\n          id\n        }\n        id\n        price\n        quantity\n        createdAt\n        updatedAt\n        contract {\n          currency\n          localSymbol\n          symbol\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dc10d48b4c69854017d779b0d65d197';
module.exports = node;
