import React from 'react';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import Price from '../common/Price';
import TableButton from '../common/buttons/TableButton';
import OffMarketTransferCostBasisForm from './OffMarketTransferCostBasisForm';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';


const cashDescriber = (node) => {
  if (node.offmarkettransfercashquantitySet.edges.length === 0) {
    return null;
  }
  return (
    <Grid container spacing={1}>
      {
        node.offmarkettransfercashquantitySet.edges.map(cashDelta => (
          <Grid item>
            <Price currency={cashDelta.node.currency}>{cashDelta.node.quantity}</Price>
          </Grid>
        ))
      }
    </Grid>
  );
};

function OffMarketTransferList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [editCostBasis, setEditCostBasis] = React.useState(false);
  const [members] = React.useState({
    selectedHolding: null,
  });

  const holdingDescriber = (node) => {
    if (node.offmarkettransferholdingquantitySet.edges.length === 0) {
      return '';
    }
    return (
      <Grid container spacing={2}>
        {
          node.offmarkettransferholdingquantitySet.edges.map(holding => (
            <React.Fragment>
              <Grid item>
                {`${holding.node.contract.symbol}: ${holding.node.quantity}`}
              </Grid>
              <Grid item>
                (
                <Price currency={holding.node.contract.currency}>
                  {holding.node.costBasis}
                </Price>
                &nbsp;
                <Price currency="AUD">
                  {holding.node.commission}
                </Price>
                )
              </Grid>
              <Grid item>
                <TableButton
                  color="primary"
                  buttonText="Edit"
                  onClick={() => {
                    members.selectedHolding = holding.node;
                    setEditCostBasis(true);
                  }}
                />
              </Grid>
            </React.Fragment>
          ))
        }
      </Grid>
    );
  };

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('description', 'Description'),
    TableFieldMeta.account(node => node.account),
    TableFieldMeta.account(node => node.counterpartyAccount, 'Counter Party Account'),
    TableFieldMeta.field('holding', 'Holdings/Costbasis', { accessor: holdingDescriber }),
    TableFieldMeta.field('cash', 'Cash', { accessor: cashDescriber }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={editCostBasis}
        onClose={() => {
          members.selectedHolding = null;
          setEditCostBasis(false);
        }}
      >
        <div className={classes.drawerRoot}>
          {members.selectedHolding && (
            <OffMarketTransferCostBasisForm
              key={editCostBasis}
              holdingQuantity={members.selectedHolding}
              onClose={() => {
                members.selectedHolding = null;
                setEditCostBasis(false);
              }}
            />
          )}
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description"
          onChange={e => filter.updateVar('search', e.target.value)}
        />
        
        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.offMarketTransfers : null)}
            filter={filter}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query OffMarketTransferListQuery (
    $first: Int,
    $offset: Int,
    $search: String,
    $accountId: String
  ) {
    viewer {
      ...OffMarketTransferList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  OffMarketTransferList,
  {
    viewer: graphql`
      fragment OffMarketTransferList_viewer on Query 
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        accountId: {type: String, defaultValue: null }
      ){
        offMarketTransfers (
          first: $first
          offset: $offset
          orderBy: "-createdAt"
          search: $search
          accountOrCounterpartyAccount: $accountId
        ) {
          count
          edges {
            node {
              id
              account {
                name
                id
              }
              counterpartyAccount {
                name
                id
              }
              offmarkettransfercashquantitySet {
                edges {
                  node {
                    id
                    currency
                    quantity
                    updatedAt
                  }
                }
              }
              offmarkettransferholdingquantitySet {
                edges {
                  node {
                    id
                    contract {
                      id
                      symbol
                      currency
                    }
                    costBasis
                    commission
                    dummy
                    portfolio {
                      id 
                      trackingVue {
                        name
                      }
                    }
                    quantity
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
  { debounceFields: ['search'] },
));
