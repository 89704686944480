import { graphql } from 'react-relay';

const approvePositionTransfers = graphql`
  mutation ApprovePositionTransfersMutation (
    $brokerTransferId: ID
    $transferRequestIds: [ID]
  ) {
    approvePositionTransfers (
      brokerTransferId: $brokerTransferId
      transferRequestIds: $transferRequestIds
    ) {
      positionTransferRequests {
        id
        status
        updatedAt
        createdAt
        positionTransferPurchases {
          edges {
            node {
              id
              portfolio {
                trackingVueName
              }
              contract {
                id
                symbol
                name
              }
            }
          }
        }
      }
      
      brokerTransfer {
        id
        direction
        createdAt
        updatedAt
        processedAt
        processedBy {
          fullName
          firstName
          lastName
        }
        brokeractivityPtr {
          brokeractivitytransactionSet {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default approvePositionTransfers;
