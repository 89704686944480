
import { graphql } from 'react-relay';

// language=GraphQL
const feeComponentMutation = graphql`
  mutation FeeComponentMutation(
    $input: FeeComponentMutationInput!
  ){
    feeComponent(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }

      obj {
        id
        createdAt
        updatedAt
        calculationType
        flatChargeAmount
        minChargePoint
        maxChargePoint
        minPriceChargePoint
        maxPriceChargePoint
        percentChargeAmount
        tenant {
          id
          name
        }
        fee {
          id
          feeComponents {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }`;

export const deleteFeeComponentMutation = graphql`
  mutation FeeComponentDeleteMutation(
    $feeComponentId: ID!
  ){
    deleteFeeComponent(
      feeComponentId: $feeComponentId
    ){
      fee {
        id
        feeComponents {
          edges {
            node {
              id
            }
          }
        }
      }
    } 
  }
`;

export default feeComponentMutation;
