import { graphql } from 'react-relay';

const createCurrencyConversionRequest = graphql`
  mutation CreateCurrencyConversionRequestStatusMutation (
    $accountId: ID!
    $baseCurrency: Currency!
    $quoteCurrency: Currency!
    $quantity: Decimal!
  ) {
    createCurrencyConversionRequest(
      accountId: $accountId,
      baseCurrency: $baseCurrency,
      quoteCurrency: $quoteCurrency,
      quantity: $quantity
    ) {
      errors {
        field
        messages
      }
      currencyConversionRequest {
        id
        baseCurrency
        quoteCurrency
        quantity
        status
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    }
  }
`;

export default createCurrencyConversionRequest;
