import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';


export default function BottomGridContainer(props) {
  const { children, extraStyles } = props;
  return (
    <Grid
      container
      spacing={1}
      justify="flex-end"
      style={{ marginTop: '10pt', ...extraStyles }}
    >
      {children}
    </Grid>
  );
}
BottomGridContainer.propTypes = {
  extraStyles: PropTypes.object,
};

BottomGridContainer.defaultProps = {
  extraStyles: {},
};
