import { graphql } from 'react-relay';

export const brokerageCredit = graphql`
  mutation BrokerageCreditMutation(
    $accountId: ID!
    $initialAmount: Decimal!
    $expiryDate: Date!
    $note: String!,
    $description: String!
    $creditType: CreditType!
    $provisionedById: ID!
  ) {
    createBrokerageCredit (
      accountId: $accountId,
      initialAmount: $initialAmount,
      expiryDate: $expiryDate,
      note: $note,
      description: $description,
      creditType: $creditType,
      provisionedById: $provisionedById
    ) {
      errors {
        field
        messages
      }
      brokerageCredit {
        id
      }
    }
  }
`;

export const updateBrokerageCreditStatus = graphql`
  mutation BrokerageCreditStatusUpdateMutation(
    $brokerageCreditId: ID!
    $status: ApprovableStatus!
  ) {
    updateBrokerageCreditStatus(
      brokerageCreditId: $brokerageCreditId,
      status: $status
    ) {
      brokerageCredit {
        ...BrokerageCreditUpdateForm_brokerageCredit
      }
    }
  }
`;
