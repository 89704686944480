/**
 * @flow
 * @relayHash 3784dbd53f3bfdaff7a469b5e9c23efc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
export type ContraBatchInput = {|
  name?: ?string,
  custodian?: ?Custodian,
|};
export type CreateCashTransferRequestInverseBatchMutationVariables = {|
  batchId: string,
  name?: ?string,
  initiateCashTransaction?: ?boolean,
  contraBatch?: ?ContraBatchInput,
|};
export type CreateCashTransferRequestInverseBatchMutationResponse = {|
  +createCashTransferRequestInverseBatch: ?{|
    +batch: ?{|
      +inverseBatch: ?{|
        +id: string,
        +name: string,
        +cashtransferrequestSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
      |},
      +id: string,
      +name: string,
      +cashtransferrequestSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateCashTransferRequestInverseBatchMutation = {|
  variables: CreateCashTransferRequestInverseBatchMutationVariables,
  response: CreateCashTransferRequestInverseBatchMutationResponse,
|};
*/


/*
mutation CreateCashTransferRequestInverseBatchMutation(
  $batchId: ID!
  $name: String
  $initiateCashTransaction: Boolean
  $contraBatch: ContraBatchInput
) {
  createCashTransferRequestInverseBatch(batchId: $batchId, name: $name, initiateCashTransaction: $initiateCashTransaction, contraBatch: $contraBatch) {
    batch {
      inverseBatch {
        id
        name
        cashtransferrequestSet {
          edges {
            node {
              id
            }
          }
        }
      }
      id
      name
      cashtransferrequestSet {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "batchId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "initiateCashTransaction",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contraBatch",
    "type": "ContraBatchInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cashtransferrequestSet",
  "storageKey": null,
  "args": null,
  "concreteType": "CashTransferRequestNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CashTransferRequestNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CashTransferRequestNode",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        }
      ]
    }
  ]
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCashTransferRequestInverseBatch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "batchId",
        "variableName": "batchId"
      },
      {
        "kind": "Variable",
        "name": "contraBatch",
        "variableName": "contraBatch"
      },
      {
        "kind": "Variable",
        "name": "initiateCashTransaction",
        "variableName": "initiateCashTransaction"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CreateCashTransferRequestInverseBatch",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "batch",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestBatchNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "inverseBatch",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestBatchNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCashTransferRequestInverseBatchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCashTransferRequestInverseBatchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCashTransferRequestInverseBatchMutation",
    "id": null,
    "text": "mutation CreateCashTransferRequestInverseBatchMutation(\n  $batchId: ID!\n  $name: String\n  $initiateCashTransaction: Boolean\n  $contraBatch: ContraBatchInput\n) {\n  createCashTransferRequestInverseBatch(batchId: $batchId, name: $name, initiateCashTransaction: $initiateCashTransaction, contraBatch: $contraBatch) {\n    batch {\n      inverseBatch {\n        id\n        name\n        cashtransferrequestSet {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n      id\n      name\n      cashtransferrequestSet {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'afc5af1ecb9ea2e280cf5a4c99ca26f0';
module.exports = node;
