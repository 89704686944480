

class Console {}

// eslint-disable-next-line no-console
Console.log = function log(...args) { console.log(...args); };
// eslint-disable-next-line no-console
Console.debug = function debug(...args) { console.debug(...args); };
// eslint-disable-next-line no-console
Console.info = function info(...args) { console.info(...args); };
// eslint-disable-next-line no-console
Console.error = function error(...args) { console.error(...args); };

export default Console;
