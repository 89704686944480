import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { commitMutation, graphql } from 'react-relay';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import { TableBody } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { useCommonStyles } from '../common/Styles';
import environment from '../../Environment';
import notiStack from '../../common/notiStack';
import Console from '../../common/log';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import QueryRendererProgress from '../common/QueryRendererProgress';
import createPositionTransfersOut from '../../mutations/CreatePositionTransfersOut';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';


function HoldingEntry(props) {
  const {
    holding,
    index,
    onUpdate,
  } = props;
  const [selected, setSelection] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [quantity, setQuantity] = React.useState(holding.quantity);

  return (
    <TableRow>
      <TableCell>{holding.portfolio.trackingVueName}</TableCell>
      <TableCell>{holding.contract.symbol}</TableCell>
      <TableCell align="right">
        <Grid container spacing={1} alignItems="center" justify="flex-end">
          {
            editing ? (
              <Grid item>
                <TextField
                  style={{ width: '40pt' }}
                  id="accountNumber"
                  type="number"
                  defaultValue={holding.quantity}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                    onUpdate && onUpdate(index, {
                      quantity: e.target.value,
                      selected,
                      portfolio: holding.portfolio,
                      contract: holding.contract,
                    });
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                    },
                  }}
                />
              </Grid>
            ) : (
              <Grid item>
                {quantity}
              </Grid>
            )
          }
          <Grid item>
            {
              editing ? (
                <IconButton
                  onClick={() => setEditing(false)}
                >
                  <DoneIcon fontSize="small" style={{ position: 'absolute' }} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => setEditing(true)}
                >
                  <EditIcon fontSize="small" style={{ position: 'absolute' }} />
                </IconButton>
              )
            }
          </Grid>
        </Grid>

      </TableCell>
      <TableCell>
        <Checkbox
          style={{ padding: '0px' }}
          checked={selected}
          value="allowExtraEmails"
          color="primary"
          size="small"
          disableRipple
          onChange={(e) => {
            setSelection(e.target.checked);
            onUpdate && onUpdate(index, {
              quantity,
              selected: e.target.checked,
              portfolio: holding.portfolio,
              contract: holding.contract,
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export default function PositionTransferOutForm(props) {
  const classes = useCommonStyles();
  const {
    transferRequest,
    onClose,
    onAdd,
  } = props;
  const [account, setAccount] = React.useState(null);
  const [selection, setSelection] = React.useState({});

  const onUpdate = (index, data) => {
    selection[index] = data;
    setSelection(selection);
  };

  const createTransfers = () => {
    if (!account) {
      notiStack.warning('Portfolio not selected');
      return;
    }
    const selections = Object.values(selection).filter(element => element.selected);
    const positionTransfers = selections.map(element => ({
      contractId: element.contract.id,
      portfolioId: element.portfolio.id,
      quantity: element.quantity ? Number(element.quantity) : 0,
    }));

    const variables = {
      accountId: account.id,
      positionTransfers,
    };

    commitMutation(environment, {
      mutation: createPositionTransfersOut,
      variables,
      onCompleted: (response, errors) => {
        const payload = response && response.createPositionTransfersOut;
        if (payload && payload.positionTransferRequests) {
          notiStack.success(
            `${payload.positionTransferRequests.length} Position transfers created`,
          );
          if (onClose) onClose();
          if (onAdd) onAdd();
        }
      },
      onError: (err) => {
        Console.error(err);
      },
    });
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <Typography variant="h6" gutterBottom>
              Position Transfer Out
            </Typography>
          </div>
          {
            transferRequest ? [`description: ${transferRequest.description}`,
              `symbol: ${transferRequest.symbol},  quantity: ${transferRequest.quantity}`] : [].map(
              x => (
                <div>
                  <Typography variant="caption" gutterBottom>
                    {x}
                  </Typography>
                </div>
              ),
            )
          }
        </Grid>
        <Grid item xs={12}>
          <PopupAccountSelectorField
            label="Account"
            fullWidth
            onSelect={inAccount => setAccount(inAccount)}
          />
        </Grid>
        {
          account && (
            <QueryRendererProgress
              componentClass={(innerProps) => {
                const { viewer } = innerProps;
                const { availableHoldings: holdings } = viewer.accounts.edges[0].node;

                return (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Table
                        size="small"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Portfolio</TableCell>
                            <TableCell>Contract</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell>&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            holdings.map(
                              (holding, i) => (
                                <HoldingEntry
                                  holding={holding}
                                  index={i}
                                  onUpdate={onUpdate}
                                />
                              ),
                            )
                          }
                        </TableBody>
                      </Table>
                    </Grid>
                  </React.Fragment>
                );
              }}
              queryClass={graphql`
              query PositionTransferOutFormQuery(
                $accountId: ID
              ) {
                viewer {
                  accounts (
                    id: $accountId
                  ) {
                    edges {
                      node {
                        availableHoldings {
                          contract {
                            id
                            name
                            symbol
                          }
                          quantity
                          portfolio {
                            id
                            trackingVueName
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
              initQueryParams={{
                accountId: account.id,
              }}
            />
          )
        }

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <AddButton
                onClick={createTransfers}
                buttonText="Transfer"
                disabled={!account}
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </Grid>
    </div>
  );
}
