import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import PositionTransferRequestList from './PositionTransferRequestList';
import BrokerPositionTransferList from './BrokerPositionTransferList';
import MsgRouter from '../../common/MsgRouter';


function PositionTransferMatchPane(props) {
  const [msgRouter] = React.useState(new MsgRouter());
  const { accountId, accountType } = props;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <PositionTransferRequestList
            accountId={accountId}
            msgRouter={msgRouter}
            accountType={accountType}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <BrokerPositionTransferList
            msgRouter={msgRouter}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(PositionTransferMatchPane);
