import React from 'react';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import TableFieldMeta from '../../common/TableFieldMeta';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';
import VerticalTable from '../common/VerticalTable';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ApproveButton, CloseButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import ApprovalList from '../approvals/ApprovalList';
import createApproval from '../../mutations/CreateApproval';
import updateCurrencyConversionRequestStatus from '../../mutations/UpdateCurrencyConversionRequestStatus';
import createCurrencyConversionRequest from '../../mutations/CreateCurrencyConversionRequest';
import mvMutationErrors from '../../common/errorReport';
import ApprovableStatus from '../globals/ApprovableStatus';


function CurrencyConversionFormData(props) {
  const {
    viewer, accountId, baseCurrency, defaultQuantity, onConvertSuccess, 
  } = props;
  const edge = viewer.currencyConversionRequests.edges[0];
  const currencyConversionRequest = edge && edge.node;
  const classes = useCommonStyles();
  const [request, setRequest] = React.useState(currencyConversionRequest);
  const [quantity, setQuantity] = React.useState(defaultQuantity);

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Convert Currency"
        />
        { request ? (
          <>
            <Grid item xs={12}>
              <VerticalTable
                columnMeta={[
                  ['Request ID', TableFieldMeta.localId.accessor],
                  ['Quantity', node => -1 * node.quantity],
                  ['From Currency', 'baseCurrency'],
                  ['To Currency', 'quoteCurrency'],
                  ['Status', node => (
                    <ApprovableStatus approvable={node} />
                  )],
                ]}
                dataNode={request}
                showHeadings={false}
                valueAlignment="left"
              />
            </Grid>

            <DrawerGridItemBottomButtonContainer>
              <Grid item>
                <CloseButton
                  buttonText="Cancel"
                  color="secondary"
                  disabled={request.status === 'CANCELLED'}
                  onClickPromise={() => doCommitPromise(
                    updateCurrencyConversionRequestStatus,
                    {
                      currencyConversionRequestId: request.id,
                      status: 'CANCELLED',
                    },
                    () => (onConvertSuccess && onConvertSuccess()),
                  )}
                />
              </Grid>

              <Grid item>
                <ApproveButton
                  buttonText="Convert"
                  disabled={request.status === 'APPROVED' || !request.hasCompleteApprovals}
                  onClickPromise={() => doCommitPromise(
                    updateCurrencyConversionRequestStatus,
                    {
                      currencyConversionRequestId: request.id,
                      status: 'APPROVED',
                    },
                    () => (onConvertSuccess && onConvertSuccess()),
                  )}
                />
              </Grid>
            </DrawerGridItemBottomButtonContainer>

            <Grid item xs={12}>
              <ApprovalList approvals={request.approvals.edges} />
            </Grid>
            <DrawerGridItemBottomButtonContainer>
              <Grid item>
                <ApproveButton
                  buttonText="Approve"
                  onClickPromise={() => doCommitPromise(
                    createApproval,
                    { approvableId: request.id },
                    (response) => {
                      if (!mvMutationErrors(response, 'createApproval')) {
                        const approvalEdges = [
                          ...request.approvals.edges,
                          { node: response.createApproval.approval },
                        ];
                        const { hasCompleteApprovals } = response.createApproval;
                        const newRequest = {
                          ...request,
                          approvals: { edges: approvalEdges },
                          hasCompleteApprovals,
                        };
                        setRequest(newRequest);
                      }
                    },
                  )}
                />
              </Grid>
            </DrawerGridItemBottomButtonContainer>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.fromControlDrawer}
                id="quantity"
                label="Quantity"
                type="number"
                onChange={(e) => { setQuantity(e.target.value); }}
                defaultValue={defaultQuantity}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{ baseCurrency }</InputAdornment>,
                }}
              />
            </Grid>
            <DrawerGridItemBottomButtonContainer>
              <Grid item>
                <ApproveButton
                  onClickPromise={() => doCommitPromise(
                    createCurrencyConversionRequest,
                    {
                      accountId,
                      baseCurrency,
                      quoteCurrency: 'AUD',
                      quantity: -1 * quantity, 
                    },
                    (response) => {
                      if (!mvMutationErrors(response, 'createCurrencyConversionRequest')) {
                        const updatedRequest = (
                          response.createCurrencyConversionRequest.currencyConversionRequest
                        );
                        setRequest(updatedRequest);
                      }
                    },
                  )}
                >
                  Convert
                </ApproveButton>
              </Grid>
            </DrawerGridItemBottomButtonContainer>
          </>
        )}
      </DrawerGridItemContainer>
    </>
  );
}


const query = graphql`
  query CurrencyConversionFormQuery(
    $accountId: ID!
    $baseCurrency: String
  ) {
    viewer {
      ...CurrencyConversionFormPending_viewer
      id
    }
  }
`;

const container = createFragmentContainer(
  CurrencyConversionFormData,
  graphql`
    fragment CurrencyConversionFormPending_viewer on Query {
      currencyConversionRequests(
        accountId: $accountId,
        baseCurrency: $baseCurrency,
        quoteCurrency: "AUD",
        status: "PENDING"
      ) {
        count
        edges {
          node {
            id
            baseCurrency
            quoteCurrency
            quantity
            status
            approvals {
              edges {
                node {
                  id
                  approvedBy {
                    id
                    username
                  }
                  approvedAt
                }
              }
            }
            hasCompleteApprovals
          }
        }
      }
    }
  `,
);

const modifiedComp = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({
    viewer,
    accountId: outerProps.accountId,
    baseCurrency: outerProps.baseCurrency,
    defaultQuantity: outerProps.defaultQuantity,
    onConvertSuccess: outerProps.onConvertSuccess,
  }),
  ({ accountId, baseCurrency }) => ({ accountId, baseCurrency }),
);


function CurrencyConversionForm(props) {
  const {
    accountId, currency, quantity, onConvertSuccess, 
  } = props;
  const CurrencyConversionFormComp = modifiedComp;
  const classes = useCommonStyles();
  return (
    <div className={classes.drawerRoot}>
      <CurrencyConversionFormComp
        accountId={accountId}
        baseCurrency={currency}
        defaultQuantity={quantity}
        onConvertSuccess={onConvertSuccess}
      />
    </div>
  );
}

export default CurrencyConversionForm;
