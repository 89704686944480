import * as React from 'react';
import Link from '@material-ui/core/Link';
import { fromGlobalId } from '../../../helpers/ID';

const AdviserLink = (props) => {
  const { adviser } = props;
  if (!adviser) return null;
  return (
    <Link href={`/adviser/${fromGlobalId(adviser.id)[1]}`}>
      {`${adviser.firstName} ${adviser.lastName}`}
    </Link>
  );
};

export default AdviserLink;
