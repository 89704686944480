

class MsgRouter {
  constructor() {
    this.subscribers = [];
    this.state = {};

    this.stateLiteners = new Set();
  }

  subscribe = (client) => {
    this.subscribers.push(client);
  };

  addStateListener = (listenFunc) => {
    this.stateLiteners.add(listenFunc);
  };

  removeStateListener = (listenFunc) => {
    this.stateLiteners.delete(listenFunc);
  };

  unsubscribe = (client) => {
    this.subscribers.map(
      (subscriber, i) => {
        if (client === subscriber) {
          this.subscribers.splice(i, 1);
        }
        return null;
      },
    );
  };

  postMsg = (client, msg) => {
    this.subscribers.map(
      (subscriber, i) => {
        if (client !== subscriber) {
          subscriber.onPostMsg(msg, client);
        }
        return null;
      },
    );
  };

  doPublishState = (client, state) => {
    this.subscribers.map(
      (subscriber, i) => {
        if (client !== subscriber) {
          if (subscriber.onPublishState) {
            subscriber.onPublishState(client, state);
          }
        }
        return null;
      },
    );

    for (const stateListener of this.stateLiteners) {
      stateListener(client, state);
    }
  };

  publishState = (client, state) => {
    this.state = { ...this.state, ...state };
    this.doPublishState(client, this.state);
  };

  updateState = (state) => { // Do not publish
    this.state = { ...this.state, ...state };
  };

  getState = () => (this.state);
}

export default MsgRouter;
