/**
 * @flow
 * @relayHash 710188a01a651fed3700f12e83f894fa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VueResearchList_viewer$ref = any;
export type VueResearchListQueryVariables = {|
  vueId?: ?string
|};
export type VueResearchListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VueResearchList_viewer$ref
  |}
|};
export type VueResearchListQuery = {|
  variables: VueResearchListQueryVariables,
  response: VueResearchListQueryResponse,
|};
*/


/*
query VueResearchListQuery(
  $vueId: ID
) {
  viewer {
    ...VueResearchList_viewer_l0Q9D
    id
  }
}

fragment VueResearchList_viewer_l0Q9D on Query {
  vues(id: $vueId) {
    edges {
      node {
        id
        vueresearchSet(orderBy: "-createdAt") {
          count
          edges {
            node {
              id
              title
              researchPdfUrl
              createdAt
              updatedAt
              createdBy {
                id
                username
              }
            }
          }
        }
        latestDraft {
          id
          vueresearchSet(orderBy: "-createdAt") {
            count
            edges {
              node {
                id
                title
                researchPdfUrl
                createdAt
                updatedAt
                createdBy {
                  id
                  username
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vueresearchSet",
  "storageKey": "vueresearchSet(orderBy:\"-createdAt\")",
  "args": [
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "-createdAt"
    }
  ],
  "concreteType": "VueResearchNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "VueResearchNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "VueResearchNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "researchPdfUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "createdBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "username",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueResearchListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VueResearchList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "vueId",
                "variableName": "vueId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueResearchListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "vueId"
              }
            ],
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "latestDraft",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VueResearchListQuery",
    "id": null,
    "text": "query VueResearchListQuery(\n  $vueId: ID\n) {\n  viewer {\n    ...VueResearchList_viewer_l0Q9D\n    id\n  }\n}\n\nfragment VueResearchList_viewer_l0Q9D on Query {\n  vues(id: $vueId) {\n    edges {\n      node {\n        id\n        vueresearchSet(orderBy: \"-createdAt\") {\n          count\n          edges {\n            node {\n              id\n              title\n              researchPdfUrl\n              createdAt\n              updatedAt\n              createdBy {\n                id\n                username\n              }\n            }\n          }\n        }\n        latestDraft {\n          id\n          vueresearchSet(orderBy: \"-createdAt\") {\n            count\n            edges {\n              node {\n                id\n                title\n                researchPdfUrl\n                createdAt\n                updatedAt\n                createdBy {\n                  id\n                  username\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd43b607d57a5a100790e5d1dab8fb1db';
module.exports = node;
