import Grid from '@material-ui/core/Grid';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { commitMutation, graphql } from 'react-relay';
import Link from '@material-ui/core/Link';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import TenantFeeTemplateDlg from './TenantFeeTemplateDlg';
import VerticalTable from '../common/VerticalTable';
import PricingEstimator from './PricingEstimator';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import environment from '../../Environment';
import updateTenantFeeTemplateMutation from '../../mutations/UpdateTenantFeeTemplate';
import { fromGlobalId } from '../../helpers/ID';
import MoreButton from '../common/buttons/MoreButton';
import TableButton from '../common/buttons/TableButton';
import { deleteTenantExchangeFeeTemplate } from '../../mutations/TenantExchangeFeeTemplate';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';
import { doCommitPromise } from '../../common/commit';
import TenantExchangeFeeTemplateForm from './TenantExchangeFeeTemplateForm';
import PricingContractDetails from './PricingContractDetails';


function Pricing(props) {
  const { tenant } = props;
  const classes = useCommonStyles();
  const [feeTemplateDlgShown, setFeeTemplateDlgShown] = React.useState(false);
  const [tenantExchangeFeeTemplateShown, setTenantExchangeFeeTemplateShown] = React.useState(false);
  const [infoContract, setInfoContract] = React.useState(null);
  const updateFeeTemplate = (feeTemplate) => {
    commitMutation(
      environment,
      {
        variables: {
          tenantId: tenant.id,
          feeTemplateId: feeTemplate ? feeTemplate.id : null,
        },
        mutation: updateTenantFeeTemplateMutation,
        onCompleted: (response, errors) => {
          if (!errors) {
            setFeeTemplateDlgShown(false);
          }
        },
      },
    );
  };
  
  const pricingDetails = [
    ['Template', node => (
      <Grid container spacing={1} justify="flex-end">
        { node.feeTemplate ? (
          <React.Fragment>
            <Grid item>
              <Link href={`/fee-template/${fromGlobalId(node.feeTemplate.id)[1]}`}>
                {node.feeTemplate.name}
              </Link>
            </Grid>
            <Grid item>
              <MoreButton
                onClick={() => setFeeTemplateDlgShown(true)}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item>
            <TableButton
              color="primary"
              onClick={() => setFeeTemplateDlgShown(true)}
            >
              Set
            </TableButton>
          </Grid>
        )}
      </Grid>
    )],
  ];

  
  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={3}>
        
        {/*Pricing*/}
        <Grid item xs={12} md={5}>
          <Paper className={classes.tableHolderRoot}>

            <Dialog
              open={feeTemplateDlgShown}
              onClose={() => setFeeTemplateDlgShown(false)}
              aria-labelledby="form-dialog-title"
              className={classes.dialogSingleInput}
              maxWidth="sm"
              fullWidth
            >
              <TenantFeeTemplateDlg
                feeTemplate={tenant.feeTemplate}
                onClose={() => setFeeTemplateDlgShown(false)}
                onFeeTemplate={feeTemplate => updateFeeTemplate(feeTemplate)}
              />
            </Dialog>

            <div className={classes.widgetHeadingContainer}>
              <Typography
                component="h6"
                variant="subtitle2"
                gutterBottom
              >
                Pricing
              </Typography>
            </div>

            <VerticalTable
              columnMeta={pricingDetails}
              dataNode={tenant}
              showHeadings={false}
            />
          </Paper>
        </Grid>

        <Drawer
          anchor="right"
          onClose={() => setTenantExchangeFeeTemplateShown(false)}
          open={tenantExchangeFeeTemplateShown}
        >
          <TenantExchangeFeeTemplateForm
            onClose={() => setTenantExchangeFeeTemplateShown(false)}
            tenant={tenant}
          />
        </Drawer>


        <DataTableWidget
          xs={12}
          md={7}
          rightTopComp={(
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={() => setTenantExchangeFeeTemplateShown(true)}
            >
              <Add />
            </IconButton>
          )}
          title="Exchange Templates"
          columnMeta={[
            TableFieldMeta.linkField(node => node.feeTemplate.id, 'fee-template', 'ID'),
            TableFieldMeta.field(node => node.exchange.symbol, 'Exchange'),
            TableFieldMeta.field(node => node.feeTemplate.name, 'Fee Template'),
            TableFieldMeta.fieldCell('del', '', node => (
              <TableButton
                color="secondary"
                onClickPromise={() => doCommitPromise(
                  deleteTenantExchangeFeeTemplate,
                  {
                    tenantExchangeFeeTemplateId: node.value.id,
                  },
                )}
              >
                Delete
              </TableButton>
            )),
          ]}
          dataNodes={tenant.tenantexchangefeetemplateSet.edges.map(x => x.node)}
        />

        <Grid item xs={12}>
          <Paper className={classes.tableHolderRoot}>
            <div className={classes.widgetHeadingContainer}>
              <Typography
                component="h6"
                variant="subtitle2"
                gutterBottom
              >
                Estimator
              </Typography>
            </div>

            <div className={classes.widgetHeadingContainer}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <div style={{ padding: 10 }}>
                    <PricingEstimator
                      tenant={tenant}
                      onSetContract={inContract => setInfoContract(inContract)}
                    />
                  </div>
                </Grid>
                <PricingContractDetails
                  contractId={infoContract ? infoContract.id : 'Q29udHJhY3ROb2RlOi0xCg=='}
                />
              </Grid>

            </div>

          </Paper>
        </Grid>

      </Grid>
    </div>
  );
}

const query = graphql`
  query PricingQuery(
    $tenantId: ID!
  ){
    viewer {
      tenants(
        id: $tenantId
      ){
        edges {
          node {
            id
            name
            
            feeTemplate {
              id
              name
            }
            
            tenantexchangefeetemplateSet {
              edges {
                node {
                  id
                  createdAt
                  exchange {
                    symbol
                  }
                  feeTemplate {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withRouter(createQueryRendererProgress(
  query,
  Pricing,
  (viewer, outProps) => ({ tenant: viewer.tenants.edges[0].node, viewer }),
  props => ({ tenantId: props.tenantId }),
));
