import { graphql } from 'react-relay';

// language=GraphQL
const createRangeReconciliation = graphql`
  mutation CreateRangeReconciliationMutation (
    $fromDate: Date!
    $toDate: Date!
  ) {
    createRangeReconciliation(
      fromDate: $fromDate
      toDate: $toDate
    ){
      rangeReconciliation {
        createdAt
        updatedAt
        excelFile
        excelFileName
        excelFileUrl
        fromDate
        toDate
        ibHash
        mvHash 
        noOfDays
        
        ibrangereconciliationendbalanceSet {
          edges {
            node {
              id
              quantity
              currency
            }
          }
        }
        
        ibrangereconciliationdeltaSet {
          edges {
            node {
              id 
              quantity
              currency
            }
          }
        }
        
      }
    }
  }
`;

export default createRangeReconciliation;
