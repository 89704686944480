import React from 'react';
import PropTypes from 'prop-types';
import AccountSelectionDlg from './dialogs/AccountSelectionDlg';
import PopupEntitySelectorField from './PopupEntitySelectorField';
import { toLocal } from '../../helpers/ID';

function PopupAccountSelectorField(props) {
  const {
    children,
    caption,
    ...otherProps
  } = props;

  const accountDescriber = (account) => {
    if (!account) return '';
    let accountType = '';
    if (account.tenantaccount) {
      accountType = 'Tenant Acc';
    }

    if (account.clientaccount) {
      accountType = 'Client Acc';
    }

    return `${accountType}: ${toLocal(account.id)}`;
  };

  return (
    <PopupEntitySelectorField
      {...otherProps}
      caption={caption}
      searchDlgComp={AccountSelectionDlg}
      helperTextDescriber={account => (account ? `${account.name}` : 'Select Account...')}
      valueDescriber={account => accountDescriber(account)}
    />
  );
}

PopupAccountSelectorField.propTypes = {
  caption: PropTypes.string,
};

PopupAccountSelectorField.defaultProps = {
  caption: 'Account',
};

export default PopupAccountSelectorField;
