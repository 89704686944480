import * as React from 'react';
import Link from '@material-ui/core/Link';
import { fromGlobalId } from '../../../helpers/ID';

const VueLink = (props) => {
  const { vue } = props;
  return (
    <Link href={`/vue/${fromGlobalId(vue.id)[1]}`}>
      {vue.name}
    </Link>
  );
};

export default VueLink;
