import { graphql } from 'react-relay';

// language=GraphQL
const createCashTransferRequestBatch = graphql`
  mutation CreateCashTransferRequestBatchMutation (
    $cashTransferRequestIds: [ID]!
    $name: String
  ) {
    createCashTransferRequestBatch(
      cashTransferRequestIds: $cashTransferRequestIds
      name: $name
    ) {
      batch {
        id
        cashtransferrequestSet {
          edges {
            node {
              id
              batch {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default createCashTransferRequestBatch;
