// import { useCommonStyles } from '../Styles';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function DrawerGridItemParagraph(props) {
  const [sizeProps] = extractKeys(props, muiGridSizes);
  const {
    children,
  } = props;
  return (
    <Grid item {...sizeProps}>
      <Typography variant="caption">
        {children}
      </Typography>
    </Grid>
  );
}

export default DrawerGridItemParagraph;
