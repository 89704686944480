import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ProcessForm from './ProcessForm';
import BrokerActivityDetails from './BrokerActivityDetails';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import BrokerActivityActions from './BrokerActivityActions';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';


function DividendAccrualList(props) {
  const {
    viewer,
    relay,
    tableRenderer,
    columns: propColumns,
    onRowSelect,
    msgRouter,
    postExDateColumns,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columnsLocal = [
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    BrokerActivityTableFieldMeta.conId,
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.field('description', 'Description', { width: 250 }),
    BrokerActivityTableFieldMeta.symbol,
    BrokerActivityTableFieldMeta.quantity,
    TableFieldMeta.field('fxRateToBase', 'Fx Rate To Base', { align: 'right' }),
    TableFieldMeta.field('assetCategory', 'Asset Category'),
    TableFieldMeta.field('listingExchange', 'Listing Exchange'),
    TableFieldMeta.field('multiplier', 'Multiplier'),
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.field('date', 'Date'),
    TableFieldMeta.field('exDate', 'Ex Date'),
    ...postExDateColumns,
    TableFieldMeta.field('payDate', 'Pay Date'),
    TableFieldMeta.field('tax', 'Tax', { align: 'right' }),
    TableFieldMeta.field('fee', 'Fee', { align: 'right' }),
    TableFieldMeta.field('grossRate', 'Gross Rate', { align: 'right' }),
    TableFieldMeta.field('grossAmount', 'Gross Amount', { align: 'right' }),
    TableFieldMeta.field('netAmount', 'Net Amount', { align: 'right' }),
    TableFieldMeta.field('code', 'Code', { width: 50 }),
    {
      id: 'actions',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={(be) => {
            setBrokerActivity(be);
            setDrawerOpen(true);
          }}
          onShowDetails={(be) => {
            setBrokerActivity(be);
            setDetailsOpen(true);
          }}
        />
      ),
    },
  ];

  const columns = propColumns || columnsLocal;
  const [brokerActivity, setBrokerActivity] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [defaultIbConID, setDefaultIbConID] = React.useState(filter.params.ibConId);
  const [reportDate, setReportDate] = React.useState(filter.params.reportDate);

  React.useEffect(() => {
    const onState = (client, state) => {
      const { divAccrualConId, divAccrualReportDate } = state;
      const updates = {};
      if (divAccrualConId) {
        setDefaultIbConID(divAccrualConId);
        updates.ibConId = divAccrualConId;
      }
      if (divAccrualReportDate) {
        setReportDate(divAccrualReportDate);
        updates.reportDate = divAccrualReportDate;
      }
      if (Object.keys(updates).length > 0) {
        filter.updateVars(updates);
      }
    };
    if (msgRouter) msgRouter.addStateListener(onState);
    return function cleanup() {
      if (msgRouter) msgRouter.removeStateListener(onState);
    };
  }, []);

  return (
    <div className={classes.innerTabRoot}>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <ProcessForm brokerActivity={brokerActivity} close={() => setDrawerOpen(false)} />
        </div>
      </Drawer>

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails brokerActivity={brokerActivity} />
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="con-id-filter"
          label="Con ID"
          type="number"
          key={defaultIbConID}
          defaultValue={defaultIbConID}
          onChange={(e) => {
            const newConId = (e.target.value && Number(e.target.value)) || null;
            filter.updateVar('ibConId', newConId);
            if (msgRouter) msgRouter.updateState({ divAccrualConId: newConId });
          }}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          defaultValue={filter.params.symbol}
          onChange={e => filter.updateVar('symbol', e.target.value)}
          halfWidth
        />

        <InnerTabFilterSelectField
          id="symbol-filter"
          enumType="Currency"
          label="Currency"
          halfWidth
          value={filter.params.currency}
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterGridItemDateField
          value={reportDate}
          onChange={(value) => {
            filter.updateVar('reportDate', value);
            setReportDate(value);
            if (msgRouter) msgRouter.updateState({ divAccrualReportDate: value });
          }}
          label="Report Date"
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            tableRender={tableRenderer}
            getDataNode={() => (viewer ? viewer.ibDividendAccruals : null)}
            onRowSelectInfo={onRowSelect ? (dataRow => (
              {
                selectEnabled: true,
                rowKey: dataRow.value.id,
                callback: onRowSelect,
              }
            )) : null}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

DividendAccrualList.propTypes = {
  postExDateColumns: PropTypes.arrayOf(PropTypes.object),
};

DividendAccrualList.defaultProps = {
  postExDateColumns: [],
};


const query = graphql`
  query DividendAccrualListQuery (
    $first: Int
    $offset: Int
    $search: String
    $symbol: String
    $currency: String
    $ibConId: Int
    $reportDate: Date
  ) {
    viewer {
      ...DividendAccrualList_viewer
      @arguments (
        first: $first,
        offset: $offset
        search: $search
        symbol: $symbol
        currency: $currency
        ibConId: $ibConId
        reportDate: $reportDate
      )
    }
  }
`;


const container = createRefetchContainer(
  DividendAccrualList,
  {
    viewer: graphql`
      fragment DividendAccrualList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        search: {type: String}
        symbol: {type: String}
        currency: {type: String}
        ibConId: {type: Int}
        reportDate: {type: Date}
      ) {
        ibDividendAccruals(
          first: $first
          offset: $offset
          search: $search
          symbol: $symbol
          currency: $currency
          ibConId: $ibConId
          reportDate: $reportDate
        ) {
          edges {
            node {
              ...IBDividendAccrual_all @relay(mask: false)
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ rowsPerPage, ibConId }) => ({
    search: null,
    symbol: null,
    ibConId: ibConId || null,
    currency: null,
    reportDate: null,
    ...(rowsPerPage ? { first: rowsPerPage } : {}),
  }),
  {
    debounceFields: ['search', 'symbol', 'ibConId'],
  },
));
