import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export default function DrawerSubGroupHeading(props) {
  const { children } = props;
  return (
    <Typography variant="subtitle2">
      {children}
    </Typography>
  );
}
