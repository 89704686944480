import { graphql } from 'react-relay';

const createDepositRequestMutation = graphql`
  mutation CreateDepositRequestMutation (
      $accountId: ID!
      $amount: Float!
      $currency: String!
      $custodian: Custodian
  ) {
      createDepositRequest(
          accountId: $accountId,
          amount: $amount,
          currency: $currency
          custodian: $custodian
      ) {
          cashTransferRequest {
              id
              amount
              currency
              createdAt
              status
              blocked
              account {
                  id
                  name
              }
          }
      }
  }
`;

export default createDepositRequestMutation;
