import { graphql } from 'react-relay';

const testVueCommunicationMutation = graphql`
  mutation TestVueCommunicationMutation(
    $communicationId: ID!
  ) {
    testVueCommunication(
      communicationId: $communicationId
    ){
      vueCommunication {
        id
        published
        publishedAt
        message
        heading
      }
      message
    }
  }
`;
export default testVueCommunicationMutation;
