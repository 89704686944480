/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderAlgo = "TWAP" | "VWAP" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StockOrderAlgorithm_all$ref: FragmentReference;
declare export opaque type StockOrderAlgorithm_all$fragmentType: StockOrderAlgorithm_all$ref;
export type StockOrderAlgorithm_all = {|
  +algoParams: ?{|
    +algo: OrderAlgo,
    +orderalgoparamstwap: ?{|
      +strategyType: string,
      +startTime: ?any,
      +endTime: ?any,
      +allowPastEndTime: boolean,
    |},
    +orderalgoparamsvwap: ?{|
      +id: string,
      +maxVolumeRatio: ?number,
      +startTime: ?any,
      +endTime: ?any,
      +allowPastEndTime: boolean,
      +neverTakeLiquidity: boolean,
      +speedup: ?boolean,
    |},
  |},
  +$refType: StockOrderAlgorithm_all$ref,
|};
export type StockOrderAlgorithm_all$data = StockOrderAlgorithm_all;
export type StockOrderAlgorithm_all$key = {
  +$data?: StockOrderAlgorithm_all$data,
  +$fragmentRefs: StockOrderAlgorithm_all$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "startTime",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endTime",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "allowPastEndTime",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StockOrderAlgorithm_all",
  "type": "BrokerOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "algoParams",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderAlgoParamsNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "algo",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orderalgoparamstwap",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderAlgoParamsTWAPNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "strategyType",
              "args": null,
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "orderalgoparamsvwap",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderAlgoParamsVWAPNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "maxVolumeRatio",
              "args": null,
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "neverTakeLiquidity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "speedup",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e2f72c78cab44f5ec230855bd05e19de';
module.exports = node;
