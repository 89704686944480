/**
 * @flow
 * @relayHash 3072cd64f47d1819f34364d58d2feccc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeComponentDeleteMutationVariables = {|
  feeComponentId: string
|};
export type FeeComponentDeleteMutationResponse = {|
  +deleteFeeComponent: ?{|
    +fee: ?{|
      +id: string,
      +feeComponents: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |}
  |}
|};
export type FeeComponentDeleteMutation = {|
  variables: FeeComponentDeleteMutationVariables,
  response: FeeComponentDeleteMutationResponse,
|};
*/


/*
mutation FeeComponentDeleteMutation(
  $feeComponentId: ID!
) {
  deleteFeeComponent(feeComponentId: $feeComponentId) {
    fee {
      id
      feeComponents {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feeComponentId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteFeeComponent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "feeComponentId",
        "variableName": "feeComponentId"
      }
    ],
    "concreteType": "DeleteFeeComponent",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fee",
        "storageKey": null,
        "args": null,
        "concreteType": "FeeNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeComponents",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeComponentNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeComponentNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeComponentNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeComponentDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeComponentDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeComponentDeleteMutation",
    "id": null,
    "text": "mutation FeeComponentDeleteMutation(\n  $feeComponentId: ID!\n) {\n  deleteFeeComponent(feeComponentId: $feeComponentId) {\n    fee {\n      id\n      feeComponents {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b73cea815a8ca099cf8f70ac0b4b443d';
module.exports = node;
