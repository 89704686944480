import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { commitMutation } from 'react-relay';
import environment from '../../Environment';
import generateTradeInvoiceMutation from '../../mutations/GenerateTradeInvoiceMutation';
import emailTradeInvoiceMutation from '../../mutations/EmailTradeInvoiceMutation';
import TableButton from '../common/buttons/TableButton';
import TableStatus from '../common/TableStatus';
import { doCommitPromise } from '../../common/commit';
import mvMutationErrors from '../../common/errorReport';
import notiStack from '../../common/notiStack';

export const doGenerateInvoice = (portfolioOrderId, stockOrderId) => {
  const promise = new Promise((resolve, reject) => {
    const variables = {
      portfolioOrderId,
      stockOrderId: portfolioOrderId ? null : stockOrderId,
    };

    commitMutation(environment, {
      mutation: generateTradeInvoiceMutation,
      variables,
      onCompleted: (response) => {
        resolve(response);
      },
      onError: (error) => {
        reject(error);
      },
      updater: (store, data) => {
        const payload = store.getRootField('generateTradeInvoice');
        if (!payload) return;
        const newTradeInvoice = payload.getLinkedRecord('tradeInvoice');
        if (stockOrderId) {
          const cachedStockOrder = store.get(stockOrderId);
          cachedStockOrder.setLinkedRecord(newTradeInvoice, 'tradeInvoice');
        } else {
          const cachedPortfolioOrder = store.get(portfolioOrderId);
          cachedPortfolioOrder.setLinkedRecord(newTradeInvoice, 'tradeInvoice');
        }
      },
    });
  });

  return promise;
};


export const viewInvoice = node => () => {
  window.open(node.tradeInvoice.pdfUrl, '_blank');
};


export default function renderPortfolioOrderInvoiceActions(node) {
  const invoiceGenerated = (node.tradeInvoice !== null) && (node.tradeInvoice !== undefined);
  const account = node.portfolio.account.clientaccount;
  const forShareSalesAccount = account ? account.accountType === 'SSD' : false;
  const hasError = invoiceGenerated && (node.tradeInvoice.state === 'ERR');
  const viewButtonStyle = {
    color: hasError ? 'secondary' : 'default',
  };

  const itemKey = `${node.id}-${node.updatedAt}`;
  const invoiceItemKey = node.tradeInvoice ? `${node.tradeInvoice.id}-${node.tradeInvoice.updatedAt}` : itemKey;
  let invoiceType = 'Portfolio';
  if (forShareSalesAccount) {
    invoiceType = 'SSD';
  }

  return (
    <Grid container spacing={1} justify="center">
      {
        !invoiceGenerated && (
          <>
            <Grid item style={{ 'padding-top': '6px' }}>
              <TableStatus color="primary">
                { invoiceType }
              </TableStatus>
            </Grid>

            { !forShareSalesAccount && (
              <Grid item>
                <TableButton
                  color="primary"
                  onClickPromise={() => doGenerateInvoice(node.portfolioOrder.id, null)}
                >
                  Generate
                </TableButton>
              </Grid>
            )}
          </>
        )
      }

      {
        invoiceGenerated && (
          <>
            <Grid item style={{ 'padding-top': '6px' }}>
              <TableStatus color="primary">
                { invoiceType }
              </TableStatus>
            </Grid>

            <Grid item>
              <TableButton
                key={invoiceItemKey}
                onClick={viewInvoice(node)}
                disabled={hasError}
                {...viewButtonStyle}
              >
                View
              </TableButton>
            </Grid>

            { !forShareSalesAccount && (
              <>
                <Grid item>
                  <TableButton
                    key={itemKey}
                    color="primary"
                    onClickPromise={() => doGenerateInvoice(node.id, null)}
                  >
                    Regenerate
                  </TableButton>
                </Grid>

                <Grid item>
                  <TableButton
                    key={invoiceItemKey}
                    color="primary"
                    onClickPromise={() => doCommitPromise(
                      emailTradeInvoiceMutation,
                      {
                        tradeInvoiceId: node.tradeInvoice.id,
                      },
                      (response) => {
                        if (!mvMutationErrors(response, 'emailTradeInvoice')) {
                          notiStack.info('Email sent');
                        }
                      },
                      () => null,
                    )}
                    disabled={hasError}
                  >
                    e-mail
                  </TableButton>
                </Grid>
              </>
            )}
          </>
        )
      }

    </Grid>
  );
}
