/**
 * @flow
 * @relayHash f8f921b99826a9039cea2f8969797219
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VBDividendList_viewer$ref = any;
export type VBDividendListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  reportDate?: ?any,
  processedAt_Isnull?: ?boolean,
|};
export type VBDividendListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VBDividendList_viewer$ref
  |}
|};
export type VBDividendListQuery = {|
  variables: VBDividendListQueryVariables,
  response: VBDividendListQueryResponse,
|};
*/


/*
query VBDividendListQuery(
  $first: Int
  $offset: Int
  $reportDate: Date
  $processedAt_Isnull: Boolean
) {
  viewer {
    ...VBDividendList_viewer_ITVbr
    id
  }
}

fragment VBDividendList_viewer_ITVbr on Query {
  vbDividends(first: $first, offset: $offset, reportDate: $reportDate, processedAt_Isnull: $processedAt_Isnull) {
    edges {
      node {
        id
        processedAt
        processedBy {
          username
          id
        }
        reinvestmentPrice
        exDate
        payDate
        ignored
        reportDate
        ibCorporateAction {
          id
          contract {
            id
            currency
          }
        }
        brokeractivityPtr {
          brokeractivitytransactionSet {
            edges {
              node {
                id
                account {
                  name
                  id
                }
                brokeractivitytransactioncontractdeltaSet {
                  edges {
                    node {
                      portfolio {
                        id
                      }
                      contract {
                        id
                        symbol
                      }
                      quantity
                      id
                    }
                  }
                }
                brokeractivitytransactioncashdeltaSet {
                  edges {
                    node {
                      currency
                      quantity
                      id
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reportDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "processedAt_Isnull",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "processedAt_Isnull",
    "variableName": "processedAt_Isnull"
  },
  {
    "kind": "Variable",
    "name": "reportDate",
    "variableName": "reportDate"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VBDividendListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VBDividendList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VBDividendListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vbDividends",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "VBDividendNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VBDividendNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VBDividendNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "processedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "processedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reinvestmentPrice",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "exDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "payDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ignored",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibCorporateAction",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBCorporateActionNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokeractivityPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "brokeractivitytransactionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "account",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AccountNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncontractdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "portfolio",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PortfolioNode",
                                                    "plural": false,
                                                    "selections": [
                                                      (v2/*: any*/)
                                                    ]
                                                  },
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "contract",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ContractNode",
                                                    "plural": false,
                                                    "selections": [
                                                      (v2/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "alias": null,
                                                        "name": "symbol",
                                                        "args": null,
                                                        "storageKey": null
                                                      }
                                                    ]
                                                  },
                                                  (v4/*: any*/),
                                                  (v2/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncashdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  (v4/*: any*/),
                                                  (v2/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VBDividendListQuery",
    "id": null,
    "text": "query VBDividendListQuery(\n  $first: Int\n  $offset: Int\n  $reportDate: Date\n  $processedAt_Isnull: Boolean\n) {\n  viewer {\n    ...VBDividendList_viewer_ITVbr\n    id\n  }\n}\n\nfragment VBDividendList_viewer_ITVbr on Query {\n  vbDividends(first: $first, offset: $offset, reportDate: $reportDate, processedAt_Isnull: $processedAt_Isnull) {\n    edges {\n      node {\n        id\n        processedAt\n        processedBy {\n          username\n          id\n        }\n        reinvestmentPrice\n        exDate\n        payDate\n        ignored\n        reportDate\n        ibCorporateAction {\n          id\n          contract {\n            id\n            currency\n          }\n        }\n        brokeractivityPtr {\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n                account {\n                  name\n                  id\n                }\n                brokeractivitytransactioncontractdeltaSet {\n                  edges {\n                    node {\n                      portfolio {\n                        id\n                      }\n                      contract {\n                        id\n                        symbol\n                      }\n                      quantity\n                      id\n                    }\n                  }\n                }\n                brokeractivitytransactioncashdeltaSet {\n                  edges {\n                    node {\n                      currency\n                      quantity\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79898859cc2ac19372805ce96b0b114f';
module.exports = node;
