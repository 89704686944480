import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { SaveAlt } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TableFieldMeta from '../../common/TableFieldMeta';
import TablePaginator from '../common/TablePaginator';
import MoreButton from '../common/buttons/MoreButton';
import VueResearchUpdateForm from './VueResearchUpdateForm';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import VueResearchAddForm from './VueResearchAddForm';
import BaseButton, { AddButton } from '../common/buttons/BaseButton';

function VueResearchList(props) {
  const classes = useCommonStyles();
  const {
    viewer, filter, relay,
  } = props;
  filter.setRelay(relay);

  const vue = viewer.vues.edges[0].node;
  const [updatingVueResearch, setUpdatingVueResearch] = React.useState(null);
  const [addVueResearchOpen, setAddVueResearchOpen] = React.useState(false);
  const [editVueDraft, setEditVueDraft] = React.useState(false);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('title', 'Title'),
    TableFieldMeta.fieldCell('research', 'Research',
      row => (
        row.value.researchPdfUrl && (
          <Link href={row.value.researchPdfUrl} target="_blank">
            <SaveAlt />
          </Link>
        )
      ),
      { align: 'center' }),
    TableFieldMeta.createdAt,
    TableFieldMeta.updatedAt,
    TableFieldMeta.fieldCell('update', '',
      row => (
        <MoreButton
          key={row.value}
          onClick={() => setUpdatingVueResearch(row.value)}
        />
      )),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={updatingVueResearch !== null}
        onClose={() => setUpdatingVueResearch(null)}
      >
        {
          updatingVueResearch && (
            <VueResearchUpdateForm
              vueResearch={updatingVueResearch}
              onClose={() => setUpdatingVueResearch(null)}
            />
          )
        }
      </Drawer>

      <Drawer
        anchor="right"
        open={addVueResearchOpen}
        onClose={() => setAddVueResearchOpen(false)}
      >
        <VueResearchAddForm
          vue={vue.latestDraft ? vue.latestDraft : vue}
          onClose={() => setAddVueResearchOpen(false)}
          onAddVueResearch={() => relay.refetch()}
        />
      </Drawer>

      <Grid item xs={12}>
        <TablePaginator
          filter={filter}
          columns={columns}
          getDataNode={() => (editVueDraft ? vue.latestDraft.vueresearchSet : vue.vueresearchSet)}
        />
      </Grid>
      <BottomGridContainer>
        {
          vue.latestDraft && !editVueDraft && (
            <Grid item>
              <BaseButton
                color="primary"
                onClick={() => setEditVueDraft(true)}
                buttonText="View Draft"
              />
            </Grid>
          )
        }

        {
          editVueDraft && (
            <>
              <Grid item>
                <BaseButton
                  color="primary"
                  onClick={() => setEditVueDraft(false)}
                  buttonText="View Published"
                />
              </Grid>

              <Grid item>
                <AddButton
                  onClick={() => setAddVueResearchOpen(true)}
                  buttonText="Vue Research"
                />
              </Grid>
            </>
          )
        }
      </BottomGridContainer>
    </div>
  );
}

const query = graphql`
  query VueResearchListQuery (
    $vueId: ID,
  ) {
    viewer {
      ...VueResearchList_viewer
      @arguments(
        vueId: $vueId
      )
    }
  }
`;

const container = createRefetchContainer(
  VueResearchList,
  {
    viewer: graphql`
      fragment VueResearchList_viewer on Query
      @argumentDefinitions(
        vueId: {type: ID}
      ) {
        vues(
          id: $vueId
        ) {
          edges {
            node {
              id
              vueresearchSet (
                orderBy: "-createdAt"
              ) {
                count
                edges {
                  node {
                    id
                    title
                    researchPdfUrl
                    createdAt
                    updatedAt
                    createdBy {
                      id
                      username
                    }
                  }
                }
              }
              latestDraft {
                id
                vueresearchSet (
                  orderBy: "-createdAt"
                ) {
                  count
                  edges {
                    node {
                      id
                      title
                      researchPdfUrl
                      createdAt
                      updatedAt
                      createdBy {
                        id
                        username
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);


export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  () => ({}),
  props => ({ vueId: props.vueId }),
));
