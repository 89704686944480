/**
 * @flow
 * @relayHash ce44c17533e1939475cbee7101aa3de2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailTradeInvoiceMutationVariables = {|
  tradeInvoiceId: string
|};
export type EmailTradeInvoiceMutationResponse = {|
  +emailTradeInvoice: ?{|
    +success: ?boolean
  |}
|};
export type EmailTradeInvoiceMutation = {|
  variables: EmailTradeInvoiceMutationVariables,
  response: EmailTradeInvoiceMutationResponse,
|};
*/


/*
mutation EmailTradeInvoiceMutation(
  $tradeInvoiceId: ID!
) {
  emailTradeInvoice(tradeInvoiceId: $tradeInvoiceId) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tradeInvoiceId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "emailTradeInvoice",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tradeInvoiceId",
        "variableName": "tradeInvoiceId"
      }
    ],
    "concreteType": "EmailTradeInvoice",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EmailTradeInvoiceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EmailTradeInvoiceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "EmailTradeInvoiceMutation",
    "id": null,
    "text": "mutation EmailTradeInvoiceMutation(\n  $tradeInvoiceId: ID!\n) {\n  emailTradeInvoice(tradeInvoiceId: $tradeInvoiceId) {\n    success\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '95eafdc7314511e1de42ceff143de6cb';
module.exports = node;
