/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractHoldings_contracts$ref: FragmentReference;
declare export opaque type ContractHoldings_contracts$fragmentType: ContractHoldings_contracts$ref;
export type ContractHoldings_contracts = {|
  +id: string,
  +symbol: string,
  +ibConId: number,
  +$refType: ContractHoldings_contracts$ref,
|};
export type ContractHoldings_contracts$data = ContractHoldings_contracts;
export type ContractHoldings_contracts$key = {
  +$data?: ContractHoldings_contracts$data,
  +$fragmentRefs: ContractHoldings_contracts$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ContractHoldings_contracts",
  "type": "ContractNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ibConId",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9e1a7aa9db92c21434f6f895076a8975';
module.exports = node;
