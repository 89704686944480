import { graphql } from 'react-relay';

const addVueCommunicationMutation = graphql`
  mutation AddVueCommunicationMutation(
    $vueId: ID!
    $heading: String!
    $message: String!
    $first: Int!
  ) {
    addVueCommunication (
      vueId: $vueId
      heading: $heading
      message: $message
    ){
      vue {
        vuecommunicationSet (
          orderBy: "-createdAt"
          first: $first
        )
        {
          edges {
            node {
              id
              heading
              message
              published
              publishedAt
            }
          }
        }
      }
    }
  }
`;
export default addVueCommunicationMutation;
