import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { toLocal } from '../../../helpers/ID';
import TableIconButton from '../../common/table/TableIconButton';
import Aba from '../../../icons/Aba';
import { doCommitPromise } from '../../../common/commit';
import generateABAForCTRBatch from '../../../mutations/GenerateABAForCTRBatch';
import downloadFileContent from '../../../common/download';

export default function BatchCell(props) {
  const { cashTransferRequest, onBatchSelect } = props;
  if (!cashTransferRequest.batch) {
    return null;
  }
  const isAU = (process.env.DEPLOYMENT_CODE === 'AU');
  const isUK = (process.env.DEPLOYMENT_CODE === 'UK');
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        {toLocal(cashTransferRequest.batch.id)}
      </Grid>
      { isAU && (
        <Grid item>
          <TableIconButton
            key={`${cashTransferRequest.id}-batch`}
            iconClass={Aba}
            tooltip={`Export ABA: ${cashTransferRequest.batch.name}`}
            color="primary"
            onClickPromise={() => doCommitPromise(
              generateABAForCTRBatch,
              {
                cashTransferRequestBatchId: cashTransferRequest.batch.id,
              },
              (response) => {
                downloadFileContent(response.generateAbaForCtrBatch.abaText,
                  'text/plain',
                  `${cashTransferRequest.batch.name}.aba`);
              },
              () => null,
            )}
          />
        </Grid>
      )}

      { isUK && (
        <Grid item>
          <TableIconButton
            key={`${cashTransferRequest.id}-inverse-batch`}
            iconClass={MoreHorizIcon}
            tooltip={`Create Contra Batch for Batch ${toLocal(cashTransferRequest.batch.id)}`}
            color="primary"
            onClick={() => onBatchSelect && onBatchSelect(cashTransferRequest.batch)}
          />
        </Grid>
      )}

      {/*</TableCompactGridItem>*/}
    </Grid>
  );
}
