/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SubscriberType = "ADV" | "CLIENT" | "%future added value";
export type SubscriptionCode = "RTP_CU" | "RTP_CX" | "RTP_OC" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSubscriptionsDetails_all$ref: FragmentReference;
declare export opaque type UserSubscriptionsDetails_all$fragmentType: UserSubscriptionsDetails_all$ref;
export type UserSubscriptionsDetails_all = {|
  +subscriberType: SubscriberType,
  +id: string,
  +createdAt: any,
  +updatedAt: any,
  +subscriptionTemplate: {|
    +code: SubscriptionCode,
    +name: string,
  |},
  +user: {|
    +id: string
  |},
  +billPeriodStart: any,
  +billPeriodEnd: any,
  +pendingCancel: boolean,
  +active: boolean,
  +paymentAccount: {|
    +id: string,
    +name: ?string,
  |},
  +$refType: UserSubscriptionsDetails_all$ref,
|};
export type UserSubscriptionsDetails_all$data = UserSubscriptionsDetails_all;
export type UserSubscriptionsDetails_all$key = {
  +$data?: UserSubscriptionsDetails_all$data,
  +$fragmentRefs: UserSubscriptionsDetails_all$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserSubscriptionsDetails_all",
  "type": "UserSubscriptionNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "subscriberType",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "subscriptionTemplate",
      "storageKey": null,
      "args": null,
      "concreteType": "SubscriptionTemplateNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billPeriodStart",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "billPeriodEnd",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pendingCancel",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "active",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "paymentAccount",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '26bd58ef2daa508b8fd3bb0ac8c8f4f3';
module.exports = node;
