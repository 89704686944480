/**
 * @flow
 * @relayHash a59179c0b768d0210413fc6c75af06b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type UserClientAccountPermissionInput = {|
  resource?: ?Resource,
  verb?: ?Verb,
|};
export type UpdateUserRoleMutationVariables = {|
  userRoleId: string,
  rolePermissions?: ?$ReadOnlyArray<?UserClientAccountPermissionInput>,
|};
export type UpdateUserRoleMutationResponse = {|
  +updateUserRole: ?{|
    +userRole: ?{|
      +id: string,
      +name: string,
      +rolePermissions: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +resource: Resource,
            +verb: Verb,
          |}
        |}>
      |},
    |}
  |}
|};
export type UpdateUserRoleMutation = {|
  variables: UpdateUserRoleMutationVariables,
  response: UpdateUserRoleMutationResponse,
|};
*/


/*
mutation UpdateUserRoleMutation(
  $userRoleId: ID!
  $rolePermissions: [UserClientAccountPermissionInput]
) {
  updateUserRole(userRoleId: $userRoleId, rolePermissions: $rolePermissions) {
    userRole {
      id
      name
      rolePermissions {
        edges {
          node {
            id
            resource
            verb
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userRoleId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rolePermissions",
    "type": "[UserClientAccountPermissionInput]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUserRole",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rolePermissions",
        "variableName": "rolePermissions"
      },
      {
        "kind": "Variable",
        "name": "userRoleId",
        "variableName": "userRoleId"
      }
    ],
    "concreteType": "UpdateUserRole",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userRole",
        "storageKey": null,
        "args": null,
        "concreteType": "UserRoleNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rolePermissions",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRolePermissionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRolePermissionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRolePermissionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "resource",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "verb",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserRoleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserRoleMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserRoleMutation",
    "id": null,
    "text": "mutation UpdateUserRoleMutation(\n  $userRoleId: ID!\n  $rolePermissions: [UserClientAccountPermissionInput]\n) {\n  updateUserRole(userRoleId: $userRoleId, rolePermissions: $rolePermissions) {\n    userRole {\n      id\n      name\n      rolePermissions {\n        edges {\n          node {\n            id\n            resource\n            verb\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13ce838f7d60032357ef4832325e3ce2';
module.exports = node;
