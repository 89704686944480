/**
 * @flow
 * @relayHash ad5c45037ce831a0b13f8a3f7240e68d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreditType = "DSCR" | "OTH" | "PROMO" | "RFR" | "%future added value";
export type BrokerageCreditMutationVariables = {|
  accountId: string,
  initialAmount: any,
  expiryDate: any,
  note: string,
  description: string,
  creditType: CreditType,
  provisionedById: string,
|};
export type BrokerageCreditMutationResponse = {|
  +createBrokerageCredit: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +brokerageCredit: ?{|
      +id: string
    |},
  |}
|};
export type BrokerageCreditMutation = {|
  variables: BrokerageCreditMutationVariables,
  response: BrokerageCreditMutationResponse,
|};
*/


/*
mutation BrokerageCreditMutation(
  $accountId: ID!
  $initialAmount: Decimal!
  $expiryDate: Date!
  $note: String!
  $description: String!
  $creditType: CreditType!
  $provisionedById: ID!
) {
  createBrokerageCredit(accountId: $accountId, initialAmount: $initialAmount, expiryDate: $expiryDate, note: $note, description: $description, creditType: $creditType, provisionedById: $provisionedById) {
    errors {
      field
      messages
    }
    brokerageCredit {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "initialAmount",
    "type": "Decimal!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expiryDate",
    "type": "Date!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "note",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "creditType",
    "type": "CreditType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "provisionedById",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createBrokerageCredit",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "creditType",
        "variableName": "creditType"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "expiryDate",
        "variableName": "expiryDate"
      },
      {
        "kind": "Variable",
        "name": "initialAmount",
        "variableName": "initialAmount"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "note"
      },
      {
        "kind": "Variable",
        "name": "provisionedById",
        "variableName": "provisionedById"
      }
    ],
    "concreteType": "CreateBrokerageCredit",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerageCredit",
        "storageKey": null,
        "args": null,
        "concreteType": "BrokerageCreditNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerageCreditMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerageCreditMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerageCreditMutation",
    "id": null,
    "text": "mutation BrokerageCreditMutation(\n  $accountId: ID!\n  $initialAmount: Decimal!\n  $expiryDate: Date!\n  $note: String!\n  $description: String!\n  $creditType: CreditType!\n  $provisionedById: ID!\n) {\n  createBrokerageCredit(accountId: $accountId, initialAmount: $initialAmount, expiryDate: $expiryDate, note: $note, description: $description, creditType: $creditType, provisionedById: $provisionedById) {\n    errors {\n      field\n      messages\n    }\n    brokerageCredit {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98b069fa0db0063b1322a28ab98d830e';
module.exports = node;
