import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Drawer, Grid } from '@material-ui/core';
import TableFieldMeta from '../../common/TableFieldMeta';
import MoreButton from '../common/buttons/MoreButton';
import { fromGlobalId } from '../../helpers/ID';
import AdviserSearch from '../common/dialogs/AdviserSearch';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import withSuspense from '../common/table/Suspention';

const AdviserAddForm = withSuspense(React.lazy(
  () => (
    process.env.DEPLOYMENT_CODE === 'AU'
      ? import('../tenant/au/AdviserAddForm')
      : import('../tenant/uk/AdviserAddForm')
  ),
));

function AdviserList(props) {
  const { tenantId, location, history } = props;
  const [addAdviserOpen, setAddAdviserOpen] = React.useState(false);
  const [updateSeq, setUpdateSeq] = React.useState(0);
  const postColumns = [
    TableFieldMeta.customCell('more', '', row => (
      <MoreButton href={`/adviser/${fromGlobalId(row.value.id)[1]}`} />
    )),
  ];
  return (
    <>
      <Drawer
        anchor="right"
        open={addAdviserOpen}
        onClose={() => setAddAdviserOpen(false)}
      >
        <AdviserAddForm
          tenantId={tenantId}
          close={() => setAddAdviserOpen(false)}
          onAddAdviser={() => setUpdateSeq(updateSeq + 1)}
        />
      </Drawer>

      <AdviserSearch
        key={updateSeq}
        location={location}
        history={history}
        postColumns={postColumns}
        tenantId={tenantId}
      />
      <BottomGridContainer>
        <Grid item>
          <AddButton
            onClick={() => setAddAdviserOpen(true)}
            buttonText="Adviser"
          />
        </Grid>
      </BottomGridContainer>
    </>
  );
}

export default withRouter(AdviserList);
