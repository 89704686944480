import { graphql } from 'react-relay';

// language=GraphQL
const updateAdviserRoleMutation = graphql`
  mutation UpdateAdviserRoleMutation(
    $adviserRoleId: ID!
    $rolePermissions: [AdviserPermissionInput]!
  ) {
    updateAdviserRole(
      adviserRoleId: $adviserRoleId
      rolePermissions: $rolePermissions
    ) {
    adviserRole {
        id
        name
        rolePermissions {
          edges {
            node {
              id
              resource
              verb
              scope
            }
          }
        }
      }
    }
  }
`;

export default updateAdviserRoleMutation;
