/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AdminResource = "ACC" | "ADV" | "APP" | "BAC" | "BAT" | "CHR" | "CON" | "DEV" | "FEE" | "FXO" | "FXR" | "MPR" | "ODR" | "OMT" | "PER" | "RPT" | "SUB" | "SUP" | "TEN" | "TRA" | "TRD" | "USR" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AdminPanel_userNode$ref: FragmentReference;
declare export opaque type AdminPanel_userNode$fragmentType: AdminPanel_userNode$ref;
export type AdminPanel_userNode = {|
  +id: string,
  +fullName: ?string,
  +adminpermissionSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +resource: AdminResource,
        +verb: Verb,
      |}
    |}>
  |},
  +$refType: AdminPanel_userNode$ref,
|};
export type AdminPanel_userNode$data = AdminPanel_userNode;
export type AdminPanel_userNode$key = {
  +$data?: AdminPanel_userNode$data,
  +$fragmentRefs: AdminPanel_userNode$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AdminPanel_userNode",
  "type": "UserNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "adminpermissionSet",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminPermissionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminPermissionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AdminPermissionNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "resource",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "verb",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '10cd033ad2d1c927d56970a9f83b68fa';
module.exports = node;
