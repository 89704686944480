import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const loaderQuery = graphql`
  query ExchangeLoaderQuery (
    $first: Int
    $offset: Int
    $search: String
  ) {
    viewer {
      ...ExchangeLoaderFragment_viewer
      @arguments(
        first: $first,
        offset: $offset,
        search: $search,
      )
    }
  }
`;

const reFetched = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment ExchangeLoaderFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
      ) {
        exchanges (
          first: $first
          offset: $offset
          search: $search
        ){
          edges {
            node {
              id
              name
              symbol
            }
          }
          count
        }
      }
    `,
  },
  loaderQuery,
);

export default function ExchangeLoader(props) {
  return (
    <FieldLoader
      loaderQuery={loaderQuery}
      refetchContainer={reFetched}
      getOptionLabel={option => (option.name)}
      nodeCollector={viewer => viewer.exchanges.edges.map(edge => edge.node)}
      {...props}
    />
  );
}
