/**
 * @flow
 * @relayHash c54749e5b6fc11e7400e071892cb5ab5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserProvisionRequestListFormQueryVariables = {|
  userProvisionRequestId: string,
  clientAccountId: string,
|};
export type UserProvisionRequestListFormQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +userProvisionRequests: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +status: string,
          +clientAccount: ?{|
            +id: string,
            +name: ?string,
          |},
          +userModel: {|
            +id: string,
            +email: string,
            +userclientaccountroleSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +userRole: {|
                    +id: string,
                    +name: string,
                  |}
                |}
              |}>
            |},
          |},
          +approvals: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +approvedBy: {|
                  +id: string,
                  +username: string,
                |},
                +approvedAt: any,
              |}
            |}>
          |},
          +hasCompleteApprovals: ?boolean,
        |}
      |}>
    |},
  |}
|};
export type UserProvisionRequestListFormQuery = {|
  variables: UserProvisionRequestListFormQueryVariables,
  response: UserProvisionRequestListFormQueryResponse,
|};
*/


/*
query UserProvisionRequestListFormQuery(
  $userProvisionRequestId: ID!
  $clientAccountId: ID!
) {
  viewer {
    id
    userProvisionRequests(id: $userProvisionRequestId, clientAccountId: $clientAccountId) {
      edges {
        node {
          id
          status
          clientAccount {
            id
            name
          }
          userModel {
            id
            email
            userclientaccountroleSet(clientAccountId: $clientAccountId) {
              edges {
                node {
                  userRole {
                    id
                    name
                  }
                  id
                }
              }
            }
          }
          approvals {
            edges {
              node {
                id
                approvedBy {
                  id
                  username
                }
                approvedAt
              }
            }
          }
          hasCompleteApprovals
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userProvisionRequestId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "clientAccountId",
  "variableName": "clientAccountId"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userProvisionRequestId"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "clientAccount",
  "storageKey": null,
  "args": null,
  "concreteType": "ClientAccountNode",
  "plural": false,
  "selections": (v5/*: any*/)
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v8 = [
  (v2/*: any*/)
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "userRole",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRoleNode",
  "plural": false,
  "selections": (v5/*: any*/)
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "approvals",
  "storageKey": null,
  "args": null,
  "concreteType": "ApprovalNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ApprovalNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ApprovalNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "approvedBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "username",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "approvedAt",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasCompleteApprovals",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserProvisionRequestListFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userProvisionRequests",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "UserProvisionRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProvisionRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserProvisionRequestNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "userModel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountroleSet",
                            "storageKey": null,
                            "args": (v8/*: any*/),
                            "concreteType": "UserClientAccountRoleNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountRoleNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountRoleNode",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserProvisionRequestListFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userProvisionRequests",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "UserProvisionRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProvisionRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserProvisionRequestNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "userModel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountroleSet",
                            "storageKey": null,
                            "args": (v8/*: any*/),
                            "concreteType": "UserClientAccountRoleNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountRoleNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountRoleNode",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v1/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserProvisionRequestListFormQuery",
    "id": null,
    "text": "query UserProvisionRequestListFormQuery(\n  $userProvisionRequestId: ID!\n  $clientAccountId: ID!\n) {\n  viewer {\n    id\n    userProvisionRequests(id: $userProvisionRequestId, clientAccountId: $clientAccountId) {\n      edges {\n        node {\n          id\n          status\n          clientAccount {\n            id\n            name\n          }\n          userModel {\n            id\n            email\n            userclientaccountroleSet(clientAccountId: $clientAccountId) {\n              edges {\n                node {\n                  userRole {\n                    id\n                    name\n                  }\n                  id\n                }\n              }\n            }\n          }\n          approvals {\n            edges {\n              node {\n                id\n                approvedBy {\n                  id\n                  username\n                }\n                approvedAt\n              }\n            }\n          }\n          hasCompleteApprovals\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb0567711162c30fc84f6cd9375b3657';
module.exports = node;
