import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function AddButton(props) {
  const classes = useStyles();
  const { buttonText, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      color="default"
      className={classes.button}
      {...otherProps}
    >
      { buttonText }
      <AddIcon
        className={classes.rightIcon}
      />
    </Button>
  );
}

AddButton.propTypes = {
  buttonText: PropTypes.string,
  ...Button.propTypes,
};

AddButton.defaultProps = {
  buttonText: 'Add Button',
};

export default AddButton;
