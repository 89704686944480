import React from 'react';
import { Link } from '@material-ui/core';
import {
  COLOR_FILLED,
  COLOR_MATCHED,
  COLOR_UNDEFINED,
  orderStatusColorMap,
} from '../../common/constants';
import ProgressTick from '../common/ProgressTick';
import { toLocal } from '../../helpers/ID';

export default function StockOrderTicks(props) {
  const {
    stockOrders,
    parentOrder,
  } = props;

  return (
    <div
      key={parentOrder && parentOrder.id}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {
        stockOrders.map(
          (stockOrder) => {
            const { brokerOrder } = stockOrder;
            const backColor = orderStatusColorMap[brokerOrder.status] || COLOR_UNDEFINED;
            let layers = [];
            if (brokerOrder.quantityFilled > 0) {
              layers = [
                { color: COLOR_FILLED, progress: brokerOrder.quantityFilled },
                { color: COLOR_MATCHED, progress: Math.abs(stockOrder.quantityToDate) },
              ];
            }
            return (
              <Link
                href={`/stock-order/${toLocal(stockOrder.id)}`}
              >
                <ProgressTick
                  key={brokerOrder.id}
                  maxLevel={Math.abs(brokerOrder.quantity)}
                  backColor={backColor}
                  layers={layers}
                />
              </Link>
            );
          },
        )
      }
    </div>
  );
}
