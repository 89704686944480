/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CronList_viewer$ref: FragmentReference;
declare export opaque type CronList_viewer$fragmentType: CronList_viewer$ref;
export type CronList_viewer = {|
  +adminMeta: ?{|
    +systemName: ?string
  |},
  +crons: ?$ReadOnlyArray<?{|
    +expression: ?string,
    +functionUrl: ?string,
    +awsLogUrl: ?string,
    +awsEventUrl: ?string,
    +paramDefs: ?$ReadOnlyArray<?{|
      +defaultValue: ?string,
      +id: ?string,
      +type: ?string,
      +description: ?string,
    |}>,
  |}>,
  +$refType: CronList_viewer$ref,
|};
export type CronList_viewer$data = CronList_viewer;
export type CronList_viewer$key = {
  +$data?: CronList_viewer$data,
  +$fragmentRefs: CronList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "CronList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "adminMeta",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminMeta",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "systemName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "crons",
      "storageKey": null,
      "args": null,
      "concreteType": "Cron",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "expression",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "functionUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "awsLogUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "awsEventUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "paramDefs",
          "storageKey": null,
          "args": null,
          "concreteType": "CronParamDefs",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "defaultValue",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "type",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "description",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e85a2722be8628827e8a81435b281d4b';
module.exports = node;
