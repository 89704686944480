import React from 'react';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import { TableHead } from '@material-ui/core';
import { renderMUITableFooters, renderMUITableHeaders, renderMUITableRows } from './table/MUITable';


function DataTable(props) {
  const {
    columnMeta,
    dataNodes,
    showHeadings,
    showFooter,
  } = props;

  const aggregateData = columnMeta.map((column) => {
    const { reducer } = column;
    return reducer ? reducer.initiator(dataNodes) : null;
  });

  return (
    <Table size="small">
      <TableHead>{showHeadings && renderMUITableHeaders(columnMeta)}</TableHead>
      <TableBody>
        {renderMUITableRows(dataNodes, columnMeta, {}, aggregateData)}
      </TableBody>
      <TableHead>{showFooter && renderMUITableFooters(columnMeta, aggregateData)}</TableHead>
    </Table>
  );
}


DataTable.propTypes = {
  columnMeta: PropTypes.object.isRequired,
  dataNodes: PropTypes.object.isRequired,
  showHeadings: PropTypes.bool,
  showFooter: PropTypes.bool,
};

DataTable.defaultProps = {
  showHeadings: true,
  showFooter: false,
};


export default DataTable;
