/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountStockHoldingPortfolios_mainVues$ref: FragmentReference;
declare export opaque type AccountStockHoldingPortfolios_mainVues$fragmentType: AccountStockHoldingPortfolios_mainVues$ref;
export type AccountStockHoldingPortfolios_mainVues = {|
  +vues: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +$refType: AccountStockHoldingPortfolios_mainVues$ref,
|};
export type AccountStockHoldingPortfolios_mainVues$data = AccountStockHoldingPortfolios_mainVues;
export type AccountStockHoldingPortfolios_mainVues$key = {
  +$data?: AccountStockHoldingPortfolios_mainVues$data,
  +$fragmentRefs: AccountStockHoldingPortfolios_mainVues$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AccountStockHoldingPortfolios_mainVues",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vues",
      "storageKey": "vues(orderBy:\"name\",parentVue_Isnull:true,vueType:\"MV\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "name"
        },
        {
          "kind": "Literal",
          "name": "parentVue_Isnull",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "vueType",
          "value": "MV"
        }
      ],
      "concreteType": "VueNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '18c0507704e606cb9e1ff783528699dd';
module.exports = node;
