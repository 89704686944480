/**
 * @flow
 * @relayHash e36a000d55de12c04cc1f51313cb5954
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type CreditType = "DSCR" | "OTH" | "PROMO" | "RFR" | "%future added value";
export type BrokerageCreditUpdateFormQueryVariables = {|
  brokerageCreditId: string
|};
export type BrokerageCreditUpdateFormQueryResponse = {|
  +viewer: ?{|
    +brokerageCredits: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +account: {|
            +id: string,
            +name: ?string,
          |},
          +description: ?string,
          +expiryDate: ?any,
          +initialAmount: number,
          +remainingAmount: number,
          +note: ?string,
          +creditType: CreditType,
          +consumed: boolean,
          +provisionedBy: ?{|
            +id: string,
            +fullName: ?string,
          |},
          +status: ApprovableStatus,
          +approvals: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +approvedBy: {|
                  +id: string,
                  +username: string,
                |},
                +approvedAt: any,
              |}
            |}>
          |},
          +hasCompleteApprovals: ?boolean,
        |}
      |}>
    |}
  |}
|};
export type BrokerageCreditUpdateFormQuery = {|
  variables: BrokerageCreditUpdateFormQueryVariables,
  response: BrokerageCreditUpdateFormQueryResponse,
|};
*/


/*
query BrokerageCreditUpdateFormQuery(
  $brokerageCreditId: ID!
) {
  viewer {
    brokerageCredits(id: $brokerageCreditId) {
      edges {
        node {
          id
          account {
            id
            name
          }
          description
          expiryDate
          initialAmount
          remainingAmount
          note
          creditType
          consumed
          provisionedBy {
            id
            fullName
          }
          status
          approvals {
            edges {
              node {
                id
                approvedBy {
                  id
                  username
                }
                approvedAt
              }
            }
          }
          hasCompleteApprovals
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerageCreditId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokerageCredits",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "brokerageCreditId"
    }
  ],
  "concreteType": "BrokerageCreditNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerageCreditNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerageCreditNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "account",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "description",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "expiryDate",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "initialAmount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "remainingAmount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "note",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "creditType",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "consumed",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "provisionedBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "approvals",
              "storageKey": null,
              "args": null,
              "concreteType": "ApprovalNodeConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ApprovalNodeEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ApprovalNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "approvedBy",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "UserNode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "username",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "approvedAt",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasCompleteApprovals",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerageCreditUpdateFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerageCreditUpdateFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerageCreditUpdateFormQuery",
    "id": null,
    "text": "query BrokerageCreditUpdateFormQuery(\n  $brokerageCreditId: ID!\n) {\n  viewer {\n    brokerageCredits(id: $brokerageCreditId) {\n      edges {\n        node {\n          id\n          account {\n            id\n            name\n          }\n          description\n          expiryDate\n          initialAmount\n          remainingAmount\n          note\n          creditType\n          consumed\n          provisionedBy {\n            id\n            fullName\n          }\n          status\n          approvals {\n            edges {\n              node {\n                id\n                approvedBy {\n                  id\n                  username\n                }\n                approvedAt\n              }\n            }\n          }\n          hasCompleteApprovals\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72a669dd63ab9a58025e4b594b2b8833';
module.exports = node;
