import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { useTheme } from '@material-ui/core';
import { COLOR_HALO_DARK, COLOR_HALO_LIGHT } from '../../common/constants';

export default function StockChart(props) {
  const { seriesData } = props;
  const theme = useTheme();

  const priceAxisOptions = {
    id: 'main',
    title: { text: 'Price' },
    allowDecimal: true,
    floor: 0,
    height: '75%',
    resize: {
      enabled: true,
      lineWidth: 3,
      lineDashStyle: 'longDash',
      lineColor: theme.palette.text.secondary,
      x: 0,
      y: 5,
    },
    gridLineColor: theme.palette.action.selected,
  };

  const scaleFactorAxisOptions = {
    title: { text: 'Scale Factor' },
    offset: 2,
    top: '80%',
    height: '22%',
    id: 'macd',
    opposite: false,
    gridLineColor: theme.palette.action.selected,
    visible: false,
  };

  const chartOptions = {
    legend: {
      layout: 'vertical',
      align: 'left',
      x: 80,
      verticalAlign: 'top',
      y: 55,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'rgba(255,255,255,0.25)',
    },
    chart: {
      backgroundColor: theme.palette.background.paper,
      style: { fontFamily: theme.typography.fontFamily },
      height: 600,
    },
    scrollbar: {
      trackBackgroundColor: theme.palette.background.paper,
      barBackgroundColor: theme.palette.text.disabled,
      buttonBackgroundColor: theme.palette.background.paper,
      trackBorderColor: theme.palette.action.selected,
    },
    series: seriesData,
    title: { text: '' },
    credits: { enabled: false },
    yAxis: [scaleFactorAxisOptions, priceAxisOptions],
    xAxis: {
      labels: { style: { color: theme.palette.text.secondary }, format: '{value:%d %b}' },
    },
    colors: ['#aa8971', COLOR_HALO_DARK, COLOR_HALO_LIGHT,
      '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4'],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      constructorType="stockChart"
    />
  );
}
