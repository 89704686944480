import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import OrderApprovalList from './OrderApprovalList';
import { toGlobalId } from '../../helpers/ID';


function RebalanceProposal(props) {
  const classes = useCommonStyles();
  const {
    match,
  } = props;

  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <OrderApprovalList
            proposalId={toGlobalId('OrderProposalNode', match.params.proposalId)}
          />
        </Grid>

      </Grid>
    </div>
  );
}


export default withRouter(RebalanceProposal);
