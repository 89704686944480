/**
 * @flow
 * @relayHash 1d1762254407b6b60b100bc49d56a266
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type CreatePortfolioOrderInputType = {|
  accountId: string,
  portfolioId?: ?string,
  vueId?: ?string,
  portfolio?: ?OrderPortfolioInputType,
  portfolioOrderSide: string,
  limitOrders?: ?$ReadOnlyArray<?LimitOrderInputType>,
  marketOrders?: ?$ReadOnlyArray<?MarketOrderInputType>,
  conditionalOrders?: ?$ReadOnlyArray<?ConditionalOrderInputType>,
  stopLimitOrders?: ?$ReadOnlyArray<?StopLimitOrderInputType>,
  stopMarketOrders?: ?$ReadOnlyArray<?StopMarketOrderInputType>,
|};
export type OrderPortfolioInputType = {|
  portfolioId?: ?string,
  trackingVueId?: ?string,
  trackingVueName?: ?string,
|};
export type LimitOrderInputType = {|
  trailStopOffset?: ?number,
  trailStopRatio?: ?number,
  stopPrice?: ?number,
  contractId: string,
  side: string,
  quantity?: ?number,
  customCommission?: ?number,
  vwap?: ?VWAPInputType,
  twap?: ?TWAPInputType,
  exchange?: ?string,
  cashQuantity?: ?number,
  limitPrice?: ?number,
  stopLoss?: ?StopLossInput,
  takeProfit?: ?TakeProfitInput,
|};
export type VWAPInputType = {|
  startTime?: ?any,
  endTime?: ?any,
  allowPastEndTime?: ?boolean,
  maxPercentageVolume?: ?number,
  speedup?: ?boolean,
  neverTakeLiquidity?: ?boolean,
|};
export type TWAPInputType = {|
  strategyType?: ?string,
  startTime?: ?any,
  endTime?: ?any,
  allowPastEndTime?: ?boolean,
|};
export type StopLossInput = {|
  trailStopOffset?: ?number,
  trailStopRatio?: ?number,
  stopPrice?: ?number,
|};
export type TakeProfitInput = {|
  limitPrice?: ?number
|};
export type MarketOrderInputType = {|
  trailStopOffset?: ?number,
  trailStopRatio?: ?number,
  stopPrice?: ?number,
  contractId: string,
  side: string,
  quantity?: ?number,
  customCommission?: ?number,
  vwap?: ?VWAPInputType,
  twap?: ?TWAPInputType,
  exchange?: ?string,
  cashQuantity?: ?number,
  refPrice?: ?number,
  stopLoss?: ?StopLossInput,
  takeProfit?: ?TakeProfitInput,
|};
export type ConditionalOrderInputType = {|
  contractId: string,
  side: string,
  quantity?: ?number,
  customCommission?: ?number,
  vwap?: ?VWAPInputType,
  twap?: ?TWAPInputType,
  exchange?: ?string,
  cashQuantity?: ?number,
|};
export type StopLimitOrderInputType = {|
  trailStopOffset?: ?number,
  trailStopRatio?: ?number,
  stopPrice?: ?number,
  contractId: string,
  side: string,
  quantity?: ?number,
  customCommission?: ?number,
  vwap?: ?VWAPInputType,
  twap?: ?TWAPInputType,
  exchange?: ?string,
  cashQuantity?: ?number,
  limitPrice?: ?number,
|};
export type StopMarketOrderInputType = {|
  trailStopOffset?: ?number,
  trailStopRatio?: ?number,
  stopPrice?: ?number,
  contractId: string,
  side: string,
  quantity?: ?number,
  customCommission?: ?number,
  vwap?: ?VWAPInputType,
  twap?: ?TWAPInputType,
  exchange?: ?string,
  cashQuantity?: ?number,
  refPrice?: ?number,
|};
export type OrderClientApprovalInputType = {|
  sendType: string,
  showFeeBreakdown: boolean,
  showAdviserContact: boolean,
  subject?: ?string,
  adviserCommentary?: ?string,
  signature?: ?any,
|};
export type CreateOrdersMutationVariables = {|
  portfolioOrders?: ?$ReadOnlyArray<?CreatePortfolioOrderInputType>,
  withApprovals?: ?OrderClientApprovalInputType,
  withMakerChecker?: ?boolean,
|};
export type CreateOrdersMutationResponse = {|
  +createOrders: ?{|
    +portfolioOrders: ?$ReadOnlyArray<?{|
      +id: string,
      +stockOrders: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +brokerOrder: ?{|
              +id: string,
              +status: OrderStatus,
            |},
          |}
        |}>
      |},
      +account: {|
        +id: string,
        +currentAssets: ?{|
          +stock: ?{|
            +balances: ?$ReadOnlyArray<?{|
              +contract: ?{|
                +id: string,
                +symbol: string,
              |},
              +availableQuantity: ?number,
              +quantity: ?number,
            |}>
          |}
        |},
      |},
    |}>,
    +errors: ?$ReadOnlyArray<?{|
      +messages: $ReadOnlyArray<string>,
      +field: string,
    |}>,
    +orderErrors: ?$ReadOnlyArray<?{|
      +orderIndex: ?number,
      +accountId: ?string,
      +accountName: ?string,
      +message: ?string,
    |}>,
  |}
|};
export type CreateOrdersMutation = {|
  variables: CreateOrdersMutationVariables,
  response: CreateOrdersMutationResponse,
|};
*/


/*
mutation CreateOrdersMutation(
  $portfolioOrders: [CreatePortfolioOrderInputType]
  $withApprovals: OrderClientApprovalInputType
  $withMakerChecker: Boolean
) {
  createOrders(portfolioOrders: $portfolioOrders, withApprovals: $withApprovals, withMakerChecker: $withMakerChecker) {
    portfolioOrders {
      id
      stockOrders {
        edges {
          node {
            id
            brokerOrder {
              id
              status
            }
          }
        }
      }
      account {
        id
        currentAssets {
          stock {
            balances {
              contract {
                id
                symbol
              }
              availableQuantity
              quantity
            }
          }
        }
      }
    }
    errors {
      messages
      field
    }
    orderErrors {
      orderIndex
      accountId
      accountName
      message
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "portfolioOrders",
    "type": "[CreatePortfolioOrderInputType]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "withApprovals",
    "type": "OrderClientApprovalInputType",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "withMakerChecker",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createOrders",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "portfolioOrders",
        "variableName": "portfolioOrders"
      },
      {
        "kind": "Variable",
        "name": "withApprovals",
        "variableName": "withApprovals"
      },
      {
        "kind": "Variable",
        "name": "withMakerChecker",
        "variableName": "withMakerChecker"
      }
    ],
    "concreteType": "CreateOrdersAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "portfolioOrders",
        "storageKey": null,
        "args": null,
        "concreteType": "PortfolioOrderNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "stockOrders",
            "storageKey": null,
            "args": null,
            "concreteType": "StockOrderNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "StockOrderNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StockOrderNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokerOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerOrderNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "status",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "currentAssets",
                "storageKey": null,
                "args": null,
                "concreteType": "CurrentAssets",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "stock",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "StockHoldings",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "balances",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StockBalance",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "symbol",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "availableQuantity",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "quantity",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "orderErrors",
        "storageKey": null,
        "args": null,
        "concreteType": "OrderError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orderIndex",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accountId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "accountName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateOrdersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateOrdersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateOrdersMutation",
    "id": null,
    "text": "mutation CreateOrdersMutation(\n  $portfolioOrders: [CreatePortfolioOrderInputType]\n  $withApprovals: OrderClientApprovalInputType\n  $withMakerChecker: Boolean\n) {\n  createOrders(portfolioOrders: $portfolioOrders, withApprovals: $withApprovals, withMakerChecker: $withMakerChecker) {\n    portfolioOrders {\n      id\n      stockOrders {\n        edges {\n          node {\n            id\n            brokerOrder {\n              id\n              status\n            }\n          }\n        }\n      }\n      account {\n        id\n        currentAssets {\n          stock {\n            balances {\n              contract {\n                id\n                symbol\n              }\n              availableQuantity\n              quantity\n            }\n          }\n        }\n      }\n    }\n    errors {\n      messages\n      field\n    }\n    orderErrors {\n      orderIndex\n      accountId\n      accountName\n      message\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fed07b433c988c64a25c958ff3e66292';
module.exports = node;
