import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { uploadCustomReportMutation } from '../../mutations/Report';
import FileUpload from '../common/FileUpload';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import { doCommitPromise } from '../../common/commit';


const makeMulitpart = (_inFiles) => {
  const pdf = _inFiles.find(x => x.name.endsWith('.pdf'));
  const xlsx = _inFiles.find(x => x.name.endsWith('.xlsx'));
  const multiParts = [];
  const fileMap = {};
  if (pdf) {
    fileMap[multiParts.length] = pdf;
    multiParts.push(` "${multiParts.length}": ["variables.pdf"]`);
  }
  if (xlsx) {
    fileMap[multiParts.length] = xlsx;
    multiParts.push(` "${multiParts.length}": ["variables.xlsx"]`);
  }

  const x = {
    map: `{${multiParts.join(',')}}`,
    ...fileMap,
  };
  return x;
};

function CustomReportUploadForm(props) {
  const { clientAccountId, onAdd, onClose } = props;
  const [ucVar] = React.useState({
    files: null,
    name: '',
  });

  const classes = useCommonStyles();

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>

        <DrawerGridItemHeading
          heading="Upload Custom Report"
        />
        <DrawerGridItemTextField
          sm={12}
          label="Name"
          defaultValue={ucVar.name}
          onChange={(e) => {
            ucVar.name = e.target.value;
          }}
        />
        <Grid item xs={12}>
          <FileUpload
            largeButton
            onUploadPromise={_inFiles => doCommitPromise(
              uploadCustomReportMutation,
              {
                clientAccountId,
                name: ucVar.name,
              },
              () => {
                if (onClose) onClose();
                if (onAdd) onAdd();
              },
              () => null,
              null,
              makeMulitpart(_inFiles),
            )}
          />
        </Grid>

      </Grid>
    </div>
  );
}

export default CustomReportUploadForm;
