import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import BaseGridContainer from '../common/BaseGridContainer';
import PopupContractSelectorField from '../common/PopupContractSelectorField';
import { useCommonStyles } from '../common/Styles';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import DailyHoldingsList from './DailyHoldingsList';


function DailyHoldings(props) {
  const classes = useCommonStyles();
  const { contract: inContract, reportDate: inReportDate, ibContractId } = props;
  const [reportDate, setReportDate] = React.useState(inReportDate);
  const [contract, setContract] = React.useState(inContract);
  const [ucVar] = useState({
    reportDate: inReportDate,
  });
  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <Grid item>
          <PopupContractSelectorField
            id="standard-helperText"
            label="Contract Search"
            defaultValue={contract}
            className={classes.formControl}
            helperText="email, username or user id"
            margin="normal"
            key={contract && contract.id}
            onSelect={(inContact) => {
              setContract(inContact);
            }}
          />
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/*Report Data*/}
            <KeyboardDatePicker
              className={classes.formControl}
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Report Date"
              value={reportDate}
              disableFuture
              onChange={(reportDateTime) => {
                let newReportDate = null;
                if (reportDateTime !== null && reportDateTime !== '') {
                  newReportDate = moment(reportDateTime).format(MOMENT_DATE_FORMAT_GRAPHQL);
                } else {
                  newReportDate = null;
                }
                setReportDate(newReportDate);
                ucVar.reportDate = newReportDate;
                //tableRef.current.refreshData();
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </BaseGridContainer>
      <div>
        {
          (contract && reportDate) && (
            <DailyHoldingsList
              key={`${contract || contract.id}-${ibContractId}-${reportDate}`}
              onUpdatedContract={infoContract => setContract(infoContract)}
              contractId={contract.id}
              ibContractId={ibContractId}
              date={reportDate}
              contractCaption={`${contract.symbol}:${contract.currency}`}
            />
          )
        }

      </div>
    </div>
  );
}

DailyHoldings.propTypes = {
  contract: PropTypes.object,
  reportDate: PropTypes.string,
};

DailyHoldings.defaultProps = {
  contract: null,
  reportDate: moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL),
};

export default withRouter(DailyHoldings);
