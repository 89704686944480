import { Grid } from '@material-ui/core';
import React from 'react';
import { createRefetchContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { useCommonStyles } from '../common/Styles';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { toLocal } from '../../helpers/ID';
import ApprovableStatus from '../globals/ApprovableStatus';
import BaseButton from '../common/buttons/BaseButton';
import PortfolioOrderRequestOrderForm from './PortfolioOrderRequestOrderForm';
import MoreButton from '../common/buttons/MoreButton';
import PortfolioOrderRequestApprovalForm from './PortfolioOrderRequestApprovalForm';


function PortfolioOrderRequestList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [showCreateOrder, setShowCreateOrder] = React.useState(false);
  const [showApproval, setShowApproval] = React.useState(false);
  const [approval, setApproval] = React.useState(null);
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.createdBy,
    TableFieldMeta.account(node => node.portfolioOrder.account),
    TableFieldMeta.field(node => (
      <Link href={`/order/${toLocal(node.portfolioOrder.id)}`}>
        {toLocal(node.portfolioOrder.id)}
      </Link>
    ), 'Vue Order'),
    TableFieldMeta.field(node => <ApprovableStatus approvable={node} />),
    TableFieldMeta.enum('AccountType', 'Account Type', (node) => {
      const clientAccount = node.portfolioOrder.account.clientaccount;
      return clientAccount && clientAccount.accountType;
    }),
    TableFieldMeta.fieldCell('summary', 'Summary',
      row => (
        <MoreButton
          key={row.value}
          onClick={() => {
            setApproval(row.value);
            setShowApproval(true);
          }}
        />
      )),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={showCreateOrder}
        onClose={() => setShowCreateOrder(false)}
      >
        <PortfolioOrderRequestOrderForm
          onClose={() => setShowCreateOrder(false)}
          onCreate={() => filter.update()}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showApproval}
        onClose={() => setShowApproval(false)}
      >
        <div className={classes.drawerRoot}>
          <PortfolioOrderRequestApprovalForm
            requestId={approval && approval.id}
            onCancelSuccess={() => setShowApproval(false)}
            onApproveSuccess={() => setShowApproval(false)}
          />
        </div>
      </Drawer>
      <BaseGridContainer>
        <Grid item xs={8}>
          <Grid container>
            <InnerTabFilterSelectField
              enumType="ApprovableStatus"
              label="Status"
              value={filter.params.status}
              helperText="Approved/Pending/Cancelled"
              onChange={e => filter.updateVar('status', e.target.value)}
            />
          </Grid>

        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="flex-end" alignItems="baseline">
            <Grid item style={{ marginTop: 10 }}>
              <BaseButton
                color="primary"
                onClick={() => setShowCreateOrder(true)}
              >
                Create Order
              </BaseButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.portfolioOrderRequests : null)}
            filter={filter}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query PortfolioOrderRequestListQuery(
    $first: Int
    $offset: Int
    $accountType: String
  ) {
    viewer {
      ...PortfolioOrderRequestList_viewer
      @arguments(
        first: $first
        offset: $offset
        accountType: $accountType
      )
    }
  }
`;

const container = createRefetchContainer(
  PortfolioOrderRequestList,
  {
    viewer: graphql`
      fragment PortfolioOrderRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        accountType: {type: String}
      ) {
        portfolioOrderRequests(
          first: $first
          offset: $offset
          accountType: $accountType
          orderBy: "-createdAt"
        ) {
          count
          edges {
            node {
              id
              status
              createdAt
              createdBy {
                id
                fullName
              }
              approvals {
                count
                edges {
                  node {
                    id
                  }
                }
              }
              portfolioOrder {
                id
                account {
                  id
                  name
                  clientaccount {
                    accountType
                  }
                }
                stockOrders {
                  edges {
                    node {
                      id
                      brokerOrder {
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    accountType: props.accountType,
  }),
));
