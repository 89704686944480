import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { commonStyles } from '../Styles';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  miniBadge: {
    fontSize: '6pt',
    position: 'absolute',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    width: '8pt',
    height: '8pt',
    borderRadius: '50%',
    fontWeight: '800',
    left: 0,
    top: 0,
  },
  progressViewWrapper: {
    position: 'relative',
  },
  progressableIcon: {
    position: 'absolute',
  },
  primaryProgress: {
    color: theme.palette.secondary.light,
    position: 'relative',
    zIndex: 10,
  },
  secondaryProgress: {
    color: theme.palette.primary.light,
    position: 'relative',
    zIndex: 10,
  },
}));

function TableIconButton(props) {
  const {
    iconClass,
    onClick,
    onClickPromise,
    color,
    disabled,
    tooltip,
    count,
    href,
  } = props;
  const IconClass = iconClass;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  return (
    <Tooltip title={tooltip} enterDelay={1000}>
      <div className={classes.progressViewWrapper}>
        <IconButton
          color={color}
          href={href}
          disabled={disabled}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
            if (onClickPromise) {
              setLoading(true);
              onClickPromise().finally(() => setLoading(false));
            }
          }}
          style={loading ? { padding: '4px' } : { padding: '14px' }}
        >
          <IconClass
            fontSize="small"
            className={classes.progressableIcon}
          />
          {
            count > 0 && (
              <div className={classes.miniBadge}>
                {count}
              </div>
            )
          }
          {
            loading && (
              <CircularProgress
                className={color === 'primary'
                  ? classes.primaryProgress : classes.secondaryProgress}
                size={20}
                thickness={4}
              />
            )
          }
        </IconButton>
      </div>
    </Tooltip>
  );
}

TableIconButton.propTypes = {
  iconClass: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onClickPromise: PropTypes.func,
};

TableIconButton.defaultProps = {
  onClick: null,
  onClickPromise: null,
};

export default TableIconButton;
