/**
 * @flow
 * @relayHash c73bde3c958527a67bd67f6cc9cda803
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountStockHoldingList_viewer$ref = any;
export type AccountStockHoldingListQueryVariables = {|
  accountId?: ?string
|};
export type AccountStockHoldingListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountStockHoldingList_viewer$ref
  |}
|};
export type AccountStockHoldingListQuery = {|
  variables: AccountStockHoldingListQueryVariables,
  response: AccountStockHoldingListQueryResponse,
|};
*/


/*
query AccountStockHoldingListQuery(
  $accountId: ID
) {
  viewer {
    ...AccountStockHoldingList_viewer_shlXP
    id
  }
}

fragment AccountStockHoldingList_viewer_shlXP on Query {
  vues(orderBy: "name", vueType: "MV", parentVue_Isnull: true) {
    edges {
      node {
        id
        name
      }
    }
  }
  accounts(id: $accountId) {
    edges {
      node {
        portfolios {
          edges {
            node {
              id
              trackingVue {
                id
                name
              }
            }
          }
        }
        defaultPortfolio
        holdings {
          id
          contract {
            id
            name
            symbol
            currency
          }
          quantity
          portfolio {
            id
            trackingVue {
              name
              id
            }
          }
        }
        availableHoldings {
          id
          contract {
            id
            name
            symbol
            currency
          }
          quantity
          portfolio {
            id
            trackingVue {
              name
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contract",
    "storageKey": null,
    "args": null,
    "concreteType": "ContractNode",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "symbol",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currency",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "portfolio",
    "storageKey": null,
    "args": null,
    "concreteType": "PortfolioNode",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trackingVue",
        "storageKey": null,
        "args": null,
        "concreteType": "VueNode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountStockHoldingListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountStockHoldingList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountStockHoldingListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": "vues(orderBy:\"name\",parentVue_Isnull:true,vueType:\"MV\")",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "name"
              },
              {
                "kind": "Literal",
                "name": "parentVue_Isnull",
                "value": true
              },
              {
                "kind": "Literal",
                "name": "vueType",
                "value": "MV"
              }
            ],
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "accountId"
              }
            ],
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolios",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PortfolioNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PortfolioNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "trackingVue",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VueNode",
                                    "plural": false,
                                    "selections": (v3/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "defaultPortfolio",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "holdings",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "HoldingBalanceNode",
                        "plural": true,
                        "selections": (v4/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "availableHoldings",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AvailableHoldingBalanceNode",
                        "plural": true,
                        "selections": (v4/*: any*/)
                      },
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountStockHoldingListQuery",
    "id": null,
    "text": "query AccountStockHoldingListQuery(\n  $accountId: ID\n) {\n  viewer {\n    ...AccountStockHoldingList_viewer_shlXP\n    id\n  }\n}\n\nfragment AccountStockHoldingList_viewer_shlXP on Query {\n  vues(orderBy: \"name\", vueType: \"MV\", parentVue_Isnull: true) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  accounts(id: $accountId) {\n    edges {\n      node {\n        portfolios {\n          edges {\n            node {\n              id\n              trackingVue {\n                id\n                name\n              }\n            }\n          }\n        }\n        defaultPortfolio\n        holdings {\n          id\n          contract {\n            id\n            name\n            symbol\n            currency\n          }\n          quantity\n          portfolio {\n            id\n            trackingVue {\n              name\n              id\n            }\n          }\n        }\n        availableHoldings {\n          id\n          contract {\n            id\n            name\n            symbol\n            currency\n          }\n          quantity\n          portfolio {\n            id\n            trackingVue {\n              name\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '908384244f15fe442d9c9cde4343f5d3';
module.exports = node;
