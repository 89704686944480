/**
 * @flow
 * @relayHash 26022504ecb7d88e2f974aec78bcfc7a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VBDividend_all$ref = any;
export type BrokerActivityCreateStockDividendMutationVariables = {|
  corporateActionId?: ?string,
  exDate?: ?any,
  payDate?: ?any,
  reinvestmentPrice?: ?number,
|};
export type BrokerActivityCreateStockDividendMutationResponse = {|
  +vbCreateDividend: ?{|
    +dividend: ?{|
      +$fragmentRefs: VBDividend_all$ref
    |}
  |}
|};
export type BrokerActivityCreateStockDividendMutation = {|
  variables: BrokerActivityCreateStockDividendMutationVariables,
  response: BrokerActivityCreateStockDividendMutationResponse,
|};
*/


/*
mutation BrokerActivityCreateStockDividendMutation(
  $corporateActionId: ID
  $exDate: Date
  $payDate: Date
  $reinvestmentPrice: Float
) {
  vbCreateDividend(corporateActionId: $corporateActionId, exDate: $exDate, payDate: $payDate, reinvestmentPrice: $reinvestmentPrice) {
    dividend {
      ...VBDividend_all
      id
    }
  }
}

fragment VBDividend_all on VBDividendNode {
  id
  processedAt
  processedBy {
    username
    id
  }
  reinvestmentPrice
  exDate
  payDate
  ignored
  reportDate
  ibCorporateAction {
    id
    contract {
      id
      currency
    }
  }
  brokeractivityPtr {
    brokeractivitytransactionSet {
      edges {
        node {
          id
          account {
            name
            id
          }
          brokeractivitytransactioncontractdeltaSet {
            edges {
              node {
                portfolio {
                  id
                }
                contract {
                  id
                  symbol
                }
                quantity
                id
              }
            }
          }
          brokeractivitytransactioncashdeltaSet {
            edges {
              node {
                currency
                quantity
                id
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "corporateActionId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "exDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "payDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reinvestmentPrice",
    "type": "Float",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "corporateActionId",
    "variableName": "corporateActionId"
  },
  {
    "kind": "Variable",
    "name": "exDate",
    "variableName": "exDate"
  },
  {
    "kind": "Variable",
    "name": "payDate",
    "variableName": "payDate"
  },
  {
    "kind": "Variable",
    "name": "reinvestmentPrice",
    "variableName": "reinvestmentPrice"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityCreateStockDividendMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vbCreateDividend",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStockDividend",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dividend",
            "storageKey": null,
            "args": null,
            "concreteType": "VBDividendNode",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "VBDividend_all",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityCreateStockDividendMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vbCreateDividend",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateStockDividend",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dividend",
            "storageKey": null,
            "args": null,
            "concreteType": "VBDividendNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "processedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "processedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "username",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reinvestmentPrice",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "exDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "payDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ignored",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "reportDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ibCorporateAction",
                "storageKey": null,
                "args": null,
                "concreteType": "IBCorporateActionNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contract",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivityPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokeractivitytransactionSet",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityTransactionNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "account",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AccountNode",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncontractdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "portfolio",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "PortfolioNode",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/)
                                            ]
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "contract",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ContractNode",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "symbol",
                                                "args": null,
                                                "storageKey": null
                                              }
                                            ]
                                          },
                                          (v4/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncashdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v4/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityCreateStockDividendMutation",
    "id": null,
    "text": "mutation BrokerActivityCreateStockDividendMutation(\n  $corporateActionId: ID\n  $exDate: Date\n  $payDate: Date\n  $reinvestmentPrice: Float\n) {\n  vbCreateDividend(corporateActionId: $corporateActionId, exDate: $exDate, payDate: $payDate, reinvestmentPrice: $reinvestmentPrice) {\n    dividend {\n      ...VBDividend_all\n      id\n    }\n  }\n}\n\nfragment VBDividend_all on VBDividendNode {\n  id\n  processedAt\n  processedBy {\n    username\n    id\n  }\n  reinvestmentPrice\n  exDate\n  payDate\n  ignored\n  reportDate\n  ibCorporateAction {\n    id\n    contract {\n      id\n      currency\n    }\n  }\n  brokeractivityPtr {\n    brokeractivitytransactionSet {\n      edges {\n        node {\n          id\n          account {\n            name\n            id\n          }\n          brokeractivitytransactioncontractdeltaSet {\n            edges {\n              node {\n                portfolio {\n                  id\n                }\n                contract {\n                  id\n                  symbol\n                }\n                quantity\n                id\n              }\n            }\n          }\n          brokeractivitytransactioncashdeltaSet {\n            edges {\n              node {\n                currency\n                quantity\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a9f99cd129e5485358a13b60cec12678';
module.exports = node;
