/**
 * @flow
 * @relayHash a144a5903e2a6ee84c74cfe0a548ed71
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type CancelPortfolioOrderRequestMutationVariables = {|
  approvableId: string
|};
export type CancelPortfolioOrderRequestMutationResponse = {|
  +cancelApprovable: ?{|
    +approvable: ?({|
      +__typename: "PortfolioOrderRequestNode",
      +status: ApprovableStatus,
      +approvals: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +approvedAt: any,
            +approvedBy: {|
              +username: string,
              +fullName: ?string,
              +email: string,
            |},
          |}
        |}>
      |},
      +portfolioOrder: {|
        +id: string,
        +stockOrders: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +contract: {|
                +id: string,
                +symbol: string,
              |},
              +brokerOrder: ?{|
                +id: string,
                +status: OrderStatus,
              |},
            |}
          |}>
        |},
      |},
    |} | {|
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      +__typename: "%other"
    |})
  |}
|};
export type CancelPortfolioOrderRequestMutation = {|
  variables: CancelPortfolioOrderRequestMutationVariables,
  response: CancelPortfolioOrderRequestMutationResponse,
|};
*/


/*
mutation CancelPortfolioOrderRequestMutation(
  $approvableId: ID!
) {
  cancelApprovable(approvableId: $approvableId) {
    approvable {
      __typename
      ... on PortfolioOrderRequestNode {
        status
        approvals {
          edges {
            node {
              id
              approvedAt
              approvedBy {
                username
                fullName
                email
                id
              }
            }
          }
        }
        portfolioOrder {
          id
          stockOrders {
            edges {
              node {
                id
                contract {
                  id
                  symbol
                }
                brokerOrder {
                  id
                  status
                }
              }
            }
          }
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "approvableId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "approvableId",
    "variableName": "approvableId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "approvedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "portfolioOrder",
  "storageKey": null,
  "args": null,
  "concreteType": "PortfolioOrderNode",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "stockOrders",
      "storageKey": null,
      "args": null,
      "concreteType": "StockOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StockOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StockOrderNode",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contract",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractNode",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "symbol",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "brokerOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderNode",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v3/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelPortfolioOrderRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelApprovable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelApprovable",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvable",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "PortfolioOrderRequestNode",
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvals",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalNode",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "approvedBy",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelPortfolioOrderRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cancelApprovable",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelApprovable",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvable",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "PortfolioOrderRequestNode",
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvals",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalNode",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "approvedBy",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelPortfolioOrderRequestMutation",
    "id": null,
    "text": "mutation CancelPortfolioOrderRequestMutation(\n  $approvableId: ID!\n) {\n  cancelApprovable(approvableId: $approvableId) {\n    approvable {\n      __typename\n      ... on PortfolioOrderRequestNode {\n        status\n        approvals {\n          edges {\n            node {\n              id\n              approvedAt\n              approvedBy {\n                username\n                fullName\n                email\n                id\n              }\n            }\n          }\n        }\n        portfolioOrder {\n          id\n          stockOrders {\n            edges {\n              node {\n                id\n                contract {\n                  id\n                  symbol\n                }\n                brokerOrder {\n                  id\n                  status\n                }\n              }\n            }\n          }\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f9630e476992528d6969996e5fdaf9e';
module.exports = node;
