import React from 'react';
import Grid from '@material-ui/core/Grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddToGroupIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import TableIconButton from '../common/table/TableIconButton';
import {
  assignToBrokerActivityGroup,
  createBrokerActivityGroup,
  removeFromBrokerActivityGroup,
} from '../../mutations/BrokerActivityGroup';
import TableStatus from '../common/TableStatus';
import { fromGlobalId } from '../../helpers/ID';
import TableButton from '../common/buttons/TableButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import { doCommitPromise } from '../../common/commit';

export default function BrokerActivityGroupActions(props) {
  const {
    brokerActivity,
    msgRouter,
    groupName,
    onClickGroup,
    extraParams,
  } = props;
  const key = `${brokerActivity.id}-${brokerActivity.updatedAt}`;
  const [lockedGroup, setLockedGroup] = React.useState(msgRouter.state.lockedGroup);

  React.useEffect(() => {
    const onPublishState = (client, state) => {
      const { lockedGroup: bts } = state;
      setLockedGroup(bts);
    };
    msgRouter.addStateListener(onPublishState);
    return function cleanup() {
      msgRouter.removeStateListener(onPublishState);
    };
  }, []);

  const hasGroup = !!brokerActivity.group;
  const groupLocked = !!lockedGroup;
  const isInLockedGroup = hasGroup && groupLocked && (brokerActivity.group.id === lockedGroup.id);

  let groupCaption = '';
  if (brokerActivity.group) {
    groupCaption = `${fromGlobalId(brokerActivity.group.id)[1]} - ${brokerActivity.group.description}`;
  }

  return (
    <Grid container spacing={1} justify="center" alignItems="center">
      {
        brokerActivity.group && (
          <Grid item>
            {
              isInLockedGroup ? (
                <TableStatus secondary>{groupCaption}</TableStatus>
              ) : (
                <TableButton
                  buttonText={groupCaption}
                  onClick={() => onClickGroup && onClickGroup()}
                >
                  {groupCaption}
                </TableButton>
              )
            }
          </Grid>
        )
      }
      {
        !brokerActivity.group && (
          <TableCompactGridItem>
            <TableIconButton
              key={key}
              tooltip="Add Group"
              color="primary"
              iconClass={groupLocked ? AddToGroupIcon : AddCircleIcon}
              onClickPromise={() => (groupLocked
                ? doCommitPromise(
                  assignToBrokerActivityGroup,
                  {
                    brokerActivityGroupId: lockedGroup.id,
                    brokerActivityId: brokerActivity.id,
                  },
                )
                : doCommitPromise(
                  createBrokerActivityGroup,
                  {
                    description: groupName,
                    brokerActivityIds: [brokerActivity.id],
                    ...((extraParams && extraParams(brokerActivity)) || {}),
                  },
                )
              )}
            />
          </TableCompactGridItem>
        )
      }
      {
        hasGroup && (
          <TableCompactGridItem>
            <TableIconButton
              key={key}
              disabled={groupLocked && !isInLockedGroup}
              tooltip="Lock Group"
              color="primary"
              iconClass={isInLockedGroup ? UnlockIcon : LockIcon}
              onClick={() => {
                msgRouter.publishState(this, {
                  lockedGroup: groupLocked ? null : brokerActivity.group,
                });
              }}
            />
          </TableCompactGridItem>
        )
      }

      {
        brokerActivity.group && (
          <TableCompactGridItem>
            <TableIconButton
              key={key}
              tooltip="Remove From Group"
              color="secondary"
              iconClass={RemoveCircleIcon}
              onClickPromise={() => doCommitPromise(
                removeFromBrokerActivityGroup,
                {
                  brokerActivityId: brokerActivity.id,
                },
              )}
            />
          </TableCompactGridItem>
        )
      }

    </Grid>
  );
}
