/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountStockHoldingPortfolios_portfolios$ref: FragmentReference;
declare export opaque type AccountStockHoldingPortfolios_portfolios$fragmentType: AccountStockHoldingPortfolios_portfolios$ref;
export type AccountStockHoldingPortfolios_portfolios = {|
  +portfolios: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +trackingVue: ?{|
          +id: string,
          +name: string,
        |},
      |}
    |}>
  |},
  +defaultPortfolio: ?string,
  +$refType: AccountStockHoldingPortfolios_portfolios$ref,
|};
export type AccountStockHoldingPortfolios_portfolios$data = AccountStockHoldingPortfolios_portfolios;
export type AccountStockHoldingPortfolios_portfolios$key = {
  +$data?: AccountStockHoldingPortfolios_portfolios$data,
  +$fragmentRefs: AccountStockHoldingPortfolios_portfolios$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AccountStockHoldingPortfolios_portfolios",
  "type": "AccountNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolios",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "trackingVue",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "VueNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "defaultPortfolio",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e46a1f10993a40c266ccb884dd6ae23';
module.exports = node;
