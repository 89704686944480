import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import TopGridContainer from '../common/containers/TopGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import TableFieldMeta from '../../common/TableFieldMeta';
import BrokerageCreditForm from './BrokerageCreditForm';
import BrokerageCreditUpdateForm from './BrokerageCreditUpdateForm';
import TableStatus from '../common/TableStatus';
import EnumValue from '../common/EnumValue';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';

const colorPropsForStatus = (status) => {
  if (status === 'APPROVED') return { primary: true };
  if (status === 'CANCELLED') return { secondary: true };
  return {};
};

function BrokerageCreditList(props) {
  const {
    accountId,
    viewer,
    filter,
    relay,
  } = props;
  filter.setRelay(relay);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('description', 'Description'),
    TableFieldMeta.field('note', 'Note'),
    TableFieldMeta.customCell('', 'Remaining Amount', row => (
      `${row.value.remainingAmount} out of ${row.value.initialAmount}`
    ), { align: 'right' }),
    TableFieldMeta.enum('CreditType', 'Credit Type', node => node.creditType),
    TableFieldMeta.field('expiryDate', 'Expiry Date'),
    TableFieldMeta.field(node => (
      <TableStatus {...colorPropsForStatus(node.status)}>
        <EnumValue
          enumValue={node.status}
          enumType="ApprovableStatus"
        />
      </TableStatus>
    ), 'Status', { align: 'center' }),
    TableFieldMeta.customCell('', '', row => (
      <BrokerageCreditUpdateForm brokerageCredit={row.value} />
    )),
    TableFieldMeta.account(
      node => node.account,
      'Account',
      { charLimit: 40, showNumber: true },
    ),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <TopGridContainer>
        <Grid item />
        <Grid item>
          <AddButton
            buttonText="Brokerage Credit"
            onClick={() => setDrawerOpen(true)}
          />
        </Grid>
      </TopGridContainer>
      <Paper>
        <TablePaginator
          getDataNode={() => (viewer ? viewer.brokerageCredits : null)}
          columns={columns}
          filter={filter}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <BrokerageCreditForm
          accountId={accountId}
          onClose={() => setDrawerOpen(false)}
          onAdd={() => filter.update()}
        />
      </Drawer>
    </div>
  );
}

const query = graphql`
  query BrokerageCreditListQuery (
    $accountId: ID,
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...BrokerageCreditList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        accountId: $accountId,
      )
    }
  }
`;

const container = createRefetchContainer(
  BrokerageCreditList,
  {
    viewer: graphql`
      fragment BrokerageCreditList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID, defaultValue: null }
      ) {
        brokerageCredits (
          account: $accountId
          first: $first
          offset: $offset
          orderBy: "-createdAt"
        ) {
          count
          edges {
            node {
              id
              account {
                id
                name
              }
              description
              expiryDate
              initialAmount
              remainingAmount
              note
              creditType
              consumed
              status
              provisionedBy {
                id
                fullName
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
));
