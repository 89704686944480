import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { createNewVue } from '../../mutations/Vue';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';


function VueAddForm(props) {
  const {
    tenantId,
    onAdd,
  } = props;
  const classes = useCommonStyles();
  const [members] = React.useState({
    newVueName: null,
  });
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add Vue"
        />
        <DrawerGridItemTextField
          sm={12}
          label="Vue Name"
          onChange={(e) => {
            members.newVueName = e.target.value;
          }}
        />
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                createNewVue,
                {
                  name: members.newVueName,
                  tenantId,
                },
                () => (onAdd && onAdd()),
                () => null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}

export default VueAddForm;
