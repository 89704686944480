import React from 'react';
import { commitMutation } from 'react-relay';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import {
  Dialog, Drawer, Grid, IconButton, 
} from '@material-ui/core';
import {
  Add, Edit, Email, RemoveCircle, 
} from '@material-ui/icons';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import UserLink from '../../common/links/UserLink';
import environment from '../../../Environment';
import updateEmailRecipient from '../../../mutations/UpdateEmailRecipient';
import AddUserFrom from './AddUserForm';
import UserPermissionUpdateForm from '../../user/UserPermissionUpdateForm';
import TableIconButton from '../../common/table/TableIconButton';
import removeUserFromAccount from '../../../mutations/RemoveUserFromAccountMutation';
import ConfirmationDlg from '../../common/dialogs/ConfirmationDlg';
import { UserRoleDrawerContainer } from '../../permission/UserRoleForm';
import { doCommitPromise } from '../../../common/commit';

function ClientUserInfo(props) {
  const { clientAccount } = props;
  if (!clientAccount) return null;
  const removeUserDisabled = clientAccount.userModels.edges.length <= 1;
  const initialEmailRecipientIds = clientAccount.activeEmailRecipients.map(
    recipient => recipient.id,
  );
  const [activeEmailRecipientIds, setActiveEmailRecipientIds] = React.useState(
    initialEmailRecipientIds,
  );
  const [addUserAddUserDrawerOpen, setAddUserDrawerOpen] = React.useState(false);
  const [editUserPermissionDrawerOpen, setEditUserPermissionDrawerOpen] = React.useState(false);
  const [userModelForPermissionUpdate, setUserModelForPermissionUpdate] = React.useState(null);
  const [userModelForRemoval, setUserModelForRemoval] = React.useState(null);


  const doUpdateEmailRecipient = (userModelId) => {
    const variables = {
      clientAccountId: clientAccount.id,
      userModelId,
      receivesEmail: !activeEmailRecipientIds.includes(userModelId),
    };

    commitMutation(environment, {
      mutation: updateEmailRecipient,
      variables,
      onCompleted: (response, errors) => {
        if (response.updateEmailRecipient) {
          setActiveEmailRecipientIds(
            response.updateEmailRecipient.activeEmailRecipients.map(recipient => recipient.id),
          );
        }
      },
    });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={addUserAddUserDrawerOpen}
        onClose={() => setAddUserDrawerOpen(false)}
      >
        <AddUserFrom
          clientAccount={clientAccount}
          onClose={() => setAddUserDrawerOpen(false)}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={editUserPermissionDrawerOpen}
        onClose={() => setEditUserPermissionDrawerOpen(false)}
      >
        <UserRoleDrawerContainer>
          <UserPermissionUpdateForm
            userModel={userModelForPermissionUpdate}
            clientAccount={clientAccount}
            close={() => setEditUserPermissionDrawerOpen(false)}
          />
        </UserRoleDrawerContainer>
      </Drawer>

      <Dialog
        open={userModelForRemoval != null}
        onClose={() => setUserModelForRemoval(null)}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <ConfirmationDlg
          message="Are you sure you want to remove the user from this account?"
          options={[{ id: 0, caption: 'Yes' }, { id: 1, caption: 'No' }]}
          onOption={(option) => {
            if (option === 0) {
              return doCommitPromise(
                removeUserFromAccount,
                {
                  clientAccountId: clientAccount.id,
                  userId: userModelForRemoval.id,
                },
                () => setUserModelForRemoval(null),
              );
            }
            setUserModelForRemoval(null);
            return null;
          }}
        />
      </Dialog>

      <TitledWidgetItem
        xs={12}
        title="Users"
        rightTopComp={(
          <IconButton
            color="primary"
            aria-label="delete"
            onClick={() => setAddUserDrawerOpen(true)}
          >
            <Add />
          </IconButton>
        )}
      >
        <Table size="small">
          <TableHead>
            <TableCell leftAlign>ID</TableCell>
            <TableCell align="left">Username</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableHead>
          <TableBody>
            {
              clientAccount.userModels.edges.map(
                userModel => (
                  <TableRow>
                    <TableCell leftAlign><UserLink user={userModel.node} /></TableCell>
                    <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                      {userModel.node.username}
                    </TableCell>
                    <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                      {userModel.node.email}
                    </TableCell>
                    <TableCell align="center">
                      <Grid container justify="center">
                        <Grid item>
                          <TableIconButton
                            key={`${userModel.node.id}_edit_permissions`}
                            tooltip="Edit User Permissions"
                            iconClass={Edit}
                            color="primary"
                            onClick={() => {
                              setUserModelForPermissionUpdate(userModel.node);
                              setEditUserPermissionDrawerOpen(true);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <TableIconButton
                            key={`${userModel.node.id}_toggle_email`}
                            tooltip={
                              activeEmailRecipientIds.includes(userModel.node.id)
                                ? 'Disable Email Notifications'
                                : 'Enable Email Notifications'
                            }
                            iconClass={Email}
                            disabled={
                              activeEmailRecipientIds.includes(userModel.node.id)
                              && activeEmailRecipientIds.length <= 1
                            }
                            color={
                              activeEmailRecipientIds.includes(userModel.node.id)
                                ? 'secondary'
                                : 'primary'
                            }
                            onClick={() => doUpdateEmailRecipient(userModel.node.id)}
                          />

                        </Grid>
                        <Grid item>
                          <TableIconButton
                            key={`${userModel.node.id}_remove_user`}
                            tooltip="Remove User"
                            iconClass={RemoveCircle}
                            disabled={removeUserDisabled}
                            color="secondary"
                            onClick={() => setUserModelForRemoval(userModel.node)}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ),
              )
            }
            {/*{*/}
            {/*  account.tenantaccount && (*/}
            {/*    <TableRow>*/}
            {/*      <TableCell leftAlign>*/}
            {/*        {fromGlobalId(account.tenantaccount.tenant.id)[1]}*/}
            {/*      </TableCell>*/}
            {/*      <TableCell align="right">{account.tenantaccount.tenant.email}</TableCell>*/}
            {/*    </TableRow>*/}
            {/*  )*/}
            {/*}*/}
          </TableBody>
        </Table>

      </TitledWidgetItem>
    </>
  );
}

export default ClientUserInfo;
