/**
 * @flow
 * @relayHash 251a61170ddfca6cb4061faefd964f47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeeTemplateList_viewer$ref = any;
export type FeeTemplateListContainerQueryVariables = {|
  first?: ?number,
  offset?: ?number,
|};
export type FeeTemplateListContainerQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: FeeTemplateList_viewer$ref
  |}
|};
export type FeeTemplateListContainerQuery = {|
  variables: FeeTemplateListContainerQueryVariables,
  response: FeeTemplateListContainerQueryResponse,
|};
*/


/*
query FeeTemplateListContainerQuery(
  $first: Int
  $offset: Int
) {
  viewer {
    ...FeeTemplateList_viewer_21Sbsv
    id
  }
}

fragment FeeTemplateList_viewer_21Sbsv on Query {
  feeTemplates(offset: $offset, first: $first) {
    edges {
      node {
        notes {
          id
        }
        id
        name
        description
        type
        createdAt
        updatedAt
        currency
        fees {
          edges {
            node {
              id
              feeType
              enabled
            }
          }
        }
        tenantexchangefeetemplateSet {
          edges {
            node {
              id
            }
          }
        }
        tenantSet {
          edges {
            node {
              name
              id
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeTemplateListContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FeeTemplateList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeTemplateListContainerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeTemplates",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "FeeTemplateNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeTemplateNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeTemplateNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "notes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NotesNode",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      },
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fees",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FeeNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FeeNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "feeType",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "enabled",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantexchangefeetemplateSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantExchangeFeeTemplateNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantExchangeFeeTemplateNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TenantExchangeFeeTemplateNode",
                                "plural": false,
                                "selections": (v3/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FeeTemplateListContainerQuery",
    "id": null,
    "text": "query FeeTemplateListContainerQuery(\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...FeeTemplateList_viewer_21Sbsv\n    id\n  }\n}\n\nfragment FeeTemplateList_viewer_21Sbsv on Query {\n  feeTemplates(offset: $offset, first: $first) {\n    edges {\n      node {\n        notes {\n          id\n        }\n        id\n        name\n        description\n        type\n        createdAt\n        updatedAt\n        currency\n        fees {\n          edges {\n            node {\n              id\n              feeType\n              enabled\n            }\n          }\n        }\n        tenantexchangefeetemplateSet {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        tenantSet {\n          edges {\n            node {\n              name\n              id\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be48896cd01ab6af5c9a87de2fecfb93';
module.exports = node;
