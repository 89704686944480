
import { graphql } from 'react-relay';

// language=GraphQL
const upsertFeeComponentMutation = graphql`
  mutation UpsertFeeComponentMutation(
    $feeId: ID!
    $feeComponentId: ID
    $tenantId: ID!
    $minChargePoint: Decimal
    $maxChargePoint: Decimal
    $flatChargeAmount: Decimal
    $percentChargeAmount: Decimal
    $calculationType: FeeCalculationType!
    $minPriceChargePoint: Decimal
    $maxPriceChargePoint: Decimal
  ){
    upsertFeeComponent(
      feeComponentId: $feeComponentId
      minChargePoint: $minChargePoint
      maxChargePoint: $maxChargePoint
      flatChargeAmount: $flatChargeAmount
      percentChargeAmount: $percentChargeAmount
      tenantId: $tenantId
      calculationType: $calculationType
      feeId: $feeId
      minPriceChargePoint: $minPriceChargePoint
      maxPriceChargePoint: $maxPriceChargePoint
    ) {
      errors {
        field
        messages
      }

      feeComponent {
        id
        createdAt
        updatedAt
        calculationType
        flatChargeAmount
        minChargePoint
        maxChargePoint
        minPriceChargePoint
        maxPriceChargePoint
        percentChargeAmount
        tenant {
          id
          name
        }
        fee {
          id
          feeComponents {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }`;

export default upsertFeeComponentMutation;
