/**
 * @flow
 * @relayHash 26b44ad487f276f32590c41e05b53cb4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IBCorporateAction_all$ref = any;
export type BrokerActivityUnprocessMutationVariables = {|
  brokerActivityId?: ?string
|};
export type BrokerActivityUnprocessMutationResponse = {|
  +unprocessBrokerActivity: ?{|
    +corporateAction: ?{|
      +$fragmentRefs: IBCorporateAction_all$ref
    |}
  |}
|};
export type BrokerActivityUnprocessMutation = {|
  variables: BrokerActivityUnprocessMutationVariables,
  response: BrokerActivityUnprocessMutationResponse,
|};
*/


/*
mutation BrokerActivityUnprocessMutation(
  $brokerActivityId: ID
) {
  unprocessBrokerActivity(brokerActivityId: $brokerActivityId) {
    corporateAction: brokerActivity {
      __typename
      ... on IBCorporateActionNode {
        ...IBCorporateAction_all
      }
      ... on Node {
        id
      }
    }
  }
}

fragment IBCorporateAction_all on IBCorporateActionNode {
  id
  ignored
  processedAt
  processedBy {
    username
    fullName
    id
  }
  group {
    id
    description
  }
  transactionId
  ibConId
  currency
  description
  isin
  symbol
  transactionDatetime
  reportDate
  quantity
  proceeds
  type
  contract {
    id
    symbol
    currency
    ibConId
  }
  notes {
    id
  }
  brokeractivityPtr {
    id
    group {
      id
      description
    }
    responsiblecontract {
      contract {
        symbol
        id
        ibConId
        name
        currency
        exchange {
          name
          symbol
          id
        }
      }
      id
    }
    brokeractivitytransactionSet {
      edges {
        node {
          id
          account {
            name
            id
          }
          brokeractivitytransactioncontractdeltaSet {
            edges {
              node {
                portfolio {
                  id
                }
                contract {
                  id
                  symbol
                }
                quantity
                id
              }
            }
          }
          brokeractivitytransactioncashdeltaSet {
            edges {
              node {
                currency
                quantity
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerActivityId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerActivityId",
    "variableName": "brokerActivityId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "group",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerActivityGroupNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibConId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v9 = [
  (v2/*: any*/)
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityUnprocessMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unprocessBrokerActivity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UnprocessBrokerActivity",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "corporateAction",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "type": "IBCorporateActionNode",
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "IBCorporateAction_all",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityUnprocessMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "unprocessBrokerActivity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UnprocessBrokerActivity",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "corporateAction",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "IBCorporateActionNode",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "ignored",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "processedAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "processedBy",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fullName",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "transactionId",
                    "args": null,
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isin",
                    "args": null,
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "transactionDatetime",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "reportDate",
                    "args": null,
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "proceeds",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contract",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "notes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotesNode",
                    "plural": false,
                    "selections": (v9/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokeractivityPtr",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "responsiblecontract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ResponsibleContractNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v10/*: any*/),
                              (v6/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exchange",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ExchangeNode",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokeractivitytransactionSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "account",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AccountNode",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "brokeractivitytransactioncontractdeltaSet",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "portfolio",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "PortfolioNode",
                                                "plural": false,
                                                "selections": (v9/*: any*/)
                                              },
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "contract",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "ContractNode",
                                                "plural": false,
                                                "selections": [
                                                  (v2/*: any*/),
                                                  (v7/*: any*/)
                                                ]
                                              },
                                              (v8/*: any*/),
                                              (v2/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "brokeractivitytransactioncashdeltaSet",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                            "plural": false,
                                            "selections": [
                                              (v6/*: any*/),
                                              (v8/*: any*/),
                                              (v2/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityUnprocessMutation",
    "id": null,
    "text": "mutation BrokerActivityUnprocessMutation(\n  $brokerActivityId: ID\n) {\n  unprocessBrokerActivity(brokerActivityId: $brokerActivityId) {\n    corporateAction: brokerActivity {\n      __typename\n      ... on IBCorporateActionNode {\n        ...IBCorporateAction_all\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n\nfragment IBCorporateAction_all on IBCorporateActionNode {\n  id\n  ignored\n  processedAt\n  processedBy {\n    username\n    fullName\n    id\n  }\n  group {\n    id\n    description\n  }\n  transactionId\n  ibConId\n  currency\n  description\n  isin\n  symbol\n  transactionDatetime\n  reportDate\n  quantity\n  proceeds\n  type\n  contract {\n    id\n    symbol\n    currency\n    ibConId\n  }\n  notes {\n    id\n  }\n  brokeractivityPtr {\n    id\n    group {\n      id\n      description\n    }\n    responsiblecontract {\n      contract {\n        symbol\n        id\n        ibConId\n        name\n        currency\n        exchange {\n          name\n          symbol\n          id\n        }\n      }\n      id\n    }\n    brokeractivitytransactionSet {\n      edges {\n        node {\n          id\n          account {\n            name\n            id\n          }\n          brokeractivitytransactioncontractdeltaSet {\n            edges {\n              node {\n                portfolio {\n                  id\n                }\n                contract {\n                  id\n                  symbol\n                }\n                quantity\n                id\n              }\n            }\n          }\n          brokeractivitytransactioncashdeltaSet {\n            edges {\n              node {\n                currency\n                quantity\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0629460436b0d308efb0cc48f0ecf01';
module.exports = node;
