/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StockOrderDetails_stockOrder$ref = any;
export type PortfolioOrderSide = "BUY" | "REB" | "SELL" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PortfolioOrderDetails_portfolioOrder$ref: FragmentReference;
declare export opaque type PortfolioOrderDetails_portfolioOrder$fragmentType: PortfolioOrderDetails_portfolioOrder$ref;
export type PortfolioOrderDetails_portfolioOrder = {|
  +id: string,
  +side: PortfolioOrderSide,
  +createdAt: any,
  +updatedAt: any,
  +status: PortfolioOrderStatus,
  +bulkOrder: ?{|
    +id: string
  |},
  +portfolio: {|
    +id: string,
    +trackingVueName: ?string,
    +trackingVue: ?{|
      +id: string,
      +name: string,
    |},
  |},
  +account: {|
    +id: string,
    +name: ?string,
  |},
  +tradeInvoice: ?{|
    +pdf: ?string,
    +pdfUrl: ?string,
    +createdAt: any,
    +updatedAt: any,
    +state: ReportState,
  |},
  +stockOrders: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: StockOrderDetails_stockOrder$ref
      |}
    |}>
  |},
  +$refType: PortfolioOrderDetails_portfolioOrder$ref,
|};
export type PortfolioOrderDetails_portfolioOrder$data = PortfolioOrderDetails_portfolioOrder;
export type PortfolioOrderDetails_portfolioOrder$key = {
  +$data?: PortfolioOrderDetails_portfolioOrder$data,
  +$fragmentRefs: PortfolioOrderDetails_portfolioOrder$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "PortfolioOrderDetails_portfolioOrder",
  "type": "PortfolioOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "side",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bulkOrder",
      "storageKey": null,
      "args": null,
      "concreteType": "BulkOrderNode",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolio",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trackingVueName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "trackingVue",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNode",
          "plural": false,
          "selections": (v3/*: any*/)
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "account",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountNode",
      "plural": false,
      "selections": (v3/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tradeInvoice",
      "storageKey": null,
      "args": null,
      "concreteType": "TradeInvoiceNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdf",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdfUrl",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "stockOrders",
      "storageKey": "stockOrders(orderBy:\"createdAt\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "createdAt"
        }
      ],
      "concreteType": "StockOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "StockOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "StockOrderNode",
              "plural": false,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "StockOrderDetails_stockOrder",
                  "args": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fca4c53546d3874dac28f4de70aca298';
module.exports = node;
