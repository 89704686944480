import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import PaginatedTable from '../common/PaginatedTable';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import RelayFilter from '../../common/RelayFilter';

const exchangeName = (node) => {
  if (!node.fsExchange) return null;
  const { fsExchange } = node;
  return `${fsExchange.exchangeCode} : ${fsExchange.symbol}`;
};

function FactsetContractList(props) {
  const {
    relay,
    viewer,
  } = props;

  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
    symbol: null,
    conId: null,
    isin: null,
  }, ['search', 'isin']));

  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('isin', 'ISIN'),
    // TableFieldMeta.field(node => node.exchange.symbol, 'Exchange'),
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.field('exchangeSymbol', 'Ex.Symbol'),
    TableFieldMeta.field('fsId', 'Factset.ID'),
    TableFieldMeta.field('exchange', 'Fs.Exchange', { accessor: exchangeName }),
  ];


  return (
    <div>
      <div className={classes.formControlContainer}>
        <BaseGridContainer>
          <InnerTabFilterGridItemTextField
            id="search-filter"
            label="Custom Search"
            helperText="id, symbol, factset-name"
            onChange={e => relayFilter.updateVar('search', e.target.value)}
          />

          <InnerTabFilterGridItemTextField
            id="isin-search"
            label="ISIN"
            helperText="isin"
            onChange={e => relayFilter.updateVar('isin', e.target.value || null)}
          />

        </BaseGridContainer>
      </div>

      <PaginatedTable
        columns={columns}
        ref={relayFilter.tableRef}
        viewer={viewer}
        getDataNode={() => (viewer ? viewer.factsetContracts : null)}
        getFetchParams={relayFilter.fetchParams}
        relay={relay}
      />
    </div>
  );
}


const query = graphql`
  query FactsetContractListQuery (
    $first: Int
    $offset: Int
    $search: String
    $isin: String
  ) {
    viewer {
      ...FactsetContractList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        isin: $isin
      )
    }
  }
`;

const container = createRefetchContainer(
  FactsetContractList,
  {
    viewer: graphql`
      fragment FactsetContractList_viewer on Query      
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        isin: {type: String}
      ) {
        factsetContracts(
          first: $first
          offset: $offset
          search: $search
          isin: $isin
        ) {
          edges {
            node {
              id
              sedol
              isin
              fsExchange {
                id
                exchangeCode
                symbol
              }
              name
              fsId
              country
              industry
              sector
              exchangeSymbol
              fsymId
              lotSize
            }
          }
          count
        }
      }
    `,
  },
  query,
);

FactsetContractList.propTypes = {
};

FactsetContractList.defaultProps = {
};

export default createQueryRendererProgress(
  query,
  container,
);
