/**
 * @flow
 * @relayHash 154d889a4edc24934bf0ff71bf6ec6a1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateUserProvisionRequestMutationVariables = {|
  clientAccountId: string,
  email: string,
  firstName?: ?string,
  lastName?: ?string,
  userRoleIds: $ReadOnlyArray<?string>,
|};
export type CreateUserProvisionRequestMutationResponse = {|
  +createUserProvisionRequest: ?{|
    +userProvisionRequest: ?{|
      +id: string,
      +userModel: {|
        +id: string,
        +username: string,
        +email: string,
      |},
      +clientAccount: ?{|
        +id: string,
        +name: ?string,
      |},
      +status: string,
      +approvals: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +approvedBy: {|
              +id: string,
              +username: string,
            |},
            +approvedAt: any,
          |}
        |}>
      |},
      +hasCompleteApprovals: ?boolean,
    |}
  |}
|};
export type CreateUserProvisionRequestMutation = {|
  variables: CreateUserProvisionRequestMutationVariables,
  response: CreateUserProvisionRequestMutationResponse,
|};
*/


/*
mutation CreateUserProvisionRequestMutation(
  $clientAccountId: ID!
  $email: String!
  $firstName: String
  $lastName: String
  $userRoleIds: [ID]!
) {
  createUserProvisionRequest(clientAccountId: $clientAccountId, email: $email, firstName: $firstName, lastName: $lastName, userRoleIds: $userRoleIds) {
    userProvisionRequest {
      id
      userModel {
        id
        username
        email
      }
      clientAccount {
        id
        name
      }
      status
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "email",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "firstName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lastName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userRoleIds",
    "type": "[ID]!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUserProvisionRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "userRoleIds",
        "variableName": "userRoleIds"
      }
    ],
    "concreteType": "CreateUserProvisionRequest",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userProvisionRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "UserProvisionRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userModel",
            "storageKey": null,
            "args": null,
            "concreteType": "UserModelNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "clientAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "ClientAccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvals",
            "storageKey": null,
            "args": null,
            "concreteType": "ApprovalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approvedAt",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasCompleteApprovals",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUserProvisionRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUserProvisionRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateUserProvisionRequestMutation",
    "id": null,
    "text": "mutation CreateUserProvisionRequestMutation(\n  $clientAccountId: ID!\n  $email: String!\n  $firstName: String\n  $lastName: String\n  $userRoleIds: [ID]!\n) {\n  createUserProvisionRequest(clientAccountId: $clientAccountId, email: $email, firstName: $firstName, lastName: $lastName, userRoleIds: $userRoleIds) {\n    userProvisionRequest {\n      id\n      userModel {\n        id\n        username\n        email\n      }\n      clientAccount {\n        id\n        name\n      }\n      status\n      approvals {\n        edges {\n          node {\n            id\n            approvedBy {\n              id\n              username\n            }\n            approvedAt\n          }\n        }\n      }\n      hasCompleteApprovals\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '702849494736ccee6ccd43202074b3c7';
module.exports = node;
