import React from 'react';
import { Grid } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ProcessButton } from '../common/buttons/BaseButton';
import DrawerGridItemParagraph from '../common/drawer/DrawerGridItemParagraph';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemSubGroupHeading from '../common/drawer/DrawerGridItemSubGroupHeading';
import DrawerGridItemDateField from '../common/drawer/DrawerGridItemDateField';
import { doCommitPromise } from '../../common/commit';
import executeCron from '../../mutations/Cron';
import mvMutationErrors from '../../common/errorReport';


function ParamLineItem(props) {
  const { paramDef, onSetValue } = props;
  const [override, setOverride] = React.useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [members] = React.useState({
    value: null,
  });
  return (
    <>
      <DrawerGridItemSubGroupHeading heading={paramDef.id} xs={12} />
      {
        paramDef.description && (
          <DrawerGridItemParagraph xs={12}>
            { paramDef.description }
          </DrawerGridItemParagraph>
        )
      }
      <DrawerGridItemCheckBox
        xs={3}
        label="Specify"
        value={override}
        onChange={(e) => {
          setOverride(e.target.checked);
          onSetValue && onSetValue(paramDef.id, members.value, e.target.checked);
        }}
      />

      {
        paramDef.type === 'date' && (
          <DrawerGridItemDateField
            xs={9}
            disabled={!override}
            label={paramDef.id}
            onChange={(sd) => {
              members.value = sd;
              setDateValue(sd);
              onSetValue && onSetValue(paramDef.id, sd, override);
            }}
            value={dateValue}
            autoOk
          />
        )
      }

      {
        paramDef.type === 'str' && (
          <DrawerGridItemTextField
            disabled={!override}
            xs={9}
            label={paramDef.id}
            onChange={(e) => {
              members.value = e.target.value;
              onSetValue && onSetValue(paramDef.id, e.target.value, override);
            }}
            autoOk
          />
        )
      }

      {
        paramDef.type === 'int' && (
          <DrawerGridItemTextField
            disabled={!override}
            xs={9}
            label={paramDef.id}
            onChange={(e) => {
              members.value = e.target.value;
              onSetValue && onSetValue(paramDef.id, Number(e.target.value), override);
            }}
            autoOk
          />
        )
      }


    </>
  );
}

const fromIt = (xMap) => {
  const altered = Object.entries(xMap).filter(([x, y]) => y[0] === true).map(x => [x[0], x[1][1]]);
  return Object.fromEntries(altered);
};

export default function CronExecutionForm(props) {
  const { cron, onResponse } = props;
  const classes = useCommonStyles();
  if (!cron) return <div className={classes.drawerRoot} />;
  const [members] = React.useState({
    outParams: {},
  });

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Run Cron with Parameters"
        />
        {
          cron.paramDefs.length === 0 ? (
            <DrawerGridItemParagraph xs={12}>
              No parameter definitions found for the cron
            </DrawerGridItemParagraph>
          ) : (
            <div />
          )
        }

        {
          cron.paramDefs.map(paramDef => (
            <ParamLineItem
              paramDef={paramDef}
              onSetValue={(paraKey, paraValue, paraUsed) => {
                members.outParams[paraKey] = [paraUsed, paraValue];
              }}
            />
          ))
        }

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ProcessButton
              buttonText="Run"
              onClickPromise={() => doCommitPromise(
                executeCron,
                {
                  functionUrl: cron.functionUrl,
                  params: fromIt(members.outParams),
                },
                (response) => {
                  if (onResponse) {
                    if (!mvMutationErrors(response, 'executeCron')) {
                      onResponse(response.executeCron.response);
                    }
                  }
                },
                () => null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}
