import { graphql } from 'react-relay';

// language=GraphQL
const createAdviserRoleMutation = graphql`
  mutation CreateAdviserRoleMutation(
    $name: String!
    $defaultPermissions: String
    $rolePermissions: [AdviserPermissionInput]
  ) {
    createAdviserRole(
      name: $name,
      defaultPermissions: $defaultPermissions
      rolePermissions: $rolePermissions
    ) {
    adviserRole {
        id
        name
        rolePermissions {
          edges {
            node {
              id
              resource
              verb
              scope
            }
          }
        }
      }
    }
  }
`;

export default createAdviserRoleMutation;
