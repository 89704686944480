
import { graphql } from 'react-relay';

// language=GraphQL
const generateRemittanceAdviceMutation = graphql`
  mutation GenerateRemittanceAdviceMutation(
    $input: GenerateRemittanceAdviceMutationInput!
  ) {
    generateRemittanceAdvice(input: $input) {
      errors {
        messages
        field
      }
      obj {
        reportPtr {
          id
          createdAt
          xlsxUrl
          reportType
        }
      }
    }
  }`;

export default generateRemittanceAdviceMutation;
