/**
 * @flow
 * @relayHash 5a4465286820b1e67926c23079edcb4c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerageCreditList_viewer$ref = any;
export type BrokerageCreditListQueryVariables = {|
  accountId?: ?string,
  first?: ?number,
  offset?: ?number,
|};
export type BrokerageCreditListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrokerageCreditList_viewer$ref
  |}
|};
export type BrokerageCreditListQuery = {|
  variables: BrokerageCreditListQueryVariables,
  response: BrokerageCreditListQueryResponse,
|};
*/


/*
query BrokerageCreditListQuery(
  $accountId: ID
  $first: Int
  $offset: Int
) {
  viewer {
    ...BrokerageCreditList_viewer_4tQiFT
    id
  }
}

fragment BrokerageCreditList_viewer_4tQiFT on Query {
  brokerageCredits(account: $accountId, first: $first, offset: $offset, orderBy: "-createdAt") {
    count
    edges {
      node {
        id
        account {
          id
          name
        }
        description
        expiryDate
        initialAmount
        remainingAmount
        note
        creditType
        consumed
        status
        provisionedBy {
          id
          fullName
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerageCreditListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BrokerageCreditList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerageCreditListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerageCredits",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "account",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              }
            ],
            "concreteType": "BrokerageCreditNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerageCreditNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerageCreditNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "expiryDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "initialAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "remainingAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "note",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "creditType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "consumed",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "provisionedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerageCreditListQuery",
    "id": null,
    "text": "query BrokerageCreditListQuery(\n  $accountId: ID\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...BrokerageCreditList_viewer_4tQiFT\n    id\n  }\n}\n\nfragment BrokerageCreditList_viewer_4tQiFT on Query {\n  brokerageCredits(account: $accountId, first: $first, offset: $offset, orderBy: \"-createdAt\") {\n    count\n    edges {\n      node {\n        id\n        account {\n          id\n          name\n        }\n        description\n        expiryDate\n        initialAmount\n        remainingAmount\n        note\n        creditType\n        consumed\n        status\n        provisionedBy {\n          id\n          fullName\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26779c644787a1356d3379d9486a666b';
module.exports = node;
