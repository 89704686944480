/**
 * @flow
 * @relayHash 203d5dbf1f6d8429bfd33cf025b963bc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Direction = "IN" | "OUT" | "%future added value";
export type TransferRequestStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type ApprovePositionTransfersMutationVariables = {|
  brokerTransferId?: ?string,
  transferRequestIds?: ?$ReadOnlyArray<?string>,
|};
export type ApprovePositionTransfersMutationResponse = {|
  +approvePositionTransfers: ?{|
    +positionTransferRequests: ?$ReadOnlyArray<?{|
      +id: string,
      +status: TransferRequestStatus,
      +updatedAt: any,
      +createdAt: any,
      +positionTransferPurchases: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +portfolio: {|
              +trackingVueName: ?string
            |},
            +contract: {|
              +id: string,
              +symbol: string,
              +name: ?string,
            |},
          |}
        |}>
      |},
    |}>,
    +brokerTransfer: ?{|
      +id: string,
      +direction: Direction,
      +createdAt: any,
      +updatedAt: any,
      +processedAt: ?any,
      +processedBy: ?{|
        +fullName: ?string,
        +firstName: string,
        +lastName: string,
      |},
      +brokeractivityPtr: {|
        +brokeractivitytransactionSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |}
      |},
    |},
  |}
|};
export type ApprovePositionTransfersMutation = {|
  variables: ApprovePositionTransfersMutationVariables,
  response: ApprovePositionTransfersMutationResponse,
|};
*/


/*
mutation ApprovePositionTransfersMutation(
  $brokerTransferId: ID
  $transferRequestIds: [ID]
) {
  approvePositionTransfers(brokerTransferId: $brokerTransferId, transferRequestIds: $transferRequestIds) {
    positionTransferRequests {
      id
      status
      updatedAt
      createdAt
      positionTransferPurchases {
        edges {
          node {
            id
            portfolio {
              trackingVueName
              id
            }
            contract {
              id
              symbol
              name
            }
          }
        }
      }
    }
    brokerTransfer {
      id
      direction
      createdAt
      updatedAt
      processedAt
      processedBy {
        fullName
        firstName
        lastName
        id
      }
      brokeractivityPtr {
        brokeractivitytransactionSet {
          edges {
            node {
              id
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerTransferId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "transferRequestIds",
    "type": "[ID]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerTransferId",
    "variableName": "brokerTransferId"
  },
  {
    "kind": "Variable",
    "name": "transferRequestIds",
    "variableName": "transferRequestIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "trackingVueName",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contract",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "direction",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "processedAt",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokeractivitytransactionSet",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerActivityTransactionNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerActivityTransactionNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerActivityTransactionNode",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ApprovePositionTransfersMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvePositionTransfers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovePositionTransfers",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferRequests",
            "storageKey": null,
            "args": null,
            "concreteType": "PositionTransferRequestNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "positionTransferPurchases",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferPurchaseNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferPurchaseNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PositionTransferPurchaseNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "portfolio",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PortfolioNode",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "IBTransferNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "processedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivityPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  (v13/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApprovePositionTransfersMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approvePositionTransfers",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApprovePositionTransfers",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferRequests",
            "storageKey": null,
            "args": null,
            "concreteType": "PositionTransferRequestNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "positionTransferPurchases",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferPurchaseNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferPurchaseNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PositionTransferPurchaseNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "portfolio",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PortfolioNode",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "IBTransferNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/),
              (v9/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "processedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivityPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ApprovePositionTransfersMutation",
    "id": null,
    "text": "mutation ApprovePositionTransfersMutation(\n  $brokerTransferId: ID\n  $transferRequestIds: [ID]\n) {\n  approvePositionTransfers(brokerTransferId: $brokerTransferId, transferRequestIds: $transferRequestIds) {\n    positionTransferRequests {\n      id\n      status\n      updatedAt\n      createdAt\n      positionTransferPurchases {\n        edges {\n          node {\n            id\n            portfolio {\n              trackingVueName\n              id\n            }\n            contract {\n              id\n              symbol\n              name\n            }\n          }\n        }\n      }\n    }\n    brokerTransfer {\n      id\n      direction\n      createdAt\n      updatedAt\n      processedAt\n      processedBy {\n        fullName\n        firstName\n        lastName\n        id\n      }\n      brokeractivityPtr {\n        brokeractivitytransactionSet {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69cbc240fe0727cb100008280514d356';
module.exports = node;
