/**
 * @flow
 * @relayHash 3fc30794f7cd19956f9ea74e2cb55d89
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Scope = "DEEP" | "SELF" | "TENANT" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type AdviserRoleFormQueryVariables = {|
  adviserRoleId: string
|};
export type AdviserRoleFormQueryResponse = {|
  +viewer: ?{|
    +adviserRoles: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +rolePermissions: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +resource: Resource,
                +verb: Verb,
                +scope: Scope,
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type AdviserRoleFormQuery = {|
  variables: AdviserRoleFormQueryVariables,
  response: AdviserRoleFormQueryResponse,
|};
*/


/*
query AdviserRoleFormQuery(
  $adviserRoleId: ID!
) {
  viewer {
    adviserRoles(id: $adviserRoleId) {
      edges {
        node {
          id
          name
          rolePermissions(orderBy: "resource,verb") {
            edges {
              node {
                resource
                verb
                scope
                id
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "adviserRoleId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adviserRoleId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "resource,verb"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resource",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verb",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "scope",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AdviserRoleFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adviserRoles",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AdviserRoleNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AdviserRoleNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdviserRoleNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "rolePermissions",
                        "storageKey": "rolePermissions(orderBy:\"resource,verb\")",
                        "args": (v4/*: any*/),
                        "concreteType": "AdviserRolePermissionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AdviserRolePermissionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AdviserRolePermissionNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AdviserRoleFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adviserRoles",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AdviserRoleNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AdviserRoleNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdviserRoleNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "rolePermissions",
                        "storageKey": "rolePermissions(orderBy:\"resource,verb\")",
                        "args": (v4/*: any*/),
                        "concreteType": "AdviserRolePermissionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AdviserRolePermissionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AdviserRolePermissionNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AdviserRoleFormQuery",
    "id": null,
    "text": "query AdviserRoleFormQuery(\n  $adviserRoleId: ID!\n) {\n  viewer {\n    adviserRoles(id: $adviserRoleId) {\n      edges {\n        node {\n          id\n          name\n          rolePermissions(orderBy: \"resource,verb\") {\n            edges {\n              node {\n                resource\n                verb\n                scope\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '59950cf07de4461f1e6f55d30d22f7cf';
module.exports = node;
