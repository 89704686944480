import React from 'react';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { UpdateButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import contractFromBroker from '../../mutations/ContractFromBroker';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import ContractLink from '../common/links/ContractLink';
import TableButton from '../common/buttons/TableButton';


function ContractFromBrokerDrawer(props) {
  const { openCtx } = props;
  const [forceCreate, setForceCreate] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = openCtx;

  const classes = useCommonStyles();
  return (
    <Drawer
      anchor="right"
      open={!!brokerActivity}
      onClose={() => setBrokerActivity(null)}
    >
      <div className={classes.drawerRoot}>
        <DrawerGridItemContainer>

          <DrawerGridItemHeading
            heading="Create Contract from Broker"
          />
          <DrawerGridItemCheckBox
            label="Force Create"
            onChange={e => setForceCreate(e.target.checked)}
            value={forceCreate}
          />

          <Grid item xs={12}>
            <BottomGridContainer>
              <UpdateButton
                onClickPromise={() => doCommitPromise(
                  contractFromBroker,
                  {
                    brokerActivityId: brokerActivity.id,
                    forceCreate,
                  },
                  () => setBrokerActivity(null),
                )}
                buttonText="Update"
              />
            </BottomGridContainer>
          </Grid>
        </DrawerGridItemContainer>
      </div>
    </Drawer>
  );
}


ContractFromBrokerDrawer.tableField = (baAccessor, openCtx) => ({
  Header: 'Contract',
  id: 'mvContract',
  accessor: node => node,
  Cell: (row) => {
    const brokerActivity = baAccessor(row);
    const [, setBrokerActivity] = openCtx;
    const { contract } = brokerActivity;
    if (contract) {
      return <ContractLink contract={contract} />;
    }
    return (
      <TableButton
        color="primary"
        onClick={() => setBrokerActivity(brokerActivity)}
      >
        Update
      </TableButton>
    );
  },
  style: {
    textAlign: 'center',
  },
  width: 75,
});

export default ContractFromBrokerDrawer;
