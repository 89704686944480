import { graphql } from 'react-relay';

const updateMFADeactivationRequestStatus = graphql`
  mutation UpdateMFADeactivationRequestStatusMutation(
    $mfaDeactivationRequestId: ID!,
    $status: ApprovableStatus!
  ) {
    updateMfaDeactivationRequestStatus (
      mfaDeactivationRequestId: $mfaDeactivationRequestId,
      status: $status
    ) {
      errors {
        field
        messages
      }
      mfaDeactivationRequest {
        id
        status
        createdAt
        forUser {
          id
          email
        }
        hasCompleteApprovals
      }
    }
  }
`;

export default updateMFADeactivationRequestStatus;
