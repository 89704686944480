/**
 * @flow
 * @relayHash 695da4066520a8c3645918179b507023
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserRoleLoaderFragment_viewer$ref = any;
export type UserRoleLoaderQueryVariables = {|
  first?: ?number,
  offset?: ?number,
|};
export type UserRoleLoaderQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserRoleLoaderFragment_viewer$ref
  |}
|};
export type UserRoleLoaderQuery = {|
  variables: UserRoleLoaderQueryVariables,
  response: UserRoleLoaderQueryResponse,
|};
*/


/*
query UserRoleLoaderQuery(
  $first: Int
  $offset: Int
) {
  viewer {
    ...UserRoleLoaderFragment_viewer_21Sbsv
    id
  }
}

fragment UserRoleLoaderFragment_viewer_21Sbsv on Query {
  userRoles(first: $first, offset: $offset) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserRoleLoaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserRoleLoaderFragment_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserRoleLoaderQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userRoles",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "UserRoleNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRoleNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRoleNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserRoleLoaderQuery",
    "id": null,
    "text": "query UserRoleLoaderQuery(\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...UserRoleLoaderFragment_viewer_21Sbsv\n    id\n  }\n}\n\nfragment UserRoleLoaderFragment_viewer_21Sbsv on Query {\n  userRoles(first: $first, offset: $offset) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a048018dae6213059277501dfde7742';
module.exports = node;
