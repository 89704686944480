
import { graphql } from 'react-relay';

// language=GraphQL
const feeMutation = graphql`
  mutation FeeMutation(
    $input: FeeMutationInput!
  ){
    fee(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }
      obj {
        id
        enabled
        feeType
        paymentPeriod
        simpleTranche
        createdAt
        updatedAt
        feeComponents {
          edges {
            node {
              id
            }
          }
        }
        
        template {
          id
          fees {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }`;

export const deleteFeeMutation = graphql`
  mutation FeeDeleteMutation(
    $feeId: ID!
  ){
    deleteFee(
      feeId: $feeId
    ){
      feeTemplate {
        id
        fees {
          edges {
            node {
              id
            }
          }
        }
      }
    } 
  }
`;

export default feeMutation;
