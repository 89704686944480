import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { createRefetchContainer, graphql } from 'react-relay';
import TimeAgo from 'javascript-time-ago';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableStatus from '../common/TableStatus';
import { 
  COLOR_EXCHANGE_OPEN, 
  COLOR_EXCHANGE_TO_OPEN, 
  MOMENT_TIME_FORMAT,
} from '../../common/constants';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import { toLocal } from '../../helpers/ID';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';

const timeAgo = new TimeAgo('en-US');

function ExchangeSessionStatus(props) {
  const {
    exchangeSession,
  } = props;
  // let statusColor = orderStatusColorMap[stockOrder.status] || COLOR_UNDEFINED;
  // const statusForeColor = orderStatusForeColorMap[stockOrder.status] || 'white';
  // if (stockOrder.matched) {
  //   statusColor = COLOR_MATCHED;
  // }
  const timeNow = new Date();
  if (moment(exchangeSession.marketClose) < timeNow) {
    return <TableStatus>Closed</TableStatus>;
  }

  if (moment(exchangeSession.marketClose) >= timeNow
    && moment(exchangeSession.marketOpen) <= timeNow) {
    return (
      <TableStatus
        customStyle={{ backgroundColor: COLOR_EXCHANGE_OPEN }}
      >
        Open
      </TableStatus>
    );
  }

  const inTime = moment(exchangeSession.marketOpen).format(MOMENT_TIME_FORMAT);
  const dateTarget = new Date(inTime);
  const diffStr = timeAgo.format(dateTarget);
  
  return (
    <TableStatus
      customStyle={{ backgroundColor: COLOR_EXCHANGE_TO_OPEN }}
    >
      {diffStr}
    </TableStatus>
  );
}

function ExchangeSession(props) {
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.field(node => toLocal(node.exchangeSession.id), 'ID'),
    {
      Header: '',
      id: 'openStatus',
      accessor: node => node,
      Cell: row => <ExchangeSessionStatus exchangeSession={row.value.exchangeSession} />,
    },
    TableFieldMeta.field(node => node.exchangeSession.exchange.symbol, 'Exchange'),
    TableFieldMeta.field(node => node.exchangeSession.exchange.name, 'Name'),
    TableFieldMeta.dateTimeField('openAt', 'Open At', node => node.exchangeSession.marketOpen),
    TableFieldMeta.dateTimeField('closeAt', 'Close At', node => node.exchangeSession.marketClose),
    TableFieldMeta.field('all', 'All', { align: 'right' }),
    TableFieldMeta.field('filled', 'Fil.', { align: 'right' }),
    TableFieldMeta.field('partiallyFilled', 'PFil.', { align: 'right' }),
    TableFieldMeta.field('inMarket', 'Inm.', { align: 'right' }),
    TableFieldMeta.field('pendingExecution', 'Pex.', { align: 'right' }),
    TableFieldMeta.field('cancelled', 'Can.', { align: 'right' }),
    TableFieldMeta.field('rejected', 'Rej.', { align: 'right' }),
    TableFieldMeta.field('posted', 'Pos.', { align: 'right' }),
    TableFieldMeta.field('queued', 'Que.', { align: 'right' }),
    TableFieldMeta.field('created', 'Cre.', { align: 'right' }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Exchange"
          helperText="exchange"
          defaultValue={filter.params.exchange}
          onChange={e => filter.updateVar('exchange', e.target.value)}
          halfWidth
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.exchangeSessions : null)}
            nodeResolver={node => node}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query ExchangeSessionQuery (
    $first: Int
    $offset: Int
    $exchange: String
  ) {
    viewer {
      ...ExchangeSession_viewer
      @arguments (
        first: $first
        offset: $offset
        exchange: $exchange
      )
    }
  }
`;

const container = createRefetchContainer(
  ExchangeSession,
  {
    viewer: graphql`
      fragment ExchangeSession_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        exchange: {type: String, defaultValue: null}
      ) {
        exchangeSessions(
          first: $first
          offset: $offset
          exchange: $exchange
        ) {
          edges {
            exchangeSession {
              id
              marketOpen
              marketClose
              exchange {
                symbol
                name
              }
            }
            all
            cancelled
            rejected
            created
            partiallyFilled
            pendingExecution
            inMarket
            queued
            posted
            filled
          }
          count
        }
      }
    `,
  },
  query,
);

const queryRenderer = createQueryRendererProgressV2(
  query,
  container,
  {},
  outerProps => ({ exchange: null }),
);

export default withRouter(queryRenderer);
