import { graphql } from 'react-relay';

// language=GraphQL
const updateUserPermissionMutation = graphql`
  mutation UpdateUserPermissionMutation(
    $userId: ID!
    $clientAccountId: ID!
    $userRoleIds: [ID]!
    $userClientAccountPermissions: [UserClientAccountPermissionInput]
  ) {
    updateUserPermissions (
      userId: $userId,
      clientAccountId: $clientAccountId,
      userRoleIds: $userRoleIds,
      userClientAccountPermissions: $userClientAccountPermissions
    ) {
      userModel {
        id
      }
    }
  }
`;

export default updateUserPermissionMutation;
