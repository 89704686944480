/**
 * @flow
 * @relayHash 860cf413c55497f4adb304914bd8f466
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSubscriptionList_viewer$ref = any;
export type UserSubscriptionListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  subscriptionCode?: ?string,
  active?: ?boolean,
|};
export type UserSubscriptionListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserSubscriptionList_viewer$ref
  |}
|};
export type UserSubscriptionListQuery = {|
  variables: UserSubscriptionListQueryVariables,
  response: UserSubscriptionListQueryResponse,
|};
*/


/*
query UserSubscriptionListQuery(
  $first: Int
  $offset: Int
  $subscriptionCode: String
  $active: Boolean
) {
  viewer {
    ...UserSubscriptionList_viewer_1Tz5db
    id
  }
}

fragment UserSubscriptionList_viewer_1Tz5db on Query {
  userSubscriptions(first: $first, offset: $offset, subscriptionCode: $subscriptionCode, active: $active) {
    edges {
      node {
        subscriberType
        id
        createdAt
        updatedAt
        subscriptionTemplate {
          code
          name
          id
        }
        user {
          id
        }
        billPeriodStart
        billPeriodEnd
        pendingCancel
        active
        paymentAccount {
          id
          name
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subscriptionCode",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "active",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "active",
    "variableName": "active"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "subscriptionCode",
    "variableName": "subscriptionCode"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSubscriptionListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSubscriptionList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSubscriptionListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userSubscriptions",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "UserSubscriptionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserSubscriptionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "subscriberType",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subscriptionTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SubscriptionTemplateNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "code",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "billPeriodStart",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "billPeriodEnd",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "pendingCancel",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "active",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "paymentAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSubscriptionListQuery",
    "id": null,
    "text": "query UserSubscriptionListQuery(\n  $first: Int\n  $offset: Int\n  $subscriptionCode: String\n  $active: Boolean\n) {\n  viewer {\n    ...UserSubscriptionList_viewer_1Tz5db\n    id\n  }\n}\n\nfragment UserSubscriptionList_viewer_1Tz5db on Query {\n  userSubscriptions(first: $first, offset: $offset, subscriptionCode: $subscriptionCode, active: $active) {\n    edges {\n      node {\n        subscriberType\n        id\n        createdAt\n        updatedAt\n        subscriptionTemplate {\n          code\n          name\n          id\n        }\n        user {\n          id\n        }\n        billPeriodStart\n        billPeriodEnd\n        pendingCancel\n        active\n        paymentAccount {\n          id\n          name\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '297830484de776183845153ce3d8dec3';
module.exports = node;
