/**
 * @flow
 * @relayHash 294b409aaeadd739ac8cc5ef4a7fd4ed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OffMarketHoldingTransferMutationVariables = {|
  accountId?: ?string,
  portfolioId?: ?string,
  contractId?: ?string,
  counterpartyAccountId?: ?string,
  counterpartyPortfolioId?: ?string,
  quantity?: ?number,
|};
export type OffMarketHoldingTransferMutationResponse = {|
  +offMarketHoldingTransfer: ?{|
    +account: ?{|
      +id: string
    |},
    +counterpartyAccount: ?{|
      +id: string
    |},
  |}
|};
export type OffMarketHoldingTransferMutation = {|
  variables: OffMarketHoldingTransferMutationVariables,
  response: OffMarketHoldingTransferMutationResponse,
|};
*/


/*
mutation OffMarketHoldingTransferMutation(
  $accountId: ID
  $portfolioId: ID
  $contractId: ID
  $counterpartyAccountId: ID
  $counterpartyPortfolioId: ID
  $quantity: Float
) {
  offMarketHoldingTransfer(accountId: $accountId, portfolioId: $portfolioId, contractId: $contractId, counterpartyAccountId: $counterpartyAccountId, counterpartyPortfolioId: $counterpartyPortfolioId, quantity: $quantity) {
    account {
      id
    }
    counterpartyAccount {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "portfolioId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "counterpartyAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "counterpartyPortfolioId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "quantity",
    "type": "Float",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offMarketHoldingTransfer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "counterpartyAccountId",
        "variableName": "counterpartyAccountId"
      },
      {
        "kind": "Variable",
        "name": "counterpartyPortfolioId",
        "variableName": "counterpartyPortfolioId"
      },
      {
        "kind": "Variable",
        "name": "portfolioId",
        "variableName": "portfolioId"
      },
      {
        "kind": "Variable",
        "name": "quantity",
        "variableName": "quantity"
      }
    ],
    "concreteType": "OffMarketHoldingTransfer",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountNode",
        "plural": false,
        "selections": (v1/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "counterpartyAccount",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountNode",
        "plural": false,
        "selections": (v1/*: any*/)
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketHoldingTransferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketHoldingTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OffMarketHoldingTransferMutation",
    "id": null,
    "text": "mutation OffMarketHoldingTransferMutation(\n  $accountId: ID\n  $portfolioId: ID\n  $contractId: ID\n  $counterpartyAccountId: ID\n  $counterpartyPortfolioId: ID\n  $quantity: Float\n) {\n  offMarketHoldingTransfer(accountId: $accountId, portfolioId: $portfolioId, contractId: $contractId, counterpartyAccountId: $counterpartyAccountId, counterpartyPortfolioId: $counterpartyPortfolioId, quantity: $quantity) {\n    account {\n      id\n    }\n    counterpartyAccount {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c604067bcf156f7e106a5014cb50158';
module.exports = node;
