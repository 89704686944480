/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type VBDividendList_viewer$ref: FragmentReference;
declare export opaque type VBDividendList_viewer$fragmentType: VBDividendList_viewer$ref;
export type VBDividendList_viewer = {|
  +vbDividends: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +processedAt: ?any,
        +processedBy: ?{|
          +username: string
        |},
        +reinvestmentPrice: number,
        +exDate: any,
        +payDate: any,
        +ignored: boolean,
        +reportDate: any,
        +ibCorporateAction: {|
          +id: string,
          +contract: ?{|
            +id: string,
            +currency: Currency,
          |},
        |},
        +brokeractivityPtr: {|
          +brokeractivitytransactionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +account: {|
                  +name: ?string,
                  +id: string,
                |},
                +brokeractivitytransactioncontractdeltaSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +portfolio: {|
                        +id: string
                      |},
                      +contract: {|
                        +id: string,
                        +symbol: string,
                      |},
                      +quantity: number,
                    |}
                  |}>
                |},
                +brokeractivitytransactioncashdeltaSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +currency: Currency,
                      +quantity: number,
                    |}
                  |}>
                |},
              |}
            |}>
          |}
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: VBDividendList_viewer$ref,
|};
export type VBDividendList_viewer$data = VBDividendList_viewer;
export type VBDividendList_viewer$key = {
  +$data?: VBDividendList_viewer$data,
  +$fragmentRefs: VBDividendList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VBDividendList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "reportDate",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "processedAt_Isnull",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vbDividends",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Variable",
          "name": "processedAt_Isnull",
          "variableName": "processedAt_Isnull"
        },
        {
          "kind": "Variable",
          "name": "reportDate",
          "variableName": "reportDate"
        }
      ],
      "concreteType": "VBDividendNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VBDividendNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VBDividendNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "processedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "processedBy",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "username",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reinvestmentPrice",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "exDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "payDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "ignored",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reportDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "ibCorporateAction",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "IBCorporateActionNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contract",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContractNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "brokeractivityPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerActivityNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "brokeractivitytransactionSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BrokerActivityTransactionNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BrokerActivityTransactionNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerActivityTransactionNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "account",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AccountNode",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "name",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    (v0/*: any*/)
                                  ]
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokeractivitytransactioncontractdeltaSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "portfolio",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "PortfolioNode",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/)
                                              ]
                                            },
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "contract",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "ContractNode",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/),
                                                {
                                                  "kind": "ScalarField",
                                                  "alias": null,
                                                  "name": "symbol",
                                                  "args": null,
                                                  "storageKey": null
                                                }
                                              ]
                                            },
                                            (v2/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokeractivitytransactioncashdeltaSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                          "plural": false,
                                          "selections": [
                                            (v1/*: any*/),
                                            (v2/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bb24cdc2137ca44c589310f27847da0';
module.exports = node;
