/**
 * @flow
 * @relayHash b5718e50b0b56d14c4a17578b15843b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportDeleteMutationVariables = {|
  reportId: string
|};
export type ReportDeleteMutationResponse = {|
  +deleteReport: ?{|
    +deletedReportId: ?string
  |}
|};
export type ReportDeleteMutation = {|
  variables: ReportDeleteMutationVariables,
  response: ReportDeleteMutationResponse,
|};
*/


/*
mutation ReportDeleteMutation(
  $reportId: ID!
) {
  deleteReport(reportId: $reportId) {
    deletedReportId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "reportId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteReport",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "reportId",
        "variableName": "reportId"
      }
    ],
    "concreteType": "DeleteReport",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedReportId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReportDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ReportDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReportDeleteMutation",
    "id": null,
    "text": "mutation ReportDeleteMutation(\n  $reportId: ID!\n) {\n  deleteReport(reportId: $reportId) {\n    deletedReportId\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4fb83e5c1c28e990e59b7a0e8ce60e2';
module.exports = node;
