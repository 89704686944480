import React from 'react';
import TableStatus from '../common/TableStatus';

const statusColor = {
  true: { color: 'primary' },
  false: {},
};

function SubscriptionStatus(props) {
  const { userSubscription } = props;
  return (
    <TableStatus {...statusColor[userSubscription.active]}>
      {userSubscription.active ? 'active' : 'inactive'}
    </TableStatus>
  );
}

export default SubscriptionStatus;
