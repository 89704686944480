/**
 * @flow
 * @relayHash 6f2d9b27b9ba3513d2a08d2f043abd37
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type OffMarketCashTransferMutationVariables = {|
  accountId?: ?string,
  counterPartyAccountId?: ?string,
  currency?: ?string,
  amount?: ?string,
|};
export type OffMarketCashTransferMutationResponse = {|
  +offMarketCashTransfer: ?{|
    +offMarketTransfer: ?{|
      +account: {|
        +actualCashBase: ?number,
        +availableCashBase: ?number,
        +stockValueBase: ?number,
        +updatedAt: any,
        +actualCashBalances: ?$ReadOnlyArray<?{|
          +currency: ?Currency,
          +balance: ?number,
        |}>,
        +availableCashBalances: ?$ReadOnlyArray<?{|
          +currency: ?Currency,
          +balance: ?number,
        |}>,
      |}
    |}
  |}
|};
export type OffMarketCashTransferMutation = {|
  variables: OffMarketCashTransferMutationVariables,
  response: OffMarketCashTransferMutationResponse,
|};
*/


/*
mutation OffMarketCashTransferMutation(
  $accountId: ID
  $counterPartyAccountId: ID
  $currency: String
  $amount: String
) {
  offMarketCashTransfer(accountId: $accountId, counterpartyAccountId: $counterPartyAccountId, currency: $currency, amount: $amount) {
    offMarketTransfer {
      account {
        actualCashBase
        availableCashBase
        stockValueBase
        updatedAt
        actualCashBalances {
          currency
          balance
        }
        availableCashBalances {
          currency
          balance
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "counterPartyAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "amount",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "amount",
    "variableName": "amount"
  },
  {
    "kind": "Variable",
    "name": "counterpartyAccountId",
    "variableName": "counterPartyAccountId"
  },
  {
    "kind": "Variable",
    "name": "currency",
    "variableName": "currency"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "actualCashBase",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "availableCashBase",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "stockValueBase",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
],
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "actualCashBalances",
  "storageKey": null,
  "args": null,
  "concreteType": "MultiCurrencyNode",
  "plural": true,
  "selections": (v6/*: any*/)
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "availableCashBalances",
  "storageKey": null,
  "args": null,
  "concreteType": "MultiCurrencyNode",
  "plural": true,
  "selections": (v6/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketCashTransferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offMarketCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OffMarketCashTransfer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "offMarketTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "OffMarketTransferNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketCashTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "offMarketCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OffMarketCashTransfer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "offMarketTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "OffMarketTransferNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ]
              },
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "OffMarketCashTransferMutation",
    "id": null,
    "text": "mutation OffMarketCashTransferMutation(\n  $accountId: ID\n  $counterPartyAccountId: ID\n  $currency: String\n  $amount: String\n) {\n  offMarketCashTransfer(accountId: $accountId, counterpartyAccountId: $counterPartyAccountId, currency: $currency, amount: $amount) {\n    offMarketTransfer {\n      account {\n        actualCashBase\n        availableCashBase\n        stockValueBase\n        updatedAt\n        actualCashBalances {\n          currency\n          balance\n        }\n        availableCashBalances {\n          currency\n          balance\n        }\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc998057bdcfda9d5f5b8fc134bc5518';
module.exports = node;
