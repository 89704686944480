/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CurrencyConversionFormPending_viewer$ref: FragmentReference;
declare export opaque type CurrencyConversionFormPending_viewer$fragmentType: CurrencyConversionFormPending_viewer$ref;
export type CurrencyConversionFormPending_viewer = {|
  +currencyConversionRequests: ?{|
    +count: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +baseCurrency: string,
        +quoteCurrency: string,
        +quantity: number,
        +status: string,
        +approvals: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +approvedBy: {|
                +id: string,
                +username: string,
              |},
              +approvedAt: any,
            |}
          |}>
        |},
        +hasCompleteApprovals: ?boolean,
      |}
    |}>,
  |},
  +$refType: CurrencyConversionFormPending_viewer$ref,
|};
export type CurrencyConversionFormPending_viewer$data = CurrencyConversionFormPending_viewer;
export type CurrencyConversionFormPending_viewer$key = {
  +$data?: CurrencyConversionFormPending_viewer$data,
  +$fragmentRefs: CurrencyConversionFormPending_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "CurrencyConversionFormPending_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "accountId",
      "type": "ID"
    },
    {
      "kind": "RootArgument",
      "name": "baseCurrency",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currencyConversionRequests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountId",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "baseCurrency",
          "variableName": "baseCurrency"
        },
        {
          "kind": "Literal",
          "name": "quoteCurrency",
          "value": "AUD"
        },
        {
          "kind": "Literal",
          "name": "status",
          "value": "PENDING"
        }
      ],
      "concreteType": "CurrencyConversionRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "CurrencyConversionRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "CurrencyConversionRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "baseCurrency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quoteCurrency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "approvals",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ApprovalNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ApprovalNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ApprovalNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "approvedBy",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "username",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "approvedAt",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hasCompleteApprovals",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f79dce0cc80ac6313e1ad5bb2151daa';
module.exports = node;
