import { graphql } from 'react-relay';

// language=GraphQL
const updateBrokerActivityPerformance = graphql`
  mutation UpdateBrokerActivityPerformanceMutation (
    $brokerActivityGroupId: ID!
    $entries: [BrokerActivityTransactionPerformanceType]!
  ) {
    updateBrokerActivityPerformance(
      brokerActivityGroupId: $brokerActivityGroupId
      entries: $entries
    ){
      brokerActivities {
        brokeractivitytransactionSet {
          edges {
            node {
              brokeractivitytransactionperformanceSet {
                edges {
                  node {
                    ...Performance_batPerformance @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default updateBrokerActivityPerformance;
