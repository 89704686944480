import React from 'react';
import PropTypes from 'prop-types';

import KeyboardArrowRight from '@material-ui/core/es/internal/svg-icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/core/es/internal/svg-icons/KeyboardArrowLeft';
import {
  Grid,
  Slider, useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function PageNavigatorDialog(props) {
  const {
    open,
    onClose,
    onChange,
    defaultValue,
    count: rowCount,
    rowsPerPage,
  } = props;

  const pageCount = Math.floor(rowCount / rowsPerPage);
  const [sliderValue, setSliderValue] = React.useState(defaultValue);
  const [zoomRange, setZoomRange] = React.useState([0, pageCount]);
  const [sliderZoomRange, setSliderZoomRange] = React.useState([0, pageCount]);
  const sliderInRange = (sliderValue <= zoomRange[1] && sliderValue >= zoomRange[0]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"

      open={open}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <DialogContentText>
          Page Selector
        </DialogContentText>
        <Grid
          container
          style={{ paddingTop: 30, paddingRight: 30, paddingLeft: 30 }}
        >
          <Grid item xs={12}>
            <Slider
              color="secondary"
              defaultValue={!sliderInRange && sliderValue}
              valueLabelDisplay={sliderInRange ? 'on' : 'off'}
              track={false}
              onChangeCommitted={(e, n) => {
                onChange(e, n);
                setSliderValue(n);
              }}
              min={zoomRange[0]}
              max={zoomRange[1]}
              marks={[
                {
                  value: zoomRange[0],
                  label: `${zoomRange[0]}`,
                },
                {
                  value: zoomRange[1],
                  label: `${zoomRange[1]}`,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Slider
              value={sliderZoomRange}
              onChangeCommitted={(e, newRange) => setZoomRange(newRange)}
              onChange={(e, newRange) => setSliderZoomRange(newRange)}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              min={0}
              max={pageCount}
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: sliderValue,
                  label: `${sliderValue}`,
                  color: 'secondary',
                },
                {
                  value: pageCount,
                  label: `${pageCount}`,
                },
              ]}
            />
          </Grid>
        </Grid>

      </DialogContent>

    </Dialog>
  );
}

function TablePaginationActions(props) {
  const {
    onChangePage,
    page,
    rowsPerPage,
    count,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [showSlider, setShowSlider] = React.useState(false);

  return (
    <div className={classes.root}>

      <PageNavigatorDialog
        key={showSlider}
        onClose={e => setShowSlider(false)}
        open={showSlider}
        defaultValue={page}
        count={count}
        rowsPerPage={rowsPerPage}
        onChange={(e, n) => {
          onChangePage(e, n);
        }}
      />

      <IconButton
        onClick={e => onChangePage(e, 0)}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={e => onChangePage(e, page - 1)}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        disabled={count <= rowsPerPage}
        onClick={e => setShowSlider(true)}
      >
        <MoreIcon />
      </IconButton>


      <IconButton
        onClick={e => onChangePage(e, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={e => onChangePage(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

TablePaginationActions.FORWARD = 0;
TablePaginationActions.BACKWARD = 1;
TablePaginationActions.ROW_COUNT_OPTIONS = [5, 10, 15, 20, 30, 50];

export default TablePaginationActions;
