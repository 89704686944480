import { graphql } from 'react-relay';

const createPositionTransfer = graphql`
  mutation CreatePositionTransferMutation (
    $transferRequest: PositionTransferInput
  ) {
    createPositionTransfer (
      transferRequest: $transferRequest
    ) {
      positionTransferRequest {
        id
        positionTransferPurchases {
          edges {
            node {
              id
            }
          }
        }
      }
      
      ibTransfer {
        ...IBTransfer_all @relay(mask: false)
      }
    }
  }
  `;

export default createPositionTransfer;
