import React from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import TableFieldMeta from '../../../common/TableFieldMeta';
import TableButton from '../buttons/TableButton';
import PaginatedTable from '../PaginatedTable';
import { useCommonStyles } from '../Styles';
import { createQueryRendererProgress } from '../QueryRendererProgress';
import RelayFilter from '../../../common/RelayFilter';
import InnerTabFilterGridItemTextField from '../innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../BaseGridContainer';
import MoreButton from '../buttons/MoreButton';
import { toLocal } from '../../../helpers/ID';
import ContractIconWidget from '../../contract/ContractIconWidget';

function ContractSearch(props) {
  const {
    relay,
    viewer,
    onSelectItems,
    closeOnSingleSelect,
    showIcon,
  } = props;

  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
    symbol: null,
    conId: null,
    isin: null,
  }, ['search', 'symbol', 'isin']));

  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('symbol', 'Symbol'),
    TableFieldMeta.field('currency', 'Currency'),
    TableFieldMeta.field(node => node.exchange.symbol, 'Exchange'),
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.field('ibConId', 'Con ID'),
    TableFieldMeta.field(node => (node.fsContract ? node.fsContract.isin : ''), 'ISIN'),
    TableFieldMeta.enum('InstrumentType', 'Instrument Type', node => node.instrumentType),
    TableFieldMeta.enum('ContractIbStatus', 'IB Status', node => node.ibStatus),
    // {
    //   Header: '',
    //   id: 'name-name',
    //   accessor: node => node,
    //   Cell: row => (
    //     <div>
    //       {row.value.exchange.symbol} &nbsp;&bull;&nbsp;
    //       {row.value.symbol} &nbsp;&bull;&nbsp;
    //       {row.value.currency} <br />
    //       {row.value.name} <br />
    //       {(row.value.fsContract ? row.value.fsContract.isin : '')} <br />
    //       {row.value.ibConId}
    //     </div>
    //   ),
    // },
    {
      Header: '',
      id: 'action',
      accessor: node => node,
      Cell: (row) => {
        if (onSelectItems) {
          return (
            <TableButton
              key={row.value.id}
              color="primary"
              onClick={() => onSelectItems([row.value])}
            >
              Select
            </TableButton>
          );
        }
        return <MoreButton key={row.value.id} href={`/contract/${toLocal(row.value.id)}`} />;
      },
    },
  ];

  if (showIcon) {
    columns.push({
      Header: '',
      id: 'image',
      accessor: node => node,
      Cell: row => <ContractIconWidget imageUrl={row.value.imageUrl} />,
    });
  }

  if (viewer && viewer.contracts && viewer.contracts.edges.length === 1 && closeOnSingleSelect) {
    if (onSelectItems) {
      onSelectItems([viewer.contracts.edges[0].node]);
    }
  }

  return (
    <div>
      <div className={classes.formControlContainer}>
        <BaseGridContainer>
          <InnerTabFilterGridItemTextField
            id="search-filter"
            label="Custom Search"
            helperText="id, symbol, factset-name"
            onChange={e => relayFilter.updateVar('search', e.target.value)}
          />

          <InnerTabFilterGridItemTextField
            id="symbol-filter"
            label="Symbol"
            halfWidth
            helperText="symbol"
            onChange={e => relayFilter.updateVar('symbol', e.target.value)}
          />

          <InnerTabFilterGridItemTextField
            id="exchange-filter"
            label="Exchange"
            halfWidth
            helperText="exchange"
            onChange={e => relayFilter.updateVar('exchange', e.target.value)}
          />

          <InnerTabFilterGridItemTextField
            id="con-id-search"
            label="Con ID"
            helperText="ib-con-id"
            onChange={e => relayFilter.updateVar('conId', Number(e.target.value) || null)}
          />

          <InnerTabFilterGridItemTextField
            id="isin-search"
            label="ISIN"
            helperText="isin"
            onChange={e => relayFilter.updateVar('isin', e.target.value || null)}
          />

        </BaseGridContainer>
      </div>

      <PaginatedTable
        columns={columns}
        ref={relayFilter.tableRef}
        viewer={viewer}
        getDataNode={() => (viewer ? viewer.contracts : null)}
        getFetchParams={relayFilter.fetchParams}
        relay={relay}
      />
    </div>
  );
}


const query = graphql`
  query ContractSearchQuery (
    $first: Int
    $offset: Int
    $search: String
    $symbol: String
    $conId: Float
    $isin: String
    $exchange: String
  ) {
    viewer {
      ...ContractSearch_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        symbol: $symbol
        conId: $conId
        isin: $isin
        exchange: $exchange
      )
    }
  }
`;

const container = createRefetchContainer(
  ContractSearch,
  {
    viewer: graphql`
      fragment ContractSearch_viewer on Query      
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        symbol: {type: String}
        conId: {type: Float}
        isin: {type: String}
        exchange: {type: String}
      ) {
        contracts(
          first: $first
          offset: $offset
          search: $search
          symbol: $symbol
          conId: $conId
          isin: $isin
          exchange_Symbol: $exchange
        ) {
          edges {
            node {
              id
              symbol
              name
              currency
              industry
              country
              ibConId
              imageUrl
              instrumentType
              ibStatus
              exchange {
                id
                symbol
              }
              fsContract {
                isin
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

ContractSearch.propTypes = {
  closeOnSingleSelect: PropTypes.bool,
  onSelectItems: PropTypes.func,
  showIcon: PropTypes.bool,
};

ContractSearch.defaultProps = {
  closeOnSingleSelect: false,
  onSelectItems: null,
  showIcon: false,
};

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { onSelectItems, closeOnSingleSelect }) => ({ onSelectItems, closeOnSingleSelect }),
);
