import React from 'react';
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { recursiveRefer } from '../../common/helpers';
import EnumValue from './EnumValue';

function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array;
}

function renderData(payLoad, dataNode) {
  if (typeof payLoad === 'function') {
    return payLoad(dataNode);
  }

  if (isArray(payLoad)) {
    return recursiveRefer(payLoad, dataNode);
  }

  if (typeof payLoad === 'boolean') {
    return payLoad === true ? 'Yes' : 'No';
  }

  if (React.isValidElement(payLoad)) {
    return payLoad;
  }
  return dataNode[payLoad];
}

function VerticalTable(props) {
  const {
    columnMeta,
    dataNode,
    valueAlignment,
    showHeadings,
  } = props;
  return (
    <Table size="small">
      {
        showHeadings && (
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell align={valueAlignment}>Value</TableCell>
          </TableRow>
        </TableHead>)
      }
      <TableBody>
        {
          columnMeta.map(
            fieldMeta => (
              <TableRow>
                <TableCell align="left">
                  {fieldMeta[0]}
                </TableCell>
                <TableCell align={valueAlignment}>
                  { renderData(fieldMeta[1], dataNode) }
                </TableCell>
              </TableRow>
            ),
          )
        }
      </TableBody>
    </Table>
  );
}


VerticalTable.propTypes = {
  columnMeta: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  valueAlignment: PropTypes.string,
  showHeadings: PropTypes.bool,
};

VerticalTable.defaultProps = {
  valueAlignment: 'right',
  showHeadings: true,
};

VerticalTable.enum = (sourceVar, enumType) => dataNode => (
  <EnumValue enumType={enumType} enumValue={dataNode[sourceVar]} />
);

export default VerticalTable;
