/**
 * @flow
 * @relayHash 2841d1487ef4108dc70b6c86dfc626d4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateABAForCTRBatchMutationVariables = {|
  cashTransferRequestBatchId: string
|};
export type GenerateABAForCTRBatchMutationResponse = {|
  +generateAbaForCtrBatch: ?{|
    +abaText: ?string
  |}
|};
export type GenerateABAForCTRBatchMutation = {|
  variables: GenerateABAForCTRBatchMutationVariables,
  response: GenerateABAForCTRBatchMutationResponse,
|};
*/


/*
mutation GenerateABAForCTRBatchMutation(
  $cashTransferRequestBatchId: ID!
) {
  generateAbaForCtrBatch(cashTransferRequestBatchId: $cashTransferRequestBatchId) {
    abaText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cashTransferRequestBatchId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateAbaForCtrBatch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cashTransferRequestBatchId",
        "variableName": "cashTransferRequestBatchId"
      }
    ],
    "concreteType": "GenerateABAForCTRBatch",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "abaText",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateABAForCTRBatchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateABAForCTRBatchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateABAForCTRBatchMutation",
    "id": null,
    "text": "mutation GenerateABAForCTRBatchMutation(\n  $cashTransferRequestBatchId: ID!\n) {\n  generateAbaForCtrBatch(cashTransferRequestBatchId: $cashTransferRequestBatchId) {\n    abaText\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0672df834cc0288404164572a965648';
module.exports = node;
