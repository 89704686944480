/**
 * @flow
 * @relayHash 69d53786ca4997f064781d6a40d6a945
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractSearch_viewer$ref = any;
export type ContractSearchQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  symbol?: ?string,
  conId?: ?number,
  isin?: ?string,
  exchange?: ?string,
|};
export type ContractSearchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ContractSearch_viewer$ref
  |}
|};
export type ContractSearchQuery = {|
  variables: ContractSearchQueryVariables,
  response: ContractSearchQueryResponse,
|};
*/


/*
query ContractSearchQuery(
  $first: Int
  $offset: Int
  $search: String
  $symbol: String
  $conId: Float
  $isin: String
  $exchange: String
) {
  viewer {
    ...ContractSearch_viewer_yU1I9
    id
  }
}

fragment ContractSearch_viewer_yU1I9 on Query {
  contracts(first: $first, offset: $offset, search: $search, symbol: $symbol, conId: $conId, isin: $isin, exchange_Symbol: $exchange) {
    edges {
      node {
        id
        symbol
        name
        currency
        industry
        country
        ibConId
        imageUrl
        instrumentType
        ibStatus
        exchange {
          id
          symbol
        }
        fsContract {
          isin
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "symbol",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "conId",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isin",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "exchange",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "conId",
  "variableName": "conId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "isin",
  "variableName": "isin"
},
v4 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v5 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v6 = {
  "kind": "Variable",
  "name": "symbol",
  "variableName": "symbol"
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ContractSearch_viewer",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "exchange",
                "variableName": "exchange"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractSearchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contracts",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "exchange_Symbol",
                "variableName": "exchange"
              },
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "ContractNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "industry",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "country",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ibConId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "imageUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instrumentType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ibStatus",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exchange",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExchangeNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fsContract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FactsetContractNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "isin",
                            "args": null,
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractSearchQuery",
    "id": null,
    "text": "query ContractSearchQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $symbol: String\n  $conId: Float\n  $isin: String\n  $exchange: String\n) {\n  viewer {\n    ...ContractSearch_viewer_yU1I9\n    id\n  }\n}\n\nfragment ContractSearch_viewer_yU1I9 on Query {\n  contracts(first: $first, offset: $offset, search: $search, symbol: $symbol, conId: $conId, isin: $isin, exchange_Symbol: $exchange) {\n    edges {\n      node {\n        id\n        symbol\n        name\n        currency\n        industry\n        country\n        ibConId\n        imageUrl\n        instrumentType\n        ibStatus\n        exchange {\n          id\n          symbol\n        }\n        fsContract {\n          isin\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f9c0182683ca42f24605a1de00ab482';
module.exports = node;
