/**
 * @flow
 * @relayHash 60fce8a421a5bac22a487d49d7c89872
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferRequestStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type CancelPositionTransferMutationVariables = {|
  positionTransferRequestId?: ?string
|};
export type CancelPositionTransferMutationResponse = {|
  +cancelPositionTransfer: ?{|
    +positionTransferRequest: ?{|
      +id: string,
      +status: TransferRequestStatus,
    |}
  |}
|};
export type CancelPositionTransferMutation = {|
  variables: CancelPositionTransferMutationVariables,
  response: CancelPositionTransferMutationResponse,
|};
*/


/*
mutation CancelPositionTransferMutation(
  $positionTransferRequestId: ID
) {
  cancelPositionTransfer(positionTransferRequestId: $positionTransferRequestId) {
    positionTransferRequest {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "positionTransferRequestId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "cancelPositionTransfer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "positionTransferRequestId",
        "variableName": "positionTransferRequestId"
      }
    ],
    "concreteType": "CancelPositionTransfer",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "positionTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "PositionTransferRequestNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelPositionTransferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelPositionTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelPositionTransferMutation",
    "id": null,
    "text": "mutation CancelPositionTransferMutation(\n  $positionTransferRequestId: ID\n) {\n  cancelPositionTransfer(positionTransferRequestId: $positionTransferRequestId) {\n    positionTransferRequest {\n      id\n      status\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b42ce4154d8d11b1b0d064bb87cf742e';
module.exports = node;
