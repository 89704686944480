/**
 * @flow
 * @relayHash 0df96c2688726f7083d8baa870c622d2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BatchDetailsFormBase_viewer$ref = any;
export type BatchDetailsFormBaseQueryVariables = {|
  batchId?: ?string
|};
export type BatchDetailsFormBaseQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BatchDetailsFormBase_viewer$ref
  |}
|};
export type BatchDetailsFormBaseQuery = {|
  variables: BatchDetailsFormBaseQueryVariables,
  response: BatchDetailsFormBaseQueryResponse,
|};
*/


/*
query BatchDetailsFormBaseQuery(
  $batchId: ID
) {
  viewer {
    ...BatchDetailsFormBase_viewer_1zZGiZ
    id
  }
}

fragment BatchDetailsFormBase_viewer_1zZGiZ on Query {
  cashTransferRequestBatches(id: $batchId) {
    edges {
      node {
        id
        name
        transactionInitiationSupported
        createdAt
        updatedAt
        custodian
        inverseBatch {
          id
          name
        }
        contraBatch {
          id
          name
        }
        cashtransferrequestSet {
          edges {
            node {
              id
              status
              amount
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "batchId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BatchDetailsFormBaseQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BatchDetailsFormBase_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "batchId",
                "variableName": "batchId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BatchDetailsFormBaseQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequestBatches",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "batchId"
              }
            ],
            "concreteType": "CashTransferRequestBatchNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferRequestBatchNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashTransferRequestBatchNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "transactionInitiationSupported",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "custodian",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "inverseBatch",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestBatchNode",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contraBatch",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestBatchNode",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashtransferrequestSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashTransferRequestNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashTransferRequestNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "status",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "amount",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BatchDetailsFormBaseQuery",
    "id": null,
    "text": "query BatchDetailsFormBaseQuery(\n  $batchId: ID\n) {\n  viewer {\n    ...BatchDetailsFormBase_viewer_1zZGiZ\n    id\n  }\n}\n\nfragment BatchDetailsFormBase_viewer_1zZGiZ on Query {\n  cashTransferRequestBatches(id: $batchId) {\n    edges {\n      node {\n        id\n        name\n        transactionInitiationSupported\n        createdAt\n        updatedAt\n        custodian\n        inverseBatch {\n          id\n          name\n        }\n        contraBatch {\n          id\n          name\n        }\n        cashtransferrequestSet {\n          edges {\n            node {\n              id\n              status\n              amount\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6dcf1f474a15e1a7b0617e20beca2ec0';
module.exports = node;
