import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useCommonStyles } from '../Styles';

function InnerTabFilterGridItemTextField(props) {
  const classes = useCommonStyles();
  const {
    halfWidth,
    ...otherProps
  } = props;
  return (
    <Grid item>
      {/* Search */}
      <TextField
        className={halfWidth ? classes.formControlHalf : classes.formControl}
        margin="normal"
        {...otherProps}
      />
    </Grid>
  );
}

InnerTabFilterGridItemTextField.propTypes = {
  ...TextField.propTypes,
  halfWidth: PropTypes.bool,
};

InnerTabFilterGridItemTextField.defaultProps = {
  ...TextField.defaultProps,
  halfWidth: false,
};

export default InnerTabFilterGridItemTextField;
