import React from 'react';
import Grid from '@material-ui/core/Grid';
import { graphql, createFragmentContainer } from 'react-relay';
import { Drawer } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { ApproveButton, CloseButton } from '../common/buttons/BaseButton';
import { updateBrokerageCreditStatus } from '../../mutations/BrokerageCredit';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import ApprovalList from '../approvals/ApprovalList';
import { doCommitPromise } from '../../common/commit';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import createApproval from '../../mutations/CreateApproval';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import VerticalTable from '../common/VerticalTable';
import TableFieldMeta from '../../common/TableFieldMeta';
import MoreButton from '../common/buttons/MoreButton';
import EnumValue from '../common/EnumValue';
import ApprovableStatus from '../globals/ApprovableStatus';

function BrokerageCreditUpdateInnerForm(props) {
  const { brokerageCredit } = props;
  return (
    <DrawerGridItemContainer>
      <DrawerGridItemHeading
        heading="Update Brokerage Credit"
      />

      <Grid item xs={12}>
        <VerticalTable
          columnMeta={[
            ['ID', TableFieldMeta.localId.accessor],
            ['Description', 'description'],
            ['Note', 'note'],
            ['Remaining Amount', node => `${node.remainingAmount} out of ${node.initialAmount}`],
            ['Credit Type', node => (
              <EnumValue enumType="CreditType" enumValue={node.creditType} />
            )],
            ['Expiry Date', 'expiryDate'],
            ['Provisioned By', node => node.provisionedBy && node.provisionedBy.fullName],
            ['Status', node => (
              <ApprovableStatus approvable={node} />
            )],
          ]}
          dataNode={brokerageCredit}
          showHeadings={false}
          valueAlignment="left"
        />
      </Grid>

      <DrawerGridItemBottomButtonContainer>
        <Grid item>
          <CloseButton
            buttonText="Cancel"
            color="secondary"
            disabled={brokerageCredit.status === 'CANCELLED'}
            onClickPromise={() => doCommitPromise(
              updateBrokerageCreditStatus,
              {
                brokerageCreditId: brokerageCredit.id,
                status: 'CANCELLED',
              },
              null,
            )}
          />
        </Grid>

        <Grid item>
          <ApproveButton
            buttonText="Approve"
            disabled={brokerageCredit.status === 'APPROVED' || !brokerageCredit.hasCompleteApprovals}
            onClickPromise={() => doCommitPromise(
              updateBrokerageCreditStatus,
              {
                brokerageCreditId: brokerageCredit.id,
                status: 'APPROVED',
              },
              null,
            )}
          />
        </Grid>
      </DrawerGridItemBottomButtonContainer>

      <Grid item xs={12}>
        <ApprovalList approvals={brokerageCredit.approvals.edges} />
      </Grid>
      <DrawerGridItemBottomButtonContainer>
        <Grid item>
          <ApproveButton
            buttonText="Approve"
            onClickPromise={() => doCommitPromise(
              createApproval,
              {
                approvableId: brokerageCredit.id,
              },
              null,
              null,
              (store) => {
                const payload = store.getRootField('createApproval');
                if (!payload) return;
                const cachedBrokerageCredit = store.get(brokerageCredit.id);
                const approvals = cachedBrokerageCredit.getLinkedRecord('approvals');
                const approvalEdges = approvals.getLinkedRecords('edges');
                const newApproval = payload.getLinkedRecord('approval');
                const newApprovalEdge = store.create('newApproval', 'ApprovalNodeEdge');
                const newHasCompleteApprovals = payload.getValue('hasCompleteApprovals');
                newApprovalEdge.setLinkedRecord(newApproval, 'node');
                approvals.setLinkedRecords([...approvalEdges, newApprovalEdge], 'edges');
                cachedBrokerageCredit.setValue(newHasCompleteApprovals, 'hasCompleteApprovals');
              },
            )}
          />
        </Grid>
      </DrawerGridItemBottomButtonContainer>

    </DrawerGridItemContainer>
  );
}

const query = graphql`
  query BrokerageCreditUpdateFormQuery(
    $brokerageCreditId: ID!
  ) {
    viewer {
      brokerageCredits(
        id: $brokerageCreditId
      ) {
        edges {
          node {
            ...BrokerageCreditUpdateForm_brokerageCredit
            @relay(mask:false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  BrokerageCreditUpdateInnerForm,
  {
    brokerageCredit: graphql`
      fragment BrokerageCreditUpdateForm_brokerageCredit on BrokerageCreditNode {
        id
        account {
          id
          name
        }
        description
        expiryDate
        initialAmount
        remainingAmount
        note
        creditType
        consumed
        provisionedBy {
          id
          fullName
        }
        status
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    `,
  },
);

const BrokerageCreditUpdateFormRenderer = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({ brokerageCredit: viewer && viewer.brokerageCredits.edges[0].node }),
  ({ brokerageCreditId }) => ({ brokerageCreditId }),
);

function BrokerageCreditUpdateForm(props) {
  const { brokerageCredit } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();

  return (
    <div>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <BrokerageCreditUpdateFormRenderer
            brokerageCreditId={brokerageCredit.id}
          />
        </div>
      </Drawer>
      <MoreButton
        onClick={() => setDrawerOpen(true)}
      >
        Update
      </MoreButton>
    </div>
  );
}

export default BrokerageCreditUpdateForm;
