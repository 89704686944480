/**
 * @flow
 * @relayHash adc3b7ba2dbc9b2bcacbfbab923d05c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CronList_viewer$ref = any;
export type CronListQueryVariables = {||};
export type CronListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CronList_viewer$ref
  |}
|};
export type CronListQuery = {|
  variables: CronListQueryVariables,
  response: CronListQueryResponse,
|};
*/


/*
query CronListQuery {
  viewer {
    ...CronList_viewer
    id
  }
}

fragment CronList_viewer on Query {
  adminMeta {
    systemName
  }
  crons {
    expression
    functionUrl
    awsLogUrl
    awsEventUrl
    paramDefs {
      defaultValue
      id
      type
      description
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CronListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CronList_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CronListQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adminMeta",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminMeta",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "systemName",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "crons",
            "storageKey": null,
            "args": null,
            "concreteType": "Cron",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expression",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "functionUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "awsLogUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "awsEventUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "paramDefs",
                "storageKey": null,
                "args": null,
                "concreteType": "CronParamDefs",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "defaultValue",
                    "args": null,
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "type",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "description",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CronListQuery",
    "id": null,
    "text": "query CronListQuery {\n  viewer {\n    ...CronList_viewer\n    id\n  }\n}\n\nfragment CronList_viewer on Query {\n  adminMeta {\n    systemName\n  }\n  crons {\n    expression\n    functionUrl\n    awsLogUrl\n    awsEventUrl\n    paramDefs {\n      defaultValue\n      id\n      type\n      description\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17cb14c246f75a5816ef2e473c5a33bd';
module.exports = node;
