import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';
import TablePaginator from '../common/TablePaginator';


function UserSubscriptionActionLogs(props) {
  const {
    relay,
    viewer,
    filter,
  } = props;
  filter.setRelay(relay);
  const actionLogs = isRecurNull(viewer, ['userSubscriptions', 'edges', 0, 'node', 'actionLogs']);
  return (

    <Grid
      item
      xs={12}
      lg={7}
    >
      <TablePaginator
        title="Action Logs"
        filter={filter}
        columns={[
          TableFieldMeta.dateTimeField('actionTime', 'Action Time', node => node.actionTime),
          TableFieldMeta.field('user', 'User', { accessor: node => node.user && node.user.fullName }),
          TableFieldMeta.enum('ActionType', 'Action Type', node => node.actionType),
          TableFieldMeta.field('actionDescription', 'Action Description'),
        ]}
        getDataNode={() => actionLogs}
      />
    </Grid>

  );
}

const query = graphql`
  query UserSubscriptionActionLogsSetQuery (
    $first: Int
    $offset: Int
    $userSubscriptionId: ID
  ) {
    viewer {
      ...UserSubscriptionActionLogs_viewer
      @arguments(
        first: $first
        offset: $offset
        userSubscriptionId: $userSubscriptionId
      ) 
    }
  }
`;

const container = createRefetchContainer(
  UserSubscriptionActionLogs,
  {
    viewer: graphql`
      fragment UserSubscriptionActionLogs_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        userSubscriptionId: {type: ID}
      ){
        userSubscriptions(
          id: $userSubscriptionId
        ) {
          count
          edges {
            node {
              actionLogs(
                first: $first
                offset: $offset
                orderBy: "-actionTime"
              ){
                edges {
                  node {
                    id
                    actionType
                    actionTime
                    actionDescription
                    user {
                      id
                      username
                      email
                      fullName
                    }
                  }
                }
                count
              }
              
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    userSubscriptionId: props.userSubscriptionId,
  }),
));
