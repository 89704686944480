import React from 'react';
import { Grid } from '@material-ui/core';

export default function TableCompactGridItem(props) {
  const { children, ...otherProps } = props;
  return (
    <Grid
      {...otherProps}
    >
      {children}
    </Grid>
  );
}
