import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function BaseGridContainer(props) {
  const { children, ...otherProps } = props;
  return (
    <Grid container spacing={2} {...otherProps}>
      {children}
    </Grid>
  );
}
