/**
 * @flow
 * @relayHash c05517ed9792d80ff16c7bdec8204415
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCashTransferRequestBatchMutationVariables = {|
  cashTransferRequestIds: $ReadOnlyArray<?string>,
  name?: ?string,
|};
export type CreateCashTransferRequestBatchMutationResponse = {|
  +createCashTransferRequestBatch: ?{|
    +batch: ?{|
      +id: string,
      +cashtransferrequestSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +batch: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateCashTransferRequestBatchMutation = {|
  variables: CreateCashTransferRequestBatchMutationVariables,
  response: CreateCashTransferRequestBatchMutationResponse,
|};
*/


/*
mutation CreateCashTransferRequestBatchMutation(
  $cashTransferRequestIds: [ID]!
  $name: String
) {
  createCashTransferRequestBatch(cashTransferRequestIds: $cashTransferRequestIds, name: $name) {
    batch {
      id
      cashtransferrequestSet {
        edges {
          node {
            id
            batch {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cashTransferRequestIds",
    "type": "[ID]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCashTransferRequestBatch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cashTransferRequestIds",
        "variableName": "cashTransferRequestIds"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CreateCashTransferRequestBatch",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "batch",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestBatchNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashtransferrequestSet",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashTransferRequestNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "batch",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestBatchNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCashTransferRequestBatchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCashTransferRequestBatchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCashTransferRequestBatchMutation",
    "id": null,
    "text": "mutation CreateCashTransferRequestBatchMutation(\n  $cashTransferRequestIds: [ID]!\n  $name: String\n) {\n  createCashTransferRequestBatch(cashTransferRequestIds: $cashTransferRequestIds, name: $name) {\n    batch {\n      id\n      cashtransferrequestSet {\n        edges {\n          node {\n            id\n            batch {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4699f04515c6a14441a58c0c38197446';
module.exports = node;
