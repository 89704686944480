import { graphql } from 'react-relay';

const blockCashTransferRequest = graphql`
  mutation BlockCashTransferRequestMutation (
    $cashTransferRequestId: ID!
    $block: Boolean
  ) {
    blockCashTransferRequest(
      cashTransferRequestId: $cashTransferRequestId
      block: $block
    ) {
      cashTransferRequest {
        id
        blocked
        createdAt
        currency
        updatedAt
        amount
      }
    }
  }
`;

export default blockCashTransferRequest;
