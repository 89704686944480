/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PortfolioOrderRequestApprovalForm_viewer$ref: FragmentReference;
declare export opaque type PortfolioOrderRequestApprovalForm_viewer$fragmentType: PortfolioOrderRequestApprovalForm_viewer$ref;
export type PortfolioOrderRequestApprovalForm_viewer = {|
  +portfolioOrderRequests: ?{|
    +count: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +status: ApprovableStatus,
        +createdAt: any,
        +approvals: ?{|
          +count: ?number,
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +approvedBy: {|
                +id: string,
                +username: string,
              |},
              +approvedAt: any,
            |}
          |}>,
        |},
        +portfolioOrder: {|
          +id: string,
          +account: {|
            +id: string,
            +name: ?string,
          |},
          +stockOrders: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +brokerOrder: ?{|
                  +status: OrderStatus
                |},
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |},
  +$refType: PortfolioOrderRequestApprovalForm_viewer$ref,
|};
export type PortfolioOrderRequestApprovalForm_viewer$data = PortfolioOrderRequestApprovalForm_viewer;
export type PortfolioOrderRequestApprovalForm_viewer$key = {
  +$data?: PortfolioOrderRequestApprovalForm_viewer$data,
  +$fragmentRefs: PortfolioOrderRequestApprovalForm_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PortfolioOrderRequestApprovalForm_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "requestId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolioOrderRequests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "requestId"
        }
      ],
      "concreteType": "PortfolioOrderRequestNodeConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioOrderRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioOrderRequestNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "approvals",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ApprovalNodeConnection",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ApprovalNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ApprovalNode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "approvedBy",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "username",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "approvedAt",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioOrderNode",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "account",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "stockOrders",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StockOrderNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "StockOrderNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "StockOrderNode",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokerOrder",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerOrderNode",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/)
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edbf0b2858b8bafe978bad20ebdf9dbd';
module.exports = node;
