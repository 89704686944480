import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function TitledWidgetContainer(props) {
  const { children } = props;
  return (
    <Grid container spacing={3}>
      {children}
    </Grid>
  );
}
