/**
 * @flow
 * @relayHash 0b9152be101195e54a88338a48d2a601
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SalesTaxList_viewer$ref = any;
export type SalesTaxListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  reportDate?: ?any,
  currency?: ?string,
  processedAt_Isnull?: ?boolean,
  ignored?: ?boolean,
|};
export type SalesTaxListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SalesTaxList_viewer$ref
  |}
|};
export type SalesTaxListQuery = {|
  variables: SalesTaxListQueryVariables,
  response: SalesTaxListQueryResponse,
|};
*/


/*
query SalesTaxListQuery(
  $first: Int
  $offset: Int
  $search: String
  $reportDate: Date
  $currency: String
  $processedAt_Isnull: Boolean
  $ignored: Boolean
) {
  viewer {
    ...SalesTaxList_viewer_3MK1vF
    id
  }
}

fragment SalesTaxList_viewer_3MK1vF on Query {
  ibSalesTaxes(first: $first, offset: $offset, search: $search, reportDate: $reportDate, currency: $currency, processedAt_Isnull: $processedAt_Isnull, orderBy: "-createdAt", ignored: $ignored) {
    edges {
      node {
        id
        processedAt
        processedBy {
          username
          fullName
          id
        }
        taxableDescription
        ignored
        currency
        salesTax
        reportDate
        updatedAt
        notes {
          id
        }
        brokeractivityPtr {
          brokeractivitytransactionSet {
            edges {
              node {
                id
                account {
                  name
                  id
                }
                brokeractivitytransactioncontractdeltaSet {
                  edges {
                    node {
                      portfolio {
                        id
                      }
                      contract {
                        id
                        symbol
                      }
                      quantity
                      id
                    }
                  }
                }
                brokeractivitytransactioncashdeltaSet {
                  edges {
                    node {
                      currency
                      quantity
                      id
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reportDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "processedAt_Isnull",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignored",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "currency",
  "variableName": "currency"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "ignored",
  "variableName": "ignored"
},
v4 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v5 = {
  "kind": "Variable",
  "name": "processedAt_Isnull",
  "variableName": "processedAt_Isnull"
},
v6 = {
  "kind": "Variable",
  "name": "reportDate",
  "variableName": "reportDate"
},
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v10 = [
  (v8/*: any*/)
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SalesTaxListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SalesTaxList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SalesTaxListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibSalesTaxes",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "concreteType": "IBSalesTaxNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBSalesTaxNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBSalesTaxNode",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "processedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "processedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "taxableDescription",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ignored",
                        "args": null,
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "salesTax",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "notes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NotesNode",
                        "plural": false,
                        "selections": (v10/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokeractivityPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "brokeractivitytransactionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionNode",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "account",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AccountNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          (v8/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncontractdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "portfolio",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PortfolioNode",
                                                    "plural": false,
                                                    "selections": (v10/*: any*/)
                                                  },
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "contract",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ContractNode",
                                                    "plural": false,
                                                    "selections": [
                                                      (v8/*: any*/),
                                                      {
                                                        "kind": "ScalarField",
                                                        "alias": null,
                                                        "name": "symbol",
                                                        "args": null,
                                                        "storageKey": null
                                                      }
                                                    ]
                                                  },
                                                  (v11/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncashdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  (v9/*: any*/),
                                                  (v11/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v8/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "SalesTaxListQuery",
    "id": null,
    "text": "query SalesTaxListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $reportDate: Date\n  $currency: String\n  $processedAt_Isnull: Boolean\n  $ignored: Boolean\n) {\n  viewer {\n    ...SalesTaxList_viewer_3MK1vF\n    id\n  }\n}\n\nfragment SalesTaxList_viewer_3MK1vF on Query {\n  ibSalesTaxes(first: $first, offset: $offset, search: $search, reportDate: $reportDate, currency: $currency, processedAt_Isnull: $processedAt_Isnull, orderBy: \"-createdAt\", ignored: $ignored) {\n    edges {\n      node {\n        id\n        processedAt\n        processedBy {\n          username\n          fullName\n          id\n        }\n        taxableDescription\n        ignored\n        currency\n        salesTax\n        reportDate\n        updatedAt\n        notes {\n          id\n        }\n        brokeractivityPtr {\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n                account {\n                  name\n                  id\n                }\n                brokeractivitytransactioncontractdeltaSet {\n                  edges {\n                    node {\n                      portfolio {\n                        id\n                      }\n                      contract {\n                        id\n                        symbol\n                      }\n                      quantity\n                      id\n                    }\n                  }\n                }\n                brokeractivitytransactioncashdeltaSet {\n                  edges {\n                    node {\n                      currency\n                      quantity\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '470975b410e914549b35ddaf85cbbf94';
module.exports = node;
