import { excel, FileDropZone } from 'mui-dropzone';
import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import notiStack from '../../common/notiStack';
import { useCommonStyles } from './Styles';
import { hrFileSize } from '../../common/helpers';
import TableButton from './buttons/TableButton';
import './FileUpload.css';
import { UploadButton } from './buttons/BaseButton';

function FileUpload(props) {
  const classes = useCommonStyles();
  const [files, setFiles] = React.useState([]);
  const {
    acceptedMimeTypes,
    onChange,
    singleFile,
    displayList,
    onUploadPromise,
    largeButton,
    xs,
  } = props;

  const onAddUploadFile = (newFiles) => {
    if (singleFile) {
      setFiles(newFiles);
      if (onChange) onChange(newFiles);
    } else {
      const mergedFiles = [...files, ...newFiles];
      setFiles(mergedFiles);
      if (onChange) onChange(mergedFiles);
    }
  };

  const onRejectUploadFile = (rejFiles) => {
    rejFiles.forEach((file) => {
      notiStack.error(`Failed to load file ${file}`);
    });
  };

  const renderAttachingFileList = () => (
    <Grid item xs={xs} xl={12}>
      <Paper>
        <div className={classes.tableHolderRootNoFooter}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody key={files.length}>
              {
                files.map(
                  (attachedFile, i) => (
                    <TableRow key={attachedFile.name}>
                      <TableCell>{attachedFile.name}</TableCell>
                      <TableCell>{hrFileSize(attachedFile.size)}</TableCell>
                      <TableCell>
                        <TableButton
                          key={attachedFile.name}
                          color="secondary"
                          onClick={() => {
                            const newFiles = [...files];
                            newFiles.splice(i, 1);
                            setFiles(newFiles);
                            if (onChange) onChange(newFiles);
                          }}
                        >
                          Remove
                        </TableButton>
                      </TableCell>
                    </TableRow>
                  ),
                )
              }
            </TableBody>
          </Table>
        </div>
      </Paper>
    </Grid>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FileDropZone
          acceptedMimeTypes={acceptedMimeTypes}
          onFilesAdded={onAddUploadFile}
          onFilesRejected={onRejectUploadFile}
          elevation={2}
          dragOverElevation={10}
          className="file-drop-zone"
        />
      </Grid>
      {
        files.length > 0 && displayList ? renderAttachingFileList() : ''
      }
      {
        onUploadPromise && Object.values(files).length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={1} justify="flex-end">
              {
                largeButton ? (
                  <UploadButton
                    key={files}
                    color="primary"
                    buttonText="upload"
                    onClickPromise={() => new Promise((resolve, reject) => {
                      const fn = onUploadPromise(files);
                      fn.then((value) => {
                        const ret = resolve(value);
                        setFiles([]);
                        return ret;
                      }, reason => reject(reason)).then(error => reject(error));
                    })}
                  />
                ) : (
                  <TableButton
                    key={files}
                    color="primary"
                    buttonText="upload"
                    onClickPromise={() => new Promise((resolve, reject) => {
                      const fn = onUploadPromise();
                      fn.then((value) => {
                        const ret = resolve(value);
                        setFiles([]);
                        return ret;
                      }, reason => reject(reason)).then(error => reject(error));
                    })}
                  />
                )
              }

            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
}

FileUpload.imageMimeTypes = [
  'image/svg+xml',
  'image/jpeg',
  'image/png',
  'image/gif',
];


FileUpload.propTypes = {
  acceptedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  singleFile: PropTypes.bool,
  displayList: PropTypes.bool,
  onUploadPromise: PropTypes.func,
  largeButton: PropTypes.bool,
};

FileUpload.defaultProps = {
  acceptedMimeTypes: [
    'text/plain',
    'text/html',
    'application/pdf',
    'application/msword',
    'text/csv',
    ...FileUpload.imageMimeTypes,
    ...excel.excelMimeTypes,
  ],
  singleFile: false,
  displayList: true,
  onUploadPromise: null,
  largeButton: false,
};

export default FileUpload;
