/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type TransferRequestStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PositionTransferRequestList_viewer$ref: FragmentReference;
declare export opaque type PositionTransferRequestList_viewer$fragmentType: PositionTransferRequestList_viewer$ref;
export type PositionTransferRequestList_viewer = {|
  +positionTransferRequests: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: any,
        +updatedAt: any,
        +status: TransferRequestStatus,
        +account: {|
          +id: string,
          +name: ?string,
        |},
        +positionTransferPurchases: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +updatedAt: any,
              +quantity: number,
              +reportDate: ?any,
              +contract: {|
                +id: string,
                +name: ?string,
                +symbol: string,
                +ibConId: number,
                +currency: Currency,
              |},
              +portfolio: {|
                +id: string,
                +trackingVueName: ?string,
              |},
              +costBasis: ?number,
              +commission: ?number,
              +dummy: boolean,
            |}
          |}>
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: PositionTransferRequestList_viewer$ref,
|};
export type PositionTransferRequestList_viewer$data = PositionTransferRequestList_viewer;
export type PositionTransferRequestList_viewer$key = {
  +$data?: PositionTransferRequestList_viewer$data,
  +$fragmentRefs: PositionTransferRequestList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PositionTransferRequestList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "accountType",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "positionTransferRequests",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "account",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "accountType",
          "variableName": "accountType"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "PositionTransferRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PositionTransferRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PositionTransferRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "positionTransferPurchases",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PositionTransferPurchaseNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PositionTransferPurchaseNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PositionTransferPurchaseNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "quantity",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "reportDate",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "contract",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ContractNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "symbol",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "ibConId",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "currency",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "portfolio",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "PortfolioNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "trackingVueName",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "costBasis",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "commission",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "dummy",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9a02a750866693c34c6421afbe92bac7';
module.exports = node;
