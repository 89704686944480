import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import BaseButton from '../buttons/BaseButton';


function ConfirmationDlg(props) {
  const { message, options, onOption } = props;
  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          options.map(option => (
            <BaseButton
              onClickPromise={() => onOption(option.id)}
              color="primary"
              buttonText={option.caption}
            />
          ))
        }
      </DialogActions>
    </React.Fragment>
  );
}

export default ConfirmationDlg;
