/**
 * @flow
 * @relayHash 03a4e374facca54ba2a287e29ab961d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VueDetails_vueDetails$ref = any;
export type VueDetailsQueryVariables = {|
  vueId?: ?string
|};
export type VueDetailsQueryResponse = {|
  +viewer: ?{|
    +vues: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: VueDetails_vueDetails$ref
        |}
      |}>
    |}
  |}
|};
export type VueDetailsQuery = {|
  variables: VueDetailsQueryVariables,
  response: VueDetailsQueryResponse,
|};
*/


/*
query VueDetailsQuery(
  $vueId: ID
) {
  viewer {
    vues(id: $vueId) {
      edges {
        node {
          ...VueDetails_vueDetails
          id
        }
      }
    }
    id
  }
}

fragment VueDetails_vueDetails on VueNode {
  id
  name
  description
  investmentObjective
  investmentStrategy
  investorProfile
  timeFrame
  sharingStrategy
  portfolioType
  riskFactor
  visible
  published
  createdAt
  updatedAt
  sharesightportfolio {
    id
  }
  haloportfolio {
    id
  }
  vuecontractSet {
    edges {
      node {
        id
        weight
        description
        researchPdfUrl
        contract {
          id
          symbol
          name
          currency
          exchange {
            symbol
            country
            name
            id
          }
        }
      }
    }
  }
  vueSet(orderBy: "-id") {
    edges {
      node {
        id
        name
        description
        investmentObjective
        investmentStrategy
        investorProfile
        timeFrame
        sharingStrategy
        portfolioType
        riskFactor
        visible
        published
        createdAt
        updatedAt
        sharesightportfolio {
          id
        }
        haloportfolio {
          id
        }
        vuecontractSet {
          edges {
            node {
              id
              weight
              description
              researchPdfUrl
              contract {
                id
                symbol
                name
                currency
                exchange {
                  symbol
                  country
                  name
                  id
                }
              }
            }
          }
        }
      }
    }
  }
  parentVue {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investmentObjective",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investmentStrategy",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investorProfile",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timeFrame",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sharingStrategy",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "riskFactor",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "visible",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "published",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v16 = [
  (v2/*: any*/)
],
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sharesightportfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "SharesightPortfolioNode",
  "plural": false,
  "selections": (v16/*: any*/)
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "haloportfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "HaloPortfolioNode",
  "plural": false,
  "selections": (v16/*: any*/)
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vuecontractSet",
  "storageKey": null,
  "args": null,
  "concreteType": "VueContractNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "VueContractNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "VueContractNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "weight",
              "args": null,
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "researchPdfUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "contract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v19/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "exchange",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExchangeNode",
                  "plural": false,
                  "selections": [
                    (v19/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "country",
                      "args": null,
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v2/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "VueDetails_vueDetails",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v20/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vueSet",
                        "storageKey": "vueSet(orderBy:\"-id\")",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "-id"
                          }
                        ],
                        "concreteType": "VueNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v20/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "parentVue",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueNode",
                        "plural": false,
                        "selections": (v16/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VueDetailsQuery",
    "id": null,
    "text": "query VueDetailsQuery(\n  $vueId: ID\n) {\n  viewer {\n    vues(id: $vueId) {\n      edges {\n        node {\n          ...VueDetails_vueDetails\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment VueDetails_vueDetails on VueNode {\n  id\n  name\n  description\n  investmentObjective\n  investmentStrategy\n  investorProfile\n  timeFrame\n  sharingStrategy\n  portfolioType\n  riskFactor\n  visible\n  published\n  createdAt\n  updatedAt\n  sharesightportfolio {\n    id\n  }\n  haloportfolio {\n    id\n  }\n  vuecontractSet {\n    edges {\n      node {\n        id\n        weight\n        description\n        researchPdfUrl\n        contract {\n          id\n          symbol\n          name\n          currency\n          exchange {\n            symbol\n            country\n            name\n            id\n          }\n        }\n      }\n    }\n  }\n  vueSet(orderBy: \"-id\") {\n    edges {\n      node {\n        id\n        name\n        description\n        investmentObjective\n        investmentStrategy\n        investorProfile\n        timeFrame\n        sharingStrategy\n        portfolioType\n        riskFactor\n        visible\n        published\n        createdAt\n        updatedAt\n        sharesightportfolio {\n          id\n        }\n        haloportfolio {\n          id\n        }\n        vuecontractSet {\n          edges {\n            node {\n              id\n              weight\n              description\n              researchPdfUrl\n              contract {\n                id\n                symbol\n                name\n                currency\n                exchange {\n                  symbol\n                  country\n                  name\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  parentVue {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edf45c6fa626af0144ca085a63414fe9';
module.exports = node;
