/**
 * @flow
 * @relayHash 8334f2bdfba5ea731cd0f6f4e61ff160
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountPermissions_viewer$ref = any;
export type AccountPermissionsQueryVariables = {|
  accountId?: ?string
|};
export type AccountPermissionsQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountPermissions_viewer$ref
  |}
|};
export type AccountPermissionsQuery = {|
  variables: AccountPermissionsQueryVariables,
  response: AccountPermissionsQueryResponse,
|};
*/


/*
query AccountPermissionsQuery(
  $accountId: ID
) {
  viewer {
    ...AccountPermissions_viewer_shlXP
    id
  }
}

fragment AccountPermissions_viewer_shlXP on Query {
  accounts(id: $accountId) {
    edges {
      node {
        vueexclusivepermissionSet {
          edges {
            node {
              id
              vue {
                id
                name
              }
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountPermissionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountPermissions_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountPermissionsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "accountId"
              }
            ],
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vueexclusivepermissionSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueExclusivePermissionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueExclusivePermissionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueExclusivePermissionNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "vue",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "VueNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "name",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountPermissionsQuery",
    "id": null,
    "text": "query AccountPermissionsQuery(\n  $accountId: ID\n) {\n  viewer {\n    ...AccountPermissions_viewer_shlXP\n    id\n  }\n}\n\nfragment AccountPermissions_viewer_shlXP on Query {\n  accounts(id: $accountId) {\n    edges {\n      node {\n        vueexclusivepermissionSet {\n          edges {\n            node {\n              id\n              vue {\n                id\n                name\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1bc57e594aaf83befb7987ed2195fc1c';
module.exports = node;
