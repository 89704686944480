import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCommonStyles } from '../Styles';
import BaseButton from '../buttons/BaseButton';


function InnerTabButton(props) {
  const classes = useCommonStyles();
  const { 
    label,
    children,
    onClick,
  } = props;

  return (
    <Grid item>
      <FormControl className={classes.formControlToggleButtonGroup}>
        <InputLabel htmlFor="accountType-helper">{label}</InputLabel>
        <BaseButton
          color="Primary"
          size="large"
          onClick={onClick}
        >
          {children}
        </BaseButton>
      </FormControl>
    </Grid>
  );
}

InnerTabButton.propTypes = {
};

InnerTabButton.defaultProps = {
};

export default InnerTabButton;
