import React, { useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import DailyHoldingsList from '../holdings/DailyHoldingsList';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';


function ContractHoldings(props) {
  const classes = useCommonStyles();
  const { viewer } = props;
  const contract = viewer.contracts.edges[0].node;
  const ibContractId = contract.ibConId;
  const initReportDate = moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL);
  const [reportDate, setReportDate] = React.useState(initReportDate);
  const [ucVar] = useState({
    reportDate: initReportDate,
  });
  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/*Report Data*/}
            <KeyboardDatePicker
              className={classes.formControl}
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              id="date-picker-inline"
              label="Report Date"
              value={reportDate}
              disableFuture
              onChange={(reportDateTime) => {
                let newReportDate = null;
                if (reportDateTime !== null && reportDateTime !== '') {
                  newReportDate = moment(reportDateTime).format(MOMENT_DATE_FORMAT_GRAPHQL);
                } else {
                  newReportDate = null;
                }
                setReportDate(newReportDate);
                ucVar.reportDate = newReportDate;
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </BaseGridContainer>
      <div>
        {
          (contract && reportDate) && (
            <DailyHoldingsList
              key={`${contract || contract.id}-${ibContractId}-${reportDate}`}
              onUpdatedContract={() => null}
              contractId={contract.id}
              ibContractId={ibContractId}
              date={reportDate}
              contractCaption={`${contract.symbol}:${contract.currency}`}
            />
          )
        }

      </div>
    </div>
  );
}

const query = graphql`
  query ContractHoldingsQuery (
    $contractId: ID,
  ) {
    viewer {
      contracts (
        id: $contractId
      ) {
        edges {
          node {
            ...ContractHoldings_contracts 
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  ContractHoldings,
  {
    contracts: graphql`
      fragment ContractHoldings_contracts on ContractNode {
        id
        symbol
        ibConId
      }
    `,
  },
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ contractId }) => ({ contractId }),
));
