import { withTabItems } from '../common/RoutableTabContainer';
import ContractList from './ContractList';
import FactsetContractList from './FactsetContractList';


export default withTabItems([
  {
    label: 'Contracts',
    url: 'contracts',
    comp: ContractList,
  },
  {
    label: 'Factset Contracts',
    url: 'factset-contracts',
    comp: FactsetContractList,
  },
]);
