import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

export default function SSDIcon(props) {
  const {
    overrideStyle,
    ...otherProps
  } = props;
  return (
    <SvgIcon {...otherProps}>
      <path
        style={overrideStyle}
        d="M21.5,11.9c-0.1,5-4.2,9.1-9.3,9.1H3v-5.6L6,12L3,8.5V3h9.2C17.1,3,21.4,7,21.5,11.9 M7.1,17.6h5.7l4.8-5.6l-4.8-5.6H7.1 M7.1,6.4L7.1,6.4l4.8,5.6l-4.8,5.6"
      />
    </SvgIcon>
  );
}

SSDIcon.propTypes = {
  overrideStyle: PropTypes.object,
};

SSDIcon.defaultProps = {
  overrideStyle: {},
};
