import React from 'react';
import { ToggleButton } from '@material-ui/lab';

function TableToggleButton(props) {
  return (
    <ToggleButton
      {...props}
      style={{ height: 18 }}
    />
  );
}

export default TableToggleButton;
