/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DividendDefForm_viewer$ref: FragmentReference;
declare export opaque type DividendDefForm_viewer$fragmentType: DividendDefForm_viewer$ref;
export type DividendDefForm_viewer = {|
  +ibCashTransactions: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +group: ?{|
          +id: string,
          +description: ?string,
          +dividenddef: ?{|
            +description: ?string,
            +currency: Currency,
            +id: string,
            +createdAt: any,
            +updatedAt: any,
            +payDate: any,
            +exDate: any,
            +rateFranked: ?number,
            +frankingTaxRate: ?number,
            +dividendAmount: ?number,
          |},
        |},
      |}
    |}>
  |},
  +$refType: DividendDefForm_viewer$ref,
|};
export type DividendDefForm_viewer$data = DividendDefForm_viewer;
export type DividendDefForm_viewer$key = {
  +$data?: DividendDefForm_viewer$data,
  +$fragmentRefs: DividendDefForm_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DividendDefForm_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "brokerActivityId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ibCashTransactions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "brokerActivityId"
        }
      ],
      "concreteType": "IBCashTransactionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "IBCashTransactionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "IBCashTransactionNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "group",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerActivityGroupNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "dividenddef",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "DividendDefNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "currency",
                          "args": null,
                          "storageKey": null
                        },
                        (v0/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "createdAt",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "updatedAt",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "payDate",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "exDate",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "rateFranked",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "frankingTaxRate",
                          "args": null,
                          "storageKey": null
                        },
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "dividendAmount",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9a8fd77be012e856c3ede2d9de6d0a6';
module.exports = node;
