import * as React from 'react';
import TableStatus from './TableStatus';
import EnumValue from './EnumValue';
import { COLOR_UNDEFINED } from '../../common/constants';

export const approvalStatusColorMap = {
  RR: '#2b728c',
  SFA: '#FFA500',
  SA: '#ff6200',
  OD: '#c70202',
  OA: '#284815',
  CA: '#48772c',
  CD: '#af4727',
  EX: '#858585',
  IN: '#82ab8a',
};


export default function ApprovalStatus(props) {
  const {
    approval,
  } = props;
  const statusColor = approvalStatusColorMap[approval.status] || COLOR_UNDEFINED;
  return (
    <TableStatus customStyle={{ backgroundColor: statusColor }}>
      <EnumValue
        enumType="OrderApprovalStatus"
        enumValue={approval.status}
        // captionOverrides={{
        //   'Pending Execution': 'pen. ex.',
        //   'Partially Filled': 'p-fill',
        // }}
      />
    </TableStatus>
  );
}
