/**
 * @flow
 * @relayHash 68896a04ee29740794df084547f5ea97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveUserFromAccountMutationVariables = {|
  clientAccountId: string,
  userId: string,
|};
export type RemoveUserFromAccountMutationResponse = {|
  +removeUserFromAccount: ?{|
    +clientAccount: ?{|
      +id: string,
      +name: ?string,
      +userModels: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +username: string,
            +email: string,
            +firstName: string,
            +lastName: string,
          |}
        |}>
      |},
    |}
  |}
|};
export type RemoveUserFromAccountMutation = {|
  variables: RemoveUserFromAccountMutationVariables,
  response: RemoveUserFromAccountMutationResponse,
|};
*/


/*
mutation RemoveUserFromAccountMutation(
  $clientAccountId: ID!
  $userId: ID!
) {
  removeUserFromAccount(clientAccountId: $clientAccountId, userId: $userId) {
    clientAccount {
      id
      name
      userModels {
        edges {
          node {
            id
            username
            email
            firstName
            lastName
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeUserFromAccount",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "RemoveUserFromAccount",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "clientAccount",
        "storageKey": null,
        "args": null,
        "concreteType": "ClientAccountNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userModels",
            "storageKey": null,
            "args": null,
            "concreteType": "UserModelNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserModelNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserModelNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "firstName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastName",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveUserFromAccountMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveUserFromAccountMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveUserFromAccountMutation",
    "id": null,
    "text": "mutation RemoveUserFromAccountMutation(\n  $clientAccountId: ID!\n  $userId: ID!\n) {\n  removeUserFromAccount(clientAccountId: $clientAccountId, userId: $userId) {\n    clientAccount {\n      id\n      name\n      userModels {\n        edges {\n          node {\n            id\n            username\n            email\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8d4d8c4ee497a13082b0b162dadde1b';
module.exports = node;
