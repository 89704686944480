import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemDateField from '../common/drawer/DrawerGridItemDateField';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import { brokerageCredit } from '../../mutations/BrokerageCredit';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import PopupUserSelectorField from '../common/PopupUserSelectorField';
import { doCommitPromise } from '../../common/commit';

function BrokerageCreditForm(props) {
  const { accountId, onAdd, onClose } = props;
  const [expiryDate, setExpiryDate] = React.useState(null);
  const [creditType, setCreditType] = React.useState(0);
  const [members] = React.useState({
    initialAmount: 0,
    note: '',
    description: '',
    provisionedBy: null,
  });
  const classes = useCommonStyles();

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>

        <DrawerGridItemHeading
          heading="Brokerage Credit"
        />

        <DrawerGridItemNumberField
          label="Amount"
          defaultValue={members.initialAmount}
          onChange={(e) => {
            members.initialAmount = e.target.value;
          }}
        />

        <DrawerGridItemDateField
          label="Expiry Date"
          onChange={newExpiryDate => setExpiryDate(newExpiryDate)}
          value={expiryDate}
        />

        <DrawerGridItemTextField
          label="Note"
          defaultValue={members.note}
          onChange={(e) => {
            members.note = e.target.value;
          }}
        />

        <DrawerGridItemTextField
          label="Description"
          defaultValue={members.description}
          onChange={(e) => {
            members.description = e.target.value;
          }}
        />

        <DrawerGridItemSelectField
          enumType="CreditType"
          onChange={e => setCreditType(e.target.value)}
        />

        <Grid item xs={12}>
          <PopupUserSelectorField
            fullWidth
            caption="Provisioned by"
            onSelect={(user) => {
              members.provisionedBy = user;
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <AddButton
              disabled={!expiryDate || !creditType}
              onClickPromise={() => doCommitPromise(
                brokerageCredit,
                {
                  initialAmount: members.initialAmount,
                  expiryDate,
                  note: members.note,
                  description: members.description,
                  accountId,
                  creditType,
                  clientMutationId: 0,
                  provisionedById: members.provisionedBy && members.provisionedBy.id,
                },
                () => {
                  onAdd && onAdd();
                  onClose && onClose();
                },
              )}
            />
          </BottomGridContainer>
        </Grid>

      </DrawerGridItemContainer>
    </div>
  );
}

export default BrokerageCreditForm;
