/**
 * @flow
 * @relayHash 31db610968f13655975f211d73260956
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateApprovalMutationVariables = {|
  approvableId: string
|};
export type CreateApprovalMutationResponse = {|
  +createApproval: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +approval: ?{|
      +id: string,
      +approvedBy: {|
        +id: string,
        +username: string,
      |},
      +approvedAt: any,
    |},
    +hasCompleteApprovals: ?boolean,
  |}
|};
export type CreateApprovalMutation = {|
  variables: CreateApprovalMutationVariables,
  response: CreateApprovalMutationResponse,
|};
*/


/*
mutation CreateApprovalMutation(
  $approvableId: ID!
) {
  createApproval(approvableId: $approvableId) {
    errors {
      field
      messages
    }
    approval {
      id
      approvedBy {
        id
        username
      }
      approvedAt
    }
    hasCompleteApprovals
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "approvableId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createApproval",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "approvableId",
        "variableName": "approvableId"
      }
    ],
    "concreteType": "CreateApproval",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approval",
        "storageKey": null,
        "args": null,
        "concreteType": "ApprovalNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "approvedBy",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "approvedAt",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasCompleteApprovals",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateApprovalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateApprovalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateApprovalMutation",
    "id": null,
    "text": "mutation CreateApprovalMutation(\n  $approvableId: ID!\n) {\n  createApproval(approvableId: $approvableId) {\n    errors {\n      field\n      messages\n    }\n    approval {\n      id\n      approvedBy {\n        id\n        username\n      }\n      approvedAt\n    }\n    hasCompleteApprovals\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9fce82bfbe174bf4ac87909509ce2f88';
module.exports = node;
