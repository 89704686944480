/**
 * @flow
 * @relayHash d0be29c272c24f4ef0bad3d6a50b7a07
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantExchangeFeeTemplateDeleteMutationVariables = {|
  tenantExchangeFeeTemplateId: string
|};
export type TenantExchangeFeeTemplateDeleteMutationResponse = {|
  +deleteTenantExchangeFeeTemplate: ?{|
    +tenant: ?{|
      +id: string,
      +tenantexchangefeetemplateSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +createdAt: any,
            +exchange: {|
              +id: string,
              +symbol: string,
            |},
            +tenant: {|
              +id: string,
              +name: string,
            |},
            +feeTemplate: {|
              +id: string,
              +name: string,
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type TenantExchangeFeeTemplateDeleteMutation = {|
  variables: TenantExchangeFeeTemplateDeleteMutationVariables,
  response: TenantExchangeFeeTemplateDeleteMutationResponse,
|};
*/


/*
mutation TenantExchangeFeeTemplateDeleteMutation(
  $tenantExchangeFeeTemplateId: ID!
) {
  deleteTenantExchangeFeeTemplate(tenantExchangeFeeTemplateId: $tenantExchangeFeeTemplateId) {
    tenant {
      id
      tenantexchangefeetemplateSet {
        edges {
          node {
            id
            createdAt
            exchange {
              id
              symbol
            }
            tenant {
              id
              name
            }
            feeTemplate {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantExchangeFeeTemplateId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteTenantExchangeFeeTemplate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "tenantExchangeFeeTemplateId",
        "variableName": "tenantExchangeFeeTemplateId"
      }
    ],
    "concreteType": "DeleteTenantExchangeFeeTemplate",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantexchangefeetemplateSet",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantExchangeFeeTemplateNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantExchangeFeeTemplateNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantExchangeFeeTemplateNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exchange",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExchangeNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "feeTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeTemplateNode",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TenantExchangeFeeTemplateDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TenantExchangeFeeTemplateDeleteMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "TenantExchangeFeeTemplateDeleteMutation",
    "id": null,
    "text": "mutation TenantExchangeFeeTemplateDeleteMutation(\n  $tenantExchangeFeeTemplateId: ID!\n) {\n  deleteTenantExchangeFeeTemplate(tenantExchangeFeeTemplateId: $tenantExchangeFeeTemplateId) {\n    tenant {\n      id\n      tenantexchangefeetemplateSet {\n        edges {\n          node {\n            id\n            createdAt\n            exchange {\n              id\n              symbol\n            }\n            tenant {\n              id\n              name\n            }\n            feeTemplate {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b9f0fbce4e602668848b9405ddc1c473';
module.exports = node;
