import * as React from 'react';
import EntitySelectionDlg from './EntitySelectionDlg';
import TenantSearch from './TenantSearch';

function TenantSelectionDlg(props) {
  const {
    children,
    ...otherProps
  } = props;
  return (
    <EntitySelectionDlg
      {...otherProps}
      searchComp={TenantSearch}
      dialogTitle="Select Tenant"
    />
  );
}

TenantSelectionDlg.propTypes = {
  ...EntitySelectionDlg.propTypes,
};

TenantSelectionDlg.defaultProps = {
  ...EntitySelectionDlg.defaultProps,
};

export default TenantSelectionDlg;
