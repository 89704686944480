import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import BaseGridContainer from '../common/BaseGridContainer';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { toGlobalId } from '../../helpers/ID';
import UserSubscriptionsDetails from './UserSubscriptionsDetails';
import { isRecurNull } from '../../common/helpers';
import UserSubscriptionInvoices from './UserSubscriptionInvoices';
import UserSubscriptionActionLogs from './UserSubscriptionActionLogs';


const subSelector = viewer => viewer
  && viewer.userSubscriptions
  && viewer.userSubscriptions.edges[0].node;

function UserSubscription(props) {
  const {
    relay,
    viewer,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const subscription = isRecurNull(viewer, ['userSubscriptions', 'edges', 0, 'node']);

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <UserSubscriptionsDetails
          subscriptionSet={[subSelector(viewer)]}
          showTitle={false}
          showUserColumn
        />
        <UserSubscriptionInvoices
          userSubscriptionId={subscription.id}
          urlParamPrefix="inv."
        />
        <UserSubscriptionActionLogs
          userSubscriptionId={subscription.id}
          urlParamPrefix="al."
        />
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query UserSubscriptionSetQuery (
    $first: Int
    $offset: Int
    $userSubscriptionId: ID
  ) {
    viewer {
      ...UserSubscription_viewer
      @arguments(
        first: $first
        offset: $offset
        userSubscriptionId: $userSubscriptionId
      ) 
    }
  }
`;

const container = createRefetchContainer(
  UserSubscription,
  {
    viewer: graphql`
      fragment UserSubscription_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        userSubscriptionId: {type: ID}
      ){
        userSubscriptions(
          id: $userSubscriptionId
        ) {
          count
          edges {
            node {
              ...UserSubscriptionsDetails_all
              @relay(mask: false)
              
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    userSubscriptionId: toGlobalId('UserSubscription', props.match.params.userSubscriptionId),
  }),
  { debounceFields: ['search'] },
));
