import { withTabItems } from '../common/RoutableTabContainer';
import VueList from './VueList';
import { toGlobalId } from '../../helpers/ID';
import VueSharePermissionList from './VueSharePermissionList';

export default withTabItems(
  [
    {
      label: 'Macrovue',
      url: 'macrovue',
      comp: VueList,
      propCopyFn: () => ({
        vueType: 'MV',
        tenantId: toGlobalId('TenantNode', 1),
      }),
    },
    {
      label: 'Advised',
      url: 'adviser',
      comp: VueList,
      propCopyFn: () => ({
        vueType: 'COMPANY',
        showOwners: true,
        showAdd: false,
        showTenantFilter: true,
      }),
    },
    {
      label: 'Vue-Share',
      url: 'vue-share',
      comp: VueSharePermissionList,
      propCopyFn: () => ({}),
    },
  ],
);
