import * as React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import TableFieldMeta from '../../common/TableFieldMeta';
import { AddButton } from '../common/buttons/BaseButton';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import BaseGridContainer from '../common/BaseGridContainer';
import PopupTenantSelectorField from '../common/PopupTenantSelectorField';
import VueSharePermissionAddForm from './VueSharePermissionAddForm';
import TenantLink from '../common/links/TenantLink';
import TableButton from '../common/buttons/TableButton';
import { doCommitPromise } from '../../common/commit';
import removeTenantVueSharePermission from '../../mutations/RemoveTenantVueSharePermission';


function VueSharePermissionList(props) {
  const {
    viewer,
    relay,
    filter,
    tenantId,
  } = props;
  filter.setRelay(relay);
  const [showAddDrawer, setShowAddDrawer] = React.useState(false);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.fieldCell('owner-tenant', 'Owner Tenant',
      row => <TenantLink tenant={row.value.ownerTenant} />),
    TableFieldMeta.fieldCell('guest-tenant', 'Guest Tenant',
      row => <TenantLink tenant={row.value.guestTenant} />),
    TableFieldMeta.fieldCell('guest-tenant', '',
      row => (
        <TableButton
          key={row.value.id}
          color="secondary"
          onClickPromise={() => doCommitPromise(
            removeTenantVueSharePermission,
            {
              guestTenantId: row.value.guestTenant.id,
              ownerTenantId: row.value.ownerTenant.id,
            },
            () => filter.update(),
          )}
        >
          Delete
        </TableButton>)),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        onClose={() => setShowAddDrawer(false)}
        open={showAddDrawer}
        anchor="right"
      >
        <VueSharePermissionAddForm
          tenantId={tenantId}
          onAdd={() => {
            setShowAddDrawer(false);
            filter.update();
          }}
        />
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <BaseGridContainer>
            <Grid item>
              <PopupTenantSelectorField
                caption="Owner Tenant"
                onSelect={newTenant => filter.updateVar('ownerTenantId', newTenant.id)}
                className={classes.formControl}
                onSetNull={() => filter.updateVar('ownerTenantId', null)}
              />
            </Grid>

            <Grid item>
              <PopupTenantSelectorField
                caption="Guest Tenant"
                onSelect={newTenant => filter.updateVar('guestTenantId', newTenant.id)}
                className={classes.formControl}
                onSetNull={() => filter.updateVar('guestTenantId', null)}
              />
            </Grid>

          </BaseGridContainer>
        </Grid>

        <Grid item xs={4}>
          <Grid container justify="flex-end">
            <Grid item>
              <AddButton onClick={() => setShowAddDrawer(true)} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.vueSharePermissions : null)}
          />
        </Grid>

      </Grid>
    </div>
  );
}


const query = graphql`
  query VueSharePermissionListQuery (
    $first: Int
    $offset: Int
    $ownerTenantId: ID
    $guestTenantId: ID
  ) {
    viewer {
      ...VueSharePermissionList_viewer
      @arguments (
        first: $first
        offset: $offset
        ownerTenantId: $ownerTenantId
        guestTenantId: $guestTenantId
      )
    }
  }
`;

const container = createRefetchContainer(
  VueSharePermissionList,
  {
    viewer: graphql`
      fragment VueSharePermissionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int}
        offset: {type: Int}
        ownerTenantId: {type: ID}
        guestTenantId: {type: ID}
      ) {
        vueSharePermissions(
          first: $first
          offset: $offset
          ownerTenant: $ownerTenantId
          guestTenant: $guestTenantId
        ) {
          count
          edges {
            node {
              id
              createdAt
              updatedAt
              guestTenant {
                id
                name
              }
              ownerTenant {
                id
                name
              }
            }
          }

        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  () => ({
  }),
  ({ tenantId }) => ({ ownerTenantId: tenantId }),
));
