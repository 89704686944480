import * as React from 'react';
import { Drawer } from '@material-ui/core';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import VerticalTable from '../../common/VerticalTable';
import { toLocal } from '../../../helpers/ID';
import DateTime from '../../common/DateTimeMoment';
import TableButton from '../../common/buttons/TableButton';
import ConfirmCancelForm from './ConfirmCancelForm';


function StockOrderBrokerOrderDetails(props) {
  const { brokerOrder } = props;
  const [cancelPanelOpen, setCancelPanelOpen] = React.useState(false);

  const brokerOrderColumns = [
    ['Broker Order Id', node => toLocal(node.id)],
    ['Order Id (TWS-API)', 'twsOrderId'],
    ['Perm ID (Id in TWS Desktop)', 'twsPermId'],
    ['Order Ref', 'orderRef'],
    ['Quantity Filled (TWS-API)', 'quantityFilled'],
    ['Quantity Left (TWS-API)', 'quantityLeft'],
    ['Destination Exchange', node => node.ibExchange && node.ibExchange.symbol],
    ['Last Sync Time', node => <DateTime>{node.lastSyncTime}</DateTime>],
    ['', node => (
      <TableButton
        color="primary"
        onClick={e => setCancelPanelOpen(true)}
        disabled={['FIL', 'CAN', 'REJ', 'CRE'].includes(node.status)}
      >
        Cancel
      </TableButton>
    )],
  ];

  return (
    <>
      <Drawer
        open={cancelPanelOpen}
        anchor="right"
        onClose={() => setCancelPanelOpen(false)}
      >
        <ConfirmCancelForm
          brokerOrderId={brokerOrder.id}
          onClose={() => setCancelPanelOpen(false)}
        />
      </Drawer>
      <TitledWidgetItem
        xs={12}
        title="Broker Order Details"
      >
        <VerticalTable
          showHeadings={false}
          columnMeta={brokerOrderColumns}
          dataNode={brokerOrder}
        />
      </TitledWidgetItem>
    </>
  );
}

export default StockOrderBrokerOrderDetails;
