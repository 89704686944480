import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { commitMutation } from 'react-relay';
import moment from 'moment';
import { useCommonStyles } from '../common/Styles';
import environment from '../../Environment';
import notiStack from '../../common/notiStack';
import AddButton from '../common/buttons/AddButton';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import createRangeReconciliationMutation from '../../mutations/CreateRangeReconciliation';
import { setGraphQLTime } from '../../common/time';

export default function AddReconciliationForm(props) {
  const { onClose, onAddRec } = props;
  const classes = useCommonStyles();
  const [fromDate, setFromDate] = useState(moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL));
  const [toDate, setToDate] = useState(moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL));

  const createRangeReconciliation = (recFromDate, recToDate) => {
    const variables = {
      fromDate: recFromDate,
      toDate: recToDate,
    };
    commitMutation(
      environment,
      {
        mutation: createRangeReconciliationMutation,
        variables,
        onCompleted: (response, errors) => {
          if (response) {
            notiStack.success('Reconciliation created');
            if (onClose) {
              onClose();
            }
            if (onAddRec) {
              onAddRec();
            }
          }
        },
      },
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Create Reconciliation
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/*Report Data*/}
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-inline"
              label="From Date"
              value={fromDate}
              disableFuture
              fullWidth
              onChange={e => setGraphQLTime(e, setFromDate)}
              KeyboardButtonProps={{
                'aria-label': 'from date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/*Report Data*/}
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="yyyy-MM-dd"
              id="date-picker-inline"
              label="To Date"
              value={toDate}
              disableFuture
              onChange={e => setGraphQLTime(e, setToDate)}
              KeyboardButtonProps={{
                'aria-label': 'to date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.bottomButtonContainer}>
            <AddButton
              onClick={e => createRangeReconciliation(fromDate, toDate)}
              buttonText="Reconciliation"
            />
          </div>
        </Grid>

      </Grid>
    </div>
  );
}
