import { graphql } from 'react-relay';

const vueCommunicationQuery = graphql`
  query VueCommunicationQuery (
    $vueId: ID,
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...VueCommunicationList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        vueId: $vueId,
      )
    }
  }
`;

export default vueCommunicationQuery;
