/**
 * @flow
 * @relayHash d49bc8fa68f9a90b648701d7fba5c60d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCannedSecurityQuestionMutationVariables = {|
  question: string
|};
export type CreateCannedSecurityQuestionMutationResponse = {|
  +createCannedSecurityQuestion: ?{|
    +cannedSecurityQuestion: ?{|
      +id: string
    |}
  |}
|};
export type CreateCannedSecurityQuestionMutation = {|
  variables: CreateCannedSecurityQuestionMutationVariables,
  response: CreateCannedSecurityQuestionMutationResponse,
|};
*/


/*
mutation CreateCannedSecurityQuestionMutation(
  $question: String!
) {
  createCannedSecurityQuestion(question: $question) {
    cannedSecurityQuestion {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "question",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCannedSecurityQuestion",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "question",
        "variableName": "question"
      }
    ],
    "concreteType": "CreateCannedSecurityQuestion",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cannedSecurityQuestion",
        "storageKey": null,
        "args": null,
        "concreteType": "CannedSecurityQuestionNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCannedSecurityQuestionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCannedSecurityQuestionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCannedSecurityQuestionMutation",
    "id": null,
    "text": "mutation CreateCannedSecurityQuestionMutation(\n  $question: String!\n) {\n  createCannedSecurityQuestion(question: $question) {\n    cannedSecurityQuestion {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0408fd590b831f86617e5d48c4a6530';
module.exports = node;
