/**
 * @flow
 * @relayHash a80d4f40cfe6f241ba4d4630991ce545
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVueResearchMutationVariables = {|
  vueResearchId: string,
  title?: ?string,
  researchPdf?: ?any,
|};
export type UpdateVueResearchMutationResponse = {|
  +updateVueResearch: ?{|
    +vueResearch: ?{|
      +id: string,
      +title: string,
      +researchPdfUrl: ?string,
      +updatedAt: any,
    |}
  |}
|};
export type UpdateVueResearchMutation = {|
  variables: UpdateVueResearchMutationVariables,
  response: UpdateVueResearchMutationResponse,
|};
*/


/*
mutation UpdateVueResearchMutation(
  $vueResearchId: ID!
  $title: String
  $researchPdf: Upload
) {
  updateVueResearch(vueResearchId: $vueResearchId, title: $title, researchPdf: $researchPdf) {
    vueResearch {
      id
      title
      researchPdfUrl
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueResearchId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "researchPdf",
    "type": "Upload",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateVueResearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "researchPdf",
        "variableName": "researchPdf"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "vueResearchId",
        "variableName": "vueResearchId"
      }
    ],
    "concreteType": "UpdateVueResearchAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueResearch",
        "storageKey": null,
        "args": null,
        "concreteType": "VueResearchNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "researchPdfUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateVueResearchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateVueResearchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateVueResearchMutation",
    "id": null,
    "text": "mutation UpdateVueResearchMutation(\n  $vueResearchId: ID!\n  $title: String\n  $researchPdf: Upload\n) {\n  updateVueResearch(vueResearchId: $vueResearchId, title: $title, researchPdf: $researchPdf) {\n    vueResearch {\n      id\n      title\n      researchPdfUrl\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47974cc36f1c6b6dbc86f59b837c9ea6';
module.exports = node;
