/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AdminPanel_adminMeta$ref: FragmentReference;
declare export opaque type AdminPanel_adminMeta$fragmentType: AdminPanel_adminMeta$ref;
export type AdminPanel_adminMeta = {|
  +isPaper: ?boolean,
  +$refType: AdminPanel_adminMeta$ref,
|};
export type AdminPanel_adminMeta$data = AdminPanel_adminMeta;
export type AdminPanel_adminMeta$key = {
  +$data?: AdminPanel_adminMeta$data,
  +$fragmentRefs: AdminPanel_adminMeta$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "AdminPanel_adminMeta",
  "type": "AdminMeta",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isPaper",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '664cc85e270a695d154261dbc62296d9';
module.exports = node;
