/**
 * @flow
 * @relayHash 29ae5ce669fcef43cb41e019d88eb65f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractUploadImageMutationVariables = {|
  contractId: string,
  file: any,
  updateShared?: ?boolean,
|};
export type ContractUploadImageMutationResponse = {|
  +uploadContractImage: ?{|
    +contract: ?{|
      +id: string,
      +imageUrl: ?string,
    |}
  |}
|};
export type ContractUploadImageMutation = {|
  variables: ContractUploadImageMutationVariables,
  response: ContractUploadImageMutationResponse,
|};
*/


/*
mutation ContractUploadImageMutation(
  $contractId: ID!
  $file: Upload!
  $updateShared: Boolean
) {
  uploadContractImage(contractId: $contractId, file: $file, updateShared: $updateShared) {
    contract {
      id
      imageUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "file",
    "type": "Upload!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "updateShared",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "uploadContractImage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "file",
        "variableName": "file"
      },
      {
        "kind": "Variable",
        "name": "updateShared",
        "variableName": "updateShared"
      }
    ],
    "concreteType": "UploadContractImage",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "contract",
        "storageKey": null,
        "args": null,
        "concreteType": "ContractNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "imageUrl",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractUploadImageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractUploadImageMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ContractUploadImageMutation",
    "id": null,
    "text": "mutation ContractUploadImageMutation(\n  $contractId: ID!\n  $file: Upload!\n  $updateShared: Boolean\n) {\n  uploadContractImage(contractId: $contractId, file: $file, updateShared: $updateShared) {\n    contract {\n      id\n      imageUrl\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77bd109109e2ca2a39f9c4e91239a0a2';
module.exports = node;
