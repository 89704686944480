import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function NoBlock(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M 12 2 C 10.042026 2 8.2124162 2.5716928 6.6660156 3.5546875 L 8.125 5.0117188 C 9.2748089 4.3689913 10.595871 4 12 4 C 13.8 4 15.500391 4.5992187 16.900391 5.6992188 L 12.855469 9.7441406 L 14.255859 11.144531 L 18.300781 7.0996094 C 19.400781 8.4996094 20 10.2 20 12 C 20 13.404487 19.62936 14.724986 18.986328 15.875 L 20.445312 17.333984 C 21.428307 15.787584 22 13.957974 22 12 C 22 6.5 17.5 2 12 2 z M 4.2226562 2.8066406 L 2.8066406 4.2226562 L 4.265625 5.6816406 C 2.8519418 7.4072169 2 9.6086738 2 12 C 2 17.5 6.5 22 12 22 C 14.391326 22 16.592783 21.148058 18.318359 19.734375 L 19.777344 21.193359 L 21.193359 19.777344 L 19.734375 18.318359 L 18.3125 16.896484 L 13.408203 11.992188 L 12.007812 10.591797 L 7.1035156 5.6875 L 5.6816406 4.265625 L 4.3085938 2.8925781 L 4.2226562 2.8066406 z M 5.6875 7.1035156 L 10.591797 12.007812 L 5.6992188 16.900391 C 4.5992187 15.500391 4 13.8 4 12 C 4 10.15894 4.6320599 8.4593333 5.6875 7.1035156 z M 11.992188 13.408203 L 16.896484 18.3125 C 15.540667 19.36794 13.84106 20 12 20 C 10.2 20 8.4996094 19.400781 7.0996094 18.300781 L 11.992188 13.408203 z "
      />
    </SvgIcon>
  );
}
