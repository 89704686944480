import React from 'react';
import { Link } from 'react-router-dom';

const PlainLink = (props) => {
  const { children } = props;
  return (
    <Link
      {...props}
      style={{ textDecoration: 'none' }}
    >
      { children }
    </Link>
  );
};

export default PlainLink;
