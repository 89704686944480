import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import TableFieldMeta from '../../common/TableFieldMeta';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';
import VerticalTable from '../common/VerticalTable';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ApproveButton, CloseButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import ApprovalList from '../approvals/ApprovalList';
import createApproval from '../../mutations/CreateApproval';
import updateCurrencyConversionRequestStatus from '../../mutations/UpdateCurrencyConversionRequestStatus';
import EnumValue from '../common/EnumValue';
import mvMutationErrors from '../../common/errorReport';
import MoreButton from '../common/buttons/MoreButton';


function CurrencyConversionFormData(props) {
  const { currencyConversionRequest, onConvertSuccess } = props;
  const [request, setRequest] = React.useState(currencyConversionRequest);

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Convert Currency"
        />
        <Grid item xs={12}>
          <VerticalTable
            columnMeta={[
              ['Request ID', TableFieldMeta.localId.accessor],
              ['Quantity', node => -1 * node.quantity],
              ['From Currency', 'baseCurrency'],
              ['To Currency', 'quoteCurrency'],
              ['Status', node => (
                <EnumValue enumType="ApprovableStatus" enumValue={node.status} />
              )],
            ]}
            dataNode={request}
            showHeadings={false}
            valueAlignment="left"
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <CloseButton
              buttonText="Cancel"
              color="secondary"
              disabled={request.status !== 'PENDING'}
              onClickPromise={() => doCommitPromise(
                updateCurrencyConversionRequestStatus,
                {
                  currencyConversionRequestId: request.id,
                  status: 'CANCELLED',
                },
                () => (onConvertSuccess && onConvertSuccess()),
              )}
            />
          </Grid>

          <Grid item>
            <ApproveButton
              buttonText="Convert"
              disabled={request.status !== 'PENDING' || !request.hasCompleteApprovals}
              onClickPromise={() => doCommitPromise(
                updateCurrencyConversionRequestStatus,
                {
                  currencyConversionRequestId: request.id,
                  status: 'APPROVED',
                },
                () => (onConvertSuccess && onConvertSuccess()),
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <Grid item xs={12}>
          <ApprovalList approvals={request.approvals.edges} />
        </Grid>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ApproveButton
              buttonText="Approve"
              onClickPromise={() => doCommitPromise(
                createApproval,
                { approvableId: request.id },
                (response) => {
                  if (!mvMutationErrors(response, 'createApproval')) {
                    const approvalEdges = [
                      ...request.approvals.edges,
                      { node: response.createApproval.approval },
                    ];
                    const { hasCompleteApprovals } = response.createApproval;
                    const newRequest = {
                      ...request,
                      approvals: { edges: approvalEdges },
                      hasCompleteApprovals,
                    };
                    setRequest(newRequest);
                  }
                },
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}


const query = graphql`
  query CurrencyConversionListFormQuery(
    $currencyConversionRequestId: ID!
  ) {
    viewer {
      id
      currencyConversionRequests(
        id: $currencyConversionRequestId,
      ) {
        edges {
          node {
            ...CurrencyConversionListForm_viewer
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  CurrencyConversionFormData,
  graphql`
    fragment CurrencyConversionListForm_viewer on CurrencyConversionRequestNode {
      id
      baseCurrency
      quoteCurrency
      quantity
      status
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  `,
);

const modifiedComp = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({ 
    currencyConversionRequest: viewer && viewer.currencyConversionRequests.edges[0].node,
    onConvertSuccess: outerProps.onConvertSuccess,
  }),
  ({ currencyConversionRequestId }) => ({ currencyConversionRequestId }),
);


function CurrencyConversionForm(props) {
  const { currencyConversionRequest, onConvertSuccess } = props;
  const CurrencyConversionFormComp = modifiedComp;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();
  return (
    <div>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <CurrencyConversionFormComp
            currencyConversionRequestId={currencyConversionRequest.id}
            onConvertSuccess={(e) => {
              if (onConvertSuccess) onConvertSuccess();
              setDrawerOpen(false);
            }}
            
          />
        </div>
      </Drawer>
      <MoreButton
        onClick={() => setDrawerOpen(true)}
      />
    </div>
  );
}

export default CurrencyConversionForm;
