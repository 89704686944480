/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountStockHoldingList_viewer$ref: FragmentReference;
declare export opaque type AccountStockHoldingList_viewer$fragmentType: AccountStockHoldingList_viewer$ref;
export type AccountStockHoldingList_viewer = {|
  +vues: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |},
  +accounts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +portfolios: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +trackingVue: ?{|
                +id: string,
                +name: string,
              |},
            |}
          |}>
        |},
        +defaultPortfolio: ?string,
        +holdings: ?$ReadOnlyArray<?{|
          +id: string,
          +contract: {|
            +id: string,
            +name: ?string,
            +symbol: string,
            +currency: Currency,
          |},
          +quantity: number,
          +portfolio: {|
            +id: string,
            +trackingVue: ?{|
              +name: string
            |},
          |},
        |}>,
        +availableHoldings: ?$ReadOnlyArray<?{|
          +id: string,
          +contract: {|
            +id: string,
            +name: ?string,
            +symbol: string,
            +currency: Currency,
          |},
          +quantity: number,
          +portfolio: {|
            +id: string,
            +trackingVue: ?{|
              +name: string
            |},
          |},
        |}>,
      |}
    |}>
  |},
  +$refType: AccountStockHoldingList_viewer$ref,
|};
export type AccountStockHoldingList_viewer$data = AccountStockHoldingList_viewer;
export type AccountStockHoldingList_viewer$key = {
  +$data?: AccountStockHoldingList_viewer$data,
  +$fragmentRefs: AccountStockHoldingList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/),
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "contract",
    "storageKey": null,
    "args": null,
    "concreteType": "ContractNode",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "symbol",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "currency",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "portfolio",
    "storageKey": null,
    "args": null,
    "concreteType": "PortfolioNode",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trackingVue",
        "storageKey": null,
        "args": null,
        "concreteType": "VueNode",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "AccountStockHoldingList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vues",
      "storageKey": "vues(orderBy:\"name\",parentVue_Isnull:true,vueType:\"MV\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "name"
        },
        {
          "kind": "Literal",
          "name": "parentVue_Isnull",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "vueType",
          "value": "MV"
        }
      ],
      "concreteType": "VueNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "accounts",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "accountId"
        }
      ],
      "concreteType": "AccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolios",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PortfolioNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PortfolioNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "trackingVue",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "VueNode",
                              "plural": false,
                              "selections": (v2/*: any*/)
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "defaultPortfolio",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "holdings",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "HoldingBalanceNode",
                  "plural": true,
                  "selections": (v3/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "availableHoldings",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AvailableHoldingBalanceNode",
                  "plural": true,
                  "selections": (v3/*: any*/)
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ec05079d54f8ce69ee5af6eed1a851d';
module.exports = node;
