import * as React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import { fromGlobalId, toGlobalId } from '../../../helpers/ID';
import { useCommonStyles } from '../Styles';

const AccountLink = (props) => {
  const {
    account,
    charLimit,
    showAppLink,
    showNumber,
  } = props;
  if (!account) return null;
  let accountCaption = account.name || '';
  if (charLimit !== null && accountCaption.length > charLimit) {
    accountCaption = `${account.name.substr(0, charLimit - 1)}...`;
  }
  const accountId = fromGlobalId(account.id)[1];
  if (accountCaption === '' && showNumber) {
    accountCaption = `${accountId}`;
  }
  const classes = useCommonStyles();
  const clientAccountId = toGlobalId('ClientAccountNode', accountId);
  const appLink = `${process.env.REACT_APP_HALO}/account/holdings/${clientAccountId}`;
  return (
    <span>
      <Link href={`/account/${accountId}`}>
        {accountCaption}
      </Link>
      {
        showAppLink && (
          <>
            &nbsp;
            <a
              href={appLink}
              className={classes.appAccountLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              app
            </a>
          </>
        )
      }
    </span>
  );
};

AccountLink.propTypes = {
  account: PropTypes.object.isRequired,
  charLimit: PropTypes.number,
  showAppLink: PropTypes.bool,
  showNumber: PropTypes.bool,
};

AccountLink.defaultProps = {
  charLimit: null,
  showAppLink: false,
  showNumber: false,
};

export default AccountLink;
