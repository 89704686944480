import { graphql } from 'react-relay';

const invalidateTradeSummaries = graphql`
  mutation InvalidateTradeSummariesMutation (
    $accountId: ID!
    $portfolioId: ID
    $regenerate: Boolean
  ) {
    invalidateTradeSummaries (
      accountId: $accountId,
      portfolioId: $portfolioId,
      regenerate: $regenerate
    ) {
      success
    }
  }
`;

export default invalidateTradeSummaries;
