/**
 * @flow
 * @relayHash 5af645cae32ac958ed2fbb5656ae95e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveVueContractMutationVariables = {|
  vueId: string,
  contractId: string,
|};
export type RemoveVueContractMutationResponse = {|
  +removeVueContract: ?{|
    +vue: ?{|
      +id: string,
      +vuecontractSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +contract: {|
              +id: string
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type RemoveVueContractMutation = {|
  variables: RemoveVueContractMutationVariables,
  response: RemoveVueContractMutationResponse,
|};
*/


/*
mutation RemoveVueContractMutation(
  $vueId: ID!
  $contractId: ID!
) {
  removeVueContract(vueId: $vueId, contractId: $contractId) {
    vue {
      id
      vuecontractSet {
        edges {
          node {
            id
            contract {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "removeVueContract",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "vueId",
        "variableName": "vueId"
      }
    ],
    "concreteType": "RemoveVueContractAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vue",
        "storageKey": null,
        "args": null,
        "concreteType": "VueNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vuecontractSet",
            "storageKey": null,
            "args": null,
            "concreteType": "VueContractNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueContractNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueContractNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "RemoveVueContractMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "RemoveVueContractMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "RemoveVueContractMutation",
    "id": null,
    "text": "mutation RemoveVueContractMutation(\n  $vueId: ID!\n  $contractId: ID!\n) {\n  removeVueContract(vueId: $vueId, contractId: $contractId) {\n    vue {\n      id\n      vuecontractSet {\n        edges {\n          node {\n            id\n            contract {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '93b322f1fd3812ec119fcbca802ebb2b';
module.exports = node;
