/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BulkOrderList_viewer$ref: FragmentReference;
declare export opaque type BulkOrderList_viewer$fragmentType: BulkOrderList_viewer$ref;
export type BulkOrderList_viewer = {|
  +bulkOrders: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: any,
        +createdBy: ?{|
          +id: string,
          +fullName: ?string,
        |},
        +portfolioorderSet: {|
          +count: ?number
        |},
        +contracts: ?$ReadOnlyArray<?{|
          +symbol: string
        |}>,
        +statusCounts: ?$ReadOnlyArray<?{|
          +type: ?string,
          +count: ?number,
          +description: ?string,
        |}>,
        +account: ?{|
          +id: string,
          +name: ?string,
        |},
        +stockOrders: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +quantity: ?number,
              +brokerOrder: ?{|
                +id: string,
                +matched: boolean,
                +status: OrderStatus,
                +quantity: ?number,
                +quantityFilled: ?number,
                +quantityLeft: ?number,
              |},
            |}
          |}>
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: BulkOrderList_viewer$ref,
|};
export type BulkOrderList_viewer$data = BulkOrderList_viewer;
export type BulkOrderList_viewer$key = {
  +$data?: BulkOrderList_viewer$data,
  +$fragmentRefs: BulkOrderList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BulkOrderList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bulkOrders",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "account",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        }
      ],
      "concreteType": "BulkOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BulkOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BulkOrderNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "createdBy",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioorderSet",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioOrderNodeConnection",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contracts",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractNode",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "symbol",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "statusCounts",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TypeCount",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "account",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AccountNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "stockOrders",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "StockOrderNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StockOrderNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "StockOrderNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "brokerOrder",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerOrderNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "matched",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "status",
                                  "args": null,
                                  "storageKey": null
                                },
                                (v2/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "quantityFilled",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "quantityLeft",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        (v1/*: any*/)
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '329c3774d2649a08582a574f4dd683ba';
module.exports = node;
