import notiStack from './notiStack';

function mvMutationErrors(response, returnVar, errors) {
  if (!response) {
    return true;
  }

  if (errors && errors.length > 0) {
    errors.forEach((error) => {
      notiStack.error(`${error.messages}`);
    });
    return true;
  }

  const payload = response[returnVar];
  if (!response) {
    return true;
  }

  if (payload.errors && payload.errors.length > 0) {
    payload.errors.forEach((error) => {
      notiStack.error(`${error.messages}: ${error.field}`);
    });
    return true;
  }
  return false;
}


export function mvOrderErrors(response, returnVar) {
  if (!response) {
    return true;
  }
  const payload = response[returnVar];
  if (!payload) {
    return true;
  }

  if (payload.orderErrors && payload.orderErrors.length > 0) {
    payload.orderErrors.forEach((error) => {
      notiStack.error(`${error.orderIndex}: ${error.message}`);
    });
    return true;
  }
  return false;
}

export default mvMutationErrors;
