import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ViewIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/ReplayRounded';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../common/TableFieldMeta';
import DateTime, { DateComp } from '../common/DateTimeMoment';
import { useCommonStyles } from '../common/Styles';
import MultiCurrencyValue from '../common/MultiCurrencyValue';
import generateDailyReconciliation from '../../mutations/GenerateDailyReconciliation';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import { doCommitPromise } from '../../common/commit';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';


function DailyReconcileList(props) {
  const classes = useCommonStyles();
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);

  const renderBalanceDiff = (node) => {
    const { ibdailyreconciliation: recon } = node;
    if (!recon) {
      return <React.Fragment />;
    }
    const key = `${recon.id}-${recon.updatedAt}`;
    return (
      <MultiCurrencyValue
        decimalPlaces={2}
        key={key}
        suppressZero
        valueNode={recon.ibdailyreconciliationendbalanceSet}
      />
    );
  };

  const renderDeltaDiff = (node) => {
    const { ibdailyreconciliation: recon } = node;
    if (!recon) {
      return <React.Fragment />;
    }
    const key = `${recon.id}-${recon.updatedAt}`;
    return (
      <MultiCurrencyValue
        decimalPlaces={2}
        key={key}
        suppressZero
        valueNode={recon.ibdailyreconciliationdeltaSet}
      />
    );
  };

  const renderActions = (node) => {
    const hasReconcileOutput = node.ibdailyreconciliation;
    let outputKey = `view${node.id}`;
    if (hasReconcileOutput) {
      outputKey += node.ibdailyreconciliation.id;
    }

    return (
      <Grid container spacing={1} justify="center">
        { hasReconcileOutput && (
          <TableCompactGridItem>
            <TableIconButton
              key={outputKey}
              tooltip="View"
              color="primary"
              iconClass={ViewIcon}
              onClick={() => {
                window.open(node.ibdailyreconciliation.excelFileUrl, '_blank');
              }}
            />
          </TableCompactGridItem>)
        }
        <TableCompactGridItem>
          <TableIconButton
            key={outputKey}
            tooltip={hasReconcileOutput ? 'Regenerate' : 'Generate'}
            color={hasReconcileOutput ? 'primary' : 'default'}
            iconClass={ReplayIcon}
            onClickPromise={() => doCommitPromise(
              generateDailyReconciliation,
              {
                statementOfFundsId: node.id,
              },
            )}
          />
        </TableCompactGridItem>
      </Grid>

    );
  };

  const getDataNode = () => (viewer ? viewer.ibStatementOfFunds : null);

  if (!viewer) {
    return <React.Fragment />;
  }

  const columns = [
    {
      id: 'fromDate',
      Header: 'From Date',
      accessor: node => node,
      Cell: row => (
        <Typography variant="subtitle2">
          <DateComp key={row.value.id}>
            {row.value.fromDate}
          </DateComp>
        </Typography>
      ),
      ...TableFieldMeta.rightAlignStyle,
    },
    TableFieldMeta.field(node => (
      <DateTime>{node.ibdailyreconciliation && node.ibdailyreconciliation.updatedAt}</DateTime>
    ), 'Updated At'),
    {
      id: 'action',
      Header: 'Actions',
      accessor: node => node,
      Cell: row => renderActions(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'gapEndBalance',
      Header: 'Gap (End Balance)',
      accessor: node => node,
      Cell: row => renderBalanceDiff(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'gapDelta',
      Header: 'Gap (Delta)',
      accessor: node => node,
      Cell: row => renderDeltaDiff(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'srcFile',
      Header: 'src.',
      accessor: node => node,
      Cell: row => <Link href={row.value.fileUrl}>file</Link>,
      ...TableFieldMeta.centerAlignStyle,
    },
  ];

  return (
    <div className={classes.innerTabRoot}>
      <TablePaginator
        filter={filter}
        columns={columns}
        getDataNode={getDataNode}
      />
    </div>
  );
}

const query = graphql`
  query DailyReconcileListQuery (
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...DailyReconcileList_viewer
      @arguments (
        first: $first,
        offset: $offset,
      )
    }
  }
`;

const container = createRefetchContainer(
  DailyReconcileList,
  {
    viewer: graphql`
      fragment DailyReconcileList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
      ) {
        ibStatementOfFunds(
          first: $first,
          offset: $offset,
          orderBy: "-fromDate"
        ) {
          count
          edges {
            node {
              id
              file
              fileUrl
              createdAt
              updatedAt
              fromDate
              toDate
              ibdailyreconciliation {
                id
                createdAt
                updatedAt
                excelFile
                excelFileUrl
                excelFileName
                ibdailyreconciliationendbalanceSet {
                  edges {
                    node {
                      createdAt
                      updatedAt
                      currency
                      quantity
                    }
                  }
                }
                
                ibdailyreconciliationdeltaSet {
                  edges {
                    node {
                      createdAt
                      updatedAt
                      currency
                      quantity
                    }
                  }
                }
                
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  (viewer, outProps) => ({}),
  props => ({ }),
));
