import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import VerticalTable from './VerticalTable';
import TitledWidgetItem from './TitledWidgetItem';
import { extractKeys } from '../../common/helpers';
import { muiGridSizes } from '../../common/constants';

export function VerticalTableWidgetContainer(props) {
  const { children } = props;
  return (
    <Grid container spacing={3}>
      {children}
    </Grid>
  );
}

export function VerticalTableWidget(props) {
  const {
    columnMeta,
    dataNode,
    title,
    showHeadings,
    rightTopComp,
    valueAlignment,
    ...otherProps
  } = props;

  const [sizeProps, nonSizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <TitledWidgetItem
      title={title}
      rightTopComp={rightTopComp}
      {...sizeProps}
    >
      <VerticalTable
        showHeadings={showHeadings}
        columnMeta={columnMeta}
        dataNode={dataNode}
        valueAlignment={valueAlignment}
        {...nonSizeProps}
      />
    </TitledWidgetItem>
  );
}

VerticalTableWidget.propTypes = {
  title: PropTypes.string.isRequired,
  columnMeta: PropTypes.array.isRequired,
  dataNode: PropTypes.object.isRequired,
  xs: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  sm: PropTypes.number,
};

VerticalTableWidget.defaultProps = {
  xs: null,
  md: null,
  lg: null,
  sm: null,
};
