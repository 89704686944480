/**
 * @flow
 * @relayHash 8f6820de2f48b7bd12eb49fbf4905d5d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Scope = "DEEP" | "SELF" | "TENANT" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type AdviserPermissionInput = {|
  resource?: ?Resource,
  verb?: ?Verb,
  scope?: ?Scope,
|};
export type UpdateAdviserRoleMutationVariables = {|
  adviserRoleId: string,
  rolePermissions: $ReadOnlyArray<?AdviserPermissionInput>,
|};
export type UpdateAdviserRoleMutationResponse = {|
  +updateAdviserRole: ?{|
    +adviserRole: ?{|
      +id: string,
      +name: string,
      +rolePermissions: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +resource: Resource,
            +verb: Verb,
            +scope: Scope,
          |}
        |}>
      |},
    |}
  |}
|};
export type UpdateAdviserRoleMutation = {|
  variables: UpdateAdviserRoleMutationVariables,
  response: UpdateAdviserRoleMutationResponse,
|};
*/


/*
mutation UpdateAdviserRoleMutation(
  $adviserRoleId: ID!
  $rolePermissions: [AdviserPermissionInput]!
) {
  updateAdviserRole(adviserRoleId: $adviserRoleId, rolePermissions: $rolePermissions) {
    adviserRole {
      id
      name
      rolePermissions {
        edges {
          node {
            id
            resource
            verb
            scope
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "adviserRoleId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rolePermissions",
    "type": "[AdviserPermissionInput]!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateAdviserRole",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "adviserRoleId",
        "variableName": "adviserRoleId"
      },
      {
        "kind": "Variable",
        "name": "rolePermissions",
        "variableName": "rolePermissions"
      }
    ],
    "concreteType": "UpdateAdviserRole",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adviserRole",
        "storageKey": null,
        "args": null,
        "concreteType": "AdviserRoleNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rolePermissions",
            "storageKey": null,
            "args": null,
            "concreteType": "AdviserRolePermissionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AdviserRolePermissionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdviserRolePermissionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "resource",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "verb",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "scope",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateAdviserRoleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateAdviserRoleMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateAdviserRoleMutation",
    "id": null,
    "text": "mutation UpdateAdviserRoleMutation(\n  $adviserRoleId: ID!\n  $rolePermissions: [AdviserPermissionInput]!\n) {\n  updateAdviserRole(adviserRoleId: $adviserRoleId, rolePermissions: $rolePermissions) {\n    adviserRole {\n      id\n      name\n      rolePermissions {\n        edges {\n          node {\n            id\n            resource\n            verb\n            scope\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2dbfb0d6101d248fa92b200cf94902c9';
module.exports = node;
