/**
 * @flow
 * @relayHash e1cf61155a75d2399f5af29e179ce4b0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EnumValueQueryVariables = {|
  enumType: string
|};
export type EnumValueQueryResponse = {|
  +enumMetaInfo: ?{|
    +enumValues: ?$ReadOnlyArray<{|
      +description: ?string,
      +name: string,
    |}>
  |}
|};
export type EnumValueQuery = {|
  variables: EnumValueQueryVariables,
  response: EnumValueQueryResponse,
|};
*/


/*
query EnumValueQuery(
  $enumType: String!
) {
  enumMetaInfo: __type(name: $enumType) {
    enumValues {
      description
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "enumType",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "enumMetaInfo",
    "name": "__type",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "enumType"
      }
    ],
    "concreteType": "__Type",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "enumValues",
        "storageKey": null,
        "args": null,
        "concreteType": "__EnumValue",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "EnumValueQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "EnumValueQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "EnumValueQuery",
    "id": null,
    "text": "query EnumValueQuery(\n  $enumType: String!\n) {\n  enumMetaInfo: __type(name: $enumType) {\n    enumValues {\n      description\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '078d093fb1a4d532f15ea8310b7c1205';
module.exports = node;
