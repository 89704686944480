/**
 * @flow
 * @relayHash 968cb28eaddbb2079adceefa90223489
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountStockHoldingListClosed_viewer$ref = any;
export type AccountStockHoldingListClosedQueryVariables = {|
  accountId?: ?string
|};
export type AccountStockHoldingListClosedQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountStockHoldingListClosed_viewer$ref
  |}
|};
export type AccountStockHoldingListClosedQuery = {|
  variables: AccountStockHoldingListClosedQueryVariables,
  response: AccountStockHoldingListClosedQueryResponse,
|};
*/


/*
query AccountStockHoldingListClosedQuery(
  $accountId: ID
) {
  viewer {
    ...AccountStockHoldingListClosed_viewer_shlXP
    id
  }
}

fragment AccountStockHoldingListClosed_viewer_shlXP on Query {
  accounts(id: $accountId) {
    edges {
      node {
        portfolioContracts {
          portfolio {
            id
            trackingVue {
              name
              id
            }
          }
          contract {
            id
            name
            symbol
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountStockHoldingListClosedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountStockHoldingListClosed_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountStockHoldingListClosedQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "accountId"
              }
            ],
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolioContracts",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioContractNode",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "portfolio",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PortfolioNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "trackingVue",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v1/*: any*/)
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "symbol",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      },
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountStockHoldingListClosedQuery",
    "id": null,
    "text": "query AccountStockHoldingListClosedQuery(\n  $accountId: ID\n) {\n  viewer {\n    ...AccountStockHoldingListClosed_viewer_shlXP\n    id\n  }\n}\n\nfragment AccountStockHoldingListClosed_viewer_shlXP on Query {\n  accounts(id: $accountId) {\n    edges {\n      node {\n        portfolioContracts {\n          portfolio {\n            id\n            trackingVue {\n              name\n              id\n            }\n          }\n          contract {\n            id\n            name\n            symbol\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b4a6ae572730f9a87cf2e7dc0a4fbbe';
module.exports = node;
