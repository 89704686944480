import { createFragmentContainer, graphql } from 'react-relay';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { Edit, ExpandMore, RemoveCircle } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
// import { commonStyles, useCommonStyles } from '../../common/Styles';
import { Drawer } from '@material-ui/core';
import { VerticalTableWidget } from '../common/VerticalTableWidget';
import TitledWidgetItem from '../common/TitledWidgetItem';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { UpdateButton, DownloadButton } from '../common/buttons/BaseButton';
import EventAddForm from './EventAddForm';
import EventEditForm from './EventEditForm';

// import ApplicantUpdateForm from './ApplicantUpdateForm';

const eventElections = {
  edges: [{
    node: {
      description: 'lorem ipsum',
      election: '--',
    },
  }],
};
function EventDetails() {
  const columns = [
    ['Event', 'event'],
    ['Event type', 'eventType'],
    ['Contract', 'contract'],
    ['Announcement date', 'announcementDate'],
    ['Cutoff date / Time for elections', 'cutOffDate'],
    ['Description', 'description'],
  ];
  
  const [showEditEvent, setShowEditEvent] = React.useState(false);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={showEditEvent}
        onClose={() => setShowEditEvent(null)}
      >
        <EventEditForm close={() => {}} onAddUser={() => {}} />
      </Drawer>
      <BottomGridContainer extraStyles={{ marginBottom: '10px' }}>
        <Grid item>
          <DownloadButton onClickPromise={() => {}} />
        </Grid>
        <Grid item>
          <UpdateButton onClick={() => setShowEditEvent(true)} />
        </Grid>
      </BottomGridContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VerticalTableWidget
            xs={12}
            columnMeta={columns}
            dataNode={{
              event: 'This is an event',
              eventType: 'EventType',
              contract: 1252,
              announcementDate: '12/11/2024',
              cutOffDate: '01/22/2025',
              description: 'Lore ipsum dolor set amet',
            }}
            showHeadings={false}
            title="Client Account Details"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TitledWidgetItem
            xs={12}
            title="Election Info"
          >
            <Table size="small">
              <TableHead>
                <TableCell align="left">Description</TableCell>
                <TableCell align="center">Election</TableCell>
              </TableHead>
              <TableBody>
                {
                  eventElections.edges.map(
                    userModel => (
                      <TableRow>
                        <TableCell align="left" style={{ wordBreak: 'break-word' }}>
                          {userModel.node.description}
                        </TableCell>
                        <TableCell align="center" style={{ wordBreak: 'break-word' }}>
                          {userModel.node.election}
                        </TableCell>
                      </TableRow>
                    ),
                  )
                }
              </TableBody>
            </Table>

          </TitledWidgetItem>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventDetails;

// export default createFragmentContainer(
//   withStyles(commonStyles)(ApplicantDetails),
//   {
//     applicant: graphql`
//     fragment ApplicantDetails_applicant on ApplicantNode {
//       id
//       title
//       firstName
//       middleName
//       lastName
//       otherNames
//       dateOfBirth
//       countryOfBirth
//       countryOfCitizenship
//       gender
//       sourceOfFunds
//       sourceOfWealth
//       mobileNumber
//       email
//       billingAddress
//       mailingAddress
//       signature
//       applicantType
//       createdAt
//       updatedAt
//       applicantcheckSet {
//         edges {
//           node {
//             ...CheckListItemRow_applicantCheck
//             @relay(mask: false)
//           }
//         }
//       }
//       hasPendingRevisionRequest 
//     }
//   `,
//   },
// );
