import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EnumValue from '../common/EnumValue';
import TableStatus from '../common/TableStatus';


export default function FeeTitle(props) {
  const { fee } = props;
  return (
    <>
      <div>
        <Typography
          component="h6"
          variant="subtitle2"
          gutterBottom
        >
          <EnumValue enumType="FeeType" enumValue={fee.feeType} />
          {
            fee.currency && ` (${fee.currency})`
          }
        </Typography>
      </div>
      <Grid container spacing={1}>
        <Grid item>
          {fee.enabled
            ? <TableStatus primary>Enabled</TableStatus>
            : <TableStatus>Disabled</TableStatus>
          }
        </Grid>
        <Grid item>
          <TableStatus customStyle={{ color: 'black' }}>
            <EnumValue enumType="PaymentPeriod" enumValue={fee.paymentPeriod} />
          </TableStatus>
        </Grid>
        <Grid item>
          <TableStatus
            {...{
              primary: fee.simpleTranche,
              secondary: !fee.simpleTranche,
            }}
          >
            {fee.simpleTranche ? 'simple' : 'aggregated'}
          </TableStatus>
        </Grid>
      </Grid>
    </>
  );
}
