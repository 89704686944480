import { graphql } from 'react-relay';

// language=GraphQL
const createUserProvisionRequestMutation = graphql`
  mutation CreateUserProvisionRequestMutation(
    $clientAccountId: ID!,
    $email: String!,
    $firstName: String,
    $lastName: String,
    $userRoleIds: [ID]!
  ) {
    createUserProvisionRequest (
      clientAccountId: $clientAccountId,
      email: $email,
      firstName: $firstName,
      lastName: $lastName,
      userRoleIds: $userRoleIds
    ) {
      userProvisionRequest {
        id
        userModel {
          id
          username
          email
        }
        clientAccount {
          id
          name
        }
        status
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    }
  }
`;

export default createUserProvisionRequestMutation;
