/**
 * @flow
 * @relayHash 5bd7a5f4637ede0ea90831bb5003ba02
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerOrderList_viewer$ref = any;
export type BrokerOrderListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  statusIn?: ?$ReadOnlyArray<?string>,
  instrumentType?: ?string,
  matched?: ?boolean,
  orderRef?: ?string,
|};
export type BrokerOrderListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrokerOrderList_viewer$ref
  |}
|};
export type BrokerOrderListQuery = {|
  variables: BrokerOrderListQueryVariables,
  response: BrokerOrderListQueryResponse,
|};
*/


/*
query BrokerOrderListQuery(
  $first: Int
  $offset: Int
  $statusIn: [String]
  $instrumentType: String
  $matched: Boolean
  $orderRef: String
) {
  viewer {
    ...BrokerOrderList_viewer_OVFmX
    id
  }
}

fragment BrokerOrderList_viewer_OVFmX on Query {
  brokerOrders(first: $first, offset: $offset, statusIn: $statusIn, instrumentType: $instrumentType, matched: $matched, orderBy: "-id", orderRef: $orderRef) {
    edges {
      node {
        id
        createdAt
        orderType
        matched
        quantity
        quantityFilled
        quantityLeft
        status
        instrumentType
        orderRef
        side
        lastSyncTime
        twsPermId
        twsOrderId
        parentOrder {
          id
        }
        orderSet {
          edges {
            node {
              id
              stockorder {
                contract {
                  id
                  symbol
                }
                id
              }
              fxorder {
                contract {
                  id
                  localSymbol
                }
                id
              }
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "statusIn",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "instrumentType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "matched",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderRef",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "instrumentType",
  "variableName": "instrumentType"
},
v3 = {
  "kind": "Variable",
  "name": "matched",
  "variableName": "matched"
},
v4 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v5 = {
  "kind": "Variable",
  "name": "orderRef",
  "variableName": "orderRef"
},
v6 = {
  "kind": "Variable",
  "name": "statusIn",
  "variableName": "statusIn"
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerOrderListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BrokerOrderList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerOrderListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerOrders",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-id"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "BrokerOrderNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerOrderNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerOrderNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "orderType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "matched",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantityFilled",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantityLeft",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instrumentType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "orderRef",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "side",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastSyncTime",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "twsPermId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "twsOrderId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "parentOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerOrderNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "orderSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OrderNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "OrderNode",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "stockorder",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StockOrderNode",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "contract",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ContractNode",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "symbol",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      },
                                      (v7/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "fxorder",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "FxOrderNode",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "contract",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "FxContractNode",
                                        "plural": false,
                                        "selections": [
                                          (v7/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "localSymbol",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      },
                                      (v7/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerOrderListQuery",
    "id": null,
    "text": "query BrokerOrderListQuery(\n  $first: Int\n  $offset: Int\n  $statusIn: [String]\n  $instrumentType: String\n  $matched: Boolean\n  $orderRef: String\n) {\n  viewer {\n    ...BrokerOrderList_viewer_OVFmX\n    id\n  }\n}\n\nfragment BrokerOrderList_viewer_OVFmX on Query {\n  brokerOrders(first: $first, offset: $offset, statusIn: $statusIn, instrumentType: $instrumentType, matched: $matched, orderBy: \"-id\", orderRef: $orderRef) {\n    edges {\n      node {\n        id\n        createdAt\n        orderType\n        matched\n        quantity\n        quantityFilled\n        quantityLeft\n        status\n        instrumentType\n        orderRef\n        side\n        lastSyncTime\n        twsPermId\n        twsOrderId\n        parentOrder {\n          id\n        }\n        orderSet {\n          edges {\n            node {\n              id\n              stockorder {\n                contract {\n                  id\n                  symbol\n                }\n                id\n              }\n              fxorder {\n                contract {\n                  id\n                  localSymbol\n                }\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72533106dc5271186b81e32fd8a1c01a';
module.exports = node;
