/**
 * @flow
 * @relayHash 4e6f4c215ec56612b654277cfdfdc2c5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type BlockCashTransferRequestMutationVariables = {|
  cashTransferRequestId: string,
  block?: ?boolean,
|};
export type BlockCashTransferRequestMutationResponse = {|
  +blockCashTransferRequest: ?{|
    +cashTransferRequest: ?{|
      +id: string,
      +blocked: boolean,
      +createdAt: any,
      +currency: Currency,
      +updatedAt: any,
      +amount: number,
    |}
  |}
|};
export type BlockCashTransferRequestMutation = {|
  variables: BlockCashTransferRequestMutationVariables,
  response: BlockCashTransferRequestMutationResponse,
|};
*/


/*
mutation BlockCashTransferRequestMutation(
  $cashTransferRequestId: ID!
  $block: Boolean
) {
  blockCashTransferRequest(cashTransferRequestId: $cashTransferRequestId, block: $block) {
    cashTransferRequest {
      id
      blocked
      createdAt
      currency
      updatedAt
      amount
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cashTransferRequestId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "block",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "blockCashTransferRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "block",
        "variableName": "block"
      },
      {
        "kind": "Variable",
        "name": "cashTransferRequestId",
        "variableName": "cashTransferRequestId"
      }
    ],
    "concreteType": "BlockCashTransferRequest",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "blocked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BlockCashTransferRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BlockCashTransferRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BlockCashTransferRequestMutation",
    "id": null,
    "text": "mutation BlockCashTransferRequestMutation(\n  $cashTransferRequestId: ID!\n  $block: Boolean\n) {\n  blockCashTransferRequest(cashTransferRequestId: $cashTransferRequestId, block: $block) {\n    cashTransferRequest {\n      id\n      blocked\n      createdAt\n      currency\n      updatedAt\n      amount\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a2d9b345f087ca4108dbac78cc38fa5';
module.exports = node;
