

import { graphql } from 'react-relay';

const vueTrivialUpdate = graphql`
  mutation VueTrivialUpdateMutation (
    $input: VueTrivialMutationInput!
  ) {
    vueTrivial(input: $input) {
      errors {
        field
        messages
      }
      
      obj {
        ...VueDetails_min
      }
    }
  }
`;


export const createNewVue = graphql`
  mutation VueCreateNewMutation(
    $name: String!
    $tenantId: ID!
  ) {
    createNewVue(
      name: $name,
      tenantId: $tenantId
    ) {
      errors {
        field
        messages
      }
      vue {
        name
        id
      }
    }
  }
`;

export default vueTrivialUpdate;
