/**
 * @flow
 * @relayHash cc1ceaa913032c7bde0c1a2f5ef63fcc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateEmailRecipientMutationVariables = {|
  clientAccountId: string,
  userModelId: string,
  receivesEmail: boolean,
|};
export type UpdateEmailRecipientMutationResponse = {|
  +updateEmailRecipient: ?{|
    +activeEmailRecipients: ?$ReadOnlyArray<?{|
      +id: string
    |}>
  |}
|};
export type UpdateEmailRecipientMutation = {|
  variables: UpdateEmailRecipientMutationVariables,
  response: UpdateEmailRecipientMutationResponse,
|};
*/


/*
mutation UpdateEmailRecipientMutation(
  $clientAccountId: ID!
  $userModelId: ID!
  $receivesEmail: Boolean!
) {
  updateEmailRecipient(clientAccountId: $clientAccountId, userModelId: $userModelId, receivesEmail: $receivesEmail) {
    activeEmailRecipients {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userModelId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "receivesEmail",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateEmailRecipient",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "receivesEmail",
        "variableName": "receivesEmail"
      },
      {
        "kind": "Variable",
        "name": "userModelId",
        "variableName": "userModelId"
      }
    ],
    "concreteType": "UpdateEmailRecipient",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "activeEmailRecipients",
        "storageKey": null,
        "args": null,
        "concreteType": "UserModelNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateEmailRecipientMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateEmailRecipientMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateEmailRecipientMutation",
    "id": null,
    "text": "mutation UpdateEmailRecipientMutation(\n  $clientAccountId: ID!\n  $userModelId: ID!\n  $receivesEmail: Boolean!\n) {\n  updateEmailRecipient(clientAccountId: $clientAccountId, userModelId: $userModelId, receivesEmail: $receivesEmail) {\n    activeEmailRecipients {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd8e5969021260e9f30e55ce032d92670';
module.exports = node;
