import { fromBase64, toBase64 } from '../common/base64';

export function fromGlobalId(globalId) {
  return fromBase64(globalId).split(':');
}

export function toGlobalId(nodeType, dbId) {
  return toBase64(`${nodeType}:${dbId}`);
}

export function toLocal(globalId) {
  return fromGlobalId(globalId)[1];
}
