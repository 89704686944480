import { graphql } from 'react-relay';

const removeVueContractMutation = graphql`
  mutation RemoveVueContractMutation (
    $vueId: ID!
    $contractId: ID!
  ) {
    removeVueContract(
      vueId: $vueId,
      contractId: $contractId
    ) {
      vue {
        id
        vuecontractSet {
          edges {
           node {
             id
             contract {
               id
             }
           } 
          }
        }
      }
    }
  }
`;

export default removeVueContractMutation;
