/**
 * @flow
 * @relayHash 997d6f09cd919fd5b883448300bc0343
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
export type CashWithdrawalRequestInput = {|
  accountId: string,
  bankAccountId: string,
  amount: any,
  currency?: ?Currency,
|};
export type CreateWithdrawalRequestsMutationVariables = {|
  entries: $ReadOnlyArray<?CashWithdrawalRequestInput>,
  batchName?: ?string,
  custodian: Custodian,
|};
export type CreateWithdrawalRequestsMutationResponse = {|
  +createWithdrawalRequests: ?{|
    +cashTransferRequests: ?$ReadOnlyArray<?{|
      +id: string,
      +amount: number,
      +currency: Currency,
      +status: ?CashTransferStatus,
      +blocked: boolean,
      +account: {|
        +id: string
      |},
    |}>
  |}
|};
export type CreateWithdrawalRequestsMutation = {|
  variables: CreateWithdrawalRequestsMutationVariables,
  response: CreateWithdrawalRequestsMutationResponse,
|};
*/


/*
mutation CreateWithdrawalRequestsMutation(
  $entries: [CashWithdrawalRequestInput]!
  $batchName: String
  $custodian: Custodian!
) {
  createWithdrawalRequests(entries: $entries, batchName: $batchName, custodian: $custodian) {
    cashTransferRequests {
      id
      amount
      currency
      status
      blocked
      account {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "entries",
    "type": "[CashWithdrawalRequestInput]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "batchName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "custodian",
    "type": "Custodian!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createWithdrawalRequests",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "batchName",
        "variableName": "batchName"
      },
      {
        "kind": "Variable",
        "name": "custodian",
        "variableName": "custodian"
      },
      {
        "kind": "Variable",
        "name": "entries",
        "variableName": "entries"
      }
    ],
    "concreteType": "BulkCreateWithdrawalRequestAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashTransferRequests",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "blocked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateWithdrawalRequestsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateWithdrawalRequestsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateWithdrawalRequestsMutation",
    "id": null,
    "text": "mutation CreateWithdrawalRequestsMutation(\n  $entries: [CashWithdrawalRequestInput]!\n  $batchName: String\n  $custodian: Custodian!\n) {\n  createWithdrawalRequests(entries: $entries, batchName: $batchName, custodian: $custodian) {\n    cashTransferRequests {\n      id\n      amount\n      currency\n      status\n      blocked\n      account {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '543f9ff03541a6898b0002e40b75cdd0';
module.exports = node;
