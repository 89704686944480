import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { createRefetchContainer, graphql } from 'react-relay';
import { Grid } from '@material-ui/core';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import BaseGridContainer from '../common/BaseGridContainer';
import userSubscriptionColumns from './helper';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { useCommonStyles } from '../common/Styles';
import { strToBool } from '../common/CustomEnumDefs';


function UserSubscriptionList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>

        <InnerTabFilterSelectField
          enumType="SubscriptionCode"
          label="Code"
          helperText="CBOE/Ocenia"
          value={filter.params.subscriptionCode}
          onChange={e => filter.updateVar('subscriptionCode', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="ActiveStatus"
          label="Is Active"
          helperText="Active/Inactive status"
          value={filter.params.active}
          onChange={e => filter.updateVar('active', strToBool(e.target.value))}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={userSubscriptionColumns(true)}
            getDataNode={() => (viewer ? viewer.userSubscriptions : null)}
          />
        </Grid>
      </BaseGridContainer>

    </div>
  );
}

const query = graphql`
  query UserSubscriptionListQuery (
    $first: Int
    $offset: Int
    $subscriptionCode: String
    $active: Boolean
  ) {
    viewer {
      ...UserSubscriptionList_viewer
      @arguments (
        first: $first
        offset: $offset
        subscriptionCode: $subscriptionCode
        active: $active
      )
    }
  }
`;

const container = createRefetchContainer(
  UserSubscriptionList,
  {
    viewer: graphql`
      fragment UserSubscriptionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        subscriptionCode: {type: String, defaultValue: null }
        active: {type: Boolean, defaultValue: null }
      ) {

        userSubscriptions(
          first: $first
          offset: $offset
          subscriptionCode: $subscriptionCode
          active: $active
        ) {
          edges {
            node {
              ...UserSubscriptionsDetails_all
              @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

const queryRenderer = createQueryRendererProgressV2(
  query,
  container,
  {},
  {},
  {
    debounceFields: ['search'],
  },
);

export default withRouter(queryRenderer);
