/**
 * @flow
 * @relayHash 6fdf7c77cb2ccf78059f3da52d000660
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
export type CreateCashTransferRequestContraBatchMutationVariables = {|
  batchId: string,
  name?: ?string,
  custodian?: ?Custodian,
|};
export type CreateCashTransferRequestContraBatchMutationResponse = {|
  +createCashTransferRequestContraBatch: ?{|
    +batch: ?{|
      +contraBatch: ?{|
        +id: string,
        +name: string,
        +cashtransferrequestSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
      |},
      +id: string,
      +name: string,
      +cashtransferrequestSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateCashTransferRequestContraBatchMutation = {|
  variables: CreateCashTransferRequestContraBatchMutationVariables,
  response: CreateCashTransferRequestContraBatchMutationResponse,
|};
*/


/*
mutation CreateCashTransferRequestContraBatchMutation(
  $batchId: ID!
  $name: String
  $custodian: Custodian
) {
  createCashTransferRequestContraBatch(batchId: $batchId, name: $name, custodian: $custodian) {
    batch {
      contraBatch {
        id
        name
        cashtransferrequestSet {
          edges {
            node {
              id
            }
          }
        }
      }
      id
      name
      cashtransferrequestSet {
        edges {
          node {
            id
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "batchId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "custodian",
    "type": "Custodian",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cashtransferrequestSet",
  "storageKey": null,
  "args": null,
  "concreteType": "CashTransferRequestNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CashTransferRequestNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CashTransferRequestNode",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ]
        }
      ]
    }
  ]
},
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createCashTransferRequestContraBatch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "batchId",
        "variableName": "batchId"
      },
      {
        "kind": "Variable",
        "name": "custodian",
        "variableName": "custodian"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "CreateCashTransferRequestContraBatch",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "batch",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestBatchNode",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contraBatch",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestBatchNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCashTransferRequestContraBatchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCashTransferRequestContraBatchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v4/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateCashTransferRequestContraBatchMutation",
    "id": null,
    "text": "mutation CreateCashTransferRequestContraBatchMutation(\n  $batchId: ID!\n  $name: String\n  $custodian: Custodian\n) {\n  createCashTransferRequestContraBatch(batchId: $batchId, name: $name, custodian: $custodian) {\n    batch {\n      contraBatch {\n        id\n        name\n        cashtransferrequestSet {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n      id\n      name\n      cashtransferrequestSet {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c937a9e18cc7b6394d3504c8c19fb79';
module.exports = node;
