/**
 * @flow
 * @relayHash caffeaadc87a3b2f0e8e6c487b0bfca2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TransactionTaxList_viewer$ref = any;
export type TransactionTaxListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  reportDate?: ?any,
  symbol?: ?string,
  currency?: ?string,
  ibConId?: ?number,
  processedAt_Isnull?: ?boolean,
  ignored?: ?boolean,
|};
export type TransactionTaxListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: TransactionTaxList_viewer$ref
  |}
|};
export type TransactionTaxListQuery = {|
  variables: TransactionTaxListQueryVariables,
  response: TransactionTaxListQueryResponse,
|};
*/


/*
query TransactionTaxListQuery(
  $first: Int
  $offset: Int
  $search: String
  $reportDate: Date
  $symbol: String
  $currency: String
  $ibConId: Int
  $processedAt_Isnull: Boolean
  $ignored: Boolean
) {
  viewer {
    ...TransactionTaxList_viewer_4akiPa
    id
  }
}

fragment TransactionTaxList_viewer_4akiPa on Query {
  ibTransactionTaxes(first: $first, offset: $offset, search: $search, reportDate: $reportDate, symbol: $symbol, currency: $currency, ibConId: $ibConId, processedAt_Isnull: $processedAt_Isnull, ignored: $ignored) {
    edges {
      node {
        id
        processedAt
        processedBy {
          username
          id
        }
        notes {
          id
        }
        ignored
        ibConId
        currency
        taxAmount
        description
        tradeId
        isin
        symbol
        reportDate
        taxDescription
        brokeractivityPtr {
          brokeractivitytransactionSet {
            edges {
              node {
                id
                account {
                  name
                  id
                }
                brokeractivitytransactioncontractdeltaSet {
                  edges {
                    node {
                      portfolio {
                        id
                      }
                      contract {
                        id
                        symbol
                      }
                      quantity
                      id
                    }
                  }
                }
                brokeractivitytransactioncashdeltaSet {
                  edges {
                    node {
                      currency
                      quantity
                      id
                    }
                  }
                }
              }
            }
          }
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reportDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "symbol",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ibConId",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "processedAt_Isnull",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignored",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "currency",
    "variableName": "currency"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ibConId",
    "variableName": "ibConId"
  },
  {
    "kind": "Variable",
    "name": "ignored",
    "variableName": "ignored"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "processedAt_Isnull",
    "variableName": "processedAt_Isnull"
  },
  {
    "kind": "Variable",
    "name": "reportDate",
    "variableName": "reportDate"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "symbol"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TransactionTaxListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TransactionTaxList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TransactionTaxListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibTransactionTaxes",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "IBTransactionTaxNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBTransactionTaxNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBTransactionTaxNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "processedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "processedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "username",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "notes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NotesNode",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ignored",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ibConId",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "taxAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tradeId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isin",
                        "args": null,
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "taxDescription",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokeractivityPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "brokeractivitytransactionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "account",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AccountNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncontractdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "portfolio",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "PortfolioNode",
                                                    "plural": false,
                                                    "selections": (v3/*: any*/)
                                                  },
                                                  {
                                                    "kind": "LinkedField",
                                                    "alias": null,
                                                    "name": "contract",
                                                    "storageKey": null,
                                                    "args": null,
                                                    "concreteType": "ContractNode",
                                                    "plural": false,
                                                    "selections": [
                                                      (v2/*: any*/),
                                                      (v5/*: any*/)
                                                    ]
                                                  },
                                                  (v6/*: any*/),
                                                  (v2/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokeractivitytransactioncashdeltaSet",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "node",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                                "plural": false,
                                                "selections": [
                                                  (v4/*: any*/),
                                                  (v6/*: any*/),
                                                  (v2/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TransactionTaxListQuery",
    "id": null,
    "text": "query TransactionTaxListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $reportDate: Date\n  $symbol: String\n  $currency: String\n  $ibConId: Int\n  $processedAt_Isnull: Boolean\n  $ignored: Boolean\n) {\n  viewer {\n    ...TransactionTaxList_viewer_4akiPa\n    id\n  }\n}\n\nfragment TransactionTaxList_viewer_4akiPa on Query {\n  ibTransactionTaxes(first: $first, offset: $offset, search: $search, reportDate: $reportDate, symbol: $symbol, currency: $currency, ibConId: $ibConId, processedAt_Isnull: $processedAt_Isnull, ignored: $ignored) {\n    edges {\n      node {\n        id\n        processedAt\n        processedBy {\n          username\n          id\n        }\n        notes {\n          id\n        }\n        ignored\n        ibConId\n        currency\n        taxAmount\n        description\n        tradeId\n        isin\n        symbol\n        reportDate\n        taxDescription\n        brokeractivityPtr {\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n                account {\n                  name\n                  id\n                }\n                brokeractivitytransactioncontractdeltaSet {\n                  edges {\n                    node {\n                      portfolio {\n                        id\n                      }\n                      contract {\n                        id\n                        symbol\n                      }\n                      quantity\n                      id\n                    }\n                  }\n                }\n                brokeractivitytransactioncashdeltaSet {\n                  edges {\n                    node {\n                      currency\n                      quantity\n                      id\n                    }\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '169360031a7fcd371479585bf81a2e57';
module.exports = node;
