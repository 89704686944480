import withDebounce from './common/debounce';
import { MVA_AUTH_TOKEN_TAG } from './common/constants';
import Console from './common/log';

const tokenRefreshQuery = require('./mutations/__generated__/TokenRefreshMutation.graphql');

const TOKEN_REFRESH_SECONDS = 120;

const refreshToken = withDebounce(
  () => {
    const jwtToken = localStorage.getItem(MVA_AUTH_TOKEN_TAG);
    if (jwtToken) {
      Console.log('Refreshing token');
      const queryPayload = JSON.stringify({
        query: tokenRefreshQuery.params.text,
        variables: {
          input: {
            token: jwtToken,
          },
        },
      });

      const callParams = {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: queryPayload,
      };

      const apiUrl = `${process.env.REACT_APP_API}/admin-graphql/`;
      fetch(apiUrl, callParams).then(
        response => response.json().then((jsonPayload) => {
          if ('errors' in jsonPayload && jsonPayload.errors.length > 0) {
            if (jsonPayload.errors[0].message === 'Signature has expired') {
              localStorage.removeItem(MVA_AUTH_TOKEN_TAG);
              window.location.href = '/login';
              return jsonPayload;
            }
            return jsonPayload;
          }
          const { data: responseRoot } = jsonPayload;
          if (responseRoot) {
            localStorage.setItem(MVA_AUTH_TOKEN_TAG, responseRoot.refreshToken.token);
            Console.log('Token updated');
          }
          return jsonPayload;
        }),
        (error) => {
          Console.log('Failed to fetch, while trying to refresh token');
        },
      );
    }
  },
  TOKEN_REFRESH_SECONDS * 1000,
);

export default refreshToken;
