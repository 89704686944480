import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useCommonStyles } from '../Styles';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function DrawerGridItemSwitch(props) {
  const {
    label,
    onChange,
    checked,
    ...otherProps
  } = props;
  const classes = useCommonStyles();
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <FormControlLabel
        className={classes.fromControlDrawer}
        control={(
          <Switch
            checked={checked}
            onChange={onChange}
            value="simpleTranche"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )}
        label={label}
      />
    </Grid>
  );
}

DrawerGridItemSwitch.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

DrawerGridItemSwitch.defaultProps = {
  disabled: false,
};

export default DrawerGridItemSwitch;
