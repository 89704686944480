import React from 'react';

import { withTabItems } from '../common/RoutableTabContainer';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import TenantAccountsTab from './TenantAccountsTab';
import { toGlobalId } from '../../helpers/ID';
import TenantAccountQuery from './TenantAccountQuery';

import AccountList from '../account/AccountList';
import Pricing from './Pricing';
import AdviserList from '../adviser/AdviserList';
import TenantReportList from './TenantReportList';
import VueList from '../vue/VueList';
import VueSharePermissionList from '../vue/VueSharePermissionList';
import withSuspense from '../common/table/Suspention';

const TenantProfileTab = withSuspense(
  React.lazy(() => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./au/TenantProfileTab')
    : import('./uk/TenantProfileTab'))),
);

const tenantCopy = props => ({
  tenantId: toGlobalId('TenantNode', props.match.params.tenantId),
});

let TenantNotificationTab = {};
let TenantRestrictionTab = {};
if (process.env.DEPLOYMENT_CODE === 'UK') {
  const TenantNotificationSettings = withSuspense(
    React.lazy(() => import('./uk/TenantNotificationSettings')),
  );
  const TenantContractRestrictions = withSuspense(
    React.lazy(() => import('./uk/TenantContractRestrictions')),
  );

  TenantNotificationTab = {
    label: 'Notifications',
    url: 'notifications',
    comp: TenantNotificationSettings,
    propCopyFn: tenantCopy,
  };

  TenantRestrictionTab = {
    label: 'Restrictions',
    url: 'restrictions',
    comp: TenantContractRestrictions,
    propCopyFn: tenantCopy,
  };
}

export default withTabItems([
  {
    label: 'Profile',
    url: 'profile',
    comp: TenantProfileTab,
    propCopyFn: tenantCopy,
  },
  {
    label: 'Advisers',
    url: 'advisers',
    comp: AdviserList,
    propCopyFn: tenantCopy,
  },
  {
    label: 'Tenant Accounts',
    url: 'tenant-accounts',
    comp: createQueryRendererProgress(
      TenantAccountQuery,
      TenantAccountsTab,
      viewer => ({ tenant: viewer.tenants.edges[0].node }),
      props => ({ tenantId: props.tenantId }),
    ),
    propCopyFn: tenantCopy,
  },
  {
    label: 'Clients',
    url: 'clients',
    comp: AccountList,
    propCopyFn: tenantCopy,
  },
  {
    label: 'Pricing',
    url: 'pricing',
    comp: Pricing,
    propCopyFn: tenantCopy,
  },
  {
    label: 'Reports',
    url: 'reports',
    comp: TenantReportList,
    propCopyFn: tenantCopy,
  },
  {
    label: 'Vues',
    url: 'vue',
    comp: VueList,
    propCopyFn: props => ({
      tenantId: toGlobalId('TenantNode', props.match.params.tenantId),
      relevantToTenant: toGlobalId('TenantNode', props.match.params.tenantId),
      showOwners: true,
      showAdd: true,
      vueType: 'COMPANY',
    }),
  },
  {
    label: 'Vue Share',
    url: 'vshare',
    comp: VueSharePermissionList,
    propCopyFn: tenantCopy,
  },
  TenantNotificationTab,
  TenantRestrictionTab,
]);
