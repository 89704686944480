/**
 * @flow
 * @relayHash 2173ebac5efb8cd2fa9948898232b376
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TenantReportList_viewer$ref = any;
export type TenantReportListQueryVariables = {|
  tenantId?: ?string,
  first?: ?number,
  offset?: ?number,
|};
export type TenantReportListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: TenantReportList_viewer$ref
  |}
|};
export type TenantReportListQuery = {|
  variables: TenantReportListQueryVariables,
  response: TenantReportListQueryResponse,
|};
*/


/*
query TenantReportListQuery(
  $tenantId: ID
  $first: Int
  $offset: Int
) {
  viewer {
    ...TenantReportList_viewer_3bvJKU
    id
  }
}

fragment TenantReportList_viewer_3bvJKU on Query {
  tenantReports(first: $first, offset: $offset, orderBy: "-createdAt", tenantId: $tenantId) {
    edges {
      node {
        id
        xlsxUrl
        remittanceadvice {
          startDate
          endDate
          coverageDate
          id
        }
      }
    }
    count
  }
  tenants(id: $tenantId) {
    edges {
      node {
        tenantAccounts {
          edges {
            node {
              name
              latestWithdrawalDate
              postWithdrawalCashTransferCount
              id
            }
          }
          count
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "tenantId",
  "variableName": "tenantId"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TenantReportListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "TenantReportList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TenantReportListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantReports",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v3/*: any*/)
            ],
            "concreteType": "TenantReportNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantReportNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantReportNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "xlsxUrl",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "remittanceadvice",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "RemittanceAdviceNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "startDate",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "endDate",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "coverageDate",
                            "args": null,
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenants",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "tenantId"
              }
            ],
            "concreteType": "TenantNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantAccounts",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantAccountNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantAccountNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TenantAccountNode",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "latestWithdrawalDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "postWithdrawalCashTransferCount",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          },
                          (v5/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TenantReportListQuery",
    "id": null,
    "text": "query TenantReportListQuery(\n  $tenantId: ID\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...TenantReportList_viewer_3bvJKU\n    id\n  }\n}\n\nfragment TenantReportList_viewer_3bvJKU on Query {\n  tenantReports(first: $first, offset: $offset, orderBy: \"-createdAt\", tenantId: $tenantId) {\n    edges {\n      node {\n        id\n        xlsxUrl\n        remittanceadvice {\n          startDate\n          endDate\n          coverageDate\n          id\n        }\n      }\n    }\n    count\n  }\n  tenants(id: $tenantId) {\n    edges {\n      node {\n        tenantAccounts {\n          edges {\n            node {\n              name\n              latestWithdrawalDate\n              postWithdrawalCashTransferCount\n              id\n            }\n          }\n          count\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b6ce03c59e40d57e0e3fc84d31c1673';
module.exports = node;
