/**
 * @flow
 * @relayHash f16347677a4dfbf25681c3b220f58aa2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VueExclusivePermissionCreateMutationVariables = {|
  accountId?: ?string,
  vueId?: ?string,
|};
export type VueExclusivePermissionCreateMutationResponse = {|
  +createVueExclusivePermission: ?{|
    +vueExclusivePermission: ?{|
      +account: {|
        +id: string
      |},
      +vue: {|
        +id: string
      |},
    |}
  |}
|};
export type VueExclusivePermissionCreateMutation = {|
  variables: VueExclusivePermissionCreateMutationVariables,
  response: VueExclusivePermissionCreateMutationResponse,
|};
*/


/*
mutation VueExclusivePermissionCreateMutation(
  $accountId: ID
  $vueId: ID
) {
  createVueExclusivePermission(accountId: $accountId, vueId: $vueId) {
    vueExclusivePermission {
      account {
        id
      }
      vue {
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "vueId",
    "variableName": "vueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "account",
  "storageKey": null,
  "args": null,
  "concreteType": "AccountNode",
  "plural": false,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vue",
  "storageKey": null,
  "args": null,
  "concreteType": "VueNode",
  "plural": false,
  "selections": (v3/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueExclusivePermissionCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createVueExclusivePermission",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateVueExclusivePermission",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vueExclusivePermission",
            "storageKey": null,
            "args": null,
            "concreteType": "VueExclusivePermissionNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueExclusivePermissionCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createVueExclusivePermission",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateVueExclusivePermission",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vueExclusivePermission",
            "storageKey": null,
            "args": null,
            "concreteType": "VueExclusivePermissionNode",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "VueExclusivePermissionCreateMutation",
    "id": null,
    "text": "mutation VueExclusivePermissionCreateMutation(\n  $accountId: ID\n  $vueId: ID\n) {\n  createVueExclusivePermission(accountId: $accountId, vueId: $vueId) {\n    vueExclusivePermission {\n      account {\n        id\n      }\n      vue {\n        id\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca02100a53d4d05c7f3454f8565a2513';
module.exports = node;
