import { graphql } from 'react-relay';

const createPortfolio = graphql`
  mutation PortfolioCreateMutation (
    $accountId: ID!
    $vueId: ID!
  ) {
    createPortfolio(
      accountId: $accountId
      vueId: $vueId
    ) {
      portfolio {
        id
        account {
          ...AccountStockHoldingPortfolios_portfolios
        }
      }
    }
  }
`;

export default createPortfolio;
