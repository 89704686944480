import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Typography from '@material-ui/core/Typography';
import { useCommonStyles } from '../common/Styles';
import { fromGlobalId } from '../../helpers/ID';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import OffMarketTradeForm from './OffMarketTradeForm';
import OffMarketTransferForm from './OffMarketTransferForm';
import TableButton from '../common/buttons/TableButton';
import StatelessDownloadButton from '../common/buttons/StatelessDownloadButton';
import AccountStockHoldingListClosed from './AccountStockHoldingListClosed';
import AccountStockHoldingPortfolios from './AccountStockHoldingPortfolios';
import AccountTradeSummaryExportPanel from './AccountTradeSummaryExportPanel';


const AccountStockHoldingList = function StockHoldingList(props) {
  const { viewer, accountId, relay } = props;
  const classes = useCommonStyles();
  const [edge] = viewer.accounts.edges;
  const { node: holdings_ } = edge;
  const mainVues = viewer.vues.edges;
  const { holdings, availableHoldings, portfolios } = holdings_;
  const [showOmtTransfer, setShowOmtTransfer] = React.useState(false);
  const [showOmtTrade, setShowOmtTrade] = React.useState(false);
  const [activeHolding, setActiveHolding] = React.useState(null);
  const [showClosed] = React.useState(true);

  const tmpKeys = holdings.reduce(
    (a, v) => {
      const key = `${v.portfolio.id}-${v.contract.id}`;
      return {
        [key]: {
          portfolio: v.portfolio,
          contract: v.contract,
          quantity: v.quantity,
          availableQuantity: 0,
        },
        ...a,
      };
    },
    {},
  );

  const allKeys = availableHoldings.reduce(
    (a, v) => {
      const key = `${v.portfolio.id}-${v.contract.id}`;
      if (key in a) {
        return {
          ...a,
          [key]: {
            ...a[key],
            availableQuantity: v.quantity,
          },
        };
      }
      return {
        [key]: {
          portfolio: v.portfolio,
          contract: v.contract,
          quantity: 0,
          availableQuantity: v.quantity,
        },
        ...a,
      };
    },
    tmpKeys,
  );

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        open={showOmtTrade}
        onClose={() => {
          setActiveHolding(null);
          setShowOmtTrade(false);
        }}
        anchor="right"
      >
        <OffMarketTradeForm
          key={activeHolding ? activeHolding.id : ''}
          holding={activeHolding}
          accountId={accountId}
          onSuccess={() => {
            setActiveHolding(null);
            setShowOmtTrade(false);
            relay.refetch(() => ({ accountId }));
          }}
        />
      </Drawer>

      <Drawer
        open={showOmtTransfer}
        onClose={() => setShowOmtTransfer(false)}
        anchor="right"
      >
        <OffMarketTransferForm
          key={activeHolding ? activeHolding.id : ''}
          holding={activeHolding}
          accountId={accountId}
          onSuccess={() => {
            setActiveHolding(null);
            setShowOmtTransfer(false);
            relay.refetch(() => ({ accountId }));
          }}
        />
      </Drawer>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AccountTradeSummaryExportPanel
            accountId={accountId}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <div className={classes.tableHolderRoot}>
              <div className={classes.widgetHeadingContainer}>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  gutterBottom
                >
                  Open Positions
                </Typography>
              </div>

              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Portfolio</TableCell>
                    <TableCell>Vue</TableCell>
                    <TableCell>Contract ID</TableCell>
                    <TableCell>Ticker</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Available Quantity</TableCell>
                    <TableCell align="center">Off Market</TableCell>
                    <TableCell align="center">Trade Summary</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.values(allKeys).map((holding) => {
                      let tradeSummaryUrl = `${process.env.REACT_APP_API}/api/admin/exports/trade-summary`;
                      tradeSummaryUrl += `?contractId=${fromGlobalId(holding.contract.id)[1]}`;
                      tradeSummaryUrl += `&portfolioId=${fromGlobalId(holding.portfolio.id)[1]}`;
                      tradeSummaryUrl += `&accountId=${fromGlobalId(accountId)[1]}`;
                      return (
                        <TableRow key={holding.id}>
                          <TableCell>
                            {fromGlobalId(holding.portfolio.id)[1]}
                          </TableCell>
                          <TableCell>
                            {holding.portfolio.trackingVue ? holding.portfolio.trackingVue.name : ''}
                          </TableCell>
                          <TableCell>
                            {fromGlobalId(holding.contract.id)[1]}
                          </TableCell>
                          <TableCell>
                            {holding.contract.symbol}
                          </TableCell>
                          <TableCell>
                            {holding.contract.name}
                          </TableCell>
                          <TableCell align="right">
                            {holding.quantity}
                          </TableCell>
                          <TableCell align="right">
                            {holding.availableQuantity}
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={1} justify="center">
                              <Grid item>
                                <TableButton
                                  onClick={() => {
                                    setActiveHolding(holding);
                                    setShowOmtTrade(true);
                                  }}
                                  color="primary"
                                >
                                  Trade
                                </TableButton>
                              </Grid>
                              <Grid item>
                                <TableButton
                                  onClick={() => {
                                    setActiveHolding(holding);
                                    setShowOmtTransfer(true);
                                  }}
                                  color="primary"
                                >
                                  Transfer
                                </TableButton>
                              </Grid>
                            </Grid>
                          </TableCell>

                          <TableCell>
                            <Grid container spacing={1} justify="center">
                              <Grid item>
                                <StatelessDownloadButton
                                  downloadUrl={tradeSummaryUrl}
                                >
                                  Export
                                </StatelessDownloadButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
                <TableFooter />
              </Table>
            </div>
          </Paper>
        </Grid>


        <Grid item xs={12}>
          {showClosed && (
            <AccountStockHoldingListClosed
              accountId={accountId}
              openHoldings={holdings}
            />
          )}
        </Grid>

        <Grid item xs={4}>
          <AccountStockHoldingPortfolios
            portfolios={portfolios}
            mainVues={mainVues}
            accountId={accountId}
          />
        </Grid>

      </Grid>
    </div>
  );
};

const query = graphql`
  query AccountStockHoldingListQuery(
    $accountId: ID
  ){
    viewer {
      ...AccountStockHoldingList_viewer
      @arguments(
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  AccountStockHoldingList,
  {
    viewer: graphql`
      fragment AccountStockHoldingList_viewer on Query
      @argumentDefinitions(
        accountId: {type: ID}
      )
      {
        ...AccountStockHoldingPortfolios_mainVues
        @relay(mask: false)

        accounts(
          id: $accountId
        ) {
          edges {
            node {

              ...AccountStockHoldingPortfolios_portfolios
              @relay(mask: false)

              holdings {
                id
                contract {
                  id
                  name
                  symbol
                  currency
                }
                quantity
                portfolio {
                  id
                  trackingVue {
                    name
                  }
                }
              }
              
              availableHoldings {
                id
                contract {
                  id
                  name
                  symbol
                  currency
                }
                quantity
                portfolio {
                  id
                  trackingVue {
                    name
                  }
                }
              }

            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { accountId }) => ({ accountId }),
  props => ({ accountId: props.accountId }),
);
