import moment from 'moment-timezone';
import { MOMENT_DATE_FORMAT_GRAPHQL } from './constants';


export const setGraphQLTime = (inTime, setter) => {
  let outDate = null;
  if (inTime !== null && inTime !== '') {
    outDate = moment(inTime).format(MOMENT_DATE_FORMAT_GRAPHQL);
  }
  if (setter) {
    setter(outDate);
  }

  return outDate;
};


export const maxOfTime = (a, b) => {
  if (a > b) return a;
  return b;
};
