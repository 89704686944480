import { graphql } from 'react-relay';

const publishVueDraftMutation = graphql`
  mutation PublishVueDraftMutation (
    $vueId: ID!
    $silent: Boolean
  ) {
    publishVueDraft(
      vueId: $vueId,
      silent: $silent
    ) {
      parentVue {
        ...VueDetails_vueDetails
      }
    }
  }
`;

export default publishVueDraftMutation;
