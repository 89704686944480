/**
 * @flow
 * @relayHash 3471d28fc65668e2a141133282805918
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CannedSecurityQuestionUpdateFormQueryVariables = {|
  cannedSecurityQuestionId: string
|};
export type CannedSecurityQuestionUpdateFormQueryResponse = {|
  +viewer: ?{|
    +cannedSecurityQuestions: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +question: string,
          +enabled: boolean,
        |}
      |}>
    |}
  |}
|};
export type CannedSecurityQuestionUpdateFormQuery = {|
  variables: CannedSecurityQuestionUpdateFormQueryVariables,
  response: CannedSecurityQuestionUpdateFormQueryResponse,
|};
*/


/*
query CannedSecurityQuestionUpdateFormQuery(
  $cannedSecurityQuestionId: ID!
) {
  viewer {
    cannedSecurityQuestions(id: $cannedSecurityQuestionId) {
      edges {
        node {
          id
          question
          enabled
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cannedSecurityQuestionId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cannedSecurityQuestions",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "cannedSecurityQuestionId"
    }
  ],
  "concreteType": "CannedSecurityQuestionNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CannedSecurityQuestionNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CannedSecurityQuestionNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "question",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "enabled",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CannedSecurityQuestionUpdateFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CannedSecurityQuestionUpdateFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CannedSecurityQuestionUpdateFormQuery",
    "id": null,
    "text": "query CannedSecurityQuestionUpdateFormQuery(\n  $cannedSecurityQuestionId: ID!\n) {\n  viewer {\n    cannedSecurityQuestions(id: $cannedSecurityQuestionId) {\n      edges {\n        node {\n          id\n          question\n          enabled\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0911e69706b47f579fc3242b3464ce9';
module.exports = node;
