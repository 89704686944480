/**
 * @flow
 * @relayHash 433f1d7f9883499e0152ec17f03bd572
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ContractDetails_contract$ref = any;
export type ContractEmulatePriceMutationVariables = {|
  requestId?: ?string,
  price?: ?string,
|};
export type ContractEmulatePriceMutationResponse = {|
  +trademanEmulateMarketPrice: ?{|
    +brokerOrders: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
    +contract: ?{|
      +$fragmentRefs: ContractDetails_contract$ref
    |},
  |}
|};
export type ContractEmulatePriceMutation = {|
  variables: ContractEmulatePriceMutationVariables,
  response: ContractEmulatePriceMutationResponse,
|};
*/


/*
mutation ContractEmulatePriceMutation(
  $requestId: ID
  $price: String
) {
  trademanEmulateMarketPrice(requestId: $requestId, price: $price) {
    brokerOrders {
      id
    }
    contract {
      ...ContractDetails_contract
      id
    }
  }
}

fragment ContractDetails_contract on ContractNode {
  id
  symbol
  currency
  imageUrl
  createdAt
  updatedAt
  ibStatus
  ibConId
  instrumentType
  name
  exchange {
    symbol
    id
  }
  latestPriceAud
  latestPriceLocal
  latestPriceDate
  latestPriceTime
  priceIncrements {
    increment
    lowEdge
  }
  priceCashInfo {
    timeSet
    timeOfPrice
    price
    source
    currency
  }
  contractmarketdatarequestSet {
    edges {
      node {
        id
      }
    }
  }
  fsContract {
    id
    isin
    name
    country
    industry
    sedol
    sector
    lotSize
    currency
    factsetlatestcontractfinancialdata {
      date
      closingPriceLocal
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "requestId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "price",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "price",
    "variableName": "price"
  },
  {
    "kind": "Variable",
    "name": "requestId",
    "variableName": "requestId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokerOrders",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerOrderNode",
  "plural": true,
  "selections": (v3/*: any*/)
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractEmulatePriceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trademanEmulateMarketPrice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EmulateMarketPrice",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contract",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractNode",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "ContractDetails_contract",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractEmulatePriceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trademanEmulateMarketPrice",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EmulateMarketPrice",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contract",
            "storageKey": null,
            "args": null,
            "concreteType": "ContractNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "imageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ibStatus",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ibConId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "instrumentType",
                "args": null,
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "exchange",
                "storageKey": null,
                "args": null,
                "concreteType": "ExchangeNode",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "latestPriceAud",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "latestPriceLocal",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "latestPriceDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "latestPriceTime",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "priceIncrements",
                "storageKey": null,
                "args": null,
                "concreteType": "PriceIncrement",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "increment",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lowEdge",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "priceCashInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PriceCacheInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timeSet",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "timeOfPrice",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "price",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "source",
                    "args": null,
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contractmarketdatarequestSet",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractMarketDataRequestNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractMarketDataRequestNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractMarketDataRequestNode",
                        "plural": false,
                        "selections": (v3/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "fsContract",
                "storageKey": null,
                "args": null,
                "concreteType": "FactsetContractNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isin",
                    "args": null,
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "country",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "industry",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sedol",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sector",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lotSize",
                    "args": null,
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "factsetlatestcontractfinancialdata",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "LatestContractFinancialDataNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "date",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "closingPriceLocal",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ContractEmulatePriceMutation",
    "id": null,
    "text": "mutation ContractEmulatePriceMutation(\n  $requestId: ID\n  $price: String\n) {\n  trademanEmulateMarketPrice(requestId: $requestId, price: $price) {\n    brokerOrders {\n      id\n    }\n    contract {\n      ...ContractDetails_contract\n      id\n    }\n  }\n}\n\nfragment ContractDetails_contract on ContractNode {\n  id\n  symbol\n  currency\n  imageUrl\n  createdAt\n  updatedAt\n  ibStatus\n  ibConId\n  instrumentType\n  name\n  exchange {\n    symbol\n    id\n  }\n  latestPriceAud\n  latestPriceLocal\n  latestPriceDate\n  latestPriceTime\n  priceIncrements {\n    increment\n    lowEdge\n  }\n  priceCashInfo {\n    timeSet\n    timeOfPrice\n    price\n    source\n    currency\n  }\n  contractmarketdatarequestSet {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n  fsContract {\n    id\n    isin\n    name\n    country\n    industry\n    sedol\n    sector\n    lotSize\n    currency\n    factsetlatestcontractfinancialdata {\n      date\n      closingPriceLocal\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0be30cc36f6e79d90e5348a2bc572f6';
module.exports = node;
