import React from 'react';
import { useCommonStyles } from '../../common/Styles';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemDateField from '../../common/drawer/DrawerGridItemDateField';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { doCommitPromise } from '../../../common/commit';
import { createStockDividend } from '../../../mutations/BrokerActivity';
import { AddButton } from '../../common/buttons/BaseButton';


export default function StockDividendAddForm(props) {
  const { onClose } = props;
  const classes = useCommonStyles();
  const [payDate, setPayDate] = React.useState();
  const [exDate, setExDate] = React.useState();
  const [members] = React.useState({
    corporateActionId: null,
    reInvestmentPrice: null,
  });
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Stock Dividend"
        />
        <DrawerGridItemDateField
          xs={6}
          label="Pay Date"
          value={payDate}
          onChange={newPayDate => setPayDate(newPayDate)}
          autoOk
          disableFuture
        />
        <DrawerGridItemDateField
          xs={6}
          label="Ex Date"
          value={exDate}
          onChange={newExDate => setExDate(newExDate)}
          autoOk
          disableFuture
        />
        <DrawerGridItemTextField
          xs={6}
          label="Re-Investment Price (Local)"
          defaultValue={members.reInvestmentPrice}
          onChange={(e) => {
            members.reInvestmentPrice = e.target.value;
          }}
        />

        <DrawerGridItemTextField
          xs={6}
          label="Corporate Action ID"
          onChange={(e) => {
            members.corporateActionId = e.target.value;
          }}
        />
        <DrawerGridItemBottomButtonContainer>
          <AddButton
            onClickPromise={() => doCommitPromise(
              createStockDividend,
              {
                corporateActionId: members.corporateActionId,
                exDate,
                payDate,
                reinvestmentPrice: Number(members.reInvestmentPrice),
              },
              () => (onClose && onClose()),
              () => null,
            )}
            buttonText="Add"
          />
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}
