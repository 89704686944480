import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  tableStatusWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconFontSize: {
    fontSize: '1.12rem',
  },
  statusStyle: {
    padding: '0px 8px',
    margin: '0px',
    lineHeight: '1.75',
    // padding: '2px 10px 2px 10px',
    fontSize: '0.65rem',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    fontWeight: 500,
    borderRadius: '4px',
    color: 'white',
  },
  statusPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  statusSecondary: {
    backgroundColor: theme.palette.secondary.light,
  },
  statusDefault: {
    backgroundColor: theme.palette.grey.A200,
    color: 'black',
  },
}));

const TableStatus = (props) => {
  const {
    children,
    info,
    error,
    warn,
    success,
    secondary,
    primary,
    customStyle,
    color,
  } = props;

  const customStyleEx = { color: 'white', ...customStyle };

  const classes = useStyles();
  let secondClass = 'statusDefault';

  info && (secondClass = 'statusPrimary');
  error && (secondClass = 'statusSecondary');
  warn && (secondClass = 'statusPrimary');
  primary && (secondClass = 'statusPrimary');
  secondary && (secondClass = 'statusSecondary');
  success && (secondClass = 'statusPrimary');
  if (color && color === 'primary') secondClass = 'statusPrimary';
  if (color && color === 'secondary') secondClass = 'statusSecondary';

  return (
    <div className={classes.tableStatusWrapper}>
      <div className={`${classes.statusStyle} ${classes[secondClass]}`} style={customStyleEx}>
        {children}
      </div>
    </div>
  );
};

TableStatus.propTypes = {
  info: PropTypes.bool,
  error: PropTypes.bool,
  warn: PropTypes.bool,
  success: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  customStyle: PropTypes.object,
};

TableStatus.defaultProps = {
  info: false,
  error: false,
  warn: false,
  success: false,
  primary: false,
  secondary: false,
  customStyle: {},
};


export default TableStatus;
