/**
 * @flow
 * @relayHash 2b801ac1369f05447d775570343e3477
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type SetResponsibleContractMutationVariables = {|
  brokerActivityId?: ?string,
  contractId?: ?string,
|};
export type SetResponsibleContractMutationResponse = {|
  +setResponsibleStock: ?{|
    +brokerActivity: ?{|
      +id: string,
      +responsiblecontract: ?{|
        +brokerActivity: {|
          +id: string
        |},
        +id: string,
        +contract: {|
          +id: string,
          +name: ?string,
          +ibConId: number,
          +symbol: string,
          +currency: Currency,
        |},
      |},
    |}
  |}
|};
export type SetResponsibleContractMutation = {|
  variables: SetResponsibleContractMutationVariables,
  response: SetResponsibleContractMutationResponse,
|};
*/


/*
mutation SetResponsibleContractMutation(
  $brokerActivityId: ID
  $contractId: ID
) {
  setResponsibleStock(brokerActivityId: $brokerActivityId, contractId: $contractId) {
    brokerActivity {
      id
      responsiblecontract {
        brokerActivity {
          id
        }
        id
        contract {
          id
          name
          ibConId
          symbol
          currency
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerActivityId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setResponsibleStock",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerActivityId",
        "variableName": "brokerActivityId"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "SetResponsibleContract",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerActivity",
        "storageKey": null,
        "args": null,
        "concreteType": "BrokerActivityNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "responsiblecontract",
            "storageKey": null,
            "args": null,
            "concreteType": "ResponsibleContractNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokerActivity",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/)
                ]
              },
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contract",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "ibConId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "symbol",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "currency",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "SetResponsibleContractMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "SetResponsibleContractMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "SetResponsibleContractMutation",
    "id": null,
    "text": "mutation SetResponsibleContractMutation(\n  $brokerActivityId: ID\n  $contractId: ID\n) {\n  setResponsibleStock(brokerActivityId: $brokerActivityId, contractId: $contractId) {\n    brokerActivity {\n      id\n      responsiblecontract {\n        brokerActivity {\n          id\n        }\n        id\n        contract {\n          id\n          name\n          ibConId\n          symbol\n          currency\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ddddd6e25a6622dc903182c90d2152f';
module.exports = node;
