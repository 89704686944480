import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemLoaderField from '../common/drawer/DrawerGridItemLoaderField';
import VueLoader from '../common/fieldLoaders/VueLoader';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { UpdateButton } from '../common/buttons/BaseButton';
import doCommit from '../../common/commit';
import { createVueExclusivePermission } from '../../mutations/VueExclusivePermission';

function AccountVueExclusivePermissionForm(props) {
  const classes = useCommonStyles();
  const { accountId, onSuccess } = props;
  const [vue, setVue] = React.useState(null);
  const addPermission = () => {
    doCommit(
      createVueExclusivePermission,
      {
        accountId,
        vueId: vue.id,
      },
      () => (onSuccess && onSuccess()),
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Add Vue Exclusive Permission
      </Typography>
      <DrawerGridItemLoaderField
        sm={12}
        label="Vue"
        loader={VueLoader}
        onChange={v => setVue(v)}
      />
      <Grid item xs={12}>
        <BottomGridContainer>
          <UpdateButton
            onClick={addPermission}
            buttonText="Update"
          />
        </BottomGridContainer>
      </Grid>
    </div>
  );
}

export default AccountVueExclusivePermissionForm;
