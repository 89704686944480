import React from 'react';
import PropTypes from 'prop-types';
import PopupEntitySelectorField from './PopupEntitySelectorField';
import ContractSelectionDlg from './dialogs/ContractSelectionDlg';

function PopupContractSelectorField(props) {
  const {
    children,
    caption,
    ...otherProps
  } = props;
  return (
    <PopupEntitySelectorField
      {...otherProps}
      caption={caption}
      searchDlgComp={ContractSelectionDlg}
      valueDescriber={contract => (contract ? `${contract.symbol}:${contract.currency}` : '')}
      helperTextDescriber={(contract) => {
        if (!contract) return 'Select Contract...';
        let description = contract.name;
        if (description && contract.ibConId) description += `, conId: ${contract.ibConId}`;
        else if (contract.ibConId) description = `conId: ${contract.ibConId}`;

        return description;
      }}
    />
  );
}

PopupContractSelectorField.propTypes = {
  caption: PropTypes.string,
};

PopupContractSelectorField.defaultProps = {
  caption: 'Contract',
};

export default PopupContractSelectorField;
