import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';

import '../styles/Login.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { commitMutation } from 'react-relay';
import tokenAuthMutation from '../mutations/TokenAuthMutation';
import { MVA_AUTH_TOKEN_TAG, MVA_LOGGED_IN_USER } from '../common/constants';
import environment from '../Environment';
import notiStack from '../common/notiStack';
import { getUserDisplayName } from '../common/helpers';
import Console from '../common/log';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

const connectProgress = (
  <React.Fragment>
    <div style={{ marginTop: '40px' }}>
      <Typography variant="caption" align="center">
        {`Connecting to ${process.env.REACT_APP_API}`}
      </Typography>
    </div>
    <div style={{ marginTop: '10px' }}>
      <LinearProgress />
    </div>
  </React.Fragment>
);

function Login(props) {
  const { classes, location } = props;
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isConnecting, setIsConnecting] = React.useState(false);


  const submitCredentials = (e) => {
    e.preventDefault();
    setIsConnecting(true);
    const variables = { username, password };
    commitMutation(environment, {
      mutation: tokenAuthMutation,
      variables,
      onCompleted: (response, errors) => {
        if (response && response.tokenAuth != null) {
          const loggedInUser = getUserDisplayName(response.tokenAuth.user);
          notiStack.success(`${loggedInUser} logged in successfully.`);
          Console.info(`${username} Logged in successfully. setting token`);
          localStorage.setItem(MVA_AUTH_TOKEN_TAG, response.tokenAuth.token);
          localStorage.setItem(MVA_LOGGED_IN_USER, JSON.stringify(response.tokenAuth.user));
          axios.defaults.headers.common.Authorization = `JWT ${response.tokenAuth.token}`;
          setLoggedIn(true);
        }
        setIsConnecting(false);
      },
      onError: (error) => {
        Console.error(error);
        setIsConnecting(false);
      },
    });
  };

  if (loggedIn) {
    const { state } = location;
    if (state) {
      const { pathname } = state;
      if (pathname) {
        return <Redirect to={pathname} />;
      }
    }
    return <Redirect to="/" />;
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Halo Trading Operations
        </Typography>
        <form className={classes.form} onSubmit={submitCredentials}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">Username</InputLabel>
            <Input
              id="username"
              name="username"
              autoComplete="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              autoFocus
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
            />
          </FormControl>
          {/* <FormControlLabel */}
          {/*    control={<Checkbox value="remember" color="primary"/>} */}
          {/*    label="Remember me" */}
          {/* /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
                  Log in
          </Button>
        </form>
      </Paper>

      { isConnecting && connectProgress }
    </main>
  );
}

export default withStyles(styles)(withRouter(Login));
