import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

function TableTwoStateIconButton(props) {
  const {
    state,
    states,
    onClick,
    tooltip,
  } = props;

  const [stateColor, StateIcon] = states[state];

  return (
    <div>
      <Tooltip
        title={tooltip}
        enterDelay={1000}
      >
        <IconButton
          color={stateColor}
          onClick={onClick}
        >
          <StateIcon fontSize="small" style={{ position: 'absolute' }} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default TableTwoStateIconButton;
