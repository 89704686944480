import React from 'react';
import PropTypes from 'prop-types';
import PopupEntitySelectorField from './PopupEntitySelectorField';
import { toLocal } from '../../helpers/ID';
import TenantSelectionDlg from './dialogs/TenantSelectionDlg';

function PopupTenantSelectorField(props) {
  const {
    children,
    caption,
    ...otherProps
  } = props;

  const tenantDescriber = (tenant) => {
    if (!tenant) return '';
    return tenant.name;
  };

  return (
    <PopupEntitySelectorField
      {...otherProps}
      caption={caption}
      searchDlgComp={TenantSelectionDlg}
      helperTextDescriber={tenant => (tenant ? `${toLocal(tenant.id)} : ${tenant.name}` : 'Select Tenant...')}
      valueDescriber={adviser => tenantDescriber(adviser)}
    />
  );
}

PopupTenantSelectorField.propTypes = {
  caption: PropTypes.string,
};

PopupTenantSelectorField.defaultProps = {
  caption: 'Tenant',
};

export default PopupTenantSelectorField;
