import { graphql } from 'react-relay';

export const createBrokerActivityGroup = graphql`
  mutation BrokerActivityGroupCreateMutation (
    $description: String!
    $brokerActivityIds: [ID]!
    $dividendDef: DividendDefInput
  ) {
    createBrokerActivityGroup(
      description: $description
      brokerActivityIds: $brokerActivityIds
      dividendDef: $dividendDef
    ) {
      brokerActivityGroup {
        id
        description
        brokeractivitySet {
          edges {
            node {
              id
              group {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const assignToBrokerActivityGroup = graphql`
  mutation BrokerActivityGroupAssignToMutation (
    $brokerActivityGroupId: ID!
    $brokerActivityId: ID!
  ) {
    assignToBrokerActivityGroup(
      brokerActivityGroupId: $brokerActivityGroupId,
      brokerActivityId: $brokerActivityId
    ) {
      brokerActivity {
        id
        group {
          id
          description
          brokeractivitySet {
            edges {
              node {
                ibcorporateaction {
                  ...IBCorporateAction_all @relay(mask: false)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const removeFromBrokerActivityGroup = graphql`
  mutation BrokerActivityGroupRemoveFromMutation (
    $brokerActivityId: ID!
  ) {
    removeFromBrokerActivityGroup(
      brokerActivityId: $brokerActivityId
    ) {
      brokerActivity {
        id
        group {
          id
          description
        }
      }
    }
  }
`;


export const updateBrokerActivityGroup = graphql`
  mutation BrokerActivityGroupUpdateMutation (
    $brokerActivityGroupId: ID!
    $description: String!
    $dividendDef: DividendDefInput
  ) {
    updateBrokerActivityGroup(
      brokerActivityGroupId: $brokerActivityGroupId
      description: $description
      dividendDef: $dividendDef
    ) {
      brokerActivityGroup {
        id
        description
        dividenddef {
          id
          dividendAmount
        }
        brokeractivitySet {
          edges {
            node {
              id
              group {
                id
              }
            }
          }
        }
      }
    }
  }
`;
