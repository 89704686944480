/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CacheSource = "DP" | "DPE" | "FF" | "OP" | "%future added value";
export type ContractIbStatus = "CO" | "EX" | "TR" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type InstrumentType = "CASH" | "STK" | "WAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ContractDetails_contract$ref: FragmentReference;
declare export opaque type ContractDetails_contract$fragmentType: ContractDetails_contract$ref;
export type ContractDetails_contract = {|
  +id: string,
  +symbol: string,
  +currency: Currency,
  +imageUrl: ?string,
  +createdAt: any,
  +updatedAt: any,
  +ibStatus: ContractIbStatus,
  +ibConId: number,
  +instrumentType: InstrumentType,
  +name: ?string,
  +exchange: {|
    +symbol: string
  |},
  +latestPriceAud: ?number,
  +latestPriceLocal: ?number,
  +latestPriceDate: ?any,
  +latestPriceTime: ?any,
  +priceIncrements: ?$ReadOnlyArray<?{|
    +increment: ?any,
    +lowEdge: ?any,
  |}>,
  +priceCashInfo: ?{|
    +timeSet: ?any,
    +timeOfPrice: ?any,
    +price: ?number,
    +source: ?CacheSource,
    +currency: ?string,
  |},
  +contractmarketdatarequestSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string
      |}
    |}>
  |},
  +fsContract: ?{|
    +id: string,
    +isin: ?string,
    +name: ?string,
    +country: ?string,
    +industry: ?string,
    +sedol: string,
    +sector: ?string,
    +lotSize: number,
    +currency: Currency,
    +factsetlatestcontractfinancialdata: ?{|
      +date: any,
      +closingPriceLocal: ?number,
    |},
  |},
  +$refType: ContractDetails_contract$ref,
|};
export type ContractDetails_contract$data = ContractDetails_contract;
export type ContractDetails_contract$key = {
  +$data?: ContractDetails_contract$data,
  +$fragmentRefs: ContractDetails_contract$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ContractDetails_contract",
  "type": "ContractNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "imageUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ibStatus",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ibConId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "instrumentType",
      "args": null,
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exchange",
      "storageKey": null,
      "args": null,
      "concreteType": "ExchangeNode",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "latestPriceAud",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "latestPriceLocal",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "latestPriceDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "latestPriceTime",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "priceIncrements",
      "storageKey": null,
      "args": null,
      "concreteType": "PriceIncrement",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "increment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lowEdge",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "priceCashInfo",
      "storageKey": null,
      "args": null,
      "concreteType": "PriceCacheInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "timeSet",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "timeOfPrice",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "source",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractmarketdatarequestSet",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractMarketDataRequestNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractMarketDataRequestNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractMarketDataRequestNode",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fsContract",
      "storageKey": null,
      "args": null,
      "concreteType": "FactsetContractNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isin",
          "args": null,
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "country",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "industry",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sedol",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sector",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lotSize",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "factsetlatestcontractfinancialdata",
          "storageKey": null,
          "args": null,
          "concreteType": "LatestContractFinancialDataNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "date",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "closingPriceLocal",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a8b9e6059804416d160c6b117b07a4fd';
module.exports = node;
