import { graphql } from 'react-relay';

const removeTenantVueSharePermission = graphql`
  mutation RemoveTenantVueSharePermissionMutation(
    $guestTenantId: ID!
    $ownerTenantId: ID!
  ){
    removeTenantVueSharePermission(
      guestTenantId: $guestTenantId
      ownerTenantId: $ownerTenantId
    ){
      ownerTenant {
        id
        name
      }
      guestTenant {
        id
        name
      }
    }
  }
`;


export default removeTenantVueSharePermission;
