import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TableHead, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { graphql } from 'react-relay';
import TenantLink from '../common/links/TenantLink';
import { useCommonStyles } from '../common/Styles';
import Price from '../common/Price';
import withDebounce from '../../common/debounce';
import PopupContractSelectorField from '../common/PopupContractSelectorField';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import QueryRendererProgressV2 from '../common/QueryRendererProgressV2';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';


function FeeEntryList(props) {
  const { feeEstimate, filter, contract } = props;
  const { feeEntries: fees } = feeEstimate;
  return (
    <>
      <Grid item xs={12}>
        <Typography color="primary" variant="subtitle1">
          { `Fees for: ${filter.params.quantity}@${feeEstimate.refPrice} ${contract.currency}`}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Tenant</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fees.map(
              fee => (
                <TableRow>
                  <TableCell>{fee.feeType}</TableCell>
                  <TableCell>{fee.tenant && (<TenantLink tenant={fee.tenant} />)}</TableCell>
                  <TableCell align="right">
                    <Price
                      currency={fee.currency}
                    >
                      {fee.quantity}
                    </Price>
                  </TableCell>
                  <TableCell>{fee.description}</TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}


const feeQuery = graphql`
  query PricingEstimatorFeeQuery(
    $tenantId: ID!
    $accountId: ID
    $contractId: ID!
    $quantity: Float
    $price: Float
    $customCommission: Float
    $isManagedSystemVue: Boolean
  ) {
    viewer{
      tenants(
        id: $tenantId
      ){
        edges {
          node {
            id
            feeEstimate(
              contractId: $contractId
              quantity: $quantity
              price: $price
              customCommission: $customCommission
              accountId: $accountId
              isManagedSystemVue: $isManagedSystemVue
            ){
              refPrice
              feeEntries {
                currency
                quantity
                feeType
                description
                tenant {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const delayUpdater = withDebounce(
  (updateFn, updateVar) => {
    updateFn(updateVar);
  },
  300,
);


function PricingEstimator(props) {
  const startOrderQuantity = 10;
  const startOrderPrice = 10;
  const {
    tenant,
    onSetContract,
  } = props;
  const [contract, setContract] = React.useState(null);
  const [orderQuantity, setOrderQuantity] = React.useState(startOrderQuantity);
  const [, setPrice] = React.useState(null);
  const [account, setAccount] = React.useState(null);
  const [customCommission, setCustomCommission] = React.useState(null);
  const [ucVars] = React.useState({
    orderQuantity: startOrderQuantity,
    orderPrice: startOrderPrice,
  });

  const classes = useCommonStyles();
  return (
    <div className={classes.formControlContainer}>
      <BaseGridContainer>

        <Grid item>
          <PopupContractSelectorField
            id="standard-helperText"
            label="Contract Search"
            defaultValue={contract}
            className={classes.formControl}
            helperText="email, username or user id"
            margin="normal"
            key={contract && contract.id}
            onSelect={(inContact) => {
              setContract(inContact);
              onSetContract && onSetContract(inContact);
            }}
          />
        </Grid>

        <InnerTabFilterGridItemTextField
          id="value-input"
          type="number"
          label="Quantity"
          defaultValue={ucVars.orderQuantity}
          helperText="Order quantity"
          onChange={(e) => {
            ucVars.orderQuantity = e.target.value;
            delayUpdater(setOrderQuantity, e.target.value);
          }}
        />

        <InnerTabFilterGridItemTextField
          id="price-input"
          type="number"
          label="Price"
          defaultValue={ucVars.orderPrice}
          helperText="for (Min Price/Max Price) in fee rule"
          onChange={(e) => {
            ucVars.orderPrice = e.target.value;
            delayUpdater(setPrice, e.target.value);
          }}
        />

        <InnerTabFilterGridItemTextField
          id="price-input"
          type="number"
          label="Custom Commission"
          helperText="Custom commission"
          defaultValue={null}
          onChange={(e) => {
            const parsed = parseFloat(e.target.value);
            const final = isNaN(parsed) ? null : parsed;
            delayUpdater(setCustomCommission, final);
          }}
        />

        <Grid item>
          <PopupAccountSelectorField
            fullWidth
            className={classes.formControl}
            caption="Account"
            onSelect={a => setAccount(a)}
            onSetNull={() => setAccount(null)}
          />
        </Grid>

        {
          contract && (
            <QueryRendererProgressV2
              component={FeeEntryList}
              query={feeQuery}
              propConverter={(outerProps, _props) => (
                { feeEstimate: _props.viewer.tenants.edges[0].node.feeEstimate })}
              params={{
                tenantId: tenant.id,
                contractId: contract.id,
                accountId: account && account.id,
                quantity: Number(ucVars.orderQuantity) || 0,
                price: Number(ucVars.orderPrice) || null,
                customCommission: customCommission || null,
              }}
              contract={contract}
            />
          )
        }

      </BaseGridContainer>
    </div>
  );
}

export default PricingEstimator;
