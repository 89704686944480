import React from 'react';
import { commitMutation, graphql } from 'react-relay';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import environment from '../../Environment';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { UpdateButton } from '../common/buttons/BaseButton';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';
import updateCannedSecurityQuestion from '../../mutations/UpdateCannedSecurityQuestion';

function CannedSecurityQuestionUpdateForm(props) {
  const { 
    cannedSecurityQuestion, viewer, close, onUpdateCannedSecurityQuestion,
  } = props;
  const classes = useCommonStyles();
  const cannedSecurityQuestionId = cannedSecurityQuestion.id;
  const currentCannedSecurityQuestion = viewer.cannedSecurityQuestions.edges[0].node;
  const { question } = currentCannedSecurityQuestion;
  const [enabled, setEnabled] = React.useState(currentCannedSecurityQuestion.enabled);

  const doUpdateCannedSecurityQuestion = () => {
    const variables = {
      cannedSecurityQuestionId,
      enabled,
    };

    commitMutation(environment, {
      mutation: updateCannedSecurityQuestion,
      variables,
      onCompleted: (response) => {
        if (onUpdateCannedSecurityQuestion) onUpdateCannedSecurityQuestion();
        if (close) close(); 
      },
    });
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Update Canned Security Question
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled
            multiline
            fullWidth
            label="Question"
            defaultValue={question}
          />
        </Grid>
        <DrawerGridItemCheckBox
          key="enabled"
          label="Enabled"
          value={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked);
          }}
        />
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <UpdateButton
            onClick={doUpdateCannedSecurityQuestion}
            buttonText="Update Canned Question"
          />
        </Grid>
      </Grid>
    </div>
  );
}

const query = graphql`
  query CannedSecurityQuestionUpdateFormQuery (
    $cannedSecurityQuestionId: ID!
  ) {
    viewer {
      cannedSecurityQuestions(id: $cannedSecurityQuestionId) {
        edges {
          node {
            id
            question
            enabled
          }
        }
      }
    }
  }
`;

export default createQueryRendererProgress(
  query,
  CannedSecurityQuestionUpdateForm,
  (viewer, outerProps) => ({
    cannedSecurityQuestion: outerProps.cannedSecurityQuestion,
    close: outerProps.close,
    onUpdateCannedSecurityQuestion: outerProps.onUpdateCannedSecurityQuestion,
  }),
  props => ({
    cannedSecurityQuestionId: props.cannedSecurityQuestion.id,
  }),
);
