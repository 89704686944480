import React from 'react';
import Grid from '@material-ui/core/Grid';
import { doCommitPromise } from '../../common/commit';
import { ignoreBrokerActivity } from '../../mutations/BrokerActivity';
import TableIconButton from '../common/table/TableIconButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import BlockAdd from '../../icons/BlockAdd';
import BlockRemove from '../../icons/BlockRemove';
import BlockCommit from '../../icons/BlockCommit';
import Block from '../../icons/Block';
import NoBlock from '../../icons/NoBlock';


export default function IgnoreAction(props) {
  const { brokerActivity, msgRouter } = props;
  const {
    id,
    ignored,
    brokeractivityPtr,
    updatedAt,
  } = brokerActivity;

  const [selection, setSelection] = React.useState(msgRouter.state.selection || []);
  const { brokeractivitytransactionSet } = brokeractivityPtr;

  React.useEffect(() => {
    const onPublishState = (client, state) => {
      let { selection: updatedSelection } = state;
      updatedSelection = updatedSelection || [];
      setSelection(updatedSelection);
    };
    msgRouter.addStateListener(onPublishState);
    return function cleanup() {
      msgRouter.removeStateListener(onPublishState);
    };
  }, []);

  if (brokeractivitytransactionSet.edges.length > 0) return null;
  const refreshKey = `${id}-${updatedAt}`;

  const haveSelection = selection.length > 0;
  const currentSelected = selection.includes(id);

  return (
    <Grid
      key={refreshKey}
      container
      spacing={1}
      justify="center"
    >

      {
        (!haveSelection && !ignored) && (
          <TableCompactGridItem>
            <TableIconButton
              key={0}
              tooltip="Ignore"
              iconClass={Block}
              onClick={() => {
                const newSelection = [...selection, id];
                setSelection(newSelection);
                msgRouter.publishState(this, { selection: newSelection });
              }}
            />
          </TableCompactGridItem>
        )
      }

      {
        (haveSelection && currentSelected) && (
          <React.Fragment>
            <TableCompactGridItem>
              <TableIconButton
                key={0}
                tooltip="- Selection"
                color="secondary"
                iconClass={BlockRemove}
                onClick={() => {
                  const newSelection = selection.filter(x => x !== id);
                  setSelection(newSelection);
                  msgRouter.publishState(this, { selection: newSelection });
                }}
              />
            </TableCompactGridItem>

            <TableCompactGridItem>
              <TableIconButton
                key={0}
                tooltip="Commit to Ignore"
                color="primary"
                disabled={selection.length === 1}
                iconClass={BlockCommit}
                onClickPromise={() => doCommitPromise(
                  ignoreBrokerActivity,
                  {
                    ignore: true,
                    brokerActivityIds: selection,
                  },
                  (response, errors) => {
                    if (!errors || errors.length === 0) {
                      setSelection([]);
                      msgRouter.publishState(this, { selection: [] });
                    }
                  },
                )}
              />
            </TableCompactGridItem>
          </React.Fragment>
        )
      }

      {
        (haveSelection && !currentSelected) && (
          <TableCompactGridItem>
            <TableIconButton
              key={0}
              tooltip="+ Selection"
              color="primary"
              iconClass={BlockAdd}
              onClick={() => {
                const newSelection = [...selection, id];
                setSelection(newSelection);
                msgRouter.publishState(this, { selection: newSelection });
              }}
            />
          </TableCompactGridItem>
        )
      }


      {
        (!haveSelection && ignored) && (
          <TableCompactGridItem>
            <TableIconButton
              key={0}
              tooltip="Unignore"
              iconClass={NoBlock}
              onClickPromise={() => doCommitPromise(
                ignoreBrokerActivity,
                {
                  ignore: false,
                  brokerActivityId: id,
                },
                (response, errors) => {
                  if (!errors || errors.length === 0) {
                    setSelection([]);
                    msgRouter.publishState(this, { selection: [] });
                  }
                },
              )}
            />
          </TableCompactGridItem>
        )
      }

    </Grid>
  );
}
