/**
 * @flow
 * @relayHash c6880389d09be6226fbf91f53e260fa6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PositionTransferRequestList_viewer$ref = any;
export type PositionTransferRequestListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  accountId?: ?string,
  accountType?: ?string,
|};
export type PositionTransferRequestListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PositionTransferRequestList_viewer$ref
  |}
|};
export type PositionTransferRequestListQuery = {|
  variables: PositionTransferRequestListQueryVariables,
  response: PositionTransferRequestListQueryResponse,
|};
*/


/*
query PositionTransferRequestListQuery(
  $first: Int
  $offset: Int
  $search: String
  $accountId: ID
  $accountType: String
) {
  viewer {
    ...PositionTransferRequestList_viewer_1mgWoi
    id
  }
}

fragment PositionTransferRequestList_viewer_1mgWoi on Query {
  positionTransferRequests(first: $first, offset: $offset, search: $search, orderBy: "-createdAt", account: $accountId, accountType: $accountType) {
    edges {
      node {
        id
        createdAt
        updatedAt
        status
        account {
          id
          name
        }
        positionTransferPurchases {
          edges {
            node {
              id
              updatedAt
              quantity
              reportDate
              contract {
                id
                name
                symbol
                ibConId
                currency
              }
              portfolio {
                id
                trackingVueName
              }
              costBasis
              commission
              dummy
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountType",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountType",
  "variableName": "accountType"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PositionTransferRequestListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PositionTransferRequestList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PositionTransferRequestListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferRequests",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "account",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v4/*: any*/)
            ],
            "concreteType": "PositionTransferRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferRequestNode",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "positionTransferPurchases",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PositionTransferPurchaseNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PositionTransferPurchaseNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "PositionTransferPurchaseNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "quantity",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "reportDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "contract",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ContractNode",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v7/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "symbol",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "ibConId",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "currency",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "portfolio",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PortfolioNode",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "trackingVueName",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "costBasis",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "commission",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "dummy",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PositionTransferRequestListQuery",
    "id": null,
    "text": "query PositionTransferRequestListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $accountId: ID\n  $accountType: String\n) {\n  viewer {\n    ...PositionTransferRequestList_viewer_1mgWoi\n    id\n  }\n}\n\nfragment PositionTransferRequestList_viewer_1mgWoi on Query {\n  positionTransferRequests(first: $first, offset: $offset, search: $search, orderBy: \"-createdAt\", account: $accountId, accountType: $accountType) {\n    edges {\n      node {\n        id\n        createdAt\n        updatedAt\n        status\n        account {\n          id\n          name\n        }\n        positionTransferPurchases {\n          edges {\n            node {\n              id\n              updatedAt\n              quantity\n              reportDate\n              contract {\n                id\n                name\n                symbol\n                ibConId\n                currency\n              }\n              portfolio {\n                id\n                trackingVueName\n              }\n              costBasis\n              commission\n              dummy\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e01b07f250f35bfbb61674deccad41b0';
module.exports = node;
