import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import moment from 'moment-timezone';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import PaginatedTable from '../common/PaginatedTable';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableButton from '../common/buttons/TableButton';
import doCommit from '../../common/commit';
import { deleteReportMutation } from '../../mutations/Report';
import RelayFilter from '../../common/RelayFilter';
import TenantRemittanceAdviceGenerateForm from './TenantRemittanceAdviceGenerateForm';


function ReportLinks(props) {
  const { node: report } = props;
  if (report.xlsxUrl) {
    return <Link key={`xlsx-${report.updatedAt}`} href={report.xlsxUrl}>Excel</Link>;
  }

  return <React.Fragment />;
}

function TenantReportList(props) {
  const { tenantId, viewer, relay } = props;
  const [tenantReportDrawerOpen, setTenantReportDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();
  const [relayFilter] = React.useState(new RelayFilter({
    tenantId,
  }));

  const columns = [
    TableFieldMeta.localId,
    {
      Header: 'Coverage',
      accessor: node => moment(node.remittanceadvice.coverageDate).format('MMM-YY'),
    },
    {
      Header: 'Start Date',
      accessor: node => (node.remittanceadvice.startDate !== null
        ? moment(node.remittanceadvice.startDate).format('DD/MM/YY')
        : ''),
    },
    {
      Header: 'End Date',
      accessor: node => moment(node.remittanceadvice.endDate).format('DD/MM/YY'),
    },
    {
      Header: '',
      id: 'links',
      accessor: node => node,
      Cell: row => <ReportLinks node={row.value} />,
    },
    {
      Header: '',
      id: 'delete',
      accessor: node => node,
      Cell: row => (
        <TableButton
          color="secondary"
          onClick={() => {
            deleteReport(row.value);
          }}
        >
          Delete
        </TableButton>
      ),
    },
  ];

  const deleteReport = (report) => {
    doCommit(
      deleteReportMutation,
      {
        reportId: report.id,
      },
      () => relayFilter.update(),
    );
  };

  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={1} justify="flex-end" style={{ marginBottom: '10pt' }}>
        <Grid item />
        <Grid item>
          <AddButton
            buttonText="Create Remittance Advice"
            onClick={() => setTenantReportDrawerOpen(true)}
          />
        </Grid>
      </Grid>
      <Paper>
        <PaginatedTable
          ref={relayFilter.tableRef}
          getDataNode={() => (viewer ? viewer.tenantReports : null)}
          getFetchParams={relayFilter.fetchParams}
          columns={columns}
          relay={relay}
          viewer={viewer}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={tenantReportDrawerOpen}
        onClose={() => setTenantReportDrawerOpen(false)}
      >
        <TenantRemittanceAdviceGenerateForm
          tenantId={tenantId}
          viewer={viewer}
          relay={relay}
          relayFilter={viewer}
          onClose={() => {
            relayFilter.update();
            setTenantReportDrawerOpen(false);
          }}
        />
      </Drawer>
    </div>
  );
}

const query = graphql`
  query TenantReportListQuery (
    $tenantId: ID,
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...TenantReportList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        tenantId: $tenantId,
      )
    }
  }
`;

const container = createRefetchContainer(
  TenantReportList,
  {
    viewer: graphql`
      fragment TenantReportList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        tenantId: {type: ID, defaultValue: null }
      ) {
        tenantReports (
          first: $first
          offset: $offset
          orderBy: "-createdAt"
          tenantId: $tenantId
        ) {
          edges {
            node {
              id
              xlsxUrl
              remittanceadvice {
                startDate
                endDate
                coverageDate
              }
            }
          }
          count
        }
        tenants(id: $tenantId) {
          edges {
            node {
              tenantAccounts {
                edges {
                  node {
                    name
                    latestWithdrawalDate
                    postWithdrawalCashTransferCount
                  }
                }
                count
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { tenantId }) => ({ tenantId }),
  props => ({ tenantId: props.tenantId }),
);
