import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import TableFieldMeta from '../../common/TableFieldMeta';
import VerbModifier from '../user/VerbModifier';
import EnumListerModifierDataTable from '../common/table/EnumListerModifierDataTable';
import TableToggleButtonGroup from '../common/table/TableToggleButtonGroup';


function AdviserPermissionRow(props) {
  const {
    permission,
    onUpdateRecord,
  } = props;
  const [disableScope, setDisableScope] = React.useState(permission.verb === null);
  const [members] = React.useState({
    permission,
  });

  return (
    <Grid container justify="space-around" key={permission.resource}>
      <Grid item>
        <VerbModifier
          resource={permission.resource}
          verb={permission.verb}
          onSetVerb={(resource, newVerb) => {
            setDisableScope(newVerb === null);
            members.permission.verb = newVerb;
            onUpdateRecord && onUpdateRecord(members.permission);
          }}
        />
      </Grid>
      <Grid item>
        <TableToggleButtonGroup
          groupKey={permission.resource}
          disabled={disableScope}
          key={`${permission.resource}-${disableScope}`}
          toggleOptions={[
            ['SELF', 'Self'],
            ['TENANT', 'Tenant'],
            ['DEEP', 'Deep'],
          ]}
          initialValue={permission.scope || 'SELF'}
          onSetValue={(newValue, groupKey) => {
            members.permission.scope = newValue;
            onUpdateRecord && onUpdateRecord(members.permission);
          }}
        />
      </Grid>
    </Grid>
  );
}

export default function AdviserPermissions(props) {
  const {
    permissions,
    onSetPermissions,
  } = props;

  const [members] = React.useState({
    permissions,
  });

  const updateRecords = (n) => {
    const idx = members.permissions.findIndex(e => e.resource === n.resource);
    if (idx === -1) members.permissions.push(n);
    else members.permissions[idx] = n;

    const filtered = members.permissions.filter(e => e.verb !== null);
    onSetPermissions && onSetPermissions(filtered);
  };

  return (
    <EnumListerModifierDataTable
      enumType="Resource"
      keyFinder={(record, metaKey) => record.resource === metaKey}
      keyInitiator={metaKey => ({
        resource: metaKey,
        verb: null,
        scope: 'SELF',
      })}
      columnMeta={[
        TableFieldMeta.field('resource', 'Resource'),
        // TableFieldMeta.enum('Resource', '', node => node.resource),
        TableFieldMeta.fieldCell('verb', 'Access & Scope', node => (
          <AdviserPermissionRow
            key={node.value.resource}
            permission={node.value}
            onUpdateRecord={updateRecords}
          />
        ), { align: 'center' }),
      ]}
      dataNodes={members.permissions}
    />

  );
}

AdviserPermissions.propTypes = {
  permissions: PropTypes.array.isRequired,
};

AdviserPermissions.defaultProps = {
};
