import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { toGlobalId } from '../../helpers/ID';
import DataTableWidget from '../common/DataTableWidget';
import { isRecurNull } from '../../common/helpers';
import ContractLink from '../common/links/ContractLink';
import BrokerOrderStatus from '../common/BrokerOrderStatus';
import ApprovalActionLogDataTable from './approval/ApprovalActionLogDataTable';
import ApprovalDetailVerticalTable from './approval/ApprovalDetailVerticalTable';


function OrderApproval(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);

  const orderApproval = isRecurNull(viewer, ['orderApprovals', 'edges', 0, 'node']);

  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={3}>

        <ApprovalDetailVerticalTable
          xs={12}
          md={[4]}
          orderApproval={orderApproval}
        />

        <DataTableWidget
          xs={12}
          md={8}
          dataNodes={orderApproval.orderapprovalcontractSet.edges.map(x => x.node)}
          columnMeta={[
            TableFieldMeta.field(node => <ContractLink contract={node.contract} />, 'Contract', { align: 'right' }),
            TableFieldMeta.field(row => row.contract.symbol, 'Symbol'),
            TableFieldMeta.field('quantity', 'Quantity', { align: 'right' }),
            TableFieldMeta.enum('OrderApprovalContractStatus', 'Status', node => node.orderStatus),
            TableFieldMeta.linkField(node => (node.order && node.order.id), 'stock-order', 'Order'),
            TableFieldMeta.field(
              node => (node.order && <BrokerOrderStatus stockOrder={node.order.brokerOrder} />),
              'Order Status',
              { align: 'center' },
            ),
          ]}
          title="Contracts"
        />

        <ApprovalActionLogDataTable
          xs={12}
          actionLogs={orderApproval.actionLogs.edges.map(node => node.node)}
        />

      </Grid>
    </div>
  );
}


const query = graphql`
  query OrderApprovalQuery (
    $first: Int
    $offset: Int
    $approvalId: ID
  ) {
    viewer {
      ...OrderApproval_viewer
      @arguments (
        first: $first
        offset: $offset
        approvalId: $approvalId
      )
    }
  }
`;

const container = createRefetchContainer(
  OrderApproval,
  {
    viewer: graphql`
      fragment OrderApproval_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        approvalId: {type: ID}
      ) {
        orderApprovals(
          first: $first
          offset: $offset
          id: $approvalId
        ) {
          edges {
            node {
              ...ApprovalDetailVerticalTable_approvalDetails
              @relay(mask: false)
              
              ...ApprovalActionLogDataTable_actionLogs
              @relay(mask: false)
              
              orderclientapprovalSet {
                edges {
                  node {
                    id
                    pdfUrl
                  }
                }
              }
              
              orderapprovalcontractSet {
                edges {
                  node {
                    id
                    createdAt
                    updatedAt
                    contract {
                      id
                      symbol
                    }
                    quantity
                    orderStatus
                    order {
                      id
                      brokerOrder {
                        status
                        matched
                        id
                      }
                    }
                  }
                }
              }
              
              
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    approvalId: toGlobalId('OrderApproval', props.match.params.approvalId),
  }),
));
