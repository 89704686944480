/**
 * @flow
 * @relayHash 4287eebca76f2ab0a82cde2e2c84e90c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSubscriptionActionLogs_viewer$ref = any;
export type UserSubscriptionActionLogsSetQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  userSubscriptionId?: ?string,
|};
export type UserSubscriptionActionLogsSetQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserSubscriptionActionLogs_viewer$ref
  |}
|};
export type UserSubscriptionActionLogsSetQuery = {|
  variables: UserSubscriptionActionLogsSetQueryVariables,
  response: UserSubscriptionActionLogsSetQueryResponse,
|};
*/


/*
query UserSubscriptionActionLogsSetQuery(
  $first: Int
  $offset: Int
  $userSubscriptionId: ID
) {
  viewer {
    ...UserSubscriptionActionLogs_viewer_5UfCD
    id
  }
}

fragment UserSubscriptionActionLogs_viewer_5UfCD on Query {
  userSubscriptions(id: $userSubscriptionId) {
    count
    edges {
      node {
        actionLogs(first: $first, offset: $offset, orderBy: "-actionTime") {
          edges {
            node {
              id
              actionType
              actionTime
              actionDescription
              user {
                id
                username
                email
                fullName
              }
            }
          }
          count
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userSubscriptionId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSubscriptionActionLogsSetQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSubscriptionActionLogs_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "userSubscriptionId",
                "variableName": "userSubscriptionId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSubscriptionActionLogsSetQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userSubscriptions",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userSubscriptionId"
              }
            ],
            "concreteType": "UserSubscriptionNodeConnection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserSubscriptionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actionLogs",
                        "storageKey": null,
                        "args": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "-actionTime"
                          }
                        ],
                        "concreteType": "ActionLogUserRequestNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActionLogUserRequestNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ActionLogUserRequestNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionType",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionTime",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionDescription",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "username",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "email",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fullName",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v3/*: any*/)
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSubscriptionActionLogsSetQuery",
    "id": null,
    "text": "query UserSubscriptionActionLogsSetQuery(\n  $first: Int\n  $offset: Int\n  $userSubscriptionId: ID\n) {\n  viewer {\n    ...UserSubscriptionActionLogs_viewer_5UfCD\n    id\n  }\n}\n\nfragment UserSubscriptionActionLogs_viewer_5UfCD on Query {\n  userSubscriptions(id: $userSubscriptionId) {\n    count\n    edges {\n      node {\n        actionLogs(first: $first, offset: $offset, orderBy: \"-actionTime\") {\n          edges {\n            node {\n              id\n              actionType\n              actionTime\n              actionDescription\n              user {\n                id\n                username\n                email\n                fullName\n              }\n            }\n          }\n          count\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91caec78cc4f6840aadd1d0638572eff';
module.exports = node;
