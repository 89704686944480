/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type InstrumentType = "CASH" | "STK" | "WAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BrokerTransactionList_viewer$ref: FragmentReference;
declare export opaque type BrokerTransactionList_viewer$fragmentType: BrokerTransactionList_viewer$ref;
export type BrokerTransactionList_viewer = {|
  +ibBrokerTransactions: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +description: ?string,
        +matched: boolean,
        +conId: number,
        +price: number,
        +quantity: number,
        +symbol: string,
        +currency: ?Currency,
        +proceeds: ?number,
        +tradeId: ?any,
        +reportDate: any,
        +instrumentType: InstrumentType,
        +transactionType: string,
        +transactionDatetime: any,
        +execId: ?string,
        +commission: ?number,
        +commissionCurrency: Currency,
        +ignore: boolean,
        +createdAt: any,
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: BrokerTransactionList_viewer$ref,
|};
export type BrokerTransactionList_viewer$data = BrokerTransactionList_viewer;
export type BrokerTransactionList_viewer$key = {
  +$data?: BrokerTransactionList_viewer$data,
  +$fragmentRefs: BrokerTransactionList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "BrokerTransactionList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "reportDate",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "currency",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "matched",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "ignore",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "instrumentType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "transactionType",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "symbol",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "ibConId",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "transactionTypeIn",
      "type": "[String]",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ibBrokerTransactions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "conId",
          "variableName": "ibConId"
        },
        {
          "kind": "Variable",
          "name": "currency",
          "variableName": "currency"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "ignore",
          "variableName": "ignore"
        },
        {
          "kind": "Variable",
          "name": "instrumentType",
          "variableName": "instrumentType"
        },
        {
          "kind": "Variable",
          "name": "matched",
          "variableName": "matched"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "reportDate",
          "variableName": "reportDate"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "symbol",
          "variableName": "symbol"
        },
        {
          "kind": "Variable",
          "name": "transactionType",
          "variableName": "transactionType"
        },
        {
          "kind": "Variable",
          "name": "transactionTypeIn",
          "variableName": "transactionTypeIn"
        }
      ],
      "concreteType": "IBBrokerTransactionNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "IBBrokerTransactionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "IBBrokerTransactionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "matched",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "conId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "price",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "symbol",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "proceeds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "tradeId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reportDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "instrumentType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "transactionType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "transactionDatetime",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "execId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "commission",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "commissionCurrency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "ignore",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '572f50a8555f5b0eeea282a07aa6934a';
module.exports = node;
