/**
 * @flow
 * @relayHash c6aa6b1da00e7a4d2c4cfb54602b790e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerPositionTransferList_viewer$ref = any;
export type BrokerPositionTransferListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  processedAt_Isnull?: ?boolean,
  search?: ?string,
|};
export type BrokerPositionTransferListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrokerPositionTransferList_viewer$ref
  |}
|};
export type BrokerPositionTransferListQuery = {|
  variables: BrokerPositionTransferListQueryVariables,
  response: BrokerPositionTransferListQueryResponse,
|};
*/


/*
query BrokerPositionTransferListQuery(
  $first: Int
  $offset: Int
  $processedAt_Isnull: Boolean
  $search: String
) {
  viewer {
    ...BrokerPositionTransferList_viewer_3llZbG
    id
  }
}

fragment BrokerPositionTransferList_viewer_3llZbG on Query {
  ibTransfers(first: $first, offset: $offset, processedAt_Isnull: $processedAt_Isnull, search: $search) {
    edges {
      node {
        processedBy {
          id
          fullName
        }
        processedAt
        id
        direction
        positionAmount
        description
        symbol
        quantity
        contraAccountId
        contraAccountName
        contraCompany
        ibConId
        updatedAt
        createdAt
        reportDate
        cashQuantity
        currency
        ignored
        brokeractivityPtr {
          id
          brokeractivitytransactionSet {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "processedAt_Isnull",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "processedAt_Isnull",
    "variableName": "processedAt_Isnull"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerPositionTransferListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BrokerPositionTransferList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerPositionTransferListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibTransfers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "IBTransferNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBTransferNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBTransferNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "processedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "processedAt",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "direction",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "positionAmount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "symbol",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contraAccountId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contraAccountName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "contraCompany",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ibConId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "cashQuantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ignored",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokeractivityPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "brokeractivitytransactionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerPositionTransferListQuery",
    "id": null,
    "text": "query BrokerPositionTransferListQuery(\n  $first: Int\n  $offset: Int\n  $processedAt_Isnull: Boolean\n  $search: String\n) {\n  viewer {\n    ...BrokerPositionTransferList_viewer_3llZbG\n    id\n  }\n}\n\nfragment BrokerPositionTransferList_viewer_3llZbG on Query {\n  ibTransfers(first: $first, offset: $offset, processedAt_Isnull: $processedAt_Isnull, search: $search) {\n    edges {\n      node {\n        processedBy {\n          id\n          fullName\n        }\n        processedAt\n        id\n        direction\n        positionAmount\n        description\n        symbol\n        quantity\n        contraAccountId\n        contraAccountName\n        contraCompany\n        ibConId\n        updatedAt\n        createdAt\n        reportDate\n        cashQuantity\n        currency\n        ignored\n        brokeractivityPtr {\n          id\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a09a4a35b9aad03237fb99eb42e1156';
module.exports = node;
