import { graphql } from 'react-relay';

const createVueDraftMutation = graphql`
  mutation CreateVueDraftMutation (
    $vueId: ID!
  ) {
    createVueDraft(
      vueId: $vueId,
    ) {
      vue {
        ...VueDetails_vueDetails
      }
    }
  }
`;

export default createVueDraftMutation;
