/**
 * @flow
 * @relayHash c18b2b43c398cc0d5c997ac24eb5d20c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PricingQueryVariables = {|
  tenantId: string
|};
export type PricingQueryResponse = {|
  +viewer: ?{|
    +tenants: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +feeTemplate: ?{|
            +id: string,
            +name: string,
          |},
          +tenantexchangefeetemplateSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +createdAt: any,
                +exchange: {|
                  +symbol: string
                |},
                +feeTemplate: {|
                  +id: string,
                  +name: string,
                |},
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type PricingQuery = {|
  variables: PricingQueryVariables,
  response: PricingQueryResponse,
|};
*/


/*
query PricingQuery(
  $tenantId: ID!
) {
  viewer {
    tenants(id: $tenantId) {
      edges {
        node {
          id
          name
          feeTemplate {
            id
            name
          }
          tenantexchangefeetemplateSet {
            edges {
              node {
                id
                createdAt
                exchange {
                  symbol
                  id
                }
                feeTemplate {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenantId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "feeTemplate",
  "storageKey": null,
  "args": null,
  "concreteType": "FeeTemplateNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PricingQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenants",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "TenantNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantexchangefeetemplateSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantExchangeFeeTemplateNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantExchangeFeeTemplateNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TenantExchangeFeeTemplateNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "exchange",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ExchangeNode",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/)
                                    ]
                                  },
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PricingQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenants",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "TenantNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantexchangefeetemplateSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantExchangeFeeTemplateNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantExchangeFeeTemplateNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TenantExchangeFeeTemplateNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "exchange",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ExchangeNode",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  },
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PricingQuery",
    "id": null,
    "text": "query PricingQuery(\n  $tenantId: ID!\n) {\n  viewer {\n    tenants(id: $tenantId) {\n      edges {\n        node {\n          id\n          name\n          feeTemplate {\n            id\n            name\n          }\n          tenantexchangefeetemplateSet {\n            edges {\n              node {\n                id\n                createdAt\n                exchange {\n                  symbol\n                  id\n                }\n                feeTemplate {\n                  id\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9fd9ded13ca37ecb2d916ea44e80e0e5';
module.exports = node;
