import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { VerticalTableWidget } from '../../common/VerticalTableWidget';
import AccountLink from '../../common/links/AccountLink';
import ApprovalStatus from '../../common/ApprovalStatus';
import TableFieldMeta, { Gid } from '../../../common/TableFieldMeta';
import { extractKeys, isRecurNull } from '../../../common/helpers';
import { toLocal } from '../../../helpers/ID';
import { muiGridSizes } from '../../../common/constants';
import EnumValue from '../../common/EnumValue';


export default function ApprovalDetailVerticalTable(props) {
  const {
    orderApproval,
    title,
    showSelf,
    ...otherProps
  } = props;
  if (!orderApproval) {
    return null;
  }

  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  const orderApprovalReport = isRecurNull(orderApproval,
    ['orderclientapprovalSet', 'edges', 0, 'node']);
  const pdfUrl = orderApprovalReport && orderApprovalReport.pdfUrl;
  const { portfolioOrder } = orderApproval;
  const poGid = portfolioOrder && toLocal(portfolioOrder.id);

  return (
    <VerticalTableWidget
      {...sizeProps}
      columnMeta={[
        [...(showSelf ? [['Id'], node => (
          <Link href={`/order-approval/${toLocal(node.id)}`}>
            {toLocal(node.id)}
          </Link>
        )] : [])],
        ['Account', node => (
          <AccountLink
            account={node.portfolio.account}
            charLimit={20}
            showAppLink
          />
        )],
        ['Status', node => (
          <Grid container justify="flex-end">
            <Grid item>
              <ApprovalStatus approval={node} />
            </Grid>
          </Grid>
        )],
        ['Created At', TableFieldMeta.createdAt.accessor],
        ['Updated At', TableFieldMeta.updatedAt.accessor],
        ['Notified At', TableFieldMeta.dateTimeField('notifiedAt', '').accessor],
        ['Reminded At', TableFieldMeta.dateTimeField('remindedAt', '').accessor],
        ['Proposal', node => (
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <EnumValue enumValue={orderApproval.proposal.type} enumType="OrderProposalType" />
            </Grid>
            <Grid item>
              <Link href={`/order-proposal/${toLocal(orderApproval.proposal.id)}`}>
                {toLocal(orderApproval.proposal.id)}
              </Link>
            </Grid>
          </Grid>
        )],
        ['Approval PDF', node => orderApprovalReport && <Link href={pdfUrl}>PDF</Link>],
        ['Portfolio Order', node => portfolioOrder && <Link href={`/order/${poGid}`}>{poGid}</Link>],
        ['Gid', node => <Gid gid={node.id} />],
      ]}
      dataNode={orderApproval}
      showHeadings={false}
      title={title}
    />
  );
}

ApprovalDetailVerticalTable.propTypes = {
  orderApproval: PropTypes.array.isRequired,
  title: PropTypes.string,
  showSelf: PropTypes.bool,
};

ApprovalDetailVerticalTable.defaultProps = {
  title: 'Details',
  showSelf: false,
};


graphql`
  fragment ApprovalDetailVerticalTable_approvalDetails on OrderApprovalNode {
    id

    portfolioOrder {
      id
      status
    }
    createdAt
    updatedAt
    approvedAt
    notifiedAt
    remindedAt
    
    proposal {
      id
      type
    }

    approvedBy {
      fullName
    }
    status
    portfolio {
      id
      trackingVueName
      account {
        name
        id
      }
    }
  }
`;
