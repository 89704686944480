import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { createRefetchContainer, graphql } from 'react-relay';
import { Grid } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import TableFieldMeta from '../../common/TableFieldMeta';
import AccountLink from '../common/links/AccountLink';
import DateTime from '../common/DateTimeMoment';
import MoreButton from '../common/buttons/MoreButton';
import { fromGlobalId } from '../../helpers/ID';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { strToBool } from '../common/CustomEnumDefs';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import withSuspense from '../common/table/Suspention';

const UserAddForm = withSuspense(React.lazy(
  () => (
    process.env.DEPLOYMENT_CODE === 'AU'
      ? import('./au/UserAddForm')
      : import('./uk/UserAddForm')
  ),
));

const mobileNoReader = (node) => {
  if (node.usermodel) return node.usermodel.mobileNumber;
  if (node.adviser) return node.adviser.mobileNumber;
  return null;
};

function UserList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const [addUserOpen, setAddUserOpen] = React.useState(false);
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('username', 'UserName'),
    TableFieldMeta.field('email', 'email'),
    {
      id: 'accounts',
      accessor: node => node,
      Cell: (row) => {
        const { usermodel } = row.value;
        if (usermodel) {
          return (
            usermodel.accounts.edges.map(
              (account, i) => (
                <React.Fragment>
                  {i !== 0 && ', '}
                  <AccountLink account={account.node} charLimit={40} showNumber />
                </React.Fragment>
              ),
            )
          );
        }
        return <React.Fragment />;
      },
      Header: 'Accounts',
    },
    TableFieldMeta.field('firstName', 'First Name'),
    TableFieldMeta.field('lastName', 'Last Name'),
    TableFieldMeta.field(
      node => mobileNoReader(node),
      'Mobile',
      { align: 'right' },
    ),
    {
      id: 'dateJoined',
      accessor: node => node,
      Cell: row => <DateTime>{row.value.dateJoined}</DateTime>,
      Header: 'Date Joined',
    },
    {
      id: 'isStaff',
      accessor: node => (node.isStaff ? 'True' : ''),
      Header: 'Is Staff',
    },
    {
      id: 'more',
      Header: '',
      accessor: node => node,
      Cell: row => <MoreButton href={`/user/${fromGlobalId(row.value.id)[1]}`} />,
    },
  ];

  return (
    <div>
      <Drawer
        anchor="right"
        open={addUserOpen}
        onClose={() => setAddUserOpen(false)}
      >
        <UserAddForm
          close={() => setAddUserOpen(false)}
        />
      </Drawer>

      <BaseGridContainer>

        <InnerTabFilterGridItemTextField
          id="user-id-search-filter"
          label="Custom Search"
          helperText="Name, e-mail, ID, username"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="first-name-filter"
          label="First Name"
          helperText="Full First Name"
          defaultValue={filter.params.firstName}
          onChange={e => filter.updateVar('firstName', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="last-name-filter"
          label="Last Name"
          helperText="Full Last Name"
          defaultValue={filter.params.lastName}
          onChange={e => filter.updateVar('lastName', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="IsStaff"
          label="Is Staff"
          halfWidth
          value={filter.params.isStaff}
          onChange={e => filter.updateVar('isStaff', strToBool(e.target.value))}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.authUsers : null)}
          />
        </Grid>
      </BaseGridContainer>

      <BottomGridContainer>
        <Grid item>
          <AddButton
            onClick={() => setAddUserOpen(true)}
            buttonText="User"
          />
        </Grid>
      </BottomGridContainer>

    </div>
  );
}

const query = graphql`
  query UserListQuery (
    $first: Int,
    $offset: Int
    $search: String
    $firstName: String
    $lastName: String
    $isStaff: Boolean
  ) {
    viewer {
      ...UserList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        firstName: $firstName
        lastName: $lastName
        isStaff: $isStaff
      )
    }
  }
`;

const container = createRefetchContainer(
  UserList,
  {
    viewer: graphql`
      fragment UserList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        firstName: {type: String}
        lastName: {type: String}
        isStaff: {type: Boolean, defaultValue: null}
      ) {

        authUsers(
          first: $first
          offset: $offset
          search: $search
          firstName: $firstName
          lastName: $lastName
          isStaff: $isStaff
        ) {
          edges {
            node {
              id
              firstName
              lastName
              lastLogin
              username
              email
              isStaff
              isSuperuser
              isActive
              dateJoined
              
              usersubscriptionSet {
                edges {
                  node {
                    id
                    billPeriodEnd
                    active
                  }
                }
              }

              tenant {
                id
                name
              }

              adviser {
                id
                firstName
                lastName
                mobileNumber
              }

              usermodel {
                mobileNumber
                parsedMobileNumber {
                  rawNumber
                  e164Number
                  countryCode
                  countrySymbol
                  nationalNumber
                  isPossibleNumber
                  isValidNumber
                }
                accounts {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

const queryRenderer = createQueryRendererProgressV2(
  query,
  container,
  {},
  {},
  {
    debounceFields: ['search', 'firstName', 'lastName'],
  },
);

export default withRouter(queryRenderer);
