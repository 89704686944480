import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import PaginatedTable from '../common/PaginatedTable';
import { useCommonStyles } from '../common/Styles';
import BrokerActivityActions from './BrokerActivityActions';
import TableFieldMeta from '../../common/TableFieldMeta';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import BaseGridContainer from '../common/BaseGridContainer';
import RelayFilter from '../../common/RelayFilter';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import { toLocal } from '../../helpers/ID';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { boolToStrInv, strToBoolInv } from '../common/CustomEnumDefs';
import BrokerActivityDetails from './BrokerActivityDetails';
import ProcessForm from './forms/ProcessForm';
import { AddButton } from '../common/buttons/BaseButton';
import StockDividendAddForm from './forms/StockDividendAddForm';


function VBDividendList(props) {
  const { viewer, relay } = props;
  const classes = useCommonStyles();
  
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [addDrawerOpen, setAddDrawerOpen] = React.useState(false);
  const [relayFilter] = React.useState(new RelayFilter({
    search: null,
    reportDate: null,
    symbol: null,
    ibConId: null,
    currency: null,
    processedAt_Isnull: null,
  }, ['search', 'symbol', 'conId', 'currency']));

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    TableFieldMeta.field('exDate', 'Ex Date'),
    TableFieldMeta.field('payDate', 'Pay Date'),
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.priceField('reinvestmentPrice', 'ReInv. Price',
      'reinvestmentPrice', ['ibCorporateAction', 'contract', 'currency']),
    TableFieldMeta.field('ibCorpactId', 'IB Corpact ID', {
      align: 'right',
      accessor: node => toLocal(node.ibCorporateAction.id),
    }),
    {
      id: 'actions',
      accessor: node => node,
      Header: 'Actions',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={() => {
            setBrokerActivity(row.value);
            setDrawerOpen(true);
          }}
          onShowDetails={() => {
            setBrokerActivity(row.value);
            setDetailsOpen(true);
          }}
        />
      ),
    },
  ];

  return (
    <div className={classes.innerTabRoot}>

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails brokerActivity={brokerActivity} />
        </div>
      </Drawer>

      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <ProcessForm
          key={brokerActivity ? brokerActivity.id : 'procForm'}
          brokerActivity={brokerActivity}
          onClose={() => setDrawerOpen(false)}
          quantityDescription="Responsible Quantity"
          brokerActivityCashDeltaVarName="amount"
          checkQuantitySum={false}
          heading="Process Dividend"
          nodeName="VBDividendNode"
          showSibling={false}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={addDrawerOpen}
        onClose={() => setAddDrawerOpen(false)}
      >
        <StockDividendAddForm
          onClose={() => setAddDrawerOpen(false)}
        />
      </Drawer>


      <BaseGridContainer>
        <Grid item xs={12} md={6}>
          <BaseGridContainer>
            <InnerTabFilterGridItemDateField
              value={relayFilter.params.reportDate}
              onChange={value => relayFilter.updateVar('reportDate', value)}
              label="Report Date"
            />

            <InnerTabFilterSelectField
              enumType="ProcessedStatus"
              label="Processed"
              helperText="Processed or not"
              value={boolToStrInv(relayFilter.params.processedAt_Isnull)}
              onChange={e => relayFilter.updateVar('processedAt_Isnull', strToBoolInv(e.target.value))}
            />
          </BaseGridContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container justify="flex-end">
            <Grid item>
              <AddButton onClick={() => setAddDrawerOpen(true)} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PaginatedTable
            columns={columns}
            ref={relayFilter.tableRef}
            viewer={viewer}
            getDataNode={() => (viewer ? viewer.vbDividends : null)}
            getFetchParams={relayFilter.fetchParams}
            relay={relay}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query VBDividendListQuery (
    $first: Int
    $offset: Int
    $reportDate: Date
    $processedAt_Isnull: Boolean
  ) {
    viewer {
      ...VBDividendList_viewer
      @arguments (
        first: $first
        offset: $offset
        reportDate: $reportDate
        processedAt_Isnull: $processedAt_Isnull
      )
    }
  }
`;

const container = createRefetchContainer(
  VBDividendList,
  {
    viewer: graphql`
      fragment VBDividendList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        reportDate: {type: Date}
        processedAt_Isnull: {type: Boolean}
      ) {
        vbDividends(
          first: $first
          offset: $offset
          reportDate: $reportDate
          processedAt_Isnull: $processedAt_Isnull
        ) {
          edges {
            node {
              ...VBDividend_all @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgress(
  query,
  container,
));
