import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const loaderQuery = graphql`
  query FeeTemplateLoaderQuery (
    $first: Int
    $offset: Int
    $search: String
  ) {
    viewer {
      ...FeeTemplateLoaderFragment_viewer
      @arguments(
        first: $first
        offset: $offset
        search: $search
      )
    }
  }
`;

const refetchContainer = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment FeeTemplateLoaderFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
      ) {
        feeTemplates (
          first: $first
          offset: $offset
          search: $search
        ) {
          edges {
            node {
              id
              name
              createdAt
              updatedAt
              description
            }
          }
        }
      }
    `,
  },
  loaderQuery,
);

export default function FeeTemplateLoader(props) {
  return (
    <FieldLoader
      loaderQuery={loaderQuery}
      refetchContainer={refetchContainer}
      getOptionLabel={option => (option.name)}
      nodeCollector={viewer => viewer.feeTemplates.edges.map(edge => edge.node)}
      {...props}
    />
  );
}
