import React from 'react';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'react-relay';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton, UpdateButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';
import createUserRoleMutation from '../../mutations/CreateUserRoleMutation';
import updateUserRoleMutation from '../../mutations/UpdateUserRoleMutation';
import UserPermissions from './UserPermissions';

export function UserRoleDrawerContainer(props) {
  const classes = useCommonStyles();
  const { children } = props;
  return (
    <div className={classes.drawerRoot}>
      {children}
    </div>
  );
}


export function UserRoleAddForm(props) {
  const {
    close,
    onAddUserRole,
    userRole,
  } = props;
  const onModify = !!userRole;
  const initialPermissions = onModify ? userRole.rolePermissions.edges.map(
    x => ({ ...(x.node) }), // Need a copy of read-only objects
  ) : [];
  const [members] = React.useState({
    roleName: onModify ? userRole.name : '',
  });
  const [rolePermissions, setRolePermissions] = React.useState(initialPermissions);
  const [defaultPermissions, setDefaultPermissions] = React.useState('custom');
  const isCustom = defaultPermissions === 'custom';

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading={`${onModify ? 'Modify' : 'Add'} User Role`}
        />

        <DrawerGridItemTextField
          label="Role name"
          disabled={onModify}
          defaultValue={members.roleName}
          onChange={(e) => { members.roleName = e.target.value; }}
        />

        {
          !onModify && (
            <DrawerGridItemSelectField
              enumType={[
                { name: 'default', description: 'Default Adviser' },
                { name: 'custom', description: 'Custom Permissions' },
              ]}
              label="Permission Set"
              value={defaultPermissions}
              onChange={e => setDefaultPermissions(e.target.value)}
            />
          )
        }

        <Grid item xs={12}>
          { isCustom && (
            <UserPermissions
              permissions={rolePermissions}
              onSetPermissions={e => setRolePermissions(e)}
            />
          )}
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            {
              onModify ? (
                <UpdateButton
                  onClickPromise={() => doCommitPromise(
                    updateUserRoleMutation,
                    {
                      userRoleId: userRole.id,
                      rolePermissions,
                    },
                    () => (close && close()),
                    () => null,
                  )}
                />
              ) : (
                <AddButton
                  onClickPromise={() => doCommitPromise(
                    createUserRoleMutation,
                    {
                      name: members.roleName,
                      defaultPermissions,
                      rolePermissions,
                    },
                    () => {
                      onAddUserRole && onAddUserRole();
                      close && close();
                    },
                    () => null,
                  )}
                />
              )
            }

          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}

const query = graphql`
  query UserRoleFormQuery (
    $userRoleId: ID!
  ) {
    viewer {
      userRoles(id: $userRoleId) {
        edges {
          node {
            id
            name
            rolePermissions(orderBy: "resource,verb") {
              edges {
                node {
                  resource
                  verb
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UserRoleModifyForm = createQueryRendererProgressV2(
  query,
  UserRoleAddForm,
  (props, outerProps) => ({
    userRole: isRecurNull(outerProps, ['viewer', 'userRoles', 'edges', 0, 'node']),
  }),
  props => ({
    userRoleId: props.userRole.id,
    // Trying to get empty data rather than non-filter-all data if role not specified
  }),
);
