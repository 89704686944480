import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { useCommonStyles } from '../common/Styles';
import PopupTenantSelectorField from '../common/PopupTenantSelectorField';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import addTenantVueSharePermission from '../../mutations/AddTenantVueSharePermission';


function VueSharePermissionAddForm(props) {
  const {
    ownerTenant: ownerTenantIn,
    onAdd,
  } = props;
  const classes = useCommonStyles();
  const [members] = React.useState({
    ownerTenant: ownerTenantIn,
    guestTenant: null,
  });
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add Vue Share Permission"
        />

        {/* Owner tenant*/}
        <Grid item xs={12}>
          <PopupTenantSelectorField
            fullWidth
            caption="Owner Tenant"
            onSelect={(newTen) => {
              members.ownerTenant = newTen;
            }}
          />
        </Grid>

        {/* Guest tenant*/}
        <Grid item xs={12}>
          <PopupTenantSelectorField
            fullWidth
            caption="Guest Tenant"
            onSelect={(newTen) => {
              members.guestTenant = newTen;
            }}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                addTenantVueSharePermission,
                {
                  ownerTenantId: members.ownerTenant.id,
                  guestTenantId: members.guestTenant.id,
                },
                () => (onAdd && onAdd()),
                () => null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}

export default VueSharePermissionAddForm;
