import React from 'react';
import Price from '../../common/Price';


export default function StockOrderQuantityStatus(props) {
  const { stockOrder } = props;
  const {
    quantity,
    cashQuantity,
    quantityToDate,
    brokerOrder,
    contract,
  } = stockOrder;

  if (quantity === null) {
    const sign = -cashQuantity / Math.abs(cashQuantity);
    return (
      <>
        {quantityToDate}
        /
        {brokerOrder.quantityFilled ? brokerOrder.quantityFilled * sign : 0}
        /
        <Price currency={contract.currency}>{cashQuantity}</Price>
      </>
    );
  }

  const sign = quantity / Math.abs(quantity);
  return (
    <>
      {quantityToDate}
      /
      {brokerOrder.quantityFilled ? brokerOrder.quantityFilled * sign : 0}
      /
      {quantity}
    </>
  );
}
