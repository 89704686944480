const downloadFileContent = (content, contentType, fileName) => {
  const file = new Blob([content], { type: contentType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = fileName;
  link.click();
  link.remove();
};

export default downloadFileContent;
