import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Price from './Price';


export default function MultiCurrencyValue(props) {
  const { valueNode, decimalPlaces, suppressZero } = props;
  let resultSet = [...valueNode.edges];
  resultSet.sort((a, b) => {
    if (a.node.currency < b.node.currency) {
      return -1;
    }
    return 1;
  });

  if (suppressZero) {
    resultSet = resultSet.filter(number => number.node.quantity !== 0.0);
  }

  return (
    <Typography variant="caption">
      {
        resultSet.map(
          (resultNode, i) => (
            <span>
              {i !== 0 && <span>&nbsp;&bull;&nbsp;</span>}
              <Price
                currency={resultNode.node.currency}
                decimalPlaces={decimalPlaces}
              >
                {resultNode.node.quantity}
              </Price>
            </span>
          ),
        )
      }
    </Typography>
  );
}

MultiCurrencyValue.propTypes = {
  decimalPlaces: PropTypes.number,
  suppressZero: PropTypes.bool,
};

MultiCurrencyValue.defaultProps = {
  decimalPlaces: 4, // or 2
  suppressZero: false,
};
