/**
 * @flow
 * @relayHash 6793e72b7e1e0cb7dc69ffac47a376f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProcessBrokerTransactionMutationVariables = {|
  allowSweepGroups?: ?boolean,
  brokerTransactionIds?: ?$ReadOnlyArray<?string>,
|};
export type ProcessBrokerTransactionMutationResponse = {|
  +processBrokerTransaction: ?{|
    +brokerTransactions: ?$ReadOnlyArray<?{|
      +id: string,
      +ignore: boolean,
      +matched: boolean,
      +updatedAt: any,
    |}>
  |}
|};
export type ProcessBrokerTransactionMutation = {|
  variables: ProcessBrokerTransactionMutationVariables,
  response: ProcessBrokerTransactionMutationResponse,
|};
*/


/*
mutation ProcessBrokerTransactionMutation(
  $allowSweepGroups: Boolean
  $brokerTransactionIds: [ID]
) {
  processBrokerTransaction(allowSweepGroups: $allowSweepGroups, brokerTransactionIds: $brokerTransactionIds) {
    brokerTransactions {
      id
      ignore
      matched
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "allowSweepGroups",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerTransactionIds",
    "type": "[ID]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "processBrokerTransaction",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "allowSweepGroups",
        "variableName": "allowSweepGroups"
      },
      {
        "kind": "Variable",
        "name": "brokerTransactionIds",
        "variableName": "brokerTransactionIds"
      }
    ],
    "concreteType": "ProcessBrokerTransaction",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerTransactions",
        "storageKey": null,
        "args": null,
        "concreteType": "IBBrokerTransactionNode",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "ignore",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "matched",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ProcessBrokerTransactionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ProcessBrokerTransactionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ProcessBrokerTransactionMutation",
    "id": null,
    "text": "mutation ProcessBrokerTransactionMutation(\n  $allowSweepGroups: Boolean\n  $brokerTransactionIds: [ID]\n) {\n  processBrokerTransaction(allowSweepGroups: $allowSweepGroups, brokerTransactionIds: $brokerTransactionIds) {\n    brokerTransactions {\n      id\n      ignore\n      matched\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ec7e94c94c2e386f12dc4ea30811565d';
module.exports = node;
