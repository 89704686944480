import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import CloseButton from '../common/buttons/CloseButton';
import BaseButton, { SaveButton } from '../common/buttons/BaseButton';
import PopupContractSelectorField from '../common/PopupContractSelectorField';
import DrawerGridItemDateField from '../common/drawer/DrawerGridItemDateField';
import DrawerGridItemTextArea from '../common/drawer/DrawerGridItemTextArea';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';
import EventElectionOptionItem from './EventElectionOptionItem';

export default function EventAddForm(props) {
  const {
    close,
    onAddEvent,
  } = props;
  const classes = useCommonStyles();

  const [event, setEvent] = React.useState('');
  const [contract, setContract] = React.useState('');
  const [eventType, setEventType] = React.useState('');
  const [announcementDate, setAnnouncementDate] = React.useState(null);
  const [cutOffDate, setCutOffDate] = React.useState(null);
  const [description, setDescription] = React.useState('');
  const [showElectionOption, setShowElectionOption] = React.useState(false);

  const eventAddPromise = () => {
    let mutation;
    
    // mutations here
  };

  return (
    <div className={classes.drawerRootExtended}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add New Event"
        />
        <DrawerGridItemSelectField
          xs={6}
          label="Event"
          enumType={[
            {
              name: 'voluntary',
              description: 'Voluntary',
            },
            {
              name: 'mandatory',
              description: 'Mandatory',
            },
            {
              name: 'income',
              description: 'Income',
            },
          ]}
          value={event}
          onChange={e => setEvent(e.target.value)}
        />

        <Grid item xs={6}>
          <PopupContractSelectorField
            fullWidth
            caption="Contract"
            onSelect={_contract => setContract(_contract)}
          />
        </Grid>

        <DrawerGridItemSelectField
          label="Event type"
          enumType={[
            {
              name: 'spp',
              description: 'Stock Purchase Plan',
            },
          ]}
          value={eventType}
          onChange={e => setEventType(e.target.value)}
        />
        <Grid item xs={6}>
          <Grid container spacing={1}>
            <DrawerGridItemDateField
              xs={6}
              label="Announcement date"
              value={announcementDate}
              onChange={date => setAnnouncementDate(date)}
              autoOk
            />
            <DrawerGridItemDateField
              xs={6}
              label="Cut off date"
              value={cutOffDate}
              onChange={date => setCutOffDate(date)}
              autoOk
              disablePast
            />
          </Grid>
        </Grid>
        <Grid item />
        <DrawerGridItemTextArea
          label="Description"
          maxRows={12}
          minRows={12}
          defaultValue={description}
          onChange={e => setDescription(e.target.value)}
        />
        
        <DrawerGridItemCheckBox
          xs={6}
          labelPlacement="start"
          label="Show election options"
          value={showElectionOption}
          onChange={e => setShowElectionOption(e.target.checked)}
        />

        {showElectionOption && (
          <React.Fragment>
            <Grid item xs={6} alignContent="center" justifyContent="flex-end" style={{ display: 'inline-grid' }}>
              <BaseButton buttonText="Add" color="primary" onClick={() => {}} />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={1}>No.</Grid>
                <Grid item xs={5}>Description</Grid>
                <Grid item xs={2}>Show input?</Grid>
                <Grid item xs={2}>Default?</Grid>
                <Grid item xs={2}>&nbsp;</Grid> 
              </Grid>
              <EventElectionOptionItem />
            </Grid>
          </React.Fragment>
        )}

        
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <CloseButton onClick={close} />
          </Grid>
          <Grid item>
            <SaveButton
              onClickPromise={() => eventAddPromise()}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}
