import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableFieldMeta from '../../common/TableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import TableButton from '../common/buttons/TableButton';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { UserRoleAddForm, UserRoleDrawerContainer, UserRoleModifyForm } from './UserRoleForm';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';

function UserRoleList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();

  const [addUserRoleOpen, setAddUserRoleOpen] = React.useState(false);
  const [updateUserRoleOpen, setUpdateUserRoleOpen] = React.useState(false);
  const [userRoleForUpdate, setUserRoleForUpdate] = React.useState(null);

  const columns = [ 
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Name'),
    TableFieldMeta.createdAt,
    TableFieldMeta.updatedAt,
    TableFieldMeta.customCell('', '', row => (
      <TableButton
        color="primary"
        onClick={() => {
          setUserRoleForUpdate(row.value);
          setUpdateUserRoleOpen(true);
        }}
      >
      Update
      </TableButton>
    )),
  ];

  return (
    <div className={classes.innerTabRoot}>

      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Drawer
            anchor="right"
            open={addUserRoleOpen}
            onClose={() => { setAddUserRoleOpen(false); }}
          >
            <UserRoleDrawerContainer>
              <UserRoleAddForm
                close={() => setAddUserRoleOpen(false)}
                onAddUserRole={() => filter.update()}
              />
            </UserRoleDrawerContainer>
          </Drawer>

          <Drawer
            anchor="right"
            open={updateUserRoleOpen}
            onClose={() => { setUpdateUserRoleOpen(false); }}
          >
            <UserRoleDrawerContainer>
              <UserRoleModifyForm
                userRole={userRoleForUpdate}
                close={() => setUpdateUserRoleOpen(false)}
                onUpdateUserRole={() => filter.update()}
              />
            </UserRoleDrawerContainer>
          </Drawer>

          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.userRoles : null)}
          />

        </div>
      </Paper>
      <BottomGridContainer>
        <Grid item>
          <AddButton
            onClick={() => setAddUserRoleOpen(true)}
            buttonText="User Role"
          />
        </Grid>
      </BottomGridContainer>
    </div>
  );
}

const query = graphql`
  query UserRoleListQuery (
    $first: Int
    $offset: Int
  ) {
    viewer {
      ...UserRoleList_viewer
      @arguments (
        first: $first
        offset: $offset
      )
    }
  }
`;

const container = createRefetchContainer(
  UserRoleList,
  {
    viewer: graphql`
      fragment UserRoleList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
      ) {
        userRoles(
          first: $first,
          offset: $offset,
          orderBy: "-id"
        ) {
          edges {
            node {
              id
              name
              createdAt
              updatedAt
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }      
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
));
