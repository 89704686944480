import React from 'react';
import {
  graphql,
  createFragmentContainer,
} from 'react-relay';

import FileSaver from 'file-saver';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Decimal } from 'decimal.js';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { fromGlobalId, toLocal } from '../../helpers/ID';
import AccountLink from '../common/links/AccountLink';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { ExportButton } from '../common/buttons/BaseButton';
import StatelessDownloadButton from '../common/buttons/StatelessDownloadButton';


function DailyHoldingsList(props) {
  const classes = useCommonStyles();
  const {
    reportDate,
    contractCaption,
    onUpdatedContract,
  } = props;
  const { viewer } = props;
  const totalQuantity = viewer.contractHoldings.holdings.reduce(
    (v, c) => v.plus(new Decimal(c.quantity)),
    new Decimal(0),
  );

  let holdings = [];
  if (!viewer.contractHoldings) {
    holdings = [];
  } else {
    const { holdings: holdingsIn } = viewer.contractHoldings;
    holdings = holdingsIn;
  }

  const { contract: selectedContract } = viewer.contractHoldings;
  const { latestContract } = viewer.contractHoldings;
  if (onUpdatedContract) {
    onUpdatedContract(selectedContract);
  }

  const exportEntries = () => {
    let csvContent = 'AccountID,PortfolioID,Quantity,ResponsibleQuantity\n';
    holdings.forEach((holding) => {
      const portfolioId = fromGlobalId(holding.portfolio.id)[1];
      const accountId = fromGlobalId(holding.account.id)[1];
      csvContent += `${accountId},${portfolioId},${holding.quantity},${holding.quantity}\n`;
    });
    const fileName = `${contractCaption}.${toLocal(selectedContract.id)}.${reportDate}.csv`;
    const blob = new Blob(
      [csvContent],
      { type: 'text/plain;charset=utf-8' },
    );
    FileSaver.saveAs(blob, fileName);
  };

  let linkPath = `${process.env.REACT_APP_API}/api/admin/exports/holdings`;
  linkPath += `?contractId=${toLocal(selectedContract.id)}&reportDate=${reportDate}`;
  
  return (
    <div className={classes.innerTabRoot}>
      { latestContract.id !== selectedContract.id && (
        <Alert severity="warning" className={classes.formControl}>
          This contract has already undergone a contract change and/or a merger.
          The latest active contract is now
          {` ${latestContract.symbol} (${toLocal(latestContract.id)}).`}
        </Alert>
      )}
      <div>
        <Typography variant="subtitle2">
          {`Total holdings: ${totalQuantity}`}
        </Typography>
      </div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell align="right">Account ID</TableCell>
            <TableCell align="right">Portfolio</TableCell>
            <TableCell align="right">Quantity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { holdings.map(holding => (
            <TableRow>
              <TableCell><AccountLink account={holding.account} charLimit={50} /></TableCell>
              <TableCell align="right">{fromGlobalId(holding.account.id)[1]}</TableCell>
              <TableCell align="right">{fromGlobalId(holding.portfolio.id)[1]}</TableCell>
              {/*<TableCell align="right">{fromGlobalId(holding.contract.id)[1]}</TableCell>*/}
              <TableCell align="right">{holding.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <BottomGridContainer>
        <Grid item>
          <StatelessDownloadButton
            buttonType="export"
            buttonText="Export with Value"
            downloadUrl={linkPath}
          />
        </Grid>
        <Grid item>
          <ExportButton
            onClick={() => exportEntries()}
          />
        </Grid>
      </BottomGridContainer>
    </div>
  );
}

const query = graphql`
  query DailyHoldingsListQuery (
    $date: Date!,
    $contractId: ID
    $ibContractId: Int
  ) {
    viewer {
      ...DailyHoldingsList_viewer
      @arguments (
        date: $date,
        contractId: $contractId,
        ibContractId: $ibContractId
      )
    }
  }
`;

const container = createFragmentContainer(
  DailyHoldingsList,
  {
    viewer: graphql`
      fragment DailyHoldingsList_viewer on Query
      @argumentDefinitions(
        date: {type: Date, defaultValue: "2019-07-24"}
        contractId: {type: ID, defaultValue: null}
        ibContractId: {type: Int, defaultValue: null}
      ) {
        contractHoldings(
          date: $date,
          contractId: $contractId,
          ibContractId: $ibContractId
        ) {
          holdings {
            account {
              name
              id
            }
            portfolio {
              id
            }
            contract {
              id
            }
            quantity
          }
          contract {
            id
            symbol
            currency
            name
            ibConId
          }
          
          latestContract {
            id
            symbol
            currency
            name
          }
          
        }
      }
    `,
  },
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, {
    date,
    contractId,
    contractCaption,
    onUpdatedContract,
  }) => ({
    reportDate: date,
    contractId,
    contractCaption,
    onUpdatedContract,
  }),
  ({ date, contractId, ibContractId }) => ({ date, contractId, ibContractId }),
);
