/**
 * @flow
 * @relayHash 229f2b4409914a6ca4b3c5b6e7f5949e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActionLogList_viewer$ref = any;
export type ActionLogListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  userId?: ?string,
  forUserId?: ?string,
  actionTypeIn?: ?$ReadOnlyArray<?string>,
  actionIdIn?: ?$ReadOnlyArray<?number>,
|};
export type ActionLogListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ActionLogList_viewer$ref
  |}
|};
export type ActionLogListQuery = {|
  variables: ActionLogListQueryVariables,
  response: ActionLogListQueryResponse,
|};
*/


/*
query ActionLogListQuery(
  $first: Int
  $offset: Int
  $userId: ID
  $forUserId: ID
  $actionTypeIn: [String]
  $actionIdIn: [Int]
) {
  viewer {
    ...ActionLogList_viewer_2a8ddY
    id
  }
}

fragment ActionLogList_viewer_2a8ddY on Query {
  actionLogUserRequests(first: $first, offset: $offset, actionType_In: $actionTypeIn, orderBy: "-actionTime", userId: $userId, forUserId: $forUserId, actionId_In: $actionIdIn) {
    edges {
      node {
        id
        actionTime
        actionType
        actionId
        actionDescription
        ipAddress
        userAgent
        user {
          id
          fullName
          email
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "forUserId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "actionTypeIn",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "actionIdIn",
    "type": "[Int]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "forUserId",
  "variableName": "forUserId"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ActionLogListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ActionLogList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "actionIdIn",
                "variableName": "actionIdIn"
              },
              {
                "kind": "Variable",
                "name": "actionTypeIn",
                "variableName": "actionTypeIn"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ActionLogListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "actionLogUserRequests",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "actionId_In",
                "variableName": "actionIdIn"
              },
              {
                "kind": "Variable",
                "name": "actionType_In",
                "variableName": "actionTypeIn"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-actionTime"
              },
              (v4/*: any*/)
            ],
            "concreteType": "ActionLogUserRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ActionLogUserRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ActionLogUserRequestNode",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionTime",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "actionDescription",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ipAddress",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "userAgent",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ActionLogListQuery",
    "id": null,
    "text": "query ActionLogListQuery(\n  $first: Int\n  $offset: Int\n  $userId: ID\n  $forUserId: ID\n  $actionTypeIn: [String]\n  $actionIdIn: [Int]\n) {\n  viewer {\n    ...ActionLogList_viewer_2a8ddY\n    id\n  }\n}\n\nfragment ActionLogList_viewer_2a8ddY on Query {\n  actionLogUserRequests(first: $first, offset: $offset, actionType_In: $actionTypeIn, orderBy: \"-actionTime\", userId: $userId, forUserId: $forUserId, actionId_In: $actionIdIn) {\n    edges {\n      node {\n        id\n        actionTime\n        actionType\n        actionId\n        actionDescription\n        ipAddress\n        userAgent\n        user {\n          id\n          fullName\n          email\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98858743b3d501f7f309ce5042045a13';
module.exports = node;
