import React from 'react';
import Grid from '@material-ui/core/Grid';


function DrawerGridItemContainer(props) {
  const { children } = props;
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}

export default DrawerGridItemContainer;
