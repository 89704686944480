/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderApprovalStatus = "CA" | "CD" | "EX" | "IN" | "OA" | "OD" | "RR" | "SA" | "SFA" | "%future added value";
export type OrderProposalType = "BT" | "IN" | "PR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderApprovalList_viewer$ref: FragmentReference;
declare export opaque type OrderApprovalList_viewer$fragmentType: OrderApprovalList_viewer$ref;
export type OrderApprovalList_viewer = {|
  +orderApprovals: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +proposal: {|
          +id: string,
          +type: OrderProposalType,
        |},
        +id: string,
        +createdAt: any,
        +status: OrderApprovalStatus,
        +portfolio: {|
          +id: string,
          +trackingVueName: ?string,
          +account: {|
            +name: ?string,
            +id: string,
          |},
        |},
        +portfolioOrder: ?{|
          +id: string
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +$refType: OrderApprovalList_viewer$ref,
|};
export type OrderApprovalList_viewer$data = OrderApprovalList_viewer;
export type OrderApprovalList_viewer$key = {
  +$data?: OrderApprovalList_viewer$data,
  +$fragmentRefs: OrderApprovalList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderApprovalList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statusIn",
      "type": "[String]",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "proposalId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "orderApprovals",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "account",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "proposal",
          "variableName": "proposalId"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "statusIn",
          "variableName": "statusIn"
        }
      ],
      "concreteType": "OrderApprovalNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "OrderApprovalNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "OrderApprovalNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "proposal",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderProposalNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "type",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolio",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trackingVueName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "account",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AccountNode",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        },
                        (v0/*: any*/)
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae2a818080a088442dbe8da996c74814';
module.exports = node;
