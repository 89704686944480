import { graphql } from 'react-relay';

const updateEmailRecipient = graphql`
  mutation UpdateEmailRecipientMutation (
    $clientAccountId: ID!
    $userModelId: ID!
    $receivesEmail: Boolean!
  ) {
    updateEmailRecipient(
      clientAccountId: $clientAccountId,
      userModelId: $userModelId,
      receivesEmail: $receivesEmail
    ) {
      activeEmailRecipients {
        id
      }
    }
  }
`;

export default updateEmailRecipient;
