import React from 'react';
import TableFieldMeta from '../../common/TableFieldMeta';
import IgnoreAction from './IgnoreActions';


export default class BrokerActivityTableFieldMeta {}


BrokerActivityTableFieldMeta.symbol = TableFieldMeta.field(
  'symbol', 'Symbol', { width: 85, align: 'center' },
);

BrokerActivityTableFieldMeta.isin = TableFieldMeta.field(
  'isin', 'ISIN', { width: 120, align: 'center' },
);

BrokerActivityTableFieldMeta.currency = TableFieldMeta.field(
  'currency', 'Currency', { width: 50, align: 'center' },
);

BrokerActivityTableFieldMeta.reportDate = TableFieldMeta.field(
  'reportDate', 'Report Date', { width: 90, align: 'center' },
);

BrokerActivityTableFieldMeta.conId = TableFieldMeta.field(
  'ibConId', 'Con ID', { width: 90, align: 'right' },
);

BrokerActivityTableFieldMeta.quantity = TableFieldMeta.field(
  'quantity', 'Quantity', { width: 90, align: 'right' },
);


BrokerActivityTableFieldMeta.ignore = msgRouter => ({
  id: 'ignoreAction',
  Header: 'Ignore',
  accessor: node => node,
  Cell: row => <IgnoreAction brokerActivity={row.value} msgRouter={msgRouter} />,
});
