import React from 'react';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DrawerSubGroupHeading from '../common/drawer/DrawerSubGroupHeading';
import DrawerSubGroupDivider from '../common/drawer/DrawerSubGroupDivider';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemCurrencyField from '../common/drawer/DrawerGridItemCurrencyField';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';

export default function PositionTransferPurchase(props) {
  const classes = useCommonStyles();
  const {
    purchase,
    currency,
    updatePurchase,
    heading,
    editMode,
  } = props;
  const [costBasis, setCostBasis] = React.useState(purchase.costBasis);
  const [commission, setCommission] = React.useState(purchase.commission);
  const [purchaseDate, setPurchaseDate] = React.useState(purchase.purchaseDate);
  const [quantity, setQuantity] = React.useState(purchase.quantity);
  const [dummy, setDummy] = React.useState(purchase.dummy);

  const doUpdatePurchase = () => {
    if (updatePurchase) {
      const newPurchase = {
        id: purchase.id,
        index: purchase.index,
        quantity,
        purchaseDate,
        currency,
        costBasis,
        commission,
        dummy,
      };
      updatePurchase(
        purchase,
        newPurchase,
      );
    }
  };

  React.useEffect(() => {
    doUpdatePurchase();
  }, [costBasis, commission, purchaseDate, quantity, dummy]);

  const handlePurchaseDate = (purchaseDateTime) => {
    let purchaseDateValue = null;
    if (purchaseDateTime !== null && purchaseDateTime !== '') {
      purchaseDateValue = moment(purchaseDateTime).format(MOMENT_DATE_FORMAT_GRAPHQL);
    }
    setPurchaseDate(purchaseDateValue);
  };

  return (
    <>
      <DrawerSubGroupDivider />

      <Grid item xs={12}>
        <DrawerSubGroupHeading>
          {heading}
        </DrawerSubGroupHeading>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormControl>
          <TextField
            className={classes.fromControlDrawer}
            id="quantity"
            label="Quantity"
            type="number"
            defaultValue={quantity}
            disabled={editMode}
            onChange={e => setQuantity(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {/*Report Data*/}
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            id="date-picker-inline"
            label="Purchase Date"
            value={purchaseDate}
            disableFuture
            disabled={editMode}
            onChange={handlePurchaseDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <DrawerGridItemCurrencyField
        label="Cost Basis"
        defaultValue={purchase.costBasis}
        currency={currency}
        onChange={(e) => {
          setCostBasis(e.target.value);
        }}
      />
      <DrawerGridItemCurrencyField
        label="Commission"
        defaultValue={purchase.commission}
        currency="AUD"
        onChange={(e) => {
          setCommission(e.target.value);
        }}
      />
      <DrawerGridItemCheckBox
        label="Dummy"
        onChange={e => setDummy(e.target.checked)}
        value={dummy}
      />
    </>
  );
}
