import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import AddDepositForm from './AddDepositForm';
import AddWithdrawalForm from './AddWithdrawalForm';
import CashTransferRequestList from './CashTransferRequestList';
import MsgRouter from '../../common/MsgRouter';

function CashTransferMatchPane(props) {
  const {
    accountId,
    accountType,
    custodian,
    contraCustodian,
    contraOnly,
    brokerCashTransactionList,
  } = props;
  const leftPaneUnits = 9; // out of 12
  const account = accountId ? { name: 'Selected.. ', id: accountId } : null;
  const [showDepositDrawer, setShowDepositDrawer] = React.useState(false);
  const [showWithdrawalDrawer, setShowWithdrawalDrawer] = React.useState(false);
  const [members] = React.useState({
    msgRouter: new MsgRouter(),
    refreshRequestSeq: 0,
  });

  const BrokerCashTransferList = brokerCashTransactionList;

  const refreshTransferRequests = () => {
    members.refreshRequestSeq += 1;
    members.msgRouter.publishState(this, { refreshRequestSeq: members.refreshRequestSeq });
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={showDepositDrawer}
        onClose={() => setShowDepositDrawer(false)}
      >
        <AddDepositForm
          onClose={() => setShowDepositDrawer(false)}
          onAdd={() => refreshTransferRequests()}
          account={account}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={showWithdrawalDrawer}
        onClose={() => setShowWithdrawalDrawer(false)}
      >
        <AddWithdrawalForm
          onClose={() => setShowWithdrawalDrawer(false)}
          onAdd={() => refreshTransferRequests()}
          account={account}
        />
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={12} md={leftPaneUnits}>
          <CashTransferRequestList
            accountId={accountId}
            msgRouter={members.msgRouter}
            accountType={accountType}
            urlParamPrefix="tr."
            custodian={custodian}
            contraCustodian={contraCustodian}
            contraOnly={contraOnly}
          />
        </Grid>
        <Grid item xs={12} md={12 - leftPaneUnits}>
          <BrokerCashTransferList
            msgRouter={members.msgRouter}
            urlParamPrefix="ibtr."
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(CashTransferMatchPane);
