/**
 * @flow
 * @relayHash 3065367ec4407fd12d9240a970d7c341
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OffMarketTransferList_viewer$ref = any;
export type OffMarketTransferListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  accountId?: ?string,
|};
export type OffMarketTransferListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: OffMarketTransferList_viewer$ref
  |}
|};
export type OffMarketTransferListQuery = {|
  variables: OffMarketTransferListQueryVariables,
  response: OffMarketTransferListQueryResponse,
|};
*/


/*
query OffMarketTransferListQuery(
  $first: Int
  $offset: Int
  $search: String
  $accountId: String
) {
  viewer {
    ...OffMarketTransferList_viewer_13Yiz5
    id
  }
}

fragment OffMarketTransferList_viewer_13Yiz5 on Query {
  offMarketTransfers(first: $first, offset: $offset, orderBy: "-createdAt", search: $search, accountOrCounterpartyAccount: $accountId) {
    count
    edges {
      node {
        id
        account {
          name
          id
        }
        counterpartyAccount {
          name
          id
        }
        offmarkettransfercashquantitySet {
          edges {
            node {
              id
              currency
              quantity
              updatedAt
            }
          }
        }
        offmarkettransferholdingquantitySet {
          edges {
            node {
              id
              contract {
                id
                symbol
                currency
              }
              costBasis
              commission
              dummy
              portfolio {
                id
                trackingVue {
                  name
                  id
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  (v4/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketTransferListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OffMarketTransferList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketTransferListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "offMarketTransfers",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "accountOrCounterpartyAccount",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v3/*: any*/)
            ],
            "concreteType": "OffMarketTransferNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OffMarketTransferNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OffMarketTransferNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "counterpartyAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "offmarkettransfercashquantitySet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OffMarketTransferCashQuantityNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OffMarketTransferCashQuantityNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "OffMarketTransferCashQuantityNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updatedAt",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "offmarkettransferholdingquantitySet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OffMarketTransferHoldingQuantityNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OffMarketTransferHoldingQuantityNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "OffMarketTransferHoldingQuantityNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "contract",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ContractNode",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "symbol",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "costBasis",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "commission",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "dummy",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "portfolio",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "PortfolioNode",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "trackingVue",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "VueNode",
                                        "plural": false,
                                        "selections": (v5/*: any*/)
                                      }
                                    ]
                                  },
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OffMarketTransferListQuery",
    "id": null,
    "text": "query OffMarketTransferListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $accountId: String\n) {\n  viewer {\n    ...OffMarketTransferList_viewer_13Yiz5\n    id\n  }\n}\n\nfragment OffMarketTransferList_viewer_13Yiz5 on Query {\n  offMarketTransfers(first: $first, offset: $offset, orderBy: \"-createdAt\", search: $search, accountOrCounterpartyAccount: $accountId) {\n    count\n    edges {\n      node {\n        id\n        account {\n          name\n          id\n        }\n        counterpartyAccount {\n          name\n          id\n        }\n        offmarkettransfercashquantitySet {\n          edges {\n            node {\n              id\n              currency\n              quantity\n              updatedAt\n            }\n          }\n        }\n        offmarkettransferholdingquantitySet {\n          edges {\n            node {\n              id\n              contract {\n                id\n                symbol\n                currency\n              }\n              costBasis\n              commission\n              dummy\n              portfolio {\n                id\n                trackingVue {\n                  name\n                  id\n                }\n              }\n              quantity\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3925379a71841cd9be243b52ef89bb89';
module.exports = node;
