/**
 * @flow
 * @relayHash c7c78464074c05ab8e57cb881bd252b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BrokerActivityIgnoreMutationVariables = {|
  brokerActivityId?: ?string,
  ignore: boolean,
  brokerActivityIds?: ?$ReadOnlyArray<?string>,
|};
export type BrokerActivityIgnoreMutationResponse = {|
  +ignoreBrokerActivity: ?{|
    +transfer: ?{|
      +id?: string,
      +ignored?: boolean,
      +processedAt?: ?any,
      +updatedAt?: any,
      +processedBy?: ?{|
        +id: string,
        +fullName: ?string,
      |},
    |},
    +cashTransfer: ?{|
      +id?: string,
      +ignored?: boolean,
      +processedAt?: ?any,
      +updatedAt?: any,
      +processedBy?: ?{|
        +id: string,
        +fullName: ?string,
      |},
    |},
    +corporateAction: ?{|
      +id?: string,
      +ignored?: boolean,
      +processedAt?: ?any,
      +updatedAt?: any,
      +processedBy?: ?{|
        +id: string,
        +fullName: ?string,
      |},
    |},
    +brokerActivities: ?$ReadOnlyArray<?{|
      +id?: string,
      +ignored?: boolean,
      +processedAt?: ?any,
      +updatedAt?: any,
      +processedBy?: ?{|
        +id: string,
        +fullName: ?string,
      |},
    |}>,
  |}
|};
export type BrokerActivityIgnoreMutation = {|
  variables: BrokerActivityIgnoreMutationVariables,
  response: BrokerActivityIgnoreMutationResponse,
|};
*/


/*
mutation BrokerActivityIgnoreMutation(
  $brokerActivityId: ID
  $ignore: Boolean!
  $brokerActivityIds: [ID]
) {
  ignoreBrokerActivity(brokerActivityId: $brokerActivityId, ignore: $ignore, brokerActivityIds: $brokerActivityIds) {
    transfer: brokerActivity {
      __typename
      ... on IBTransferNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on Node {
        id
      }
    }
    cashTransfer: brokerActivity {
      __typename
      ... on IBCashTransactionNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on Node {
        id
      }
    }
    corporateAction: brokerActivity {
      __typename
      ... on IBCorporateActionNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on Node {
        id
      }
    }
    brokerActivities {
      __typename
      ... on IBCorporateActionNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on IBTransferNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on IBCashTransactionNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on IBTransactionTaxNode {
        id
        ignored
        processedAt
        updatedAt
        processedBy {
          id
          fullName
        }
      }
      ... on Node {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerActivityId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignore",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerActivityIds",
    "type": "[ID]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerActivityId",
    "variableName": "brokerActivityId"
  },
  {
    "kind": "Variable",
    "name": "brokerActivityIds",
    "variableName": "brokerActivityIds"
  },
  {
    "kind": "Variable",
    "name": "ignore",
    "variableName": "ignore"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ignored",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "processedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "processedBy",
  "storageKey": null,
  "args": null,
  "concreteType": "UserNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "fullName",
      "args": null,
      "storageKey": null
    }
  ]
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "kind": "InlineFragment",
  "type": "IBTransferNode",
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "InlineFragment",
  "type": "IBCashTransactionNode",
  "selections": (v7/*: any*/)
},
v10 = {
  "kind": "InlineFragment",
  "type": "IBCorporateActionNode",
  "selections": (v7/*: any*/)
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v13 = {
  "kind": "InlineFragment",
  "type": "IBTransferNode",
  "selections": (v12/*: any*/)
},
v14 = {
  "kind": "InlineFragment",
  "type": "IBCashTransactionNode",
  "selections": (v12/*: any*/)
},
v15 = {
  "kind": "InlineFragment",
  "type": "IBCorporateActionNode",
  "selections": (v12/*: any*/)
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityIgnoreMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ignoreBrokerActivity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "IgnoreBrokerActivity",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "transfer",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "cashTransfer",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "corporateAction",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerActivities",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": true,
            "selections": [
              (v10/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "IBTransactionTaxNode",
                "selections": (v7/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityIgnoreMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "ignoreBrokerActivity",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "IgnoreBrokerActivity",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "transfer",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/),
              (v13/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "cashTransfer",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/),
              (v14/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "corporateAction",
            "name": "brokerActivity",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/),
              (v15/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerActivities",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": true,
            "selections": [
              (v11/*: any*/),
              (v2/*: any*/),
              (v15/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "IBTransactionTaxNode",
                "selections": (v12/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityIgnoreMutation",
    "id": null,
    "text": "mutation BrokerActivityIgnoreMutation(\n  $brokerActivityId: ID\n  $ignore: Boolean!\n  $brokerActivityIds: [ID]\n) {\n  ignoreBrokerActivity(brokerActivityId: $brokerActivityId, ignore: $ignore, brokerActivityIds: $brokerActivityIds) {\n    transfer: brokerActivity {\n      __typename\n      ... on IBTransferNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n    cashTransfer: brokerActivity {\n      __typename\n      ... on IBCashTransactionNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n    corporateAction: brokerActivity {\n      __typename\n      ... on IBCorporateActionNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n    brokerActivities {\n      __typename\n      ... on IBCorporateActionNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on IBTransferNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on IBCashTransactionNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on IBTransactionTaxNode {\n        id\n        ignored\n        processedAt\n        updatedAt\n        processedBy {\n          id\n          fullName\n        }\n      }\n      ... on Node {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2aca30d68936fcd2be46279a96cde4af';
module.exports = node;
