/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DailyHoldingsList_viewer$ref: FragmentReference;
declare export opaque type DailyHoldingsList_viewer$fragmentType: DailyHoldingsList_viewer$ref;
export type DailyHoldingsList_viewer = {|
  +contractHoldings: ?{|
    +holdings: ?$ReadOnlyArray<?{|
      +account: {|
        +name: ?string,
        +id: string,
      |},
      +portfolio: {|
        +id: string
      |},
      +contract: {|
        +id: string
      |},
      +quantity: number,
    |}>,
    +contract: {|
      +id: string,
      +symbol: string,
      +currency: Currency,
      +name: ?string,
      +ibConId: number,
    |},
    +latestContract: {|
      +id: string,
      +symbol: string,
      +currency: Currency,
      +name: ?string,
    |},
  |},
  +$refType: DailyHoldingsList_viewer$ref,
|};
export type DailyHoldingsList_viewer$data = DailyHoldingsList_viewer;
export type DailyHoldingsList_viewer$key = {
  +$data?: DailyHoldingsList_viewer$data,
  +$fragmentRefs: DailyHoldingsList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DailyHoldingsList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "date",
      "type": "Date",
      "defaultValue": "2019-07-24"
    },
    {
      "kind": "LocalArgument",
      "name": "contractId",
      "type": "ID",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "ibContractId",
      "type": "Int",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contractHoldings",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "contractId",
          "variableName": "contractId"
        },
        {
          "kind": "Variable",
          "name": "date",
          "variableName": "date"
        },
        {
          "kind": "Variable",
          "name": "ibContractId",
          "variableName": "ibContractId"
        }
      ],
      "concreteType": "ContractAccountPosition",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "holdings",
          "storageKey": null,
          "args": null,
          "concreteType": "EndOfDayAccountPosition",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "account",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "portfolio",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioNode",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "contract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": (v2/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contract",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "ibConId",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "latestContract",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v0/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '83c1926f96d4fe9651fe193adecece18';
module.exports = node;
