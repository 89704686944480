import Paper from '@material-ui/core/Paper';
import ReactTable from 'react-table';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { commonStyles } from '../Styles';
import TablePaginationActions from '../pagination/TablePaginationActions';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  reactTableWrapper: {
  },
}));

function RTTableRenderer(props) {
  const {
    onFetchData,
    page: loadedPage,
    rowsPerPage,
    count,
    isLoading,
    columns,
    data,
  } = props;

  const classes = useStyles();

  const doFetchData = (state, instance) => {
    if (loadedPage !== state.page || rowsPerPage !== state.pageSize) {
      onFetchData(state, instance);
    }
  };

  const pages = Math.ceil(count / rowsPerPage);

  return (
    <div className={classes.reactTableWrapper}>
      <Paper style={{ overflow: 'auto' }}>
        <ReactTable
          manual
          columns={columns}
          data={data}
          defaultPageSize={rowsPerPage}
          pageSize={rowsPerPage}
          pages={pages}
          page={loadedPage}
          pageSizeOptions={TablePaginationActions.ROW_COUNT_OPTIONS}
          onFetchData={doFetchData}
          loading={isLoading}
        />
      </Paper>
    </div>
  );
}

export default RTTableRenderer;
