/* eslint-disable camelcase */
import moment from 'moment';
import { MOMENT_TIME_FORMAT } from './constants';

export default function dtf(inTime, timezoneRegion) {
  if (timezoneRegion) {
    return moment(inTime).tz(timezoneRegion).format(MOMENT_TIME_FORMAT);
  }
  return moment(inTime).format(MOMENT_TIME_FORMAT);
}

export function hrFileSize(fileSizeInBytes) {
  let hrSize = fileSizeInBytes;
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    hrSize /= 1024;
    i += 1;
  } while (hrSize > 1024);

  return Math.max(hrSize, 0.1).toFixed(1) + byteUnits[i];
}

export function getUserDisplayName(node) {
  return `${node.firstName} ${node.lastName}`;
}

export const usedCurrencies = [
  'AUD', 'CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD',
  'HUF', 'ILS', 'JPY', 'KRW', 'MXN', 'NOK', 'NZD', 'PLN',
  'RUB', 'SEK', 'SGD', 'USD', 'ZAR', 'CNH', 'CNY'];

const currencyFormatters = {};
usedCurrencies.map(
  (currency) => {
    const formatter4 = new Intl.NumberFormat(navigator.language || 'en-AU', { style: 'currency', minimumFractionDigits: 4, currency });
    const formatter2 = new Intl.NumberFormat(navigator.language || 'en-AU', { style: 'currency', minimumFractionDigits: 2, currency });
    currencyFormatters[currency] = { 2: formatter2, 4: formatter4 };
    return null;
  },
);

export function toCurrencyFormat(value, inCurrency, decimalPlaces = 4) {
  const currency = inCurrency || 'AUD';
  const formatter = currencyFormatters[currency][decimalPlaces];
  return formatter.format(value);
}

export function recursiveRefer(varAccessPath, dataNode, idx = 0) {
  if (typeof varAccessPath === 'string' || varAccessPath instanceof String) {
    return dataNode[varAccessPath];
  }

  if (typeof varAccessPath === 'function') {
    return varAccessPath(dataNode);
  }

  if (idx >= varAccessPath.length) {
    return dataNode;
  }

  if (dataNode === null || dataNode === undefined) {
    return dataNode;
  }

  return recursiveRefer(varAccessPath, dataNode[varAccessPath[idx]], idx + 1);
}

const format = (addressComponents) => {
  const {
    subpremise = '',
    street_number = '',
    route = '',
    locality = '',
    administrative_area_level_1 = '',
    country = '',
    postal_code = '',
  } = addressComponents || {};

  let addressString = '';
  if (subpremise && subpremise !== 'null') addressString += `${subpremise}/`;
  if (street_number && street_number !== 'null' && street_number !== 'None') addressString += street_number;
  if (route && route !== 'null') addressString += ` ${route}`;
  if (locality && locality !== 'null') addressString += `, ${locality}`;
  if (administrative_area_level_1 && administrative_area_level_1 !== 'null') addressString += `, ${administrative_area_level_1}`;
  if (postal_code && postal_code !== 'null') addressString += ` ${postal_code}`;
  if (country && country !== 'null') addressString += `, ${country}`;

  return addressString;
};

export function renderAddress(addressStr) {
  if (!addressStr) {
    return '';
  }
  return format(JSON.parse(addressStr));
}


export function extractKeys(inObject, keyList) {
  const keyProps = {};
  const otherProps = {};
  for (const [key, value] of Object.entries(inObject)) {
    if (keyList.indexOf(key) !== -1) {
      keyProps[key] = value;
    } else {
      otherProps[key] = value;
    }
  }

  return [keyProps, otherProps];
}


export const makeUploadsTopArray = (fileList, varName = 'files') => {
  const fileTags = fileList.map((f, i) => `"${i}": ["variables.${varName}.${i}"]`);
  const concreteFiles = Object.fromEntries(fileList.map((f, i) => [i, f]));
  return {
    map: `{${fileTags.join(',')}}`,
    ...concreteFiles,
  };
};


export const permitted = (menuItems, permissions, filterIndex) => {
  // return menuItems;
  const givenPermission = new Set(permissions.map(p => p.node.resource));
  return menuItems.filter(
    item => (
      item[filterIndex] == null
      || (item[filterIndex].reduce((v, i) => (givenPermission.has(i) || v), false))),
  );
};

export const readPermitted = (permissions, resource) => permissions.find(
  x => (x.node.resource === resource && (x.node.verb === 'READ' || x.node.verb === 'MUTATE')),
) !== undefined;

export const mutatePermitted = (permissions, resource) => permissions.find(
  x => (x.node.resource === resource && x.node.verb === 'MUTATE'),
) !== undefined;

export const isRecurNull = (variable, spec) => {
  let pointer = variable;
  for (const specKey of spec) {
    if (pointer === null || !(specKey in pointer)) {
      return null;
    }
    pointer = pointer[specKey];
  }
  return pointer;
};
