import React from 'react';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

function CloseButton(props) {
  const classes = useStyles();
  const { children, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.button}
      {...otherProps}
    >
      { children }
      <CancelIcon
        className={classes.rightIcon}
      />
    </Button>
  );
}

CloseButton.propTypes = {
  children: PropTypes.string,
  ...Button.propTypes,
};

CloseButton.defaultProps = {
  children: 'Close',
};

export default CloseButton;
