import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableFieldMeta from '../../common/TableFieldMeta';
import PaginatedTable from '../common/PaginatedTable';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';
import RelayFilter from '../../common/RelayFilter';
import { AddButton } from '../common/buttons/BaseButton';
import TableButton from '../common/buttons/TableButton';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import CannedSecurityQuestionAddForm from './CannedSecurityQuestionAddForm';
import CannedSecurityQuestionUpdateForm from './CannedSecurityQuestionUpdateForm';
import TableStatus from '../common/TableStatus';

function CannedSecurityQuestionList(props) {
  const { viewer, relay } = props;
  const classes = useCommonStyles();
  
  const [addCannedQuestionOpen, setAddCannedQuestionOpen] = React.useState(false);
  const [updateCannedQuestionOpen, setUpdateCannedQuestionOpen] = React.useState(false);
  const [cannedQuestionForUpdate, setCannedQuestionForUpdate] = React.useState(null);
  const [relayFilter] = React.useState(new RelayFilter({}));

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('question', 'Question'),
    TableFieldMeta.customCell('', '', row => (
      <TableStatus
        primary={row.value.enabled}
        secondary={!row.value.enabled}
      >
        {row.value.enabled ? 'Enabled' : 'Disabled'}
      </TableStatus>
    )),
    TableFieldMeta.customCell('', '', row => (
      <TableButton
        color="primary"
        onClick={() => {
          setCannedQuestionForUpdate(row.value);
          setUpdateCannedQuestionOpen(true);
        }}
      >
        Update
      </TableButton>
    )),
  ];

  return (
    <div className={classes.innerTabRoot}>

      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Drawer
            anchor="right"
            open={addCannedQuestionOpen}
            onClose={() => { setAddCannedQuestionOpen(false); }}
          >
            <CannedSecurityQuestionAddForm 
              close={() => { setAddCannedQuestionOpen(false); }}
              onAddCannedSecurityQuestion={() => relayFilter.update()}
            /> 
          </Drawer>

          <Drawer
            anchor="right"
            open={updateCannedQuestionOpen}
            onClose={() => { setUpdateCannedQuestionOpen(false); }}
          >
            <CannedSecurityQuestionUpdateForm
              cannedSecurityQuestion={cannedQuestionForUpdate}
              close={() => { setUpdateCannedQuestionOpen(false); }}
              onUpdateCannedSecurityQuestion={() => relayFilter.update()}
            /> 
          </Drawer>

          <PaginatedTable
            columns={columns}
            viewer={viewer}
            getDataNode={() => (viewer ? viewer.cannedSecurityQuestions : null)}
            getFetchParams={relayFilter.fetchParams}
            relay={relay}
            ref={relayFilter.tableRef}
          />

        </div>
      </Paper>
      <BottomGridContainer>
        <Grid item>
          <AddButton
            onClick={() => setAddCannedQuestionOpen(true)}
            buttonText="Security Question"
          />
        </Grid>
      </BottomGridContainer>
    </div>
  );
}


const query = graphql`
  query CannedSecurityQuestionListQuery (
    $first: Int
    $offset: Int
  ) {
    viewer {
      ...CannedSecurityQuestionList_viewer
      @arguments(
        first: $first
        offset: $offset
      )
    }
  }
`;

const container = createRefetchContainer(
  CannedSecurityQuestionList,
  {
    viewer: graphql`
      fragment CannedSecurityQuestionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
      ) {
        cannedSecurityQuestions(
          first: $first,
          offset: $offset,
          orderBy: "-id"
        ) {
          edges {
            node {
              id
              question
              enabled
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }      
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgress(
  query,
  container,
));
