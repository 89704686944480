/**
 * @flow
 * @relayHash 8f3a7490123f62939bf95024b01f5f70
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PricingEstimatorFeeQueryVariables = {|
  tenantId: string,
  accountId?: ?string,
  contractId: string,
  quantity?: ?number,
  price?: ?number,
  customCommission?: ?number,
  isManagedSystemVue?: ?boolean,
|};
export type PricingEstimatorFeeQueryResponse = {|
  +viewer: ?{|
    +tenants: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +feeEstimate: ?{|
            +refPrice: ?number,
            +feeEntries: ?$ReadOnlyArray<?{|
              +currency: ?string,
              +quantity: ?number,
              +feeType: ?string,
              +description: ?string,
              +tenant: ?{|
                +id: string,
                +name: string,
              |},
            |}>,
          |},
        |}
      |}>
    |}
  |}
|};
export type PricingEstimatorFeeQuery = {|
  variables: PricingEstimatorFeeQueryVariables,
  response: PricingEstimatorFeeQueryResponse,
|};
*/


/*
query PricingEstimatorFeeQuery(
  $tenantId: ID!
  $accountId: ID
  $contractId: ID!
  $quantity: Float
  $price: Float
  $customCommission: Float
  $isManagedSystemVue: Boolean
) {
  viewer {
    tenants(id: $tenantId) {
      edges {
        node {
          id
          feeEstimate(contractId: $contractId, quantity: $quantity, price: $price, customCommission: $customCommission, accountId: $accountId, isManagedSystemVue: $isManagedSystemVue) {
            refPrice
            feeEntries {
              currency
              quantity
              feeType
              description
              tenant {
                id
                name
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "quantity",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "price",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "customCommission",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isManagedSystemVue",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tenants",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "tenantId"
    }
  ],
  "concreteType": "TenantNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TenantNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "feeEstimate",
              "storageKey": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "accountId",
                  "variableName": "accountId"
                },
                {
                  "kind": "Variable",
                  "name": "contractId",
                  "variableName": "contractId"
                },
                {
                  "kind": "Variable",
                  "name": "customCommission",
                  "variableName": "customCommission"
                },
                {
                  "kind": "Variable",
                  "name": "isManagedSystemVue",
                  "variableName": "isManagedSystemVue"
                },
                {
                  "kind": "Variable",
                  "name": "price",
                  "variableName": "price"
                },
                {
                  "kind": "Variable",
                  "name": "quantity",
                  "variableName": "quantity"
                }
              ],
              "concreteType": "FeeEstimationResultNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "refPrice",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "feeEntries",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "FeeEntryNode",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "currency",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantity",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "feeType",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "description",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "tenant",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TenantNode",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "name",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PricingEstimatorFeeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PricingEstimatorFeeQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PricingEstimatorFeeQuery",
    "id": null,
    "text": "query PricingEstimatorFeeQuery(\n  $tenantId: ID!\n  $accountId: ID\n  $contractId: ID!\n  $quantity: Float\n  $price: Float\n  $customCommission: Float\n  $isManagedSystemVue: Boolean\n) {\n  viewer {\n    tenants(id: $tenantId) {\n      edges {\n        node {\n          id\n          feeEstimate(contractId: $contractId, quantity: $quantity, price: $price, customCommission: $customCommission, accountId: $accountId, isManagedSystemVue: $isManagedSystemVue) {\n            refPrice\n            feeEntries {\n              currency\n              quantity\n              feeType\n              description\n              tenant {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b2caa6aa6e8813871b67558ef044e4d';
module.exports = node;
