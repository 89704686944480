/**
 * @flow
 * @relayHash 2794436eff8a41bdee9a1174cd53b0d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type TenantAccountQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  tenantId?: ?string,
|};
export type TenantAccountQueryResponse = {|
  +viewer: ?{|
    +tenants: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +createdAt: any,
          +updatedAt: any,
          +parent: ?{|
            +id: string,
            +name: string,
          |},
          +subdomain: ?string,
          +logoUrl: ?string,
          +tenantAccounts: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +name: ?string,
                +id: string,
                +accountPtr: {|
                  +id: string,
                  +availableCashBase: ?number,
                  +actualCashBase: ?number,
                  +availableCashBalances: ?$ReadOnlyArray<?{|
                    +currency: ?Currency,
                    +balance: ?number,
                  |}>,
                  +actualCashBalances: ?$ReadOnlyArray<?{|
                    +currency: ?Currency,
                    +balance: ?number,
                  |}>,
                |},
              |}
            |}>
          |},
        |}
      |}>,
      +count: ?number,
    |}
  |}
|};
export type TenantAccountQuery = {|
  variables: TenantAccountQueryVariables,
  response: TenantAccountQueryResponse,
|};
*/


/*
query TenantAccountQuery(
  $first: Int
  $offset: Int
  $tenantId: ID
) {
  viewer {
    tenants(offset: $offset, first: $first, id: $tenantId) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          parent {
            id
            name
          }
          subdomain
          logoUrl
          tenantAccounts {
            edges {
              node {
                name
                id
                accountPtr {
                  id
                  availableCashBase
                  actualCashBase
                  availableCashBalances {
                    currency
                    balance
                  }
                  actualCashBalances {
                    currency
                    balance
                  }
                }
              }
            }
          }
        }
      }
      count
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "tenants",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "tenantId"
    },
    {
      "kind": "Variable",
      "name": "offset",
      "variableName": "offset"
    }
  ],
  "concreteType": "TenantNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "TenantNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "parent",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "subdomain",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "logoUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "tenantAccounts",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantAccountNodeConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "edges",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantAccountNodeEdge",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "node",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TenantAccountNode",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "accountPtr",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "AccountNode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "availableCashBase",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "actualCashBase",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "availableCashBalances",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "MultiCurrencyNode",
                              "plural": true,
                              "selections": (v3/*: any*/)
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "actualCashBalances",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "MultiCurrencyNode",
                              "plural": true,
                              "selections": (v3/*: any*/)
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TenantAccountQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "TenantAccountQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "TenantAccountQuery",
    "id": null,
    "text": "query TenantAccountQuery(\n  $first: Int\n  $offset: Int\n  $tenantId: ID\n) {\n  viewer {\n    tenants(offset: $offset, first: $first, id: $tenantId) {\n      edges {\n        node {\n          id\n          name\n          createdAt\n          updatedAt\n          parent {\n            id\n            name\n          }\n          subdomain\n          logoUrl\n          tenantAccounts {\n            edges {\n              node {\n                name\n                id\n                accountPtr {\n                  id\n                  availableCashBase\n                  actualCashBase\n                  availableCashBalances {\n                    currency\n                    balance\n                  }\n                  actualCashBalances {\n                    currency\n                    balance\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      count\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50aa1ba73702326be9ec6a24aaebc156';
module.exports = node;
