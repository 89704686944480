import React from 'react';
import { toGlobalId } from '../../helpers/ID';
import { withTabItems } from '../common/RoutableTabContainer';
import AdviserClientList from './AdviserClientList';
import withSuspense from '../common/table/Suspention';

const AdviserProfile = withSuspense(React.lazy(
  () => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./au/AdviserProfile') : import('./uk/AdviserProfile')),
));

const adviserIdPass = props => ({
  adviserId: toGlobalId('AdviserNode', props.match.params.adviserId),
});

export default withTabItems(
  [
    {
      label: 'profile',
      url: 'profile',
      comp: AdviserProfile,
      propCopyFn: adviserIdPass,
    },
    {
      label: 'clients',
      url: 'clients',
      comp: AdviserClientList,
      propCopyFn: adviserIdPass,
    },
  ],
);
