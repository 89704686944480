import React from 'react';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import notiStack from '../../common/notiStack';

// eslint-disable-next-line react/prefer-stateless-function
class NotiStackHooker extends React.Component {
  componentDidMount = () => {
    notiStack.register(this);
  };

  action = (key) => {
    const { closeSnackbar } = this.props;
    return (
      <React.Fragment>
        <Button onClick={() => { closeSnackbar(key); }}>
          {'Dismiss'}
        </Button>
      </React.Fragment>
    );
  };


  doNotify = (message, variant, action) => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, { variant, action: action || this.action });
  };

  render() {
    return <React.Fragment />;
  }
}

export default withSnackbar(NotiStackHooker);
