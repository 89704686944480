import React from 'react';
import { withRouter } from 'react-router-dom';
import AccountList from '../account/AccountList';

function AdviserClientList(props) {
  const { adviserId } = props;
  return (
    <AccountList
      adviserId={adviserId}
    />
  );
}

export default withRouter(AdviserClientList);
