import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import VueCommunicationList from './VueCommunicationList';
import vueCommunicationQuery from './VueCommunicationQuery';

export default createQueryRendererProgress(
  vueCommunicationQuery,
  VueCommunicationList,
  {},
  props => ({ vueId: props.vueId }),
);
