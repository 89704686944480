import React from 'react';
import TableStatus from '../common/TableStatus';
import EnumValue from '../common/EnumValue';

const approvableColor = {
  APPROVED: { color: 'primary' },
  PENDING: {},
  CANCELLED: { color: 'secondary' },
};

function ApprovableStatus(props) {
  const { approvable } = props;
  return (
    <TableStatus {...approvableColor[approvable.status]}>
      <EnumValue
        enumType="ApprovableStatus"
        enumValue={approvable.status}
      />
    </TableStatus>
  );
}

export default ApprovableStatus;
