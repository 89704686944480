import { commitMutation } from 'react-relay';
import environment from '../Environment';


function doCommit(mutation, variables, onSuccess, onError) {
  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (errors) {
          if (onError) {
            return onError(errors);
          }
        }
        if (onSuccess) {
          onSuccess(response, errors);
        }
        return null;
      },
      onError: (error) => {
        if (onError) onError(error);
      },
    },
  );
}


export function doCommitPromise(
  mutation,
  variables,
  onSuccess,
  onError,
  updater = null,
  uploadables = null,
  preValidator: null,
) {
  return new Promise((resolve, reject) => {
    if (preValidator) {
      if (!preValidator()) {
        reject();
        onError();
      }
    }
    const config = {
      mutation,
      variables: ((typeof variables) === 'function' ? variables() : variables),
      onCompleted: (response, errors) => {
        if (errors) {
          if (onError) {
            reject(errors);
            return onError(errors);
          }
        }
        resolve();
        if (onSuccess) {
          onSuccess(response, errors);
        }
        return null;
      },
      onError: (error) => {
        reject();
        if (onError) onError(error);
      },
    };
    if (updater) config.updater = updater;
    if (uploadables) config.uploadables = uploadables;

    commitMutation(
      environment,
      config,
    );
  });
}

export const graceErrChecked = callback => (response, errors) => {
  if (!errors || errors.length === 0) {
    callback && callback();
  }
};

export default doCommit;
