

class NotiStackRegister {
  constructor() {
    this.publisher = null;
  }

  register = (publisher) => {
    this.publisher = publisher;
  };

  error = (message, action) => {
    if (this.publisher) {
      this.publisher.doNotify(message, 'error', action);
    }
  };

  success = (message, action) => {
    if (this.publisher) {
      this.publisher.doNotify(message, 'success', action);
    }
  };

  warning = (message, action) => {
    if (this.publisher) {
      this.publisher.doNotify(message, 'warning', action);
    }
  };

  info = (message, action) => {
    if (this.publisher) {
      this.publisher.doNotify(message, 'info', action);
    }
  };
}

const notiStack = new NotiStackRegister();

const notifyTypeFuncMap = {
  ERROR: notiStack.error,
  INFO: notiStack.info,
  SUCCESS: notiStack.success,
  WARNING: notiStack.warning,
};

export const renderNotification = (notification) => {
  const mappedFn = notifyTypeFuncMap[notification.type];
  if (notification && mappedFn) {
    mappedFn(notification.message);
  }
};

export default notiStack;
