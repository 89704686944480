import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';

function AddChargeFormPane(props) {
  const { viewer } = props;
  const accountCharge = viewer && viewer.manualAccountCharges.edges[0].node.accountchargePtr;
  return (
    <DrawerGridItemContainer>
      <DrawerGridItemHeading
        heading="Charge Information"
      />
      <DataTableWidget
        title="Tenant Portions"
        columnMeta={[
          TableFieldMeta.account(node => node.tenantAccount.accountPtr),
          TableFieldMeta.priceField(
            'qty',
            'Quantity',
            node => node.quantity * -1.0,
            node => accountCharge.currency,
          ),
        ]}
        dataNodes={accountCharge ? accountCharge.accountchargetenantportionSet.edges.map(
          x => x.node,
        ) : []}
        xs={12}
      />
    </DrawerGridItemContainer>
  );
}


const query = graphql`
  query ChargeInfoFormPaneQuery (
    $chargeId: ID,
  ) {
    viewer {
      ...ChargeInfoFormPane_viewer
      @arguments (
        chargeId: $chargeId
      )
    }
  }
`;

const container = createFragmentContainer(
  AddChargeFormPane,
  {
    viewer: graphql`
      fragment ChargeInfoFormPane_viewer on Query
      @argumentDefinitions(
        chargeId: {type: ID}
      ) {
        manualAccountCharges(
          id: $chargeId
        ) {
          edges {
            node {
              accountchargePtr {
                currency
                accountchargetenantportionSet {
                  edges {
                    node {
                      id
                      quantity
                      tenantAccount {
                        id
                        name
                        accountPtr {
                          id 
                          name
                        }
                      }
                    }
                  }
                }
              }
              id
              amount
              description
              createdAt
              updatedAt
              feeType
              currency
              account {
                id
                name
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ chargeGetter }) => ({ chargeId: chargeGetter().id }),
);
