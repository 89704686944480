/**
 * @flow
 * @relayHash db8018d1f75defb656b90b8fd21e70b2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeeTemplate_viewer$ref = any;
export type FeeTemplateQueryVariables = {|
  feeTemplateId?: ?string
|};
export type FeeTemplateQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: FeeTemplate_viewer$ref
  |}
|};
export type FeeTemplateQuery = {|
  variables: FeeTemplateQueryVariables,
  response: FeeTemplateQueryResponse,
|};
*/


/*
query FeeTemplateQuery(
  $feeTemplateId: ID
) {
  viewer {
    ...FeeTemplate_viewer_1d7bw3
    id
  }
}

fragment FeeTemplate_viewer_1d7bw3 on Query {
  feeTemplates(id: $feeTemplateId) {
    edges {
      node {
        id
        name
        description
        createdAt
        updatedAt
        type
        notes {
          id
        }
        fees(orderBy: "-createdAt") {
          edges {
            node {
              id
              feeType
              simpleTranche
              paymentPeriod
              enabled
              createdAt
              updatedAt
              currency
              notes {
                id
              }
              feeComponents {
                edges {
                  node {
                    id
                    tenant {
                      id
                      name
                      baseCurrency
                    }
                    calculationType
                    flatChargeAmount
                    maxChargePoint
                    minChargePoint
                    minPriceChargePoint
                    maxPriceChargePoint
                    percentChargeAmount
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feeTemplateId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notes",
  "storageKey": null,
  "args": null,
  "concreteType": "NotesNode",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeTemplateQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "FeeTemplate_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "feeTemplateId",
                "variableName": "feeTemplateId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeTemplateQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeTemplates",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "feeTemplateId"
              }
            ],
            "concreteType": "FeeTemplateNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeTemplateNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeTemplateNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "type",
                        "args": null,
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "fees",
                        "storageKey": "fees(orderBy:\"-createdAt\")",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "-createdAt"
                          }
                        ],
                        "concreteType": "FeeNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "FeeNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "FeeNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "feeType",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "simpleTranche",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "paymentPeriod",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "enabled",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "currency",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v5/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "feeComponents",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "FeeComponentNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "FeeComponentNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "FeeComponentNode",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              {
                                                "kind": "LinkedField",
                                                "alias": null,
                                                "name": "tenant",
                                                "storageKey": null,
                                                "args": null,
                                                "concreteType": "TenantNode",
                                                "plural": false,
                                                "selections": [
                                                  (v1/*: any*/),
                                                  (v2/*: any*/),
                                                  {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "baseCurrency",
                                                    "args": null,
                                                    "storageKey": null
                                                  }
                                                ]
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "calculationType",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "flatChargeAmount",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "maxChargePoint",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "minChargePoint",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "minPriceChargePoint",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "maxPriceChargePoint",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "percentChargeAmount",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              (v3/*: any*/),
                                              (v4/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "FeeTemplateQuery",
    "id": null,
    "text": "query FeeTemplateQuery(\n  $feeTemplateId: ID\n) {\n  viewer {\n    ...FeeTemplate_viewer_1d7bw3\n    id\n  }\n}\n\nfragment FeeTemplate_viewer_1d7bw3 on Query {\n  feeTemplates(id: $feeTemplateId) {\n    edges {\n      node {\n        id\n        name\n        description\n        createdAt\n        updatedAt\n        type\n        notes {\n          id\n        }\n        fees(orderBy: \"-createdAt\") {\n          edges {\n            node {\n              id\n              feeType\n              simpleTranche\n              paymentPeriod\n              enabled\n              createdAt\n              updatedAt\n              currency\n              notes {\n                id\n              }\n              feeComponents {\n                edges {\n                  node {\n                    id\n                    tenant {\n                      id\n                      name\n                      baseCurrency\n                    }\n                    calculationType\n                    flatChargeAmount\n                    maxChargePoint\n                    minChargePoint\n                    minPriceChargePoint\n                    maxPriceChargePoint\n                    percentChargeAmount\n                    createdAt\n                    updatedAt\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '715155bb344ecb5eebc2aac20b627e94';
module.exports = node;
