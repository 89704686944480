import React from 'react';
import Grid from '@material-ui/core/Grid';
import TableButton from '../common/buttons/TableButton';
import { doCommitPromise } from '../../common/commit';
import ignoreBrokerTransaction from '../../mutations/BrokerTransaction';

export default class BrokerTransactionTableFieldMeta {}

BrokerTransactionTableFieldMeta.ignore = {
  id: 'ignoreAction',
  Header: '',
  accessor: node => node,
  Cell: (row) => {
    const brokerTransaction = row.value;
    const { ignore: ignored } = brokerTransaction;

    if (brokerTransaction.matched > 0) return null;
    const refreshKey = `${brokerTransaction.id}-${brokerTransaction.updatedAt}`;
    return (
      <Grid
        key={refreshKey}
        container
        spacing={1}
        ustify="center"
      >
        <Grid item>
          {
            ignored ? (
              <TableButton
                key={refreshKey}
                buttonText="unignore"
                onClickPromise={() => doCommitPromise(
                  ignoreBrokerTransaction,
                  {
                    brokerTransactionId: brokerTransaction.id,
                    ignore: false,
                  },
                )}
              />
            ) : (
              <TableButton
                key={refreshKey}
                buttonText="ignore"
                onClickPromise={() => doCommitPromise(
                  ignoreBrokerTransaction,
                  {
                    brokerTransactionId: brokerTransaction.id,
                    ignore: true,
                  },
                )}
              />
            )
          }
        </Grid>
      </Grid>
    );
  },
};
