
import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';


export default function DrawerSubGroupDivider(props) {
  return (
    <Grid item xs={12}>
      <Divider light style={{ marginTop: '6px' }} />
    </Grid>
  );
}
