import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useCommonStyles } from '../Styles';

function DrawerGridItemCurrencyField(props) {
  const {
    xs,
    sm,
    ref,
    defaultValue,
    onChange,
    label,
    id,
    currency,
  } = props;
  const classes = useCommonStyles();
  return (
    <Grid item xs={xs || 12} sm={sm || 6}>
      <FormControl>
        <TextField
          className={classes.fromControlDrawer}
          id={id || (label && label.toLowerCase())}
          label={label}
          type="number"
          ref={ref}
          defaultValue={defaultValue}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
          }}
        />
      </FormControl>
    </Grid>
  );
}

export default DrawerGridItemCurrencyField;
