import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MoreButton from '../common/buttons/MoreButton';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableStatus from '../common/TableStatus';
import AccountLink from '../common/links/AccountLink';
import PortfolioOrderDlg from './PortfolioOrderDlg';
import BaseGridContainer from '../common/BaseGridContainer';
import { fromGlobalId, toLocal } from '../../helpers/ID';
import EnumValue from '../common/EnumValue';
import StockOrderTicks from './StockOrderTicks';
import renderPortfolioOrderInvoiceActions from './renderUtils';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';

const sideColors = {
  BUY: '#6969d2',
  SELL: '#af4d4a',
  REB: 'purple',
};

function PortfolioOrderList(props) {
  const {
    relay,
    viewer,
    filter,
  } = props;

  filter.setRelay(relay);
  const [status, setStatus] = React.useState(filter.params.status);
  const [side, setSide] = React.useState(filter.params.side);
  const [portfolioOrder, setPortfolioOrder] = React.useState(null);
  const columns = [
    {
      accessor: node => node,
      Header: 'ID',
      id: 'id',
      Cell: (row) => {
        const orderId = fromGlobalId(row.value.id)[1];
        return <Link href={`/order/${orderId}`}>{orderId}</Link>;
      },
      ...TableFieldMeta.rightAlignStyle,
    },
    {
      accessor: node => (
        <TableStatus customStyle={{ backgroundColor: sideColors[node.side] }}>
          <EnumValue enumType="PortfolioOrderSide" enumValue={node.side} />
        </TableStatus>
      ),
      Header: 'Side',
      id: 'side',
    },
    TableFieldMeta.enum('PortfolioOrderStatus', 'Status', node => node.status),
    TableFieldMeta.field(node => node.portfolio.trackingVueName, 'Vue'),
    {
      accessor: node => <AccountLink account={node.portfolio.account} />,
      Header: 'Account',
      id: 'account',
    },
    TableFieldMeta.createdAt,
    {
      id: 'actions',
      accessor: node => node,
      Header: 'Invoice',
      Cell: node => renderPortfolioOrderInvoiceActions(node.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'more',
      accessor: node => node,
      Header: '',
      Cell: row => <MoreButton onClick={e => setPortfolioOrder(row.value)} />,
    },
    {
      id: 'subOrderStatus',
      Header: 'Order Status',
      accessor: node => node,
      Cell: row => (
        <StockOrderTicks
          parentOrder={row.value}
          stockOrders={row.value.stockOrders.edges.map(x => x.node)}
        />
      ),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      accessor: node => node,
      Header: 'Grp.OID',
      id: 'bulkOrderId',
      Cell: (row) => {
        if (!row.value.bulkOrder) return null;
        const orderId = toLocal(row.value.bulkOrder.id);
        return <Link href={`/bulk-oder/${orderId}`}>{orderId}</Link>;
      },
      ...TableFieldMeta.rightAlignStyle,
    },
  ];

  const classes = useCommonStyles();

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>

        <InnerTabFilterSelectField
          enumType="PortfolioOrderStatus"
          helperText="Order Status"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
            filter.updateVar('status', e.target.value);
          }}
        />

        <InnerTabFilterSelectField
          enumType="PortfolioOrderSide"
          helperText="Side"
          value={side}
          onChange={(e) => {
            setSide(e.target.value);
            filter.updateVar('side', e.target.value);
          }}
        />

        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Search"
          helperText="ID, Vue, Account name"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.portfolioOrders : null)}
          />
        </Grid>

        {portfolioOrder && (
          <PortfolioOrderDlg
            portfolioOrder={portfolioOrder}
            onClose={() => setPortfolioOrder(null)}
          />
        )
        }
      </BaseGridContainer>
    </div>

  );
}

const query = graphql`
  query PortfolioOrderListQuery (
    $first: Int
    $offset: Int
    $search: String
    $side: String
    $status: String
    $accountId: ID
    $trackingVue: ID
  ) {
    viewer {
      ...PortfolioOrderList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        side: $side
        status: $status
        accountId: $accountId
        trackingVue: $trackingVue
      )
    }
  }
`;

const container = createRefetchContainer(
  PortfolioOrderList,
  {
    viewer: graphql`
      fragment PortfolioOrderList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        side: {type: String}
        status: {type: String}
        accountId: {type: ID}
        trackingVue: {type: ID}
      ) {

        portfolioOrders(
          first: $first
          offset: $offset
          side: $side,
          search: $search,
          status: $status,
          accountId: $accountId,
          portfolio_TrackingVue: $trackingVue
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              id
              side
              status
              createdAt
              updatedAt
              tradeInvoice {
                id
                pdfUrl
                pdf
                state
                createdAt
                updatedAt
              }
              
              bulkOrder {
                id
              }
              
              stockOrders {
                edges {
                  node {
                    id
                    side
                    quantityToDate
                    matched
                    quantity
                    brokerOrder {
                      id
                      quantity
                      quantityFilled
                      quantityLeft
                      status
                    }
                  }
                }
              }

              portfolio {
                id
                trackingVueName
                account {
                  name
                  id
                  clientaccount {
                    id
                    accountType
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId, vueId }) => ({ accountId, trackingVue: vueId }),
  {
    debounceFields: ['search'],
  },
));
