import { graphql } from 'react-relay';

const createOrdersMutation = graphql`
  mutation CreateOrdersMutation (
    $portfolioOrders: [CreatePortfolioOrderInputType]
    $withApprovals: OrderClientApprovalInputType
    $withMakerChecker: Boolean
  ) {
    createOrders(
      portfolioOrders:$portfolioOrders
      withApprovals: $withApprovals
      withMakerChecker: $withMakerChecker
    ) {
      portfolioOrders {
        id
        stockOrders {
          edges {
            node {
              id
              brokerOrder {
                id
                status
              }
            }
          }
        }
        account {
          id
          currentAssets {
            stock {
              balances {
                contract {
                  id
                  symbol
                }
                availableQuantity
                quantity
              }
            }
          }
        }
      }
      
      errors {
        messages
        field
      }
      orderErrors {
        orderIndex
        accountId
        accountName
        message
      }
    }
  }
`;

export default createOrdersMutation;
