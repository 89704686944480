import React from 'react';
import './IBIcon.css';
import { SvgIcon } from '@material-ui/core';

function IBICon(props) {
  return (
    <SvgIcon {...props}>
      <g id="layer1" transform="translate(12.582832,-170.97114)">
        <g id="g9910" transform="translate(0.50000041,-0.50000076)">

          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="3176.7703"
            y1="48.5061"
            x2="3190.0557"
            y2="48.5061"
            gradientTransform="matrix(-0.75 0 0 -0.75 2385.7808 224.8668)"
          >
            <stop offset="0" style={{ stopColor: '#D81222' }} />
            <stop offset="1" style={{ stopColor: '#960B1A' }} />
          </linearGradient>
          <polygon
            id="polygon9702"
            style={{ fill: 'url(#SVGID_1_)' }}
            points="-6.7,194 -6.7,183 3.2,194"
          />
          <circle
            id="circle9704"
            className="st1"
            cx="1.8"
            cy="185.7"
            r="3"
          />
          <polygon
            id="polygon9706"
            className="st1"
            points="-6.7,183 -6.7,194 3.2,171.5"
          />
        </g>
      </g>

    </SvgIcon>
  );
}

export default IBICon;
