/**
 * @flow
 * @relayHash 2857d2346fd61ace66319bd9861c4bf7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type ReconcileCashTransferRequestsMutationVariables = {|
  transferRequestIds?: ?$ReadOnlyArray<?string>,
  brokerTransferId?: ?string,
|};
export type ReconcileCashTransferRequestsMutationResponse = {|
  +reconcileCashTransfer: ?{|
    +cashTransferRequests: ?$ReadOnlyArray<?{|
      +id: string,
      +status: ?CashTransferStatus,
      +createdAt: any,
      +updatedAt: any,
      +blocked: boolean,
      +cashTransfer: ?{|
        +createdAt: any,
        +updatedAt: any,
        +id: string,
        +brokerActivity: {|
          +brokeractivitytransactionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +createdAt: any,
              |}
            |}>
          |}
        |},
      |},
    |}>
  |}
|};
export type ReconcileCashTransferRequestsMutation = {|
  variables: ReconcileCashTransferRequestsMutationVariables,
  response: ReconcileCashTransferRequestsMutationResponse,
|};
*/


/*
mutation ReconcileCashTransferRequestsMutation(
  $transferRequestIds: [ID]
  $brokerTransferId: ID
) {
  reconcileCashTransfer(cashTransferRequestIds: $transferRequestIds, brokerCashTransferId: $brokerTransferId) {
    cashTransferRequests {
      id
      status
      createdAt
      updatedAt
      blocked
      cashTransfer {
        createdAt
        updatedAt
        id
        brokerActivity {
          brokeractivitytransactionSet {
            edges {
              node {
                id
                createdAt
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferRequestIds",
    "type": "[ID]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerTransferId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerCashTransferId",
    "variableName": "brokerTransferId"
  },
  {
    "kind": "Variable",
    "name": "cashTransferRequestIds",
    "variableName": "transferRequestIds"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "blocked",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokeractivitytransactionSet",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerActivityTransactionNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerActivityTransactionNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerActivityTransactionNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ReconcileCashTransferRequestsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reconcileCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReconcileCashTransferRequest",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequests",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cashTransfer",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokerActivity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ReconcileCashTransferRequestsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "reconcileCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ReconcileCashTransferRequest",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequests",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestNode",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cashTransfer",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokerActivity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ReconcileCashTransferRequestsMutation",
    "id": null,
    "text": "mutation ReconcileCashTransferRequestsMutation(\n  $transferRequestIds: [ID]\n  $brokerTransferId: ID\n) {\n  reconcileCashTransfer(cashTransferRequestIds: $transferRequestIds, brokerCashTransferId: $brokerTransferId) {\n    cashTransferRequests {\n      id\n      status\n      createdAt\n      updatedAt\n      blocked\n      cashTransfer {\n        createdAt\n        updatedAt\n        id\n        brokerActivity {\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n                createdAt\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd4f4c9b9ba2a86bb9eeb007f8fcc545f';
module.exports = node;
