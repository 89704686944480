import React from 'react';
import Grid from '@material-ui/core/Grid';


function DrawerGridItemBottomButtonContainer(props) {
  const {
    children,
  } = props;
  return (
    <Grid item xs={12}>
      <Grid container spacing={1} justify="flex-end" style={{ marginTop: '10pt' }}>
        {children}
      </Grid>
    </Grid>
  );
}


export default DrawerGridItemBottomButtonContainer;
