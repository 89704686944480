import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function BlockRemove(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="m 23,18 v -2 h -8 v 2 z"
      />
      <path
        d="M 12 2 C 6.5 2 2 6.4999998 2 12 C 2 17.5 6.5 22 12 22 C 13.138916 22 14.233782 21.804064 15.255859 21.449219 A 5.9130435 5.9130435 0 0 1 13.873047 19.775391 C 13.271801 19.921007 12.644353 20 12 20 C 10.2 20 8.4996094 19.400781 7.0996094 18.300781 L 18.300781 7.0996094 C 19.207408 8.2534983 19.768132 9.6130007 19.939453 11.0625 A 5.9130435 5.9130435 0 0 1 21.994141 11.791016 C 21.881304 6.3869739 17.429804 2 12 2 z M 12 4 C 13.8 4 15.500391 4.5992187 16.900391 5.6992188 L 5.6992188 16.900391 C 4.5992187 15.500391 4 13.8 4 12 C 4 7.5999998 7.6 4 12 4 z "
      />
    </SvgIcon>
  );
}
