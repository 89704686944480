import notiStack from './notiStack';

export const getColumnIndex = (array, names, required, optionalMsg) => {
  const index = names.reduce(
    (v, n) => ((v !== -1) ? v : array.data[0].findIndex(e => e.toLowerCase() === n.toLowerCase())),
    -1,
  );
  if (index === -1) {
    const fieldDesc = names.slice(1).reduce((v, n) => `${v}, or "${n}"`, `"${names[0]}"`);
    if (required) {
      notiStack.error(`${fieldDesc} is required`);
    } else if (optionalMsg) {
      notiStack.warning(`${fieldDesc} ${optionalMsg}`);
    }
  }
  return index;
};

export const getColumnIndices = (array, columnDefs) => columnDefs.map(columnDef => getColumnIndex(
  array,
  columnDef[0],
  columnDef[1],
  columnDef.length >= 3 ? columnDef[2] : null,
));
