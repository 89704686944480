import React from 'react';
import axios from 'axios';
import { MVA_AUTH_TOKEN_TAG } from '../../../common/constants';
import TableButton from './TableButton';
import { ExportButton } from './BaseButton';

// Use this component for file downloads that require JWT
function StatelessDownloadButton(props) {
  const { downloadUrl, buttonType, ...otherProps } = props;

  const onClick = () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem(MVA_AUTH_TOKEN_TAG)}`,
      },
      responseType: 'arraybuffer',
    };
    axios.get(downloadUrl, config)
      .then((response) => {
        const file = new Blob([response.data], { type: response.headers.content_type });
        const dispositionHeader = response.headers['content-disposition'];
        const filename = dispositionHeader ? dispositionHeader.split('filename=')[1] : `file_${Date.now()}`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = filename;
        link.click();
        link.remove();
      });
  };

  return (
    buttonType === 'export'
      ? (
        <ExportButton
          onClick={onClick}
          {...otherProps}
        />
      )
      : (
        <TableButton
          onClick={onClick}
          color="secondary"
          {...otherProps}
        />
      )
  );
}

export default StatelessDownloadButton;
