import { graphql } from 'react-relay';

const addTenantVueSharePermission = graphql`
  mutation AddTenantVueSharePermissionMutation(
    $guestTenantId: ID!
    $ownerTenantId: ID!
  ){
    addTenantVueSharePermission(
      guestTenantId: $guestTenantId
      ownerTenantId: $ownerTenantId
    ){
      vueSharePermission {
        id
        ownerTenant {
          id
          name
        }
        guestTenant {
          id
          name
        }
      }
    }
  }
`;


export default addTenantVueSharePermission;
