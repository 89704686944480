/**
 * @flow
 * @relayHash d8d87e6efb7206080b7c38db9cdaffb0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TransferRequestStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type PositionTransferInput = {|
  accountId?: ?string,
  status?: ?TransferRequestStatus,
  transferId?: ?string,
  purchases?: ?$ReadOnlyArray<?PositionTransferPurchaseInput>,
  cashQuantities?: ?$ReadOnlyArray<?PositionTransferCashQuantityInput>,
  id?: ?string,
|};
export type PositionTransferPurchaseInput = {|
  contractId?: ?string,
  purchaseDate?: ?any,
  portfolioId?: ?string,
  quantity?: ?number,
  costBasis?: ?number,
  commission?: ?number,
  id?: ?string,
  dummy?: ?boolean,
|};
export type PositionTransferCashQuantityInput = {|
  currency?: ?string,
  quantity?: ?number,
|};
export type UpdatePositionTransferMutationVariables = {|
  transferInput?: ?PositionTransferInput
|};
export type UpdatePositionTransferMutationResponse = {|
  +updatePositionTransfer: ?{|
    +positionTransferRequest: ?{|
      +id: string,
      +status: TransferRequestStatus,
      +createdAt: any,
      +updatedAt: any,
      +positionTransferPurchases: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +updatedAt: any,
            +quantity: number,
            +portfolio: {|
              +id: string,
              +trackingVueName: ?string,
            |},
            +contract: {|
              +id: string,
              +name: ?string,
              +ibConId: number,
            |},
            +costBasis: ?number,
            +commission: ?number,
            +dummy: boolean,
          |}
        |}>
      |},
    |}
  |}
|};
export type UpdatePositionTransferMutation = {|
  variables: UpdatePositionTransferMutationVariables,
  response: UpdatePositionTransferMutationResponse,
|};
*/


/*
mutation UpdatePositionTransferMutation(
  $transferInput: PositionTransferInput
) {
  updatePositionTransfer(transferInput: $transferInput) {
    positionTransferRequest {
      id
      status
      createdAt
      updatedAt
      positionTransferPurchases {
        edges {
          node {
            id
            updatedAt
            quantity
            portfolio {
              id
              trackingVueName
            }
            contract {
              id
              name
              ibConId
            }
            costBasis
            commission
            dummy
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferInput",
    "type": "PositionTransferInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updatePositionTransfer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "transferInput",
        "variableName": "transferInput"
      }
    ],
    "concreteType": "UpdatePositionTransfer",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "positionTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "PositionTransferRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferPurchases",
            "storageKey": null,
            "args": null,
            "concreteType": "PositionTransferPurchaseNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferPurchaseNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferPurchaseNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trackingVueName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "ibConId",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "costBasis",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "commission",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dummy",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdatePositionTransferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdatePositionTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdatePositionTransferMutation",
    "id": null,
    "text": "mutation UpdatePositionTransferMutation(\n  $transferInput: PositionTransferInput\n) {\n  updatePositionTransfer(transferInput: $transferInput) {\n    positionTransferRequest {\n      id\n      status\n      createdAt\n      updatedAt\n      positionTransferPurchases {\n        edges {\n          node {\n            id\n            updatedAt\n            quantity\n            portfolio {\n              id\n              trackingVueName\n            }\n            contract {\n              id\n              name\n              ibConId\n            }\n            costBasis\n            commission\n            dummy\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8616dec65924e141df6d78346e965b8';
module.exports = node;
