/**
 * @flow
 * @relayHash e678ee1077fbaa58b278f714f8eee427
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerageCreditUpdateForm_brokerageCredit$ref = any;
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type BrokerageCreditStatusUpdateMutationVariables = {|
  brokerageCreditId: string,
  status: ApprovableStatus,
|};
export type BrokerageCreditStatusUpdateMutationResponse = {|
  +updateBrokerageCreditStatus: ?{|
    +brokerageCredit: ?{|
      +$fragmentRefs: BrokerageCreditUpdateForm_brokerageCredit$ref
    |}
  |}
|};
export type BrokerageCreditStatusUpdateMutation = {|
  variables: BrokerageCreditStatusUpdateMutationVariables,
  response: BrokerageCreditStatusUpdateMutationResponse,
|};
*/


/*
mutation BrokerageCreditStatusUpdateMutation(
  $brokerageCreditId: ID!
  $status: ApprovableStatus!
) {
  updateBrokerageCreditStatus(brokerageCreditId: $brokerageCreditId, status: $status) {
    brokerageCredit {
      ...BrokerageCreditUpdateForm_brokerageCredit
      id
    }
  }
}

fragment BrokerageCreditUpdateForm_brokerageCredit on BrokerageCreditNode {
  id
  account {
    id
    name
  }
  description
  expiryDate
  initialAmount
  remainingAmount
  note
  creditType
  consumed
  provisionedBy {
    id
    fullName
  }
  status
  approvals {
    edges {
      node {
        id
        approvedBy {
          id
          username
        }
        approvedAt
      }
    }
  }
  hasCompleteApprovals
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerageCreditId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "ApprovableStatus!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerageCreditId",
    "variableName": "brokerageCreditId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerageCreditStatusUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBrokerageCreditStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBrokerageCreditStatus",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerageCredit",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerageCreditNode",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "BrokerageCreditUpdateForm_brokerageCredit",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerageCreditStatusUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBrokerageCreditStatus",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateBrokerageCreditStatus",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerageCredit",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerageCreditNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expiryDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "initialAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "remainingAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "note",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "creditType",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "consumed",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "provisionedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "fullName",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvals",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "approvedBy",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "approvedAt",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasCompleteApprovals",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerageCreditStatusUpdateMutation",
    "id": null,
    "text": "mutation BrokerageCreditStatusUpdateMutation(\n  $brokerageCreditId: ID!\n  $status: ApprovableStatus!\n) {\n  updateBrokerageCreditStatus(brokerageCreditId: $brokerageCreditId, status: $status) {\n    brokerageCredit {\n      ...BrokerageCreditUpdateForm_brokerageCredit\n      id\n    }\n  }\n}\n\nfragment BrokerageCreditUpdateForm_brokerageCredit on BrokerageCreditNode {\n  id\n  account {\n    id\n    name\n  }\n  description\n  expiryDate\n  initialAmount\n  remainingAmount\n  note\n  creditType\n  consumed\n  provisionedBy {\n    id\n    fullName\n  }\n  status\n  approvals {\n    edges {\n      node {\n        id\n        approvedBy {\n          id\n          username\n        }\n        approvedAt\n      }\n    }\n  }\n  hasCompleteApprovals\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae64668cef88e5b106b58d56521e3da7';
module.exports = node;
