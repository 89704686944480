/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StockOrderInvoiceActions_invoice$ref: FragmentReference;
declare export opaque type StockOrderInvoiceActions_invoice$fragmentType: StockOrderInvoiceActions_invoice$ref;
export type StockOrderInvoiceActions_invoice = {|
  +tradeInvoice: ?{|
    +id: string,
    +pdfUrl: ?string,
    +pdf: ?string,
    +state: ReportState,
    +createdAt: any,
    +updatedAt: any,
    +portfolioOrder: ?{|
      +id: string
    |},
  |},
  +$refType: StockOrderInvoiceActions_invoice$ref,
|};
export type StockOrderInvoiceActions_invoice$data = StockOrderInvoiceActions_invoice;
export type StockOrderInvoiceActions_invoice$key = {
  +$data?: StockOrderInvoiceActions_invoice$data,
  +$fragmentRefs: StockOrderInvoiceActions_invoice$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "StockOrderInvoiceActions_invoice",
  "type": "StockOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tradeInvoice",
      "storageKey": null,
      "args": null,
      "concreteType": "TradeInvoiceNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdfUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "pdf",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "state",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "createdAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "updatedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "portfolioOrder",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioOrderNode",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '691d0a525c838f758d31d450e275cb78';
module.exports = node;
