import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Drawer from '@material-ui/core/Drawer';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { VerticalTableWidgetContainer } from '../common/VerticalTableWidget';
import { useCommonStyles } from '../common/Styles';

import { fromGlobalId } from '../../helpers/ID';
import TableButton from '../common/buttons/TableButton';
import AccountVueExclusivePermissionForm from './AccountVueExclusivePermissionForm';
import { deleteVueExclusivePermission } from '../../mutations/VueExclusivePermission';
import doCommit from '../../common/commit';


function AccountPermissions(props) {
  const { accountId, viewer, relay } = props;
  const [showAddVEPermission, setShowAddVEPermission] = React.useState(false);
  const classes = useCommonStyles();
  const vueExclusivePermissions = viewer.accounts.edges[0].node.vueexclusivepermissionSet.edges;

  const deleteVEP = (vepId) => {
    doCommit(
      deleteVueExclusivePermission,
      {
        vueExclusivePermissionId: vepId,
      },
    );
  };

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={showAddVEPermission}
        onClose={() => setShowAddVEPermission(false)}
      >
        <AccountVueExclusivePermissionForm
          accountId={accountId}
          onSuccess={() => {
            setShowAddVEPermission(false);
            relay.refetch(() => ({ accountId }));
          }}
        />

      </Drawer>
      <VerticalTableWidgetContainer>

        <Grid item xs={12} md={6} lg={6}>
          <Paper className={classes.tableHolderRoot}>
            <div className={classes.widgetHeadingContainer}>
              <Typography
                component="h6"
                variant="subtitle2"
                gutterBottom
              >
                Vue Exclusive Permissions
              </Typography>
            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Vue ID</TableCell>
                  <TableCell>Vue Name</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  vueExclusivePermissions.map(edge => (
                    <TableRow>
                      <TableCell>{fromGlobalId(edge.node.vue.id)[1]}</TableCell>
                      <TableCell>{edge.node.vue.name}</TableCell>
                      <TableCell>
                        <Grid container spacing={1} justify="center">
                          <Grid item>
                            <TableButton
                              color="secondary"
                              onClick={() => {
                                deleteVEP(edge.node.id);
                                relay.refetch(() => ({ accountId }));
                              }}
                            >
                              Remove
                            </TableButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                }

              </TableBody>

            </Table>
            <Grid container spacing={1} justify="flex-end" style={{ marginTop: '10px' }}>
              <Grid item>
                <TableButton
                  color="primary"
                  onClick={() => setShowAddVEPermission(true)}
                >
                  Add
                </TableButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

      </VerticalTableWidgetContainer>
    </div>
  );
}

const query = graphql`
  query AccountPermissionsQuery (
    $accountId: ID
  ) {
    viewer {
      ...AccountPermissions_viewer
      @arguments(
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  AccountPermissions,
  {
    viewer: graphql`
      fragment AccountPermissions_viewer on Query
      @argumentDefinitions(
        accountId: {type: ID}
      )
      {
        accounts (
          id: $accountId
        ) {
          edges {
            node {
              vueexclusivepermissionSet {
                edges {
                  node {
                    id
                    vue {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);


export default createQueryRendererProgress(
  query,
  container,
  (viewer, { accountId }) => ({ accountId }),
  ({ accountId }) => ({ accountId }),
);
