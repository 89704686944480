import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemDateField from '../common/drawer/DrawerGridItemDateField';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { DownloadButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import generateDividendPayouts from '../../mutations/GenerateDividendPayouts';
import downloadFileContent from '../../common/download';

export default function DividendPayoutForm(props) {
  const classes = useCommonStyles();
  const [processedDate, setProcessedDate] = React.useState(null);
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Dividend Payout"
        />
        <DrawerGridItemDateField
          label="Processed Date"
          value={processedDate}
          onChange={_n => setProcessedDate(_n)}
          autoOk
          disableFuture
        />
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <DownloadButton
              disabled={!processedDate}
              onClickPromise={() => doCommitPromise(
                generateDividendPayouts,
                {
                  forDate: processedDate,
                },
                (response) => {
                  downloadFileContent(
                    response.generateDividendPayoutCashTransfers.csvText,
                    'text/csv',
                    `dp-${moment(processedDate).format('DDMMYY')}.csv`,
                  );
                },
                () => null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}
