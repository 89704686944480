/**
 * @flow
 * @relayHash 63b39632f83cba8d447d4f77c1e60b30
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CurrencyConversionRequestList_viewer$ref = any;
export type CurrencyConversionRequestListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  status?: ?string,
  accountId?: ?string,
|};
export type CurrencyConversionRequestListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CurrencyConversionRequestList_viewer$ref
  |}
|};
export type CurrencyConversionRequestListQuery = {|
  variables: CurrencyConversionRequestListQueryVariables,
  response: CurrencyConversionRequestListQueryResponse,
|};
*/


/*
query CurrencyConversionRequestListQuery(
  $first: Int
  $offset: Int
  $status: String
  $accountId: ID
) {
  viewer {
    ...CurrencyConversionRequestList_viewer_332lZq
    id
  }
}

fragment CurrencyConversionRequestList_viewer_332lZq on Query {
  currencyConversionRequests(first: $first, offset: $offset, status: $status, accountId: $accountId, orderBy: "-createdAt") {
    count
    edges {
      node {
        id
        baseCurrency
        quoteCurrency
        quantity
        status
        createdAt
        createdBy {
          id
          fullName
        }
        account {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CurrencyConversionRequestListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CurrencyConversionRequestList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CurrencyConversionRequestListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currencyConversionRequests",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v4/*: any*/)
            ],
            "concreteType": "CurrencyConversionRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CurrencyConversionRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CurrencyConversionRequestNode",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "baseCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quoteCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CurrencyConversionRequestListQuery",
    "id": null,
    "text": "query CurrencyConversionRequestListQuery(\n  $first: Int\n  $offset: Int\n  $status: String\n  $accountId: ID\n) {\n  viewer {\n    ...CurrencyConversionRequestList_viewer_332lZq\n    id\n  }\n}\n\nfragment CurrencyConversionRequestList_viewer_332lZq on Query {\n  currencyConversionRequests(first: $first, offset: $offset, status: $status, accountId: $accountId, orderBy: \"-createdAt\") {\n    count\n    edges {\n      node {\n        id\n        baseCurrency\n        quoteCurrency\n        quantity\n        status\n        createdAt\n        createdBy {\n          id\n          fullName\n        }\n        account {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '111a79587e05d14bc1ddd26a4612c37d';
module.exports = node;
