/**
 * @flow
 * @relayHash 7467468a264bdd4899887b4f5467d29b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PublishVueCommunicationMutationVariables = {|
  communicationId: string
|};
export type PublishVueCommunicationMutationResponse = {|
  +publishVueCommunication: ?{|
    +vueCommunication: ?{|
      +id: string,
      +published: boolean,
      +publishedAt: ?any,
      +createdAt: any,
      +updatedAt: any,
      +message: ?string,
      +heading: string,
    |}
  |}
|};
export type PublishVueCommunicationMutation = {|
  variables: PublishVueCommunicationMutationVariables,
  response: PublishVueCommunicationMutationResponse,
|};
*/


/*
mutation PublishVueCommunicationMutation(
  $communicationId: ID!
) {
  publishVueCommunication(communicationId: $communicationId) {
    vueCommunication {
      id
      published
      publishedAt
      createdAt
      updatedAt
      message
      heading
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "communicationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "publishVueCommunication",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "communicationId",
        "variableName": "communicationId"
      }
    ],
    "concreteType": "PublishVueCommunication",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueCommunication",
        "storageKey": null,
        "args": null,
        "concreteType": "VueCommunicationNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "published",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "publishedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "heading",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PublishVueCommunicationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PublishVueCommunicationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PublishVueCommunicationMutation",
    "id": null,
    "text": "mutation PublishVueCommunicationMutation(\n  $communicationId: ID!\n) {\n  publishVueCommunication(communicationId: $communicationId) {\n    vueCommunication {\n      id\n      published\n      publishedAt\n      createdAt\n      updatedAt\n      message\n      heading\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8af5d81fe97aec969f441eb85fa331a1';
module.exports = node;
