import { graphql } from 'react-relay';

// language=GraphQL
const createCannedSecurityQuestionMutation = graphql`
  mutation CreateCannedSecurityQuestionMutation(
    $question: String!
  ) {
    createCannedSecurityQuestion(
      question: $question
    ) {
      cannedSecurityQuestion {
        id
      }
    }
  }
`;

export default createCannedSecurityQuestionMutation;
