import { graphql } from 'react-relay';

const updatePositionTransfer = graphql`
  mutation UpdatePositionTransferMutation (
    $transferInput: PositionTransferInput
  ) {
    updatePositionTransfer (
      transferInput: $transferInput
    ) {
      positionTransferRequest {
        id
        status
        createdAt
        updatedAt
        positionTransferPurchases {
          edges {
            node {
              id
              updatedAt
              quantity
              portfolio {
                id
                trackingVueName
              }
              contract {
                id
                name
                ibConId
              }
              costBasis
              commission
              dummy
            }
          }
        }
      }
    }
  }
  `;

export default updatePositionTransfer;
