/**
 * @flow
 * @relayHash b3d6474f61850e7e6cd08ec72234db35
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddTenantVueSharePermissionMutationVariables = {|
  guestTenantId: string,
  ownerTenantId: string,
|};
export type AddTenantVueSharePermissionMutationResponse = {|
  +addTenantVueSharePermission: ?{|
    +vueSharePermission: ?{|
      +id: string,
      +ownerTenant: {|
        +id: string,
        +name: string,
      |},
      +guestTenant: {|
        +id: string,
        +name: string,
      |},
    |}
  |}
|};
export type AddTenantVueSharePermissionMutation = {|
  variables: AddTenantVueSharePermissionMutationVariables,
  response: AddTenantVueSharePermissionMutationResponse,
|};
*/


/*
mutation AddTenantVueSharePermissionMutation(
  $guestTenantId: ID!
  $ownerTenantId: ID!
) {
  addTenantVueSharePermission(guestTenantId: $guestTenantId, ownerTenantId: $ownerTenantId) {
    vueSharePermission {
      id
      ownerTenant {
        id
        name
      }
      guestTenant {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "guestTenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ownerTenantId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "addTenantVueSharePermission",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "guestTenantId",
        "variableName": "guestTenantId"
      },
      {
        "kind": "Variable",
        "name": "ownerTenantId",
        "variableName": "ownerTenantId"
      }
    ],
    "concreteType": "CreateTenantVueSharePermission",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueSharePermission",
        "storageKey": null,
        "args": null,
        "concreteType": "VueSharePermissionNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ownerTenant",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": (v2/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "guestTenant",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": (v2/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddTenantVueSharePermissionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AddTenantVueSharePermissionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddTenantVueSharePermissionMutation",
    "id": null,
    "text": "mutation AddTenantVueSharePermissionMutation(\n  $guestTenantId: ID!\n  $ownerTenantId: ID!\n) {\n  addTenantVueSharePermission(guestTenantId: $guestTenantId, ownerTenantId: $ownerTenantId) {\n    vueSharePermission {\n      id\n      ownerTenant {\n        id\n        name\n      }\n      guestTenant {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40c78be69652ec14f09eac4360add230';
module.exports = node;
