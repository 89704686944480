/**
 * @flow
 * @relayHash d568a962582413cd048d4094abb941d8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type UpdateCurrencyConversionRequestStatusMutationVariables = {|
  currencyConversionRequestId: string,
  status: ApprovableStatus,
|};
export type UpdateCurrencyConversionRequestStatusMutationResponse = {|
  +updateCurrencyConversionRequestStatus: ?{|
    +currencyConversionRequest: ?{|
      +id: string,
      +status: string,
      +account: {|
        +id: string,
        +name: ?string,
        +actualCashBalances: ?$ReadOnlyArray<?{|
          +balance: ?number,
          +currency: ?Currency,
        |}>,
        +availableCashBalances: ?$ReadOnlyArray<?{|
          +balance: ?number,
          +currency: ?Currency,
        |}>,
      |},
    |}
  |}
|};
export type UpdateCurrencyConversionRequestStatusMutation = {|
  variables: UpdateCurrencyConversionRequestStatusMutationVariables,
  response: UpdateCurrencyConversionRequestStatusMutationResponse,
|};
*/


/*
mutation UpdateCurrencyConversionRequestStatusMutation(
  $currencyConversionRequestId: ID!
  $status: ApprovableStatus!
) {
  updateCurrencyConversionRequestStatus(currencyConversionRequestId: $currencyConversionRequestId, status: $status) {
    currencyConversionRequest {
      id
      status
      account {
        id
        name
        actualCashBalances {
          balance
          currency
        }
        availableCashBalances {
          balance
          currency
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "currencyConversionRequestId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "ApprovableStatus!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "balance",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateCurrencyConversionRequestStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "currencyConversionRequestId",
        "variableName": "currencyConversionRequestId"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "UpdateCurrencyConversionRequestStatus",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "currencyConversionRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CurrencyConversionRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "actualCashBalances",
                "storageKey": null,
                "args": null,
                "concreteType": "MultiCurrencyNode",
                "plural": true,
                "selections": (v2/*: any*/)
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "availableCashBalances",
                "storageKey": null,
                "args": null,
                "concreteType": "MultiCurrencyNode",
                "plural": true,
                "selections": (v2/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCurrencyConversionRequestStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCurrencyConversionRequestStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateCurrencyConversionRequestStatusMutation",
    "id": null,
    "text": "mutation UpdateCurrencyConversionRequestStatusMutation(\n  $currencyConversionRequestId: ID!\n  $status: ApprovableStatus!\n) {\n  updateCurrencyConversionRequestStatus(currencyConversionRequestId: $currencyConversionRequestId, status: $status) {\n    currencyConversionRequest {\n      id\n      status\n      account {\n        id\n        name\n        actualCashBalances {\n          balance\n          currency\n        }\n        availableCashBalances {\n          balance\n          currency\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2eee809d4578cd74fe301848409325a';
module.exports = node;
