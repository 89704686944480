import { graphql } from 'react-relay';

// language=GraphQL
const processBrokerActivityMutation = graphql`
  mutation ProcessBrokerActivityMutation (
    $brokerActivityId: ID!
    $copyFromBrokerActivityId: ID,
    $responsibleHoldings: [AccountPortfolioHolding!]!
    $selectiveDistribution: [AccountPortfolioHoldingCash!]
    $exDate: Date
    $payDate: Date
    $validateHolding: Boolean
    $siblingBrokerActivityId: ID,
    $reverseWithBrokerActivityId: ID,
    $toAccountId: ID,
    $forceNoSibling: Boolean,
    $holdingsDate: Date
  ) {
    processBrokerActivity(
      brokerActivityId: $brokerActivityId,
      copyFromBrokerActivityId: $copyFromBrokerActivityId,
      responsibleHoldings: $responsibleHoldings
      selectiveDistribution: $selectiveDistribution
      exDate: $exDate
      payDate: $payDate
      validateHolding: $validateHolding
      siblingBrokerActivityId: $siblingBrokerActivityId
      reverseWithBrokerActivityId: $reverseWithBrokerActivityId
      toAccountId: $toAccountId
      forceNoSibling: $forceNoSibling
      holdingsDate: $holdingsDate
    ) {
      corporateAction: brokerActivity {
        ... on IBCorporateActionNode {
          ...IBCorporateAction_all
        }
      }
      dividendAccrual: brokerActivity {
        ... on IBDividendAccrualNode {
          ...IBDividendAccrual_all
        }
      }
      cashTransaction: brokerActivity {
        ... on IBCashTransactionNode {
          ...IBCashTransaction_all
        }
      }
      transactionTax: brokerActivity {
        ... on IBTransactionTaxNode {
          ...IBTransactionTax_all
        }
      }
      
      salesTax: brokerActivity {
        ... on IBSalesTaxNode {
          ...IBSalesTax_all
        }
      }
      
      siblingCorporateAction: siblingBrokerActivity {
        ... on IBCorporateActionNode {
          ...IBCorporateAction_all
        }
      }
      
      siblingCashTransaction: siblingBrokerActivity {
        ... on IBCashTransactionNode {
          ...IBCashTransaction_all
        }
      }
      
      errors {
        field
        messages
      }
    }
  }
`;

export default processBrokerActivityMutation;
