import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { useCommonStyles } from '../Styles';


function DrawerGridItemFixedCurrencyCashField(props) {
  const {
    xs,
    sm,
    ref,
    defaultValue,
    onChange,
    currency,
    label,
    id,
  } = props;
  const classes = useCommonStyles();
  return (
    <Grid item xs={xs || 12} sm={sm || 6}>
      <FormControl>
        <TextField
          className={classes.fromControlDrawer}
          id={id || (label && label.toLowerCase())}
          label={label}
          type="number"
          ref={ref}
          defaultValue={defaultValue}
          onChange={onChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
          }}
        />
      </FormControl>
    </Grid>
  );
}

export default DrawerGridItemFixedCurrencyCashField;
