import { graphql } from 'react-relay';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { toGlobalId } from '../../helpers/ID';
import PortfolioOrderDetails from './PortfolioOrderDetails';
import QueryRendererProgress from '../common/QueryRendererProgress';


const portfolioOrderDetailsContainerQuery = graphql`
  query PortfolioOrderDetailsContainerQuery (
    $portfolioOrderId: ID,
  ) {
    viewer {
      sideEnum: __type(name: "Side") { enumValues { description, name} }
      orderTypeEnum: __type(name: "OrderType") { enumValues { description, name} }
      orderStatusEnum: __type(name: "OrderStatus") { enumValues { description, name} }
      portfolioOrderStatusEnum: __type(name: "PortfolioOrderStatus") { enumValues { description, name} }
      exchanges {
        edges {
          node {
            symbol
            nextDaySession {
              startTime
              endTime
            }
          }
        }
      }
      portfolioOrders(
        id: $portfolioOrderId
      ) {
        edges {
          node {
            ...PortfolioOrderDetails_portfolioOrder
          }
        }
        count
      }
    }
  }
`;

function propsFromViewer(viewer) {
  return { 
    portfolioOrder: viewer.portfolioOrders.edges[0].node, 
    exchanges: viewer.exchanges.edges.length > 0 
      ? viewer.exchanges.edges.map(edge => edge.node || {}) 
      : [],
  };
}

function PortfolioOrderDetailsContainer(props) {
  const { portfolioOrder, exchanges, viewer } = props;
  let { portfolioOrderId } = props;
  if (portfolioOrder) {
    return (
      <PortfolioOrderDetails
        viewer={viewer}
        portfolioOrder={portfolioOrder}
        exchanges={exchanges}
      />);
  }

  const { match } = props;
  if (!portfolioOrderId) {
    const { portfolioOrderId: portfolioOrderIdFromParams } = match.params;
    portfolioOrderId = toGlobalId('PortfolioOrderNode', portfolioOrderIdFromParams);
  }

  return (
    <QueryRendererProgress
      componentClass={PortfolioOrderDetails}
      queryClass={portfolioOrderDetailsContainerQuery}
      initQueryParams={{ portfolioOrderId }}
      propsToChild={propsFromViewer}
    />
  );
}

export default withRouter(PortfolioOrderDetailsContainer);
