import { graphql } from 'react-relay';

const updateCurrencyConversionRequestStatus = graphql`
  mutation UpdateCurrencyConversionRequestStatusMutation (
    $currencyConversionRequestId: ID!
    $status: ApprovableStatus!
  ) {
    updateCurrencyConversionRequestStatus(
      currencyConversionRequestId: $currencyConversionRequestId,
      status: $status
    ) {
      currencyConversionRequest {
        id
        status
        account {
          id
          name
          actualCashBalances {
            balance
            currency
          }
          availableCashBalances {
            balance
            currency
          }
        }
      }
    }
  }
`;

export default updateCurrencyConversionRequestStatus;
