/**
 * @flow
 * @relayHash f1fe565655e542304d19603ec1a49d9c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VueLoaderQueryFragment_viewer$ref = any;
export type VueLoaderQueryVariables = {|
  first: number,
  offset: number,
  search?: ?string,
|};
export type VueLoaderQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VueLoaderQueryFragment_viewer$ref
  |}
|};
export type VueLoaderQuery = {|
  variables: VueLoaderQueryVariables,
  response: VueLoaderQueryResponse,
|};
*/


/*
query VueLoaderQuery(
  $first: Int!
  $offset: Int!
  $search: String
) {
  viewer {
    ...VueLoaderQueryFragment_viewer_2pFftX
    id
  }
}

fragment VueLoaderQueryFragment_viewer_2pFftX on Query {
  vues(first: $first, offset: $offset, search: $search, vueType: "MV", parentVue_Isnull: true) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueLoaderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VueLoaderQueryFragment_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueLoaderQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "parentVue_Isnull",
                "value": true
              },
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "vueType",
                "value": "MV"
              }
            ],
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VueLoaderQuery",
    "id": null,
    "text": "query VueLoaderQuery(\n  $first: Int!\n  $offset: Int!\n  $search: String\n) {\n  viewer {\n    ...VueLoaderQueryFragment_viewer_2pFftX\n    id\n  }\n}\n\nfragment VueLoaderQueryFragment_viewer_2pFftX on Query {\n  vues(first: $first, offset: $offset, search: $search, vueType: \"MV\", parentVue_Isnull: true) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b56cd8f5d69a8783da03a453ad53b93c';
module.exports = node;
