import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useCommonStyles } from '../Styles';

function InnerTabFilterGridItemMultiTextField(props) {
  const classes = useCommonStyles();
  const {
    size,
    onChange,
    defaultValue,
    ...otherProps
  } = props;

  return (
    <Grid item>
      <Autocomplete
        multiple
        freeSolo
        size={size}
        onChange={onChange}
        defaultValue={defaultValue}
        options={[]} // Bug fix after lab update
        renderInput={params => (
          <TextField
            className={classes.formControlMultiText}
            margin="normal"
            {...params}
            {...otherProps}
          /* This adjustment is necessary because of how autocomplete offsets the underline.*/
            {...(size === 'small' && { style: { marginTop: '11px' } })}
          />
        )}
      />
    </Grid>
  );
}

InnerTabFilterGridItemMultiTextField.propTypes = {
  ...TextField.propTypes,
  size: PropTypes.string,
};

InnerTabFilterGridItemMultiTextField.defaultProps = {
  ...TextField.defaultProps,
  size: 'medium',
};

export default InnerTabFilterGridItemMultiTextField;
