import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { useCommonStyles } from '../../common/Styles';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemParagraph from '../../common/drawer/DrawerGridItemParagraph';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import BaseButton from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import { trialCancelBrokerOrder } from '../../../mutations/CancelBrokerOrder';


export default function ConfirmCancelForm(props) {
  const classes = useCommonStyles();
  const { brokerOrderId } = props;
  const [twsCheck, setTwsCheck] = React.useState(false);
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Confirm Cancel Order"
        />
        <DrawerGridItemParagraph sm={12}>
          This is panel is to confirm cancellation of a order
          by manually checking the status of the order.
          Make sure the order not executed either by checking tws manually.
          Also if it has been couple of hours passed order placed and also couple hours passes
          &apos;same US day&apos; start, there must be un-reconclied transactions in
          <Link href="/broker-activity/ib-transactions"> Transactions </Link>
          page.
        </DrawerGridItemParagraph>
        <DrawerGridItemParagraph sm={12}>
          Press trial cancel to re-send the cancel request.
          Wait to see it cancels and update the status.
          If not confirm cancel
        </DrawerGridItemParagraph>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <BaseButton
              color="primary"
              onClick={() => {
                setTwsCheck(true);
              }}
            >
              I Checked TWS / Confirmations
            </BaseButton>
          </Grid>
        </DrawerGridItemBottomButtonContainer>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <BaseButton
              color="secondary"
              disabled={!twsCheck}
              onClickPromise={() => doCommitPromise(
                trialCancelBrokerOrder,
                { brokerOrderId },
              )}
            >
              Trial Cancel & Confirm
            </BaseButton>
          </Grid>
          {/*<Grid item>*/}
          {/*  <BaseButton*/}
          {/*    disabled={!twsCheck}*/}
          {/*    color="secondary"*/}
          {/*    onClickPromise={() => doCommitPromise(*/}
          {/*      confirmCancelBrokerOrder,*/}
          {/*      { brokerOrderId },*/}
          {/*      () => (onClose && onClose()),*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    Confirm Cancel*/}
          {/*  </BaseButton>*/}
          {/*</Grid>*/}
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}
