/**
 * @flow
 * @relayHash cedf473198d4a3dbc1b6158404560345
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type UserPermissionUpdateFormQueryVariables = {|
  userId: string,
  clientAccountId?: ?string,
|};
export type UserPermissionUpdateFormQueryResponse = {|
  +viewer: ?{|
    +authUsers: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +usermodel: ?{|
            +id: string,
            +username: string,
            +email: string,
            +firstName: string,
            +lastName: string,
            +userclientaccountpermissionSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +resource: Resource,
                  +verb: Verb,
                |}
              |}>
            |},
            +userclientaccountroleSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +userRole: {|
                    +id: string,
                    +name: string,
                  |}
                |}
              |}>
            |},
          |},
        |}
      |}>
    |}
  |}
|};
export type UserPermissionUpdateFormQuery = {|
  variables: UserPermissionUpdateFormQueryVariables,
  response: UserPermissionUpdateFormQueryResponse,
|};
*/


/*
query UserPermissionUpdateFormQuery(
  $userId: ID!
  $clientAccountId: ID
) {
  viewer {
    authUsers(id: $userId) {
      edges {
        node {
          id
          usermodel {
            id
            username
            email
            firstName
            lastName
            userclientaccountpermissionSet(clientAccountId: $clientAccountId) {
              edges {
                node {
                  resource
                  verb
                  id
                }
              }
            }
            userclientaccountroleSet(clientAccountId: $clientAccountId) {
              edges {
                node {
                  userRole {
                    id
                    name
                  }
                  id
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "clientAccountId",
    "variableName": "clientAccountId"
  }
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "resource",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "verb",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "userRole",
  "storageKey": null,
  "args": null,
  "concreteType": "UserRoleNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserPermissionUpdateFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "authUsers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "UserNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "usermodel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountpermissionSet",
                            "storageKey": null,
                            "args": (v7/*: any*/),
                            "concreteType": "UserClientAccountPermissionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountPermissionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountPermissionNode",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountroleSet",
                            "storageKey": null,
                            "args": (v7/*: any*/),
                            "concreteType": "UserClientAccountRoleNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountRoleNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountRoleNode",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserPermissionUpdateFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "authUsers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "UserNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "usermodel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountpermissionSet",
                            "storageKey": null,
                            "args": (v7/*: any*/),
                            "concreteType": "UserClientAccountPermissionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountPermissionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountPermissionNode",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userclientaccountroleSet",
                            "storageKey": null,
                            "args": (v7/*: any*/),
                            "concreteType": "UserClientAccountRoleNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserClientAccountRoleNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserClientAccountRoleNode",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserPermissionUpdateFormQuery",
    "id": null,
    "text": "query UserPermissionUpdateFormQuery(\n  $userId: ID!\n  $clientAccountId: ID\n) {\n  viewer {\n    authUsers(id: $userId) {\n      edges {\n        node {\n          id\n          usermodel {\n            id\n            username\n            email\n            firstName\n            lastName\n            userclientaccountpermissionSet(clientAccountId: $clientAccountId) {\n              edges {\n                node {\n                  resource\n                  verb\n                  id\n                }\n              }\n            }\n            userclientaccountroleSet(clientAccountId: $clientAccountId) {\n              edges {\n                node {\n                  userRole {\n                    id\n                    name\n                  }\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '243c5606beeaf1723d57fe45e2351b82';
module.exports = node;
