import React from 'react';
import PropTypes from 'prop-types';
import TitledWidgetItem from './TitledWidgetItem';
import { extractKeys } from '../../common/helpers';
import DataTable from './DataTable';
import { muiGridSizes } from '../../common/constants';


export default function DataTableWidget(props) {
  const {
    columnMeta,
    dataNodes,
    title,
    showHeadings,
    rightTopComp,
    showTitle,
    ...otherProps
  } = props;

  const [sizeProps, nonSizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <TitledWidgetItem
      title={title}
      rightTopComp={rightTopComp}
      showTitle={showTitle}
      {...sizeProps}
    >
      <DataTable
        showHeadings={showHeadings}
        columnMeta={columnMeta}
        dataNodes={dataNodes}
        {...nonSizeProps}
      />
    </TitledWidgetItem>
  );
}

DataTableWidget.propTypes = {
  title: PropTypes.string.isRequired,
  columnMeta: PropTypes.array.isRequired,
  dataNodes: PropTypes.array.isRequired,
  rightTopComp: PropTypes.object,
  showHeadings: PropTypes.bool,
};

DataTableWidget.defaultProps = {
  rightTopComp: null,
  showHeadings: true,
};
