/**
 * @flow
 * @relayHash c3923679baed7ce195489ea1951f627f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StartUserSubscriptionMutationVariables = {|
  userId: string,
  paymentAccountId?: ?string,
  subscriptionTemplateId?: ?string,
  subscriberType?: ?string,
  startDate?: ?any,
|};
export type StartUserSubscriptionMutationResponse = {|
  +startUserSubscription: ?{|
    +userSubscription: ?{|
      +billPeriodEnd: any,
      +billPeriodStart: any,
      +createdAt: any,
      +updatedAt: any,
      +id: string,
    |}
  |}
|};
export type StartUserSubscriptionMutation = {|
  variables: StartUserSubscriptionMutationVariables,
  response: StartUserSubscriptionMutationResponse,
|};
*/


/*
mutation StartUserSubscriptionMutation(
  $userId: ID!
  $paymentAccountId: ID
  $subscriptionTemplateId: ID
  $subscriberType: String
  $startDate: Date
) {
  startUserSubscription(userId: $userId, subscriberType: $subscriberType, paymentAccountId: $paymentAccountId, startDate: $startDate, subscriptionTemplateId: $subscriptionTemplateId) {
    userSubscription {
      billPeriodEnd
      billPeriodStart
      createdAt
      updatedAt
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subscriptionTemplateId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "subscriberType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "startDate",
    "type": "Date",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "startUserSubscription",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "paymentAccountId",
        "variableName": "paymentAccountId"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "subscriberType",
        "variableName": "subscriberType"
      },
      {
        "kind": "Variable",
        "name": "subscriptionTemplateId",
        "variableName": "subscriptionTemplateId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "StartUserSubscription",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userSubscription",
        "storageKey": null,
        "args": null,
        "concreteType": "UserSubscriptionNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billPeriodEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "billPeriodStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StartUserSubscriptionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "StartUserSubscriptionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "StartUserSubscriptionMutation",
    "id": null,
    "text": "mutation StartUserSubscriptionMutation(\n  $userId: ID!\n  $paymentAccountId: ID\n  $subscriptionTemplateId: ID\n  $subscriberType: String\n  $startDate: Date\n) {\n  startUserSubscription(userId: $userId, subscriberType: $subscriberType, paymentAccountId: $paymentAccountId, startDate: $startDate, subscriptionTemplateId: $subscriptionTemplateId) {\n    userSubscription {\n      billPeriodEnd\n      billPeriodStart\n      createdAt\n      updatedAt\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3568de5cb5c65bf36df7cedf2fc1cf6f';
module.exports = node;
