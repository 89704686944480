import { graphql } from 'react-relay';

// language=GraphQL
const reconcileCashTransferRequests = graphql`
  mutation ReconcileCashTransferRequestsMutation (
    $transferRequestIds: [ID]
    $brokerTransferId: ID
  ) {
    reconcileCashTransfer(
      cashTransferRequestIds: $transferRequestIds
      brokerCashTransferId: $brokerTransferId
    ) {
      cashTransferRequests {
        id
        status
        createdAt
        updatedAt
        blocked
        cashTransfer {
          createdAt
          updatedAt
          id
          brokerActivity {
            brokeractivitytransactionSet {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default reconcileCashTransferRequests;
