
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment-timezone';
import { VerticalTableWidget } from '../common/VerticalTableWidget';
import DateTime from '../common/DateTimeMoment';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import feVersionInfo from '../../versionInfo.json';


export default function VersionInfoDlg(props) {
  const {
    showVersionInfo,
    onClose,
    versionInfo,
  } = props;

  return (
    <>
      <Dialog
        open={showVersionInfo}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        // fullWidth
      >
        <DialogTitle id="form-dialog-title">Version Info</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <VerticalTableWidget
              title="API Version"
              showHeadings={false}
              columnMeta={[
                ['Latest Version', 'latestVersion'],
                ['Commit At', node => (
                  <DateTime>
                    {node.commitAt}
                  </DateTime>
                )],
                ['Commit Msg', 'commitMessage'],
                ['Commit Hash', node => (
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="baseline"
                  >
                    <Grid item>
                      { node.commitHash }
                    </Grid>
                    <TableCompactGridItem>
                      {/*<Link href={ibUrl}>IB Info</Link>*/}
                      <TableIconButton
                        href={`https://gitlab.com/macrovue_au/omnibus-backend/-/network/master?extended_sha1=${node.commitHash}`}
                        iconClass={LinkIcon}
                        tooltip="Github Link"
                      />
                    </TableCompactGridItem>
                  </Grid>
                )],
              ]}
              dataNode={versionInfo}
              xs={12}
            />

            <VerticalTableWidget
              title="Front End Version"
              showHeadings={false}
              columnMeta={[
                ['Latest Version', node => feVersionInfo.versionTag],
                ['Commit At', node => (
                  <DateTime>
                    {moment(feVersionInfo.commitDate, 'YYYY-MM-DD hh:mm:ss Z').format('YYYY-MM-DDTHH:mm:ss')}
                  </DateTime>
                )],
                // ['Commit Msg', 'commitMessage'],
                ['Commit Hash', node => (
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="baseline"
                  >
                    <Grid item>
                      { feVersionInfo.commitHash }
                    </Grid>
                    <TableCompactGridItem>
                      {/*<Link href={ibUrl}>IB Info</Link>*/}
                      <TableIconButton
                        href={`https://gitlab.com/macrovue_au/omnibus-admin/-/network/master?extended_sha1=${feVersionInfo.commitHash}`}
                        iconClass={LinkIcon}
                        tooltip="Github Link"
                      />
                    </TableCompactGridItem>
                  </Grid>
                )],
              ]}
              dataNode={versionInfo}
              xs={12}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
