import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import { toLocal } from '../../../helpers/ID';

function TenantUserInfo(props) {
  const { tenantAccount } = props;
  if (!tenantAccount) return null;

  return (
    <>
      <TitledWidgetItem
        xs={12}
        title="Users"
      >
        <Table size="small">
          <TableHead>
            <TableCell leftAlign>ID</TableCell>
            <TableCell align="left">Username</TableCell>
            <TableCell align="left">Email</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {toLocal(tenantAccount.tenant.id)}
              </TableCell>
              <TableCell>
                {tenantAccount.tenant.username}
              </TableCell>
              <TableCell>{tenantAccount.tenant.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

      </TitledWidgetItem>
    </>
  );
}

export default TenantUserInfo;
