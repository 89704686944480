import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import { useCommonStyles } from '../common/Styles';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';
import PopupContractSelectorField from '../common/PopupContractSelectorField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ProcessButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import createOrdersMutation from '../../mutations/CreateOrders';
import DrawerGripItemToggleButtonGroup from '../common/drawer/DrawerGridItemToggleButtonGroup';

function PortfolioOrderRequestFormContractOrder(props) {
  const { contractParam, onSetParam } = props;
  const classes = useCommonStyles();
  const [orderType, setOrderType] = React.useState(contractParam.orderType);
  return (
    <Grid item xs={12}>
      <Paper className={classes.paperContentHolder}>
        <DrawerGridItemContainer>
          <Grid item xs={12}>
            <PopupContractSelectorField
              caption="Select Contract"
              fullWidth
              onSelect={c => onSetParam('contract', c)}
            />
          </Grid>
          <DrawerGridItemNumberField
            label="Quantity"
            defaultValue={contractParam.quantity}
            onChange={e => onSetParam('quantity', e.target.value)}
            xs={6}
          />
          <DrawerGripItemToggleButtonGroup
            enumType={[
              { name: 'MKT', description: 'Market' },
              { name: 'LMT', description: 'Limit' },
            ]}
            helperText="Order Type"
            defaultValue={orderType}
            onChange={(newVal) => {
              setOrderType(newVal);
              onSetParam('orderType', newVal);
            }}
          />
          {
            orderType === 'LMT' && (
              <DrawerGridItemNumberField
                label="Limit Price"
                defaultValue={contractParam.limitPrice}
                onChange={e => onSetParam('limitPrice', e.target.value)}
                xs={6}
              />
            )
          }
        </DrawerGridItemContainer>
      </Paper>
    </Grid>
  );
}

export default function PortfolioOrderRequestOrderForm(props) {
  const classes = useCommonStyles();
  const { onClose, onCreate } = props;
  const [account, setAccount] = React.useState(null);
  const [contractParams] = React.useState([{
    quantity: 1,
    contract: null,
    orderType: 'MKT',
    limitPrice: 0,
  }]);
  const [allSet, setAllSet] = React.useState(false);
  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Create Order"
        />
        <Grid item xs={12}>
          <PopupAccountSelectorField
            caption="Select Account"
            fullWidth
            onSelect={a => setAccount(a)}
          />
        </Grid>
        {
          contractParams.map((contractParam, i) => (
            <PortfolioOrderRequestFormContractOrder
              contractParam={contractParam}
              onSetParam={(paramName, paramValue) => {
                contractParams[i][paramName] = paramValue;
                setAllSet(contractParams.every(x => x.contract !== null));
              }}
            />
          ))
        }
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ProcessButton
              disabled={!allSet || account === null}
              buttonText="Place Order"
              onClickPromise={() => doCommitPromise(
                createOrdersMutation,
                {
                  withMakerChecker: true,
                  portfolioOrders: [{
                    accountId: account.id,
                    portfolioOrderSide: contractParams[0].quantity < 0 ? 'SELL' : 'BUY',
                    marketOrders: contractParams.filter(x => x.orderType === 'MKT').map(x => ({
                      contractId: x.contract.id,
                      side: x.quantity < 0 ? 'SELL' : 'BUY',
                      quantity: Math.abs(x.quantity),
                      refPrice: 0,
                    })),
                    limitOrders: contractParams.filter(x => x.orderType === 'LMT').map(x => ({
                      contractId: x.contract.id,
                      side: x.quantity < 0 ? 'SELL' : 'BUY',
                      quantity: Math.abs(x.quantity),
                      limitPrice: parseFloat(x.limitPrice),
                    })),
                  }],
                },
                () => {
                  onClose && onClose();
                  onCreate && onCreate();
                },
                () => null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}
