import { graphql } from 'react-relay';

// language=GraphQL
const generateDividendPayouts = graphql`
  mutation GenerateDividendPayoutsMutation (
    $forDate: Date!
  ) {
      generateDividendPayoutCashTransfers(createCtrs: false, forDate: $forDate, skipFailing: true) {
        csvText
      }
    }
`;

export default generateDividendPayouts;
