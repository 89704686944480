import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCommonStyles } from '../Styles';


function DrawerGridItemLoaderField(props) {
  const { xs, sm } = props;
  const {
    label,
    id,
    loader,
    onChange,
    defaultValue,
    loaderProps,
  } = props;
  const classes = useCommonStyles();
  const LoaderClass = loader;
  return (
    <Grid item xs={xs || 12} sm={sm || 6}>
      <LoaderClass
        className={classes.fromControlDrawer}
        id={id || label.toLowerCase()}
        label={label}
        onChange={onChange}
        defaultValue={defaultValue || ''}
        InputLabelProps={{
          shrink: true,
        }}
        {...(loaderProps || {})}
      />
    </Grid>
  );
}

export default DrawerGridItemLoaderField;
