import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import DataTableWidget from '../common/DataTableWidget';
import UserSubscriptionForm from './UserSubscriptionForm';
import userSubscriptionColumns from './helper';


export default function UserSubscriptionsDetails(props) {
  const {
    user,
    subscriptionSet,
    showTitle,
    showUserColumn,
    showAdd,
    onAdd,
  } = props;
  const [addDrawerOpen, setAddDrawerOpen] = React.useState(false);
  return (
    <>
      <Drawer
        anchor="right"
        onClose={() => setAddDrawerOpen(false)}
        open={addDrawerOpen}
      >
        <UserSubscriptionForm
          user={user}
          onClose={() => setAddDrawerOpen(null)}
          onAdd={onAdd}
        />
      </Drawer>

      <DataTableWidget
        xs={12}
        key={subscriptionSet}
        rightTopComp={showAdd && (
          <IconButton
            color="primary"
            aria-label="delete"
            onClick={() => setAddDrawerOpen(true)}
          >
            <Add />
          </IconButton>
        )}
        title="Subscriptions"
        showTitle={showTitle}
        columnMeta={userSubscriptionColumns(showUserColumn)}
        dataNodes={subscriptionSet}
      />

    </>

  );
}


UserSubscriptionsDetails.propTypes = {
  showTitle: PropTypes.bool,
  showUserColumn: PropTypes.bool,
  showAdd: PropTypes.bool,
  subscriptionSet: PropTypes.array.isRequired,
};

UserSubscriptionsDetails.defaultProps = {
  showTitle: true,
  showUserColumn: false,
  showAdd: false,
};


graphql`
  fragment UserSubscriptionsDetails_all on UserSubscriptionNode {
    subscriberType
    id
    createdAt
    updatedAt
    subscriptionTemplate {
      code
      name
    }
    user {
      id
    }
    billPeriodStart
    billPeriodEnd
    pendingCancel
    active
    paymentAccount {
      id
      name
    }
  }
`;
