import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import BrokerActivityDetails from './BrokerActivityDetails';
import { useCommonStyles } from '../common/Styles';
import BrokerActivityActions from './BrokerActivityActions';
import TableFieldMeta from '../../common/TableFieldMeta';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import MsgRouter from '../../common/MsgRouter';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import ProcessForm from './forms/ProcessForm';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import RTTableRenderer from '../common/table/RTTableRenderer';

function TransactionTaxList(props) {
  const { viewer, relay, filter } = props;
  const classes = useCommonStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = React.useState(false);
  const [msgRouter] = React.useState(new MsgRouter());
  filter.setRelay(relay);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    BrokerActivityTableFieldMeta.conId,
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.field('description', 'Description', { width: 300 }),
    TableFieldMeta.field('taxDescription', 'Tax Description', { width: 300 }),
    BrokerActivityTableFieldMeta.isin,
    BrokerActivityTableFieldMeta.symbol,
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.field('tradeId', 'Trade ID', { align: 'right' }),
    TableFieldMeta.field('taxAmount', 'Tax Amount', { align: 'right' }),
    {
      id: 'actions',
      accessor: node => node,
      Header: 'Actions',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={() => {
            setBrokerActivity(row.value);
            setDrawerOpen(true);
          }}
          onShowDetails={() => {
            setBrokerActivity(row.value);
            setDetailsOpen(true);
          }}
        />
      ),
    },
    BrokerActivityTableFieldMeta.ignore(msgRouter),
    TableFieldMeta.notes(row => row.value),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <ProcessForm
            brokerActivity={brokerActivity}
            close={() => setDrawerOpen(false)}
            nodeName="IBTransactionTaxNode"
          />
        </div>
      </Drawer>

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails brokerActivity={brokerActivity} />
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          defaultValue={filter.params.search}
          helperText="symbol, description"
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="con-id-filter"
          label="Con ID"
          type="number"
          defaultValue={filter.params.ibConId}
          onChange={e => filter.updateVar('ibConId', (e.target.value && Number(e.target.value)) || null)}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          halfWidth
          defaultValue={filter.params.symbol}
          onChange={e => filter.updateVar('symbol', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="Currency"
          label="Currency"
          halfWidth
          value={filter.params.currency}
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.reportDate}
          onChange={value => filter.updateVar('reportDate', value)}
          label="Report Date"
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: false, description: 'Yes' },
            { name: true, description: 'No' },
          ]}
          helperText="Processed?"
          defaultValue={filter.params.processedAt_Isnull}
          onChange={newVal => filter.updateVar('processedAt_Isnull', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Ignored?"
          defaultValue={filter.params.ignored}
          onChange={newVal => filter.updateVar('ignored', newVal)}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.ibTransactionTaxes : null)}
            filter={filter}
            tableRender={RTTableRenderer}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query TransactionTaxListQuery (
    $first: Int
    $offset: Int
    $search: String
    $reportDate: Date
    $symbol: String
    $currency: String
    $ibConId: Int
    $processedAt_Isnull: Boolean
    $ignored: Boolean
  ) {
    viewer {
      ...TransactionTaxList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        reportDate: $reportDate
        symbol: $symbol
        currency: $currency
        ibConId: $ibConId
        processedAt_Isnull: $processedAt_Isnull
        ignored: $ignored
      )
    }
  }
`;

const container = createRefetchContainer(
  TransactionTaxList,
  {
    viewer: graphql`
      fragment TransactionTaxList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        search: {type: String}
        reportDate: {type: Date}
        symbol: {type: String}
        currency: {type: String}
        ibConId: {type: Int}
        processedAt_Isnull: {type: Boolean}
        ignored: {type: Boolean}
      ) {
        ibTransactionTaxes(
          first: $first
          offset: $offset
          search: $search
          reportDate: $reportDate
          symbol: $symbol
          currency: $currency
          ibConId: $ibConId
          processedAt_Isnull: $processedAt_Isnull
          ignored: $ignored
        ) {
          edges {
            node {
              ...IBTransactionTax_all @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  {
    reportDate: null,
    processedAt_Isnull: null,
    ignored: null,
  },
  {
    debounceFields: ['search', 'symbol', 'conId', 'currency'],
  },
));
