/**
 * @flow
 * @relayHash 3a0441900cbdb9b0f93318f07fc6e8ee
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChargesList_viewer$ref = any;
export type ChargesListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  accountId?: ?string,
|};
export type ChargesListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ChargesList_viewer$ref
  |}
|};
export type ChargesListQuery = {|
  variables: ChargesListQueryVariables,
  response: ChargesListQueryResponse,
|};
*/


/*
query ChargesListQuery(
  $first: Int
  $offset: Int
  $search: String
  $accountId: ID
) {
  viewer {
    ...ChargesList_viewer_13Yiz5
    id
  }
}

fragment ChargesList_viewer_13Yiz5 on Query {
  manualAccountCharges(orderBy: "-createdAt", first: $first, offset: $offset, search: $search, accountId: $accountId) {
    edges {
      node {
        id
        amount
        description
        createdAt
        updatedAt
        feeType
        currency
        account {
          id
          name
        }
        accountchargePtr {
          accountchargetenantportionSet {
            edges {
              node {
                quantity
                tenant {
                  name
                  id
                }
                id
              }
            }
          }
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChargesListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ChargesList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ChargesListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "manualAccountCharges",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v4/*: any*/)
            ],
            "concreteType": "ManualAccountChargeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ManualAccountChargeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ManualAccountChargeNode",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "feeType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "accountchargePtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountChargeNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "accountchargetenantportionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountChargeTenantPortionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AccountChargeTenantPortionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AccountChargeTenantPortionNode",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "quantity",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "tenant",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "TenantNode",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v5/*: any*/)
                                        ]
                                      },
                                      (v5/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ChargesListQuery",
    "id": null,
    "text": "query ChargesListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $accountId: ID\n) {\n  viewer {\n    ...ChargesList_viewer_13Yiz5\n    id\n  }\n}\n\nfragment ChargesList_viewer_13Yiz5 on Query {\n  manualAccountCharges(orderBy: \"-createdAt\", first: $first, offset: $offset, search: $search, accountId: $accountId) {\n    edges {\n      node {\n        id\n        amount\n        description\n        createdAt\n        updatedAt\n        feeType\n        currency\n        account {\n          id\n          name\n        }\n        accountchargePtr {\n          accountchargetenantportionSet {\n            edges {\n              node {\n                quantity\n                tenant {\n                  name\n                  id\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e877584435c23d7bc7db5e08458cf0f4';
module.exports = node;
