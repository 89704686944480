import { graphql } from 'react-relay';

// language=GraphQL
const createUserRoleMutation = graphql`
  mutation CreateUserRoleMutation(
    $name: String!
    $defaultPermissions: String
    $rolePermissions: [UserClientAccountPermissionInput]
  ) {
    createUserRole(
      name: $name,
      defaultPermissions: $defaultPermissions
      rolePermissions: $rolePermissions
    ) {
    userRole {
        id
        name
        rolePermissions {
          edges {
            node {
              id
              resource
              verb
            }
          }
        }
      }
    }
  }
`;

export default createUserRoleMutation;
