import { graphql } from 'react-relay';

// language=GraphQL
const uploadImageContract = graphql`
  mutation ContractUploadImageMutation(
    $contractId: ID!
    $file: Upload!
    $updateShared: Boolean
  ) {
    uploadContractImage(
      contractId: $contractId, 
      file: $file
      updateShared: $updateShared
    ) {
      contract {
        id
        imageUrl
      }
    }
  }
`;

export const emulateContractPrice = graphql`
   mutation ContractEmulatePriceMutation(
    $requestId: ID
    $price: String
  ) {
    trademanEmulateMarketPrice(
      requestId: $requestId,
      price: $price
    ) {
      brokerOrders {
        id
      }
      
      contract {
        ...ContractDetails_contract
      }
    }
  }
`;


export const setContractCachePrice = graphql`
   mutation ContractSetCachePriceMutation(
    $contractId: ID!
    $price: Decimal!
  ) {
    factsetSetCachePrice(
      contractId: $contractId,
      price: $price
    ) {
      contract {
        ...ContractDetails_contract
      }
    }
  }
`;


export default uploadImageContract;
