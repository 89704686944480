import * as React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { useCommonStyles } from '../Styles';

function EntitySelectionDlg(props) {
  const {
    multiSelect,
    searchComp: SearchComp,
    dialogTitle,
    onSelectItems,
    closeOnSingleSelect,
    isOpen: isOpenProps,
    onClose,
  } = props;

  const [isOpen, setIsOpen] = React.useState(isOpenProps);
  const classes = useCommonStyles();
  return (
    <Dialog
      fullScreen
      onClose={() => setIsOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setIsOpen(false)}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent dividers>

        <div className={classes.innerDialogRoot}>
          <SearchComp
            closeOnSingleSelect={closeOnSingleSelect}
            onSelectItems={(items) => {
              setIsOpen(false);
              if (onSelectItems) {
                onSelectItems(items);
              }
            }}
          />
        </div>

      </DialogContent>
      <DialogActions>
        {
          multiSelect && (
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(false);
                if (onClose) onClose();
              }}
              color="primary"
            >
              Select
            </Button>
          )
        }
        <Button
          variant="contained"
          onClick={() => {
            setIsOpen(false);
            if (onClose) onClose();
          }}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EntitySelectionDlg.propTypes = {
  multiSelect: PropTypes.bool,
  onSelectItems: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  closeOnSingleSelect: PropTypes.bool,
  searchComp: PropTypes.object.isRequired,
  dialogTitle: PropTypes.string,
};

EntitySelectionDlg.defaultProps = {
  multiSelect: false,
  onSelectItems: null,
  onClose: null,
  closeOnSingleSelect: false,
  dialogTitle: '',
};

export default EntitySelectionDlg;
