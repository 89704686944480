import { makeStyles } from '@material-ui/core/styles';

export function containerStyles(theme) {
  return {
    buttonContainer: {
      // magenta: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(2),
    },
  };
}

export function singleButtonContainerStyles(theme) {
  return {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignSelf: 'flex-end',
      marginBottom: theme.spacing(2),
    },
  };
}

export function commonStyles(theme) {
  return {
    pageRoot: {
      flexGrow: 1,
      width: '100%',
    },
    innerTabRoot: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    innerDialogRoot: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    expandedDetails: {
      backgroundColor: '#a0a0a0',
    },
    tableHolderRoot: {
      padding: '5px 10px 20px 10px',
    },
    tableHolderRootNoFooter: {
      padding: '5px 10px 0px 10px',
      width: '100%',
    },
    paginatedTableHolderRoot: {
      padding: '5px 10px 0px 10px',
    },
    pageSectionRow: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    bottomButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
    shortBottomButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    formControl: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 200,
    },
    formControlToggleButtonGroup: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    formControlHalf: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 100,
    },
    formControlDate: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: 150,
    },
    formControlMultiText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 350,
    },
    fromControlDrawer: {
      width: '100%',
    },
    formControlContainer: {
      marginBottom: theme.spacing(2),
    },
    drawerRoot: {
      flexGrow: 1,
      width: 400,
      padding: theme.spacing(2),
    },
    drawerBottom: {
      flexGrow: 1,
      width: 'auto',
      height: 255,
      padding: theme.spacing(2),
    },
    drawerRootExtended: {
      flexGrow: 1,
      width: 800,
      padding: theme.spacing(2),
    },
    fileDropZone: {
      marginTop: '10px',
      height: '75px',
      fontSize: '12px',
      color: '#a0a0a0',
    },
    widgetHeadingContainer: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '8px',
    },
    inPaperButtonWrapper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    fullWidthFormControls: {
      width: '100%',
    },
    limitedWidthLink: {
      minWidth: '160px',
      marginLeft: '5px',
      marginRight: '5px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    dialogTitle: {
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    },
    dialogAppBar: {
      position: 'relative',
    },
    appAccountLink: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingBottom: '3px',
      borderRadius: '10px',
      fontSize: '10px',
      textDecoration: 'none',
      verticalAlign: 'text-top',
    },
    logoWidget: {
      borderRadius: '10px 0 10px 10px',
      padding: '0 15px',
      margin: '6px 15px',
      display: 'flex',
      alignItems: 'center',
    },
    logoWidgetContainer: {
      minHeight: '140px',
      justifyContent: 'center',
      display: 'flex',
    },
    paperContentHolder: {
      padding: '10px 10px 20px 10px',
      backgroundColor: theme.palette.type === 'dark' ? '#212121' : '#f3f3f3',
    },
    typographyWordWrap: {
      wordWrap: 'break-word',
    },
    ...singleButtonContainerStyles(theme),
  };
}


export function styleMerger(...style) {
  return (theme) => {
    let out = {};
    Object.keys(style).forEach((styleFn) => {
      const styler = style[styleFn];
      out = {
        ...out,
        ...styler(theme),
      };
    });
    return out;
  };
}


export const useCommonStyles = makeStyles(theme => ({
  ...commonStyles(theme),
}));
