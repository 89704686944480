import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-relay';
import DataTableWidget from '../../common/DataTableWidget';
import TableFieldMeta from '../../../common/TableFieldMeta';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


export default function ApprovalActionLogDataTable(props) {
  const {
    actionLogs,
    title,
    ...otherProps
  } = props;

  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <DataTableWidget
      {...sizeProps}
      title={title}
      columnMeta={[
        TableFieldMeta.dateTimeField('actionTime', 'Action Time', node => node.actionTime),
        TableFieldMeta.field('user', 'User', { accessor: node => node.user && node.user.fullName }),
        TableFieldMeta.enum('ActionType', 'Action Type', node => node.actionType),
        TableFieldMeta.field('actionDescription', 'Action Description'),
      ]}
      dataNodes={actionLogs}
    />
  );
}

ApprovalActionLogDataTable.propTypes = {
  actionLogs: PropTypes.array.isRequired,
  title: PropTypes.string,
};

ApprovalActionLogDataTable.defaultProps = {
  title: 'Action Log',
};


graphql`
  fragment ApprovalActionLogDataTable_actionLogs on OrderApprovalNode {
    actionLogs {
      edges {
        node {
          id
          actionDescription
          actionId
          actionTime
          actionType
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;
