import * as React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import { Drawer } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableStatus from '../common/TableStatus';
import MoreButton from '../common/buttons/MoreButton';
import { fromGlobalId } from '../../helpers/ID';
import { AddButton } from '../common/buttons/BaseButton';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import TenantLink from '../common/links/TenantLink';
import AdviserLink from '../common/links/AdviserLink';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import PopupTenantSelectorField from '../common/PopupTenantSelectorField';
import VueAddForm from './VueAddForm';

const getDraft = (vue) => {
  let draft = null;
  for (let i = 0; i < vue.vueSet.edges.length; i += 1) {
    const childVue = vue.vueSet.edges[i];
    if (!childVue.node.published) {
      draft = childVue.node;
      break;
    }
  }
  return draft;
};

function VueList(props) {
  const {
    viewer,
    relay,
    filter,
    showOwners,
    showAdd,
    showTenantFilter,
    tenantId,
  } = props;
  filter.setRelay(relay);
  const [showAddDrawer, setShowAddDrawer] = React.useState(false);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('name', 'Name'),
    {
      id: 'status',
      accessor: node => node,
      Header: 'Status',
      Cell: (node) => {
        const draft = getDraft(node.value);
        return (
          <Grid container spacing={1} justify="center">
            <Grid item>
              {node.value.visible ? <TableStatus primary>Visible</TableStatus>
                : <TableStatus>Hidden</TableStatus>
              }
            </Grid>
            {
              draft && (
                <Grid item>
                  <TableStatus secondary>Draft</TableStatus>
                </Grid>
              )
            }

          </Grid>
        );
      },
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'contracts',
      Header: 'Contracts',
      accessor: node => node,
      Cell: row => (
        <Typography variant="caption" component="h2">
          {
            row.value.vuecontractSet.edges.map(
              (vueContract, i) => (
                <React.Fragment>
                  {i !== 0 && (<span>&nbsp;&bull;&nbsp;</span>)}
                  {`${vueContract.node.contract.symbol}`}
                </React.Fragment>
              ),
            )
          }
        </Typography>
      ),
    },
    TableFieldMeta.createdAt,
    TableFieldMeta.updatedAt,
    {
      id: 'more',
      Header: '',
      accessor: node => node,
      Cell: row => <MoreButton href={`/vue/${fromGlobalId(row.value.id)[1]}`} />,
    },
    ...(showOwners ? [
      TableFieldMeta.fieldCell('tenant', 'Tenant',
        row => <TenantLink tenant={row.value.tenant} />),
      TableFieldMeta.fieldCell('adviser', 'Adviser',
        row => <AdviserLink adviser={row.value.adviser} />),
    ] : []),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        onClose={() => setShowAddDrawer(false)}
        open={showAddDrawer}
        anchor="right"
      >
        <VueAddForm
          tenantId={tenantId}
          onAdd={() => {
            setShowAddDrawer(false);
            filter.update();
          }}
        />
      </Drawer>


      <Grid container spacing={2}>

        <Grid item xs={8}>
          <BaseGridContainer>
            <InnerTabFilterGridItemTextField
              id="search-filter"
              label="Custom Search"
              helperText="symbol, description"
              defaultValue={filter.params.search}
              onChange={e => filter.updateVar('search', e.target.value)}
            />
            {showTenantFilter && (
              <Grid item>
                <PopupTenantSelectorField
                  caption="By Tenant"
                  onSelect={newTenant => filter.updateVar('tenantId', newTenant.id)}
                  className={classes.formControl}
                  onSetNull={() => filter.updateVar('tenantId', null)}
                />
              </Grid>
            )
            }

          </BaseGridContainer>
        </Grid>

        {showAdd && (
          <Grid item xs={4}>
            <Grid container justify="flex-end">
              <Grid item>
                <AddButton onClick={() => setShowAddDrawer(true)} />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.vues : null)}
          />
        </Grid>

      </Grid>
    </div>
  );
}

VueList.propTypes = {
  showOwners: PropTypes.bool,
  showAdd: PropTypes.bool,
  showTenantFilter: PropTypes.bool,
};

VueList.defaultProps = {
  showOwners: false,
  showAdd: true,
  showTenantFilter: false,
};


const query = graphql`
  query VueListQuery (
    $first: Int
    $offset: Int
    $vueType: String
    $relevantToTenant: ID
    $search: String
    $tenantId: ID
  ) {
    viewer {
      ...VueList_viewer
      @arguments (
        first: $first
        offset: $offset
        vueType: $vueType
        relevantToTenant: $relevantToTenant
        search: $search
        tenantId: $tenantId
      )
    }
  }
`;

const container = createRefetchContainer(
  VueList,
  {
    viewer: graphql`
      fragment VueList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        vueType: {type: String}
        relevantToTenant: {type: ID, defaultValue: null}
        search: {type: String}
        tenantId: {type: ID}
      ) {
        vues(
          first: $first
          offset: $offset
          orderBy: "name"
          vueType: $vueType
          parentVue_Isnull: true
          relevantToTenant: $relevantToTenant
          search: $search
          tenant: $tenantId
        ) {
          edges {
            node {
              id
              name
              description
              visible
              published
              createdAt
              updatedAt
              vueSet(
                orderBy: "-id"
              ){
                edges {
                  node {
                    id
                    published
                  }
                }
              }
              
              tenant {
                name
                id
              }
              
              adviser {
                id
                firstName
                lastName
              }
              
              vuecontractSet {
                edges {
                  node {
                    id 
                    contract {
                      id
                      symbol
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  () => ({
  }),
  ({ vueType, relevantToTenant }) => ({ vueType, relevantToTenant }),
));
