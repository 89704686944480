import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { MOMENT_DATE_FORMAT, MOMENT_MONTH_FORMAT, MOMENT_TIME_FORMAT } from '../../common/constants';

const MOMENT_TIME_FORMAT_COMPACT = 'Z.MMMD.ddd.hh:mm:ss.a';

const useStyles = makeStyles(theme => ({
  customWidth: {
    maxWidth: 200,
    width: 'fixContent',
    textAlign: 'center',
  },
}));


// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-US');

const DateTime = function DateTime(props) {
  const classes = useStyles();
  const {
    children: childrenTime,
    utcOffset,
    momentObject,
    outFormat,
    tooltipHeading,
  } = props;

  const inTime = momentObject || childrenTime;
  
  const rawOutTime = utcOffset ? moment(inTime).utcOffset(utcOffset) : moment(inTime);
  const outTime = rawOutTime.format(outFormat || MOMENT_TIME_FORMAT);
  
  const dateTarget = new Date(inTime);
  const diffStr = timeAgo.format(dateTarget);
  const sydneyStr = `SYD.${moment(inTime).tz('Australia/Sydney').format(MOMENT_TIME_FORMAT_COMPACT)}`;
  const utcStr = `UTC.${moment(inTime).utc().format(MOMENT_TIME_FORMAT_COMPACT)}`;
  const londonStr = `LON.${moment(inTime).tz('Europe/London').format(MOMENT_TIME_FORMAT_COMPACT)}`;
  const nycStr = `NYC.${moment(inTime).tz('America/New_York').format(MOMENT_TIME_FORMAT_COMPACT)}`;
  const tokyoStr = `TOK.${moment(inTime).tz('Asia/Tokyo').format(MOMENT_TIME_FORMAT_COMPACT)}`;


  let tooltipText = '';
  if (tooltipHeading) tooltipText = `${tooltipHeading}:  `;
  if (process.env.DEPLOYMENT_CODE === 'UK') {
    tooltipText += `
      ${diffStr}
      ${londonStr}
      ${utcStr}
      ${sydneyStr}
      ${nycStr}
      ${tokyoStr}
    `;
  } else {
    tooltipText += `
      ${diffStr}
      ${sydneyStr}
      ${utcStr}
      ${londonStr}
      ${nycStr}
      ${tokyoStr}
    `;
  }

  return (
    <Tooltip title={tooltipText} classes={{ tooltip: classes.customWidth }}>
      <span>
        <React.Fragment>
          { inTime && outTime }
        </React.Fragment>
      </span>
    </Tooltip>
  );
};

DateTime.propTypes = {
  utcOffset: PropTypes.number,
  momentObject: PropTypes.object,
  outFormat: PropTypes.string,
  tooltipHeading: PropTypes.string,
};

DateTime.defaultProps = {
  utcOffset: null,
  momentObject: null,
  outFormat: null,
  tooltipHeading: null,
};


export const DateComp = function DateComp(props) {
  const { children: inTime } = props;
  if (!inTime) {
    return <React.Fragment />;
  }

  const outTime = moment(inTime).format(MOMENT_DATE_FORMAT);

  return (
    <React.Fragment>
      { outTime }
    </React.Fragment>
  );
};

export const MonthComp = function MonthComp(props) {
  const { children: inTime } = props;
  if (!inTime) {
    return <React.Fragment />;
  }

  const outTime = moment(inTime).format(MOMENT_MONTH_FORMAT);
  return (
    <React.Fragment>
      { outTime }
    </React.Fragment>
  );
};


export default DateTime;
