import { graphql } from 'react-relay';

const reopenAccount = graphql`
  mutation ReopenAccountMutation(
    $accountId: ID!
  ) {
    reopenAccount(
      accountId: $accountId
    ) {
      account {
        id
        updatedAt
        clientaccount {
          id
          accountStatus
          updatedAt
        }
      }
    }
  }
`;

export default reopenAccount;
