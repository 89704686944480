import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const loaderQuery = graphql`
  query PortfolioLoaderQuery (
    $first: Int!
    $offset: Int!
    $accountId: ID!
  ) {
    viewer {
      ...PortfolioLoaderFragment_viewer
      @arguments(
        first: $first,
        offset: $offset,
        accountId: $accountId,
      )
    }
  }
`;

const refetchContainer = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment PortfolioLoaderFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID, defaultValue: null}
      ) {
        accounts (
          first: $first
          offset: $offset
          id: $accountId
        ) {
          edges {
            node {
              id
              name
              portfolios {
                edges {
                  node {
                    id
                    trackingVue {
                      id 
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  loaderQuery,
);

const getOptionLabel = (option) => {
  if (!option) return null;
  return option.trackingVue ? option.trackingVue.name : 'Stocks I\'m Holding';
};
const nodeCollector = (viewer) => {
  if (viewer && viewer.accounts && viewer.accounts.edges.length > 0) {
    return viewer.accounts.edges[0].node.portfolios.edges.map(edge => edge.node);
  }
  return [];
};

export default function PortfolioLoader(props) {
  const { account } = props;
  return (
    <FieldLoader
      loaderQuery={loaderQuery}
      refetchContainer={refetchContainer}
      getOptionLabel={getOptionLabel}
      nodeCollector={nodeCollector}
      queryParams={{ accountId: account.id }}
      {...props}
    />
  );
}
