import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CloseButton from '../common/buttons/CloseButton';
import notiStack from '../../common/notiStack';
import updateVueContractMutation from '../../mutations/UpdateVueContractMutation';
import FileUpload from '../common/FileUpload';
import { doCommitPromise } from '../../common/commit';
import { useCommonStyles } from '../common/Styles';
import { UpdateButton } from '../common/buttons/BaseButton';
import DrawerGridItemSubGroupHeading from '../common/drawer/DrawerGridItemSubGroupHeading';


function VueContractDetailsForm(props) {
  const {
    vueContract, enableEditing, onClose,
  } = props;
  const classes = useCommonStyles();

  const [weight, setWeight] = React.useState(vueContract.weight);
  const [description, setDescription] = React.useState(vueContract.description);
  const [researchPdf, setResearchPdf] = React.useState(null);

  const doUpdateDetails = () => {
    const uploadables = researchPdf ? {
      map: '{ "0": ["variables.researchPdf"]}',
      0: researchPdf,
    } : null;

    doCommitPromise(
      updateVueContractMutation,
      {
        vueContractId: vueContract.id,
        weight,
        description,
      },
      () => {
        notiStack.success('Vue contract details updated');
        onClose();
      },
      () => null,
      () => null,
      uploadables,
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Update Vue Contract
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-number"
            label="Weight"
            value={weight}
            onChange={e => setWeight(e.target.value)}
            type="number"
            disabled={!enableEditing}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            id="standard-multiline-static"
            multiline
            label="Description"
            rowsMax="12"
            disabled={!enableEditing}
            defaultValue={description}
            className={classes.textField}
            margin="normal"
            fullWidth
            onChange={e => setDescription(e.target.value)}
          />
        </Grid>
        <DrawerGridItemSubGroupHeading heading="Research" />
        { vueContract.researchPdfUrl && (
          <Grid item xs={12}>
            <Chip
              size="small"
              icon={<AttachmentIcon />}
              label={vueContract.contract.symbol}
              clickable
              component="a"
              href={vueContract.researchPdfUrl}
              color="primary"
              target="_blank"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <FileUpload
            onChange={(filesIn) => {
              setResearchPdf(filesIn[0]);
            }}
            singleFile
            displayList
            acceptedMimeTypes={['application/pdf']}
          />
        </Grid>
      </Grid>
      <div className={classes.bottomButtonContainer}>
        <CloseButton onClick={onClose} />
        {
          enableEditing && (
            <UpdateButton
              onClickPromise={doUpdateDetails}
              buttonText="Update"
            />
          )
        }
      </div>
    </div>
  );
}

export default VueContractDetailsForm;
