import { graphql } from 'react-relay';

export const uploadCustomReportMutation = graphql`
  mutation ReportUploadCustomMutation (
    $clientAccountId: ID!
    $name: String
    $pdf: Upload
    $xlsx: Upload
  ) {
    uploadCustomReport(
      clientAccountId: $clientAccountId
      name: $name
      pdf: $pdf
      xlsx: $xlsx
    ) {
      report {
        reportPtr {
          ...Report_all @relay(mask: false)
        }
      }
    }
  }
`;


export const deleteReportMutation = graphql`
  mutation ReportDeleteMutation (
    $reportId: ID!
  ) {
    deleteReport(
      reportId: $reportId
    ) {
      deletedReportId
    }
  }
`;
