/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PortfolioLoaderFragment_viewer$ref: FragmentReference;
declare export opaque type PortfolioLoaderFragment_viewer$fragmentType: PortfolioLoaderFragment_viewer$ref;
export type PortfolioLoaderFragment_viewer = {|
  +accounts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +portfolios: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +trackingVue: ?{|
                +id: string,
                +name: string,
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: PortfolioLoaderFragment_viewer$ref,
|};
export type PortfolioLoaderFragment_viewer$data = PortfolioLoaderFragment_viewer;
export type PortfolioLoaderFragment_viewer$key = {
  +$data?: PortfolioLoaderFragment_viewer$data,
  +$fragmentRefs: PortfolioLoaderFragment_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "PortfolioLoaderFragment_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "accounts",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "accountId"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        }
      ],
      "concreteType": "AccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolios",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PortfolioNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "PortfolioNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "trackingVue",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "VueNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b8bdb469d26938ad6670efdee0b7674';
module.exports = node;
