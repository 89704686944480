/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DailyReconcileList_viewer$ref: FragmentReference;
declare export opaque type DailyReconcileList_viewer$fragmentType: DailyReconcileList_viewer$ref;
export type DailyReconcileList_viewer = {|
  +ibStatementOfFunds: ?{|
    +count: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +file: string,
        +fileUrl: ?string,
        +createdAt: any,
        +updatedAt: any,
        +fromDate: any,
        +toDate: any,
        +ibdailyreconciliation: ?{|
          +id: string,
          +createdAt: any,
          +updatedAt: any,
          +excelFile: ?string,
          +excelFileUrl: ?string,
          +excelFileName: ?string,
          +ibdailyreconciliationendbalanceSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +createdAt: any,
                +updatedAt: any,
                +currency: Currency,
                +quantity: number,
              |}
            |}>
          |},
          +ibdailyreconciliationdeltaSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +createdAt: any,
                +updatedAt: any,
                +currency: Currency,
                +quantity: number,
              |}
            |}>
          |},
        |},
      |}
    |}>,
  |},
  +$refType: DailyReconcileList_viewer$ref,
|};
export type DailyReconcileList_viewer$data = DailyReconcileList_viewer;
export type DailyReconcileList_viewer$key = {
  +$data?: DailyReconcileList_viewer$data,
  +$fragmentRefs: DailyReconcileList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "DailyReconcileList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ibStatementOfFunds",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-fromDate"
        }
      ],
      "concreteType": "IBStatementOfFundsNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "IBStatementOfFundsNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "IBStatementOfFundsNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "file",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fileUrl",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fromDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "toDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "ibdailyreconciliation",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "IBDailyReconciliationNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "excelFile",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "excelFileUrl",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "excelFileName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "ibdailyreconciliationendbalanceSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "IBDailyReconciliationEndBalanceNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "IBDailyReconciliationEndBalanceNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "IBDailyReconciliationEndBalanceNode",
                              "plural": false,
                              "selections": (v3/*: any*/)
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "ibdailyreconciliationdeltaSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "IBDailyReconciliationDeltaNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "IBDailyReconciliationDeltaNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "IBDailyReconciliationDeltaNode",
                              "plural": false,
                              "selections": (v3/*: any*/)
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3bb9e84758b54e7e01cb391db09190ae';
module.exports = node;
