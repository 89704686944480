/**
 * @flow
 * @relayHash e14fe3b2244784eb922beb050499cc35
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateVueResearchMutationVariables = {|
  vueId: string,
  title: string,
  researchPdf: any,
|};
export type CreateVueResearchMutationResponse = {|
  +createVueResearch: ?{|
    +vueResearch: ?{|
      +id: string,
      +title: string,
      +researchPdfUrl: ?string,
      +createdAt: any,
      +updatedAt: any,
      +createdBy: ?{|
        +id: string,
        +username: string,
      |},
    |}
  |}
|};
export type CreateVueResearchMutation = {|
  variables: CreateVueResearchMutationVariables,
  response: CreateVueResearchMutationResponse,
|};
*/


/*
mutation CreateVueResearchMutation(
  $vueId: ID!
  $title: String!
  $researchPdf: Upload!
) {
  createVueResearch(vueId: $vueId, title: $title, researchPdf: $researchPdf) {
    vueResearch {
      id
      title
      researchPdfUrl
      createdAt
      updatedAt
      createdBy {
        id
        username
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "title",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "researchPdf",
    "type": "Upload!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createVueResearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "researchPdf",
        "variableName": "researchPdf"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      },
      {
        "kind": "Variable",
        "name": "vueId",
        "variableName": "vueId"
      }
    ],
    "concreteType": "CreateVueResearchAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueResearch",
        "storageKey": null,
        "args": null,
        "concreteType": "VueResearchNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "researchPdfUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "createdBy",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateVueResearchMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateVueResearchMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateVueResearchMutation",
    "id": null,
    "text": "mutation CreateVueResearchMutation(\n  $vueId: ID!\n  $title: String!\n  $researchPdf: Upload!\n) {\n  createVueResearch(vueId: $vueId, title: $title, researchPdf: $researchPdf) {\n    vueResearch {\n      id\n      title\n      researchPdfUrl\n      createdAt\n      updatedAt\n      createdBy {\n        id\n        username\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4d1ccdaea4b274c20dde117f456b2ab';
module.exports = node;
