import { graphql } from 'react-relay';

const startUserSubscription = graphql`
  mutation StartUserSubscriptionMutation(
    $userId: ID!
    $paymentAccountId: ID
    $subscriptionTemplateId: ID
    $subscriberType: String
    $startDate: Date
  ) {
     startUserSubscription (
       userId: $userId
       subscriberType: $subscriberType
       paymentAccountId: $paymentAccountId
       startDate: $startDate
       subscriptionTemplateId: $subscriptionTemplateId
     ) {
       userSubscription {
         billPeriodEnd
         billPeriodStart
         createdAt
         updatedAt
         id
       }
     }
  }
`;

export default startUserSubscription;
