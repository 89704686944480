import React from 'react';
import { commitMutation } from 'react-relay';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import environment from '../../Environment';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import createCannedSecurityQuestionMutation from '../../mutations/CreateCannedSecurityQuestion';

export default function CannedSecurityQuestionAddForm(props) {
  const { close, onAddCannedSecurityQuestion } = props;
  const classes = useCommonStyles();

  const [question, setQuestion] = React.useState('');

  const doAddCannedSecurityQuestion = () => {
    const variables = { question };

    commitMutation(environment, {
      mutation: createCannedSecurityQuestionMutation,
      variables,
      onCompleted: (response) => {
        if (response.createCannedSecurityQuestion) {
          if (onAddCannedSecurityQuestion) onAddCannedSecurityQuestion();
          if (close) close();
        }
      },
    });
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Add Canned Security Question
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            multiline
            maxLength={256}
            fullWidth
            keyIdName="question"
            label="Question"
            onChange={e => setQuestion(e.target.value)}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <AddButton
            onClick={doAddCannedSecurityQuestion}
            buttonText="Add Canned Question"
          />
        </Grid>
      </Grid>
    </div>
  );
}
