import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import BrokerActivityDetails from './BrokerActivityDetails';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import ProcessForm from './forms/ProcessForm';
import BaseGridContainer from '../common/BaseGridContainer';
import BrokerActivityActions from './BrokerActivityActions';
import BrokerActivityGroupActions from './BrokerActivityGroupActions';
import MsgRouter from '../../common/MsgRouter';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import BrokerActivityPerformanceForm from './forms/BrokerActivityPerformanceForm';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import InnerTabFilterGridItemMultiTextField from '../common/innerTab/InnerTabFilterGridItemMultiTextField';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import DailyHoldings from '../holdings/DailyHoldings';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import RTTableRenderer from '../common/table/RTTableRenderer';
import ContractFromBrokerDrawer from './ContractFromBrokerDrawer';
import ContractLink from '../common/links/ContractLink';
import { isRecurNull } from '../../common/helpers';

const processedAtIsnullDefault = null;
const ignoredDefault = null;
const performanceProcessedDefault = null;

function CorporateActionList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const [brokerActivity, setBrokerActivity] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [costBasisDrawerOpen, setCostBasisDrawerOpen] = React.useState(false);
  const [msgRouter] = React.useState(new MsgRouter());
  const contractDrawerCtx = React.useState(null);
  
  // Holdings display
  const [holdingContract, setHoldingContract] = React.useState(null);
  const [holdingsReportDate, setHoldingsReportDate] = React.useState(
    moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL),
  );

  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    BrokerActivityTableFieldMeta.conId,
    ContractFromBrokerDrawer.tableField(row => row.value, contractDrawerCtx),
    TableFieldMeta.fieldCell(
      'responsible',
      'Resp.',
      (row) => {
        const responsibleContract = isRecurNull(row.value, ['brokeractivityPtr', 'responsiblecontract']);
        if (responsibleContract) {
          return <ContractLink contract={responsibleContract.contract} />;
        }
        return null;
      },
      { align: 'center', width: 75, accessor: x => x },
    ),
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.field('description', 'Description', { width: 500 }),
    BrokerActivityTableFieldMeta.isin,
    BrokerActivityTableFieldMeta.symbol,
    TableFieldMeta.dateTimeField('transactionDatetime', 'Transaction Date'),
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.customCell('holdingActions', '', (row) => {
      const preDate = new Date(row.value.reportDate);
      preDate.setDate(preDate.getDate() - 1);
      const preDateStr = moment(preDate).format(MOMENT_DATE_FORMAT_GRAPHQL);
      return (
        <Grid container spacing={1} justify="center">
          <TableCompactGridItem>
            <TableIconButton
              tooltip={`Show Holding @ (Report-Date - 1): ${preDateStr}`}
              iconClass={ShoppingBasketIcon}
              color="primary"
              onClick={() => {
                setHoldingsReportDate(preDateStr);
                setHoldingContract(row.value.contract);
              }}
            />
          </TableCompactGridItem>
        </Grid>
      );
    }, { width: 40 }),
    TableFieldMeta.field('quantity', 'Quantity', { align: 'right' }),
    TableFieldMeta.field('proceeds', 'Proceeds', { align: 'right' }),
    TableFieldMeta.field('type', 'Type', { align: 'center', width: 45 }),
    {
      id: 'actions',
      accessor: node => node,
      Header: 'Actions',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={(be) => {
            setBrokerActivity(be);
            setDrawerOpen(true);
          }}
          onShowDetails={(be) => {
            setBrokerActivity(be);
            setDetailsOpen(true);
          }}
        />
      ),
    },
    {
      id: 'groupActions',
      accessor: node => node,
      Header: 'Group',
      width: 200,
      Cell: row => (
        <BrokerActivityGroupActions
          brokerActivity={row.value.brokeractivityPtr}
          msgRouter={msgRouter}
          groupName={row.value.symbol}
          onClickGroup={(e) => {
            setBrokerActivity(row.value);
            setCostBasisDrawerOpen(true);
          }}
        />
      ),
    },
    BrokerActivityTableFieldMeta.ignore(msgRouter),
    TableFieldMeta.notes(row => row.value),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <ProcessForm
          key={brokerActivity ? brokerActivity.id : 'procForm'}
          brokerActivity={brokerActivity}
          onClose={() => setDrawerOpen(false)}
          showResponsibleContract
          heading="Process Corporate Action"
          nodeName="IBCorporateActionNode"
          showHoldingsDate
        />
      </Drawer>

      <ContractFromBrokerDrawer
        openCtx={contractDrawerCtx}
      />

      <Drawer
        anchor="right"
        open={detailsOpen}
        onClose={() => setDetailsOpen(false)}
      >
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails
            key={brokerActivity ? brokerActivity.id : 'activityDetail'}
            brokerActivity={brokerActivity}
          />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={costBasisDrawerOpen}
        onClose={() => setCostBasisDrawerOpen(false)}
      >
        <div className={classes.drawerRootExtended} style={{ width: 900 }}>
          <BrokerActivityPerformanceForm
            key={brokerActivity ? brokerActivity.id : 'activityDetail'}
            brokerActivityGroup={brokerActivity && brokerActivity.group}
            onClose={() => setCostBasisDrawerOpen(false)}
          />
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description"
          onChange={e => filter.updateVar('search', e.target.value || null)}
        />

        <InnerTabFilterGridItemMultiTextField
          id="con-id-filter"
          label="Con ID"
          size="small"
          defaultValue={filter.params.ibConIdIn || null}
          onChange={(e, value) => {
            filter.updateVar('ibConIdIn', value);
          }}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          defaultValue={filter.params.symbol}
          halfWidth
          onChange={e => filter.updateVar('symbol', e.target.value)}
        />

        <InnerTabFilterSelectField
          id="symbol-filter"
          enumType="Currency"
          label="Currency"
          value={filter.params.currency}
          halfWidth
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.reportDate}
          onChange={value => filter.updateVar('reportDate', value)}
          label="Report Date"
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: false, description: 'Yes' },
            { name: true, description: 'No' },
          ]}
          helperText="Processed?"
          defaultValue={filter.params.processedAt_Isnull}
          onChange={newVal => filter.updateVar('processedAt_Isnull', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Ignored?"
          defaultValue={filter.params.ignored}
          onChange={newVal => filter.updateVar('ignored', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Performance?"
          defaultValue={filter.params.performanceProcessed}
          onChange={newVal => filter.updateVar('performanceProcessed', newVal)}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.ibCorporateActions : null)}
            tableRender={RTTableRenderer}
          />
        </Grid>

        {
          holdingContract && (
            <Grid item xs={12}>
              <DailyHoldings
                key={`${holdingContract.id}-${holdingsReportDate}`}
                contract={holdingContract}
                reportDate={holdingsReportDate}
                urlParamPrefix="h."
              />
            </Grid>
          )
        }
      </BaseGridContainer>

    </div>
  );
}

const query = graphql`
  query CorporateActionListQuery (
    $first: Int
    $offset: Int
    $search: String
    $symbol: String
    $currency: String
    $ibConIdIn: [String]
    $reportDate: Date
    $processedAt_Isnull: Boolean
    $ignored: Boolean
    $performanceProcessed: Boolean
  ) {
    viewer {
      ...CorporateActionList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        symbol: $symbol
        currency: $currency
        ibConIdIn: $ibConIdIn
        reportDate: $reportDate
        processedAt_Isnull: $processedAt_Isnull
        ignored: $ignored
        performanceProcessed: $performanceProcessed
      )
    }
  }
`;


const container = createRefetchContainer(
  CorporateActionList,
  {
    viewer: graphql`
      fragment CorporateActionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        search: {type: String}
        symbol: {type: String}
        currency: {type: String}
        ibConIdIn: {type: "[String]", defaultValue: []}
        reportDate: {type: Date}
        processedAt_Isnull: {type: Boolean}
        ignored: {type: Boolean}
        performanceProcessed: {type: Boolean}
      ) {
        ibCorporateActions(
          first: $first,
          offset: $offset
          search: $search
          symbol: $symbol
          currency: $currency
          ibConIdIn: $ibConIdIn
          reportDate: $reportDate
          processedAt_Isnull: $processedAt_Isnull
          ignored: $ignored
          performanceProcessed: $performanceProcessed
        ) {
          edges {
            node {
              ...IBCorporateAction_all @relay(mask: false)
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
    search: null,
    symbol: null,
    ibConIdIn: [],
    currency: null,
    reportDate: null,
    processedAt_Isnull: processedAtIsnullDefault,
    ignored: ignoredDefault,
    performanceProcessed: performanceProcessedDefault,
  }),
  {
    debounceFields: ['search', 'currency'],
  },
));
