import { graphql } from 'react-relay';

// language=GraphQL
const generateDailyReconciliation = graphql`
  mutation GenerateDailyReconciliationMutation (
    $statementOfFundsId: ID!
  ) {
    generateDailyReconciliation(
      statementOfFundsId: $statementOfFundsId
    ){
      statementOfFunds {
        id
        fromDate
        toDate
        fileUrl 
        file
        ibdailyreconciliation {
          id
          createdAt
          excelFile
          excelFileUrl 
          updatedAt
          ibdailyreconciliationendbalanceSet {
            edges {
              node {
                id
                currency
                quantity
              }
            }
          }
          
          ibdailyreconciliationdeltaSet {
            edges {
              node {
                id
                currency
                quantity
              }
            }
          }
        }
      }
    }
  }
`;

export default generateDailyReconciliation;
