import React from 'react';
import { withTabItems } from '../common/RoutableTabContainer';
import CashTransferMatchPane from './CashTransferMatchPane';
import OffMarketTransferList from './OffMarketTransferList';
import withSuspense from '../common/table/Suspention';
import LBGIcon from '../brokerLBG/LBGIcon';
import IBBrokerCashTransferList from './IBBrokerCashTransferList';

const TransferList = withSuspense(
  React.lazy(() => (process.env.DEPLOYMENT_CODE === 'UK'
    ? import('./uk/TransfersList')
    : import('./PositionTransferMatchPane'))),
);

const depCodeCashTabs = [];
if (process.env.DEPLOYMENT_CODE === 'UK') {
  const LBGBrokerCashTransferList = withSuspense(
    React.lazy(() => import('./uk/LBGBrokerCashTransferList')),
  );
  depCodeCashTabs.push({
    label: 'LBG-Cash',
    icon: LBGIcon,
    url: 'lbg-cash',
    comp: CashTransferMatchPane,
    propCopyFn: props => ({
      ...props,
      custodian: 'LB',
      contraCustodian: 'IB',
      contraOnly: false,
      brokerCashTransactionList: LBGBrokerCashTransferList,
    }),
  });
}

const tabEntries = [
  {
    label: 'IB-Cash',
    url: 'ib-cash',
    comp: CashTransferMatchPane,
    propCopyFn: props => ({
      ...props,
      custodian: 'IB',
      contraCustodian: 'LB',
      contraOnly: true,
      brokerCashTransactionList: IBBrokerCashTransferList,
    }),
  },
  ...depCodeCashTabs,
  {
    label: 'Position',
    url: 'position',
    comp: TransferList,
  },
  {
    label: 'Off Market',
    url: 'off-market',
    comp: OffMarketTransferList,
  },
];


export default withTabItems(tabEntries);
