import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTabItems } from '../common/RoutableTabContainer';
import DailyReconcileList from './DailyReconcileList';
import RangeReconcileList from './RangeReconcileList';
import withSuspense from '../common/table/Suspention';


const tabItems = [
  {
    label: 'Daily',
    url: 'daily',
    comp: DailyReconcileList,
  },
  {
    label: 'Range',
    url: 'range',
    comp: RangeReconcileList,
  },
];

if (process.env.DEPLOYMENT_CODE === 'UK') {
  const LedgerReportList = withSuspense(React.lazy(() => import('./uk/LedgerReportList')));
  const ledgerTabItem = {
    label: 'Ledger',
    url: 'ledger',
    comp: LedgerReportList,
  };
  tabItems.push(ledgerTabItem);
}

const ReconcileTabContainer = withTabItems(tabItems);

export default withRouter(ReconcileTabContainer);
