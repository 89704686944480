import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import BrokerActivityDetails from './BrokerActivityDetails';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import PositionTransferProcessForm from '../transfer/PositionTransferProcessForm';
import BrokerActivityActions from './BrokerActivityActions';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import MsgRouter from '../../common/MsgRouter';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import RTTableRenderer from '../common/table/RTTableRenderer';
import ContractFromBrokerDrawer from './ContractFromBrokerDrawer';

const processedAtIsNullDefault = true;
const ignoredDefault = false;

function TransferList(props) {
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = React.useState(null);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const contractDrawerCtx = React.useState(null);
  const [msgRouter] = React.useState(new MsgRouter());

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.processedAt,
    TableFieldMeta.processedBy,
    BrokerActivityTableFieldMeta.conId,
    BrokerActivityTableFieldMeta.currency,
    ContractFromBrokerDrawer.tableField(row => row.value, contractDrawerCtx),
    TableFieldMeta.field('cashQuantity', 'Cash Qty', { align: 'right', width: 80 }),
    TableFieldMeta.field('description', 'Description', { width: 300 }),
    BrokerActivityTableFieldMeta.isin,
    BrokerActivityTableFieldMeta.symbol,
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.field('quantity', 'Quantity', { align: 'right' }),
    TableFieldMeta.field('direction', 'Direction', { align: 'center', width: 40 }),
    TableFieldMeta.field('contraAccountId', 'Contra Account'),
    TableFieldMeta.field('contraCompany', 'Contra Company'),
    TableFieldMeta.field('transactionId', 'Txn ID'),
    TableFieldMeta.field('positionAmount', 'Position Amount', { align: 'right' }),
    TableFieldMeta.field('positionAmountInBase', 'Position Amount (AUD)', { align: 'right' }),
    {
      id: 'actions',
      accessor: node => node,
      Header: 'Actions',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          onProcess={() => {
            setBrokerActivity(row.value);
            setDrawerOpen(true);
          }}
          onShowDetails={() => {
            setBrokerActivity(row.value);
            setDetailsOpen(true);
          }}
          validateProcess={() => {
            if (row.value.quantity < 0) {
              return { valid: false, message: 'Transfer out must be processed in transfers->position page' };
            }
            return { valid: true };
          }}
        />
      ),
    },
    BrokerActivityTableFieldMeta.ignore(msgRouter),
    TableFieldMeta.notes(row => row.value),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <PositionTransferProcessForm
            brokerTransfer={brokerActivity}
            onClose={() => setDrawerOpen(false)}
          />
        </div>
      </Drawer>

      <ContractFromBrokerDrawer
        openCtx={contractDrawerCtx}
      />

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <BrokerActivityDetails brokerActivity={brokerActivity} />
        </div>
      </Drawer>

      <BaseGridContainer>

        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description, id"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          defaultValue={filter.params.symbol}
          onChange={e => filter.updateVar('symbol', e.target.value)}
          helperText="symbol"
          halfWidth
        />

        <InnerTabFilterGridItemTextField
          id="con-id-filter"
          label="Con ID"
          type="number"
          defaultValue={filter.params.ibConId}
          onChange={e => filter.updateVar('ibConId', (e.target.value && Number(e.target.value)) || null)}
        />

        <InnerTabFilterSelectField
          id="currency-filter"
          enumType="Currency"
          label="Currency"
          value={filter.params.currency}
          halfWidth
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.reportDate}
          onChange={value => filter.updateVar('reportDate', value)}
          label="Report Date"
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: false, description: 'Yes' },
            { name: true, description: 'No' },
          ]}
          helperText="Processed?"
          defaultValue={filter.params.processedAt_Isnull}
          onChange={newVal => filter.updateVar('processedAt_Isnull', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Ignored?"
          defaultValue={filter.params.ignored}
          onChange={newVal => filter.updateVar('ignored', newVal)}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.ibTransfers : null)}
            tableRender={RTTableRenderer}
          />
        </Grid>

      </BaseGridContainer>

    </div>
  );
}

const query = graphql`
  query TransferListQuery (
    $first: Int,
    $offset: Int
    $search: String
    $symbol: String
    $currency: String
    $ibConId: Int
    $reportDate: Date
    $ignored: Boolean
    $processedAt_Isnull: Boolean
  ) {
    viewer {
      ...TransferList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        symbol: $symbol
        currency: $currency
        ibConId: $ibConId
        ignored: $ignored
        reportDate: $reportDate
        processedAt_Isnull: $processedAt_Isnull
      )
    }
  }
`;

const container = createRefetchContainer(
  TransferList,
  {
    viewer: graphql`
      fragment TransferList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        search: {type: String}
        symbol: {type: String}
        currency: {type: String}
        ibConId: {type: Int}
        reportDate: {type: Date}
        processedAt_Isnull: {type: Boolean}
        ignored: {type: Boolean}
      ) {
        ibTransfers(
          first: $first
          offset: $offset
          search: $search
          symbol: $symbol
          currency: $currency
          ibConId: $ibConId
          reportDate: $reportDate
          ignored: $ignored
          processedAt_Isnull: $processedAt_Isnull
        ) {
          edges {
            node {
              ...IBTransfer_all @relay(mask: false)
            }
          }
          count
          pageInfo{
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
    search: null,
    symbol: null,
    ibConId: null,
    currency: null,
    reportDate: null,
    ignored: ignoredDefault,
    processedAt_Isnull: processedAtIsNullDefault,
  }),
  {
    debounceFields: ['search', 'currency'],
  },
));
