import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import BaseButton from '../buttons/BaseButton';
import { useCommonStyles } from '../Styles';


function SingleTextFieldModalDlg(props) {
  const {
    open,
    onClose,
    onChange,
    onChangePromise,
    title,
    fieldName,
    fieldInputType,
    defaultValue,
    commitButtonText,
  } = props;
  const classes = useCommonStyles();
  const [members] = React.useState({
    textValue: defaultValue,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialogSingleInput}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <DialogContentText>
          {title}
        </DialogContentText>
        <TextField
          autoFocus
          defaultValue={defaultValue}
          margin="dense"
          id="name"
          label={fieldName}
          fullWidth
          type={fieldInputType || 'text'}
          onChange={(e) => {
            members.textValue = e.target.value;
          }}
        />
      </DialogContent>
      <DialogActions>
        <BaseButton
          onClick={() => (onClose && onClose())}
          color="primary"
          buttonText="cancel"
        />
        <BaseButton
          onClick={() => (onChange && onChange(members.textValue))}
          onClickPromise={onChangePromise ? () => onChangePromise(members.textValue) : null}
          color="primary"
          buttonText={commitButtonText}
        />
      </DialogActions>
    </Dialog>
  );
}

SingleTextFieldModalDlg.propTypes = {
  open: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onChangePromise: PropTypes.func,
  title: PropTypes.string,
  fieldName: PropTypes.string,
  defaultValue: PropTypes.string,
  commitButtonText: PropTypes.string,
};

SingleTextFieldModalDlg.defaultProps = {
  onClose: null,
  onChange: null,
  onChangePromise: null,
  title: null,
  fieldName: null,
  defaultValue: null,
  commitButtonText: 'Update',
};

export default SingleTextFieldModalDlg;
