import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DrawerSubGroupHeading from '../common/drawer/DrawerSubGroupHeading';
import DateTime from '../common/DateTimeMoment';


function ApprovalList(props) {
  const { approvals } = props;
  return (
    <Grid container spacing={1}>
      <DrawerSubGroupHeading>Approvals</DrawerSubGroupHeading>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Approved By</TableCell>
            <TableCell>Approved At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            approvals.map((approval) => {
              const { node } = approval;
              return (
                <TableRow>
                  <TableCell>{node.approvedBy.username}</TableCell>
                  <TableCell>
                    <DateTime>{node.approvedAt}</DateTime>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </Grid>
  );
}

export default ApprovalList;
