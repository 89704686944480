import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { QueryRenderer } from 'react-relay';
import PropTypes from 'prop-types';
import environment from '../../Environment';
import withDebounce from '../../common/debounce';

const LOAD_RECORD_COUNT = 9;

const onChangeValue = withDebounce(
  (value, props, setRefetching, queryParams) => {
    const { relay } = props;
    const refetchVariables = fragmentVariables => ({
      first: LOAD_RECORD_COUNT,
      offset: 0,
      search: value,
      ...queryParams,
    });
    setRefetching(true);
    relay.refetch(refetchVariables, null, () => { setRefetching(false); });
  },
  300,
);

export function AutoCompleteHandler(props) {
  const [isOpen, setOpen] = React.useState(false);
  const [refetching, setRefetching] = React.useState(false);
  let options = [];
  let isLoading = true;

  const {
    fieldProps,
    viewer,
  } = props;

  const {
    getOptionLabel,
    getOptionSelected,
    onChange,
    nodeCollector,
    queryParams,
    defaultValue,
    disabled,
    multiple,
    ...fieldPropsRest
  } = fieldProps;

  if (viewer) {
    isLoading = false;
    options = nodeCollector(viewer);
  }

  if (refetching) {
    isLoading = true;
  }

  const onChangeAdapter = (option, values) => {
    if (onChange) {
      if (option.target) {
        onChange(values);
      } else {
        onChange(null);
      }
    }
  };

  return (
    <Autocomplete
      id="asynchronous-demo"
      multiple={multiple}
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      options={options}
      loading={isLoading}
      defaultValue={defaultValue}
      onChange={onChangeAdapter}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          type="text"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          {...fieldPropsRest}
          onChange={e => onChangeValue(e.target.value, props, setRefetching, queryParams)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

const queryRendererFunction = fieldProps => ({ error, props }) => {
  const { refetchContainer } = fieldProps;
  const RefetchContainer = refetchContainer;
  if (props) {
    const { viewer } = props;
    return (
      <RefetchContainer
        key={0}
        fieldProps={fieldProps}
        viewer={viewer}
      />
    );
  }

  return (
    <RefetchContainer
      key={1}
      fieldProps={fieldProps}
      viewer={null}
    />
  );
};

function FieldLoader(props) {
  const {
    loaderQuery,
    ...remainderProps
  } = props;

  const { queryParams } = props;
  return (
    <QueryRenderer
      environment={environment}
      query={loaderQuery}
      render={
        queryRendererFunction(remainderProps)
      }
      variables={{
        first: LOAD_RECORD_COUNT,
        offset: 0,
        ...queryParams,
      }}
    />
  );
}

FieldLoader.propTypes = {
  queryParams: PropTypes.object,
  loaderQuery: PropTypes.object.isRequired,
  refetchContainer: PropTypes.object.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  nodeCollector: PropTypes.object.isRequired,
  ...TextField.propTypes,
};

FieldLoader.defaultProps = {
  queryParams: {},
  ...TextField.defaultProps,
};

export default FieldLoader;
