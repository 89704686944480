import { Decimal } from 'decimal.js';

export default function Percentage(props) {
  const { ratio } = props;
  if (ratio === null || ratio === undefined) {
    return null;
  }
  const x = new Decimal(100.0).mul(new Decimal(ratio));
  return `${x.toString()}%`;
}
