/**
 * @flow
 * @relayHash 92e7d5f270f791fe83e6a30edbc926a5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CashActivityDetails_viewer$ref = any;
export type CashActivityDetailsQueryVariables = {|
  cashSummaryId?: ?string
|};
export type CashActivityDetailsQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CashActivityDetails_viewer$ref
  |}
|};
export type CashActivityDetailsQuery = {|
  variables: CashActivityDetailsQueryVariables,
  response: CashActivityDetailsQueryResponse,
|};
*/


/*
query CashActivityDetailsQuery(
  $cashSummaryId: ID
) {
  viewer {
    ...CashActivityDetails_viewer_4eedt0
    id
  }
}

fragment CashActivityDetails_viewer_4eedt0 on Query {
  cashSummaries(id: $cashSummaryId) {
    edges {
      node {
        id
        summaryDatetime
        reportDate
        description
        cashDeltas {
          edges {
            node {
              quantity
              currency
              createdAt
              updatedAt
              id
            }
          }
        }
        availableCashDeltas {
          edges {
            node {
              quantity
              currency
              createdAt
              updatedAt
              id
            }
          }
        }
        cashBalances {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
        availableCashBalances {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
        endingCashBase
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "cashSummaryId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "createdAt",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "updatedAt",
    "args": null,
    "storageKey": null
  },
  (v1/*: any*/)
],
v5 = [
  (v1/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CashActivityDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CashActivityDetails_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "cashSummaryId",
                "variableName": "cashSummaryId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CashActivityDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashSummaries",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "cashSummaryId"
              }
            ],
            "concreteType": "CashSummaryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashSummaryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashSummaryNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "summaryDatetime",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashDeltas",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashDeltaNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashDeltaNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashDeltaNode",
                                "plural": false,
                                "selections": (v4/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "availableCashDeltas",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AvailableCashDeltaNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AvailableCashDeltaNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AvailableCashDeltaNode",
                                "plural": false,
                                "selections": (v4/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashBalances",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashBalanceNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashBalanceNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashBalanceNode",
                                "plural": false,
                                "selections": (v5/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "availableCashBalances",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AvailableCashBalanceNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AvailableCashBalanceNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AvailableCashBalanceNode",
                                "plural": false,
                                "selections": (v5/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endingCashBase",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CashActivityDetailsQuery",
    "id": null,
    "text": "query CashActivityDetailsQuery(\n  $cashSummaryId: ID\n) {\n  viewer {\n    ...CashActivityDetails_viewer_4eedt0\n    id\n  }\n}\n\nfragment CashActivityDetails_viewer_4eedt0 on Query {\n  cashSummaries(id: $cashSummaryId) {\n    edges {\n      node {\n        id\n        summaryDatetime\n        reportDate\n        description\n        cashDeltas {\n          edges {\n            node {\n              quantity\n              currency\n              createdAt\n              updatedAt\n              id\n            }\n          }\n        }\n        availableCashDeltas {\n          edges {\n            node {\n              quantity\n              currency\n              createdAt\n              updatedAt\n              id\n            }\n          }\n        }\n        cashBalances {\n          edges {\n            node {\n              id\n              currency\n              quantity\n            }\n          }\n        }\n        availableCashBalances {\n          edges {\n            node {\n              id\n              currency\n              quantity\n            }\n          }\n        }\n        endingCashBase\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9cbeb21ef9ca7187e978a4a7a01696fd';
module.exports = node;
