import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { EnumValues } from '../EnumValue';
import { useCommonStyles } from '../Styles';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function
DrawerGridItemSelectField(props) {
  const {
    label,
    enumType,
    disabled,
    value,
    valueDescriber,
    captionDescriber,
    onChange,
    id,
    ...otherProps
  } = props;
  const classes = useCommonStyles();
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <FormControl className={classes.fromControlDrawer}>
        <InputLabel htmlFor="accountType-helper">{label}</InputLabel>

        {
          (typeof enumType === 'object') ? (
            <Select
              disabled={disabled}
              value={value}
              onChange={onChange}
              input={<Input name={label} id={id || (label && label.toLowerCase())} />}
            >
              {
                enumType.map(enumEntry => (
                  <MenuItem
                    key={enumEntry.name}
                    value={enumEntry.name}
                  >
                    {enumEntry.description}
                  </MenuItem>
                ))
              }
            </Select>
          ) : (
            <EnumValues
              enumType={enumType}
              render={enumMeta => (
                <Select
                  disabled={disabled}
                  value={value}
                  onChange={onChange}
                  input={<Input name={label} id={id || (label && label.toLowerCase())} />}
                >
                  {
                    Object.keys(enumMeta).map(
                      enumKey => (
                        <MenuItem
                          key={enumKey}
                          value={valueDescriber(enumMeta, enumKey)}
                        >
                          {captionDescriber(enumMeta, enumKey)}
                        </MenuItem>
                      ),
                    )
                  }
                </Select>
              )}
            />
          )
        }

      </FormControl>
    </Grid>
  );
}

DrawerGridItemSelectField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  enumType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })),
  ]).isRequired,
  valueDescriber: PropTypes.func,
  captionDescriber: PropTypes.func,
  xs: PropTypes.number,
};

DrawerGridItemSelectField.defaultProps = {
  disabled: false,
  valueDescriber: (enumMeta, enumKey) => enumKey,
  captionDescriber: (enumMeta, enumKey) => enumMeta[enumKey].description,
  xs: 6,
};

export default DrawerGridItemSelectField;
