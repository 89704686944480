import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TableFieldSelectorDlgForm(props) {
  const {
    columns,
    onChangeColumnOrder,
    onClose,
  } = props;
  const classes = useStyles();
  const initSettings = columns.map(column => ({
    id: column.id,
    column,
    header: column.Header,
    show: true,
  }));
  const [settings, setSettings] = React.useState(initSettings);

  const publishChange = (updatedSettings) => {
    if (!onChangeColumnOrder) return;
    setSettings([...updatedSettings]);
    const shownColumns = updatedSettings.reduce((v, x) => (x.show ? [...v, x.column] : v), []);
    onChangeColumnOrder(shownColumns);
  };

  return (
    <div style={{ width: 400 }}>
      <DialogTitle id="confirmation-dialog-title">Column Settings</DialogTitle>
      <DialogContent dividers>
        <List className={classes.root}>
          {settings.map((columnMeta, idx) => {
            const labelId = `checkbox-list-label-${columnMeta.id}`;
            return (
              <ListItem
                key={columnMeta.id}
                role={undefined}
                dense
                button
                onClick={() => {
                  settings[idx].show = !settings[idx].show;
                  publishChange(settings);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={columnMeta.show}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={columnMeta.header}
                />
                <ListItemText
                  id={labelId}
                  primary={columnMeta.id}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    disabled={idx === settings.length - 1}
                    onClick={() => {
                      const temp = settings[idx];
                      settings[idx] = settings[idx + 1];
                      settings[idx + 1] = temp;
                      publishChange(settings);
                    }}
                  >
                    <ArrowDownward />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    disabled={idx === 0}
                    onClick={() => {
                      const temp = settings[idx];
                      settings[idx] = settings[idx - 1];
                      settings[idx - 1] = temp;
                      publishChange(settings);
                    }}
                  >
                    <ArrowUpward />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => onClose()}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose()}
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>

    </div>
  );
}
