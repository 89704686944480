import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { createRefetchContainer, graphql } from 'react-relay';
import { AddButton } from '../buttons/BaseButton';
import { useCommonStyles } from '../Styles';
import FileUpload from '../FileUpload';
import { createQueryRendererProgress } from '../QueryRendererProgress';
import NoteEntry from './NoteEntry';
import { doCommitPromise } from '../../../common/commit';
import createNoteEntryMutation from '../../../mutations/CreateNoteEntry';
import { makeUploadsTopArray } from '../../../common/helpers';
import DrawerSubGroupDivider from '../drawer/DrawerSubGroupDivider';
import notiStack from '../../../common/notiStack';

const noteEntriesFromViewer = (viewer, notesOwner, notesId) => {
  if (!viewer) return [];
  if (!notesId) return [];
  if (viewer.notes.edges.length === 0) return [];
  if (notesOwner
    && notesOwner.notes
    && viewer.notes.edges[0].node.id !== notesOwner.notes.id) return [];
  return viewer.notes.edges[0].node.noteentrySet.edges.map(x => x.node);
};

function NotesDrawer(props) {
  const {
    notesOwner,
    viewer,
    relay,
    onAddUpdateNoteEntry,
  } = props;
  const classes = useCommonStyles();
  const [ucVar] = React.useState({
    files: [],
    noteText: '',
    notesId: notesOwner.notes ? notesOwner.notes.id : null,
  });

  const noteEntries = noteEntriesFromViewer(viewer, notesOwner, ucVar.notesId);

  const doCreateNoteEntry = () => {
    if (!ucVar.noteText) {
      notiStack.error('Please add text relevant to this note');
      return;
    }
    doCommitPromise(
      createNoteEntryMutation,
      {
        text: ucVar.noteText,
        ownerId: notesOwner.id,
        attachments: ucVar.files.map(() => null),
      },
      (response) => {
        if (!ucVar.notesId) {
          ucVar.notesId = response.createNoteEntry.notes.id;
        }
        relay.refetch(_var => ({ notesId: ucVar.notesId }));
        if (onAddUpdateNoteEntry) onAddUpdateNoteEntry(response.createNoteEntry.notes);
      },
      () => null,
      null,
      makeUploadsTopArray(ucVar.files, 'attachments'),
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6" gutterBottom>Notes</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="standard-multiline-flexible"
            // label="Notes"
            multiline
            rowsMax="4"
            onChange={(e) => {
              ucVar.noteText = e.target.value;
            }}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FileUpload
            onChange={(files) => {
              ucVar.files = files;
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} xl={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <AddButton
                onClickPromise={doCreateNoteEntry}
              />
            </Grid>
          </Grid>
        </Grid>

        {
          noteEntries.map(noteEntry => (
            <React.Fragment>
              <Grid item xs={12}>
                <NoteEntry noteEntry={noteEntry} />
              </Grid>
              <DrawerSubGroupDivider />
            </React.Fragment>
          ))
        }
      </Grid>
    </div>
  );
}


const query = graphql`
  query NotesDrawerQuery (
    $notesId: ID
  ) {
    viewer {
      ...NotesDrawer_viewer
      @arguments (
        notesId: $notesId
      )
    }
  }
`;

const container = createRefetchContainer(
  NotesDrawer,
  {
    viewer: graphql`
      fragment NotesDrawer_viewer on Query
      @argumentDefinitions(
        notesId: {type: ID}
      ) {
        notes(id: $notesId, first: 1) {
          edges {
            node {
              id 
              noteentrySet(orderBy: "-updatedAt") {
                edges {
                  node {
                    createdAt
                    updatedAt
                    createdBy {
                      id
                      fullName 
                    }
                    id 
                    text
                    noteentryattachmentSet {
                      edges {
                        node {
                          id
                          file
                          fileUrl
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { notesOwner, onAddUpdateNoteEntry }) => ({ notesOwner, onAddUpdateNoteEntry }),
  ({ notesOwner, notesId }) => ({
    notesId: ((notesOwner && notesOwner.notes) ? notesOwner.notes.id : null),
  }),
);
