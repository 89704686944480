/**
 * @flow
 * @relayHash 79c960d132b8123b5d2be85810e3c96d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OffMarketTransferCostBasisForm_holdingQuantity$ref = any;
export type OffMarketTransferCostBasisFormQueryVariables = {|
  holdingQuantityId: string
|};
export type OffMarketTransferCostBasisFormQueryResponse = {|
  +viewer: ?{|
    +offMarketTransferHoldingQuantities: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: OffMarketTransferCostBasisForm_holdingQuantity$ref
        |}
      |}>
    |}
  |}
|};
export type OffMarketTransferCostBasisFormQuery = {|
  variables: OffMarketTransferCostBasisFormQueryVariables,
  response: OffMarketTransferCostBasisFormQueryResponse,
|};
*/


/*
query OffMarketTransferCostBasisFormQuery(
  $holdingQuantityId: ID!
) {
  viewer {
    offMarketTransferHoldingQuantities(id: $holdingQuantityId) {
      edges {
        node {
          ...OffMarketTransferCostBasisForm_holdingQuantity
          id
        }
      }
    }
    id
  }
}

fragment OffMarketTransferCostBasisForm_holdingQuantity on OffMarketTransferHoldingQuantityNode {
  id
  quantity
  portfolio {
    id
    trackingVueName
  }
  contract {
    symbol
    id
    currency
  }
  commission
  costBasis
  dummy
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "holdingQuantityId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "holdingQuantityId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketTransferCostBasisFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "offMarketTransferHoldingQuantities",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "OffMarketTransferHoldingQuantityNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OffMarketTransferHoldingQuantityNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OffMarketTransferHoldingQuantityNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "OffMarketTransferCostBasisForm_holdingQuantity",
                        "args": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketTransferCostBasisFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "offMarketTransferHoldingQuantities",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "OffMarketTransferHoldingQuantityNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OffMarketTransferHoldingQuantityNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OffMarketTransferHoldingQuantityNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trackingVueName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "contract",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "currency",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "commission",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "costBasis",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dummy",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OffMarketTransferCostBasisFormQuery",
    "id": null,
    "text": "query OffMarketTransferCostBasisFormQuery(\n  $holdingQuantityId: ID!\n) {\n  viewer {\n    offMarketTransferHoldingQuantities(id: $holdingQuantityId) {\n      edges {\n        node {\n          ...OffMarketTransferCostBasisForm_holdingQuantity\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment OffMarketTransferCostBasisForm_holdingQuantity on OffMarketTransferHoldingQuantityNode {\n  id\n  quantity\n  portfolio {\n    id\n    trackingVueName\n  }\n  contract {\n    symbol\n    id\n    currency\n  }\n  commission\n  costBasis\n  dummy\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0278e58fcb3565a4a3c1b3b541f523d8';
module.exports = node;
