/**
 * @flow
 * @relayHash cb696ae90606c7aca03e11f705d326ab
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractHoldingsQueryVariables = {|
  contractId?: ?string
|};
export type ContractHoldingsQueryResponse = {|
  +viewer: ?{|
    +contracts: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +symbol: string,
          +ibConId: number,
        |}
      |}>
    |}
  |}
|};
export type ContractHoldingsQuery = {|
  variables: ContractHoldingsQueryVariables,
  response: ContractHoldingsQueryResponse,
|};
*/


/*
query ContractHoldingsQuery(
  $contractId: ID
) {
  viewer {
    contracts(id: $contractId) {
      edges {
        node {
          id
          symbol
          ibConId
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contracts",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "contractId"
    }
  ],
  "concreteType": "ContractNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ContractNode",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "symbol",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "ibConId",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ContractHoldingsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ContractHoldingsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ContractHoldingsQuery",
    "id": null,
    "text": "query ContractHoldingsQuery(\n  $contractId: ID\n) {\n  viewer {\n    contracts(id: $contractId) {\n      edges {\n        node {\n          id\n          symbol\n          ibConId\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '47a2be0fa7c51cfca646985fca11f7aa';
module.exports = node;
