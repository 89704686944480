import { graphql } from 'react-relay';

// language=GraphQL
const setInterestPaymentsMutation = graphql`
  mutation SetInterestPaymentsMutation(
    $clientAccountId: ID!
    $enable: Boolean!
  ) {
    setInterestPayments(clientAccountId: $clientAccountId, enable: $enable) {
      clientAccount {
        id
        accruesInterest
      }
    }
  }
`;
export default setInterestPaymentsMutation;
