import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import { toLocal } from '../../helpers/ID';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import ApprovalStatus from '../common/ApprovalStatus';


function OrderApprovalList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);

  const [statusIn, setStatusIn] = React.useState(filter.params.statusIn);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.field('proposalId', 'Proposal', { accessor: node => toLocal(node.proposal.id) }),
    TableFieldMeta.enum('OrderProposalType', 'Proposal Type', node => node.proposal.type),
    TableFieldMeta.field('portfolioId', 'Portfolio', { accessor: node => toLocal(node.portfolio.id) }),
    TableFieldMeta.account(node => node.portfolio.account, 'Account', { charLimit: 40 }),
    TableFieldMeta.fieldCell('status', 'Status', node => (
      <ApprovalStatus approval={node.value} />
    ), { align: 'center' }),
    TableFieldMeta.moreButtonLink('order-approval', node => node.id),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterSelectField
          enumType="OrderApprovalStatus"
          label="Status"
          helperText="Status: mutiple-select"
          multiple
          withEmpty={false}
          value={statusIn}
          onChange={(e) => {
            setStatusIn(e.target.value);
            filter.updateVar('statusIn', e.target.value);
          }}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.orderApprovals : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query OrderApprovalListQuery (
    $first: Int
    $offset: Int
    $accountId: ID
    $statusIn: [String]
    $proposalId: ID
    $search: String
  ) {
    viewer {
      ...OrderApprovalList_viewer
      @arguments (
        first: $first
        offset: $offset
        accountId: $accountId
        statusIn: $statusIn
        proposalId: $proposalId
        search: $search
      )
    }
  }
`;

const container = createRefetchContainer(
  OrderApprovalList,
  {
    viewer: graphql`
      fragment OrderApprovalList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID, defaultValue: null }
        statusIn: {type: "[String]", defaultValue: null }
        proposalId: {type: ID, defaultValue: null }
        search: {type: String, defaultValue: null }
      ) {
        orderApprovals(
          first: $first
          offset: $offset
          orderBy: "-createdAt"
          account: $accountId
          statusIn: $statusIn
          proposal: $proposalId
          search: $search
        ) {
          edges {
            node {
              proposal {
                id
                type
              }
              id
              createdAt
              status
              portfolio {
                id
                trackingVueName
                account {
                  name
                  id
                }
              }
              portfolioOrder {
                id
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({
    accountId,
    proposalId,
  }) => ({
    accountId,
    statusIn: [],
    proposalId,
  }),
));
