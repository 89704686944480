import DailyHoldings from './DailyHoldings';
import { withTabItems } from '../common/RoutableTabContainer';

export default withTabItems([
  {
    label: 'Daily Holdings',
    url: 'daily-holdings',
    comp: DailyHoldings,
  },
]);
