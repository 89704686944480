import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createFragmentContainer, graphql } from 'react-relay';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { UpdateButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { setOffMarketHoldingCostBasis } from '../../mutations/OffMarket';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import DrawerGridItemCurrencyField from '../common/drawer/DrawerGridItemCurrencyField';
import DrawerGridItemCheckBox from '../common/drawer/DrawerGridItemCheckBox';

function OffMarketTransferCostBasisForm(props) {
  const { holdingQuantity, onClose } = props;
  const { currency } = holdingQuantity.contract;
  const [ucVar, setUcVar] = React.useState({
    costBasis: holdingQuantity.costBasis,
    commission: holdingQuantity.commission,
    dummy: holdingQuantity.dummy,
  });

  return (
    <Grid container spacing={2}>
      <DrawerGridItemHeading
        heading="Update Cost Basis"
      />

      <DrawerGridItemCurrencyField
        label="Costbasis"
        defaultValue={ucVar.costBasis}
        currency={currency}
        onChange={e => setUcVar({ ...ucVar, costBasis: e.target.value })}
      />

      <DrawerGridItemCurrencyField
        label="Commission"
        defaultValue={ucVar.commission}
        currency="AUD"
        onChange={e => setUcVar({ ...ucVar, commission: e.target.value })}
      />

      <DrawerGridItemCheckBox
        label="Dummy"
        onChange={e => setUcVar({ ...ucVar, dummy: e.target.checked })}
        value={ucVar.dummy}
      />

      <Grid item xs={12}>
        <BottomGridContainer>
          <UpdateButton
            onClickPromise={() => doCommitPromise(
              setOffMarketHoldingCostBasis,
              {
                omtHoldingQtyId: holdingQuantity.id,
                costBasis: Number(ucVar.costBasis),
                commission: Number(ucVar.commission),
                dummy: ucVar.dummy,
              },
              () => (onClose && onClose()),
              () => null,
            )}
          />
        </BottomGridContainer>
      </Grid>
    </Grid>
  );
}

const query = graphql`
  query OffMarketTransferCostBasisFormQuery (
    $holdingQuantityId: ID!
  ) {
    viewer {
      offMarketTransferHoldingQuantities(
        id: $holdingQuantityId
      ){
        edges {
          node {
            ...OffMarketTransferCostBasisForm_holdingQuantity
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  OffMarketTransferCostBasisForm,
  {
    holdingQuantity: graphql`
      fragment OffMarketTransferCostBasisForm_holdingQuantity on OffMarketTransferHoldingQuantityNode {
        id
        quantity
        
        portfolio {
          id
          trackingVueName 
        }
        
        contract {
          symbol
          id
          currency
        }
        
        commission
        costBasis
        dummy
      }
    `,
  },
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { holdingQuantity, onClose }) => ({
    holdingQuantity: viewer.offMarketTransferHoldingQuantities.edges[0].node,
    onClose,
  }),
  ({ holdingQuantity }) => (holdingQuantity ? { holdingQuantityId: holdingQuantity.id } : null),
);
