/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSubscriptionInvoices_viewer$ref: FragmentReference;
declare export opaque type UserSubscriptionInvoices_viewer$fragmentType: UserSubscriptionInvoices_viewer$ref;
export type UserSubscriptionInvoices_viewer = {|
  +userSubscriptions: ?{|
    +count: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +subscriptionPtr: {|
          +subscriptioninvoiceSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +subscription: {|
                  +id: string
                |},
                +chargeAmount: number,
                +billEnd: any,
                +billStart: any,
                +billTo: {|
                  +id: string,
                  +name: ?string,
                |},
              |}
            |}>,
            +count: ?number,
          |}
        |},
      |}
    |}>,
  |},
  +$refType: UserSubscriptionInvoices_viewer$ref,
|};
export type UserSubscriptionInvoices_viewer$data = UserSubscriptionInvoices_viewer;
export type UserSubscriptionInvoices_viewer$key = {
  +$data?: UserSubscriptionInvoices_viewer$data,
  +$fragmentRefs: UserSubscriptionInvoices_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserSubscriptionInvoices_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "userSubscriptionId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "userSubscriptions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userSubscriptionId"
        }
      ],
      "concreteType": "UserSubscriptionNodeConnection",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "UserSubscriptionNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "UserSubscriptionNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "subscriptionPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "SubscriptionNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "subscriptioninvoiceSet",
                      "storageKey": null,
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "first",
                          "variableName": "first"
                        },
                        {
                          "kind": "Variable",
                          "name": "offset",
                          "variableName": "offset"
                        },
                        {
                          "kind": "Literal",
                          "name": "orderBy",
                          "value": "-billStart"
                        }
                      ],
                      "concreteType": "SubscriptionInvoiceNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "SubscriptionInvoiceNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "SubscriptionInvoiceNode",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "subscription",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "SubscriptionNode",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/)
                                  ]
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "chargeAmount",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "billEnd",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "billStart",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "billTo",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AccountNode",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "name",
                                      "args": null,
                                      "storageKey": null
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        },
                        (v0/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '05de1d92b7f87d159ccd55e6eaefd834';
module.exports = node;
