/**
 * @flow
 * @relayHash cb0d008dc4baefcb764cf93bc3449654
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTenantFeeTemplateMutationVariables = {|
  tenantId: string,
  feeTemplateId?: ?string,
|};
export type UpdateTenantFeeTemplateMutationResponse = {|
  +updateTenantFeeTemplate: ?{|
    +tenant: ?{|
      +id: string,
      +feeTemplate: ?{|
        +id: string
      |},
    |}
  |}
|};
export type UpdateTenantFeeTemplateMutation = {|
  variables: UpdateTenantFeeTemplateMutationVariables,
  response: UpdateTenantFeeTemplateMutationResponse,
|};
*/


/*
mutation UpdateTenantFeeTemplateMutation(
  $tenantId: ID!
  $feeTemplateId: ID
) {
  updateTenantFeeTemplate(tenantId: $tenantId, feeTemplateId: $feeTemplateId) {
    tenant {
      id
      feeTemplate {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "feeTemplateId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateTenantFeeTemplate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "feeTemplateId",
        "variableName": "feeTemplateId"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "UpdateTenantFeeTemplate",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tenant",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feeTemplate",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeTemplateNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateTenantFeeTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateTenantFeeTemplateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateTenantFeeTemplateMutation",
    "id": null,
    "text": "mutation UpdateTenantFeeTemplateMutation(\n  $tenantId: ID!\n  $feeTemplateId: ID\n) {\n  updateTenantFeeTemplate(tenantId: $tenantId, feeTemplateId: $feeTemplateId) {\n    tenant {\n      id\n      feeTemplate {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '643fc600d01cecbb64a77b27099e3901';
module.exports = node;
