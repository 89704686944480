import React from 'react';
import { useCommonStyles } from '../common/Styles';

function ContractIconWidget(props) {
  const { imageUrl } = props;
  const classes = useCommonStyles();
  return (
    <div className={classes.logoWidgetContainer}>
      <div className={classes.logoWidget}>
        <img
          alt="contract icon"
          key={imageUrl}
          src={imageUrl}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
      </div>
    </div>
  );
}

export default ContractIconWidget;
