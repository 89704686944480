import { graphql } from 'react-relay';

const createNoteEntryMutation = graphql`
  mutation CreateNoteEntryMutation (
    $ownerId: ID!
    $text: String!
    $attachments: [Upload]
  ) {
    createNoteEntry(
      ownerId:$ownerId
      text: $text
      attachments: $attachments
    ) {
      notes {
        id
        noteentrySet {
          edges {
            node {
              id
              updatedAt
              createdAt
              createdBy {
                id
                fullName
              }
              text
              noteentryattachmentSet {
                edges {
                  node {
                    id
                    file
                    fileUrl
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createNoteEntryMutation;
