/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AccountStockHoldingListClosed_viewer$ref: FragmentReference;
declare export opaque type AccountStockHoldingListClosed_viewer$fragmentType: AccountStockHoldingListClosed_viewer$ref;
export type AccountStockHoldingListClosed_viewer = {|
  +accounts: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +portfolioContracts: ?$ReadOnlyArray<?{|
          +portfolio: ?{|
            +id: string,
            +trackingVue: ?{|
              +name: string
            |},
          |},
          +contract: ?{|
            +id: string,
            +name: ?string,
            +symbol: string,
          |},
        |}>
      |}
    |}>
  |},
  +$refType: AccountStockHoldingListClosed_viewer$ref,
|};
export type AccountStockHoldingListClosed_viewer$data = AccountStockHoldingListClosed_viewer;
export type AccountStockHoldingListClosed_viewer$key = {
  +$data?: AccountStockHoldingListClosed_viewer$data,
  +$fragmentRefs: AccountStockHoldingListClosed_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "AccountStockHoldingListClosed_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "accountId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "accounts",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "accountId"
        }
      ],
      "concreteType": "AccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AccountNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolioContracts",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioContractNode",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "portfolio",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "PortfolioNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "trackingVue",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "VueNode",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/)
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "contract",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ContractNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "alias": null,
                          "name": "symbol",
                          "args": null,
                          "storageKey": null
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd5d4738bd8ddac3f322352e6fe138f5e';
module.exports = node;
