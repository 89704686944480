import * as React from 'react';
import { graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { useCommonStyles } from '../../common/Styles';
import StockOrderDetails from './StockOrderDetails';
import { createQueryRendererProgress } from '../../common/QueryRendererProgress';
import { fromGlobalId, toGlobalId } from '../../../helpers/ID';
import VerticalTable from '../../common/VerticalTable';
import TableFieldMeta from '../../../common/TableFieldMeta';
import AccountLink from '../../common/links/AccountLink';


function StockOrderView(props) {
  const { stockOrder, exchanges } = props;
  const classes = useCommonStyles();
  if (!stockOrder) {
    return <React.Fragment />;
  }
  const portfolioOrderLocalId = fromGlobalId(stockOrder.portfolioOrder.id)[1];
  const columns = [
    ['Created At', TableFieldMeta.createdAt.accessor],
    ['Updated At', TableFieldMeta.updatedAt.accessor],
    ['Account', node => <AccountLink account={node.account} />],
    ['Vue Order', node => (
      <Link href={`/vue-order/${portfolioOrderLocalId}`}>
        {portfolioOrderLocalId}
      </Link>)],
  ];

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.tableHolderRoot}>
            <VerticalTable
              showHeadings={false}
              columnMeta={columns}
              dataNode={stockOrder}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <StockOrderDetails
            stockOrder={stockOrder}
            exchanges={exchanges}
            expanded
          />
        </Grid>
      </Grid>
    </div>
  );
}

const qrp = createQueryRendererProgress(
  graphql`
    query StockOrderViewQuery (
      $stockOrderId: ID
    ) {
      viewer {
        stockOrders (
          id: $stockOrderId
        ) {
          edges {
            node {
              ... StockOrderDetails_stockOrder @relay(mask: false)
            }
          }
        }
        exchanges {
          edges {
            node {
              symbol
              nextDaySession {
                startTime
                endTime
              }
            }
          }
        }
      }
    }
  `,
  StockOrderView,
  (viewer, props) => ({
    stockOrder: viewer.stockOrders.edges.length > 0 ? viewer.stockOrders.edges[0].node : null,
    exchanges: viewer.exchanges.edges.length > 0 
      ? viewer.exchanges.edges.map(edge => edge.node || {}) 
      : [],
  }),
  outerProps => ({
    stockOrderId: toGlobalId('StockOrderNode', outerProps.match.params.stockOrderId),
  }),
);

export default withRouter(qrp);
