import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import { Grid, Link, Typography } from '@material-ui/core';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import PortfolioOrderDetails from './PortfolioOrderDetails';
import { toGlobalId, toLocal } from '../../helpers/ID';
import StockOrderDetails from './stockOrder/StockOrderDetails';
import FxOrderDetails from './FxOrderDetails';

function BulkOrderView(props) {
  const { bulkOrder, exchanges } = props;
  return (
    <Grid container spacing={2}>
      {
        bulkOrder.portfolioorderSet.edges.map(poEdge => (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                  Portfolio Order :
                <Link href={`/vue-order/${toLocal(poEdge.node.id)}`}>
                  {' '}
                  {toLocal(poEdge.node.id)}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PortfolioOrderDetails
                portfolioOrder={poEdge.node}
                exchanges={exchanges}
              />
            </Grid>
          </React.Fragment>
        ))
      }

      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom>
          Fx Orders
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <div>
          {
            bulkOrder.fxorderSet.edges.map(
              fxOrder => (
                <FxOrderDetails
                  fxOrder={fxOrder.node}
                />
              ),
            )
          }
        </div>
      </Grid>
    </Grid>
  );
}

const query = graphql`
  query BulkOrderViewQuery(
    $bulkOrderId: ID!
  )  {
    viewer {
      bulkOrders (
        id: $bulkOrderId
      ) {
        edges {
          node {
            ...BulkOrderView_bulkOrder @relay(mask: false)
          }
        }
      }
      exchanges {
        edges {
          node {
            symbol
            nextDaySession {
              startTime
              endTime
            }
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  BulkOrderView,
  {
    bulkOrder: graphql`
      fragment BulkOrderView_bulkOrder on BulkOrderNode
      {
        id
        createdBy {
          id
          fullName
        }
        portfolioorderSet {
          edges {
            node {
              ...PortfolioOrderDetails_portfolioOrder @relay(mask: false)
            }
          }
        }

        fxorderSet {
          edges {
            node {
              ...FxOrderDetails_fxOrder @relay(mask: false)
            }
          }
        }
        
        
      }
    `,
  },
);

export default withRouter(
  createQueryRendererProgress(
    query,
    container,
    (viewer, outProps) => ({ 
      bulkOrder: viewer.bulkOrders.edges[0].node,
      exchanges: viewer.exchanges.edges.length > 0 
        ? viewer.exchanges.edges.map(edge => edge.node || {}) 
        : [],
    }),
    props => ({
      bulkOrderId: toGlobalId('BulkOrderNode', props.match.params.bulkOrderId),
    }),
  ),
);
