/**
 * @flow
 * @relayHash 34a6b87afa034abf7185be6fed76a0c3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PositionTransferPurchaseInput = {|
  contractId?: ?string,
  purchaseDate?: ?any,
  portfolioId?: ?string,
  quantity?: ?number,
  costBasis?: ?number,
  commission?: ?number,
  id?: ?string,
  dummy?: ?boolean,
|};
export type CreatePositionTransfersOutMutationVariables = {|
  accountId: string,
  positionTransfers?: ?$ReadOnlyArray<?PositionTransferPurchaseInput>,
|};
export type CreatePositionTransfersOutMutationResponse = {|
  +createPositionTransfersOut: ?{|
    +positionTransferRequests: ?$ReadOnlyArray<?{|
      +id: string,
      +account: {|
        +id: string
      |},
    |}>
  |}
|};
export type CreatePositionTransfersOutMutation = {|
  variables: CreatePositionTransfersOutMutationVariables,
  response: CreatePositionTransfersOutMutationResponse,
|};
*/


/*
mutation CreatePositionTransfersOutMutation(
  $accountId: ID!
  $positionTransfers: [PositionTransferPurchaseInput]
) {
  createPositionTransfersOut(accountId: $accountId, positionTransfers: $positionTransfers) {
    positionTransferRequests {
      id
      account {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "positionTransfers",
    "type": "[PositionTransferPurchaseInput]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createPositionTransfersOut",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "positionTransfers",
        "variableName": "positionTransfers"
      }
    ],
    "concreteType": "CreatePositionTransfersOut",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "positionTransferRequests",
        "storageKey": null,
        "args": null,
        "concreteType": "PositionTransferRequestNode",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreatePositionTransfersOutMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreatePositionTransfersOutMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreatePositionTransfersOutMutation",
    "id": null,
    "text": "mutation CreatePositionTransfersOutMutation(\n  $accountId: ID!\n  $positionTransfers: [PositionTransferPurchaseInput]\n) {\n  createPositionTransfersOut(accountId: $accountId, positionTransfers: $positionTransfers) {\n    positionTransferRequests {\n      id\n      account {\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b65bb5f5d6842802db96704bda67e7b4';
module.exports = node;
