import React from 'react';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '0px',
    padding: '0px 8px',
    fontSize: '0.65rem',
  },
  iconFontSize: {
    fontSize: '1.12rem',
  },
}));

function MoreButton(props) {
  const classes = useStyles();
  const { variant, ...otherProps } = props;
  return (
    <Button
      {...otherProps}
      className={classes.button}
    >
      &nbsp;
      <MoreHorizIcon fontSize="small" className={classes.iconFontSize} />
      &nbsp;
    </Button>
  );
}

MoreButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  ...Button.propTypes,
};

MoreButton.defaultProps = {
  color: 'default',
  variant: 'contained',
};

export default MoreButton;
