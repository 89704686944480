import React from 'react';
import Grid from '@material-ui/core/Grid';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import { useCommonStyles } from '../../common/Styles';
import DividendAccrualList from '../DividendAccrualList';
import MUITableRenderer from '../../common/table/MUITableRenderer';
import TableFieldMeta from '../../../common/TableFieldMeta';


function SelectDividendAccrualForm(props) {
  const classes = useCommonStyles();
  const { onRowSelect, ibConId } = props;
  return (
    <div className={classes.drawerRootExtended}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading="Select Dividend Accrual"
        />
        <Grid item xs={12}>
          <DividendAccrualList
            ibConId={ibConId}
            tableRenderer={MUITableRenderer}
            rowsPerPage={10}
            columns={[
              TableFieldMeta.localId,
              TableFieldMeta.field('symbol', 'Symbol'),
              TableFieldMeta.field('description', 'Description'),
              TableFieldMeta.field('reportDate', 'Report Date'),
              TableFieldMeta.field('exDate', 'Ex Date'),
            ]}
            onRowSelect={onRowSelect}
          />
        </Grid>

      </Grid>
    </div>
  );
}

export default SelectDividendAccrualForm;
