import { graphql } from 'react-relay';

const addVueContractMutation = graphql`
  mutation AddVueContractMutation (
    $vueId: ID!
    $contractId: ID!
    $weight: Decimal!
    $description: String!
  ) {
    addVueContract(
      vueId: $vueId
      contractId: $contractId
      weight: $weight
      description:$description

    ) {
      vue {
        id
        vuecontractSet {
          edges {
            node {
              id
              weight
              description 
              contract {
                id
                symbol
                currency
                name
                exchange {
                  symbol
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default addVueContractMutation;
