import { graphql } from 'react-relay';

const setResponsibleContract = graphql`
  mutation SetResponsibleContractMutation (
    $brokerActivityId: ID
    $contractId: ID
  ) {
    setResponsibleStock(
      brokerActivityId: $brokerActivityId
      contractId: $contractId
    ) {
      brokerActivity {
        id
        responsiblecontract {
          brokerActivity {
            id
          }
          id
          contract {
            id
            name
            ibConId
            symbol
            currency
          }
        }
      }
    }
  }
`;

export default setResponsibleContract;
