import * as React from 'react';
import TableStatus from '../TableStatus';

// eslint-disable-next-line import/prefer-default-export
export const renderStatus = (node) => {
  let nodeAccess = node.value.brokeractivityPtr;
  nodeAccess = nodeAccess && nodeAccess.brokeractivitytransactionSet;
  const isProcessed = nodeAccess && nodeAccess.edges.length > 0;
  if (isProcessed) {
    return <TableStatus key={`${node.id}`} primary>Processed</TableStatus>;
  }

  return null;
};
