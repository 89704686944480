import * as React from 'react';
import Link from '@material-ui/core/Link';
import { fromGlobalId } from '../../../helpers/ID';

const TenantLink = (props) => {
  const { tenant } = props;
  if (!tenant) return null;
  return (
    <Link href={`/tenant/${fromGlobalId(tenant.id)[1]}`}>
      {tenant.name}
    </Link>
  );
};

export default TenantLink;
