import { graphql } from 'react-relay';

const createVueResearchMutation = graphql`
  mutation CreateVueResearchMutation (
    $vueId: ID!
    $title: String!
    $researchPdf: Upload!
  ) {
    createVueResearch (
      vueId: $vueId
      title: $title
      researchPdf: $researchPdf
    ) {
      vueResearch {
        id
        title
        researchPdfUrl
        createdAt
        updatedAt
        createdBy {
          id
          username
        }
      }
    }
  }
`;

export default createVueResearchMutation;
