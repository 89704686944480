import React, { Suspense } from 'react';

const withSuspense = suspendableComp => (props) => {
  const SuspendableComp = suspendableComp;
  return (
    <Suspense
      fallback={<div />}
    >
      <SuspendableComp
        {...props}
      />
    </Suspense>
  );
};

export default withSuspense;
