import { graphql } from 'react-relay';

// language=GraphQL
const executeCron = graphql`
  mutation CronExecuteMutation(
    $functionUrl: String
    $params: GenericScalar
  ){
    executeCron(
      functionUrl: $functionUrl
      params: $params
    ) {
      response
    }
  }
`;

export default executeCron;
