import React from 'react';
import { graphql } from 'react-relay';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { commonStyles } from '../common/Styles';
import { toLocal } from '../../helpers/ID';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  root: {
    flexGrow: 1,
  },
}));

const buildTree = (tenants) => {
  const roots = [];
  const items = {};
  tenants.forEach((v, i, a) => {
    let mergedItem;
    if (v.id in items) {
      mergedItem = items[v.id];
      mergedItem.name = v.name;
    } else {
      mergedItem = {
        id: v.id,
        name: v.name,
        children: [],
      };
      items[v.id] = mergedItem;
    }
    if (!v.parent) {
      roots.push(mergedItem);
      return;
    }
    let parent;
    if (v.parent.id in items) {
      parent = items[v.parent.id];
    } else {
      parent = { id: v.parent.id, children: [] };
      items[v.parent.id] = parent;
    }
    parent.children.push(mergedItem);
  });
  return roots;
};


const renderTree = node => (
  <TreeItem
    key={node.id}
    nodeId={node.id}
    label={node.name}
    onLabelClick={(e) => {
      window.location.href = `/tenant/${toLocal(node.id)}`;
      e.preventDefault();
    }}
  >
    {Array.isArray(node.children) ? node.children.sort(
      (a, b) => a.name.localeCompare(b.name),
    ).map(inNode => renderTree(inNode)) : null}
  </TreeItem>
);


function TenantTreeView(props) {
  const classes = useStyles();
  const { tenants: inTenants } = props;

  const [tenantRoots] = React.useState(buildTree(inTenants.edges.map(x => x.node)));
  return (
    <div style={{ width: '100%' }}>
      <Paper
        className={classes.tableHolderRoot}
        style={{ padding: '30px 20px 30px 20px' }}
      >
        <div
          style={{
            overflowY: 'scroll',
            height: 715,
          }}
        >
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={tenantRoots.map(x => x.id)}
          >
            { tenantRoots.map(tenantRoot => renderTree(tenantRoot)) }
          </TreeView>
        </div>
      </Paper>
    </div>
  );
}

export default createQueryRendererProgressV2(
  graphql`
    query TenantTreeViewQuery {
      tenants {
        edges {
          node {
            id
            name
            parent {
              id
            }
          }
        }
      }
    }
  `,
  TenantTreeView,
  {},
  {},
  {
    fetchReady: props => !!props.tenants,
  },
);
