import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';


function DrawerGridItemSubGroupHeading(props) {
  const {
    heading,
  } = props;
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2">
        {heading}
      </Typography>
    </Grid>
  );
}

DrawerGridItemSubGroupHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default DrawerGridItemSubGroupHeading;
