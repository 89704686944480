// Taken from https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, m => m.codePointAt(0));
}

function bytesToBase64(bytes) {
  const binString = Array.from(bytes, x => String.fromCodePoint(x)).join('');
  return btoa(binString);
}

export function toBase64(string) {
  return bytesToBase64(new TextEncoder().encode(string));
}

export function fromBase64(string) {
  return new TextDecoder().decode(base64ToBytes(string));
}
