import * as React from 'react';
import UserSearch from './UserSearch';
import EntitySelectionDlg from './EntitySelectionDlg';


function UserSelectionDlg(props) {
  const {
    children,
    ...otherProps
  } = props;
  return (
    <EntitySelectionDlg
      {...otherProps}
      searchComp={UserSearch}
      dialogTitle="Select Contracts"
    />
  );
}

UserSelectionDlg.propTypes = {
  ...EntitySelectionDlg.propTypes,
};

UserSelectionDlg.defaultProps = {
  ...EntitySelectionDlg.defaultProps,
};

export default UserSelectionDlg;
