import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function DrawerGridItemHeading(props) {
  const { heading, xs } = props;
  return (
    <Grid item xs={xs || 12}>
      <div>
        <Typography variant="h6" gutterBottom>
          {heading}
        </Typography>
      </div>
    </Grid>
  );
}

DrawerGridItemHeading.propTypes = {
  heading: PropTypes.string.isRequired,
};

export default DrawerGridItemHeading;
