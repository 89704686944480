/**
 * @flow
 * @relayHash 47fd151ae04eb49d74b2e74ea976f6f9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type UpdateMFADeactivationRequestStatusMutationVariables = {|
  mfaDeactivationRequestId: string,
  status: ApprovableStatus,
|};
export type UpdateMFADeactivationRequestStatusMutationResponse = {|
  +updateMfaDeactivationRequestStatus: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +mfaDeactivationRequest: ?{|
      +id: string,
      +status: ApprovableStatus,
      +createdAt: any,
      +forUser: {|
        +id: string,
        +email: string,
      |},
      +hasCompleteApprovals: ?boolean,
    |},
  |}
|};
export type UpdateMFADeactivationRequestStatusMutation = {|
  variables: UpdateMFADeactivationRequestStatusMutationVariables,
  response: UpdateMFADeactivationRequestStatusMutationResponse,
|};
*/


/*
mutation UpdateMFADeactivationRequestStatusMutation(
  $mfaDeactivationRequestId: ID!
  $status: ApprovableStatus!
) {
  updateMfaDeactivationRequestStatus(mfaDeactivationRequestId: $mfaDeactivationRequestId, status: $status) {
    errors {
      field
      messages
    }
    mfaDeactivationRequest {
      id
      status
      createdAt
      forUser {
        id
        email
      }
      hasCompleteApprovals
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "mfaDeactivationRequestId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "ApprovableStatus!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMfaDeactivationRequestStatus",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "mfaDeactivationRequestId",
        "variableName": "mfaDeactivationRequestId"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      }
    ],
    "concreteType": "UpdateMFADeactivationRequestStatus",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mfaDeactivationRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "MFADeactivationRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "forUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasCompleteApprovals",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateMFADeactivationRequestStatusMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateMFADeactivationRequestStatusMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateMFADeactivationRequestStatusMutation",
    "id": null,
    "text": "mutation UpdateMFADeactivationRequestStatusMutation(\n  $mfaDeactivationRequestId: ID!\n  $status: ApprovableStatus!\n) {\n  updateMfaDeactivationRequestStatus(mfaDeactivationRequestId: $mfaDeactivationRequestId, status: $status) {\n    errors {\n      field\n      messages\n    }\n    mfaDeactivationRequest {\n      id\n      status\n      createdAt\n      forUser {\n        id\n        email\n      }\n      hasCompleteApprovals\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5715a765b8f84739e3d681ac410b21bf';
module.exports = node;
