import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { ROWS_PER_PAGE } from '../../common/constants';
import RelayFilterV2, {
  BrowserInterface,
  onPrePackConcreteValues,
} from '../../common/relayFilter/RelayFilterV2';

export const defaultFetchReady = props => (!!props.viewer);


const wrappedRendererFunc = (
  propConverter,
  render,
  outerProps,
  filter,
  fetchReady,
) => ({ error, props, retry }) => {
  if (error) {
    return (
      <div
        style={{
          margin: '100px 0px',
          textAlign: 'center',
          color: 'grey',
        }}
      >
        {error.message}
        :
        <br />
        Make sure backend is available, and there are no connectivity problems
      </div>
    );
  }
  const RenderClass = render;
  if (props) {
    const propsToChild = {
      filter,
      retry,
      ...props,
      ...outerProps,
      outerProps,
      queryRenderProps: props,
      ...((typeof propConverter === 'function') ? propConverter(outerProps, props) : propConverter),
    };

    if (fetchReady(props, error, retry)) {
      return <RenderClass viewer={props.viewer} {...propsToChild} />;
    }
  }
  return <LinearProgress />;
};

// QueryRendererProgressV2 is supported to replace QueryRendererProgress
export default function QueryRendererProgressV2(props) {
  const {
    query,
    component,
    propConverter,
    params,
    options,
    fetchReady,
    relay,
  } = props;

  let fetchReadyFinal = fetchReady;
  const queryParams = (typeof params === 'function') ? params(props) : params;
  let debounceFields = [];
  if (options) {
    const { debounceFields: debounceFieldsIn } = options;
    const { fetchReady: fetchReadyOption } = options;
    fetchReadyFinal = fetchReadyOption || fetchReady;
    if (debounceFieldsIn) debounceFields = debounceFieldsIn;
  }

  const browserInterface = new BrowserInterface();
  const filter = new RelayFilterV2({
    params: {
      offset: 0,
      first: ROWS_PER_PAGE,
      ...queryParams,
    },
    debounceFields,
    props,
    relay,
    browserInterface,
  });

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={
        wrappedRendererFunc(propConverter, component, props, filter, fetchReadyFinal)
      }
      variables={onPrePackConcreteValues(filter.resolvedParamObject())}
    />
  );
}

QueryRendererProgressV2.propTypes = {
  propConverter: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  query: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  options: PropTypes.object,
  fetchReady: PropTypes.func,
};

QueryRendererProgressV2.defaultProps = {
  propConverter: {},
  params: {},
  options: {},
  fetchReady: defaultFetchReady,
};

export const createQueryRendererProgressV2 = (
  query, // GraphQL query
  component, // React Component to be rendered with query results
  propConverter = {},
  /* Object or... Function returning Object
      which alters or introduces props passed to the component.
      Ideally depending on initial data query results.
      Function format (outerProps, props) => ({}) */
  params = {},
  /* Object or... Function returning Object. Parameters passed to initial data query.
      Function format (props) => ({}) */
  options = {},
  /* Options object.
        fetchReady: data ready function.
        debounceFields: array of string defining parameters to be debounced
     */
) => props => (
  <QueryRendererProgressV2
    query={query}
    component={component}
    propConverter={propConverter}
    params={params}
    options={options}
    {...props}
  />
);
