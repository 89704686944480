import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButtonGroup } from '@material-ui/lab';
import TableToggleButton from './TableToggleButton';

function TableToggleButtonGroup(props) {
  const {
    groupKey,
    toggleOptions,
    initialValue,
    onSetValue,
    disabled,
  } = props;
  const [value, setValue] = React.useState(initialValue);
  // const classes = useStyles();
  // const [loading, setLoading] = React.useState(false);
  return (
    <ToggleButtonGroup
      size="small"
      color="secondary"
      value={value}
      exclusive
      onChange={(e, newValue) => {
        setValue(newValue);
        if (onSetValue) onSetValue(newValue, groupKey);
      }}
      aria-label="Permission"
    >
      {
        toggleOptions.map(([key, caption]) => (
          <TableToggleButton
            size="small"
            value={key}
            disabled={disabled}
          >
            {caption}
          </TableToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}

TableToggleButtonGroup.propTypes = {
  disabled: PropTypes.bool,
};

TableToggleButtonGroup.defaultProps = {
  disabled: false,
};

export default TableToggleButtonGroup;
