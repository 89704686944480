import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { EnumValues } from '../EnumValue';
import { useCommonStyles } from '../Styles';

const sortedEnumMeta = enumMap => Object.entries(enumMap)
  .sort((a, b) => {
    const aTrim = a[1].description.trim();
    const bTrim = b[1].description.trim();
    return aTrim.localeCompare(bTrim);
  });


function InnerTabFilterSelectField(props) {
  const classes = useCommonStyles();
  const { 
    onChange,
    enumType,
    value,
    label,
    halfWidth,
    valueDescriber,
    helperText,
    multiple,
    captionDescriber,
    withEmpty,
    disabled,
    useCaption,
  } = props;

  return (
    <Grid item>
      <FormControl className={halfWidth ? classes.formControlHalf : classes.formControl}>
        <InputLabel htmlFor="accountType-helper">{label}</InputLabel>
        <EnumValues
          enumType={enumType}
          render={enumMeta => (
            <Select
              value={value}
              multiple={multiple}
              onChange={e => (onChange && onChange(e))}
              input={<Input name={enumType} id="age-helper" />}
              disabled={disabled}
            >
              {
                withEmpty && (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )
              }
              {
                sortedEnumMeta(enumMeta).map(
                  em => (
                    <MenuItem
                      key={em[0]}
                      value={useCaption
                        ? captionDescriber(enumMeta, em[0]) : valueDescriber(enumMeta, em[0])}
                    >
                      {captionDescriber(enumMeta, em[0])}
                    </MenuItem>
                  ),
                )
              }
            </Select>
          )}
        />
        {helperText && (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

InnerTabFilterSelectField.propTypes = {
  enumType: PropTypes.string.isRequired,
  valueDescriber: PropTypes.func,
  captionDescriber: PropTypes.func,
  withEmpty: PropTypes.bool,
  value: PropTypes.string,
  halfWidth: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  useCaption: PropTypes.bool,
  helperText: PropTypes.string,
};

InnerTabFilterSelectField.defaultProps = {
  valueDescriber: (enumMeta, enumKey) => enumKey,
  captionDescriber: (enumMeta, enumKey) => enumMeta[enumKey].description,
  useCaption: false,
  withEmpty: true,
  value: null,
  halfWidth: false,
  multiple: false,
  disabled: false,
  helperText: null,
};

export default InnerTabFilterSelectField;
