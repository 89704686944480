import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import { createFragmentContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import TableFieldMeta from '../../common/TableFieldMeta';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';
import VerticalTable from '../common/VerticalTable';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { ApproveButton, CloseButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import ApprovalList from '../approvals/ApprovalList';
import createApproval from '../../mutations/CreateApproval';
import updateMFADeactivationRequestStatus from '../../mutations/UpdateMFADeactivationRequestStatus';
import EnumValue from '../common/EnumValue';
import mvMutationErrors from '../../common/errorReport';
import MoreButton from '../common/buttons/MoreButton';
import DrawerGridItemParagraph from '../common/drawer/DrawerGridItemParagraph';


function MFADeactivationRequestListFormData(props) {
  const { mfaDeactivationRequest, onDeactivationSuccess } = props;
  const [request, setRequest] = React.useState(mfaDeactivationRequest);

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="MFA Deactivation"
        />
        <Grid item xs={12}>
          <VerticalTable
            columnMeta={[
              ['Request ID', TableFieldMeta.localId.accessor],
              ['Email', node => node.forUser.email],
              ['Status', node => (
                <EnumValue enumType="ApprovableStatus" enumValue={node.status} />
              )],
            ]}
            dataNode={request}
            showHeadings={false}
            valueAlignment="left"
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <CloseButton
              buttonText="Cancel"
              color="secondary"
              disabled={request.status !== 'PENDING'}
              onClickPromise={() => doCommitPromise(
                updateMFADeactivationRequestStatus,
                {
                  mfaDeactivationRequestId: request.id,
                  status: 'CANCELLED',
                },
                () => (onDeactivationSuccess && onDeactivationSuccess()),
              )}
            />
          </Grid>

          <Grid item>
            <ApproveButton
              buttonText="Deactivate"
              disabled={request.status !== 'PENDING' || !request.hasCompleteApprovals}
              onClickPromise={() => doCommitPromise(
                updateMFADeactivationRequestStatus,
                {
                  mfaDeactivationRequestId: request.id,
                  status: 'APPROVED',
                },
                () => (onDeactivationSuccess && onDeactivationSuccess()),
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <Grid item xs={12}>
          <ApprovalList approvals={request.approvals.edges} />
        </Grid>

        <DrawerGridItemParagraph sm={12}>
          This panel is to confirm the deactivation of any existing
          MFA setup for the user. Please verify the identity and the
          intention of the user before approving this request and
          proceeding with the deactivation.
        </DrawerGridItemParagraph>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ApproveButton
              buttonText="Client has been verified"
              onClickPromise={() => doCommitPromise(
                createApproval,
                { approvableId: request.id },
                (response) => {
                  if (!mvMutationErrors(response, 'createApproval')) {
                    const approvalEdges = [
                      ...request.approvals.edges,
                      { node: response.createApproval.approval },
                    ];
                    const { hasCompleteApprovals } = response.createApproval;
                    const newRequest = {
                      ...request,
                      approvals: { edges: approvalEdges },
                      hasCompleteApprovals,
                    };
                    setRequest(newRequest);
                  }
                },
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}


const query = graphql`
  query MFADeactivationRequestListFormQuery(
    $mfaDeactivationRequestId: ID!
  ) {
    viewer {
      id
      mfaDeactivationRequests(
        id: $mfaDeactivationRequestId
      ) {
        edges {
          node {
            ...MFADeactivationRequestListForm_viewer
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  MFADeactivationRequestListFormData,
  graphql`
    fragment MFADeactivationRequestListForm_viewer on MFADeactivationRequestNode {
      id
      status
      forUser {
        id
        email
      }
      approvals {
        edges {
          node {
            id
            approvedBy {
              id
              username
            }
            approvedAt
          }
        }
      }
      hasCompleteApprovals
    }
  `,
);

const modifiedComp = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({
    mfaDeactivationRequest: viewer && viewer.mfaDeactivationRequests.edges[0].node,
    onDeactivationSuccess: outerProps.onDeactivationSuccess,
  }),
  ({ mfaDeactivationRequestId }) => ({ mfaDeactivationRequestId }),
);


function MFADeactivationRequestListForm(props) {
  const { mfaDeactivationRequest, onDeactivationSuccess } = props;
  const MFADeactivationRequestFormComp = modifiedComp;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();
  return (
    <div>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <MFADeactivationRequestFormComp
            mfaDeactivationRequestId={mfaDeactivationRequest.id}
            onDeactivationSuccess={(e) => {
              if (onDeactivationSuccess) onDeactivationSuccess();
              setDrawerOpen(false);
            }}

          />
        </div>
      </Drawer>
      <MoreButton
        onClick={() => setDrawerOpen(true)}
      />
    </div>
  );
}

export default MFADeactivationRequestListForm;
