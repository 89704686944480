/**
 * @flow
 * @relayHash dffa24ce5745c773e93fab1e223e267c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateVueContractMutationVariables = {|
  vueContractId: string,
  weight?: ?any,
  description?: ?string,
  researchPdf?: ?any,
|};
export type UpdateVueContractMutationResponse = {|
  +updateVueContract: ?{|
    +vueContract: ?{|
      +id: string,
      +weight: number,
      +description: ?string,
      +researchPdfUrl: ?string,
    |}
  |}
|};
export type UpdateVueContractMutation = {|
  variables: UpdateVueContractMutationVariables,
  response: UpdateVueContractMutationResponse,
|};
*/


/*
mutation UpdateVueContractMutation(
  $vueContractId: ID!
  $weight: Decimal
  $description: String
  $researchPdf: Upload
) {
  updateVueContract(vueContractId: $vueContractId, description: $description, weight: $weight, researchPdf: $researchPdf) {
    vueContract {
      id
      weight
      description
      researchPdfUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueContractId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "weight",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "researchPdf",
    "type": "Upload",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateVueContract",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "researchPdf",
        "variableName": "researchPdf"
      },
      {
        "kind": "Variable",
        "name": "vueContractId",
        "variableName": "vueContractId"
      },
      {
        "kind": "Variable",
        "name": "weight",
        "variableName": "weight"
      }
    ],
    "concreteType": "UpdateVueContractAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "vueContract",
        "storageKey": null,
        "args": null,
        "concreteType": "VueContractNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "weight",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "researchPdfUrl",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateVueContractMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateVueContractMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateVueContractMutation",
    "id": null,
    "text": "mutation UpdateVueContractMutation(\n  $vueContractId: ID!\n  $weight: Decimal\n  $description: String\n  $researchPdf: Upload\n) {\n  updateVueContract(vueContractId: $vueContractId, description: $description, weight: $weight, researchPdf: $researchPdf) {\n    vueContract {\n      id\n      weight\n      description\n      researchPdfUrl\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd67051f6a2573f313704299e7697ec1d';
module.exports = node;
