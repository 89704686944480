import * as React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { Drawer, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import AddChargeForm from './AddChargeForm';
import { AddButton } from '../common/buttons/BaseButton';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import ChargeInfoFormPane from './ChargeInfoFormPane';
import MoreButton from '../common/buttons/MoreButton';

function ChargesList(props) {
  const {
    viewer, filter, relay,
  } = props;
  const [addChargeDrawerOpen, setAddChargeDrawerOpen] = React.useState(false);
  const [detailDrawerOpen, setDetailDrawerOpen] = React.useState(false);
  const [members] = React.useState({
    charge: null,
  });
  const classes = useCommonStyles();
  filter.setRelay(relay);
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.account(node => node.account, 'Account', { charLimit: 40 }),
    TableFieldMeta.enum('FeeType', 'Fee Type', node => node.feeType),
    TableFieldMeta.priceField('amount', 'Amount', 'amount', 'currency'),
    {
      id: 'to',
      accessor: (node) => {
        const items = [];
        node.accountchargePtr.accountchargetenantportionSet.edges.map(
          (accountCharge, i) => {
            if (i !== 0) {
              items.push(', ');
            }
            items.push(
              <React.Fragment>
                {`${accountCharge.node.tenant.name}: ${accountCharge.node.quantity * -1}`}
              </React.Fragment>,
            );
            return items;
          },
        );
        return items;
      },
      Header: 'To',
      ...TableFieldMeta.centerAlignStyle,
    },
    TableFieldMeta.field('description', 'Description'),
    {
      id: 'more',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <MoreButton
          onClick={() => {
            members.charge = row.value;
            setDetailDrawerOpen(true);
          }}
        />),
    },
  ];

  return (
    <div className={classes.innerTabRoot}>

      <Drawer
        anchor="right"
        open={addChargeDrawerOpen}
        onClose={() => setAddChargeDrawerOpen(false)}
      >
        <AddChargeForm
          onClose={() => setAddChargeDrawerOpen(false)}
          onAddCharge={() => filter.update()}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={detailDrawerOpen}
        onClose={() => setDetailDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <ChargeInfoFormPane
            onClose={() => setDetailDrawerOpen(false)}
            chargeGetter={() => members.charge}
          />
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          defaultValue={filter.params.search}
          helperText="Account Name, Account ID, Description, ID"
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.manualAccountCharges : null)}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <AddButton
                onClick={() => setAddChargeDrawerOpen(true)}
                buttonText="Charge"
              />
            </Grid>
          </Grid>
        </Grid>

      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query ChargesListQuery (
    $first: Int,
    $offset: Int,
    $search: String,
    $accountId: ID,
  ) {
    viewer {
      ...ChargesList_viewer
      @arguments (
        first: $first,
        offset: $offset,
        search: $search,
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  ChargesList,
  {
    viewer: graphql`
      fragment ChargesList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        accountId: {type: ID}
      ) {
        manualAccountCharges(
          orderBy: "-createdAt"
          first: $first
          offset: $offset
          search: $search
          accountId: $accountId
        ) {
          edges {
            node {
              id
              amount
              description
              createdAt
              updatedAt
              feeType
              currency
              account {
                id
                name
              }
              accountchargePtr {
                accountchargetenantportionSet {
                  edges {
                    node {
                      quantity
                      tenant {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
  {
    debounceFields: ['search'],
  },
));
