import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import feeTemplateMutation from '../../mutations/FeeTemplate';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { doCommitPromise } from '../../common/commit';

const templateTypes = [
  { name: 'OVR', description: 'Override' },
  { name: 'DEF', description: 'Default' },
];

export default function FeeTemplateForm(props) {
  const { feeTemplate, onClose, onAdd } = props;
  const isUpdate = !!feeTemplate;
  const [templateType, setTemplateType] = React.useState(
    isUpdate ? feeTemplate.type : templateTypes[0].name,
  );
  const [currency, setCurrency] = React.useState(isUpdate ? feeTemplate.currency : 'AUD');
  const [members] = React.useState({
    name: isUpdate ? feeTemplate.name : '',
    description: isUpdate ? feeTemplate.description : '',
  });
  const classes = useCommonStyles();

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemTextField
          xs={12}
          id="name"
          label="Name"
          defaultValue={members.name}
          onChange={(e) => {
            members.name = e.target.value;
          }}
        />

        <DrawerGridItemSelectField
          label="Type"
          enumType="FeeTemplateType"
          value={templateType}
          onChange={e => setTemplateType(e.target.value)}
        />

        <DrawerGridItemSelectField
          enumType="Currency"
          label="Currency"
          value={currency}
          disabled={isUpdate}
          onChange={e => setCurrency(e.target.value)}
        />

        <DrawerGridItemTextField
          xs={12}
          id="description"
          label="Description"
          defaultValue={members.description}
          onChange={(e) => {
            members.description = e.target.value;
          }}
        />

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                feeTemplateMutation,
                {
                  input: {
                    id: feeTemplate ? feeTemplate.id : null,
                    name: members.name,
                    description: members.description,
                    type: templateType,
                    currency,
                  },
                },
                () => {
                  onClose && onClose();
                  onAdd && onAdd();
                },
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>

    </div>
  );
}
