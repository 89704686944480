import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TableFieldMeta from '../../common/TableFieldMeta';
import UserLink from '../common/links/UserLink';
import ApprovableStatus from './ApprovableStatus';
import { useCommonStyles } from '../common/Styles';
import MFADeactivationRequestListForm from './MFADeactivationRequestListForm';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import TablePaginator from '../common/TablePaginator';
import BaseGridContainer from '../common/BaseGridContainer';


function MFADeactivationRequestList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.createdBy,
    TableFieldMeta.field(node => <UserLink user={node.forUser} />, 'User ID'),
    TableFieldMeta.field(node => <ApprovableStatus approvable={node} />,
      'Status', { align: 'center' }),
    TableFieldMeta.field(node => (
      <MFADeactivationRequestListForm mfaDeactivationRequest={node} />
    ),
    '', { align: 'center' }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      <BaseGridContainer>
        <InnerTabFilterSelectField
          enumType="ApprovableStatus"
          label="Status"
          value={filter.params.status}
          helperText="Approved/Pending/Cancelled"
          onChange={e => filter.updateVar('status', e.target.value)}
        />
        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.mfaDeactivationRequests : null)}
            filter={filter}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query MFADeactivationRequestListQuery(
    $first: Int
    $offset: Int
    $status: String
  ) {
    viewer {
      ...MFADeactivationRequestList_viewer
      @arguments(
        first: $first
        offset: $offset
        status: $status
      )
    }
  }
`;

const container = createRefetchContainer(
  MFADeactivationRequestList,
  {
    viewer: graphql`
      fragment MFADeactivationRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int, defaultValue: 0}
        status: {type: String}
      ) {
        mfaDeactivationRequests(
          first: $first
          offset: $offset
          status: $status
          orderBy: "-createdAt"
        ) {
          count
          edges {
            node {
              id
              status
              createdAt
              createdBy {
                id
                fullName
              }
              forUser {
                id
                email
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({}),
));
