import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { commitMutation } from 'react-relay';
import { useCommonStyles } from '../common/Styles';
import environment from '../../Environment';
import { AddButton, CloseButton } from '../common/buttons/BaseButton';
import generateRemittanceAdviceMutation from '../../mutations/GenerateRemittanceAdvice';
import notiStack from '../../common/notiStack';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';
import { isRecurNull } from '../../common/helpers';


export default function TenantRemittanceAdviceGenerateForm(props) {
  const classes = useCommonStyles();
  const { 
    tenantId, 
    viewer, 
    onClose,
  } = props;

  const data = isRecurNull(viewer, ['tenants', 'edges', 0, 'node', 'tenantAccounts', 'edges']);

  const onAdd = () => {
    const variables = {
      input: {
        tenantId,
      },
    };

    commitMutation(
      environment,
      {
        mutation: generateRemittanceAdviceMutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            notiStack.error(errors[0].message);
          } else if (response.generateRemittanceAdvice.errors.length > 0) {
            notiStack.error(response.generateRemittanceAdvice.errors[0].messages[0]);
          } else if (onClose) {
            onClose();
          }
        },
      },
    );
  };

  return (
    <div className={classes.drawerRootExtended}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Generate Remittance Advice
          </Typography>
        </Grid>

        <DataTableWidget
          xs={12}
          title="Action Log"
          columnMeta={[
            TableFieldMeta.field('name', 'Name'),
            TableFieldMeta.dateField('latestWithdrawalDate', 'Latest Withdrawal'),
            TableFieldMeta.field('postWithdrawalCashTransferCount', 'Transactions (Post)'),
          ]}
          dataNodes={data ? data.map(x => x.node) : []}
        />

        {/*Control bar*/}
        <Grid item xs={12}>
          <Grid container spacing={1} justify="flex-end" style={{ marginTop: '10px' }}>
            <Grid item>
              <AddButton onClick={onAdd} />
            </Grid>
            <Grid item>
              <CloseButton
                onClick={() => onClose()}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}
