/**
 * @flow
 * @relayHash d8e9d4837e11962c7a2291380612a56b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type IBSimMenuItemIsPaperQueryVariables = {||};
export type IBSimMenuItemIsPaperQueryResponse = {|
  +adminMeta: ?{|
    +isPaper: ?boolean,
    +pendingApplications: ?number,
    +pendingTransfers: ?number,
  |}
|};
export type IBSimMenuItemIsPaperQuery = {|
  variables: IBSimMenuItemIsPaperQueryVariables,
  response: IBSimMenuItemIsPaperQueryResponse,
|};
*/


/*
query IBSimMenuItemIsPaperQuery {
  adminMeta {
    isPaper
    pendingApplications
    pendingTransfers
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "adminMeta",
    "storageKey": null,
    "args": null,
    "concreteType": "AdminMeta",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "isPaper",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pendingApplications",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "pendingTransfers",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "IBSimMenuItemIsPaperQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "IBSimMenuItemIsPaperQuery",
    "argumentDefinitions": [],
    "selections": (v0/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "IBSimMenuItemIsPaperQuery",
    "id": null,
    "text": "query IBSimMenuItemIsPaperQuery {\n  adminMeta {\n    isPaper\n    pendingApplications\n    pendingTransfers\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '432769337d95894e08666e8a99436dbf';
module.exports = node;
