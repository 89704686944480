import React from 'react';
import withDebounce from './debounce';

const debounceRefresh = withDebounce(
  (tableRef) => {
    tableRef.current.refreshData();
  },
  300,
);


const paramDataToUrl = (inPara) => {
  if (Array.isArray(inPara)) {
    return `[${inPara.join(',')}]`;
  }
  return inPara;
};

export default class RelayFilter {
  constructor(params, debounceFields, outerProps) {
    this.params = params;
    this.tableRef = React.createRef();
    this.debounceFields = debounceFields || [];
    this.urlParamPrefix = null;
    if (outerProps) {
      const { location, history, urlParamPrefix } = outerProps;
      this.urlParamPrefix = urlParamPrefix;
      if (location && history) {
        this.pathName = location.pathname;
        this.history = history;
        const queryParams = new URLSearchParams(location.search);
        for (const key of Object.keys(params)) {
          const compKey = `${(urlParamPrefix || '')}${key}`;
          const val = queryParams.get(compKey);
          if (val !== null) {
            if (val === 'true') this.params[key] = true;
            else if (val === 'false') this.params[key] = false;
            else if (val.charAt(0) === '[' && val.charAt(val.length - 1) === ']') {
              this.params[key] = val.substr(1, val.length - 2).split(',');
            } else {
              this.params[key] = val;
            }
          }
        }
      }
    }
  }

  updateVar = (key, value) => {
    this.params[key] = value;
    if (this.debounceFields.includes(key)) {
      debounceRefresh(this.tableRef);
    } else {
      this.tableRef.current.refreshData();
    }
    this.updateUrl();
  };

  updateVars = (varsObject) => {
    let instantUpdates = false;
    for (const [key, value] of Object.entries(varsObject)) {
      this.params[key] = value;
      instantUpdates = instantUpdates || !this.debounceFields.includes(key);
    }

    if (instantUpdates) {
      this.tableRef.current.refreshData();
    } else {
      debounceRefresh(this.tableRef);
    }
    this.updateUrl();
  };

  updateUrl() {
    const prefix = this.urlParamPrefix || '';
    if (this.pathName && this.history) {
      const varString = Object.keys(this.params).filter(
        k => this.params[k] !== null && this.params[k] !== '',
      ).map(k => `${prefix}${k}=${paramDataToUrl(this.params[k])}`).join('&');
      window.history.pushState('FilterUpdate', 'FilterUpdate', `${this.pathName}?${varString}`);
    }
  }

  update = () => {
    this.tableRef.current.refreshData();
  };

  fetchParams = () => this.params;

  setDebounceFields = (fields) => {
    this.debounceFields = fields;
  }
}
