import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';
import DrawerGridItemLoaderField from '../common/drawer/DrawerGridItemLoaderField';
import DrawerGridItemParagraph from '../common/drawer/DrawerGridItemParagraph';
import PortfolioLoader from '../common/fieldLoaders/PortfolioLoader';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { TransferButton } from '../common/buttons/BaseButton';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import { offMarketTradeMutation } from '../../mutations/OffMarket';
import { doCommitPromise } from '../../common/commit';
import { toCurrencyFormat } from '../../common/helpers';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import notiStack from '../../common/notiStack';

function getCaption(price, quantity, currency) {
  return `Consideration: ${toCurrencyFormat(price * quantity, currency)}`;
}

function OffMarketTradeForm(props) {
  const classes = useCommonStyles();
  const {
    onClose,
    holding,
    accountId,
    onSuccess,
  } = props;
  const initPrice = 0.0;
  const initQty = holding ? holding.quantity : 0;
  const [counterpartyAccount, setCounterpartyAccount] = React.useState(null);
  const [counterpartyPortfolio, setCounterpartyPortfolio] = React.useState(null);
  const [currency, setCurrency] = React.useState(holding ? holding.contract.currency : null);
  const [caption, setCaption] = React.useState(getCaption(initPrice, initQty, currency));
  const [ucVar] = React.useState({
    quantity: initQty,
    price: initPrice,
  });

  const updateCaption = () => {
    setCaption(getCaption(ucVar.price, ucVar.quantity, currency));
  };

  const doUpdatePromise = () => {
    if (!counterpartyPortfolio) {
      notiStack.error('Please select a counterparty portfolio');
      return null;
    }

    return doCommitPromise(
      offMarketTradeMutation,
      {
        accountId,
        portfolioId: holding.portfolio.id,
        contractId: holding.contract.id,
        counterpartyAccountId: counterpartyAccount.id,
        counterpartyPortfolioId: counterpartyPortfolio.id,
        currency,
        price: Number(ucVar.price),
        quantity: Number(ucVar.quantity),
      },
      () => (onSuccess && onSuccess()),
      () => (onClose && onClose()),
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Off Market Trade"
        />

        <DrawerGridItemSelectField
          value={currency}
          label="Currency"
          enumType="Currency"
          onChange={(e) => {
            setCurrency(e.target.value);
            updateCaption();
          }}
        />

        <DrawerGridItemNumberField
          label="Price"
          defaultValue={ucVar.price}
          onChange={(e) => {
            ucVar.price = e.target.value;
            updateCaption();
          }}
        />

        <DrawerGridItemNumberField
          label="Quantity"
          defaultValue={ucVar.quantity}
          onChange={(e) => {
            ucVar.quantity = e.target.value;
            updateCaption();
          }}
        />

        <DrawerGridItemParagraph sm={12}>
          {caption}
        </DrawerGridItemParagraph>

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            caption="Counter Party Account"
            onSelect={a => setCounterpartyAccount(a)}
          />
        </Grid>

        {counterpartyAccount && (
          <DrawerGridItemLoaderField
            sm={12}
            label="Counter Party Portfolio"
            loader={PortfolioLoader}
            loaderProps={{ account: counterpartyAccount }}
            onChange={p => setCounterpartyPortfolio(p)}
          />
        )}

        <Grid item xs={12}>
          <BottomGridContainer>
            <TransferButton
              onClickPromise={() => doUpdatePromise()}
              buttonText="Trade"
            />
          </BottomGridContainer>
        </Grid>

      </DrawerGridItemContainer>
    </div>
  );
}

export default OffMarketTradeForm;
