import React from 'react';
import { withTabItems } from '../common/RoutableTabContainer';
import { toGlobalId } from '../../helpers/ID';
import UserActionLog from './ActionLogList';
import withSuspense from '../common/table/Suspention';

const UserDetails = withSuspense(React.lazy(
  () => (process.env.DEPLOYMENT_CODE === 'AU'
    ? import('./au/UserDetails') : import('./uk/UserDetails')),
));

const userCopy = props => ({
  userId: toGlobalId('UserNode', props.match.params.userId),
  permissionEdges: props.permissionEdges,
});

const forUserCopy = props => ({
  forUserId: toGlobalId('UserNode', props.match.params.userId),
  permissionEdges: props.permissionEdges,
});

export default withTabItems([
  {
    label: 'Details',
    url: 'details',
    comp: UserDetails,
    propCopyFn: userCopy,
  },
  {
    label: 'Action Logs',
    url: 'action-logs',
    comp: UserActionLog,
    propCopyFn: forUserCopy,
  },
]);
