import { withTabItems } from '../common/RoutableTabContainer';
import PortfolioOrderList from './PortfolioOrderList';
import StockOrderList from './StockOrderList';
import BrokerOrderList from './BrokerOrderList';
import FxOrderList from './FxOrderList';
import ExchangeSession from './ExchangeSession';
import BulkOrderList from './BulkOrderList';
import OrderApprovalList from './OrderApprovalList';
import OrderProposalList from './OrderProposalList';
import PortfolioOrderRequestList from './PortfolioOrderRequestList';

export default withTabItems([
  {
    label: 'Stock Order',
    url: 'stock-orders',
    comp: StockOrderList,
  },
  {
    label: 'Vue Order',
    url: 'vue-orders',
    comp: PortfolioOrderList,
  },
  {
    label: 'Fx Order',
    url: 'fx-orders',
    comp: FxOrderList,
  },
  {
    label: 'Broker Order',
    url: 'broker-orders',
    comp: BrokerOrderList,
  },
  {
    label: 'Bulk Order',
    url: 'bulk-orders',
    comp: BulkOrderList,
  },
  {
    label: 'Proposals',
    url: 'proposals',
    comp: OrderProposalList,
  },
  {
    label: 'Approvals',
    url: 'approvals',
    comp: OrderApprovalList,
  },
  {
    label: 'Exchange Session',
    url: 'exchange-session',
    comp: ExchangeSession,
  },
  {
    label: 'Order Request',
    url: 'order-requests',
    comp: PortfolioOrderRequestList,
    permission: ['TRD'],
  },
]);
