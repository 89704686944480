import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import StockOrderTicks from './StockOrderTicks';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';


function BulkOrderList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;

  filter.setRelay(relay);
  const columns = [
    TableFieldMeta.linkField(node => node.id, 'bulk-oder', 'ID'),
    TableFieldMeta.createdAt,
    TableFieldMeta.field(node => (node.createdBy && node.createdBy.fullName), 'Created By'),
    TableFieldMeta.field(node => node.portfolioorderSet.count, 'Orders'),
    TableFieldMeta.field(
      node => Array.from(new Set(node.contracts.map(x => x.symbol))).join(' • '),
      'Contracts',
    ),
    // TableFieldMeta.field(node => <StateCounts statusCounts={node.statusCounts} />, 'Status')
    TableFieldMeta.field(
      node => (
        <StockOrderTicks
          parentOrder={node}
          stockOrders={node.stockOrders.edges.map(x => x.node)}
        />
      ),
      'Status', { align: 'center' },
    ),
    TableFieldMeta.account(node => node.account),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.bulkOrders : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query BulkOrderListQuery (
    $first: Int
    $offset: Int
    $accountId: ID
  ) {
    viewer {
      ...BulkOrderList_viewer
      @arguments (
        first: $first
        offset: $offset
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  BulkOrderList,
  {
    viewer: graphql`
      fragment BulkOrderList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID, defaultValue: null}
      ) {
        bulkOrders(
          first: $first
          offset: $offset
          orderBy: "-createdAt"
          account: $accountId
        ) {
          edges {
            node {
              id
              createdAt
              createdBy {
                id
                fullName
              }
              portfolioorderSet {
                count
              }
              contracts {
                symbol
              }
              
              statusCounts {
                type
                count
                description
              }
              
              account {
                id
                name
              }

              stockOrders {
                edges {
                  node {
                    id
                    quantity
                    brokerOrder {
                      id
                      matched
                      status
                      quantity
                      quantityFilled
                      quantityLeft
                    }
                  }
                }
              }
              
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
));
