import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default function ProcessAdd(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20.6,20v-2h-6v2H20.6z"
      />
      <path
        d="M2,21l21-9L2,3l0,7l15,2L2,14L2,21z"
      />
      <path
        d="M18.6,22v-6h-2v6"
      />

    </SvgIcon>
  );
}
