import { withTabItems } from '../common/RoutableTabContainer';
import AdviserRoleList from './AdviserRoleList';
import CannedSecurityQuestionList from './CannedSecurityQuestionList';
import UserRoleList from './UserRoleList';

export default withTabItems([
  {
    label: 'User Roles',
    url: 'user-roles',
    comp: UserRoleList,
  },
  {
    label: 'Adviser Roles',
    url: 'adviser-roles',
    comp: AdviserRoleList,
  },
  {
    label: 'Security Questions',
    url: 'security-questions',
    comp: CannedSecurityQuestionList,
  },
]);
