/**
 * @flow
 * @relayHash 038050cd21e2502e936c6ac0cda033de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotesDrawer_viewer$ref = any;
export type NotesDrawerQueryVariables = {|
  notesId?: ?string
|};
export type NotesDrawerQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: NotesDrawer_viewer$ref
  |}
|};
export type NotesDrawerQuery = {|
  variables: NotesDrawerQueryVariables,
  response: NotesDrawerQueryResponse,
|};
*/


/*
query NotesDrawerQuery(
  $notesId: ID
) {
  viewer {
    ...NotesDrawer_viewer_3dYKAb
    id
  }
}

fragment NotesDrawer_viewer_3dYKAb on Query {
  notes(id: $notesId, first: 1) {
    edges {
      node {
        id
        noteentrySet(orderBy: "-updatedAt") {
          edges {
            node {
              createdAt
              updatedAt
              createdBy {
                id
                fullName
              }
              id
              text
              noteentryattachmentSet {
                edges {
                  node {
                    id
                    file
                    fileUrl
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "notesId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "NotesDrawerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NotesDrawer_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "notesId",
                "variableName": "notesId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotesDrawerQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notes",
            "storageKey": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "notesId"
              }
            ],
            "concreteType": "NotesNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NotesNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NotesNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "noteentrySet",
                        "storageKey": "noteentrySet(orderBy:\"-updatedAt\")",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "-updatedAt"
                          }
                        ],
                        "concreteType": "NoteEntryNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "NoteEntryNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "NoteEntryNode",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updatedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "createdBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fullName",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "text",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "noteentryattachmentSet",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "NoteEntryAttachmentNodeConnection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "edges",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "NoteEntryAttachmentNodeEdge",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "node",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "NoteEntryAttachmentNode",
                                            "plural": false,
                                            "selections": [
                                              (v1/*: any*/),
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "file",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "fileUrl",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "name",
                                                "args": null,
                                                "storageKey": null
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "NotesDrawerQuery",
    "id": null,
    "text": "query NotesDrawerQuery(\n  $notesId: ID\n) {\n  viewer {\n    ...NotesDrawer_viewer_3dYKAb\n    id\n  }\n}\n\nfragment NotesDrawer_viewer_3dYKAb on Query {\n  notes(id: $notesId, first: 1) {\n    edges {\n      node {\n        id\n        noteentrySet(orderBy: \"-updatedAt\") {\n          edges {\n            node {\n              createdAt\n              updatedAt\n              createdBy {\n                id\n                fullName\n              }\n              id\n              text\n              noteentryattachmentSet {\n                edges {\n                  node {\n                    id\n                    file\n                    fileUrl\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '569792b1e3fa69bc850d2a1058d5f0d8';
module.exports = node;
