import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import PrivateRoute from './helpers/PrivateRoute';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import 'react-table/react-table.css';
import NotiStackHooker from './components/common/NotiStackHooker';
import { COLOR_HALO_DARK, COLOR_HALO_LIGHT } from './common/constants';

const primaryColor = localStorage.getItem('primaryThemeColor');
const secondaryColor = localStorage.getItem('secondaryThemeColor');
const strToColor = {
  amber,
  blue,
  blueGrey,
  brown,
  common,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
};

const getTheme = paletteType => createTheme({
  palette: {
    primary: (primaryColor
      ? strToColor[primaryColor]
      : { main: (paletteType === 'dark' ? COLOR_HALO_LIGHT : COLOR_HALO_DARK) }),
    secondary: (secondaryColor ? strToColor[secondaryColor] : red),
    type: paletteType,
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        paddingLeft: 10,
        paddingRight: 5,
      },
    },
  },
});

const App = (props) => {
  const { handler } = props;
  const [themeType, setThemeType] = React.useState(localStorage.getItem('theme') || 'light');
  const theme = getTheme(themeType);

  const onToggleTheme = () => {
    const newThemeType = themeType === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newThemeType);
    setThemeType(newThemeType);
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <NotiStackHooker />
          <Switch>
            <Route exact path="/login" component={Login} handler={handler} />
            <PrivateRoute path="/" component={AdminPanel} onToggleTheme={onToggleTheme} />
          </Switch>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
