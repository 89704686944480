import React from 'react';
import { Grid } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import moment from 'moment';
import CashTransactionList from './CashTransactionList';
import DividendAccrualList from './DividendAccrualList';
import MsgRouter from '../../common/MsgRouter';
import TableFieldMeta from '../../common/TableFieldMeta';
import DailyHoldings from '../holdings/DailyHoldings';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../common/constants';
import TableIconButton from '../common/table/TableIconButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import RTTableRenderer from '../common/table/RTTableRenderer';


function ContractHoldingWrapper(props) {
  const { msgRouter } = props;
  const [contract, setContract] = React.useState(null);
  const [reportDate, setReportDate] = React.useState(
    moment(Date.now()).format(MOMENT_DATE_FORMAT_GRAPHQL),
  );

  React.useEffect(() => {
    const onState = (client, state) => {
      const { contractHoldingContract, contractHoldingReportDate } = state;
      if (contractHoldingContract) {
        setContract(contractHoldingContract);
      }
      if (contractHoldingReportDate) {
        setReportDate(contractHoldingReportDate);
      }
    };
    if (msgRouter) msgRouter.addStateListener(onState);
    return function cleanup() {
      if (msgRouter) msgRouter.removeStateListener(onState);
    };
  }, []);

  if (!contract) return null;
  return (
    <Grid item xs={12}>
      <DailyHoldings
        key={`${contract.id}-${contract.ibContractId}-${reportDate}`}
        ibContractId={contract.ibContractId}
        contract={contract}
        reportDate={reportDate}
        urlParamPrefix="h."
      />
    </Grid>
  );
}


function CashTxnDivAccHoldingMixed(props) {
  const [members] = React.useState({
    msgRouter: new MsgRouter(),
  });

  const caPostReportDateColumns = [
    TableFieldMeta.customCell('actions', '', row => (
      <Grid container spacing={1} justify="center">
        <TableCompactGridItem>
          <TableIconButton
            tooltip={`Filter Accruals: Report Date = ${row.value.reportDate}`}
            iconClass={InfoIcon}
            color="secondary"
            onClick={() => members.msgRouter.publishState(
              this,
              {
                divAccrualReportDate: row.value.reportDate,
                divAccrualConId: row.value.ibConId,
              },
            )}
          />
        </TableCompactGridItem>
      </Grid>
    ), { width: 40 }),
  ];

  const daPostExDateColumns = [
    TableFieldMeta.customCell('actions', '', (row) => {
      const preDate = new Date(row.value.exDate);
      preDate.setDate(preDate.getDate() - 1);
      const preDateStr = moment(preDate).format(MOMENT_DATE_FORMAT_GRAPHQL);
      return (
        <Grid container spacing={1} justify="center">
          <TableCompactGridItem>
            <TableIconButton
              tooltip={`Filter Holding: Report-Date = ${preDateStr}`}
              iconClass={ShoppingBasketIcon}
              color="primary"
              onClick={() => {
                members.msgRouter.publishState(
                  this,
                  {
                    contractHoldingReportDate: preDateStr,
                    contractHoldingContract: {
                      symbol: row.value.symbol,
                      ibContractId: row.value.ibConId,
                    },
                  },
                );
              }}
            />
          </TableCompactGridItem>
        </Grid>
      );
    }, { width: 80 }),
  ];

  return (
    <div>
      <Grid container>

        <Grid item xs={12}>
          <CashTransactionList
            rowsPerPage={10}
            msgRouter={members.msgRouter}
            postReportDateColumns={caPostReportDateColumns}
            urlParamPrefix="ct."
          />
        </Grid>

        <Grid item xs={12}>
          <DividendAccrualList
            rowsPerPage={5}
            msgRouter={members.msgRouter}
            postExDateColumns={daPostExDateColumns}
            urlParamPrefix="da."
            tableRenderer={RTTableRenderer}
          />
        </Grid>

        <ContractHoldingWrapper
          msgRouter={members.msgRouter}
        />

      </Grid>
    </div>
  );
}

export default CashTxnDivAccHoldingMixed;
