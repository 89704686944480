import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';


function DrawerGridItemTextArea(props) {
  const {
    defaultValue,
    onChange,
    label,
    id,
    disabled,
    inputRef,
    maxRows,
    minRows,
    variant,
  } = props;
  return (
    <Grid item xs={12}>
      <TextField
        fullWidth
        id={id || (label && label.toLowerCase())}
        label={label}
        type="text"
        multiline
        maxRows={maxRows || 4}
        minRows={minRows || 4}
        variant={variant || 'filled'}
        inputRef={inputRef}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  );
}

export default DrawerGridItemTextArea;
