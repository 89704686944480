/**
 * @flow
 * @relayHash 0a87069cf5cc1b6938b2cbeae262561a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type Direction = "IN" | "OUT" | "%future added value";
export type TransferRequestStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type PositionTransferInput = {|
  accountId?: ?string,
  status?: ?TransferRequestStatus,
  transferId?: ?string,
  purchases?: ?$ReadOnlyArray<?PositionTransferPurchaseInput>,
  cashQuantities?: ?$ReadOnlyArray<?PositionTransferCashQuantityInput>,
  id?: ?string,
|};
export type PositionTransferPurchaseInput = {|
  contractId?: ?string,
  purchaseDate?: ?any,
  portfolioId?: ?string,
  quantity?: ?number,
  costBasis?: ?number,
  commission?: ?number,
  id?: ?string,
  dummy?: ?boolean,
|};
export type PositionTransferCashQuantityInput = {|
  currency?: ?string,
  quantity?: ?number,
|};
export type CreatePositionTransferMutationVariables = {|
  transferRequest?: ?PositionTransferInput
|};
export type CreatePositionTransferMutationResponse = {|
  +createPositionTransfer: ?{|
    +positionTransferRequest: ?{|
      +id: string,
      +positionTransferPurchases: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string
          |}
        |}>
      |},
    |},
    +ibTransfer: ?{|
      +id: string,
      +processedAt: ?any,
      +processedBy: ?{|
        +username: string,
        +fullName: ?string,
      |},
      +notes: ?{|
        +id: string
      |},
      +ignored: boolean,
      +ibConId: ?number,
      +currency: ?Currency,
      +description: ?string,
      +quantity: number,
      +cashQuantity: number,
      +positionAmount: number,
      +positionAmountInBase: number,
      +isin: ?string,
      +symbol: ?string,
      +reportDate: any,
      +contraAccountId: ?string,
      +contraCompany: ?string,
      +direction: Direction,
      +updatedAt: any,
      +transactionId: any,
      +contract: ?{|
        +id: string,
        +ibConId: number,
        +currency: Currency,
        +symbol: string,
      |},
      +brokeractivityPtr: {|
        +brokeractivitytransactionSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +account: {|
                +name: ?string,
                +id: string,
              |},
              +brokeractivitytransactioncontractdeltaSet: {|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +portfolio: {|
                      +id: string
                    |},
                    +contract: {|
                      +id: string,
                      +symbol: string,
                    |},
                    +quantity: number,
                  |}
                |}>
              |},
              +brokeractivitytransactioncashdeltaSet: {|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +currency: Currency,
                    +quantity: number,
                  |}
                |}>
              |},
            |}
          |}>
        |}
      |},
    |},
  |}
|};
export type CreatePositionTransferMutation = {|
  variables: CreatePositionTransferMutationVariables,
  response: CreatePositionTransferMutationResponse,
|};
*/


/*
mutation CreatePositionTransferMutation(
  $transferRequest: PositionTransferInput
) {
  createPositionTransfer(transferRequest: $transferRequest) {
    positionTransferRequest {
      id
      positionTransferPurchases {
        edges {
          node {
            id
          }
        }
      }
    }
    ibTransfer {
      id
      processedAt
      processedBy {
        username
        fullName
        id
      }
      notes {
        id
      }
      ignored
      ibConId
      currency
      description
      quantity
      cashQuantity
      positionAmount
      positionAmountInBase
      isin
      symbol
      reportDate
      contraAccountId
      contraCompany
      direction
      updatedAt
      transactionId
      contract {
        id
        ibConId
        currency
        symbol
      }
      brokeractivityPtr {
        brokeractivitytransactionSet {
          edges {
            node {
              id
              account {
                name
                id
              }
              brokeractivitytransactioncontractdeltaSet {
                edges {
                  node {
                    portfolio {
                      id
                    }
                    contract {
                      id
                      symbol
                    }
                    quantity
                    id
                  }
                }
              }
              brokeractivitytransactioncashdeltaSet {
                edges {
                  node {
                    currency
                    quantity
                    id
                  }
                }
              }
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferRequest",
    "type": "PositionTransferInput",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "transferRequest",
    "variableName": "transferRequest"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "positionTransferRequest",
  "storageKey": null,
  "args": null,
  "concreteType": "PositionTransferRequestNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "positionTransferPurchases",
      "storageKey": null,
      "args": null,
      "concreteType": "PositionTransferPurchaseNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PositionTransferPurchaseNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PositionTransferPurchaseNode",
              "plural": false,
              "selections": (v3/*: any*/)
            }
          ]
        }
      ]
    }
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "processedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "notes",
  "storageKey": null,
  "args": null,
  "concreteType": "NotesNode",
  "plural": false,
  "selections": (v3/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ignored",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibConId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cashQuantity",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "positionAmount",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "positionAmountInBase",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "isin",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reportDate",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contraAccountId",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "contraCompany",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "direction",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v24 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "transactionId",
  "args": null,
  "storageKey": null
},
v25 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contract",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v18/*: any*/)
  ]
},
v26 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "account",
  "storageKey": null,
  "args": null,
  "concreteType": "AccountNode",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/)
  ]
},
v27 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "portfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "PortfolioNode",
  "plural": false,
  "selections": (v3/*: any*/)
},
v28 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contract",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v18/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreatePositionTransferMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPositionTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePositionTransfer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "IBTransferNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "processedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivityPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokeractivitytransactionSet",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityTransactionNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v26/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncontractdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          (v27/*: any*/),
                                          (v28/*: any*/),
                                          (v13/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncashdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v13/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreatePositionTransferMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPositionTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePositionTransfer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibTransfer",
            "storageKey": null,
            "args": null,
            "concreteType": "IBTransferNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "processedBy",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivityPtr",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokeractivitytransactionSet",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityTransactionNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityTransactionNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v26/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncontractdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          (v27/*: any*/),
                                          (v28/*: any*/),
                                          (v13/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactioncashdeltaSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                        "plural": false,
                                        "selections": [
                                          (v11/*: any*/),
                                          (v13/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreatePositionTransferMutation",
    "id": null,
    "text": "mutation CreatePositionTransferMutation(\n  $transferRequest: PositionTransferInput\n) {\n  createPositionTransfer(transferRequest: $transferRequest) {\n    positionTransferRequest {\n      id\n      positionTransferPurchases {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    ibTransfer {\n      id\n      processedAt\n      processedBy {\n        username\n        fullName\n        id\n      }\n      notes {\n        id\n      }\n      ignored\n      ibConId\n      currency\n      description\n      quantity\n      cashQuantity\n      positionAmount\n      positionAmountInBase\n      isin\n      symbol\n      reportDate\n      contraAccountId\n      contraCompany\n      direction\n      updatedAt\n      transactionId\n      contract {\n        id\n        ibConId\n        currency\n        symbol\n      }\n      brokeractivityPtr {\n        brokeractivitytransactionSet {\n          edges {\n            node {\n              id\n              account {\n                name\n                id\n              }\n              brokeractivitytransactioncontractdeltaSet {\n                edges {\n                  node {\n                    portfolio {\n                      id\n                    }\n                    contract {\n                      id\n                      symbol\n                    }\n                    quantity\n                    id\n                  }\n                }\n              }\n              brokeractivitytransactioncashdeltaSet {\n                edges {\n                  node {\n                    currency\n                    quantity\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c6016a152220e21ee6cb7656409720d';
module.exports = node;
