/**
 * @flow
 * @relayHash f619717d99aa4158b1d032dc1a6cea46
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserProvisionRequestList_viewer$ref = any;
export type UserProvisionRequestListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  status?: ?string,
|};
export type UserProvisionRequestListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserProvisionRequestList_viewer$ref
  |}
|};
export type UserProvisionRequestListQuery = {|
  variables: UserProvisionRequestListQueryVariables,
  response: UserProvisionRequestListQueryResponse,
|};
*/


/*
query UserProvisionRequestListQuery(
  $first: Int
  $offset: Int
  $status: String
) {
  viewer {
    ...UserProvisionRequestList_viewer_3m8j9l
    id
  }
}

fragment UserProvisionRequestList_viewer_3m8j9l on Query {
  userProvisionRequests(first: $first, offset: $offset, status: $status, orderBy: "-createdAt") {
    count
    edges {
      node {
        id
        status
        createdAt
        createdBy {
          id
          fullName
        }
        clientAccount {
          id
          name
        }
        userModel {
          id
          email
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserProvisionRequestListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserProvisionRequestList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserProvisionRequestListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userProvisionRequests",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v3/*: any*/)
            ],
            "concreteType": "UserProvisionRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserProvisionRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserProvisionRequestNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "clientAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClientAccountNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "userModel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "email",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserProvisionRequestListQuery",
    "id": null,
    "text": "query UserProvisionRequestListQuery(\n  $first: Int\n  $offset: Int\n  $status: String\n) {\n  viewer {\n    ...UserProvisionRequestList_viewer_3m8j9l\n    id\n  }\n}\n\nfragment UserProvisionRequestList_viewer_3m8j9l on Query {\n  userProvisionRequests(first: $first, offset: $offset, status: $status, orderBy: \"-createdAt\") {\n    count\n    edges {\n      node {\n        id\n        status\n        createdAt\n        createdBy {\n          id\n          fullName\n        }\n        clientAccount {\n          id\n          name\n        }\n        userModel {\n          id\n          email\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0bde7f9c9d66aadf9e0ed6df41bf9f88';
module.exports = node;
