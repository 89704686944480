import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default function DrawerItem(props) {
  const {
    iconClass,
    caption,
    link,
    history,
  } = props;

  const IconClass = iconClass;

  const onClick = (event) => {
    // Use browser's default handling of CTRL/SHIFT clicks on hyperlinks
    if (event.shiftKey || event.ctrlKey) {
      return;
    }

    // Otherwise, use app's handling of normal clicks
    event.preventDefault();
    history.push(link);
  };

  return (
    <ListItem key={caption} button component="a" href={link} onClick={onClick}>
      <ListItemIcon>
        <IconClass />
      </ListItemIcon>
      <ListItemText primary={caption} />
    </ListItem>
  );
}
