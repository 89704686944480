/**
 * @flow
 * @relayHash 4757a8041a7cf8a5e4853ca963291c40
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type GenerateRangeReconciliationMutationVariables = {|
  rangeReconciliationId: string
|};
export type GenerateRangeReconciliationMutationResponse = {|
  +generateRangeReconciliation: ?{|
    +rangeReconciliation: ?{|
      +id: string,
      +noOfDays: ?number,
      +createdAt: any,
      +excelFileName: ?string,
      +excelFileUrl: ?string,
      +fromDate: ?any,
      +toDate: ?any,
      +ibrangereconciliationendbalanceSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +currency: Currency,
            +quantity: number,
          |}
        |}>
      |},
      +ibrangereconciliationdeltaSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +currency: Currency,
            +quantity: number,
          |}
        |}>
      |},
    |}
  |}
|};
export type GenerateRangeReconciliationMutation = {|
  variables: GenerateRangeReconciliationMutationVariables,
  response: GenerateRangeReconciliationMutationResponse,
|};
*/


/*
mutation GenerateRangeReconciliationMutation(
  $rangeReconciliationId: ID!
) {
  generateRangeReconciliation(rangeReconciliationId: $rangeReconciliationId) {
    rangeReconciliation {
      id
      noOfDays
      createdAt
      excelFileName
      excelFileUrl
      fromDate
      toDate
      ibrangereconciliationendbalanceSet {
        edges {
          node {
            id
            currency
            quantity
          }
        }
      }
      ibrangereconciliationdeltaSet {
        edges {
          node {
            id
            currency
            quantity
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rangeReconciliationId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateRangeReconciliation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rangeReconciliationId",
        "variableName": "rangeReconciliationId"
      }
    ],
    "concreteType": "GenerateRangeReconciliation",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "rangeReconciliation",
        "storageKey": null,
        "args": null,
        "concreteType": "IBRangeReconciliationNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "noOfDays",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "excelFileName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "excelFileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "toDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibrangereconciliationendbalanceSet",
            "storageKey": null,
            "args": null,
            "concreteType": "IBRangeReconciliationEndBalanceNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBRangeReconciliationEndBalanceNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBRangeReconciliationEndBalanceNode",
                    "plural": false,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibrangereconciliationdeltaSet",
            "storageKey": null,
            "args": null,
            "concreteType": "IBRangeReconciliationDeltaNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBRangeReconciliationDeltaNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBRangeReconciliationDeltaNode",
                    "plural": false,
                    "selections": (v2/*: any*/)
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateRangeReconciliationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateRangeReconciliationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateRangeReconciliationMutation",
    "id": null,
    "text": "mutation GenerateRangeReconciliationMutation(\n  $rangeReconciliationId: ID!\n) {\n  generateRangeReconciliation(rangeReconciliationId: $rangeReconciliationId) {\n    rangeReconciliation {\n      id\n      noOfDays\n      createdAt\n      excelFileName\n      excelFileUrl\n      fromDate\n      toDate\n      ibrangereconciliationendbalanceSet {\n        edges {\n          node {\n            id\n            currency\n            quantity\n          }\n        }\n      }\n      ibrangereconciliationdeltaSet {\n        edges {\n          node {\n            id\n            currency\n            quantity\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '19dba5d0d08a6b242b9179d6a060f35a';
module.exports = node;
