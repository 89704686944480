import { graphql } from 'react-relay';

// language=GraphQL
const dummyTransaction = graphql`
  mutation DummyTransactionMutation(
    $transactionId: ID!
    $dummy: Boolean
  ){
    dummyTransaction(
      transactionId: $transactionId
      dummy: $dummy
    ) {
      transaction {
        id
        dummy
        updatedAt
      }
    }
  }
`;

export default dummyTransaction;
