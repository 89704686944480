/**
 * @flow
 * @relayHash 94331939e36362af2669aa858e0cee28
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type Custodian = "CS" | "IB" | "LB" | "VI" | "%future added value";
export type CreateDepositRequestMutationVariables = {|
  accountId: string,
  amount: number,
  currency: string,
  custodian?: ?Custodian,
|};
export type CreateDepositRequestMutationResponse = {|
  +createDepositRequest: ?{|
    +cashTransferRequest: ?{|
      +id: string,
      +amount: number,
      +currency: Currency,
      +createdAt: any,
      +status: ?CashTransferStatus,
      +blocked: boolean,
      +account: {|
        +id: string,
        +name: ?string,
      |},
    |}
  |}
|};
export type CreateDepositRequestMutation = {|
  variables: CreateDepositRequestMutationVariables,
  response: CreateDepositRequestMutationResponse,
|};
*/


/*
mutation CreateDepositRequestMutation(
  $accountId: ID!
  $amount: Float!
  $currency: String!
  $custodian: Custodian
) {
  createDepositRequest(accountId: $accountId, amount: $amount, currency: $currency, custodian: $custodian) {
    cashTransferRequest {
      id
      amount
      currency
      createdAt
      status
      blocked
      account {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "amount",
    "type": "Float!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "custodian",
    "type": "Custodian",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createDepositRequest",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "custodian",
        "variableName": "custodian"
      }
    ],
    "concreteType": "CreateDepositRequestAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "currency",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "blocked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateDepositRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateDepositRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateDepositRequestMutation",
    "id": null,
    "text": "mutation CreateDepositRequestMutation(\n  $accountId: ID!\n  $amount: Float!\n  $currency: String!\n  $custodian: Custodian\n) {\n  createDepositRequest(accountId: $accountId, amount: $amount, currency: $currency, custodian: $custodian) {\n    cashTransferRequest {\n      id\n      amount\n      currency\n      createdAt\n      status\n      blocked\n      account {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '10def10d9ed1ccf81f594dd3afa4e2c8';
module.exports = node;
