import Grid from '@material-ui/core/Grid';
import React from 'react';
import { commitMutation } from 'react-relay';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemLoaderField from '../common/drawer/DrawerGridItemLoaderField';
import FeeTemplateLoader from '../common/fieldLoaders/FeeTemplateLoader';
import ExchangeLoader from '../common/fieldLoaders/ExchangeLoader';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import environment from '../../Environment';
import { createTenantExchangeFeeTemplate } from '../../mutations/TenantExchangeFeeTemplate';

function TenantExchangeFeeTemplateForm(props) {
  const { tenant, onClose } = props;
  const classes = useCommonStyles();
  const [exchange, setExchange] = React.useState(null);
  const [feeTemplate, setFeeTemplate] = React.useState(null);
  const addTenantExchangeFeeTemplate = () => {
    commitMutation(
      environment,
      {
        mutation: createTenantExchangeFeeTemplate,
        variables: {
          tenantId: tenant.id,
          exchangeId: exchange.id,
          feeTemplateId: feeTemplate.id,
        },
        onCompleted: (response, errors) => {
          if (!errors) {
            if (onClose) onClose();
          }
        },
      },
    );
  };
  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading="Add Tenant Exchange Fee Template"
        />
        <DrawerGridItemLoaderField
          sm={12}
          label="Exchange"
          loader={ExchangeLoader}
          onChange={e => setExchange(e)}
        />
        <DrawerGridItemLoaderField
          sm={12}
          label="Fee Template"
          loader={FeeTemplateLoader}
          onChange={f => setFeeTemplate(f)}
        />
        <BottomGridContainer>
          <AddButton
            disabled={!tenant || !exchange || !feeTemplate}
            onClick={() => addTenantExchangeFeeTemplate()}
          />
        </BottomGridContainer>
      </Grid>
    </div>
  );
}

export default TenantExchangeFeeTemplateForm;
