import TablePagination from '@material-ui/core/TablePagination';
import React from 'react';

import ReactTable from 'react-table';
import * as PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import { ROWS_PER_PAGE } from '../../common/constants';
import TablePaginationActions from './pagination/TablePaginationActions';
import TableFieldSelectorDlgForm from './table/TableFieldSelectorDlgForm';
import MUITableRenderer from './table/MUITableRenderer';


const injectedTdStyles = {
  paddingTop: '4px',
  paddingBottom: '4px',
};

// eslint-disable-next-line no-unused-vars
export function MuiPaginator(props) {
  const {
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <TablePagination
        rowsPerPageOptions={TablePaginationActions.ROW_COUNT_OPTIONS}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          native: true,
        }}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}

class PaginatedTable extends React.Component {
  constructor(props) {
    super(props);
    const { rowsPerPage } = props;

    this.state = {
      page: 0,
      rowsPerPage,
      isLoading: false,
      settingsDlgOpen: false,
    };
    this.fetchSeq = 0;
  }

  onFetchData = (state, instance) => {
    const { page, pageSize } = state;
    this.reFetchData(page, pageSize);
  };

  onChangePage = (page) => {
    const { rowsPerPage } = this.state;
    this.reFetchData(page, rowsPerPage);
  };

  onChangeRowsPerPage = (rowsPerPage) => {
    this.setState({
      rowsPerPage: Number(rowsPerPage),
    });
    this.reFetchData(0, Number(rowsPerPage));
  };

  refreshData = () => {
    const { rowsPerPage } = this.state;
    this.reFetchData(0, rowsPerPage);
  };

  reFetchData = (page, rowsPerPage) => {
    const {
      relay,
      viewer,
      getDataNode,
      getFetchParams,
      nodeResolver,
    } = this.props;

    const promise = new Promise((resolve, reject) => {
      // the function is executed automatically when the promise is constructed

      // after 1 second signal that the job is done with the result "done"
      if (!viewer) {
        resolve();
      }

      const dataNode = getDataNode();
      if (!dataNode) {
        reject(new Error('getDataNode function not specified'));
      }

      const offset = page * rowsPerPage;
      const fragmentVars = getFetchParams && getFetchParams(this.props, this.state);
      const reFetchVariables = fragmentVariables => ({
        ...fragmentVariables,
        ...fragmentVars,
        first: rowsPerPage,
        offset,
      });

      this.setState({ isLoading: true });

      relay.refetch(reFetchVariables, null, (error) => {
        if (error) {
          this.setState({ isLoading: false });
          reject(error);
        } else {
          resolve();
          this.fetchSeq += 1;
          const newDataNode = getDataNode();
          this.setState({
            data: newDataNode.edges.map(nodeResolver),
            isLoading: false,
            page,
            rowsPerPage,
          });
        }
      });
    });

    return promise;
  };

  render = () => {
    const {
      viewer,
      columns,
      getDataNode,
      tableRender,
      onRowSelectInfo,
      nodeResolver,
    } = this.props;
    const {
      page,
      data,
      rowsPerPage,
      isLoading,
      settingsDlgOpen,
    } = this.state;

    const displayColumns = [];
    columns.forEach((item, index) => {
      displayColumns.push({
        ...item,
        style: { ...item.style, ...injectedTdStyles },
      });
    });

    const { fetchSeq } = this;

    if (!viewer) {
      return <div />;
    }

    const dataNode = getDataNode();
    const { count } = dataNode;

    let tableData = data || [];
    if (viewer) {
      if (dataNode) {
        tableData = dataNode.edges.map(nodeResolver);
      }
    }

    const TableRender = tableRender;

    return (
      <>
        <Dialog
          open={settingsDlgOpen}
          onClose={() => this.setState({ settingsDlgOpen: false })}
          aria-labelledby="table-settings-dialog-title"
          aria-describedby="table-settings-dialog-description"
        >
          <TableFieldSelectorDlgForm
            onClose={() => this.setState({ settingsDlgOpen: false })}
            columns={columns}
            onChangeColumnOrder={newDisplayColumns => this.setState(
              { displayColumns: newDisplayColumns },
            )}
          />
        </Dialog>
        <TableRender
          columns={displayColumns}
          data={tableData}
          rowsPerPage={rowsPerPage}
          count={count}
          page={page}
          fetchSeq={fetchSeq}
          viewer={viewer}
          onChangeColumnOrder={() => {

          }}
          onOpenColumnSettings={() => this.setState({ settingsDlgOpen: true })}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
          onRowSelectInfo={onRowSelectInfo}
          onFetchData={this.onFetchData}
          isLoading={isLoading}
        />
      </>
    );
  };
}

PaginatedTable.propTypes = {
  tableRender: PropTypes.object,
  getDataNode: PropTypes.func.isRequired,
  getFetchParams: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRowSelectInfo: PropTypes.func,
  rowsPerPage: PropTypes.number,
  nodeResolver: PropTypes.func,
};

PaginatedTable.defaultProps = {
  tableRender: MUITableRenderer,
  getFetchParams: () => {},
  rowsPerPage: ROWS_PER_PAGE,
  nodeResolver: edge => edge.node,
  ...ReactTable.defaultProps,
};

export default PaginatedTable;
