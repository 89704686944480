import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function TopGridContainer(props) {
  const { children, justify } = props;
  return (
    <Grid container justify={justify} style={{ marginBottom: '10pt' }}>
      {children}
    </Grid>
  );
}

TopGridContainer.propTypes = {
  justify: PropTypes.string,
};

TopGridContainer.defaultProps = {
  justify: 'space-between',
};
