import { graphql } from 'react-relay';

// language=GraphQL
const createCashTransferRequestInverseBatch = graphql`
  mutation CreateCashTransferRequestInverseBatchMutation (
    $batchId: ID!
    $name: String
    $initiateCashTransaction: Boolean
    $contraBatch: ContraBatchInput
  ) {
    createCashTransferRequestInverseBatch(
      batchId: $batchId
      name: $name
      initiateCashTransaction: $initiateCashTransaction
      contraBatch: $contraBatch
    ) {
      batch {
        inverseBatch {
          id
          name
          cashtransferrequestSet {
            edges {
              node {
                id
              }
            }
          }
        }
        
        id
        name
        
        cashtransferrequestSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export default createCashTransferRequestInverseBatch;
