import { graphql } from 'react-relay';

const createApproval = graphql`
  mutation CreateApprovalMutation (
    $approvableId: ID!
  ) {
    createApproval (
      approvableId: $approvableId
    ) {
      errors {
        field
        messages
      }
      approval {
        id
        approvedBy {
          id
          username
        }
        approvedAt
      }
      hasCompleteApprovals
    }
  }
`;

export default createApproval;
