/**
 * @flow
 * @relayHash 9739d006dc02761ae3d9f9f75773957a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrderApprovalList_viewer$ref = any;
export type OrderApprovalListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  accountId?: ?string,
  statusIn?: ?$ReadOnlyArray<?string>,
  proposalId?: ?string,
  search?: ?string,
|};
export type OrderApprovalListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: OrderApprovalList_viewer$ref
  |}
|};
export type OrderApprovalListQuery = {|
  variables: OrderApprovalListQueryVariables,
  response: OrderApprovalListQueryResponse,
|};
*/


/*
query OrderApprovalListQuery(
  $first: Int
  $offset: Int
  $accountId: ID
  $statusIn: [String]
  $proposalId: ID
  $search: String
) {
  viewer {
    ...OrderApprovalList_viewer_PKNsY
    id
  }
}

fragment OrderApprovalList_viewer_PKNsY on Query {
  orderApprovals(first: $first, offset: $offset, orderBy: "-createdAt", account: $accountId, statusIn: $statusIn, proposal: $proposalId, search: $search) {
    edges {
      node {
        proposal {
          id
          type
        }
        id
        createdAt
        status
        portfolio {
          id
          trackingVueName
          account {
            name
            id
          }
        }
        portfolioOrder {
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "statusIn",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "proposalId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "Variable",
  "name": "statusIn",
  "variableName": "statusIn"
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderApprovalListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrderApprovalList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "proposalId",
                "variableName": "proposalId"
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderApprovalListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orderApprovals",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "account",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              {
                "kind": "Variable",
                "name": "proposal",
                "variableName": "proposalId"
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "concreteType": "OrderApprovalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderApprovalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderApprovalNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "proposal",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderProposalNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trackingVueName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "account",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolioOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioOrderNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrderApprovalListQuery",
    "id": null,
    "text": "query OrderApprovalListQuery(\n  $first: Int\n  $offset: Int\n  $accountId: ID\n  $statusIn: [String]\n  $proposalId: ID\n  $search: String\n) {\n  viewer {\n    ...OrderApprovalList_viewer_PKNsY\n    id\n  }\n}\n\nfragment OrderApprovalList_viewer_PKNsY on Query {\n  orderApprovals(first: $first, offset: $offset, orderBy: \"-createdAt\", account: $accountId, statusIn: $statusIn, proposal: $proposalId, search: $search) {\n    edges {\n      node {\n        proposal {\n          id\n          type\n        }\n        id\n        createdAt\n        status\n        portfolio {\n          id\n          trackingVueName\n          account {\n            name\n            id\n          }\n        }\n        portfolioOrder {\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e36dcf0ef527b3fb5616b99a5a3eed7';
module.exports = node;
