/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VueCommunicationList_viewer$ref: FragmentReference;
declare export opaque type VueCommunicationList_viewer$fragmentType: VueCommunicationList_viewer$ref;
export type VueCommunicationList_viewer = {|
  +vues: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +tenant: ?{|
          +id: string
        |},
        +id: string,
        +vuecommunicationSet: {|
          +count: ?number,
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +heading: string,
              +published: boolean,
              +publishedAt: ?any,
              +createdAt: any,
              +updatedAt: any,
              +message: ?string,
            |}
          |}>,
        |},
      |}
    |}>
  |},
  +$refType: VueCommunicationList_viewer$ref,
|};
export type VueCommunicationList_viewer$data = VueCommunicationList_viewer;
export type VueCommunicationList_viewer$key = {
  +$data?: VueCommunicationList_viewer$data,
  +$fragmentRefs: VueCommunicationList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "VueCommunicationList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "vueId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vues",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "vueId"
        }
      ],
      "concreteType": "VueNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tenant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ]
                },
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "vuecommunicationSet",
                  "storageKey": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "first",
                      "variableName": "first"
                    },
                    {
                      "kind": "Variable",
                      "name": "offset",
                      "variableName": "offset"
                    },
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": "-createdAt"
                    }
                  ],
                  "concreteType": "VueCommunicationNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "count",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "VueCommunicationNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "VueCommunicationNode",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "heading",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "published",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "publishedAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "createdAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "updatedAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "message",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5fc3761fc34166a542c3ac9fbba0c385';
module.exports = node;
