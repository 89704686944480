import React from 'react';
import './LBGIcon.css';
import { SvgIcon } from '@material-ui/core';

function LBGIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Layer_1" focusable="false">
        <path
          className="stll1"
          d="M22.9,12.5c.4.3.9.6.9,1.1s-.2.3-.6.3-.7,0-.9-.3c-.1-.2,0-.4,0-.6,0-.2-.3,0-.4-.2-.2,0-.2-.2-.3-.4-.1-.3-.5-.7-1-1.2-.4-.4-.8-.6-1-.6-.1,0-.2,0-.3,0-.3,0-.6.3-1.1.5-.3.2-.5.2-.8.3h0c0,0,.4,0,.4,0,.4,0,1,0,1.4,0s.6.4.8.8c.3.8.8,2,1.4,3,.3.5.5.8.5,1.3s0,.4-.3.4c-.3,0-1.2-.3-1.2-.7s.2-.4.2-.7-.3-.2-.3-.3c-.2-.9-.5-1.7-.9-2.3-.3-.3-.3-.4-.5-.4s-.3,0-.4,0c-.7.3-1.6,1-2.7,1.4-.4.1-.5-.1-.9,0h0c-.4,0-.6.3-1.1.4-.7.2-1.9.3-2.8.2-.7,0-1.5-.2-1.9-.2-.3.8-.9,1.9-1.8,2.3-.5.2-.6.5-.5,1,.2.7.8,1.2,1.1,1.7.3.5.8,1.3,1.2,1.5s.8.6.8.9,0,.2-.2.2h-1c-.2,0-.2,0-.3-.2-.1-.3,0-.5-.2-.7-.1-.2-.2-.1-.4-.3-.3-.3,0-.4-.3-.8-.6-1-.9-1.2-1.4-1.9-.3-.4-.7-.6-.7-1s.6-.7.6-1.3c-.4.3-.8.5-1.5.6,0,0-.3.2-.4.3-.2.2-.3.5-.4.8,0,.8-.2,1.6-.1,2.4.1.5.2.7.7,1,.3.2.6.4.6.8,0,0,0,.2-.2.2h-1.1c0,0-.2,0-.2-.1-.1-.2,0-.3,0-.6,0-.5-.7-.5-.7-1s0-.3,0-.7c0-.7.2-1.8,0-3.1,0-.8.7-.8,1.1-1.5.3-.4.2-.2.3-.5.2-.3.1-.5,0-.7,0-.2-.2-.7,0-1.7,0-.4.3-.9.6-1.2,0,0,0,0,0,0-.4,0-.4,0-.7,0s-.5.2-.5.3c-.1,0-.2-.2-.2-.3,0-.2,0-.3.2-.4,0,0,0,0,0,0-.3,0-.6.4-.7.7-.2,0-.2-.3-.3-.3s-.2.1-.2.3c0,.9,1.5,1.2,1.5,2.4s-1.7,1.1-1.7,1.7,0,.2.2.3c0,.1-.2.2-.4.2-.4,0-.7-.3-.7-.7,0-.8,1.1-.8,1.1-1.4s-.2-.4-.3-.5c-1-.7-1.6-1.4-1.6-2.6s.9-2.2,2-2.2c1.7,0,1.7,1.9,3.2,2.1.6-.4,1.2-.6,1.9-.6,1,0,1.4.3,2.5.3s1.1-.1,2-.7c.3-.2.4-.3.7-.5.7-.6,1.3-1.7,1.3-2.5h0c0-.2,0-.4-.2-.4-.3,0-.7.3-1,.7-.2.2.1.3-.4.6-.2,0-.4,0-.6,0-.4,0-.6-.5-.6-.9s.2-.3.3-.5c.2-.4.2-.6.4-.9.1-.3.3-.4.3-.6,0,0,0-.1,0-.2,0-.4.5-.7.5-1.1s-.3-.3-.3-.5,0-.2,0-.2c0,0,0,0,0,0,.2,0,.3.2.5.3,0-.3,0-.3,0-.5s0-.3,0-.3.1.2.2.3c.2.1.3,0,.4.2.2.1.3.4.4.4.2,0,.3-.2.2-.4.2,0,.5.2.8.2.5,0,.7.1.8.2.3.3.4.5.7.5s.3-.2.3-.2c.2.2.3.6,0,1,0,.3.5.3.8.5s.2.2.3.3c.1,0,.2,0,.3,0,0,.4-.3.8-.7.9,0,.5.6.9.8,1-.2.3-.4.4-.7.5,0,.3.4.8.3,1.2-.2,0-.4,0-.4.2s.3.4,0,.6c-.2,0-.2.3,0,.6.4,0,.8,0,1.2,0s.6,0,.9,0c.4,0,.7.2,1,.5.8.9,1.6,1.8,2.4,2.5"
        />
        <path
          className="stll0"
          d="M.7,10.1c.2,0,.5-1,1.2-1s.2,0,.2.2c0,.1-.2.3-.2.4s0,0,0,0c.2,0,.3-.4.9-.4s.3,0,.5,0c0,0,.2,0,.2-.1-.2-.5-.7-.9-1.4-.9s-1.5.6-1.5,1.4c0,.1,0,.2,0,.2M13.1,4c-.2,0-.2-.2-.2-.4,0-.3,0-.4,0-.4,0,0-.2,0-.4.2-.1.2-.3.4-.4.5,0,.2.2.2,0,.5-.3.6-.4.7-.5,1.1-.1.4-.2.5-.3.6,0,.1.1.1.3,0,.3,0,.3.2.2.4,0,.2,0,.2.2,0,.2-.2,0-.3,0-.8,0-.4.2-.7.4-1.1.2-.4.4-.6.8-.5.2,0,0-.2-.1-.3M21.4,16.7c0,0,0,0,0-.2,0-.2-.1-.5-.3-.7,0,.3-.2.5-.2.7s.3.2.4.2M23.3,13.7c.1,0,.3,0,.3-.1,0-.2-.3-.5-.5-.6.2.3,0,.7.2.7M15.1,9.6c1.7.8,1.6,2.2,1.7,1.8,0-.2.3-.9-.3-1.5-.5-.5-.6-.4-.5-.9.7-1.8,1.2-4.5-1-5.4-.3,0-.5,0-.5,0s.2.1.5.3c1.5,1,1,3.6-.3,4.9-.4.4-.2.5.3.8M9.3,22c.1,0,0-.2,0-.3,0-.2-.3-.3-.5-.4,0,.2,0,.6.2.6h.2ZM4.2,22c.1,0,0-.2,0-.3,0-.2-.3-.3-.5-.4,0,.2,0,.6.3.6h.3ZM10.4,11.8c.9,0,1.1-.3,1.2-1,0-.3.3-.7.5-.9.1-.2.3-.3.4-.5,0,0,0-.2,0-.1,0,0-.4.3-.6.4-.7.5-1.7.7-2,.7-.9,0-1.6-.3-2.4-.3-2.3,0-3.2,1.8-3.1,2.9,0,.2,0,.2,0,.2.2,0,.3-.6.9-1.2.9-.8,1.8-1.3,3.1-1.3s1.4.3,1.4.4c0,.2-.4.3-.4.5,0,0,.2.3.8.2"
        />
      </g>
    </SvgIcon>
  );
}

export default LBGIcon;
