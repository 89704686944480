import React from 'react';
import { graphql } from 'relay-runtime';
import { createRefetchContainer } from 'react-relay';
import { withRouter } from 'react-router-dom';
import ApprovalDrawerWidget from '../common/ApprovalDrawerWidget';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';

function PortfolioOrderRequestApprovalForm(props) {
  const { viewer, onCancelSuccess, onApproveSuccess } = props;
  const request = isRecurNull(viewer, ['portfolioOrderRequests', 'edges', 0, 'node']);

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading heading="Approve Order Request" />
      </DrawerGridItemContainer>
      <ApprovalDrawerWidget
        request={request}
        approvals={request.approvals}
        onCancelSuccess={onCancelSuccess}
        onApproveSuccess={onApproveSuccess}
      />
    </>
  );
}

const query = graphql`
  query PortfolioOrderRequestApprovalFormQuery(
    $requestId: ID
  ) {
    viewer {
      ...PortfolioOrderRequestApprovalForm_viewer
      @arguments(
        requestId: $requestId
      )
    }
  }
`;

const container = createRefetchContainer(
  PortfolioOrderRequestApprovalForm,
  {
    viewer: graphql`
      fragment PortfolioOrderRequestApprovalForm_viewer on Query
      @argumentDefinitions(
        requestId: {type: ID}
      ) {
        portfolioOrderRequests(
          id: $requestId
        ) {
          count
          edges {
            node {
              id
              status
              createdAt
              approvals {
                count
                edges {
                  node {
                    id
                    approvedBy {
                      id
                      username
                    }
                    approvedAt
                  }
                }
              }
              portfolioOrder {
                id
                account {
                  id
                  name
                }
                stockOrders {
                  edges {
                    node {
                      id
                      brokerOrder {
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ requestId }) => ({ requestId }),
));
