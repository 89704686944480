/**
 * @flow
 * @relayHash abfc9c5d9729c179a3a112666820532f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OffMarketTradeMutationVariables = {|
  accountId?: ?string,
  portfolioId?: ?string,
  contractId?: ?string,
  counterpartyAccountId?: ?string,
  counterpartyPortfolioId?: ?string,
  currency?: ?string,
  price?: ?number,
  quantity?: ?number,
|};
export type OffMarketTradeMutationResponse = {|
  +offMarketTrade: ?{|
    +account: ?{|
      +id: string
    |},
    +counterpartyAccount: ?{|
      +id: string
    |},
  |}
|};
export type OffMarketTradeMutation = {|
  variables: OffMarketTradeMutationVariables,
  response: OffMarketTradeMutationResponse,
|};
*/


/*
mutation OffMarketTradeMutation(
  $accountId: ID
  $portfolioId: ID
  $contractId: ID
  $counterpartyAccountId: ID
  $counterpartyPortfolioId: ID
  $currency: String
  $price: Float
  $quantity: Float
) {
  offMarketTrade(accountId: $accountId, portfolioId: $portfolioId, contractId: $contractId, counterpartyAccountId: $counterpartyAccountId, counterpartyPortfolioId: $counterpartyPortfolioId, currency: $currency, price: $price, quantity: $quantity) {
    account {
      id
    }
    counterpartyAccount {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "portfolioId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "counterpartyAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "counterpartyPortfolioId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "price",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "quantity",
    "type": "Float",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "id",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "offMarketTrade",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      },
      {
        "kind": "Variable",
        "name": "counterpartyAccountId",
        "variableName": "counterpartyAccountId"
      },
      {
        "kind": "Variable",
        "name": "counterpartyPortfolioId",
        "variableName": "counterpartyPortfolioId"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "portfolioId",
        "variableName": "portfolioId"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      },
      {
        "kind": "Variable",
        "name": "quantity",
        "variableName": "quantity"
      }
    ],
    "concreteType": "OffMarketTrade",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "account",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountNode",
        "plural": false,
        "selections": (v1/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "counterpartyAccount",
        "storageKey": null,
        "args": null,
        "concreteType": "AccountNode",
        "plural": false,
        "selections": (v1/*: any*/)
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketTradeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketTradeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OffMarketTradeMutation",
    "id": null,
    "text": "mutation OffMarketTradeMutation(\n  $accountId: ID\n  $portfolioId: ID\n  $contractId: ID\n  $counterpartyAccountId: ID\n  $counterpartyPortfolioId: ID\n  $currency: String\n  $price: Float\n  $quantity: Float\n) {\n  offMarketTrade(accountId: $accountId, portfolioId: $portfolioId, contractId: $contractId, counterpartyAccountId: $counterpartyAccountId, counterpartyPortfolioId: $counterpartyPortfolioId, currency: $currency, price: $price, quantity: $quantity) {\n    account {\n      id\n    }\n    counterpartyAccount {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58bf004425e734eae49426838f4daf7d';
module.exports = node;
