import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';
import DrawerGridItemLoaderField from '../common/drawer/DrawerGridItemLoaderField';
import PortfolioLoader from '../common/fieldLoaders/PortfolioLoader';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { offMarketHoldingTransferMutation } from '../../mutations/OffMarket';
import { doCommitPromise } from '../../common/commit';
import { TransferButton } from '../common/buttons/BaseButton';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import notiStack from '../../common/notiStack';


function OffMarketTransferForm(props) {
  const classes = useCommonStyles();
  const {
    onClose,
    holding,
    accountId,
    onSuccess,
  } = props;
  const initQty = holding ? holding.quantity : 0;
  const [counterpartyAccount, setCounterpartyAccount] = React.useState(null);
  const [counterpartyPortfolio, setCounterpartyPortfolio] = React.useState(null);
  const [ucVar] = React.useState({
    quantity: initQty,
  });

  const doUpdatePromise = () => {
    if (!counterpartyPortfolio) {
      notiStack.error('Please select a counterparty portfolio');
      return null;
    }

    return doCommitPromise(
      offMarketHoldingTransferMutation,
      {
        accountId,
        portfolioId: holding.portfolio.id,
        contractId: holding.contract.id,
        counterpartyAccountId: counterpartyAccount.id,
        counterpartyPortfolioId: counterpartyPortfolio.id,
        quantity: Number(ucVar.quantity),
      },
      () => (onSuccess && onSuccess()),
      () => (onClose && onClose()),
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading="Off Market Transfer"
        />

        <DrawerGridItemNumberField
          label="Quantity"
          defaultValue={ucVar.quantity}
          onChange={(e) => {
            ucVar.quantity = e.target.value;
          }}
        />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            caption="Counter Party Account"
            onSelect={a => setCounterpartyAccount(a)}
          />
        </Grid>

        {counterpartyAccount && (
          <DrawerGridItemLoaderField
            sm={12}
            label="Counter Party Portfolio"
            loader={PortfolioLoader}
            loaderProps={{ account: counterpartyAccount }}
            onChange={p => setCounterpartyPortfolio(p)}
          />
        )}

        <Grid item xs={12}>
          <BottomGridContainer>
            <TransferButton
              onClickPromise={() => doUpdatePromise()}
              buttonText="Transfer"
            />
          </BottomGridContainer>
        </Grid>

      </Grid>
    </div>
  );
}

export default OffMarketTransferForm;
