import * as React from 'react';
import { Grid } from '@material-ui/core';
import { useCommonStyles } from '../../common/Styles';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import UserRoleLoader from '../../common/fieldLoaders/UserRoleLoader';
import createUserProvisionRequestMutation from '../../../mutations/CreateUserProvisionRequestMutation';
import notiStack from '../../../common/notiStack';

function AddUserFrom(props) {
  const { clientAccount, onClose } = props;
  const classes = useCommonStyles();
  const [ucVar, setUcVar] = React.useState({
    email: null,
    firstName: null,
    lastName: null,
    userRoleIds: [],
  });

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add User"
        />
        <DrawerGridItemTextField
          xs={12}
          label="Email"
          onChange={e => setUcVar({ ...ucVar, email: e.target.value })}
        />
        <DrawerGridItemTextField
          label="First Name"
          onChange={e => setUcVar({ ...ucVar, firstName: e.target.value })}
        />
        <DrawerGridItemTextField
          label="Last Name"
          onChange={e => setUcVar({ ...ucVar, lastName: e.target.value })}
        />

        <Grid item xs={12} sm={12}>
          <UserRoleLoader
            multiple
            className={classes.fromControlDrawer}
            label="Roles"
            onChange={(userRoles) => {
              const userRoleIds = userRoles.map(role => role.id);
              setUcVar({ ...ucVar, userRoleIds });
            }}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                createUserProvisionRequestMutation,
                {
                  ...ucVar,
                  clientAccountId: clientAccount.id,
                },
                () => {
                  notiStack.success('Request to provision new user created.');
                  if (onClose) onClose();
                },
                () => null,
                null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}

export default AddUserFrom;
