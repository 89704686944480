import * as React from 'react';
import EntitySelectionDlg from './EntitySelectionDlg';
import AccountSearch from './AccountSearch';

function AccountSelectionDlg(props) {
  const {
    children,
    ...otherProps
  } = props;
  return (
    <EntitySelectionDlg
      {...otherProps}
      searchComp={AccountSearch}
      dialogTitle="Select Account"
    />
  );
}

AccountSelectionDlg.propTypes = {
  ...EntitySelectionDlg.propTypes,
};

AccountSelectionDlg.defaultProps = {
  ...EntitySelectionDlg.defaultProps,
};

export default AccountSelectionDlg;
