/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotesDrawer_viewer$ref: FragmentReference;
declare export opaque type NotesDrawer_viewer$fragmentType: NotesDrawer_viewer$ref;
export type NotesDrawer_viewer = {|
  +notes: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +noteentrySet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +createdAt: any,
              +updatedAt: any,
              +createdBy: ?{|
                +id: string,
                +fullName: ?string,
              |},
              +id: string,
              +text: string,
              +noteentryattachmentSet: {|
                +edges: $ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +file: string,
                    +fileUrl: ?string,
                    +name: string,
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +$refType: NotesDrawer_viewer$ref,
|};
export type NotesDrawer_viewer$data = NotesDrawer_viewer;
export type NotesDrawer_viewer$key = {
  +$data?: NotesDrawer_viewer$data,
  +$fragmentRefs: NotesDrawer_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "NotesDrawer_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "notesId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "notes",
      "storageKey": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "notesId"
        }
      ],
      "concreteType": "NotesNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "NotesNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "NotesNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "noteentrySet",
                  "storageKey": "noteentrySet(orderBy:\"-updatedAt\")",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": "-updatedAt"
                    }
                  ],
                  "concreteType": "NoteEntryNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "NoteEntryNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "NoteEntryNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "createdAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "updatedAt",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "createdBy",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "UserNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "fullName",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            },
                            (v0/*: any*/),
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "text",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "noteentryattachmentSet",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "NoteEntryAttachmentNodeConnection",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "edges",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "NoteEntryAttachmentNodeEdge",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "node",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "NoteEntryAttachmentNode",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "file",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "fileUrl",
                                          "args": null,
                                          "storageKey": null
                                        },
                                        {
                                          "kind": "ScalarField",
                                          "alias": null,
                                          "name": "name",
                                          "args": null,
                                          "storageKey": null
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '20c95b08365ef888ae6999ef7c1a96d8';
module.exports = node;
