/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VueResearchList_viewer$ref: FragmentReference;
declare export opaque type VueResearchList_viewer$fragmentType: VueResearchList_viewer$ref;
export type VueResearchList_viewer = {|
  +vues: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +vueresearchSet: {|
          +count: ?number,
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +title: string,
              +researchPdfUrl: ?string,
              +createdAt: any,
              +updatedAt: any,
              +createdBy: ?{|
                +id: string,
                +username: string,
              |},
            |}
          |}>,
        |},
        +latestDraft: ?{|
          +id: string,
          +vueresearchSet: {|
            +count: ?number,
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +title: string,
                +researchPdfUrl: ?string,
                +createdAt: any,
                +updatedAt: any,
                +createdBy: ?{|
                  +id: string,
                  +username: string,
                |},
              |}
            |}>,
          |},
        |},
      |}
    |}>
  |},
  +$refType: VueResearchList_viewer$ref,
|};
export type VueResearchList_viewer$data = VueResearchList_viewer;
export type VueResearchList_viewer$key = {
  +$data?: VueResearchList_viewer$data,
  +$fragmentRefs: VueResearchList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vueresearchSet",
  "storageKey": "vueresearchSet(orderBy:\"-createdAt\")",
  "args": [
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "-createdAt"
    }
  ],
  "concreteType": "VueResearchNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "VueResearchNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "VueResearchNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "title",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "researchPdfUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "createdBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "username",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "VueResearchList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "vueId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vues",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "vueId"
        }
      ],
      "concreteType": "VueNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "latestDraft",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "VueNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '61f7b235029b7a9fb0b853e77b708709';
module.exports = node;
