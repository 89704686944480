import React from 'react';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StatelessDownloadButton from '../common/buttons/StatelessDownloadButton';
import { useCommonStyles } from '../common/Styles';
import { fromGlobalId } from '../../helpers/ID';
import { UpdateButton } from '../common/buttons/BaseButton';
import invalidateTradeSummaries from '../../mutations/InvalidateTradeSummaries';
import { doCommitPromise } from '../../common/commit';


function AccountTradeSummaryExportPanel(props) {
  const { accountId } = props;
  const classes = useCommonStyles();

  const baseUrl = `${process.env.REACT_APP_API}/api/admin/exports/trade-summary?accountId=${fromGlobalId(accountId)[1]}`;
  const closedTradeSummaryUrl = `${baseUrl}&openPositions=false`;
  const openTradeSummaryUrl = `${baseUrl}&openPositions=true`;

  return (
    <Paper>
      <div className={classes.tableHolderRoot}>
        <div className={classes.widgetHeadingContainer}>
          <Typography
            component="h6"
            variant="subtitle2"
            gutterBottom
          >
            Trade Summaries
          </Typography>
        </div>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <UpdateButton
              buttonText="Regenerate"
              onClickPromise={() => doCommitPromise(
                invalidateTradeSummaries,
                { accountId, porfolioId: null, regenerate: true },
                () => null,
                () => null,
              )}
            />
          </Grid>
          <Grid item>
            <StatelessDownloadButton
              downloadUrl={openTradeSummaryUrl}
              buttonText="Export Open"
              buttonType="export"
            />
          </Grid>
          <Grid item>
            <StatelessDownloadButton
              downloadUrl={closedTradeSummaryUrl}
              buttonText="Export Closed"
              buttonType="export"
            />
          </Grid>
          <Grid item>
            <StatelessDownloadButton
              downloadUrl={baseUrl}
              buttonText="Export All"
              buttonType="export"
            />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default AccountTradeSummaryExportPanel;
