/**
 * @flow
 * @relayHash e74bc6c15e61f4915d2069ff9a6a79a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddVueCommunicationMutationVariables = {|
  vueId: string,
  heading: string,
  message: string,
  first: number,
|};
export type AddVueCommunicationMutationResponse = {|
  +addVueCommunication: ?{|
    +vue: ?{|
      +vuecommunicationSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +heading: string,
            +message: ?string,
            +published: boolean,
            +publishedAt: ?any,
          |}
        |}>
      |}
    |}
  |}
|};
export type AddVueCommunicationMutation = {|
  variables: AddVueCommunicationMutationVariables,
  response: AddVueCommunicationMutationResponse,
|};
*/


/*
mutation AddVueCommunicationMutation(
  $vueId: ID!
  $heading: String!
  $message: String!
  $first: Int!
) {
  addVueCommunication(vueId: $vueId, heading: $heading, message: $message) {
    vue {
      vuecommunicationSet(orderBy: "-createdAt", first: $first) {
        edges {
          node {
            id
            heading
            message
            published
            publishedAt
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "heading",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "message",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "heading",
    "variableName": "heading"
  },
  {
    "kind": "Variable",
    "name": "message",
    "variableName": "message"
  },
  {
    "kind": "Variable",
    "name": "vueId",
    "variableName": "vueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vuecommunicationSet",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "-createdAt"
    }
  ],
  "concreteType": "VueCommunicationNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "VueCommunicationNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "VueCommunicationNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "heading",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "message",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "published",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "publishedAt",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddVueCommunicationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addVueCommunication",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVueCommunication",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vue",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddVueCommunicationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addVueCommunication",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVueCommunication",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vue",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddVueCommunicationMutation",
    "id": null,
    "text": "mutation AddVueCommunicationMutation(\n  $vueId: ID!\n  $heading: String!\n  $message: String!\n  $first: Int!\n) {\n  addVueCommunication(vueId: $vueId, heading: $heading, message: $message) {\n    vue {\n      vuecommunicationSet(orderBy: \"-createdAt\", first: $first) {\n        edges {\n          node {\n            id\n            heading\n            message\n            published\n            publishedAt\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '53a0fba21b4fa418e27bf9683b1d827e';
module.exports = node;
