import React, { useState } from 'react';
import { commitMutation, createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { Drawer } from '@material-ui/core';
import PaginatedTable from '../common/PaginatedTable';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableButton from '../common/buttons/TableButton';
import environment from '../../Environment';
import { useCommonStyles } from '../common/Styles';
import MultiCurrencyValue from '../common/MultiCurrencyValue';
import generateRangeReconciliation from '../../mutations/GenerateRangeReconciliation';
import AddButton from '../common/buttons/AddButton';
import AddReconciliationForm from './AddReconciliationForm';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';


function RangeReconcileList(props) {
  const [showAddRangeRec, setShowAddRangeRec] = useState(false);
  const [tableRef] = useState(React.createRef());
  const classes = useCommonStyles();
  const { viewer, relay } = props;
  const renderEndBalanceDiff = (node) => {
    const { ibrangereconciliationendbalanceSet } = node;
    if (!ibrangereconciliationendbalanceSet) {
      return <React.Fragment />;
    }
    const key = `${node.id}-${node.updatedAt}`;
    return (
      <MultiCurrencyValue
        decimalPlaces={2}
        key={key}
        valueNode={ibrangereconciliationendbalanceSet}
      />
    );
  };

  const renderDeltaDiff = (node) => {
    const { ibrangereconciliationdeltaSet } = node;
    if (!ibrangereconciliationdeltaSet) {
      return <React.Fragment />;
    }
    const key = `${node.id}-${node.updatedAt}`;
    return (
      <MultiCurrencyValue
        decimalPlaces={2}
        key={key}
        valueNode={ibrangereconciliationdeltaSet}
      />
    );
  };

  const viewReconciledOutput = node => () => {
    window.open(node.excelFileUrl, '_blank');
  };

  const generateReconciledOutput = node => () => new Promise((resolve, reject) => {
    const variables = {
      rangeReconciliationId: node.id,
    };

    commitMutation(environment, {
      mutation: generateRangeReconciliation,
      variables,
      onCompleted: (response) => {
        resolve(response);
      },
      onError: (error) => {
        reject(error);
      },
    });
  });

  const onAddRec = () => {
    tableRef.current.refreshData();
  };

  const renderActions = (node) => {
    const hasReconcileOutput = node.excelFileUrl;
    const outputKey = `${node.id}-${node.updatedAt}`;

    return (
      <Grid container spacing={1} justify="center">
        { hasReconcileOutput && (
          <Grid item>
            <TableButton
              key={outputKey}
              onClick={viewReconciledOutput(node)}
            >
              View
            </TableButton>
          </Grid>)
        }
        { hasReconcileOutput && (
          <Grid item>
            <TableButton
              color="primary"
              key={outputKey}
              onClickPromise={generateReconciledOutput(node)}
            >
              Re-Generate
            </TableButton>
          </Grid>)
        }
        {
          !hasReconcileOutput && (
            <Grid item>
              <TableButton
                color="primary"
                key={outputKey}
                onClickPromise={generateReconciledOutput(node)}
              >
                Generate
              </TableButton>
            </Grid>
          )
        }
      </Grid>

    );
  };
  const columns = [
    TableFieldMeta.dateField('fromDate', 'From Date'),
    TableFieldMeta.dateField('toDate', 'To Date'),
    TableFieldMeta.field('noOfDays', 'No of Days'),
    TableFieldMeta.createdAt,
    {
      id: 'action',
      Header: 'Actions',
      accessor: node => node,
      Cell: row => renderActions(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'endBalanceDelta',
      Header: 'Gap (End Balance)',
      accessor: node => node,
      Cell: row => renderEndBalanceDiff(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
    {
      id: 'deltaDelta',
      Header: 'Gap (Delta)',
      accessor: node => node,
      Cell: row => renderDeltaDiff(row.value),
      ...TableFieldMeta.centerAlignStyle,
    },
  ];

  const getDataNode = () => (viewer ? viewer.ibRangeReconciliations : null);
  const getFetchParams = () => {};

  if (!viewer) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
      <div className={classes.innerTabRoot}>
        <PaginatedTable
          ref={tableRef}
          columns={columns}
          viewer={viewer}
          getDataNode={getDataNode}
          getFetchParams={getFetchParams}
          relay={relay}
          loadingEnums={[
            'sideEnum',
            'orderTypeEnum',
            'orderStatusEnum',
            'portfolioOrderStatusEnum',
          ]}
        />
      </div>

      <div className={classes.bottomButtonContainer}>
        <AddButton
          buttonText="Add Reconciliation"
          onClick={() => setShowAddRangeRec(true)}
        />
      </div>

      <Drawer
        anchor="right"
        open={showAddRangeRec}
        onClose={() => setShowAddRangeRec(false)}
      >
        <AddReconciliationForm
          onClose={() => setShowAddRangeRec(false)}
          onAddRec={onAddRec}
        />
      </Drawer>

    </React.Fragment>
  );
}

const query = graphql`
  query RangeReconcileListQuery (
    $first: Int,
    $offset: Int,
  ) {
    viewer {
      ...RangeReconcileList_viewer
      @arguments (
        first: $first,
        offset: $offset,
      )
    }
  }
`;

const container = createRefetchContainer(
  RangeReconcileList,
  {
    viewer: graphql`
      fragment RangeReconcileList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
      ) {
        ibRangeReconciliations(
          first: $first,
          offset: $offset,
        ) {
          count
          edges {
            node {
              id
              createdAt
              updatedAt
              excelFile
              excelFileName
              excelFileUrl
              fromDate
              toDate
              noOfDays
              ibrangereconciliationendbalanceSet {
                edges {
                  node {
                    id
                    createdAt
                    updatedAt
                    currency
                    quantity
                  }
                }
              }
              
              ibrangereconciliationdeltaSet {
                edges {
                  node {
                    id
                    createdAt
                    updatedAt
                    currency
                    quantity
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, outProps) => ({}),
  props => ({ }),
);
