import { graphql } from 'react-relay';

const updateCannedSecurityQuestion = graphql`
  mutation UpdateCannedSecurityQuestionMutation (
    $cannedSecurityQuestionId: ID!
    $enabled: Boolean!
  ) {
    updateCannedSecurityQuestion(
      cannedSecurityQuestionId: $cannedSecurityQuestionId
      enabled: $enabled
    ) {
      cannedSecurityQuestion {
        id
        question
        enabled
      }
    }
  }
`;

export default updateCannedSecurityQuestion;
