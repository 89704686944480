import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import { Grid } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { commonStyles } from '../Styles';
import TablePaginationActions from '../pagination/TablePaginationActions';
import { renderMUITableHeaders, renderMUITableRows } from './MUITable';

class MUITableRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKey: null,
    };
  }

  renderLoadingLine = () => {
    const { columns, isLoading } = this.props;
    const defaultPaddingRemoval = {
      paddingTop: '0px',
      paddingBottom: '0px',
    };
    return (
      <tr style={{ ...defaultPaddingRemoval, visibility: isLoading ? 'inherit' : 'hidden' }}>
        <td style={defaultPaddingRemoval} colSpan={columns ? columns.length : 1}>
          <LinearProgress />
        </td>
      </tr>
    );
  };

  handleChangeRowsPerPage = (event) => {
    const { onChangeRowsPerPage } = this.props;
    onChangeRowsPerPage(event.target.value);
  };

  handlePageChange = (event, page) => {
    const { onChangePage } = this.props;
    onChangePage(page);
  };

  handleSelect = (dataRow, selectInfo) => () => {
    const { selectedRowKey } = this.state;
    const newSelectedRowKey = selectedRowKey === selectInfo.rowKey ? null : selectInfo.rowKey;

    this.setState({
      selectedRowKey: newSelectedRowKey,
    });

    if (selectInfo.callback) {
      const isSelected = newSelectedRowKey === selectInfo.rowKey;
      selectInfo.callback(dataRow, isSelected, newSelectedRowKey);
    }
  };

  render = () => {
    const {
      data,
      rowsPerPage,
      columns,
      count,
      page,
      classes,
      onOpenColumnSettings,
      onRowSelectInfo,
      title,
    } = this.props;

    const maxPages = Math.max(rowsPerPage, data.length);
    const emptyRows = Array(maxPages - data.length).fill(0);
    emptyRows.map(
      (rowVal, i) => {
        emptyRows[i] = i;
        return i;
      },
    );

    const { selectedRowKey } = this.state;

    return (
      <div key="paginatedTableHolderRoot">
        <Paper style={{ overflow: 'auto' }}>
          <div className={classes.paginatedTableHolderRoot} key="paginatedTableHolderInnerRoot">
            {title && (
              <div className={classes.widgetHeadingContainer}>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  gutterBottom
                >
                  {title}
                </Typography>
              </div>
            )}
            <Table size="small">
              <TableHead>
                {renderMUITableHeaders(columns)}
                {this.renderLoadingLine()}
              </TableHead>
              <TableBody>
                {renderMUITableRows(data, columns, {
                  onRowSelectInfo,
                  selectedRowKey,
                  handleSelect: this.handleSelect,
                })}
                {
                  emptyRows.map(
                    (dataRow, idx) => (
                      <TableRow key={`${dataRow}`}>
                        {
                          columns.map(
                            columnMeta => (
                              <TableCell key={`${columnMeta.id}-${dataRow}`}>
                                &nbsp;
                              </TableCell>
                            ),
                          )
                        }
                      </TableRow>
                    ),
                  )
                }

                {this.renderLoadingLine()}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <Grid container>
                    <Grid item>
                      <IconButton
                        aria-label="First Page"
                        onClick={() => (onOpenColumnSettings && onOpenColumnSettings())}
                      >
                        <MoreVert />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <TablePagination
                    rowsPerPageOptions={TablePaginationActions.ROW_COUNT_OPTIONS}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </div>
    );
  };
}

export default withStyles(commonStyles)(MUITableRenderer);
