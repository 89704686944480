import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const loaderQuery = graphql`
  query SubscriptionTemplateLoaderQuery (
    $first: Int,
    $offset: Int
  ) {
    viewer {
      ...SubscriptionTemplateLoaderFragment_viewer
      @arguments(
        first: $first,
        offset: $offset,
      )
    }
  }
`;

const refetchContainer = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment SubscriptionTemplateLoaderFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
      ) {
        subscriptionTemplates (
          first: $first
          offset: $offset
        ) {
          edges {
            node {
              id
              code
              name
            }
          }
        }
      }
    `,
  },
  loaderQuery,
);

const getOptionLabel = option => (`${option.name}`);
const nodeCollector = viewer => viewer.subscriptionTemplates.edges.map(edge => edge.node);

export default function SubscriptionTemplateLoader(props) {
  return (
    <FieldLoader
      loaderQuery={loaderQuery}
      refetchContainer={refetchContainer}
      getOptionLabel={getOptionLabel}
      nodeCollector={nodeCollector}
      {...props}
    />
  );
}
