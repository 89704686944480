/**
 * @flow
 * @relayHash 001688b6c8b71fccaf7eac0e8443a02a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ExchangeSession_viewer$ref = any;
export type ExchangeSessionQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  exchange?: ?string,
|};
export type ExchangeSessionQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ExchangeSession_viewer$ref
  |}
|};
export type ExchangeSessionQuery = {|
  variables: ExchangeSessionQueryVariables,
  response: ExchangeSessionQueryResponse,
|};
*/


/*
query ExchangeSessionQuery(
  $first: Int
  $offset: Int
  $exchange: String
) {
  viewer {
    ...ExchangeSession_viewer_1dXu8O
    id
  }
}

fragment ExchangeSession_viewer_1dXu8O on Query {
  exchangeSessions(first: $first, offset: $offset, exchange: $exchange) {
    edges {
      exchangeSession {
        id
        marketOpen
        marketClose
        exchange {
          symbol
          name
          id
        }
      }
      all
      cancelled
      rejected
      created
      partiallyFilled
      pendingExecution
      inMarket
      queued
      posted
      filled
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "exchange",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "exchange",
    "variableName": "exchange"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ExchangeSessionQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ExchangeSession_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ExchangeSessionQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "exchangeSessions",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ExchangeSessionInfoNode",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ExchangeSessionInfo",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "exchangeSession",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ExchangeSessionNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "marketOpen",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "marketClose",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "exchange",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ExchangeNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "symbol",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "all",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cancelled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "rejected",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "created",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "partiallyFilled",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "pendingExecution",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "inMarket",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "queued",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "posted",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "filled",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ExchangeSessionQuery",
    "id": null,
    "text": "query ExchangeSessionQuery(\n  $first: Int\n  $offset: Int\n  $exchange: String\n) {\n  viewer {\n    ...ExchangeSession_viewer_1dXu8O\n    id\n  }\n}\n\nfragment ExchangeSession_viewer_1dXu8O on Query {\n  exchangeSessions(first: $first, offset: $offset, exchange: $exchange) {\n    edges {\n      exchangeSession {\n        id\n        marketOpen\n        marketClose\n        exchange {\n          symbol\n          name\n          id\n        }\n      }\n      all\n      cancelled\n      rejected\n      created\n      partiallyFilled\n      pendingExecution\n      inMarket\n      queued\n      posted\n      filled\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca1de0eda24b73243b20c1164967133a';
module.exports = node;
