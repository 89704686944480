import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterGridItemMultiTextField
  from '../common/innerTab/InnerTabFilterGridItemMultiTextField';
import UserLink from '../common/links/UserLink';

function ActionLogList(props) {
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);
  const [actionTypeIn, setActionTypeIn] = React.useState(filter.params.actionTypeIn);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.dateTimeField('actionTime', 'Time'),
    TableFieldMeta.field('actionId', 'Action ID'),
    TableFieldMeta.enum('ActionType', 'Type', node => node.actionType),
    TableFieldMeta.field('actionDescription', 'Action Description'),
    TableFieldMeta.field('ipAddress', 'IP'),
    TableFieldMeta.field((node) => {
      let userAgent = node.userAgent ? node.userAgent : '';
      if (userAgent.length > 60) {
        userAgent = `${userAgent.substring(0, 60)}...`;
      }
      return userAgent;
    }, 'User Agent'),
    TableFieldMeta.field(node => <UserLink user={node.user} />, 'User'),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>

        <InnerTabFilterSelectField
          enumType="ActionType"
          helperText="Action Type"
          multiple
          withEmpty={false}
          value={actionTypeIn || []}
          onChange={(e) => {
            setActionTypeIn(e.target.value);
            filter.updateVar('actionTypeIn', e.target.value);
          }}
        />

        <InnerTabFilterGridItemMultiTextField
          id="action-id-filter"
          label="Action ID"
          size="small"
          type="number"
          defaultValue={filter.params.actionIdIn || null}
          onChange={(e, value) => {
            const numTypedArray = value.map(element => Number(element));
            filter.updateVar('actionIdIn', numTypedArray);
          }}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.actionLogUserRequests : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query ActionLogListQuery (
    $first: Int,
    $offset: Int
    $userId: ID
    $forUserId: ID
    $actionTypeIn: [String]
    $actionIdIn: [Int]
  ) {
    viewer {
      ...ActionLogList_viewer
      @arguments (
        first: $first
        offset: $offset
        userId: $userId
        forUserId: $forUserId
        actionTypeIn: $actionTypeIn
        actionIdIn: $actionIdIn
      )
    }
  }
`;

const container = createRefetchContainer(
  ActionLogList,
  {
    viewer: graphql`
      fragment ActionLogList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        userId: {type: ID}
        forUserId: {type: ID}
        actionTypeIn: {type: "[String]"}
        actionIdIn: {type: "[Int]"}
      ) {
        actionLogUserRequests (
          first: $first
          offset: $offset
          actionType_In: $actionTypeIn
          orderBy: "-actionTime"
          userId: $userId
          forUserId: $forUserId
          actionId_In: $actionIdIn
        ) {
          edges {
            node {
              id
              actionTime
              actionType
              actionId
              actionDescription
              ipAddress
              userAgent
              user {
                id
                fullName
                email
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ userId, forUserId }) => ({
    userId,
    forUserId,
    actionTypeIn: [],
    actionIdIn: [],
  }),
));
