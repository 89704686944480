import React from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import { UploadButton } from '../common/buttons/BaseButton';
import TableFieldMeta from '../../common/TableFieldMeta';
import CustomReportUploadForm from './CustomReportUploadForm';
import TableButton from '../common/buttons/TableButton';
import { doCommitPromise } from '../../common/commit';
import { deleteReportMutation } from '../../mutations/Report';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';


function ReportLinks(props) {
  const { node: report } = props;
  if (report.pdfUrl && report.xlsxUrl) {
    return (
      <React.Fragment>
        <Link key={`pdf-${report.updatedAt}`} href={report.pdfUrl}>PDF</Link>
        { ' , '}
        <Link key={`xlsx-${report.updatedAt}`} href={report.xlsxUrl}>Excel</Link>
      </React.Fragment>
    );
  }
  if (report.pdfUrl) {
    return <Link key={`pdf-${report.updatedAt}`} href={report.pdfUrl}>PDF</Link>;
  }
  if (report.xlsxUrl) {
    return <Link key={`xlsx-${report.updatedAt}`} href={report.xlsxUrl}>Excel</Link>;
  }

  return <React.Fragment />;
}

function ReportList(props) {
  const {
    clientAccountId,
    viewer,
    relay,
    filter,
    optColumns,
    showUpload,
    freezeTrigger,
  } = props;
  filter.setRelay(relay);
  const [customReportDrawerOpen, setCustomReportDrawerOpen] = React.useState(false);
  const [reportTypeIn, setReportTypeIn] = React.useState(filter.params.reportTypeIn);
  const [triggerIn, setTriggerIn] = React.useState(filter.params.triggerIn);
  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    ...optColumns,
    TableFieldMeta.updatedAt,
    TableFieldMeta.enum('ReportType', 'Report Type', node => node.reportType),
    TableFieldMeta.enum('ReportTrigger', 'Trigger', node => node.trigger),
    {
      Header: '',
      id: 'links',
      accessor: node => node,
      Cell: row => <ReportLinks node={row.value} />,
    },
    {
      Header: '',
      id: 'delete',
      accessor: node => node,
      Cell: row => (
        <TableButton
          color="secondary"
          onClickPromise={() => doCommitPromise(
            deleteReportMutation,
            {
              reportId: row.value.id,
            },
            () => filter.update(),
          )}
        >
          Delete
        </TableButton>
      ),
    },
    TableFieldMeta.account(
      node => node.clientAccount && node.clientAccount.accountPtr,
      'Account',
      { charLimit: 40 },
    ),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Grid container spacing={1} justify="space-between" style={{ marginBottom: '10pt' }}>
        <Grid item>
          <BaseGridContainer>
            <InnerTabFilterSelectField
              enumType="ReportType"
              helperText="Report Type In"
              multiple
              withEmpty={false}
              value={reportTypeIn}
              onChange={(e) => {
                setReportTypeIn(e.target.value);
                filter.updateVar('reportTypeIn', e.target.value);
              }}
            />
            <InnerTabFilterSelectField
              enumType="ReportTrigger"
              helperText="ReportTrigger-In"
              multiple
              disabled={freezeTrigger}
              withEmpty={false}
              value={triggerIn}
              onChange={(e) => {
                setTriggerIn(e.target.value);
                filter.updateVar('triggerIn', e.target.value);
              }}
            />

          </BaseGridContainer>
        </Grid>
        <Grid item>
          {showUpload && (
          <UploadButton
            buttonText="Upload"
            onClick={() => setCustomReportDrawerOpen(true)}
          />
          )}
        </Grid>
      </Grid>
      <Paper>
        <TablePaginator
          columns={columns}
          filter={filter}
          getDataNode={() => (viewer ? viewer.reports : null)}
        />
      </Paper>
      <Drawer
        anchor="right"
        open={customReportDrawerOpen}
        onClose={() => setCustomReportDrawerOpen(false)}
      >
        <CustomReportUploadForm
          clientAccountId={clientAccountId}
          onClose={() => setCustomReportDrawerOpen(false)}
          onAdd={() => filter.update()}
        />
      </Drawer>
    </div>
  );
}

const query = graphql`
  query ReportListQuery (
    $clientAccountId: ID,
    $first: Int,
    $offset: Int,
    $triggerIn: [String]
    $reportTypeIn: [String]
  ) {
    viewer {
      ...ReportList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        clientAccountId: $clientAccountId,
        triggerIn: $triggerIn
        reportTypeIn: $reportTypeIn
      )
    }
  }
`;

const container = createRefetchContainer(
  ReportList,
  {
    viewer: graphql`
      fragment ReportList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        clientAccountId: {type: ID, defaultValue: null }
        triggerIn: {type: "[String]", defaultValue: null} 
        reportTypeIn: {type: "[String]", defaultValue: null} 
      ) {
        reports (
          first: $first
          offset: $offset
          clientAccountId: $clientAccountId
          orderBy: "-createdAt"
          triggerIn: $triggerIn
          reportTypeIn: $reportTypeIn
        ) {
          edges {
            node {
              ...Report_all @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

ReportList.propTypes = {
  optColumns: PropTypes.array,
  showUpload: PropTypes.bool,
  freezeTrigger: PropTypes.bool,
};

ReportList.defaultProps = {
  optColumns: [],
  showUpload: false,
  freezeTrigger: false,
};

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  props => ({
    clientAccountId: props.clientAccountId,
    triggerIn: props.triggerIn || [],
    reportTypeIn: [],
  }),
));

export { ReportLinks };
