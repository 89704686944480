/**
 * @flow
 * @relayHash 914b11611572b1d7a73877c0364b96b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActionLogs_cancelUpdate$ref = any;
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type CancelBrokerOrderConfirmMutationVariables = {|
  brokerOrderId?: ?string
|};
export type CancelBrokerOrderConfirmMutationResponse = {|
  +trademanConfirmOrderCancel: ?{|
    +brokerOrder: ?{|
      +id: string,
      +status: OrderStatus,
      +updatedAt: any,
      +orderSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +stockorder: ?{|
              +id: string,
              +$fragmentRefs: ActionLogs_cancelUpdate$ref,
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type CancelBrokerOrderConfirmMutation = {|
  variables: CancelBrokerOrderConfirmMutationVariables,
  response: CancelBrokerOrderConfirmMutationResponse,
|};
*/


/*
mutation CancelBrokerOrderConfirmMutation(
  $brokerOrderId: ID
) {
  trademanConfirmOrderCancel(brokerOrderId: $brokerOrderId) {
    brokerOrder {
      id
      status
      updatedAt
      orderSet {
        edges {
          node {
            id
            stockorder {
              id
              ...ActionLogs_cancelUpdate
            }
          }
        }
      }
    }
  }
}

fragment ActionLogs_cancelUpdate on StockOrderNode {
  ...ActionLogs_all
  blockedBaseCharges
  blockedHoldings
  blockedProceeds
  blockedLocalCharges
}

fragment ActionLogs_all on StockOrderNode {
  actionLogs(orderBy: "actionTime") {
    edges {
      node {
        actionId
        actionType
        actionTime
        actionDescription
        user {
          fullName
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerOrderId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerOrderId",
    "variableName": "brokerOrderId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelBrokerOrderConfirmMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trademanConfirmOrderCancel",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmOrderCancel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerOrder",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerOrderNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "orderSet",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "stockorder",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StockOrderNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "FragmentSpread",
                                "name": "ActionLogs_cancelUpdate",
                                "args": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelBrokerOrderConfirmMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "trademanConfirmOrderCancel",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmOrderCancel",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerOrder",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerOrderNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "orderSet",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "stockorder",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StockOrderNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "actionLogs",
                                "storageKey": "actionLogs(orderBy:\"actionTime\")",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "orderBy",
                                    "value": "actionTime"
                                  }
                                ],
                                "concreteType": "ActionLogUserRequestNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ActionLogUserRequestNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "ActionLogUserRequestNode",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "actionId",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "actionType",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "actionTime",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "actionDescription",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "user",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "UserNode",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "kind": "ScalarField",
                                                "alias": null,
                                                "name": "fullName",
                                                "args": null,
                                                "storageKey": null
                                              },
                                              (v2/*: any*/)
                                            ]
                                          },
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "blockedBaseCharges",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "blockedHoldings",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "blockedProceeds",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "blockedLocalCharges",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelBrokerOrderConfirmMutation",
    "id": null,
    "text": "mutation CancelBrokerOrderConfirmMutation(\n  $brokerOrderId: ID\n) {\n  trademanConfirmOrderCancel(brokerOrderId: $brokerOrderId) {\n    brokerOrder {\n      id\n      status\n      updatedAt\n      orderSet {\n        edges {\n          node {\n            id\n            stockorder {\n              id\n              ...ActionLogs_cancelUpdate\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ActionLogs_cancelUpdate on StockOrderNode {\n  ...ActionLogs_all\n  blockedBaseCharges\n  blockedHoldings\n  blockedProceeds\n  blockedLocalCharges\n}\n\nfragment ActionLogs_all on StockOrderNode {\n  actionLogs(orderBy: \"actionTime\") {\n    edges {\n      node {\n        actionId\n        actionType\n        actionTime\n        actionDescription\n        user {\n          fullName\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ccd26a44c40a3bd8fc18d8f1f3aa93ea';
module.exports = node;
