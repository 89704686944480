import React from 'react';
import Grid from '@material-ui/core/Grid';
import { createFragmentContainer, graphql } from 'react-relay';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Remove as RemoveIcon,
  Comment as CommentIcon,
} from '@material-ui/icons';
import { deleteFeeMutation } from '../../mutations/Fee';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';
import TenantLink from '../common/links/TenantLink';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import doCommit, { doCommitPromise } from '../../common/commit';
import { deleteFeeComponentMutation } from '../../mutations/FeeComponent';
import FeeTitle from './FeeTitle';
import NoteButton from '../common/notes/NoteButton';


function Fee(props) {
  const {
    fee,
    onEditFeeComponent,
    onEditFee,
    onDeleteFee,
  } = props;
  const currencyVarPath = ['tenant', 'baseCurrency'];

  return (
    <DataTableWidget
      title={() => <FeeTitle fee={fee} />}
      xs={12}
      lg={6}
      rightTopComp={(
        <>
          <Tooltip title="Edit Fee" enterDelay={500}>
            <IconButton
              color="primary"
              aria-label="edit-fee"
              onClick={() => onEditFee(fee)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Remove Fee" enterDelay={500}>
            <IconButton
              color="secondary"
              aria-label="delete"
              onClick={() => doCommit(
                deleteFeeMutation,
                { feeId: fee.id },
                () => {
                  if (onDeleteFee) onDeleteFee();
                },
              )}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add Fee Component" enterDelay={500}>
            <IconButton
              color="primary"
              aria-label="add-fee-component"
              onClick={() => onEditFeeComponent(null)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Add Fee Component" enterDelay={500}>
            <NoteButton
              internalDrawer
              notesOwner={fee}
              ownButtonComp={(compProps) => {
                const { onClickNotes, color } = compProps;
                return (
                  <IconButton
                    color={color}
                    aria-label="add-notes-component"
                    onClick={onClickNotes}
                  >
                    <CommentIcon />
                  </IconButton>
                );
              }}
            />
          </Tooltip>


        </>
      )}
      columnMeta={[
        TableFieldMeta.enum('FeeCalculationType', 'Calc.', x => x.calculationType),
        TableFieldMeta.priceField('minVal', 'Min Val', 'minChargePoint', currencyVarPath),
        TableFieldMeta.priceField('maxVal', 'Max Val', 'maxChargePoint', currencyVarPath),
        TableFieldMeta.priceField('minPrice', 'Min Price', 'minPriceChargePoint', currencyVarPath),
        TableFieldMeta.priceField('maxPrice', 'Max Price', 'maxPriceChargePoint', currencyVarPath),
        TableFieldMeta.priceField('flatAmt', 'Flat Amt', 'flatChargeAmount', currencyVarPath),
        TableFieldMeta.field('percentChargeAmount', '%Charge', { align: 'right' }),
        TableFieldMeta.fieldCell('tenant', 'Tenant', r => <TenantLink tenant={r.value.tenant} />),
        TableFieldMeta.fieldCell('actions', '', r => (
          <Grid container spacing={1}>
            <TableCompactGridItem>
              <TableIconButton
                color="primary"
                tooltip="Edit"
                iconClass={EditIcon}
                onClick={() => onEditFeeComponent(r.value)}
              />
            </TableCompactGridItem>
            <TableCompactGridItem>
              <TableIconButton
                tooltip="Delete"
                color="secondary"
                iconClass={RemoveIcon}
                onClickPromise={() => doCommitPromise(
                  deleteFeeComponentMutation,
                  { feeComponentId: r.value.id },
                )}
              />
            </TableCompactGridItem>
          </Grid>
        )),
      ]}
      dataNodes={fee.feeComponents.edges.map(x => x.node).sort(
        (x, y) => ((x.minChargePoint || 0.0) - (y.minChargePoint || 0.0)),
      )}
    />
  );
}

export default createFragmentContainer(
  Fee,
  {
    fee: graphql`
      fragment Fee_fee on FeeNode {
        id
        feeType
        simpleTranche
        paymentPeriod
        enabled
        createdAt
        updatedAt
        currency
        notes {
          id
        }
        feeComponents {
          edges {
            node {
              id
              tenant {
                id
                name
                baseCurrency
              }
              calculationType
              flatChargeAmount
              maxChargePoint
              minChargePoint
              minPriceChargePoint
              maxPriceChargePoint
              percentChargeAmount
              createdAt
              updatedAt
            }
          }
        }
      }
    `,
  },
);
