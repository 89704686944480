import { graphql } from 'react-relay';

const ignoreBrokerTransaction = graphql`
  mutation BrokerTransactionIgnoreMutation (
    $brokerTransactionId: ID
    $ignore: Boolean!
  ) {
    ignoreBrokerTransaction(
      brokerTransactionId: $brokerTransactionId
      ignore: $ignore
    ) {
      brokerTransaction {
        id 
        matched
        ignore
      }
    }
  }
`;

export default ignoreBrokerTransaction;
