import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function VerticallyCenteredGrid(props) {
  const { children, ...otherProps } = props;
  return (
    <Grid alignContent="center" style={{ display: 'inline-grid' }} {...otherProps}>
      {children}
    </Grid>
  );
}
