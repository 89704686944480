/**
 * @flow
 * @relayHash 20f530b446ccfee08fa4f6e5aa2bbbed
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PortfolioOrderRequestApprovalForm_viewer$ref = any;
export type PortfolioOrderRequestApprovalFormQueryVariables = {|
  requestId?: ?string
|};
export type PortfolioOrderRequestApprovalFormQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PortfolioOrderRequestApprovalForm_viewer$ref
  |}
|};
export type PortfolioOrderRequestApprovalFormQuery = {|
  variables: PortfolioOrderRequestApprovalFormQueryVariables,
  response: PortfolioOrderRequestApprovalFormQueryResponse,
|};
*/


/*
query PortfolioOrderRequestApprovalFormQuery(
  $requestId: ID
) {
  viewer {
    ...PortfolioOrderRequestApprovalForm_viewer_1et73o
    id
  }
}

fragment PortfolioOrderRequestApprovalForm_viewer_1et73o on Query {
  portfolioOrderRequests(id: $requestId) {
    count
    edges {
      node {
        id
        status
        createdAt
        approvals {
          count
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        portfolioOrder {
          id
          account {
            id
            name
          }
          stockOrders {
            edges {
              node {
                id
                brokerOrder {
                  status
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "requestId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PortfolioOrderRequestApprovalFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PortfolioOrderRequestApprovalForm_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "requestId",
                "variableName": "requestId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PortfolioOrderRequestApprovalFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "portfolioOrderRequests",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "requestId"
              }
            ],
            "concreteType": "PortfolioOrderRequestNodeConnection",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PortfolioOrderRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PortfolioOrderRequestNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvals",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNodeConnection",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ApprovalNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "approvedBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "username",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "approvedAt",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolioOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioOrderNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "account",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "stockOrders",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StockOrderNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "StockOrderNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "StockOrderNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokerOrder",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerOrderNode",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v2/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PortfolioOrderRequestApprovalFormQuery",
    "id": null,
    "text": "query PortfolioOrderRequestApprovalFormQuery(\n  $requestId: ID\n) {\n  viewer {\n    ...PortfolioOrderRequestApprovalForm_viewer_1et73o\n    id\n  }\n}\n\nfragment PortfolioOrderRequestApprovalForm_viewer_1et73o on Query {\n  portfolioOrderRequests(id: $requestId) {\n    count\n    edges {\n      node {\n        id\n        status\n        createdAt\n        approvals {\n          count\n          edges {\n            node {\n              id\n              approvedBy {\n                id\n                username\n              }\n              approvedAt\n            }\n          }\n        }\n        portfolioOrder {\n          id\n          account {\n            id\n            name\n          }\n          stockOrders {\n            edges {\n              node {\n                id\n                brokerOrder {\n                  status\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f42088627cca98bba5fb4ff8dad038dd';
module.exports = node;
