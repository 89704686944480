/**
 * @flow
 * @relayHash a061ea7dafc4c0612e2077306d879083
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerTransactionList_viewer$ref = any;
export type BrokerTransactionListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  symbol?: ?string,
  reportDate?: ?any,
  currency?: ?string,
  matched?: ?boolean,
  ignore?: ?boolean,
  instrumentType?: ?string,
  transactionType?: ?string,
  ibConId?: ?number,
  transactionTypeIn?: ?$ReadOnlyArray<?string>,
|};
export type BrokerTransactionListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrokerTransactionList_viewer$ref
  |}
|};
export type BrokerTransactionListQuery = {|
  variables: BrokerTransactionListQueryVariables,
  response: BrokerTransactionListQueryResponse,
|};
*/


/*
query BrokerTransactionListQuery(
  $first: Int
  $offset: Int
  $search: String
  $symbol: String
  $reportDate: Date
  $currency: String
  $matched: Boolean
  $ignore: Boolean
  $instrumentType: String
  $transactionType: String
  $ibConId: Int
  $transactionTypeIn: [String]
) {
  viewer {
    ...BrokerTransactionList_viewer_4zK6rx
    id
  }
}

fragment BrokerTransactionList_viewer_4zK6rx on Query {
  ibBrokerTransactions(first: $first, offset: $offset, search: $search, reportDate: $reportDate, currency: $currency, matched: $matched, instrumentType: $instrumentType, transactionType: $transactionType, symbol: $symbol, ignore: $ignore, conId: $ibConId, transactionTypeIn: $transactionTypeIn, orderBy: "-createdAt") {
    edges {
      node {
        id
        description
        matched
        conId
        price
        quantity
        symbol
        currency
        proceeds
        tradeId
        reportDate
        instrumentType
        transactionType
        transactionDatetime
        execId
        commission
        commissionCurrency
        ignore
        createdAt
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "symbol",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "reportDate",
    "type": "Date",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "matched",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ignore",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "instrumentType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "transactionType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ibConId",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "transactionTypeIn",
    "type": "[String]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "currency",
  "variableName": "currency"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "ignore",
  "variableName": "ignore"
},
v4 = {
  "kind": "Variable",
  "name": "instrumentType",
  "variableName": "instrumentType"
},
v5 = {
  "kind": "Variable",
  "name": "matched",
  "variableName": "matched"
},
v6 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v7 = {
  "kind": "Variable",
  "name": "reportDate",
  "variableName": "reportDate"
},
v8 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v9 = {
  "kind": "Variable",
  "name": "symbol",
  "variableName": "symbol"
},
v10 = {
  "kind": "Variable",
  "name": "transactionType",
  "variableName": "transactionType"
},
v11 = {
  "kind": "Variable",
  "name": "transactionTypeIn",
  "variableName": "transactionTypeIn"
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerTransactionListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BrokerTransactionList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "ibConId",
                "variableName": "ibConId"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerTransactionListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibBrokerTransactions",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "conId",
                "variableName": "ibConId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "concreteType": "IBBrokerTransactionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBBrokerTransactionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBBrokerTransactionNode",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "matched",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "conId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "price",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "symbol",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "proceeds",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "tradeId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "instrumentType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "transactionType",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "transactionDatetime",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "execId",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "commission",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "commissionCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "ignore",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v12/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerTransactionListQuery",
    "id": null,
    "text": "query BrokerTransactionListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $symbol: String\n  $reportDate: Date\n  $currency: String\n  $matched: Boolean\n  $ignore: Boolean\n  $instrumentType: String\n  $transactionType: String\n  $ibConId: Int\n  $transactionTypeIn: [String]\n) {\n  viewer {\n    ...BrokerTransactionList_viewer_4zK6rx\n    id\n  }\n}\n\nfragment BrokerTransactionList_viewer_4zK6rx on Query {\n  ibBrokerTransactions(first: $first, offset: $offset, search: $search, reportDate: $reportDate, currency: $currency, matched: $matched, instrumentType: $instrumentType, transactionType: $transactionType, symbol: $symbol, ignore: $ignore, conId: $ibConId, transactionTypeIn: $transactionTypeIn, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        description\n        matched\n        conId\n        price\n        quantity\n        symbol\n        currency\n        proceeds\n        tradeId\n        reportDate\n        instrumentType\n        transactionType\n        transactionDatetime\n        execId\n        commission\n        commissionCurrency\n        ignore\n        createdAt\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd93caae78cf14c56afc3aef25079a2b0';
module.exports = node;
