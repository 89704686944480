import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TableFieldMeta from '../../common/TableFieldMeta';
import VerbModifier from '../user/VerbModifier';
import EnumListerModifierDataTable from '../common/table/EnumListerModifierDataTable';
import TableToggleButtonGroup from '../common/table/TableToggleButtonGroup';
import VerticallyCenteredGrid from '../common/containers/VerticallyCenteredGrid';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGripItemToggleButtonGroup from '../common/drawer/DrawerGridItemToggleButtonGroup';
import TableButton from '../common/buttons/TableButton';


export default function EventElectionOptionItem(props) {
  const {
    option,
    onUpdateOption,
    index,
  } = props;
  const [state] = React.useState({
    option,
  });

  return (
    <Grid container spacing={2}>
      <VerticallyCenteredGrid item xs={1}>1</VerticallyCenteredGrid>
      <DrawerGridItemTextField xs={5} size="small" />
      <DrawerGripItemToggleButtonGroup
        xs={2}
        size="small"
        enumType={[
          { name: 'YES', description: 'Yes' },
          { name: 'NO', description: 'No' },
        ]}
        defaultValue="YES"
        onChange={(newVal) => {
          // setOrderType(newVal);
          // onSetParam('orderType', newVal);
        }}
      />
      <DrawerGripItemToggleButtonGroup
        xs={2}
        size="small"
        enumType={[
          { name: 'YES', description: 'Yes' },
          { name: 'NO', description: 'No' },
        ]}
        defaultValue="YES"
        onChange={(newVal) => {
          console.log({ newVal });
          // setOrderType(newVal);
          // onSetParam('orderType', newVal);
        }}
      />
      <VerticallyCenteredGrid item xs={2}>
        <TableButton
          color="secondary"
          variant="text"
          size="large"
          onClickPromise={() => {}}
        >
          <DeleteIcon style={{ fontSize: 20 }} /> 
          {' '}
          <span style={{ fontSize: 14, marginLeft: 4 }}>Delete</span>
        </TableButton>
      </VerticallyCenteredGrid>
    </Grid>
  );
}
