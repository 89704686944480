import CorporateActionList from './CorporateActionList';
import DividendAccrualList from './DividendAccrualList';
import CashTransactionList from './CashTransactionList';
import TransactionTaxList from './TransactionTaxList';
import { withTabItems } from '../common/RoutableTabContainer';
import TransferList from './TransferList';
import CashTxnDivAccHoldingMixed from './CashTxnDivAccHoldingMixed';
import SalesTaxList from './SalesTaxList';
import BrokerTransactionList from './BrokerTransactionList';
import VBDividendList from './VBDividendList';

export default withTabItems([
  {
    label: 'IB-Corpact',
    url: 'ib-corporate-actions',
    comp: CorporateActionList,
  },
  {
    label: 'IB-Div.Accrual',
    url: 'ib-dividend-accruals',
    comp: DividendAccrualList,
  },
  {
    label: 'IB-Cash Txn',
    url: 'ib-cash-transactions',
    comp: CashTransactionList,
  },
  {
    label: 'IB-Transaction Tax',
    url: 'ib-transaction-taxes',
    comp: TransactionTaxList,
  },
  {
    label: 'IB-Sales Tax',
    url: 'ib-sales-taxes',
    comp: SalesTaxList,
  },
  {
    label: 'IB-Transfers',
    url: 'ib-transfers',
    comp: TransferList,
  },
  {
    label: 'IB-Transactions',
    url: 'ib-transactions',
    comp: BrokerTransactionList,
  },
  {
    label: 'VB-Dividends',
    url: 'vb-dividends',
    comp: VBDividendList,
  },
  {
    label: 'CT Proc View',
    url: 'ct-proc-view',
    comp: CashTxnDivAccHoldingMixed,
  },
]);
