/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type Direction = "IN" | "OUT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TransferList_viewer$ref: FragmentReference;
declare export opaque type TransferList_viewer$fragmentType: TransferList_viewer$ref;
export type TransferList_viewer = {|
  +ibTransfers: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +processedAt: ?any,
        +processedBy: ?{|
          +username: string,
          +fullName: ?string,
        |},
        +notes: ?{|
          +id: string
        |},
        +ignored: boolean,
        +ibConId: ?number,
        +currency: ?Currency,
        +description: ?string,
        +quantity: number,
        +cashQuantity: number,
        +positionAmount: number,
        +positionAmountInBase: number,
        +isin: ?string,
        +symbol: ?string,
        +reportDate: any,
        +contraAccountId: ?string,
        +contraCompany: ?string,
        +direction: Direction,
        +updatedAt: any,
        +transactionId: any,
        +contract: ?{|
          +id: string,
          +ibConId: number,
          +currency: Currency,
          +symbol: string,
        |},
        +brokeractivityPtr: {|
          +brokeractivitytransactionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +account: {|
                  +name: ?string,
                  +id: string,
                |},
                +brokeractivitytransactioncontractdeltaSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +portfolio: {|
                        +id: string
                      |},
                      +contract: {|
                        +id: string,
                        +symbol: string,
                      |},
                      +quantity: number,
                    |}
                  |}>
                |},
                +brokeractivitytransactioncashdeltaSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +currency: Currency,
                      +quantity: number,
                    |}
                  |}>
                |},
              |}
            |}>
          |}
        |},
      |}
    |}>,
    +count: ?number,
    +pageInfo: {|
      +endCursor: ?string,
      +startCursor: ?string,
      +hasNextPage: boolean,
      +hasPreviousPage: boolean,
    |},
  |},
  +$refType: TransferList_viewer$ref,
|};
export type TransferList_viewer$data = TransferList_viewer;
export type TransferList_viewer$key = {
  +$data?: TransferList_viewer$data,
  +$fragmentRefs: TransferList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ibConId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TransferList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "search",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "symbol",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "currency",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "ibConId",
      "type": "Int",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "reportDate",
      "type": "Date",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "processedAt_Isnull",
      "type": "Boolean",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "ignored",
      "type": "Boolean",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "ibTransfers",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "currency",
          "variableName": "currency"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "ibConId",
          "variableName": "ibConId"
        },
        {
          "kind": "Variable",
          "name": "ignored",
          "variableName": "ignored"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Variable",
          "name": "processedAt_Isnull",
          "variableName": "processedAt_Isnull"
        },
        {
          "kind": "Variable",
          "name": "reportDate",
          "variableName": "reportDate"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "symbol",
          "variableName": "symbol"
        }
      ],
      "concreteType": "IBTransferNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "IBTransferNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "IBTransferNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "processedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "processedBy",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "UserNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "username",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "fullName",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "notes",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "NotesNode",
                  "plural": false,
                  "selections": (v1/*: any*/)
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "ignored",
                  "args": null,
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "description",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cashQuantity",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "positionAmount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "positionAmountInBase",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "isin",
                  "args": null,
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "reportDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contraAccountId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "contraCompany",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "direction",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "updatedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "transactionId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contract",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ContractNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v5/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "brokeractivityPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerActivityNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "brokeractivitytransactionSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BrokerActivityTransactionNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BrokerActivityTransactionNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerActivityTransactionNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "account",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "AccountNode",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "alias": null,
                                      "name": "name",
                                      "args": null,
                                      "storageKey": null
                                    },
                                    (v0/*: any*/)
                                  ]
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokeractivitytransactioncontractdeltaSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerActivityTransactionContractDeltaNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "BrokerActivityTransactionContractDeltaNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "BrokerActivityTransactionContractDeltaNode",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "portfolio",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "PortfolioNode",
                                              "plural": false,
                                              "selections": (v1/*: any*/)
                                            },
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "contract",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "ContractNode",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/),
                                                (v5/*: any*/)
                                              ]
                                            },
                                            (v4/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "brokeractivitytransactioncashdeltaSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "BrokerActivityTransactionCashDeltaNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "BrokerActivityTransactionCashDeltaNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "BrokerActivityTransactionCashDeltaNode",
                                          "plural": false,
                                          "selections": [
                                            (v3/*: any*/),
                                            (v4/*: any*/)
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "startCursor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasPreviousPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcd1657848bfbf03c607bf05f3eade57';
module.exports = node;
