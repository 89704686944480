/**
 * @flow
 * @relayHash c7fb5911d540cb2b37c402ffd4453d03
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CurrencyConversionFormPending_viewer$ref = any;
export type CurrencyConversionFormQueryVariables = {|
  accountId: string,
  baseCurrency?: ?string,
|};
export type CurrencyConversionFormQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: CurrencyConversionFormPending_viewer$ref,
  |}
|};
export type CurrencyConversionFormQuery = {|
  variables: CurrencyConversionFormQueryVariables,
  response: CurrencyConversionFormQueryResponse,
|};
*/


/*
query CurrencyConversionFormQuery(
  $accountId: ID!
  $baseCurrency: String
) {
  viewer {
    ...CurrencyConversionFormPending_viewer
    id
  }
}

fragment CurrencyConversionFormPending_viewer on Query {
  currencyConversionRequests(accountId: $accountId, baseCurrency: $baseCurrency, quoteCurrency: "AUD", status: "PENDING") {
    count
    edges {
      node {
        id
        baseCurrency
        quoteCurrency
        quantity
        status
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "baseCurrency",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CurrencyConversionFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "FragmentSpread",
            "name": "CurrencyConversionFormPending_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CurrencyConversionFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currencyConversionRequests",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              {
                "kind": "Variable",
                "name": "baseCurrency",
                "variableName": "baseCurrency"
              },
              {
                "kind": "Literal",
                "name": "quoteCurrency",
                "value": "AUD"
              },
              {
                "kind": "Literal",
                "name": "status",
                "value": "PENDING"
              }
            ],
            "concreteType": "CurrencyConversionRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CurrencyConversionRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CurrencyConversionRequestNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "baseCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quoteCurrency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "quantity",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvals",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ApprovalNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "approvedBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "username",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "approvedAt",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "hasCompleteApprovals",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CurrencyConversionFormQuery",
    "id": null,
    "text": "query CurrencyConversionFormQuery(\n  $accountId: ID!\n  $baseCurrency: String\n) {\n  viewer {\n    ...CurrencyConversionFormPending_viewer\n    id\n  }\n}\n\nfragment CurrencyConversionFormPending_viewer on Query {\n  currencyConversionRequests(accountId: $accountId, baseCurrency: $baseCurrency, quoteCurrency: \"AUD\", status: \"PENDING\") {\n    count\n    edges {\n      node {\n        id\n        baseCurrency\n        quoteCurrency\n        quantity\n        status\n        approvals {\n          edges {\n            node {\n              id\n              approvedBy {\n                id\n                username\n              }\n              approvedAt\n            }\n          }\n        }\n        hasCompleteApprovals\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98a10c7457afc4fc15ae31ea0d59fc25';
module.exports = node;
