import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const loaderQuery = graphql`
  query TenantLoaderQuery (
    $first: Int,
    $offset: Int
  ) {
    viewer {
      ...TenantLoaderFragment_viewer
      @arguments(
        first: $first,
        offset: $offset,
      )
    }
  }
`;

const refetchContainer = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment TenantLoaderFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String, defaultValue: null}
      ) {
        tenants (
          first: $first
          offset: $offset
          search: $search
        ) {
          edges {
            node {
              id
              name
              firstName
              lastName
              email
            }
          }
        }
      }
    `,
  },
  graphql`
    query TenantLoaderRefetchQuery(
      $first: Int!
      $offset: Int!
      $search: String!
    ) {
      viewer {
        ...TenantLoaderFragment_viewer
        @arguments(
          first: $first,
          offset: $offset,
          search: $search,
        )
      }
    }
  `,
);

const getOptionLabel = option => (option.name);
const nodeCollector = viewer => viewer.tenants.edges.map(edge => edge.node);

export default function TenantLoader(props) {
  return (
    <FieldLoader
      loaderQuery={loaderQuery}
      refetchContainer={refetchContainer}
      getOptionLabel={getOptionLabel}
      nodeCollector={nodeCollector}
      {...props}
    />
  );
}
