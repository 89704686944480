/**
 * @flow
 * @relayHash 897d41132f82711be62b7246d5287836
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StockPriceDetails_viewer$ref = any;
export type StockPriceDetailsQueryVariables = {|
  contractId: string
|};
export type StockPriceDetailsQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: StockPriceDetails_viewer$ref
  |}
|};
export type StockPriceDetailsQuery = {|
  variables: StockPriceDetailsQueryVariables,
  response: StockPriceDetailsQueryResponse,
|};
*/


/*
query StockPriceDetailsQuery(
  $contractId: ID!
) {
  viewer {
    ...StockPriceDetails_viewer_1sMWdv
    id
  }
}

fragment StockPriceDetails_viewer_1sMWdv on Query {
  contract(id: $contractId) {
    id
    currency
    contractFinancialData(orderBy: "date", last: 1000) {
      edges {
        node {
          id
          date
          closingPriceLocal
          scaleFactor
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "StockPriceDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "StockPriceDetails_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "contractId",
                "variableName": "contractId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "StockPriceDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contract",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "contractId"
              }
            ],
            "concreteType": "ContractNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contractFinancialData",
                "storageKey": "contractFinancialData(last:1000,orderBy:\"date\")",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "last",
                    "value": 1000
                  },
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "date"
                  }
                ],
                "concreteType": "ContractFinancialDataNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractFinancialDataNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ContractFinancialDataNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "date",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "closingPriceLocal",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "scaleFactor",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "StockPriceDetailsQuery",
    "id": null,
    "text": "query StockPriceDetailsQuery(\n  $contractId: ID!\n) {\n  viewer {\n    ...StockPriceDetails_viewer_1sMWdv\n    id\n  }\n}\n\nfragment StockPriceDetails_viewer_1sMWdv on Query {\n  contract(id: $contractId) {\n    id\n    currency\n    contractFinancialData(orderBy: \"date\", last: 1000) {\n      edges {\n        node {\n          id\n          date\n          closingPriceLocal\n          scaleFactor\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa6312cec5cb245c64a7d28bd3345f1c';
module.exports = node;
