import { graphql } from 'react-relay';

// language=GraphQL
const updateUserRoleMutation = graphql`
  mutation UpdateUserRoleMutation(
    $userRoleId: ID!
    $rolePermissions: [UserClientAccountPermissionInput]
  ) {
    updateUserRole(
      userRoleId: $userRoleId
      rolePermissions: $rolePermissions
    ) {
    userRole {
        id
        name
        rolePermissions {
          edges {
            node {
              id
              resource
              verb
            }
          }
        }
      }
    }
  }
`;

export default updateUserRoleMutation;
