import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { useCommonStyles } from '../common/Styles';
import TenantLoader from '../common/fieldLoaders/TenantLoader';
import { AddButton, UpdateButton, CloseButton } from '../common/buttons/BaseButton';
import { EnumValues } from '../common/EnumValue';
import { doCommitPromise } from '../../common/commit';
import upsertFeeComponentMutation from '../../mutations/UpsertFeeComponent';

const nullOrDec = inVal => ((inVal === null) ? null : `${inVal}`);

export default function FeeComponentForm(props) {
  const { feeComponent, fee, onClose } = props;
  const isUpdate = !!feeComponent;
  const classes = useCommonStyles();
  const [calculationType, setCalculationType] = React.useState(
    isUpdate ? feeComponent.calculationType : 'MAX',
  );
  const [tenant, setTenant] = React.useState(isUpdate ? feeComponent.tenant : null);
  const [ucVars] = React.useState({
    flatChargeAmount: isUpdate ? nullOrDec(feeComponent.flatChargeAmount) : null,
    minChargePoint: isUpdate ? nullOrDec(feeComponent.minChargePoint) : null,
    maxChargePoint: isUpdate ? nullOrDec(feeComponent.maxChargePoint) : null,
    minPriceChargePoint: isUpdate ? nullOrDec(feeComponent.minPriceChargePoint) : null,
    maxPriceChargePoint: isUpdate ? nullOrDec(feeComponent.maxPriceChargePoint) : null,
    percentChargeAmount: isUpdate ? nullOrDec(feeComponent.percentChargeAmount) : null,
  });

  const addOrUpdate = () => {
    const vars = [
      'minChargePoint',
      'maxChargePoint',
      'flatChargeAmount',
      'percentChargeAmount',
      'minPriceChargePoint',
      'maxPriceChargePoint',
    ];
    const packedUcVars = {};
    vars.map(
      (varItem) => {
        if (ucVars[varItem] === null || ucVars[varItem] === '') {
          packedUcVars[varItem] = null;
        } else {
          packedUcVars[varItem] = ucVars[varItem];
        }
        return 0;
      },
    );

    const variables = {
      feeComponentId: isUpdate ? feeComponent.id : null,
      ...packedUcVars,
      calculationType,
      feeId: fee.id,
      tenantId: tenant.id,
    };

    return doCommitPromise(
      upsertFeeComponentMutation,
      variables,
      () => onClose && onClose(),
    );
  };

  const ucVarParams = [
    ['minChargePoint', 'Min Value Point'],
    ['maxChargePoint', 'Max Value Point'],
    ['minPriceChargePoint', 'Min Price Point'],
    ['maxPriceChargePoint', 'Max Price Point'],
    ['flatChargeAmount', 'Flat Charge Amount'],
    ['percentChargeAmount', '% Charge Amount'],
  ];

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            { isUpdate ? 'Update Fee Component' : 'Add Fee Component'}
          </Typography>
        </Grid>

        {
          ucVarParams.map(
            ucVarParam => (
              <Grid item xs={12} md={6}>
                <TextField
                  className={classes.fromControlDrawer}
                  id={ucVarParam[0]}
                  label={ucVarParam[1]}
                  type="number"
                  defaultValue={ucVars[ucVarParam[0]]}
                  onChange={(e) => {
                    ucVars[ucVarParam[0]] = e.target.value;
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ),
          )
        }

        <Grid item xs={12} md={6}>
          <FormControl className={classes.fromControlDrawer}>
            <InputLabel htmlFor="accountType-helper">Calculation Type</InputLabel>
            <EnumValues
              enumType="FeeCalculationType"
              render={enumMeta => (
                <Select
                  value={calculationType}
                  onChange={e => setCalculationType(e.target.value)}
                  input={<Input name="accountType" id="age-helper" />}
                >
                  {
                    Object.keys(enumMeta).map(
                      enumKey => (
                        <MenuItem
                          key={enumKey}
                          value={enumKey}
                        >
                          {enumMeta[enumKey].description}
                        </MenuItem>
                      ),
                    )
                  }
                </Select>
              )
              }
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TenantLoader
            key={tenant ? tenant.id : 0}
            className={classes.fromControlDrawer}
            label="Tenant"
            onChange={e => setTenant(e)}
            defaultValue={tenant}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justify="flex-end" style={{ marginTop: '10px' }}>
            <Grid item>
              {
                isUpdate
                  ? <UpdateButton onClickPromise={addOrUpdate} disabled={!tenant} />
                  : <AddButton onClickPromise={addOrUpdate} disabled={!tenant} />
              }
            </Grid>
            <Grid item>
              <CloseButton
                onClick={() => onClose()}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}
