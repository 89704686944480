import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TableStatus from '../common/TableStatus';
import TableButton from '../common/buttons/TableButton';
import notiStack from '../../common/notiStack';


export default function BrokerActivityActions(props) {
  const {
    brokerActivity,
    onProcess,
    onShowDetails,
    validateProcess,
    isIgnored,
    isProcessed,
    showProcess,
  } = props;
  const key = `${brokerActivity.id}-${brokerActivity.updatedAt}`;
  if (isIgnored(brokerActivity)) {
    return (
      <Grid container spacing={1} justify="center">
        <Grid item>
          <TableStatus>Ignored</TableStatus>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={1} justify="center">
      <Grid item>
        {isProcessed(brokerActivity)
          ? (
            <TableButton
              key={key}
              color="primary"
              onClick={() => onShowDetails(brokerActivity)}
            >
              DETAILS
            </TableButton>
          ) : showProcess && (
            <TableButton
              key={key}
              color="secondary"
              onClick={() => {
                if (validateProcess) {
                  const res = validateProcess();
                  if (!res.valid) {
                    notiStack.error(res.message);
                    return;
                  }
                }
                onProcess(brokerActivity);
              }}
            >
              PROCESS
            </TableButton>
          )}
      </Grid>
    </Grid>
  );
}


BrokerActivityActions.propTypes = {
  isIgnored: PropTypes.func,
  isProcessed: PropTypes.func,
  showProcess: PropTypes.bool,
};

BrokerActivityActions.defaultProps = {
  isIgnored: x => x.ignored,
  isProcessed: x => x.processedAt,
  showProcess: true,
};
