import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { commonStyles, useCommonStyles } from '../common/Styles';
import createManualAccountChargeMutation from '../../mutations/CreateManualAccountChargeMutation';
import DrawerSubGroupDivider from '../common/drawer/DrawerSubGroupDivider';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSubGroupHeading from '../common/drawer/DrawerGridItemSubGroupHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemTextArea from '../common/drawer/DrawerGridItemTextArea';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';
import DrawerGridItemBottomButtonContainer from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton, RemoveButton } from '../common/buttons/BaseButton';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import { doCommitPromise } from '../../common/commit';
import notiStack from '../../common/notiStack';

function AddChargeForm(props) {
  const { onClose, onAddCharge } = props;
  const [account, setAccount] = React.useState(null);
  const [members] = React.useState({
    description: null,
  });
  const [feeType, setFeeType] = React.useState('PLT');
  const [currency, setCurrency] = React.useState('AUD');
  const [tenantCharges, setTenantCharges] = React.useState([{
    tenantAccount: null,
    amount: 0,
  }]);
  const classes = useCommonStyles();
  const validator = () => {
    tenantCharges.forEach(
      (tc) => {
        if (!tc.tenantAccount) {
          notiStack.warning('Not all tenant accounts specified');
          return false;
        }
        if (!tc.amount) {
          notiStack.warning('Not all amounts are specified');
          return false;
        }
        return true;
      },
    );
    return true;
  };


  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading heading="Add New Charge" />
        <DrawerGridItemSubGroupHeading heading="Client Account" />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            onSelect={newAccount => setAccount(newAccount)}
          />
        </Grid>

        <DrawerGridItemSelectField
          sm={12}
          enumType="FeeType"
          label="Fee Type"
          value={feeType}
          onChange={(e) => { setFeeType(e.target.value); }}
        />

        <DrawerGridItemSelectField
          sm={12}
          enumType="Currency"
          label="Currency"
          value={currency}
          onChange={(e) => { setCurrency(e.target.value); }}
        />

        <DrawerGridItemTextArea
          label="Description"
          onChange={(e) => { members.description = e.target.value; }}
        />

        { tenantCharges.map((tenantCharge, idx) => (
          <>
            <DrawerSubGroupDivider />
            <DrawerGridItemSubGroupHeading
              heading="Tenant Account"
            />

            <Grid item xs={12}>
              <PopupAccountSelectorField
                fullWidth
                onSelect={(ta) => {
                  tenantCharges[idx].tenantAccount = ta.tenantaccount;
                }}
              />
            </Grid>

            <DrawerGridItemNumberField
              label="Amount"
              onChange={(e) => {
                tenantCharges[idx].amount = e.target.value;
              }}
            />
          </>
        )) }

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClick={e => setTenantCharges([...tenantCharges, {
                tenantAccount: null,
                amount: 0,
              }])}
              buttonText="Tenant"
            />
          </Grid>
          <Grid item>
            <RemoveButton
              disabled={tenantCharges.length <= 1}
              onClick={() => setTenantCharges([...tenantCharges].splice(
                0,
                tenantCharges.length - 1,
              ))}
              buttonText="Tenant"
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              disabled={account === null}
              onClickPromise={() => doCommitPromise(
                createManualAccountChargeMutation,
                {
                  clientAccountId: account.clientaccount.id,
                  description: members.description,
                  tenantCharges: tenantCharges.map(tc => ({
                    tenantAccountId: tc.tenantAccount.id,
                    amount: Number(tc.amount),
                  })),
                  feeType,
                  currency,
                },
                () => {
                  if (onAddCharge) onAddCharge();
                  if (onClose) onClose();
                },
                () => null,
                null,
                null,
                validator,
              )}
              buttonText="Add Charge"
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>

    </div>
  );
}

export default withStyles(commonStyles)(AddChargeForm);
