import { toGlobalId } from '../../helpers/ID';
import { withTabItems } from '../common/RoutableTabContainer';
import VueHistory from './VueHistory';
import VueCommunicationTab from './VueCommunicationTab';
import VueDetails from './VueDetails';
import VueResearch from './VueResearchList';
import PortfolioOrderList from '../order/PortfolioOrderList';

const vueIdPass = props => ({
  vueId: toGlobalId('VueNode', props.match.params.vueId),
});

export default withTabItems(
  [
    {
      label: 'Details',
      url: 'detail',
      comp: VueDetails,
      propCopyFn: vueIdPass,
    },
    {
      label: 'Research',
      url: 'research',
      comp: VueResearch,
      propCopyFn: vueIdPass,
    },
    {
      label: 'History',
      url: 'history',
      comp: VueHistory,
      propCopyFn: vueIdPass,
    },
    {
      label: 'Communication',
      url: 'communication',
      comp: VueCommunicationTab,
      propCopyFn: vueIdPass,
    }, {
      label: 'Orders',
      url: 'orders',
      comp: PortfolioOrderList,
      propCopyFn: vueIdPass,
    },
  ],
);
