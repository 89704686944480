/**
 * @flow
 * @relayHash a2100fe0001743883b42f72ce5aba6e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PortfolioOrderList_viewer$ref = any;
export type PortfolioOrderListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  side?: ?string,
  status?: ?string,
  accountId?: ?string,
  trackingVue?: ?string,
|};
export type PortfolioOrderListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PortfolioOrderList_viewer$ref
  |}
|};
export type PortfolioOrderListQuery = {|
  variables: PortfolioOrderListQueryVariables,
  response: PortfolioOrderListQueryResponse,
|};
*/


/*
query PortfolioOrderListQuery(
  $first: Int
  $offset: Int
  $search: String
  $side: String
  $status: String
  $accountId: ID
  $trackingVue: ID
) {
  viewer {
    ...PortfolioOrderList_viewer_40FmTG
    id
  }
}

fragment PortfolioOrderList_viewer_40FmTG on Query {
  portfolioOrders(first: $first, offset: $offset, side: $side, search: $search, status: $status, accountId: $accountId, portfolio_TrackingVue: $trackingVue, orderBy: "-createdAt") {
    edges {
      node {
        id
        side
        status
        createdAt
        updatedAt
        tradeInvoice {
          id
          pdfUrl
          pdf
          state
          createdAt
          updatedAt
        }
        bulkOrder {
          id
        }
        stockOrders {
          edges {
            node {
              id
              side
              quantityToDate
              matched
              quantity
              brokerOrder {
                id
                quantity
                quantityFilled
                quantityLeft
                status
              }
            }
          }
        }
        portfolio {
          id
          trackingVueName
          account {
            name
            id
            clientaccount {
              id
              accountType
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "side",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "trackingVue",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "kind": "Variable",
  "name": "side",
  "variableName": "side"
},
v6 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "side",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PortfolioOrderListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PortfolioOrderList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "trackingVue",
                "variableName": "trackingVue"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PortfolioOrderListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "portfolioOrders",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              {
                "kind": "Variable",
                "name": "portfolio_TrackingVue",
                "variableName": "trackingVue"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "concreteType": "PortfolioOrderNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PortfolioOrderNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PortfolioOrderNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tradeInvoice",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TradeInvoiceNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "pdfUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "pdf",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "state",
                            "args": null,
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bulkOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BulkOrderNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "stockOrders",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "StockOrderNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "StockOrderNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "StockOrderNode",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "quantityToDate",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "matched",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v12/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "brokerOrder",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerOrderNode",
                                    "plural": false,
                                    "selections": [
                                      (v7/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "quantityFilled",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "quantityLeft",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      (v9/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trackingVueName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "account",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "clientaccount",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ClientAccountNode",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "accountType",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PortfolioOrderListQuery",
    "id": null,
    "text": "query PortfolioOrderListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $side: String\n  $status: String\n  $accountId: ID\n  $trackingVue: ID\n) {\n  viewer {\n    ...PortfolioOrderList_viewer_40FmTG\n    id\n  }\n}\n\nfragment PortfolioOrderList_viewer_40FmTG on Query {\n  portfolioOrders(first: $first, offset: $offset, side: $side, search: $search, status: $status, accountId: $accountId, portfolio_TrackingVue: $trackingVue, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        side\n        status\n        createdAt\n        updatedAt\n        tradeInvoice {\n          id\n          pdfUrl\n          pdf\n          state\n          createdAt\n          updatedAt\n        }\n        bulkOrder {\n          id\n        }\n        stockOrders {\n          edges {\n            node {\n              id\n              side\n              quantityToDate\n              matched\n              quantity\n              brokerOrder {\n                id\n                quantity\n                quantityFilled\n                quantityLeft\n                status\n              }\n            }\n          }\n        }\n        portfolio {\n          id\n          trackingVueName\n          account {\n            name\n            id\n            clientaccount {\n              id\n              accountType\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eaf8dbbce3963f69ff72b606fe21e900';
module.exports = node;
