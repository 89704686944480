/**
 * @flow
 * @relayHash 21b600af633c2953f5255f9b93bcb9d4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CashActivityList_viewer$ref = any;
export type CashActivityListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  accountId?: ?string,
|};
export type CashActivityListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CashActivityList_viewer$ref
  |}
|};
export type CashActivityListQuery = {|
  variables: CashActivityListQueryVariables,
  response: CashActivityListQueryResponse,
|};
*/


/*
query CashActivityListQuery(
  $first: Int
  $offset: Int
  $accountId: ID
) {
  viewer {
    ...CashActivityList_viewer_4tQiFT
    id
  }
}

fragment CashActivityList_viewer_4tQiFT on Query {
  activity(accountId: $accountId, first: $first, offset: $offset, orderBy: "-createdAt") {
    edges {
      node {
        id
        summaryDatetime
        reportDate
        description
        cashDeltas {
          edges {
            node {
              quantity
              currency
              createdAt
              updatedAt
              id
            }
          }
        }
        cashBalances {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
        endingCashBase
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountId",
  "variableName": "accountId"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CashActivityListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CashActivityList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CashActivityListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "activity",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              }
            ],
            "concreteType": "CashSummaryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashSummaryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashSummaryNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "summaryDatetime",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "reportDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashDeltas",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashDeltaNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashDeltaNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashDeltaNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updatedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashBalances",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashBalanceNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashBalanceNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashBalanceNode",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  (v5/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "endingCashBase",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CashActivityListQuery",
    "id": null,
    "text": "query CashActivityListQuery(\n  $first: Int\n  $offset: Int\n  $accountId: ID\n) {\n  viewer {\n    ...CashActivityList_viewer_4tQiFT\n    id\n  }\n}\n\nfragment CashActivityList_viewer_4tQiFT on Query {\n  activity(accountId: $accountId, first: $first, offset: $offset, orderBy: \"-createdAt\") {\n    edges {\n      node {\n        id\n        summaryDatetime\n        reportDate\n        description\n        cashDeltas {\n          edges {\n            node {\n              quantity\n              currency\n              createdAt\n              updatedAt\n              id\n            }\n          }\n        }\n        cashBalances {\n          edges {\n            node {\n              id\n              currency\n              quantity\n            }\n          }\n        }\n        endingCashBase\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4e237d0344ecda11760f65243aef05f2';
module.exports = node;
