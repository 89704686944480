import React from 'react';
import Relay, { graphql } from 'react-relay';
import FieldLoader, { AutoCompleteHandler } from '../FieldLoader';

const query = graphql`
  query VueLoaderQuery (
    $first: Int!
    $offset: Int!
    $search: String
  ) {
    viewer {
      ...VueLoaderQueryFragment_viewer
      @arguments(
        first: $first,
        offset: $offset,
        search: $search,
      )
    }
  }
`;

const container = Relay.createRefetchContainer(
  AutoCompleteHandler,
  {
    viewer: graphql`
      fragment VueLoaderQueryFragment_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String, defaultValue: null }
      ) {
        vues (
          first: $first
          offset: $offset
          search: $search
          vueType: "MV"
          parentVue_Isnull: true
        ) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  query,
);

const getOptionLabel = option => option.name;
const nodeCollector = (viewer) => {
  if (viewer && viewer.vues && viewer.vues.edges.length > 0) {
    return viewer.vues.edges.map(edge => edge.node);
  }
  return [];
};

export default function VueLoader(props) {
  return (
    <FieldLoader
      loaderQuery={query}
      refetchContainer={container}
      getOptionLabel={getOptionLabel}
      nodeCollector={nodeCollector}
      {...props}
    />
  );
}
