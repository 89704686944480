/**
 * @flow
 * @relayHash 3f8855efe5fe00ab1d05ce057fd1dc3a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantTreeViewQueryVariables = {||};
export type TenantTreeViewQueryResponse = {|
  +tenants: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +parent: ?{|
          +id: string
        |},
      |}
    |}>
  |}
|};
export type TenantTreeViewQuery = {|
  variables: TenantTreeViewQueryVariables,
  response: TenantTreeViewQueryResponse,
|};
*/


/*
query TenantTreeViewQuery {
  tenants {
    edges {
      node {
        id
        name
        parent {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "tenants",
    "storageKey": null,
    "args": null,
    "concreteType": "TenantNodeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "TenantNodeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "parent",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantNode",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TenantTreeViewQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TenantTreeViewQuery",
    "argumentDefinitions": [],
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TenantTreeViewQuery",
    "id": null,
    "text": "query TenantTreeViewQuery {\n  tenants {\n    edges {\n      node {\n        id\n        name\n        parent {\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4c2394330bd67315d6d3e77919a57eb';
module.exports = node;
