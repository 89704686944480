/**
 * @flow
 * @relayHash 7e6ee73ce6d068a6639829f1c45dc894
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DividendDefInput = {|
  exDate: any,
  payDate: any,
  rateFranked?: ?number,
  frankingTaxRate?: ?number,
  dividendAmount?: ?number,
  currency?: ?string,
|};
export type BrokerActivityGroupCreateMutationVariables = {|
  description: string,
  brokerActivityIds: $ReadOnlyArray<?string>,
  dividendDef?: ?DividendDefInput,
|};
export type BrokerActivityGroupCreateMutationResponse = {|
  +createBrokerActivityGroup: ?{|
    +brokerActivityGroup: ?{|
      +id: string,
      +description: ?string,
      +brokeractivitySet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +group: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type BrokerActivityGroupCreateMutation = {|
  variables: BrokerActivityGroupCreateMutationVariables,
  response: BrokerActivityGroupCreateMutationResponse,
|};
*/


/*
mutation BrokerActivityGroupCreateMutation(
  $description: String!
  $brokerActivityIds: [ID]!
  $dividendDef: DividendDefInput
) {
  createBrokerActivityGroup(description: $description, brokerActivityIds: $brokerActivityIds, dividendDef: $dividendDef) {
    brokerActivityGroup {
      id
      description
      brokeractivitySet {
        edges {
          node {
            id
            group {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerActivityIds",
    "type": "[ID]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dividendDef",
    "type": "DividendDefInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createBrokerActivityGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerActivityIds",
        "variableName": "brokerActivityIds"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "dividendDef",
        "variableName": "dividendDef"
      }
    ],
    "concreteType": "CreateBrokerActivityGroup",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerActivityGroup",
        "storageKey": null,
        "args": null,
        "concreteType": "BrokerActivityGroupNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokeractivitySet",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerActivityNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "group",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityGroupNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityGroupCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityGroupCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityGroupCreateMutation",
    "id": null,
    "text": "mutation BrokerActivityGroupCreateMutation(\n  $description: String!\n  $brokerActivityIds: [ID]!\n  $dividendDef: DividendDefInput\n) {\n  createBrokerActivityGroup(description: $description, brokerActivityIds: $brokerActivityIds, dividendDef: $dividendDef) {\n    brokerActivityGroup {\n      id\n      description\n      brokeractivitySet {\n        edges {\n          node {\n            id\n            group {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '881b26d215928d1e919980b400d220a5';
module.exports = node;
