/**
 * @flow
 * @relayHash 744d0518332620557ade00ed41a4d3cd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DailyHoldingsList_viewer$ref = any;
export type DailyHoldingsListQueryVariables = {|
  date: any,
  contractId?: ?string,
  ibContractId?: ?number,
|};
export type DailyHoldingsListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: DailyHoldingsList_viewer$ref
  |}
|};
export type DailyHoldingsListQuery = {|
  variables: DailyHoldingsListQueryVariables,
  response: DailyHoldingsListQueryResponse,
|};
*/


/*
query DailyHoldingsListQuery(
  $date: Date!
  $contractId: ID
  $ibContractId: Int
) {
  viewer {
    ...DailyHoldingsList_viewer_1buciV
    id
  }
}

fragment DailyHoldingsList_viewer_1buciV on Query {
  contractHoldings(date: $date, contractId: $contractId, ibContractId: $ibContractId) {
    holdings {
      account {
        name
        id
      }
      portfolio {
        id
      }
      contract {
        id
      }
      quantity
    }
    contract {
      id
      symbol
      currency
      name
      ibConId
    }
    latestContract {
      id
      symbol
      currency
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "date",
    "type": "Date!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "ibContractId",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractId",
    "variableName": "contractId"
  },
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  },
  {
    "kind": "Variable",
    "name": "ibContractId",
    "variableName": "ibContractId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DailyHoldingsListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DailyHoldingsList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DailyHoldingsListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "contractHoldings",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "ContractAccountPosition",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "holdings",
                "storageKey": null,
                "args": null,
                "concreteType": "EndOfDayAccountPosition",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "account",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "portfolio",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PortfolioNode",
                    "plural": false,
                    "selections": (v4/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "contract",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ContractNode",
                    "plural": false,
                    "selections": (v4/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quantity",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "contract",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "ibConId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "latestContract",
                "storageKey": null,
                "args": null,
                "concreteType": "ContractNode",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DailyHoldingsListQuery",
    "id": null,
    "text": "query DailyHoldingsListQuery(\n  $date: Date!\n  $contractId: ID\n  $ibContractId: Int\n) {\n  viewer {\n    ...DailyHoldingsList_viewer_1buciV\n    id\n  }\n}\n\nfragment DailyHoldingsList_viewer_1buciV on Query {\n  contractHoldings(date: $date, contractId: $contractId, ibContractId: $ibContractId) {\n    holdings {\n      account {\n        name\n        id\n      }\n      portfolio {\n        id\n      }\n      contract {\n        id\n      }\n      quantity\n    }\n    contract {\n      id\n      symbol\n      currency\n      name\n      ibConId\n    }\n    latestContract {\n      id\n      symbol\n      currency\n      name\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '77655208756374c7b4446db0416ce963';
module.exports = node;
