/**
 * @flow
 * @relayHash 591e93ac2ef98f6c2c0c2dd5af0cb74b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type PositionTransferUpdateFormQueryVariables = {|
  transferRequestId: string
|};
export type PositionTransferUpdateFormQueryResponse = {|
  +viewer: ?{|
    +positionTransferRequests: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +createdAt: any,
          +updatedAt: any,
          +ibTransfer: ?{|
            +reportDate: any,
            +currency: ?Currency,
          |},
          +positionTransferPurchases: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +createdAt: any,
                +updatedAt: any,
                +reportDate: ?any,
                +quantity: number,
                +contract: {|
                  +id: string,
                  +name: ?string,
                  +symbol: string,
                  +currency: Currency,
                |},
                +costBasis: ?number,
                +commission: ?number,
                +dummy: boolean,
              |}
            |}>
          |},
        |}
      |}>
    |}
  |}
|};
export type PositionTransferUpdateFormQuery = {|
  variables: PositionTransferUpdateFormQueryVariables,
  response: PositionTransferUpdateFormQueryResponse,
|};
*/


/*
query PositionTransferUpdateFormQuery(
  $transferRequestId: ID!
) {
  viewer {
    positionTransferRequests(id: $transferRequestId) {
      edges {
        node {
          id
          createdAt
          updatedAt
          ibTransfer {
            reportDate
            currency
            id
          }
          positionTransferPurchases {
            edges {
              node {
                id
                createdAt
                updatedAt
                reportDate
                quantity
                contract {
                  id
                  name
                  symbol
                  currency
                }
                costBasis
                commission
                dummy
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferRequestId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "transferRequestId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "reportDate",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "positionTransferPurchases",
  "storageKey": null,
  "args": null,
  "concreteType": "PositionTransferPurchaseNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "PositionTransferPurchaseNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "PositionTransferPurchaseNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "contract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "symbol",
                  "args": null,
                  "storageKey": null
                },
                (v6/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "costBasis",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "commission",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "dummy",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PositionTransferUpdateFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferRequests",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "PositionTransferRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferRequestNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibTransfer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBTransferNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PositionTransferUpdateFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "positionTransferRequests",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "PositionTransferRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "PositionTransferRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PositionTransferRequestNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibTransfer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBTransferNode",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PositionTransferUpdateFormQuery",
    "id": null,
    "text": "query PositionTransferUpdateFormQuery(\n  $transferRequestId: ID!\n) {\n  viewer {\n    positionTransferRequests(id: $transferRequestId) {\n      edges {\n        node {\n          id\n          createdAt\n          updatedAt\n          ibTransfer {\n            reportDate\n            currency\n            id\n          }\n          positionTransferPurchases {\n            edges {\n              node {\n                id\n                createdAt\n                updatedAt\n                reportDate\n                quantity\n                contract {\n                  id\n                  name\n                  symbol\n                  currency\n                }\n                costBasis\n                commission\n                dummy\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04248f926eb6732068e8344bf67b9e99';
module.exports = node;
