import React from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Decimal } from 'decimal.js';
import { TableHead } from '@material-ui/core';
import { commitMutation } from 'react-relay';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import { FileDropZone } from 'mui-dropzone';
import { useCommonStyles } from '../../common/Styles';
import '../../common/FileUpload.css';
import notiStack from '../../../common/notiStack';
import BottomGridContainer from '../../common/containers/BottomGridContainer';
import BaseButton, { ProcessButton } from '../../common/buttons/BaseButton';
import environment from '../../../Environment';
import processBrokerActivityMutation from '../../../mutations/ProcessBrokerActivityMutation';
import VerticalTable from '../../common/VerticalTable';
import TableFieldMeta from '../../../common/TableFieldMeta';
import { fromGlobalId, toGlobalId, toLocal } from '../../../helpers/ID';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerSubGroupHeading from '../../common/drawer/DrawerSubGroupHeading';
import SelectDividendAccrualForm from './SelectDividendForm';
import setResponsibleContract from '../../../mutations/SetResponsibleContract';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import { getColumnIndices } from '../../../common/csv';
import PopupContractSelectorField from '../../common/PopupContractSelectorField';
import DrawerGridItemDateField from '../../common/drawer/DrawerGridItemDateField';
import DrawerGridItemCheckBox from '../../common/drawer/DrawerGridItemCheckBox';
import { CSV_MIME_TYPES } from '../../../common/constants';
import DrawerGridItemNumberField from '../../common/drawer/DrawerGridItemNumberField';
import { doCommitPromise } from '../../../common/commit';
import PopupAccountSelectorField from '../../common/PopupAccountSelectorField';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import DrawerGridItemSubGroupHeading from '../../common/drawer/DrawerGridItemSubGroupHeading';
import DrawerSubGroupDivider from '../../common/drawer/DrawerSubGroupDivider';
import Console from '../../../common/log';


function ProcessForm(props) {
  const {
    brokerActivity,
    onClose,
    showResponsibleContract,
    showSetAccrual,
    showValidateHoldings,
    showForceNoSiblings,
    showHoldingsDate,
    brokerActivityCashDeltaVarName,
    checkQuantitySum,
    heading,
    nodeName,
    showToAccount,
    showSibling,
  } = props;
  const classes = useCommonStyles();
  const [entries, setEntries] = React.useState(null);
  const [match, setMatched] = React.useState(false);
  const [selectAccrual, setSelectAccrual] = React.useState(false);
  const [accrual, setAccrual] = React.useState(null);
  const [exDate, setExDate] = React.useState(null);
  const [payDate, setPayDate] = React.useState(null);
  const [holdingsDate, setHoldingsDate] = React.useState(null);
  const [validateHolding, setValidateHolding] = React.useState(true);
  const [forceNoSibling, setForceNoSibling] = React.useState(false);
  const [ucVar] = React.useState({
    siblingBrokerActivityId: null,
    reverseWithBrokerActivityId: null,
    toAccount: null,
  });

  const { responsiblecontract: responsibleContract } = brokerActivity.brokeractivityPtr;

  const processFiles = (files) => {
    files.map(file => Papa.parse(file, {
      complete: (results) => {
        setMatched(false);

        if (results.errors.length > 0) {
          results.errors.map(error => notiStack.error(error));
          return;
        }

        const [
          accountIndex,
          cashMoveIndex,
          currencyIndex,
          portfolioIndex,
          quantityIndex,
          idIndex,
          responsibleQuantityIndex,
        ] = getColumnIndices(results, [
          [['AccountID', 'Account'], true],
          [['Cash'], true],
          [['Currency'], false],
          [['PortfolioID', 'Portfolio'], true],
          [['Quantity'], false],
          [['ID', 'BrokerActivity', 'BrokerActivityID'], false],
          [['ResponsibleQuantity'], false, 'may be required if accrual not available/specified'],
          [['BrokerActivity'], false],
        ]);

        if (accountIndex === -1 || cashMoveIndex === -1) {
          return;
        }
        let newEntries = results.data.slice(1);
        if (idIndex !== -1) {
          const baId = fromGlobalId(brokerActivity.id)[1];
          newEntries = newEntries.filter(row => row[idIndex] === baId);
        }

        newEntries = newEntries.map((row) => {
          let portfolioId = null;
          let responsibleHoldingQty = null;
          if (portfolioIndex !== -1) {
            portfolioId = toGlobalId('PortfolioNode', parseFloat(row[portfolioIndex]));
          }

          if (responsibleQuantityIndex !== -1) {
            responsibleHoldingQty = parseFloat(row[responsibleQuantityIndex]);
          }
          const holdingQty = quantityIndex !== -1 ? parseFloat(row[quantityIndex]) : 0.0;
          return {
            accountId: toGlobalId('AccountNode', parseFloat(row[accountIndex])),
            cashQty: `${parseFloat(row[cashMoveIndex])}`,
            holdingQty: `${holdingQty}`,
            currency: currencyIndex !== -1 ? row[currencyIndex] : brokerActivity.currency,
            portfolioId,
            responsibleHoldingQty,
          };
        });

        const cashSum = newEntries.reduce(
          (v, c) => v.plus(new Decimal(c.cashQty)),
          new Decimal(0),
        );
        const qtySum = newEntries.reduce(
          (v, c) => v.plus(new Decimal(c.holdingQty)),
          new Decimal(0),
        );

        setEntries(newEntries);

        if (!cashSum.equals(new Decimal(brokerActivity[brokerActivityCashDeltaVarName]))) {
          notiStack.error(`Proceeds does not match. input: ${cashSum} ca: ${brokerActivity.proceeds}`);
          return;
        }

        if (checkQuantitySum) {
          if (!qtySum.equals(new Decimal(brokerActivity.quantity))) {
            notiStack.error(`Quantity does not match. input: ${qtySum} ca: ${brokerActivity.quantity}`);
            return;
          }
        }
        setMatched(true);
      },
    }));
  };


  const getWorkerPromise = () => {
    let filteredEntries = null;
    if (entries) {
      filteredEntries = entries.map((e) => {
        const { responsibleHoldingQty, ...otherFields } = e;
        return otherFields;
      });
    }

    let responsibleHoldingsCreated = null;
    if (entries) {
      responsibleHoldingsCreated = entries.map((e) => {
        const { accountId, portfolioId, responsibleHoldingQty } = e;
        return {
          accountId,
          portfolioId,
          holdingQty: responsibleHoldingQty === null ? null : `${responsibleHoldingQty}`,
        };
      }).filter(e => e.holdingQty != null);
    }

    const variables = {
      brokerActivityId: brokerActivity.id,
      copyFromBrokerActivityId: accrual ? accrual.id : null,
      responsibleHoldings: responsibleHoldingsCreated || [],
      selectiveDistribution: filteredEntries,
      exDate,
      payDate,
      validateHolding,
      holdingsDate,
      siblingBrokerActivityId: ucVar.siblingBrokerActivityId,
      reverseWithBrokerActivityId: ucVar.reverseWithBrokerActivityId,
      toAccountId: ucVar.toAccount ? ucVar.toAccount.id : null,
      forceNoSibling,
    };

    return doCommitPromise(
      processBrokerActivityMutation,
      variables,
      (response) => {
        if (!response.processBrokerActivity) {
          notiStack.error('Error. Check console for details');
          Console.error('Error: ', response);
        } else if (response.processBrokerActivity.errors.length > 0) {
          notiStack.error((
            <React.Fragment>
              {response.processBrokerActivity.errors.map((error, idx) => (
                <span key={error.field}>
                  {error.field !== '__all__' && <b>{error.field}</b>}
                  <ul>
                    {error.messages.map(message => (
                      <li key={message}>
                        {message}
                        &nbsp;
                      </li>
                    ))}
                  </ul>
                </span>
              ))}
            </React.Fragment>
          ));
        } else {
          notiStack.success('Processed broker activity');
          onClose && onClose();
        }
      },
      () => null,
    );
  };

  const doSetResponsibleContract = (contract) => {
    commitMutation(environment, {
      mutation: setResponsibleContract,
      variables: {
        contractId: contract ? contract.id : null,
        brokerActivityId: brokerActivity.brokeractivityPtr.id,
      },
    });
  };

  const renderSelectiveDistributionEntries = () => {
    const cashSum = entries.reduce(
      (v, c) => (c.cashQty !== null ? v.plus(new Decimal(c.cashQty)) : null),
      new Decimal(0),
    );
    const qtySum = entries.reduce(
      (v, c) => v.plus(new Decimal(c.holdingQty)),
      new Decimal(0),
    );

    return (
      <div className={classes.tableHolderRoot}>
        <Table
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Cash</TableCell>
              <TableCell>Portfolio</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Responsible Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              entries.map(entry => (
                <TableRow>
                  <TableCell>
                    <Link href={`/account/${fromGlobalId(entry.accountId)[1]}`}>
                      {fromGlobalId(entry.accountId)[1]}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {entry.currency}
                  </TableCell>
                  <TableCell>
                    {entry.cashQty}
                  </TableCell>
                  <TableCell>
                    {entry.portfolioId && fromGlobalId(entry.portfolioId)[1]}
                  </TableCell>
                  <TableCell>
                    {qtySum && entry.holdingQty}
                  </TableCell>
                  <TableCell>
                    {entry.responsibleHoldingQty}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>{cashSum.toString()}</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>{qtySum && qtySum.toString()}</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>

        </Table>
      </div>
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Drawer open={selectAccrual} anchor="right" onClose={() => setSelectAccrual(false)}>
        <SelectDividendAccrualForm
          ibConId={brokerActivity.ibConId}
          onClose={() => setSelectAccrual(false)}
          onRowSelect={(row, s, a) => {
            setAccrual(row);
            setSelectAccrual(false);
          }}
        />
      </Drawer>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading={heading}
        />
        <Grid item xs={12}>
          <VerticalTable
            columnMeta={[
              ['ID', TableFieldMeta.localId.accessor],
              ['Description', 'description'],
              ['Symbol', 'symbol'],
              ['Currency', 'currency'],
              ['Proceeds', brokerActivityCashDeltaVarName],
              ['Quantity', 'quantity'],
              ['Report Date', 'reportDate'],
            ]}
            dataNode={brokerActivity}
            showHeadings={false}
            valueAlignment="left"
          />
        </Grid>

        {/*Select Responsible Contract*/}
        {showResponsibleContract && (
          <Grid item xs={12}>
            <PopupContractSelectorField
              id="standard-helperText"
              label="Contract Search"
              caption="Responsible Contract"
              defaultValue={responsibleContract ? responsibleContract.contract : null}
              margin="normal"
              fullWidth
              onSelect={(inContact) => {
                doSetResponsibleContract(inContact);
              }}
            />
          </Grid>
        )
        }

        {
          accrual && (
            <DrawerGridItemTextField
              xs={12}
              sm={12}
              disabled
              label="Source Accrual"
              defaultValue={accrual && accrual.description}
              helperText={accrual && toLocal(accrual.id)}
            />
          )
        }

        {/*Select Accrual*/}
        {
          showSetAccrual && (
            <Grid item xs={12} justify="flex-end">
              <BottomGridContainer>
                <Grid item>
                  <BaseButton
                    size="small"
                    buttonText={accrual ? 'Change Accrual' : 'Select Accrual'}
                    onClick={() => setSelectAccrual(!selectAccrual)}
                  />
                </Grid>
              </BottomGridContainer>
            </Grid>

          )
        }

        {/*Ex date*/}
        {
          showSetAccrual && (
            <DrawerGridItemDateField
              label="Ex Date"
              onChange={newExDate => setExDate(newExDate)}
              value={exDate}
              autoOk
            />
          )
        }

        {/*Pay date*/}
        {
          showSetAccrual && (
            <DrawerGridItemDateField
              label="Pay Date"
              onChange={newPayDate => setPayDate(newPayDate)}
              value={payDate}
              autoOk
            />
          )
        }

        {/*Pay date*/}
        {
          showHoldingsDate && (
            <DrawerGridItemDateField
              xs={6}
              label="Holdings Date"
              onChange={newDate => setHoldingsDate(newDate)}
              value={holdingsDate}
              autoOk
            />
          )
        }

        {/*Pay date*/}
        {
          showValidateHoldings && (
            <DrawerGridItemCheckBox
              label="Validate Holding"
              onChange={e => setValidateHolding(e.target.checked)}
              value={validateHolding}
            />
          )
        }

        {/*Force no siblings*/}
        {
          showForceNoSiblings && (
            <DrawerGridItemCheckBox
              label="Force no siblings"
              onChange={e => setForceNoSibling(e.target.checked)}
              value={forceNoSibling}
            />
          )
        }

        {
          showSibling && (
            <DrawerGridItemNumberField
              label="Sibling ID"
              onChange={(e) => {
                ucVar.siblingBrokerActivityId = toGlobalId(nodeName, e.target.value);
              }}
            />
          )
        }


        <Grid item xs={12}>
          <DrawerSubGroupHeading>
            Selective Distribution
          </DrawerSubGroupHeading>
          <FileDropZone
            acceptedMimeTypes={CSV_MIME_TYPES}
            onFilesAdded={files => processFiles(files)}
            onFilesRejected={() => null}
            elevation={2}
            dragOverElevation={10}
            className="file-drop-zone"
          >
            My Text
          </FileDropZone>
        </Grid>
        <Grid item xs={12}>
          {entries && renderSelectiveDistributionEntries()}
        </Grid>


        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ProcessButton
              disabled={entries !== null && !match}
              // onClick={() => doProcess()}
              onClickPromise={getWorkerPromise}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>


        {/*Show To Account*/}
        {
          showToAccount && (
            <>
              <DrawerSubGroupDivider />
              <DrawerGridItemSubGroupHeading heading="Assign to Account" />
              <Grid item xs={12}>
                <PopupAccountSelectorField
                  fullWidth
                  onSelect={(account) => { ucVar.toAccount = account; }}
                />
              </Grid>
              <DrawerGridItemBottomButtonContainer>
                <Grid item>
                  <ProcessButton
                    buttonText="Process To Account"
                    onClickPromise={() => {
                      if (ucVar.toAccount == null) {
                        notiStack.warning('Account to Process into is not specified');
                        return null;
                      }
                      return getWorkerPromise();
                    }}
                  />
                </Grid>
              </DrawerGridItemBottomButtonContainer>
            </>
          )
        }


        {/*Reverse*/}
        <DrawerSubGroupDivider />
        <DrawerGridItemSubGroupHeading heading="Reverse" />
        <DrawerGridItemNumberField
          label="Reverse With"
          onChange={(e) => {
            ucVar.reverseWithBrokerActivityId = toGlobalId(nodeName, e.target.value);
          }}
        />

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ProcessButton
              disabled={entries !== null && !match}
              onClickPromise={() => {
                if (ucVar.reverseWithBrokerActivityId == null) {
                  notiStack.warning('Broker Activity to Reverse not specified');
                  return null;
                }
                return getWorkerPromise();
              }}
              buttonText="Reverse"
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </Grid>
    </div>
  );
}

ProcessForm.propTypes = {
  showResponsibleContract: PropTypes.bool,
  showSetAccrual: PropTypes.bool,
  showForceNoSiblings: PropTypes.bool,
  brokerActivityCashDeltaVarName: PropTypes.string,
  checkQuantitySum: PropTypes.bool,
  showToAccount: PropTypes.bool,
  showSibling: PropTypes.bool,
  showValidateHoldings: PropTypes.bool,
  showHoldingsDate: PropTypes.bool,
};

ProcessForm.defaultProps = {
  showResponsibleContract: false,
  showSetAccrual: false,
  showValidateHoldings: false,
  showForceNoSiblings: false,
  showSibling: true,
  brokerActivityCashDeltaVarName: 'proceeds',
  checkQuantitySum: true,
  showToAccount: false,
  showHoldingsDate: false,
};

export default ProcessForm;
