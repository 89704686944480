import * as React from 'react';
import { graphql } from 'react-relay';
import TitledWidgetItem from '../../common/TitledWidgetItem';
import VerticalTable from '../../common/VerticalTable';
import DateTime from '../../common/DateTimeMoment';
import TableFieldMeta from '../../../common/TableFieldMeta';

const getTwapColumns = offsetLabel => [
  ['Strategy Type', 'strategyType'],
  [`Start Time ${offsetLabel}`, node => <DateTime utcOffset={node.utcOffset}>{node.startTime}</DateTime>],
  [`End Time ${offsetLabel}`, node => <DateTime utcOffset={node.utcOffset}>{node.endTime}</DateTime>],
  ['Allow Past End Times', TableFieldMeta.boolViewer('allowPastEndTime')],
];

const getVwapColumns = offsetLabel => [
  ['Max Volume Ratio', 'maxVolumeRatio'],
  [`Start Time ${offsetLabel}`, node => <DateTime utcOffset={node.utcOffset}>{node.startTime}</DateTime>],
  [`End Time ${offsetLabel}`, node => <DateTime utcOffset={node.utcOffset}>{node.endTime}</DateTime>],
  ['Allow Past End Time', TableFieldMeta.boolViewer('allowPastEndTime')],
  ['Never Take Liquidity', TableFieldMeta.boolViewer('neverTakeLiquidity')],
  ['Speedup ', TableFieldMeta.boolViewer('speedup')],
];

function StockOrderAlgorithm(props) {
  const { brokerOrder, exchange } = props;
  if (!brokerOrder.algoParams || !exchange) {
    return null;
  }

  const utcOffset = getUTCOffset(exchange.nextDaySession.startTime, false);
  const rawUtcOffset = getUTCOffset(exchange.nextDaySession.startTime);

  const offsetLabel = `(UTC${rawUtcOffset})`;

  let columns = [];
  let dataNode = null;
  if (brokerOrder.algoParams.algo === 'TWAP') {
    columns = getTwapColumns(offsetLabel);
    dataNode = { ...(brokerOrder.algoParams.orderalgoparamstwap || {}), utcOffset };
  } else if (brokerOrder.algoParams.algo === 'VWAP') {
    columns = getVwapColumns(offsetLabel);
    dataNode = { ...(brokerOrder.algoParams.orderalgoparamsvwap || {}), utcOffset };
  }

  return (
    <TitledWidgetItem
      xs={12}
      title={`Algorithm: ${brokerOrder.algoParams.algo} (${exchange.symbol})`}
    >
      <VerticalTable
        showHeadings={false}
        columnMeta={columns}
        dataNode={dataNode}
      />
    </TitledWidgetItem>
  );
}

const getUTCOffset = (isoDate = '', raw = true) => {
  const offsetPattern = /([+-]\d{2}|Z):?(\d{2})?\s*$/;

  const result = offsetPattern.exec(isoDate);

  if (result) {
    const utcHour = +result[1] || 0;
    const sign = utcHour >= 0 ? '+' : '-';
    const paddedHour = String(Math.abs(utcHour)).padStart(2, '0');

    return `${sign}${paddedHour}${raw ? ':' : ''}${result[2] || 0}`;
  }

  return '00:00';
};

graphql`
  fragment StockOrderAlgorithm_all on BrokerOrderNode {
    algoParams {
      algo
      orderalgoparamstwap {
        strategyType
        startTime
        endTime
        allowPastEndTime
      }

      orderalgoparamsvwap {
        id
        maxVolumeRatio
        startTime
        endTime
        allowPastEndTime
        neverTakeLiquidity
        speedup
      }
    }
  }
`;

export default StockOrderAlgorithm;
