import React from 'react';
import { withRouter } from 'react-router-dom';
import ContractSearch from '../common/dialogs/ContractSearch';

function ContractList(props) {
  return (
    <ContractSearch />
  );
}

export default withRouter(ContractList);
