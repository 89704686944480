import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import TableFieldMeta from '../../../common/TableFieldMeta';
import TableButton from '../buttons/TableButton';
import PaginatedTable from '../PaginatedTable';
import { useCommonStyles } from '../Styles';
import { createQueryRendererProgress } from '../QueryRendererProgress';
import RelayFilter from '../../../common/RelayFilter';

function UserSearch(props) {
  const {
    relay,
    viewer,
    onSelectItems,
    closeOnSingleSelect,
  } = props;

  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
    symbol: null,
    conId: null,
    isin: null,
  }, ['search', 'symbol', 'isin']));

  const classes = useCommonStyles();

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('firstName', 'First Name'),
    TableFieldMeta.field('lastName', 'Last Name'),
    TableFieldMeta.field('email', 'email'),
    TableFieldMeta.field(node => (node.adviser && node.adviser.parentTenant.name), 'Adviser for'),
    {
      Header: '',
      id: 'action',
      accessor: node => node,
      Cell: row => (
        <TableButton
          key={row.value.id}
          color="primary"
          onClick={() => {
            if (onSelectItems) {
              onSelectItems([row.value]);
            }
          }}
        >
          Select
        </TableButton>
      ),
    },
  ];

  if (viewer && viewer.authUsers && viewer.authUsers.edges.length === 1 && closeOnSingleSelect) {
    if (onSelectItems) {
      onSelectItems([viewer.authUsers.edges[0].node]);
    }
  }

  return (
    <div>
      <div className={classes.formControlContainer}>
        <Grid container spacing={2}>
          <Grid item>
            {/* Search */}
            <TextField
              id="standard-helperText"
              label="Custom Search"
              defaultValue=""
              className={classes.formControl}
              helperText="symbol, name"
              margin="normal"
              onChange={e => relayFilter.updateVar('search', e.target.value)}
            />
          </Grid>
        </Grid>
      </div>

      <PaginatedTable
        columns={columns}
        ref={relayFilter.tableRef}
        viewer={viewer}
        getDataNode={() => (viewer ? viewer.authUsers : null)}
        getFetchParams={relayFilter.fetchParams}
        relay={relay}
      />
    </div>
  );
}


const query = graphql`
  query UserSearchQuery (
    $first: Int
    $offset: Int
    $search: String
  ) {
    viewer {
      ...UserSearch_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
      )
    }
  }
`;

const container = createRefetchContainer(
  UserSearch,
  {
    viewer: graphql`
      fragment UserSearch_viewer on Query      
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
      ) {
        authUsers(
          first: $first
          offset: $offset
          search: $search
        ) {
          edges {
            node {
              id
              fullName
              email
              firstName
              lastName
              adviser {
                id
                parentTenant {
                  id
                  name
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

UserSearch.propTypes = {
  closeOnSingleSelect: PropTypes.bool,
};

UserSearch.defaultProps = {
  closeOnSingleSelect: false,
};

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { onSelectItems, closeOnSingleSelect }) => ({ onSelectItems, closeOnSingleSelect }),
);
