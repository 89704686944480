/**
 * @flow
 * @relayHash e15e28068bce72e61dd1eaaadeb14dcc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountSearch_viewer$ref = any;
export type AccountSearchQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  accountId?: ?string,
|};
export type AccountSearchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AccountSearch_viewer$ref
  |}
|};
export type AccountSearchQuery = {|
  variables: AccountSearchQueryVariables,
  response: AccountSearchQueryResponse,
|};
*/


/*
query AccountSearchQuery(
  $first: Int
  $offset: Int
  $search: String
  $accountId: ID
) {
  viewer {
    ...AccountSearch_viewer_13Yiz5
    id
  }
}

fragment AccountSearch_viewer_13Yiz5 on Query {
  accounts(first: $first, offset: $offset, search: $search, id: $accountId) {
    edges {
      node {
        id
        name
        clientaccount {
          id
          userModels {
            edges {
              node {
                id
                email
              }
            }
          }
        }
        tenantaccount {
          id
          tenant {
            id
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AccountSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "AccountSearch_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountSearchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "accountId"
              },
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "clientaccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ClientAccountNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "userModels",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserModelNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserModelNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserModelNode",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "email",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenantaccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantAccountNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "tenant",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TenantNode",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "AccountSearchQuery",
    "id": null,
    "text": "query AccountSearchQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $accountId: ID\n) {\n  viewer {\n    ...AccountSearch_viewer_13Yiz5\n    id\n  }\n}\n\nfragment AccountSearch_viewer_13Yiz5 on Query {\n  accounts(first: $first, offset: $offset, search: $search, id: $accountId) {\n    edges {\n      node {\n        id\n        name\n        clientaccount {\n          id\n          userModels {\n            edges {\n              node {\n                id\n                email\n              }\n            }\n          }\n        }\n        tenantaccount {\n          id\n          tenant {\n            id\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ed8d13764696163cbd3856b1fc7c5c1';
module.exports = node;
