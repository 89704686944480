/**
 * @flow
 * @relayHash 24de75db8a80b3ab34438f24e61d94fc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type PerformanceType = "CA_SELL_TRANSACTION" | "CONTRACT_CHANGE" | "DUMMY_NOOP" | "SAME_CONTRACT_SPLIT" | "SHARE_PURCHASE_PLAN" | "%future added value";
export type AutoProcessBrokerActivityPerformanceMutationVariables = {|
  performanceType: PerformanceType,
  brokerActivityGroupId: string,
|};
export type AutoProcessBrokerActivityPerformanceMutationResponse = {|
  +autoProcessBrokerActivityPerformance: ?{|
    +brokerActivities: ?$ReadOnlyArray<?{|
      +brokeractivitytransactionSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +brokeractivitytransactionperformanceSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +sequence: number,
                  +portfolio: {|
                    +id: string,
                    +trackingVueName: ?string,
                  |},
                  +contract: {|
                    +id: string,
                    +symbol: string,
                  |},
                  +costBasis: ?number,
                  +commission: number,
                  +updatedAt: any,
                  +currency: Currency,
                  +price: number,
                  +quantity: number,
                  +splitFactor: ?number,
                  +splitResult: ?number,
                  +oldContract: ?{|
                    +id: string,
                    +symbol: string,
                  |},
                  +newContract: ?{|
                    +id: string,
                    +symbol: string,
                  |},
                  +spinoffContract: ?{|
                    +id: string,
                    +symbol: string,
                  |},
                  +dummied: boolean,
                |}
              |}>
            |}
          |}
        |}>
      |}
    |}>
  |}
|};
export type AutoProcessBrokerActivityPerformanceMutation = {|
  variables: AutoProcessBrokerActivityPerformanceMutationVariables,
  response: AutoProcessBrokerActivityPerformanceMutationResponse,
|};
*/


/*
mutation AutoProcessBrokerActivityPerformanceMutation(
  $performanceType: PerformanceType!
  $brokerActivityGroupId: ID!
) {
  autoProcessBrokerActivityPerformance(performanceType: $performanceType, brokerActivityGroupId: $brokerActivityGroupId) {
    brokerActivities {
      brokeractivitytransactionSet {
        edges {
          node {
            brokeractivitytransactionperformanceSet {
              edges {
                node {
                  id
                  sequence
                  portfolio {
                    id
                    trackingVueName
                  }
                  contract {
                    id
                    symbol
                  }
                  costBasis
                  commission
                  updatedAt
                  currency
                  price
                  quantity
                  splitFactor
                  splitResult
                  oldContract {
                    id
                    symbol
                  }
                  newContract {
                    id
                    symbol
                  }
                  spinoffContract {
                    id
                    symbol
                  }
                  dummied
                }
              }
            }
            id
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "performanceType",
    "type": "PerformanceType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerActivityGroupId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "brokerActivityGroupId",
    "variableName": "brokerActivityGroupId"
  },
  {
    "kind": "Variable",
    "name": "performanceType",
    "variableName": "performanceType"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "symbol",
    "args": null,
    "storageKey": null
  }
],
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokeractivitytransactionperformanceSet",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerActivityTransactionPerformanceNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerActivityTransactionPerformanceNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerActivityTransactionPerformanceNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "sequence",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "portfolio",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioNode",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "trackingVueName",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "contract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "costBasis",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "commission",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "updatedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "currency",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "splitFactor",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "splitResult",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "oldContract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "newContract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "spinoffContract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": (v3/*: any*/)
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "dummied",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AutoProcessBrokerActivityPerformanceMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "autoProcessBrokerActivityPerformance",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AutoProcessBrokerActivityPerformance",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerActivities",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerActivityNode",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivitytransactionSet",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityTransactionNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityTransactionNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AutoProcessBrokerActivityPerformanceMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "autoProcessBrokerActivityPerformance",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AutoProcessBrokerActivityPerformance",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokerActivities",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerActivityNode",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "brokeractivitytransactionSet",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityTransactionNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityTransactionNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityTransactionNode",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AutoProcessBrokerActivityPerformanceMutation",
    "id": null,
    "text": "mutation AutoProcessBrokerActivityPerformanceMutation(\n  $performanceType: PerformanceType!\n  $brokerActivityGroupId: ID!\n) {\n  autoProcessBrokerActivityPerformance(performanceType: $performanceType, brokerActivityGroupId: $brokerActivityGroupId) {\n    brokerActivities {\n      brokeractivitytransactionSet {\n        edges {\n          node {\n            brokeractivitytransactionperformanceSet {\n              edges {\n                node {\n                  id\n                  sequence\n                  portfolio {\n                    id\n                    trackingVueName\n                  }\n                  contract {\n                    id\n                    symbol\n                  }\n                  costBasis\n                  commission\n                  updatedAt\n                  currency\n                  price\n                  quantity\n                  splitFactor\n                  splitResult\n                  oldContract {\n                    id\n                    symbol\n                  }\n                  newContract {\n                    id\n                    symbol\n                  }\n                  spinoffContract {\n                    id\n                    symbol\n                  }\n                  dummied\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c79ba97fc9b62034ce529b2a1fba3dda';
module.exports = node;
