/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type OffMarketTransferCostBasisForm_holdingQuantity$ref: FragmentReference;
declare export opaque type OffMarketTransferCostBasisForm_holdingQuantity$fragmentType: OffMarketTransferCostBasisForm_holdingQuantity$ref;
export type OffMarketTransferCostBasisForm_holdingQuantity = {|
  +id: string,
  +quantity: number,
  +portfolio: {|
    +id: string,
    +trackingVueName: ?string,
  |},
  +contract: {|
    +symbol: string,
    +id: string,
    +currency: Currency,
  |},
  +commission: number,
  +costBasis: number,
  +dummy: boolean,
  +$refType: OffMarketTransferCostBasisForm_holdingQuantity$ref,
|};
export type OffMarketTransferCostBasisForm_holdingQuantity$data = OffMarketTransferCostBasisForm_holdingQuantity;
export type OffMarketTransferCostBasisForm_holdingQuantity$key = {
  +$data?: OffMarketTransferCostBasisForm_holdingQuantity$data,
  +$fragmentRefs: OffMarketTransferCostBasisForm_holdingQuantity$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OffMarketTransferCostBasisForm_holdingQuantity",
  "type": "OffMarketTransferHoldingQuantityNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolio",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trackingVueName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "contract",
      "storageKey": null,
      "args": null,
      "concreteType": "ContractNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "symbol",
          "args": null,
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "currency",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "commission",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "costBasis",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dummy",
      "args": null,
      "storageKey": null
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dca75e0bbd7bda24aec7895f5b014d5e';
module.exports = node;
