import { graphql } from 'react-relay';

export const createTenantExchangeFeeTemplate = graphql`
  mutation TenantExchangeFeeTemplateCreateMutation (
    $tenantId: ID!
    $exchangeId: ID!
    $feeTemplateId: ID!
  ) {
    createTenantExchangeFeeTemplate (
      tenantId: $tenantId
      exchangeId: $exchangeId
      feeTemplateId: $feeTemplateId
    ) {
      tenant {
        id
        tenantexchangefeetemplateSet {
          edges {
            node {
              id
              createdAt
              exchange {
                id
                symbol
              }
              tenant {
                id 
                name
              }
              feeTemplate {
                id,
                name
              }
            }
          }
        }
      } 
    }
  }
`;

export const deleteTenantExchangeFeeTemplate = graphql`
    mutation TenantExchangeFeeTemplateDeleteMutation (
    $tenantExchangeFeeTemplateId: ID!
  ) {
    deleteTenantExchangeFeeTemplate(
      tenantExchangeFeeTemplateId: $tenantExchangeFeeTemplateId
    ) {
      tenant {
        id
        tenantexchangefeetemplateSet {
          edges {
            node {
              id
              createdAt
              exchange {
                id
                symbol
              }
              tenant {
                id 
                name
              }
              feeTemplate {
                id,
                name
              }
            }
          }
        }
      } 
    }
  }
`;
