import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AssignmentIcon from '@material-ui/icons/AssignmentTwoTone';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  AccountBoxTwoTone,
  AllInboxTwoTone,
  AttachMoneyTwoTone,
  Brightness6,
  BusinessTwoTone,
  CallMergeTwoTone,
  CompareArrowsTwoTone,
  DragIndicatorTwoTone,
  Lock,
  PeopleTwoTone,
  PublicTwoTone,
  ShoppingBasketTwoTone,
  SlowMotionVideoTwoTone,
  SupervisorAccountTwoTone,
  WidgetsTwoTone,
  CalendarTodayTwoTone,
} from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createFragmentContainer, graphql } from 'react-relay';
import TenantList from './tenant/TenantList';
import { MVA_AUTH_TOKEN_TAG, MVA_LOGGED_IN_USER } from '../common/constants';
import BrokerActivityTabContainer from './brokerIB/BrokerActivityTabContainer';
import TenantTabContainer from './tenant/TenantTabContainer';
import PortfolioOrderDetailsContainer from './order/PortfolioOrderDetailsContainer';
import HoldingsTabContainer from './holdings/HoldingsTabContainer';
import IBIcon from './ibsim/IBIcon';
import AccountDetailsTabContainer from './account/AccountDetailsTabContainer';
import VueDetailsTabContainer from './vue/VueDetailsTabContainer';
import ReconcileTabContainer from './reconcile/ReconcileTabContainer';
import FeeTemplateList from './feeTemplate/FeeTemplateList';
import TransferTabContainer from './transfer/TransferTabContainer';
import UserList from './user/UserList';
import AdviserList from './adviser/AdviserList';
import AccountList from './account/AccountList';
import PermissionContainer from './permission/PermissionContainer';
import StockOrderView from './order/stockOrder/StockOrderView';
import UserDetailsTabContainer from './user/UserDetailsTabContainer';
import OrderTabContainer from './order/OrderTabContainer';
import DrawerItem from './adminPanel/DrawerItem';
import IBSimMenuItem from './adminPanel/IBSimMenuItem';
import { createQueryRendererProgress } from './common/QueryRendererProgress';
import FeeTemplate from './feeTemplate/FeeTemplate';
import ContractDetailsTabContainer from './contract/ContractDetailsTabContainer';
import CronList from './cron/CronList';
import GlobalsTablContainer from './globals/GlobalsTabContainer';
import { permitted } from '../common/helpers';
import ContractTabContainer from './contract/ContractTabContainer';
import BulkOrderView from './order/BulkOrderView';
import AdviserTabContainer from './adviser/AdviserTabContainer';
import ChargesList from './charge/ChargesList';
import VueTypeTabContainer from './vue/VueTypeTabContainer';
import UserSubscription from './user/UserSubscription';
import OrderApproval from './order/OrderApproval';
import RebalanceProposal from './order/RebalanceProposal';
import withSuspense from './common/table/Suspention';
import SSDColoredIcon from '../icons/colored/SSDColoredIcon';
import VersionInfo from './adminPanel/VersionInfo';
import AdminMetaContext from './adminPanel/AdminMetaContext';
import LBGIcon from './brokerLBG/LBGIcon';
import EventsList from './events/EventsList';
import EventDetailsContainer from './events/EventDetailsContainer';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  menuButtonHidden: {
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing(2),
  },
}));

function AdminPanel(props) {
  const {
    history,
    userNode,
    adminMeta,
    onToggleTheme,
  } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [showProfileMenu, setShowProfileMenu] = React.useState(null);
  const isMenuOpen = Boolean(showProfileMenu);
  const classes = useStyles();
  const Badges = withSuspense(React.lazy(
    () => (process.env.DEPLOYMENT_CODE === 'AU'
      ? import('./adminPanel/au/Badges') : import('./adminPanel/uk/Badges')),
  ));
  let ibsimMenuItem = null;
  if (process.env.HIDE_IBSIM !== '1') {
    const IBSimTabContainer = withSuspense(React.lazy(() => import('./ibsim/IBSimTabContainer')));
    ibsimMenuItem = [IBSimTabContainer, IBIcon, 'IBSim', '/ibsim', ['DEV']];
  }

  let menuApplicationSubItems = [];
  let menuSSDSubItems = [];
  let parmLinkApplicationSubItems = [];
  let transferSubItems = [];
  let brokerActivitySubItems = [];
  if (process.env.DEPLOYMENT_CODE === 'AU') {
    const ApplicationTabContainer = withSuspense(React.lazy(() => import('./application/au/ApplicationTabContainer')));
    const SSDTabContainer = withSuspense(React.lazy(() => import('./ssd/SSDTabContainer')));
    const ApplicationDetailsTabContainer = withSuspense(React.lazy(() => import('./application/au/ApplicationDetailsTabContainer')));
    menuApplicationSubItems = [
      [ApplicationTabContainer, AllInboxTwoTone, 'Applications', '/applications', ['APP']],
    ];
    menuSSDSubItems = [
      [SSDTabContainer, SSDColoredIcon, 'SSD', '/share-sales-direct', ['APP']],
    ];
    parmLinkApplicationSubItems = [
      [ApplicationDetailsTabContainer, '/application/:applicationId', ['APP']],
    ];
    brokerActivitySubItems = [
      [BrokerActivityTabContainer, IBIcon, 'IB Activity', '/ib-activity', ['BAT']],
    ];
  } else if (process.env.DEPLOYMENT_CODE === 'UK') {
    const ApplicationTabContainer = withSuspense(React.lazy(() => import('./application/uk/ApplicationTabContainer')));
    const ApplicationDetailsTabContainer = withSuspense(React.lazy(() => import('./application/uk/ApplicationDetailsTabContainer')));
    const TransferMatchPane = withSuspense(React.lazy(() => import('./transfer/uk/TransferMatchPane')));
    const LBGBrokerActivityTabContainer = withSuspense(React.lazy(() => import('./brokerLBG/LBGBrokerActivityTabContainer')));
    menuApplicationSubItems = [
      [EventsList, CalendarTodayTwoTone, 'Events', '/events', ['TEN']],
      [ApplicationTabContainer, AllInboxTwoTone, 'Applications', '/applications', ['APP']],
    ];
    parmLinkApplicationSubItems = [
      [ApplicationDetailsTabContainer, '/application/:applicationId', ['APP']],
      [EventDetailsContainer, '/event/:eventId', ['TEN']],
    ];
    transferSubItems = [[TransferMatchPane, '/transfer/:transferId', ['TRA']]];
    brokerActivitySubItems = [
      [BrokerActivityTabContainer, IBIcon, 'IB Activity', '/ib-activity', ['BAT']],
      [LBGBrokerActivityTabContainer, LBGIcon, 'LBG Activity', '/lbg-activity', ['BAT']],
    ];
  }

  // Child Component, MUI-IconClass, Caption, Link, Hidden
  const menuItemDefs = [
    [UserList, PeopleTwoTone, 'Users', '/users', ['USR']],
    [AdviserList, SupervisorAccountTwoTone, 'Advisers', '/advisers', ['TEN']],
    [AccountList, AccountBoxTwoTone, 'Accounts', '/accounts', ['ACC']],
    [PermissionContainer, Lock, 'Permissions', '/permissions', ['PER']],
    [TransferTabContainer, CompareArrowsTwoTone, 'Transfers', '/transfers', ['TRA']],
    [ChargesList, AttachMoneyTwoTone, 'Charges', '/charges', ['CHR']],
    [TenantList, BusinessTwoTone, 'Tenants', '/tenants', ['TEN']],
    ...menuApplicationSubItems,
    [OrderTabContainer, AssignmentIcon, 'Orders', '/orders', ['ODR']],
    [VueTypeTabContainer, DragIndicatorTwoTone, 'Vues', '/vues', ['VUE']],
    ...brokerActivitySubItems,
    [HoldingsTabContainer, ShoppingBasketTwoTone, 'Holdings', '/holdings', ['BAT']],
    [ReconcileTabContainer, CallMergeTwoTone, 'Reconcile', '/reconcile', ['BAT']],
    [FeeTemplateList, AttachMoneyTwoTone, 'Fee Templates', '/fee-templates', ['FEE']],
    [ContractTabContainer, WidgetsTwoTone, 'Contracts', '/contracts', ['CON']],
    [GlobalsTablContainer, PublicTwoTone, 'Globals', '/globals', null],
    ...menuSSDSubItems,
    ...((adminMeta.isPaper && !!ibsimMenuItem) ? [ibsimMenuItem] : []),
    [CronList, SlowMotionVideoTwoTone, 'Crons', '/crons', ['DEV']],
  ];

  const parameterLinkDefs = [
    [AccountDetailsTabContainer, '/account/:accountId', ['ACC']],
    [TenantTabContainer, '/tenant/:tenantId', ['TEN']],
    [AdviserTabContainer, '/adviser/:adviserId', ['TEN']],
    [PortfolioOrderDetailsContainer, '/order/:portfolioOrderId', ['ODR']],
    [PortfolioOrderDetailsContainer, '/vue-order/:portfolioOrderId', ['ODR']],
    [VueDetailsTabContainer, '/vue/:vueId', ['VUE']],
    [FeeTemplate, '/fee-template/:feeTemplateId', ['FEE']],
    [StockOrderView, '/stock-order/:stockOrderId', ['ODR']],
    [UserDetailsTabContainer, '/user/:userId', ['USR']],
    [ContractDetailsTabContainer, '/contract/:contractId', ['CON']],
    [BulkOrderView, '/bulk-oder/:bulkOrderId', ['ODR']],
    [UserSubscription, '/user-subscription/:userSubscriptionId', ['USR']],
    [OrderApproval, '/order-approval/:approvalId', ['ODR']],
    [RebalanceProposal, '/order-proposal/:proposalId', ['ODR']],
    ...transferSubItems,
    ...parmLinkApplicationSubItems,
  ];

  const permissionEdges = userNode.adminpermissionSet.edges;
  const menuItems = permitted(menuItemDefs, permissionEdges, 4);
  const parameterLinks = permitted(parameterLinkDefs, permissionEdges, 2);
  const defaultMenuItem = menuItems.reduce((v, i) => (i[2] === 'Orders' ? i : v), menuItems[0]);

  React.useEffect(() => {
    document.title = `Ops\u2022${adminMeta.systemName}`;
  }, []);

  return (
    <div className={classes.root}>
      <AdminMetaContext.Provider value={adminMeta}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={classNames(classes.appBar, drawerOpen && classes.appBarShift)}
        >

          <Toolbar disableGutters className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={classNames(
                classes.menuButton,
                drawerOpen && classes.menuButtonHidden,
              )}
            >
              {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Halo Trading Operations
            </Typography>

            <Badges />

            <IconButton
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={() => onToggleTheme()}
              color="inherit"
            >
              <Brightness6 />
            </IconButton>

            <IconButton
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={e => setShowProfileMenu(e.currentTarget)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>

          </Toolbar>
        </AppBar>

        {/*Profile Menu*/}
        <Menu
          anchorEl={showProfileMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={isMenuOpen}
          onClose={() => setShowProfileMenu(false)}
        >
          <MenuItem disabled>{userNode.fullName}</MenuItem>
          <Divider />
          <MenuItem onClick={() => {
            setShowProfileMenu(null);
            localStorage.setItem(MVA_AUTH_TOKEN_TAG, null);
            history.push('/login');
          }}
          >
            Logout
          </MenuItem>
        </Menu>


        <Drawer
          variant="persistent"
          classes={{
            paper: classNames(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
          }}
          open={drawerOpen}
        >
          <div className={classes.toolbarIcon}>
            <VersionInfo adminMeta={adminMeta} />
          </div>
          <Divider />
          <List>
            <div>
              {menuItems.map((menuItem) => {
                const iconClass = menuItem[1];
                const caption = menuItem[2];
                const link = menuItem[3];
                return (
                  <DrawerItem
                    iconClass={iconClass}
                    caption={caption}
                    link={link}
                    history={history}
                  />
                );
              })}
              {ibsimMenuItem && (
                <IBSimMenuItem menuItem={ibsimMenuItem} />
              )}

            </div>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Switch>
            {menuItems.map((menuItem) => {
              if (menuItem) {
                const ComponentClass = menuItem[0];
                const caption = menuItem[2];
                const link = menuItem[3];
                return (
                  <Route
                    key={caption}
                    path={link}
                    render={compProps => (
                      <ComponentClass permissionEdges={permissionEdges} {...compProps} />
                    )}
                  />
                );
              }
              return '';
            })}

            {parameterLinks.map((menuItem) => {
              const ComponentClass = menuItem[0];
              const link = menuItem[1];
              return (
                <Route
                  key={link}
                  path={link}
                  render={compProps => (
                    <ComponentClass permissionEdges={permissionEdges} {...compProps} />
                  )}
                />
              );
            })}
            <Redirect to={`${defaultMenuItem[3]}`} />
          </Switch>
        </main>
      </AdminMetaContext.Provider>
    </div>
  );
}


const query = graphql`
   query AdminPanelQuery (
    $userId: ID,
  ) {
    viewer {
      adminMeta {
        isPaper
        systemName
        deploymentCode
        versionInfo {
          commitAt
          commitHash
          commitMessage
          versionTags
          latestVersion
        }
        baseCurrency
      }
      authUsers (
        id: $userId
      ) {
        edges {
          node {
            ...AdminPanel_userNode
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  AdminPanel,
  {
    userNode: graphql`
      fragment AdminPanel_userNode on UserNode {
        id
        fullName
        adminpermissionSet {
          edges {
            node {
              id
              resource
              verb
            }
          }
        }
      }
    `,
    adminMeta: graphql`
      fragment AdminPanel_adminMeta on AdminMeta {
        isPaper
      }
    `,
  },
);

export default withRouter(createQueryRendererProgress(
  query,
  container,
  (viewer, { history, onToggleTheme }) => ({
    history,
    userNode: viewer && viewer.authUsers.edges[0].node,
    adminMeta: viewer && viewer.adminMeta,
    onToggleTheme,
  }),
  () => ({ userId: JSON.parse(localStorage.getItem(MVA_LOGGED_IN_USER)).id }),
));
