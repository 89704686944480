/**
 * @flow
 * @relayHash bae26f26bc7a156566ba4d4b0c01704b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSubscription_viewer$ref = any;
export type UserSubscriptionSetQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  userSubscriptionId?: ?string,
|};
export type UserSubscriptionSetQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserSubscription_viewer$ref
  |}
|};
export type UserSubscriptionSetQuery = {|
  variables: UserSubscriptionSetQueryVariables,
  response: UserSubscriptionSetQueryResponse,
|};
*/


/*
query UserSubscriptionSetQuery(
  $userSubscriptionId: ID
) {
  viewer {
    ...UserSubscription_viewer_5UfCD
    id
  }
}

fragment UserSubscription_viewer_5UfCD on Query {
  userSubscriptions(id: $userSubscriptionId) {
    count
    edges {
      node {
        subscriberType
        id
        createdAt
        updatedAt
        subscriptionTemplate {
          code
          name
          id
        }
        user {
          id
        }
        billPeriodStart
        billPeriodEnd
        pendingCancel
        active
        paymentAccount {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userSubscriptionId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSubscriptionSetQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSubscription_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "offset",
                "variableName": "offset"
              },
              {
                "kind": "Variable",
                "name": "userSubscriptionId",
                "variableName": "userSubscriptionId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSubscriptionSetQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userSubscriptions",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userSubscriptionId"
              }
            ],
            "concreteType": "UserSubscriptionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserSubscriptionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "subscriberType",
                        "args": null,
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subscriptionTemplate",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SubscriptionTemplateNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "code",
                            "args": null,
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "user",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "billPeriodStart",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "billPeriodEnd",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "pendingCancel",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "active",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "paymentAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSubscriptionSetQuery",
    "id": null,
    "text": "query UserSubscriptionSetQuery(\n  $userSubscriptionId: ID\n) {\n  viewer {\n    ...UserSubscription_viewer_5UfCD\n    id\n  }\n}\n\nfragment UserSubscription_viewer_5UfCD on Query {\n  userSubscriptions(id: $userSubscriptionId) {\n    count\n    edges {\n      node {\n        subscriberType\n        id\n        createdAt\n        updatedAt\n        subscriptionTemplate {\n          code\n          name\n          id\n        }\n        user {\n          id\n        }\n        billPeriodStart\n        billPeriodEnd\n        pendingCancel\n        active\n        paymentAccount {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5df72ee83417bbbeec22712e7318483a';
module.exports = node;
