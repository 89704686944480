import { graphql } from 'react-relay';

// language=GraphQL
const generateTradeInvoiceMutation = graphql`
  mutation GenerateTradeInvoiceMutation (
    $portfolioOrderId: ID
    $stockOrderId: ID
  ) {
    generateTradeInvoice(
      portfolioOrderId: $portfolioOrderId
      stockOrderId: $stockOrderId
    ){
      tradeInvoice {
        id
        pdfUrl
        pdf
        state
        createdAt
        updatedAt
        portfolioOrder {
          id
        }
      }
    }
  }
`;

export default generateTradeInvoiceMutation;
