import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import { List, fromJS } from 'immutable';
import {
  AddCircleOutline, Cancel, Send,
} from '@material-ui/icons';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { commitMutation } from 'react-relay';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { fromGlobalId, toGlobalId } from '../../helpers/ID';
import environment from '../../Environment';
import processBrokerActivityMutation from '../../mutations/ProcessBrokerActivityMutation';
import notiStack from '../../common/notiStack';
import Console from '../../common/log';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: 600,
    padding: 20,
  },
  copyGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: 200,
    marginRight: theme.spacing(3),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  addEntryBtn: {
    margin: theme.spacing(1),
    width: '100%',
  },
});

function doMutation(
  brokerActivityId,
  copyFromBrokerActivityId,
  responsibleContractId,
  responsibleHoldings,
  closeFormCallback,
) {
  const variables = {
    brokerActivityId,
    copyFromBrokerActivityId,
    responsibleContractId,
    responsibleHoldings,
    clientMutationId: `muProcessBrokerActivity:${brokerActivityId}:${responsibleContractId}:${responsibleHoldings}`,
  };

  commitMutation(environment, {
    mutation: processBrokerActivityMutation,
    variables,
    onCompleted: (response) => {
      if (!response.processBrokerActivity) {
        notiStack.error('Error. Check console for details');
        Console.error('Error: ', response);
      } else if (response.processBrokerActivity.errors.length > 0) {
        notiStack.error((
          <React.Fragment>
            {response.processBrokerActivity.errors.map((error, idx) => (
              <span key={error.field}>
                {error.field !== '__all__' && <b>{error.field}</b>}
                <ul>
                  {error.messages.map(message => (
                    <li key={message}>
                      {message}
                    &nbsp;
                    </li>
                  ))}
                </ul>
              </span>
            ))}
          </React.Fragment>
        ));
      } else {
        notiStack.success('Processed broker activity');
        closeFormCallback();
      }
    },
    onError: (err) => {
      notiStack.error('Error. Check console for details');
      Console.error(err);
    },
  });
}

class ProcessForm extends React.Component {
  state = {
    entries: List(),
    // copyFromDatabaseId: null,
    copyMode: false,
    copyFromType: null,
    copyFromId: '',
    responsibleContractId: '',
  };

  addEntry = () => {
    this.setState((state, props) => (
      {
        entries: state.entries.push(
          fromJS({
            // idk if this is best way to go about it
            key: Math.floor((Math.random() * 1000000000) + 1),
            account: null,
            portfolio: null,
            qty: null,
          }),
        ),
      }
    ));
  };

  handleProcess = () => {
    const {
      entries, responsibleContractId, copyFromType, copyFromId, copyMode,
    } = this.state;
    const { brokerActivity, close } = this.props;
    doMutation(
      brokerActivity.id,
      copyMode ? toGlobalId(copyFromType, copyFromId) : null,
      toGlobalId('ContractNode', responsibleContractId),
      entries.map(entry => (
        { accountId: entry.get('account'), portfolioId: entry.get('portfolio'), holdingQty: entry.get('qty') }
      )),
      close,
    );
  };

  removeEntry = idx => () => {
    this.setState((state, props) => (
      {
        entries: state.entries.delete(idx),
      }
    ));
  };

  setEntryVal = (idx, key, nodeType) => (event) => {
    const newValue = event.target.value;
    this.setState((state, props) => (
      {
        entries: state.entries.update(idx,
          value => value.update(key, () => (nodeType ? toGlobalId(nodeType, newValue) : newValue))),
      }
    ));
  };

  render() {
    const { classes, brokerActivity, close } = this.props;
    const {
      entries, responsibleContractId, copyFromType, copyMode, copyFromId,
    } = this.state;
    return (
      <Grid container direction="column" className={classes.root} spacing={0}>
        <Grid item>
          <h1>Process Broker Activity</h1>
        </Grid>
        <Grid item>
          <div>
            <p>
              <b>Node Type:</b>
              &nbsp;&nbsp;
              {fromGlobalId(brokerActivity.id)[0]}
            </p>
            <p>
              <b>ID:</b>
              &nbsp;&nbsp;
              {fromGlobalId(brokerActivity.id)[1]}
            </p>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="Mode"
                name="mode"
                value={copyMode ? 'copy' : 'manual'}
                onChange={event => this.setState({ copyMode: event.target.value === 'copy' })}
              >
                <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                <FormControlLabel value="copy" control={<Radio />} label="Copy" />
              </RadioGroup>
            </FormControl>
          </div>
        </Grid>
        <Grid item>
          <TextField
            label="Responsible Contract ID"
            value={responsibleContractId}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
            onChange={event => this.setState({ responsibleContractId: event.target.value })}
          />
        </Grid>
        {copyMode
        && (
        <Grid item className={classes.copyGrid}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>
              From Type
            </InputLabel>
            <Select
              value={copyFromType}
              onChange={event => this.setState({ copyFromType: event.target.value })}
            >
              <MenuItem value={null}>---</MenuItem>
              <MenuItem value="IBCorporateActionNode">IB Corporate Action</MenuItem>
              <MenuItem value="IBDividendAccrualNode">IB Dividend Accrual</MenuItem>
              <MenuItem value="IBCashTransactionNode">IB Cash Transaction</MenuItem>
              <MenuItem value="IBTransactionTaxNode">IB Transaction Tax</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>
              From ID
            </InputLabel>
            <Input
              label="From ID"
              value={copyFromId}
              onChange={event => this.setState({ copyFromId: event.target.value })}
              type="number"
            />
          </FormControl>
        </Grid>
        )
        }
        { !copyMode
        && (
        <React.Fragment>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account</TableCell>
                  <TableCell>Portfolio</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                entries.map((entry, idx) => (
                  <TableRow key={entry.get('key')}>
                    <TableCell>
                      <Input
                        onChange={this.setEntryVal(idx, 'account', 'AccountNode')}
                        type="number"
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        label="Responsible contract ID"
                        onChange={this.setEntryVal(idx, 'portfolio', 'ContractNode')}
                        type="number"
                      />
                    </TableCell>
                    <TableCell>
                      <Input
                        onChange={this.setEntryVal(idx, 'qty', null)}
                        type="number"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={this.removeEntry(idx)}
                        variant="contained"
                        color="secondary"
                      >
                        <Cancel />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
                <TableRow>
                  <TableCell colSpan={3}>
                    <Button
                      className={classes.addEntryBtn}
                      onClick={this.addEntry}
                      color="primary"
                      variant="contained"
                    >
                    ADD ENTRY&nbsp;&nbsp;
                      <AddCircleOutline />
                    </Button>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </React.Fragment>
        )
          }
        <Grid item>
          <Button className={classes.button} variant="contained" color="secondary" onClick={close}>
            CLOSE
            <Cancel className={classes.rightIcon} />
          </Button>
          <Button className={classes.button} variant="contained" onClick={this.handleProcess}>
            PROCESS
            <Send className={classes.rightIcon} />
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProcessForm);
