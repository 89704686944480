import { graphql } from 'react-relay';

// language=GraphQL
const processBrokerTransactionMutation = graphql`
  mutation ProcessBrokerTransactionMutation (
    $allowSweepGroups: Boolean
    $brokerTransactionIds: [ID]
  ) {
      processBrokerTransaction(
        allowSweepGroups: $allowSweepGroups
        brokerTransactionIds: $brokerTransactionIds
      ) {
        brokerTransactions {
          id
          ignore
          matched
          updatedAt
        }
      }
    }
`;


export default processBrokerTransactionMutation;
