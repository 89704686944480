import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import { createQueryRendererProgressV2 } from '../../common/QueryRendererProgressV2';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemNumberField from '../../common/drawer/DrawerGridItemNumberField';
import { isRecurNull } from '../../../common/helpers';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer
  from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { UpdateButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import { updateBrokerActivityGroup } from '../../../mutations/BrokerActivityGroup';
import DrawerGridItemSubGroupHeading from '../../common/drawer/DrawerGridItemSubGroupHeading';
import DividendAccrualList from '../DividendAccrualList';
import MUITableRenderer from '../../common/table/MUITableRenderer';
import TableFieldMeta from '../../../common/TableFieldMeta';
import DrawerGridItemDateField from '../../common/drawer/DrawerGridItemDateField';

function DividendDefForm(props) {
  const {
    viewer,
    onClose,
    cashTransaction,
  } = props;
  const dividendDef = isRecurNull(viewer, [
    'ibCashTransactions', 'edges', 0, 'node', 'group', 'dividenddef']);
  const [members] = React.useState(
    {
      frankingTaxRate: dividendDef.frankingTaxRate,
      rateFranked: dividendDef.rateFranked,
      dividendAmount: dividendDef.dividendAmount,
      description: dividendDef.description,
      currency: dividendDef.currency,
    },
  );

  const [exDate, setExDate] = React.useState(dividendDef.exDate);
  const [payDate, setPayDate] = React.useState(dividendDef.payDate);
  const refFrankingTaxRate = React.useRef();
  const refRateFranked = React.useRef();
  const refDividendAmount = React.useRef();

  return (
    <DrawerGridItemContainer>
      <DrawerGridItemHeading heading="Dividend Definition" />

      <DrawerGridItemTextField
        label="Description"
        xs={3}
        defaultValue={members.description}
        onChange={(e) => {
          members.description = e.target.value;
        }}
      />

      <DrawerGridItemNumberField
        xs={3}
        label="Dividend Amount"
        helperText="Amount per Share"
        defaultValue={members.dividendAmount}
        inputRef={refDividendAmount}
        onChange={(e) => {
          members.dividendAmount = parseFloat(e.target.value);
        }}
      />
      <DrawerGridItemNumberField
        xs={3}
        label="Rate Franked"
        helperText="Australian Franking"
        defaultValue={members.rateFranked}
        inputRef={refRateFranked}
        onChange={(e) => {
          members.rateFranked = parseFloat(e.target.value);
        }}
      />
      <DrawerGridItemNumberField
        xs={3}
        label="Fraking Tax Rate"
        helperText="0.3 by default"
        defaultValue={members.frankingTaxRate}
        inputRef={refFrankingTaxRate}
        onChange={(e) => {
          members.frankingTaxRate = parseFloat(e.target.value);
        }}
      />

      <DrawerGridItemDateField
        xs={3}
        label="Ex Date"
        value={exDate}
        onChange={n => setExDate(n)}
        autoOk
      />

      <DrawerGridItemDateField
        xs={3}
        label="Pay Date"
        value={payDate}
        onChange={n => setPayDate(n)}
        autoOk
      />

      <DrawerGridItemBottomButtonContainer>
        <Grid item>
          <UpdateButton
            onClickPromise={() => doCommitPromise(
              updateBrokerActivityGroup,
              {
                brokerActivityGroupId: dividendDef.id,
                description: members.description,
                dividendDef: {
                  frankingTaxRate: members.frankingTaxRate,
                  rateFranked: members.rateFranked,
                  dividendAmount: members.dividendAmount,
                  exDate,
                  payDate,
                },
              },
              () => (onClose && onClose()),
              () => null,
            )}
          />
        </Grid>

      </DrawerGridItemBottomButtonContainer>

      <DrawerGridItemSubGroupHeading heading="Fill from Accrual" />

      <Grid item xs={12}>
        <DividendAccrualList
          ibConId={cashTransaction.ibConId}
          tableRenderer={MUITableRenderer}
          rowsPerPage={10}
          columns={[
            TableFieldMeta.localId,
            TableFieldMeta.field('reportDate', 'Report Date'),
            TableFieldMeta.field('exDate', 'Ex Date'),
            TableFieldMeta.field('payDate', 'Pay Date'),
            TableFieldMeta.field('grossRate', 'Gross Rate', { align: 'right' }),
          ]}
          onRowSelect={(row, s, a) => {
            const accrual = row;
            refFrankingTaxRate.current.value = 0.3;
            members.frankingTaxRate = 0.3;
            refRateFranked.current.value = 0;
            members.rateFranked = 0;
            refDividendAmount.current.value = accrual.grossRate;
            members.dividendAmount = accrual.grossRate;
            setExDate(accrual.exDate);
            setPayDate(accrual.payDate);
          }}
        />
      </Grid>

    </DrawerGridItemContainer>
  );
}


const query = graphql`
  query DividendDefFormQuery (
    $brokerActivityId: ID
  ) {
    viewer {
      ...DividendDefForm_viewer
      @arguments (
        brokerActivityId: $brokerActivityId
      )
    }
  }
`;

const container = createFragmentContainer(
  DividendDefForm,
  {
    viewer: graphql`
      fragment DividendDefForm_viewer on Query
      @argumentDefinitions(
        brokerActivityId: {type: ID}
      )
      {
        ibCashTransactions(id: $brokerActivityId) {
          edges {
            node {
              id
              group {
                id
                description
                dividenddef {
                  description
                  currency
                  id
                  createdAt
                  updatedAt
                  payDate
                  exDate
                  rateFranked
                  frankingTaxRate
                  dividendAmount
                }
              }
            }
          }
        }
      }
    `,
  },
);


export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ brokerActivityId }) => ({ brokerActivityId }),
);
