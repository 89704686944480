import { graphql } from 'react-relay';

const TenantAccountQuery = graphql`
  query TenantAccountQuery(
    $first: Int,
    $offset: Int,
    $tenantId: ID,
  )  {
    viewer {
        tenants(
          offset: $offset
          first: $first
          id: $tenantId
        ) {
          edges {
            node {
              id
              name
              createdAt
              updatedAt
              parent {
                id
                name
              }
              subdomain
              logoUrl
              tenantAccounts {
                edges {
                  node {
                    name
                    id
                    accountPtr{
                      id
                      availableCashBase
                      actualCashBase
                      availableCashBalances {
                        currency
                        balance
                      }
                      actualCashBalances {
                        currency
                        balance
                      }
                    }
                  }
                }
              }
            }
          }
          count
        }
    }
  }
`;

export default TenantAccountQuery;
