import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { commitMutation, createFragmentContainer, graphql } from 'react-relay';
import Drawer from '@material-ui/core/Drawer';
import { withRouter } from 'react-router-dom';
import { IconButton, Link } from '@material-ui/core';
import { Add, SaveAlt } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useCommonStyles } from '../common/Styles';
import TableButton from '../common/buttons/TableButton';
import environment from '../../Environment';
import removeVueContractMutation from '../../mutations/RemoveVueContractMutation';
import BaseButton, { EditButton } from '../common/buttons/BaseButton';
import createVueDraftMutation from '../../mutations/CreateVueDraftMutation';
import EntitySelectionDlg from '../common/dialogs/EntitySelectionDlg';
import addVueContractMutation from '../../mutations/AddVueContractMutation';
import MoreButton from '../common/buttons/MoreButton';
import VueContractDetailsForm from './VueContractDetailsForm';
import publishVueDraftMutation from '../../mutations/PublishVueDraftMutation';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import ContractSearch from '../common/dialogs/ContractSearch';
import ContractLink from '../common/links/ContractLink';
import TitledWidgetContainer from '../common/TitledWidgetContainer';
import TitledWidgetItem from '../common/TitledWidgetItem';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { doCommitPromise } from '../../common/commit';
import VueEditForm from './VueDetails/VueEditForm';
import VerticalTable from '../common/VerticalTable';
import EnumValue from '../common/EnumValue';
import TableFieldMeta from '../../common/TableFieldMeta';
import DataTableWidget from '../common/DataTableWidget';


const getDraft = (vue) => {
  let draft = null;
  for (let i = 0; i < vue.vueSet.edges.length; i += 1) {
    const childVue = vue.vueSet.edges[i];
    if (!childVue.node.published) {
      draft = childVue.node;
      break;
    }
  }
  return draft;
};

function VueDetails(props) {
  const { vueDetails } = props;
  const [updatingVueContract, setUpdatingVueContract] = React.useState(null);
  const [editVueDraft, setEditVueDraft] = React.useState(false);
  const [editTrivial, setEditTrivial] = React.useState(false);
  const [addContract, setAddContract] = React.useState(false);
  const [publishEmail, setPublishEmail] = React.useState(true);
  const classes = useCommonStyles();

  const getActiveVueDetails = () => {
    if (editVueDraft) {
      return getDraft(vueDetails);
    }
    return vueDetails;
  };

  const draft = getDraft(vueDetails);
  const activeVueDetails = getActiveVueDetails();

  const removeContract = (vueContract) => {
    const variables = {
      vueId: activeVueDetails.id,
      contractId: vueContract.contract.id,
      clientMutationId: `removeVueContract:${vueDetails.id}:${vueContract.contract.id}`,
    };
    commitMutation(environment, {
      mutation: removeVueContractMutation,
      variables,
    });
  };

  const createVueDraft = () => {
    const variables = {
      vueId: vueDetails.id,
      clientMutationId: `cloneVue:${vueDetails.id}`,
    };

    commitMutation(environment, {
      mutation: createVueDraftMutation,
      variables,
      onCompleted: (response, errors) => {
        if (!errors) {
          setEditVueDraft(true);
        }
      },
    });
  };

  const onSelectContracts = (contracts) => {
    contracts.map(
      (contract) => {
        const variables = {
          vueId: activeVueDetails.id,
          contractId: contract.id,
          clientMutationId: `addVueContract:${activeVueDetails.id}:${contract.id}`,
          weight: 10.0,
          description: '',
        };
        return commitMutation(environment, {
          mutation: addVueContractMutation,
          variables,
        });
      },
    );
    setAddContract(false);
  };

  const columns = [
    TableFieldMeta.fieldCell('cont', 'id',
      row => <ContractLink contract={row.value.contract} />, { align: 'right' }),
    TableFieldMeta.field('symbol', 'Symbol', { accessor: node => node.contract.symbol },
      { align: 'center' }),
    TableFieldMeta.field('currency', 'Currency', { accessor: node => node.contract.currency }),
    TableFieldMeta.field('exchange', 'Exchange',
      { accessor: node => node.contract.exchange.symbol }),
    TableFieldMeta.field('weight', 'Weight %'),
    TableFieldMeta.field('name', 'Name', { accessor: node => node.contract.name }),
    TableFieldMeta.fieldCell('research', 'Research',
      row => (
        row.value.researchPdfUrl && (
          <Link href={row.value.researchPdfUrl} target="_blank">
            <SaveAlt />
          </Link>
        )
      ),
      { align: 'center' }),
  ];

  if (editVueDraft) {
    columns.push(TableFieldMeta.fieldCell(
      'rem.',
      '',
      row => (
        <Grid key={row.value.id} container spacing={1}>
          <Grid key={row.value.id} item>
            <TableButton
              key={row.value.id}
              color="secondary"
              onClick={() => removeContract(row.value)}
            >
              Remove
            </TableButton>
          </Grid>
          <Grid item>
            <MoreButton
              key={row.value.id}
              onClick={() => setUpdatingVueContract(row.value)}
            />
          </Grid>
        </Grid>
      ),
    ));
  }

  return (
    <div className={classes.innerTabRoot}>


      {/*Contract changes for proper change in draft*/}
      <Drawer
        anchor="right"
        open={editTrivial}
        onClose={() => setEditTrivial(false)}
      >
        <VueEditForm
          vue={activeVueDetails}
          onClose={() => setEditTrivial(false)}
        />
      </Drawer>

      {/*Contract changes for proper change in draft*/}
      <Drawer
        anchor="right"
        open={updatingVueContract !== null}
        onClose={() => setUpdatingVueContract(null)}
      >
        {
          updatingVueContract && (
            <VueContractDetailsForm
              key={updatingVueContract.id}
              vue={activeVueDetails}
              vueContract={updatingVueContract}
              enableEditing={editVueDraft}
              onClose={() => setUpdatingVueContract(null)}
            />
          )
        }
      </Drawer>

      <TitledWidgetContainer>

        {/*String fields*/}
        <TitledWidgetItem
          xs={12}
          title="Details"
        >
          {
            activeVueDetails && (
              <VerticalTable
                columnMeta={[
                  ['Name', 'name'],
                  ['Description', 'description'],
                  ['Investment Objective', 'investmentObjective'],
                  ['Investment Strategy', 'investmentStrategy'],
                  ['Investor Profile', 'investorProfile'],
                  ['Risk Factor', 'riskFactor'],
                  ['Sharing Strategy',
                    node => (
                      <EnumValue
                        enumType="SharingStrategy"
                        enumValue={node.sharingStrategy}
                      />
                    )],
                  ['Time Frame',
                    node => <EnumValue enumType="InvestmentTimeFrame" enumValue={node.timeFrame} />],
                ]}
                dataNode={activeVueDetails}
              />
            )
          }

          <BottomGridContainer>
            <Grid item>
              <EditButton
                color="primary"
                onClick={() => setEditTrivial(true)}
              />
            </Grid>
          </BottomGridContainer>
        </TitledWidgetItem>

        <DataTableWidget
          xs={12}
          title="Contracts"
          rightTopComp={editVueDraft && (
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={() => setAddContract(true)}
            >
              <Add />
            </IconButton>
          )}
          columnMeta={columns}
          dataNodes={activeVueDetails ? activeVueDetails.vuecontractSet.edges.map(x => x.node) : []}
        />

        <Grid item xs={12}>
          <BottomGridContainer extraStyles={{ alignItems: 'center' }}>
            {
              editVueDraft && (
                <Grid item>
                  <BaseButton
                    color="primary"
                    onClick={() => setEditVueDraft(false)}
                    buttonText="View Published"
                  />
                </Grid>
              )
            }
            {
              editVueDraft && (
                <>
                  <Grid item>
                    <BaseButton
                      color="primary"
                      onClickPromise={() => doCommitPromise(
                        publishVueDraftMutation,
                        { vueId: activeVueDetails.id, silent: !publishEmail },
                        () => {
                          setEditVueDraft(false);
                        },
                      )}
                      buttonText="Publish"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={publishEmail}
                          onChange={e => setPublishEmail(e.target.checked)}
                          name="checkedB"
                          color="primary"
                        />
                      )}
                      label="Publish email"
                    />
                  </Grid>
                </>
              )
            }

            {
              !editVueDraft && draft && (
                <Grid item>
                  <BaseButton
                    color="primary"
                    onClick={() => setEditVueDraft(true)}
                    buttonText="View Draft"
                  />
                </Grid>
              )
            }

            {
              !editVueDraft && !draft && (
                <Grid item>
                  <EditButton
                    color="primary"
                    onClick={() => createVueDraft()}
                  />
                </Grid>
              )
            }

          </BottomGridContainer>
        </Grid>

      </TitledWidgetContainer>

      <EntitySelectionDlg
        searchComp={ContractSearch}
        dialogTitle="Select Contract"
        key={addContract}
        isOpen={addContract}
        onSelectItems={contracts => onSelectContracts(contracts)}
        onClose={() => setAddContract(false)}
      />
    </div>
  );
}


const query = graphql`
  query VueDetailsQuery (
    $vueId: ID,
  ) {
    viewer {
      vues (
        id: $vueId
      ){
        edges{
          node {
            ...VueDetails_vueDetails
          }
        }
      }
    }
  }
`;

graphql`
  fragment VueDetails_min on VueNode {
    id
    name
    description
    investmentObjective
    investmentStrategy
    investorProfile
    timeFrame
    sharingStrategy
    portfolioType
    riskFactor
    visible
    published
    createdAt
    updatedAt
    sharesightportfolio {
      id
    }
    haloportfolio {
      id
    }
    vuecontractSet {
      edges {
        node {
          id
          weight
          description
          researchPdfUrl
          contract {
            id
            symbol
            name
            currency
            exchange {
              symbol
              country
              name
            }
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  VueDetails,
  {
    vueDetails: graphql`
      fragment VueDetails_vueDetails on VueNode {
        ...VueDetails_min @relay(mask: false)

        vueSet(
          orderBy: "-id"
        )
        {
          edges {
            node {
              ...VueDetails_min @relay(mask: false)
            }
          }
        }
        parentVue {
          id
        }
      }
    `,
  },
);

export default withRouter(createQueryRendererProgress(
  query,
  container,
  (viewer, outProps) => ({ vueDetails: viewer.vues.edges[0].node, viewer }),
  props => ({ vueId: props.vueId }),
));
