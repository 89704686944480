import { graphql } from 'react-relay';

// language=GraphQL
const contractFromBroker = graphql`
  mutation ContractFromBrokerMutation(
    $brokerActivityId: ID
    $forceCreate: Boolean
  ) {
    contractFromBroker(
      brokerActivityId: $brokerActivityId
      forceCreate: $forceCreate
    ) {
      contract {
        id
        symbol
        ibConId
      }
      
      brokerActivity {
        ibcorporateaction {
          ...IBCorporateAction_all @relay(mask: false)
        }
        ibcashtransaction {
          ...IBCashTransaction_all @relay(mask: false)
        }
        ibtransfer {
          ...IBTransfer_all @relay(mask: false)
        }
      }
    }
  }
`;

export default contractFromBroker;
