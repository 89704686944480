/**
 * @flow
 * @relayHash 1c812c6aa10e913fc52bc79dba6785b4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OffMarketHoldingCostBasisSetMutationVariables = {|
  omtHoldingQtyId?: ?string,
  costBasis?: ?number,
  commission?: ?number,
  dummy?: ?boolean,
|};
export type OffMarketHoldingCostBasisSetMutationResponse = {|
  +setOffMarketHoldingCostBasis: ?{|
    +omtHoldingQty: ?{|
      +updatedAt: any,
      +id: string,
      +commission: number,
      +costBasis: number,
      +dummy: boolean,
    |}
  |}
|};
export type OffMarketHoldingCostBasisSetMutation = {|
  variables: OffMarketHoldingCostBasisSetMutationVariables,
  response: OffMarketHoldingCostBasisSetMutationResponse,
|};
*/


/*
mutation OffMarketHoldingCostBasisSetMutation(
  $omtHoldingQtyId: ID
  $costBasis: Float
  $commission: Float
  $dummy: Boolean
) {
  setOffMarketHoldingCostBasis(omtHoldingQtyId: $omtHoldingQtyId, costBasis: $costBasis, commission: $commission, dummy: $dummy) {
    omtHoldingQty {
      updatedAt
      id
      commission
      costBasis
      dummy
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "omtHoldingQtyId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "costBasis",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "commission",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dummy",
    "type": "Boolean",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "setOffMarketHoldingCostBasis",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commission",
        "variableName": "commission"
      },
      {
        "kind": "Variable",
        "name": "costBasis",
        "variableName": "costBasis"
      },
      {
        "kind": "Variable",
        "name": "dummy",
        "variableName": "dummy"
      },
      {
        "kind": "Variable",
        "name": "omtHoldingQtyId",
        "variableName": "omtHoldingQtyId"
      }
    ],
    "concreteType": "SetOffMarketTransferCostBasis",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "omtHoldingQty",
        "storageKey": null,
        "args": null,
        "concreteType": "OffMarketTransferHoldingQuantityNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "commission",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "costBasis",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dummy",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OffMarketHoldingCostBasisSetMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "OffMarketHoldingCostBasisSetMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "OffMarketHoldingCostBasisSetMutation",
    "id": null,
    "text": "mutation OffMarketHoldingCostBasisSetMutation(\n  $omtHoldingQtyId: ID\n  $costBasis: Float\n  $commission: Float\n  $dummy: Boolean\n) {\n  setOffMarketHoldingCostBasis(omtHoldingQtyId: $omtHoldingQtyId, costBasis: $costBasis, commission: $commission, dummy: $dummy) {\n    omtHoldingQty {\n      updatedAt\n      id\n      commission\n      costBasis\n      dummy\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2f6b3ddb46444c2c278ba3b464f09e6';
module.exports = node;
