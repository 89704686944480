import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../common/TableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import BrokerOrderStatus from '../common/BrokerOrderStatus';
import { fromGlobalId, toGlobalId, toLocal } from '../../helpers/ID';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';

function quantityStr(fxOrder) {
  if (fxOrder.brokerOrder.status === 'REJ') {
    return `${fxOrder.quantity}`;
  }
  return `(${fxOrder.quantityToDate}/${fxOrder.quantity})`;
}

function FxOrderList(props) {
  const {
    accountId,
    viewer,
    relay,
    filter,
  } = props;
  const classes = useCommonStyles();
  const accountField = [TableFieldMeta.account(node => node.account)];
  filter.setRelay(relay);

  const columns = [
    TableFieldMeta.localId,
    ...(accountId ? [] : accountField),
    {
      accessor: node => node.contract.localSymbol,
      Header: 'Symbol',
      id: 'symbol',
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      id: 'quantity',
      Cell: row => quantityStr(row.value),
      ...TableFieldMeta.rightAlignStyle,
    },
    {
      accessor: node => node,
      Header: 'Broker Order',
      id: 'broker_order_status',
      Cell: row => (
        <Grid container spacing={1}>
          <Grid item>
            {fromGlobalId(row.value.brokerOrder.id)[1]}
          </Grid>
          <Grid item>
            <BrokerOrderStatus stockOrder={row.value.brokerOrder} />
          </Grid>
          <Grid item>
            {`(${Math.abs(row.value.brokerOrder.quantityFilled)}/${Math.abs(row.value.brokerOrder.quantity)})`}
          </Grid>
        </Grid>
      ),
    },
    TableFieldMeta.createdAt,
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Search"
          helperText="Symbol"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="broker-order-id"
          label="Broker Order ID"
          type="number"
          helperText="Broker Order Id"
          defaultValue={filter.params.brokerOrderId && toLocal(filter.params.brokerOrderId)}
          onChange={(e) => {
            let brokerOrderId = null;
            if (e.target.value) {
              brokerOrderId = toGlobalId('BrokerOrderNode', e.target.value);
            }
            filter.updateVar('brokerOrderId', brokerOrderId);
          }}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.fxOrders : null)}
          />
        </Grid>
      </BaseGridContainer>

    </div>
  );
}

const query = graphql`
  query FxOrderListQuery(
    $first: Int,
    $offset: Int,
    $accountId: ID
    $brokerOrderId: ID
    $search: String
  ) {
    viewer {
      ...FxOrderList_viewer
      @arguments(
        first: $first,
        offset: $offset,
        accountId: $accountId
        brokerOrderId: $brokerOrderId
        search: $search
      )
    }
  }
`;

const container = createRefetchContainer(
  FxOrderList,
  {
    viewer: graphql`
      fragment FxOrderList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int, defaultValue: 0}
        accountId: {type: ID}
        brokerOrderId: {type: ID, defaultValue: null}
        search: {type: String}
      )
      {
        fxOrders (
          accountId: $accountId
          first: $first
          offset: $offset
          orderBy: "-id"
          brokerOrderId: $brokerOrderId
          search: $search
        ) {
          edges {
            node {
              account {
                name
                id
              }
              quantityToDate
              brokerOrder {
                id
                side
                status
                quantity
                quantityFilled
                quantityLeft
                matched
              }
              orderPtr {
                price
              }
              id
              price
              quantity
              createdAt
              updatedAt
              contract {
                currency
                localSymbol,
                symbol
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ accountId }) => ({ accountId }),
  { debounceFields: ['search', 'brokerOrderId'] },
));
