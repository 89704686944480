/**
 * @flow
 * @relayHash 8be20444c35786a6e6d69efe0e27508e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VueCommunicationList_viewer$ref = any;
export type VueCommunicationQueryVariables = {|
  vueId?: ?string,
  first?: ?number,
  offset?: ?number,
|};
export type VueCommunicationQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VueCommunicationList_viewer$ref
  |}
|};
export type VueCommunicationQuery = {|
  variables: VueCommunicationQueryVariables,
  response: VueCommunicationQueryResponse,
|};
*/


/*
query VueCommunicationQuery(
  $vueId: ID
  $first: Int
  $offset: Int
) {
  viewer {
    ...VueCommunicationList_viewer_2PMRZ5
    id
  }
}

fragment VueCommunicationList_viewer_2PMRZ5 on Query {
  vues(id: $vueId) {
    edges {
      node {
        tenant {
          id
        }
        id
        vuecommunicationSet(first: $first, offset: $offset, orderBy: "-createdAt") {
          count
          edges {
            node {
              id
              heading
              published
              publishedAt
              createdAt
              updatedAt
              message
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "VueCommunicationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "VueCommunicationList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "vueId",
                "variableName": "vueId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "VueCommunicationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vues",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "vueId"
              }
            ],
            "concreteType": "VueNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "VueNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ]
                      },
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "vuecommunicationSet",
                        "storageKey": null,
                        "args": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "Literal",
                            "name": "orderBy",
                            "value": "-createdAt"
                          }
                        ],
                        "concreteType": "VueCommunicationNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "count",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "VueCommunicationNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueCommunicationNode",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "heading",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "published",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "publishedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "createdAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "updatedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "message",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "VueCommunicationQuery",
    "id": null,
    "text": "query VueCommunicationQuery(\n  $vueId: ID\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...VueCommunicationList_viewer_2PMRZ5\n    id\n  }\n}\n\nfragment VueCommunicationList_viewer_2PMRZ5 on Query {\n  vues(id: $vueId) {\n    edges {\n      node {\n        tenant {\n          id\n        }\n        id\n        vuecommunicationSet(first: $first, offset: $offset, orderBy: \"-createdAt\") {\n          count\n          edges {\n            node {\n              id\n              heading\n              published\n              publishedAt\n              createdAt\n              updatedAt\n              message\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e29a8b9de0a57d35f7954560f85ecf29';
module.exports = node;
