import React from 'react';
import {
  graphql,
  createRefetchContainer,
} from 'react-relay';

import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BrokerActivityTableFieldMeta from './BrokerActivityTableFieldMeta';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterGridItemDateField from '../common/innerTab/InnerTabFilterGridItemDateField';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import TableStatus from '../common/TableStatus';
import BrokerTransactionTableFieldMeta from './BrokerTransactionTableFieldMeta';
import BrokerTransactionDetails from './BrokerTransactionDetails';
import BrokerActivityActions from './BrokerActivityActions';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import MsgRouter from '../../common/MsgRouter';
import BrokerTransactionActions from './BrokerTransactionActions';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import RTTableRenderer from '../common/table/RTTableRenderer';

const matchedDefault = false;
const ignoreDefault = false;

function BrokerTransactionList(props) {
  const { viewer, relay, filter } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const [brokerActivity, setBrokerActivity] = React.useState(null);
  const [transactionTypeIn, setTransactionTypeIn] = React.useState(
    filter.params.transactionTypeIn,
  );
  const [msgRouter] = React.useState(new MsgRouter());
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('conId', 'Con ID', { align: 'right' }),
    TableFieldMeta.field('description', 'Description', { width: 200 }),
    BrokerActivityTableFieldMeta.symbol,
    TableFieldMeta.field('quantity', 'Quantity', { align: 'right', width: 120 }),
    BrokerActivityTableFieldMeta.currency,
    TableFieldMeta.field('proceeds', 'Proceeds', { align: 'right', width: 180 }),
    TableFieldMeta.field('price', 'Price', { align: 'right', width: 80 }),
    BrokerActivityTableFieldMeta.reportDate,
    TableFieldMeta.field('transactionType', 'Type', { align: 'center' }),
    TableFieldMeta.field('instrumentType', 'Asset', { align: 'center', width: 60 }),
    TableFieldMeta.field('tradeId', 'Trade ID', { align: 'right' }),
    TableFieldMeta.field(node => (node.matched
      ? <TableStatus primary>Match</TableStatus>
      : <TableStatus secondary>Unmatch</TableStatus>
    ), 'Matched'),
    TableFieldMeta.field(node => (node.ignore
      ? <TableStatus>Ignore</TableStatus>
      : <TableStatus secondary>UnIgnore</TableStatus>
    ), 'Ignored'),
    TableFieldMeta.field('execId', 'Exec ID', { align: 'right', width: 200 }),
    TableFieldMeta.field('commissionCurrency', 'Comm.Cur', { width: 90, align: 'center' }),
    TableFieldMeta.field('commission', 'Comm.', { width: 80, align: 'right' }),
    TableFieldMeta.createdAt,
    BrokerTransactionTableFieldMeta.ignore,
    {
      id: 'details',
      accessor: node => node,
      Header: 'Details',
      Cell: row => (
        <BrokerActivityActions
          brokerActivity={row.value}
          isProcessed={x => x.matched}
          isIgnored={x => x.ignore}
          showProcess={false}
          onProcess={(be) => {
            // setBrokerActivity(be);
            // setDrawerOpen(true);
          }}
          onShowDetails={(be) => {
            setBrokerActivity(be);
            setDetailsOpen(true);
          }}
        />
      ),
    },
    TableFieldMeta.fieldCell('actions', 'Actions', row => (
      <BrokerTransactionActions
        msgRouter={msgRouter}
        brokerTransaction={row.original}
        isProcessed={x => x.matched}
        isIgnored={x => x.ignore}
        onProcess={(be) => {
          // setBrokerActivity(be);
          // setDrawerOpen(true);
        }}
        onShowDetails={(be) => {
          setBrokerActivity(be);
          setDetailsOpen(true);
        }}
      />
    )),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          {/*<ProcessForm brokerActivity={brokerActivity} close={() => setDrawerOpen(false)} />*/}
        </div>
      </Drawer>

      <Drawer anchor="right" open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <div
          tabIndex={0}
          role="button"
        >
          <div className={classes.drawerRootExtended}>
            <BrokerTransactionDetails
              brokerTransactionId={brokerActivity && brokerActivity.id}
            />
          </div>
        </div>
      </Drawer>

      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          defaultValue={filter.params.search}
          helperText="description, symbol"
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="con-id-filter"
          label="Con ID"
          type="number"
          defaultValue={filter.params.ibConId}
          onChange={e => filter.updateVar('ibConId', (e.target.value && Number(e.target.value)) || null)}
        />

        <InnerTabFilterGridItemTextField
          id="symbol-filter"
          label="Symbol"
          halfWidth
          defaultValue={filter.params.symbol}
          onChange={e => filter.updateVar('symbol', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="Currency"
          label="Currency"
          halfWidth
          value={filter.params.currency}
          onChange={e => filter.updateVar('currency', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="InstrumentType"
          label="Asset"
          helperText="Stock/Fx/Warrant"
          halfWidth
          value={filter.params.instrumentType}
          onChange={e => filter.updateVar('instrumentType', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="IBBrokerTransactionTypeMeta"
          label="Type"
          halfWidth
          multiple
          useCaption
          withEmpty={false}
          value={transactionTypeIn}
          onChange={(e) => {
            setTransactionTypeIn(e.target.value);
            filter.updateVar('transactionTypeIn', e.target.value);
          }}
        />

        <InnerTabFilterGridItemDateField
          value={filter.params.reportDate}
          onChange={value => filter.updateVar('reportDate', value)}
          label="Report Date"
        />
        
        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Matched?"
          defaultValue={filter.params.matched}
          onChange={newVal => filter.updateVar('matched', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Ignored?"
          defaultValue={filter.params.ignore}
          onChange={newVal => filter.updateVar('ignore', newVal)}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.ibBrokerTransactions : null)}
            tableRender={RTTableRenderer}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query BrokerTransactionListQuery (
    $first: Int
    $offset: Int
    $search: String
    $symbol: String
    $reportDate: Date
    $currency: String
    $matched: Boolean
    $ignore: Boolean
    $instrumentType: String
    $transactionType: String
    $ibConId: Int
    $transactionTypeIn: [String]
  ) {
    viewer {
      ...BrokerTransactionList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        symbol: $symbol
        ignore: $ignore
        reportDate: $reportDate
        currency: $currency
        matched: $matched
        instrumentType: $instrumentType
        transactionType: $transactionType
        ibConId: $ibConId
        transactionTypeIn: $transactionTypeIn
      )
    }
  }
`;

const container = createRefetchContainer(
  BrokerTransactionList,
  {
    viewer: graphql`
      fragment BrokerTransactionList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        search: {type: String}
        reportDate: {type: Date}
        currency: {type: String}
        matched: {type: Boolean}
        ignore: {type: Boolean}
        instrumentType: {type: String}
        transactionType: {type: String}
        symbol: {type: String}
        ibConId: {type: Int}
        transactionTypeIn: {type: "[String]"}
      ) {
        ibBrokerTransactions(
          first: $first
          offset: $offset
          search: $search
          reportDate: $reportDate
          currency: $currency
          matched: $matched
          instrumentType: $instrumentType
          transactionType: $transactionType
          symbol: $symbol
          ignore: $ignore
          conId: $ibConId
          transactionTypeIn: $transactionTypeIn
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              ...IBBrokerTransactions_all @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);


export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
    search: null,
    reportDate: null,
    symbol: null,
    ibConId: null,
    currency: null,
    matched: matchedDefault,
    ignore: ignoreDefault,
    instrumentType: null,
    transactionTypeIn: [],
  }),
  {
    debounceFields: ['search', 'symbol', 'ibConId', 'currency'],
  },
));
