/**
 * @flow
 * @relayHash f94d49b0e3c71b9513d491fef42fdd13
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CannedSecurityQuestionList_viewer$ref = any;
export type CannedSecurityQuestionListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
|};
export type CannedSecurityQuestionListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CannedSecurityQuestionList_viewer$ref
  |}
|};
export type CannedSecurityQuestionListQuery = {|
  variables: CannedSecurityQuestionListQueryVariables,
  response: CannedSecurityQuestionListQueryResponse,
|};
*/


/*
query CannedSecurityQuestionListQuery(
  $first: Int
  $offset: Int
) {
  viewer {
    ...CannedSecurityQuestionList_viewer_21Sbsv
    id
  }
}

fragment CannedSecurityQuestionList_viewer_21Sbsv on Query {
  cannedSecurityQuestions(first: $first, offset: $offset, orderBy: "-id") {
    edges {
      node {
        id
        question
        enabled
      }
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CannedSecurityQuestionListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CannedSecurityQuestionList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CannedSecurityQuestionListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cannedSecurityQuestions",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-id"
              }
            ],
            "concreteType": "CannedSecurityQuestionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CannedSecurityQuestionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CannedSecurityQuestionNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "question",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "enabled",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CannedSecurityQuestionListQuery",
    "id": null,
    "text": "query CannedSecurityQuestionListQuery(\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...CannedSecurityQuestionList_viewer_21Sbsv\n    id\n  }\n}\n\nfragment CannedSecurityQuestionList_viewer_21Sbsv on Query {\n  cannedSecurityQuestions(first: $first, offset: $offset, orderBy: \"-id\") {\n    edges {\n      node {\n        id\n        question\n        enabled\n      }\n    }\n    count\n    pageInfo {\n      endCursor\n      startCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3dd3d200cf7a3ad80acd3a2ab392c8c7';
module.exports = node;
