import React from 'react';
import { useCommonStyles } from '../../common/Styles';
import DrawerGridItemTextField from '../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemTextArea from '../../common/drawer/DrawerGridItemTextArea';
import DrawerGridItemCheckBox from '../../common/drawer/DrawerGridItemCheckBox';
import DrawerGridItemSelectField from '../../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { UpdateButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import vueTrivialUpdate from '../../../mutations/Vue';
import DrawerGridItemNumberField from '../../common/drawer/DrawerGridItemNumberField';
import { toLocal } from '../../../helpers/ID';
import mvMutationErrors from '../../../common/errorReport';

function VueEditForm(props) {
  const { vue, onClose } = props;
  const [sharingStrategy, setSharingStrategy] = React.useState(vue.sharingStrategy);
  const [portfolioType, setPortfolioType] = React.useState(vue.portfolioType);
  const [timeFrame, setTimeFrame] = React.useState(vue.timeFrame);
  const [visible, setVisible] = React.useState(vue.visible);
  const [members] = React.useState({
    name: vue.name,
    description: vue.description,
    investmentObjective: vue.investmentObjective,
    investmentStrategy: vue.investmentStrategy,
    investorProfile: vue.investorProfile,
    riskFactor: vue.riskFactor,
    sharesightPortfolioId: vue.sharesightportfolio ? toLocal(vue.sharesightportfolio.id) : null,
    haloPortfolioId: vue.haloportfolio ? toLocal(vue.haloportfolio.id) : null,
  });
  const classes = useCommonStyles();
  return (
    <div className={classes.drawerRootExtended}>
      <DrawerGridItemContainer>
        <DrawerGridItemTextField
          xs={9}
          label="Name"
          defaultValue={vue.name}
          onChange={(e) => { members.name = e.target.value; }}
        />
        <DrawerGridItemSelectField
          xs={3}
          value={timeFrame}
          enumType="InvestmentTimeFrame"
          label="Investment Time Frame"
          onChange={e => setTimeFrame(e.target.value)}
        />
        <DrawerGridItemSelectField
          xs={12}
          value={sharingStrategy}
          enumType="SharingStrategy"
          label="Sharing Strategy"
          onChange={e => setSharingStrategy(e.target.value)}
        />

        <DrawerGridItemNumberField
          label="Risk Factor"
          helperText="0 for no risk, 1 for maximum risk"
          defaultValue={vue.riskFactor}
          onChange={(e) => { members.riskFactor = e.target.value; }}
        />

        <DrawerGridItemSelectField
          xs={3}
          value={portfolioType}
          enumType="PortfolioType"
          label="Portfolio Type"
          onChange={e => setPortfolioType(e.target.value)}
        />

        {portfolioType === 'SHARESIGHT' && (
        <DrawerGridItemNumberField
          label="Sharesight Portfolio ID"
          defaultValue={vue.sharesightportfolio ? toLocal(vue.sharesightportfolio.id) : null}
          onChange={(e) => { members.sharesightPortfolioId = e.target.value; }}
        />
        )}

        {portfolioType === 'HALO' && (
        <DrawerGridItemNumberField
          label="Halo Portfolio ID"
          defaultValue={vue.haloportfolio ? toLocal(vue.haloportfolio.id) : null}
          onChange={(e) => { members.haloPortfolioId = e.target.value; }}
        />
        )}

        <DrawerGridItemTextArea
          label="Description"
          defaultValue={vue.description}
          onChange={(e) => { members.description = e.target.value; }}
        />
        <DrawerGridItemCheckBox
          label="Visible"
          onChange={e => setVisible(e.target.checked)}
          value={visible}
        />
        <DrawerGridItemTextArea
          label="Investor Profile"
          defaultValue={vue.investorProfile}
          onChange={(e) => { members.investorProfile = e.target.value; }}
        />
        <DrawerGridItemTextArea
          label="Investment Objective"
          defaultValue={vue.investmentObjective}
          onChange={(e) => { members.investmentObjective = e.target.value; }}
        />
        <DrawerGridItemTextArea
          label="Investment Strategy"
          defaultValue={vue.investmentStrategy}
          onChange={(e) => { members.investmentStrategy = e.target.value; }}
        />
        <DrawerGridItemBottomButtonContainer>
          <UpdateButton
            onClickPromise={() => doCommitPromise(
              vueTrivialUpdate,
              {
                input: {
                  id: vue.id,
                  name: members.name,
                  riskFactor: `${members.riskFactor}`,
                  description: members.description,
                  investorProfile: members.investorProfile,
                  investmentObjective: members.investmentObjective,
                  investmentStrategy: members.investmentStrategy,
                  sharesightPortfolioId: (members.sharesightPortfolioId
                    && Number(members.sharesightPortfolioId)) || null,
                  haloPortfolioId: (members.haloPortfolioId
                    && Number(members.haloPortfolioId)) || null,
                  timeFrame,
                  visible,
                  sharingStrategy,
                  portfolioType,
                },
              },
              (response) => {
                if (!mvMutationErrors(response, 'vueTrivial')) {
                  onClose && onClose();
                }
              },
            )}
          />
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </div>
  );
}

export default VueEditForm;
