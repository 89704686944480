import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinkIcon from '@material-ui/icons/Link';
import VerticalTable from '../common/VerticalTable';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta, { Gid } from '../../common/TableFieldMeta';
import FileUpload from '../common/FileUpload';
import uploadImageContract, { emulateContractPrice, setContractCachePrice } from '../../mutations/Contract';
import { doCommitPromise } from '../../common/commit';
import TitledWidgetContainer from '../common/TitledWidgetContainer';
import TitledWidgetItem from '../common/TitledWidgetItem';
import { UploadButton } from '../common/buttons/BaseButton';
import EnumValue from '../common/EnumValue';
import TableButton from '../common/buttons/TableButton';
import { toLocal } from '../../helpers/ID';
import SingleTextFieldModalDlg from '../common/dialogs/SingleTextFieldModalDlg';
import ContractIconWidget from './ContractIconWidget';
import TableStatus from '../common/TableStatus';
import Price from '../common/Price';
import DateTime from '../common/DateTimeMoment';
import { isRecurNull } from '../../common/helpers';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TableIconButton from '../common/table/TableIconButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import notiStack from '../../common/notiStack';

function ContractDetails(props) {
  const { viewer } = props;
  const contract = isRecurNull(viewer, ['contracts', 'edges', 0, 'node']);
  const classes = useCommonStyles();
  const [imageUrl, setImageUrl] = React.useState(contract.imageUrl);
  const [image, setImage] = React.useState(null);
  const [updateShared, setUpdateShared] = React.useState(true);
  const [showEmulatePriceDlg, setShowEmulatePriceDlg] = React.useState(false);
  const [showSetCachePriceDlg, setShowSetCachePriceDlg] = React.useState(false);
  const latestFSCFD = isRecurNull(contract, ['fsContract', 'factsetlatestcontractfinancialdata']);
  const ibUrl = 'https://misc.interactivebrokers.com/cstools/contract_info/v3.10/index.php?'
            + `action=Conid Info&wlId=IB&conid=${contract.ibConId}&lang=en&ib_entity=`;

  const contractDetailColumns = [
    ['Created At', TableFieldMeta.createdAt.accessor],
    ['Updated At', TableFieldMeta.updatedAt.accessor],
    ['Currency', 'currency'],
    ['Symbol', 'symbol'],
    ['Name', 'name'],
    ['Instrument Type', node => <EnumValue enumValue={node.instrumentType} enumType="InstrumentType" />],
    ['Exchange', ['exchange', 'symbol']],
    ['Con ID', node => (
      <Grid container spacing={2} justify="flex-end" alignItems="baseline">
        <Grid item>
          {node.ibConId}
        </Grid>
        <TableCompactGridItem>
          {/*<Link href={ibUrl}>IB Info</Link>*/}
          <TableIconButton
            href={ibUrl}
            iconClass={LinkIcon}
            tooltip="IB Information"
          />
        </TableCompactGridItem>
      </Grid>
    )],
    ['IB Status', node => <EnumValue enumValue={node.ibStatus} enumType="ContractIbStatus" />],
    ['Latest Price (Base)', node => <Price currency="AUD">{node.latestPriceAud}</Price>],
    ['Latest Price', node => <Price currency={node.currency}>{node.latestPriceLocal}</Price>],
    ['Latest Price Date', node => node.latestPriceDate],
    ['Latest Price Time', node => node.latestPriceTime],
    ['Gid', node => <Gid gid={node.id} />],
  ];

  const contractPriceDetailColumns = [
    ['Price', node => (
      <Grid container spacing={1} justify="flex-end">
        <Grid item>{node.latestPriceLocal}</Grid>
        <Grid item>
          <TableButton
            buttonText="Set Price"
            color="primary"
            onClick={() => setShowSetCachePriceDlg(true)}
          />
        </Grid>
      </Grid>
    )],
    ['Source', node => (node.priceCashInfo
      ? <EnumValue enumValue={node.priceCashInfo.source} enumType="CacheSourceEnumType" />
      : <TableStatus>Not Set</TableStatus>)],
    ['Time of Price', node => (node.priceCashInfo
      ? (
        <DateTime
          key={node.priceCashInfo.timeOfPrice}
        >
          {node.priceCashInfo.timeOfPrice}
        </DateTime>
      )
      : <TableStatus>Not Set</TableStatus>)],
    ['Time Set', node => (node.priceCashInfo
      ? <DateTime>{node.priceCashInfo.timeSet}</DateTime>
      : <TableStatus>Not Set</TableStatus>)],
  ];

  const factsetContractDetails = [
    ['ID', TableFieldMeta.localId.accessor],
    ['ISIN', 'isin'],
    ['Name', 'name'],
    // ['Sedol', 'sedol'],
    ['Country', 'country'],
    ['Industry', 'industry'],
    ['Sector', 'sector'],
    ['Lot Size', 'lotSize'],
    ['EOD Price (Base)',
      node => (
        <Price currency="AUD">
          {latestFSCFD && latestFSCFD.closingPriceLocal}
        </Price>
      )],
    ['EOD Price',
      node => (
        <Price currency={node.currency}>
          {latestFSCFD && latestFSCFD.closingPriceLocal}
        </Price>
      )],
    ['EOD Price Date', node => latestFSCFD && latestFSCFD.date],
  ];

  return (
    <div className={classes.innerTabRoot}>

      <SingleTextFieldModalDlg
        commitButtonText="Post"
        open={showEmulatePriceDlg}
        onClose={() => setShowEmulatePriceDlg(false)}
        onChangePromise={newValue => doCommitPromise(
          emulateContractPrice,
          {
            requestId: contract.contractmarketdatarequestSet.edges[0].node.id,
            price: `${newValue}`,
          },
          () => setShowEmulatePriceDlg(false),
        )}
        title="Set Market Price"
        defaultValue={contract.latestPriceLocal}
      />

      <SingleTextFieldModalDlg
        commitButtonText="Set"
        open={showSetCachePriceDlg}
        onClose={() => setShowSetCachePriceDlg(false)}
        onChangePromise={(newValue) => {
          if (Number(newValue) <= 0) {
            notiStack.error('Price must be positive');
            return;
          }
          doCommitPromise(
            setContractCachePrice,
            {
              contractId: contract.id,
              price: newValue,
            },
            () => setShowSetCachePriceDlg(false),
          );
        }}
        title="Set Price in Cache"
        defaultValue={contract.latestPriceLocal}
        fieldInputType="number"
      />

      <TitledWidgetContainer>

        {/*Contract Details*/}
        <TitledWidgetItem title="Contract Details" xs={12} md={4}>
          <VerticalTable
            columnMeta={contractDetailColumns}
            dataNode={contract}
            showHeadings={false}
          />
        </TitledWidgetItem>

        {/*Factset Details*/}
        {
          contract.fsContract && (
            <TitledWidgetItem title="Factset Contract Details/Price" xs={12} md={4}>
              <VerticalTable
                columnMeta={factsetContractDetails}
                dataNode={contract.fsContract}
                showHeadings={false}
              />
            </TitledWidgetItem>
          )
        }

        {/*Image*/}
        <TitledWidgetItem title="Image" xs={12} md={4}>
          <Grid container>
            <Grid item xs={12}>
              <ContractIconWidget imageUrl={imageUrl} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FileUpload
                    onChange={(filesIn) => {
                      setImageUrl(URL.createObjectURL(filesIn[0]));
                      setImage(filesIn[0]);
                    }}
                    singleFile
                    displayList={false}
                    acceptedMimeTypes={FileUpload.imageMimeTypes}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <UploadButton
                    disabled={!image}
                    buttonText="Upload"
                    color="primary"
                    onClickPromise={() => doCommitPromise(
                      uploadImageContract,
                      {
                        contractId: contract.id,
                        updateShared,
                      },
                      () => {
                        setImage(null);
                        // setImageUrl(contract.imageUrl);
                      },
                      () => null,
                      (store) => {
                        const payload = store.getRootField('uploadContractImage');
                        const newImageUrl = payload.getLinkedRecord('contract').getValue('imageUrl');
                        setImageUrl(newImageUrl);
                      },
                      {
                        map: '{ "0": ["variables.file"]}',
                        0: image,
                      },
                    )}
                  />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        size="small"
                        checked={updateShared}
                        onChange={e => setUpdateShared(e.target.checked)}
                        color="primary"
                      />
                    )}
                    label="Update contracts share the image"
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </TitledWidgetItem>


        {/*Contract Price Details*/}
        <TitledWidgetItem title="Cached Price Details" xs={12} md={4}>
          <VerticalTable
            columnMeta={contractPriceDetailColumns}
            dataNode={contract}
            showHeadings={false}
          />
        </TitledWidgetItem>

        {/*Factset Details*/}
        {
          contract.contractmarketdatarequestSet.edges.length > 0 && (
            <TitledWidgetItem title="Market Data Requests" xs={12} md={4}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    contract.contractmarketdatarequestSet.edges.map(request => (
                      <TableRow>
                        <TableCell>{toLocal(request.node.id)}</TableCell>
                        <TableCell align="right">
                          <TableButton
                            buttonText="Post Price"
                            color="primary"
                            onClick={() => setShowEmulatePriceDlg(true)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TitledWidgetItem>
          )
        }

      </TitledWidgetContainer>
    </div>
  );
}

const query = graphql`
  query ContractDetailsQuery (
    $contractId: ID!
  ) {
    viewer {
      contracts (id: $contractId) {
        edges {
          node {
            ...ContractDetails_contract
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  ContractDetails,
  {
    contract: graphql`
      fragment ContractDetails_contract on ContractNode {
        id
        symbol
        currency
        imageUrl
        createdAt
        updatedAt
        ibStatus
        ibConId
        instrumentType
        name
        exchange {
          symbol
        }

        latestPriceAud
        latestPriceLocal
        latestPriceDate
        latestPriceTime
        
        priceIncrements {
          increment
          lowEdge
        }

        priceCashInfo {
          timeSet
          timeOfPrice
          price
          source
          currency
        }
        
        contractmarketdatarequestSet {
          edges {
            node {
              id
            }
          }
        }
        
        fsContract {
          id
          isin
          name
          country
          industry
          sedol
          sector
          lotSize
          currency

          factsetlatestcontractfinancialdata {
            
            date
            closingPriceLocal
            closingPriceLocal
          }
        }
      }
    `,
  },
);

export default withRouter(
  createQueryRendererProgressV2(
    query,
    container,
    {},
    ({ contractId }) => ({ contractId }),
  ),
);
