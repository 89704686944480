/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TenantReportList_viewer$ref: FragmentReference;
declare export opaque type TenantReportList_viewer$fragmentType: TenantReportList_viewer$ref;
export type TenantReportList_viewer = {|
  +tenantReports: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +xlsxUrl: ?string,
        +remittanceadvice: ?{|
          +startDate: ?any,
          +endDate: any,
          +coverageDate: any,
        |},
      |}
    |}>,
    +count: ?number,
  |},
  +tenants: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +tenantAccounts: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +name: ?string,
              +latestWithdrawalDate: ?any,
              +postWithdrawalCashTransferCount: ?number,
            |}
          |}>,
          +count: ?number,
        |}
      |}
    |}>
  |},
  +$refType: TenantReportList_viewer$ref,
|};
export type TenantReportList_viewer$data = TenantReportList_viewer;
export type TenantReportList_viewer$key = {
  +$data?: TenantReportList_viewer$data,
  +$fragmentRefs: TenantReportList_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "TenantReportList_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "tenantId",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tenantReports",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-createdAt"
        },
        {
          "kind": "Variable",
          "name": "tenantId",
          "variableName": "tenantId"
        }
      ],
      "concreteType": "TenantReportNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantReportNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantReportNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "xlsxUrl",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "remittanceadvice",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "RemittanceAdviceNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "startDate",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "endDate",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "coverageDate",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        },
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "tenants",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "tenantId"
        }
      ],
      "concreteType": "TenantNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "TenantNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantNode",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tenantAccounts",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantAccountNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TenantAccountNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "TenantAccountNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "name",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "latestWithdrawalDate",
                              "args": null,
                              "storageKey": null
                            },
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "postWithdrawalCashTransferCount",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    },
                    (v0/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a97ac24ae710e2d8effce92aff35d4f';
module.exports = node;
