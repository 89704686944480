import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import BrokerOrderStatus from '../common/BrokerOrderStatus';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterToggleButtonGroup from '../common/innerTab/InnerTabFilterToggleButtonGroup';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';

function ContractInfo(props) {
  const { orderNode } = props;
  if (orderNode.stockorder) {
    return orderNode.stockorder.contract.symbol;
  }
  if (orderNode.fxorder) {
    return orderNode.fxorder.contract.localSymbol;
  }
  return null;
}

function BrokerOrderList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const [statusIn, setStatusIn] = React.useState(filter.params.statusIn);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.field(node => node.orderType, 'Type'),
    TableFieldMeta.fieldCell('symbol', 'Symbol', row => (
      <ContractInfo orderNode={row.value.orderSet.edges[0].node} />)),
    TableFieldMeta.dateTimeField('lastSyncTime', 'Last Sync'),
    TableFieldMeta.field('orderRef', 'Order Ref.', { align: 'right' }),
    TableFieldMeta.field('quantity', 'Qty', { align: 'right' }),
    TableFieldMeta.field('quantityFilled', 'Filled', { align: 'right' }),
    TableFieldMeta.field('quantityLeft', 'Remaining', { align: 'right' }),
    TableFieldMeta.field('twsPermId', 'Perm ID', { align: 'right' }),
    TableFieldMeta.field('twsOrderId', 'TWS ID', { align: 'right' }),
    TableFieldMeta.field(node => (
      <BrokerOrderStatus stockOrder={node} />
    ), 'Status', { align: 'center' }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterSelectField
          enumType="OrderStatus"
          helperText="Status-In"
          multiple
          withEmpty={false}
          value={statusIn}
          onChange={(e) => {
            setStatusIn(e.target.value);
            filter.updateVar('statusIn', e.target.value);
          }}
        />

        <InnerTabFilterGridItemTextField
          id="order-ref-filter"
          label="Order Ref."
          helperText="Order Reference"
          defaultValue={filter.params.orderRef}
          onChange={e => filter.updateVar('orderRef', e.target.value)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: 'STK', description: 'STK' },
            { name: 'CASH', description: 'FX' },
            { name: 'WAR', description: 'WAR' },
          ]}
          helperText="Instrument Type"
          defaultValue={filter.params.instrumentType}
          onChange={newVal => filter.updateVar('instrumentType', newVal)}
        />

        <InnerTabFilterToggleButtonGroup
          enumType={[
            { name: null, description: '∅' },
            { name: true, description: 'Yes' },
            { name: false, description: 'No' },
          ]}
          helperText="Matched?"
          defaultValue={filter.params.matched}
          onChange={newVal => filter.updateVar('matched', newVal)}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.brokerOrders : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query BrokerOrderListQuery (
    $first: Int
    $offset: Int
    $statusIn: [String]
    $instrumentType: String
    $matched: Boolean
    $orderRef: String
  ) {
    viewer {
      ...BrokerOrderList_viewer
      @arguments (
        first: $first
        offset: $offset
        statusIn: $statusIn
        instrumentType: $instrumentType
        matched: $matched
        orderRef: $orderRef
      )
    }
  }
`;

const container = createRefetchContainer(
  BrokerOrderList,
  {
    viewer: graphql`
      fragment BrokerOrderList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        statusIn: {type: "[String]"}
        instrumentType: {type: String}
        matched: {type: Boolean, defaultValue: null}
        orderRef: {type: String, defaultValue: null}
      ) {
        brokerOrders(
          first: $first
          offset: $offset
          statusIn: $statusIn
          instrumentType: $instrumentType
          matched: $matched
          orderBy: "-id"
          orderRef: $orderRef
        ) {
          edges {
            node {
              id
              createdAt
              orderType
              matched
              quantity
              quantityFilled
              quantityLeft
              status
              instrumentType
              orderRef
              side
              lastSyncTime
              twsPermId
              twsOrderId
              parentOrder {
                id
              }
              quantity
              orderSet {
                edges {
                  node {
                    id
                    stockorder {
                      contract {
                        id
                        symbol
                      }
                    }
                    fxorder {
                      contract {
                        id
                        localSymbol
                      }
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
    statusIn: [],
    instrumentType: null,
    matched: null,
  }),
  {
    debounceFields: ['statusIn', 'orderRef'],
  },
));
