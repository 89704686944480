/**
 * @flow
 * @relayHash 9cf7bb1a52c6932452e3fcd50926cd9c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type ApproveCashTransferRequestMutationVariables = {|
  transferRequestId?: ?string
|};
export type ApproveCashTransferRequestMutationResponse = {|
  +approveCashTransfer: ?{|
    +cashTransferRequest: ?{|
      +id: string,
      +status: ?CashTransferStatus,
      +createdAt: any,
      +updatedAt: any,
      +cashtransferrequestapprovalSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +approvedAt: any,
            +approvedBy: {|
              +id: string,
              +fullName: ?string,
            |},
          |}
        |}>
      |},
      +cashTransfer: ?{|
        +createdAt: any,
        +updatedAt: any,
        +id: string,
        +brokerActivity: {|
          +brokeractivitytransactionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +createdAt: any,
              |}
            |}>
          |}
        |},
      |},
    |}
  |}
|};
export type ApproveCashTransferRequestMutation = {|
  variables: ApproveCashTransferRequestMutationVariables,
  response: ApproveCashTransferRequestMutationResponse,
|};
*/


/*
mutation ApproveCashTransferRequestMutation(
  $transferRequestId: ID
) {
  approveCashTransfer(cashTransferRequestId: $transferRequestId) {
    cashTransferRequest {
      id
      status
      createdAt
      updatedAt
      cashtransferrequestapprovalSet {
        edges {
          node {
            id
            approvedAt
            approvedBy {
              id
              fullName
            }
          }
        }
      }
      cashTransfer {
        createdAt
        updatedAt
        id
        brokerActivity {
          brokeractivitytransactionSet {
            edges {
              node {
                id
                createdAt
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferRequestId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cashTransferRequestId",
    "variableName": "transferRequestId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cashtransferrequestapprovalSet",
  "storageKey": null,
  "args": null,
  "concreteType": "CashTransferRequestApprovalNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "CashTransferRequestApprovalNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "CashTransferRequestApprovalNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "approvedAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "approvedBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "fullName",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v7 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "brokeractivitytransactionSet",
  "storageKey": null,
  "args": null,
  "concreteType": "BrokerActivityTransactionNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "BrokerActivityTransactionNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "BrokerActivityTransactionNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ApproveCashTransferRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approveCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveCashTransferRequest",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequest",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cashTransfer",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokerActivity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ApproveCashTransferRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "approveCashTransfer",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "ApproveCashTransferRequest",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequest",
            "storageKey": null,
            "args": null,
            "concreteType": "CashTransferRequestNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cashTransfer",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferNode",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "brokerActivity",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "ApproveCashTransferRequestMutation",
    "id": null,
    "text": "mutation ApproveCashTransferRequestMutation(\n  $transferRequestId: ID\n) {\n  approveCashTransfer(cashTransferRequestId: $transferRequestId) {\n    cashTransferRequest {\n      id\n      status\n      createdAt\n      updatedAt\n      cashtransferrequestapprovalSet {\n        edges {\n          node {\n            id\n            approvedAt\n            approvedBy {\n              id\n              fullName\n            }\n          }\n        }\n      }\n      cashTransfer {\n        createdAt\n        updatedAt\n        id\n        brokerActivity {\n          brokeractivitytransactionSet {\n            edges {\n              node {\n                id\n                createdAt\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d14da74ed9180911de456219336ab3f';
module.exports = node;
