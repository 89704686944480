import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { commitMutation, createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { commonStyles } from '../common/Styles';
import PaginatedTable from '../common/PaginatedTable';
import TableFieldMeta from '../../common/TableFieldMeta';
import DateTime from '../common/DateTimeMoment';
import TableButton from '../common/buttons/TableButton';
import withDebounce from '../../common/debounce';
import environment from '../../Environment';
import updateVueCommunicationMutation from '../../mutations/UpdateVueCommunication';
import addVueCommunicationMutation from '../../mutations/AddVueCommunication';
import { MVA_AUTH_TOKEN_TAG, ROWS_PER_PAGE } from '../../common/constants';
import testVueCommunicationMutation from '../../mutations/TestVueCommunication';
import BaseButton, { AddButton, CloseButton } from '../common/buttons/BaseButton';
import publishVueCommunicationMutation from '../../mutations/PublishVueCommunication';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import notiStack from '../../common/notiStack';
import { fromGlobalId, toLocal } from '../../helpers/ID';
import { doCommitPromise } from '../../common/commit';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
}));

const delayedUpdate = withDebounce(
  (doCommit, heading, message) => {
    doCommit(heading, message);
  },
  500,
);

function VueCommunicationList(props) {
  const classes = useStyles();
  const editorRef = React.useRef(null);
  const { viewer, relay } = props;
  const [activeCommunication, setActiveCommunication] = useState(null);
  const [readOnly, setReadonly] = useState(false);
  const [heading, setHeading] = useState(null);
  const [message, setMessage] = useState(null);
  const vue = viewer.vues.edges[0].node;
  if (vue.tenant == null || parseInt(toLocal(vue.tenant.id), 10) !== 1) {
    return null;
  }

  const [tableRef] = useState(React.createRef());

  const getDataNode = () => {
    if (viewer && viewer.vues) {
      return viewer.vues.edges[0].node.vuecommunicationSet;
    }
    return null;
  };

  const edit = (communication) => {
    setActiveCommunication(communication);
    if (communication) {
      setReadonly(false);
      setHeading(communication.heading);
      setMessage(communication.message);
    }
  };

  const view = (communication) => {
    setActiveCommunication(communication);
    if (communication) {
      setReadonly(true);
      setHeading(communication.heading);
      setMessage(communication.message);
    }
  };

  const commitUpdate = (newHeading, newMessage) => {
    const variables = {
      heading: newHeading,
      message: newMessage,
      first: ROWS_PER_PAGE,
      communicationId: activeCommunication.id,
    };
    commitMutation(environment, {
      mutation: updateVueCommunicationMutation,
      variables,
    });
  };

  const addVueCommunication = (newHeading, newMessage) => {
    const variables = {
      heading: newHeading,
      message: newMessage,
      first: ROWS_PER_PAGE,
      vueId: vue.id,
    };
    commitMutation(environment, {
      mutation: addVueCommunicationMutation,
      variables,
      onCompleted: () => {
        tableRef.current.refreshData();
      },
    });
  };

  const columns = [
    TableFieldMeta.localId,
    {
      id: 'heading',
      Header: 'Heading',
      accessor: 'heading',
    },
    {
      id: 'status',
      Header: '',
      accessor: node => node,
      Cell: (row) => {
        const key = `${row.value.id}-${row.value.updatedAt}`;
        return (
          <Grid container spacing={1} justify="center">
            <Grid item>
              <TableButton
                key={key}
                color="primary"
                onClick={() => addVueCommunication(row.value.heading, row.value.message)}
              >
                Clone
              </TableButton>
            </Grid>

            {
              row.value.published ? (
                <React.Fragment>
                  <Grid item>
                    <TableButton
                      key={key}
                      color="secondary"
                      disabled
                    >
                      Published
                    </TableButton>
                  </Grid>
                  <Grid item>
                    <TableButton
                      key={key}
                      color="primary"
                      onClick={() => view(row.value)}
                    >
                      View
                    </TableButton>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item>
                    <TableButton
                      key={key}
                      onClickPromise={() => doCommitPromise(
                        publishVueCommunicationMutation,
                        { communicationId: row.value.id },
                        () => null,
                        () => null,
                      )}
                    >
                      Publish
                    </TableButton>
                  </Grid>
                  <Grid item>
                    <TableButton
                      key={key}
                      color="primary"
                      onClick={() => edit(row.value)}
                    >
                      Edit
                    </TableButton>
                  </Grid>
                </React.Fragment>
              )
            }
          </Grid>
        );
      },
    },
    {
      id: 'publishedAt',
      Header: 'Published At',
      accessor: node => node,
      Cell: row => <DateTime>{row.value.publishedAt}</DateTime>,
    },
    TableFieldMeta.createdAt,
  ];

  const tinyMceImageUploader = (blobInfo, success, failure, progress) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    const uploadUrl = `${process.env.REACT_APP_API}/api/admin/file_upload`
              + '?responseType=json&type=vueCommunication&communicationId='
              + `${fromGlobalId(activeCommunication.id)[1]}`;
    xhr.open('POST', uploadUrl);
    xhr.setRequestHeader('Authorization', `JWT ${localStorage.getItem(MVA_AUTH_TOKEN_TAG)}`);

    xhr.upload.onprogress = function onProgress(e) {
      progress(e.loaded / e.total * 100);
    };

    xhr.onload = function onLoad() {
      if (xhr.status === 403) {
        failure(`HTTP Error: ${xhr.status}`, { remove: true });
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure(`HTTP Error: ${xhr.status}`);
        return;
      }

      const json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != 'string') {
        failure(`Invalid JSON: ${xhr.responseText}`);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function onError() {
      failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`);
    };

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    xhr.send(formData);
  };


  return (
    <div className={classes.innerTabRoot}>

      <Grid container spacing={2}>
        {
          activeCommunication && (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  id="standard-helperText"
                  label="Heading"
                  defaultValue={heading}
                  value={heading}
                  fullWidth
                  helperText="e-mail heading for vue communication"
                  onChange={(e) => {
                    setHeading(e.target.value);
                    delayedUpdate(commitUpdate, e.target.value, message);
                  }}
                  disabled={activeCommunication && activeCommunication.published}
                />
              </Grid>
              <Grid item xs={12}>
                <Editor
                  apiKey="a8qgiyvltg7em6721d436udoeub9uzch1cocxzsj4si4qbpe"
                  onInit={(e, editor) => {
                    editorRef.current = editor;
                  }}
                  initialValue={message}
                  onEditorChange={(editorText, b, c, d, e) => {
                    delayedUpdate(commitUpdate, heading, editorText);
                  }}
                  init={{
                    height: 500,
                    menubar: 'file edit view insert format',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar: 'undo redo | code | image | formatselect | '
                      + 'bold italic underline backcolor | alignleft aligncenter '
                      + 'alignright alignjustify | bullist numlist outdent indent | '
                      + 'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    selector: 'textarea',
                    images_upload_handler: tinyMceImageUploader,
                    automatic_uploads: true,
                  }}
                />
              </Grid>
            </React.Fragment>
          )
        }

        <Grid item xs={12}>
          <BottomGridContainer className={classes.shortBottomButtonContainer}>
            {
              !activeCommunication && (
                <Grid item>
                  <AddButton
                    color="primary"
                    buttonText="Add Communication"
                    onClick={e => addVueCommunication('New Vue Update', 'New Vue Update')}
                  />
                </Grid>
              )
            }
            {
              activeCommunication && (
                <Grid item>
                  <CloseButton
                    onClick={e => edit(null)}
                  />
                </Grid>
              )
            }
            {
              activeCommunication && !readOnly && (
                <Grid item>
                  <BaseButton
                    color="primary"
                    onClickPromise={e => doCommitPromise(
                      testVueCommunicationMutation,
                      { communicationId: activeCommunication.id },
                      (response, errors) => {
                        if (!errors && response.testVueCommunication) {
                          notiStack.success(response.testVueCommunication.message);
                        }
                      },
                      () => null,
                    )}
                    buttonText="Test e-mail"
                  />
                </Grid>
              )
            }
          </BottomGridContainer>
        </Grid>

        {
          !activeCommunication && (
            <Grid item xs={12}>
              <PaginatedTable
                columns={columns}
                getDataNode={getDataNode}
                viewer={viewer}
                relay={relay}
                ref={tableRef}
              />
            </Grid>
          )
        }

      </Grid>

    </div>
  );
}

export default createRefetchContainer(
  VueCommunicationList,
  {
    viewer: graphql`
      fragment VueCommunicationList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int, defaultValue: 0}
        vueId: {type: ID}
      )
      {
        vues (
          id: $vueId
        ){
          edges {
            node {
              tenant {
                id
              }
              id
              vuecommunicationSet (
                first: $first
                offset: $offset
                orderBy: "-createdAt"
              ){
                count
                edges {
                  node {
                    id
                    heading
                    published
                    publishedAt
                    createdAt
                    updatedAt
                    message
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query VueCommunicationListRefetchQuery(
      $first: Int!
      $offset: Int!
      $vueId: ID!
    ) {
      viewer {
        ...VueCommunicationList_viewer
        @arguments(
          first: $first,
          offset: $offset,
          vueId: $vueId,
        )
      }
    }
  `,
);
