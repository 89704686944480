/**
 * @flow
 * @relayHash f3785b451f78365ce911e45a0ac347d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrokerTransactionDetails_viewer$ref = any;
export type BrokerTransactionDetailsQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  brokerTransactionId?: ?string,
|};
export type BrokerTransactionDetailsQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BrokerTransactionDetails_viewer$ref
  |}
|};
export type BrokerTransactionDetailsQuery = {|
  variables: BrokerTransactionDetailsQueryVariables,
  response: BrokerTransactionDetailsQueryResponse,
|};
*/


/*
query BrokerTransactionDetailsQuery(
  $brokerTransactionId: ID
) {
  viewer {
    ...BrokerTransactionDetails_viewer_4h9cV0
    id
  }
}

fragment BrokerTransactionDetails_viewer_4h9cV0 on Query {
  ibBrokerTransactions(id: $brokerTransactionId) {
    edges {
      node {
        id
        brokertransactionPtr {
          id
          matched
          updatedAt
          createdAt
          transactions {
            edges {
              node {
                id
                currency
                dummy
                proceeds
                quantity
                account {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "brokerTransactionId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerTransactionDetailsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BrokerTransactionDetails_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "brokerTransactionId",
                "variableName": "brokerTransactionId"
              },
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "offset",
                "variableName": "offset"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerTransactionDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibBrokerTransactions",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "brokerTransactionId"
              }
            ],
            "concreteType": "IBBrokerTransactionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBBrokerTransactionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBBrokerTransactionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "brokertransactionPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerTransactionNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "matched",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "updatedAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "transactions",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "TransactionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "TransactionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "TransactionNode",
                                    "plural": false,
                                    "selections": [
                                      (v1/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "currency",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "dummy",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "proceeds",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "quantity",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "account",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AccountNode",
                                        "plural": false,
                                        "selections": [
                                          (v1/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BrokerTransactionDetailsQuery",
    "id": null,
    "text": "query BrokerTransactionDetailsQuery(\n  $brokerTransactionId: ID\n) {\n  viewer {\n    ...BrokerTransactionDetails_viewer_4h9cV0\n    id\n  }\n}\n\nfragment BrokerTransactionDetails_viewer_4h9cV0 on Query {\n  ibBrokerTransactions(id: $brokerTransactionId) {\n    edges {\n      node {\n        id\n        brokertransactionPtr {\n          id\n          matched\n          updatedAt\n          createdAt\n          transactions {\n            edges {\n              node {\n                id\n                currency\n                dummy\n                proceeds\n                quantity\n                account {\n                  id\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ad1f2314580064ebc79f74a8760f95b';
module.exports = node;
