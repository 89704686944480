import React from 'react';
import {
  withRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useCommonStyles } from './Styles';
import { permitted } from '../../common/helpers';

function RoutableTabContainer(props) {
  const {
    match,
    tabItems: tabItemsUnfiltered,
    permissionEdges,
    location,
    history,
  } = props;

  const classes = useCommonStyles();
  const tabItems = permitted(tabItemsUnfiltered, permissionEdges, 'permission');
  const [members] = React.useState({
    tabURLToItem: Object.fromEntries(tabItems.map((item, i) => [i, item.url])),
  });

  const getTabIndex = () => {
    for (let tabIndex = 0; tabIndex < tabItems.length; tabIndex += 1) {
      if (match) {
        const fullPath = `${match.url}/${tabItems[tabIndex].url}`;
        if (location.pathname.startsWith(fullPath)) {
          return tabIndex;
        }
      }
    }
    return null;
  };

  const onTabChange = (event, tabIndex) => {
    const targetPath = `${match.url}/${members.tabURLToItem[tabIndex]}`;
    history.push(targetPath);
  };

  const tabIndex = getTabIndex();

  return (
    <div className={classes.pageRoot}>
      <Paper square>
        <Tabs
          value={tabIndex}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {
            tabItems.map(
              tabItem => (
                <Tab key={tabItem.label} label={tabItem.label} />
              ),
            )
          }
        </Tabs>
      </Paper>
      <Switch>
        {
          tabItems.map(
            (tabItem) => {
              let childProps = {};
              const CompClass = tabItem.comp;
              if (tabItem.propCopyFn) {
                childProps = tabItem.propCopyFn(props);
              }
              return (
                <Route
                  key={tabItem.label}
                  path={`${match.url}/${tabItem.url}`}
                  render={
                    () => (
                      <CompClass {...childProps} />
                    )
                  }
                />);
            },
          )
        }
        {
          tabItems.length > 0
          && <Redirect to={`${match.url}/${tabItems[0].url}`} />
        }
      </Switch>
    </div>
  );
}

const StyledRoutableRoutableTabContainer = withRouter(RoutableTabContainer);

export const withTabItems = tabItems => (props) => {
  const { permissionEdges } = props;
  return (
    <StyledRoutableRoutableTabContainer
      tabItems={tabItems}
      permissionEdges={permissionEdges}
    />
  );
};

export default StyledRoutableRoutableTabContainer;
