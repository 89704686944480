import { graphql } from 'react-relay';

const approvePortfolioOrderRequestMutation = graphql`
  mutation ApprovePortfolioOrderRequestMutation(
    $approvableId: ID!
  ) {
    approveApprovable(
      approvableId: $approvableId
    ) {
      approvable {
        __typename
        ... on PortfolioOrderRequestNode {
          status
          approvals {
            edges {
              node {
                id
                approvedAt
                approvedBy {
                  username
                  fullName
                  email
                }
              }
            }
          }
          portfolioOrder {
            id
            stockOrders {
              edges {
                node {
                  id
                  contract {
                    id
                    symbol
                  }
                  
                  brokerOrder {
                    id
                    status
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default approvePortfolioOrderRequestMutation;
