import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useCommonStyles } from '../common/Styles';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemDateField from '../common/drawer/DrawerGridItemDateField';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import startUserSubscription from '../../mutations/StartUserSubscription';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { AddButton } from '../common/buttons/BaseButton';
import SubscriptionTemplateLoader from '../common/fieldLoaders/SubscriptionTemplateLoader';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import { doCommitPromise } from '../../common/commit';

function UserSubscriptionForm(props) {
  const { user, onClose, onAdd } = props;
  const classes = useCommonStyles();
  const [subscriberType, setSubscriberType] = React.useState('CLIENT');
  const [subscriptionTemplate, setSubscriptionTemplate] = React.useState(null);
  const [account, setAccount] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);

  return (
    <div className={classes.drawerRoot}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading="User Subscription"
        />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            onSelect={a => setAccount(a)}
          />
        </Grid>

        <Grid item xs={12}>
          <SubscriptionTemplateLoader
            className={classes.fromControlDrawer}
            id="template"
            label="Template"
            type="text"
            defaultValue=""
            onChange={a => setSubscriptionTemplate(a)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <DrawerGridItemDateField
          xs={6}
          label="Start Date"
          onChange={sd => setStartDate(sd)}
          value={startDate}
          autoOk
        />

        <DrawerGridItemSelectField
          xs={6}
          enumType="SubscriberType"
          onChange={e => setSubscriberType(e.target.value)}
        />

      </Grid>

      <BottomGridContainer>
        <AddButton
          onClickPromise={() => doCommitPromise(
            startUserSubscription,
            {
              userId: user.id,
              paymentAccountId: account.id,
              subscriptionTemplateId: subscriptionTemplate.id,
              subscriberType,
              startDate,
            },
            () => {
              if (onAdd) onAdd();
              if (onClose) onClose();
            },
          )}
        />
      </BottomGridContainer>
    </div>
  );
}

export default UserSubscriptionForm;
