/**
 * @flow
 * @relayHash 2ff82f856101b6a973c9cec5e7b2592a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type UserClientAccountPermissionInput = {|
  resource?: ?Resource,
  verb?: ?Verb,
|};
export type UpdateUserPermissionMutationVariables = {|
  userId: string,
  clientAccountId: string,
  userRoleIds: $ReadOnlyArray<?string>,
  userClientAccountPermissions?: ?$ReadOnlyArray<?UserClientAccountPermissionInput>,
|};
export type UpdateUserPermissionMutationResponse = {|
  +updateUserPermissions: ?{|
    +userModel: ?{|
      +id: string
    |}
  |}
|};
export type UpdateUserPermissionMutation = {|
  variables: UpdateUserPermissionMutationVariables,
  response: UpdateUserPermissionMutationResponse,
|};
*/


/*
mutation UpdateUserPermissionMutation(
  $userId: ID!
  $clientAccountId: ID!
  $userRoleIds: [ID]!
  $userClientAccountPermissions: [UserClientAccountPermissionInput]
) {
  updateUserPermissions(userId: $userId, clientAccountId: $clientAccountId, userRoleIds: $userRoleIds, userClientAccountPermissions: $userClientAccountPermissions) {
    userModel {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userRoleIds",
    "type": "[ID]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userClientAccountPermissions",
    "type": "[UserClientAccountPermissionInput]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateUserPermissions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "userClientAccountPermissions",
        "variableName": "userClientAccountPermissions"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      },
      {
        "kind": "Variable",
        "name": "userRoleIds",
        "variableName": "userRoleIds"
      }
    ],
    "concreteType": "UpdateUserPermissions",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userModel",
        "storageKey": null,
        "args": null,
        "concreteType": "UserModelNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateUserPermissionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateUserPermissionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateUserPermissionMutation",
    "id": null,
    "text": "mutation UpdateUserPermissionMutation(\n  $userId: ID!\n  $clientAccountId: ID!\n  $userRoleIds: [ID]!\n  $userClientAccountPermissions: [UserClientAccountPermissionInput]\n) {\n  updateUserPermissions(userId: $userId, clientAccountId: $clientAccountId, userRoleIds: $userRoleIds, userClientAccountPermissions: $userClientAccountPermissions) {\n    userModel {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4e05483b84c25596d878ebd5a29bf70';
module.exports = node;
