import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import notiStack from '../../common/notiStack';
import FileUpload from '../common/FileUpload';
import { doCommitPromise } from '../../common/commit';
import { useCommonStyles } from '../common/Styles';
import { UpdateButton } from '../common/buttons/BaseButton';
import DrawerGridItemSubGroupHeading from '../common/drawer/DrawerGridItemSubGroupHeading';
import updateVueResearchMutation from '../../mutations/UpdateVueResearch';
import { toLocal } from '../../helpers/ID';


function VueResearchUpdateForm(props) {
  const {
    vueResearch, onClose,
  } = props;
  const classes = useCommonStyles();
  const [title, setTitle] = React.useState(vueResearch.title);
  const [researchPdf, setResearchPdf] = React.useState(null);

  const doUpdateVueResearch = () => {
    const uploadables = researchPdf ? {
      map: '{ "0": ["variables.researchPdf"]}',
      0: researchPdf,
    } : null;

    doCommitPromise(
      updateVueResearchMutation,
      {
        vueResearchId: vueResearch.id,
        title,
      },
      () => {
        notiStack.success('Vue research details updated');
        onClose();
      },
      () => null,
      () => null,
      uploadables,
    );
  };

  return (
    <div className={classes.drawerRoot}>
      <Typography variant="h6" gutterBottom>
        Update Vue Research
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-number"
            label="Title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Grid>
        <DrawerGridItemSubGroupHeading heading="Research" />
        { vueResearch.researchPdfUrl && (
          <Grid item xs={12}>
            <Chip
              size="small"
              icon={<AttachmentIcon />}
              label={`Vue Research ID ${toLocal(vueResearch.id)}`}
              clickable
              component="a"
              href={vueResearch.researchPdfUrl}
              color="primary"
              target="_blank"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12}>
          <FileUpload
            onChange={(filesIn) => {
              setResearchPdf(filesIn[0]);
            }}
            singleFile
            displayList
            acceptedMimeTypes={['application/pdf']}
          />
        </Grid>
      </Grid>
      <div className={classes.bottomButtonContainer}>
        <UpdateButton
          onClickPromise={doUpdateVueResearch}
          buttonText="Update"
        />
      </div>
    </div>
  );
}

export default VueResearchUpdateForm;
