import React from 'react';
import { graphql } from 'react-relay';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useCommonStyles } from '../common/Styles';
import { fromGlobalId } from '../../helpers/ID';
import TableButton from '../common/buttons/TableButton';
import { doCommitPromise } from '../../common/commit';
import createPortfolio from '../../mutations/Portfolio';

export default function AccountStockHoldingPortfolios(props) {
  const {
    portfolios,
    mainVues,
    accountId,
  } = props;
  const vues = mainVues.map(x => x);

  const classes = useCommonStyles();
  return (
    <Paper>
      <div className={classes.tableHolderRoot}>
        <div className={classes.widgetHeadingContainer}>
          <Typography
            component="h6"
            variant="subtitle2"
            gutterBottom
          >
            Portfolios
          </Typography>
        </div>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Tracking Vue</TableCell>
              <TableCell>Portfolio ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              vues.map((vueNode) => {
                const mainVue = vueNode.node;
                const accountVue = portfolios.edges.find(
                  pfNode => (pfNode.node.trackingVue && pfNode.node.trackingVue.id === mainVue.id),
                );
                return (
                  <TableRow>
                    <TableCell>{mainVue.name}</TableCell>
                    <TableCell>
                      { accountVue ? fromGlobalId(accountVue.node.id)[1] : (
                        <TableButton
                          buttonText="create"
                          color="primary"
                          onClickPromise={() => doCommitPromise(
                            createPortfolio,
                            {
                              vueId: mainVue.id,
                              accountId,
                            },
                            () => null,
                            () => null,
                          )}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            }

          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

graphql`
  fragment AccountStockHoldingPortfolios_portfolios on AccountNode {
    portfolios {
      edges {
        node {
          id
          trackingVue {
            id
            name
          }
        }
      }
    }
    defaultPortfolio
  }
`;

graphql`
  fragment AccountStockHoldingPortfolios_mainVues on Query {
    vues(
      orderBy: "name"
      vueType: "MV"
      parentVue_Isnull: true
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
