/**
 * @flow
 * @relayHash 931439db26770dc2249d03e517ede20a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DividendDefInput = {|
  exDate: any,
  payDate: any,
  rateFranked?: ?number,
  frankingTaxRate?: ?number,
  dividendAmount?: ?number,
  currency?: ?string,
|};
export type BrokerActivityGroupUpdateMutationVariables = {|
  brokerActivityGroupId: string,
  description: string,
  dividendDef?: ?DividendDefInput,
|};
export type BrokerActivityGroupUpdateMutationResponse = {|
  +updateBrokerActivityGroup: ?{|
    +brokerActivityGroup: ?{|
      +id: string,
      +description: ?string,
      +dividenddef: ?{|
        +id: string,
        +dividendAmount: ?number,
      |},
      +brokeractivitySet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +group: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type BrokerActivityGroupUpdateMutation = {|
  variables: BrokerActivityGroupUpdateMutationVariables,
  response: BrokerActivityGroupUpdateMutationResponse,
|};
*/


/*
mutation BrokerActivityGroupUpdateMutation(
  $brokerActivityGroupId: ID!
  $description: String!
  $dividendDef: DividendDefInput
) {
  updateBrokerActivityGroup(brokerActivityGroupId: $brokerActivityGroupId, description: $description, dividendDef: $dividendDef) {
    brokerActivityGroup {
      id
      description
      dividenddef {
        id
        dividendAmount
      }
      brokeractivitySet {
        edges {
          node {
            id
            group {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "brokerActivityGroupId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "dividendDef",
    "type": "DividendDefInput",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateBrokerActivityGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "brokerActivityGroupId",
        "variableName": "brokerActivityGroupId"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "dividendDef",
        "variableName": "dividendDef"
      }
    ],
    "concreteType": "UpdateBrokerActivityGroup",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "brokerActivityGroup",
        "storageKey": null,
        "args": null,
        "concreteType": "BrokerActivityGroupNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "dividenddef",
            "storageKey": null,
            "args": null,
            "concreteType": "DividendDefNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dividendAmount",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "brokeractivitySet",
            "storageKey": null,
            "args": null,
            "concreteType": "BrokerActivityNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BrokerActivityNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BrokerActivityNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "group",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BrokerActivityGroupNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BrokerActivityGroupUpdateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "BrokerActivityGroupUpdateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "BrokerActivityGroupUpdateMutation",
    "id": null,
    "text": "mutation BrokerActivityGroupUpdateMutation(\n  $brokerActivityGroupId: ID!\n  $description: String!\n  $dividendDef: DividendDefInput\n) {\n  updateBrokerActivityGroup(brokerActivityGroupId: $brokerActivityGroupId, description: $description, dividendDef: $dividendDef) {\n    brokerActivityGroup {\n      id\n      description\n      dividenddef {\n        id\n        dividendAmount\n      }\n      brokeractivitySet {\n        edges {\n          node {\n            id\n            group {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bc57980e514953342c7e22512a1154b';
module.exports = node;
