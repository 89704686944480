import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import { fromGlobalId } from '../../helpers/ID';
import StatelessDownloadButton from '../common/buttons/StatelessDownloadButton';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import { useCommonStyles } from '../common/Styles';


function AccountStockHoldingListClosed(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    accountId,
    openHoldings,
  } = props;
  const [edge] = viewer.accounts.edges;
  const { portfolioContracts } = edge.node;

  const existingSet = new Set();
  openHoldings.map(h => existingSet.add(`${h.contract.id}-${h.portfolio.id}`));
  const closedPortfolioContracts = portfolioContracts.filter(
    pc => !existingSet.has(`${pc.contract.id}-${pc.portfolio.id}`),
  );

  return (
    <Paper>
      <div className={classes.tableHolderRoot}>
        <div className={classes.widgetHeadingContainer}>
          <Typography
            component="h6"
            variant="subtitle2"
            gutterBottom
          >
            Closed Positions
          </Typography>
        </div>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Portfolio</TableCell>
              <TableCell>Vue</TableCell>
              <TableCell>Contract ID</TableCell>
              <TableCell>Ticker</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Trade Summary</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              closedPortfolioContracts.map((portfolioContract) => {
                let tradeSummaryUrl = `${process.env.REACT_APP_API}/api/admin/exports/trade-summary`;
                tradeSummaryUrl += `?contractId=${fromGlobalId(portfolioContract.contract.id)[1]}`;
                tradeSummaryUrl += `&portfolioId=${fromGlobalId(portfolioContract.portfolio.id)[1]}`;
                tradeSummaryUrl += `&accountId=${fromGlobalId(accountId)[1]}`;
                return (
                  <TableRow>
                    <TableCell>
                      {fromGlobalId(portfolioContract.portfolio.id)[1]}
                    </TableCell>
                    <TableCell>
                      {portfolioContract.portfolio.trackingVue ? portfolioContract.portfolio.trackingVue.name : ''}
                    </TableCell>
                    <TableCell>
                      {fromGlobalId(portfolioContract.contract.id)[1]}
                    </TableCell>
                    <TableCell>
                      {portfolioContract.contract.symbol}
                    </TableCell>
                    <TableCell>
                      {portfolioContract.contract.name}
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={1} justify="center">
                        <Grid item>
                          <StatelessDownloadButton
                            downloadUrl={tradeSummaryUrl}
                          >
                            Export
                          </StatelessDownloadButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}


const query = graphql`
  query AccountStockHoldingListClosedQuery(
    $accountId: ID
  ){
    viewer {
      ...AccountStockHoldingListClosed_viewer
      @arguments(
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  AccountStockHoldingListClosed,
  {
    viewer: graphql`
      fragment AccountStockHoldingListClosed_viewer on Query
      @argumentDefinitions(
        accountId: {type: ID}
      )
      {
        accounts(
          id: $accountId
        ) {
          edges {
            node {
              portfolioContracts {
                portfolio {
                  id
                  trackingVue {
                    name
                  }
                }
                contract {
                  id
                  name
                  symbol
                }
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  container,
  (viewer, { accountId, openHoldings }) => ({ accountId, openHoldings }),
  props => ({ accountId: props.accountId }),
);
