import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';


function PopupEntitySelectorField(props) {
  const {
    searchDlgComp: SearchDlgComp,
    onSelect,
    defaultValue,
    caption,
    helperTextDescriber,
    valueDescriber,
    onSetNull,
    ...otherProps
  } = props;
  const [showSearch, setShowSearch] = React.useState(false);
  const [instance, setInstance] = React.useState(defaultValue);
  return (
    <React.Fragment>
      <FormControl
        {...otherProps}
      >
        <InputLabel htmlFor="standard-adornment-password">{caption}</InputLabel>
        <Input
          disabled
          // value={(instance && valueDescriber) ? `${contract.symbol}:${contract.currency}` : ''}
          value={(instance && valueDescriber) ? valueDescriber(instance) : ''}
          endAdornment={(
            <InputAdornment position="end">
              {onSetNull && instance && (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    onSetNull();
                    setInstance(null);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              )}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowSearch(true)}
              >
                <MoreHoriz />
              </IconButton>
            </InputAdornment>
          )}
        />
        {/*<FormHelperText>{contract ? contract.name : ''}</FormHelperText>*/}
        <FormHelperText key={instance}>{ (helperTextDescriber) ? helperTextDescriber(instance) : ''}</FormHelperText>
      </FormControl>

      <SearchDlgComp
        key={showSearch}
        isOpen={showSearch}
        onSelectItems={(items) => {
          setShowSearch(false);
          setInstance(items[0]);
          if (onSelect) onSelect(items[0]);
        }}
        onClose={() => setShowSearch(false)}
      />
    </React.Fragment>
  );
}

PopupEntitySelectorField.propTypes = {
  searchDlgComp: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
  helperTextDescriber: PropTypes.func,
  valueDescriber: PropTypes.func,
  onSelect: PropTypes.func,
  onSetNull: PropTypes.func,
};

PopupEntitySelectorField.defaultProps = {
  helperTextDescriber: null,
  valueDescriber: null,
  onSelect: null,
  onSetNull: null,
};


export default PopupEntitySelectorField;
