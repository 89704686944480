
import { graphql } from 'react-relay';

// language=GraphQL
const feeTemplateMutation = graphql`
  mutation FeeTemplateMutation(
    $input: FeeTemplateMutationInput!
  ){
    feeTemplate(input: $input) {
      clientMutationId
      errors {
        field
        messages
      }
      obj {
        id
        name
        createdAt
        updatedAt
        description
        type
        tenantSet {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }`;

export const deleteFeeTemplateMutation = graphql`
  mutation FeeTemplateDeleteMutation(
    $feeTemplateId: ID!
  ){
    deleteFeeTemplate(
      feeTemplateId: $feeTemplateId
    ){
      deleted
    }
  }
`;

export default feeTemplateMutation;
