const PAGINATION_PARAMETER_NAMES = ['first', 'last', 'offset'];

/*
  It is best to implement hooks that allow modifying variables
  that will be passed into a QueryRenderer. That way, we have
  more options in something like query parameter parsing behavior.
  This should do for now though, since I am not sure how we are
  going to proceed with admin upgrades.
 */
const NULLABLE_NUMBER_TYPE_PARAMETER_NAMES = [
  'ibConId',
];

export class FilterParameter {
  constructor(key, value) {
    this.name = null;
    this.paramPrefix = null;
    this.paramValue = null; // Variant
    this.key = key;
  }

  setKey = (key) => {
    this.key = null;
  }

  setParamPrefix = (paramPrefix) => {
    this.paramPrefix = paramPrefix;
  }

  setValue = (value) => {
    throw new Error('Abstract Method [FilterParameter:setValue] has no implementation');
  }

  updateUrl = () => {
    throw new Error('Abstract Method [FilterParameter:updateUrl] has no implementation');
  };

  packValueForUrl = () => {
    //
  };

  valid = () => true;
}

FilterParameter.fromValue = (value, key) => {
  if (value === 'true' || value === 'false') {
    return new BoolFilter(key, value);
  }
  if (Array.isArray(value)) {
    return new ArrayStringFilter(key, value);
  }
  if (typeof value === 'string'
    && value.length > 0
    && (value.charAt(0) === '[' && value.charAt(value.length - 1) === ']')) {
    let inputValue;
    try {
      inputValue = JSON.parse(value);
    } catch (err) {
      inputValue = value;
    }
    return new ArrayStringFilter(key, inputValue);
  }
  if (PAGINATION_PARAMETER_NAMES.includes(key)) {
    return new VariantSingleValue(key, Number(value));
  }
  if (NULLABLE_NUMBER_TYPE_PARAMETER_NAMES.includes(key)) {
    if (value === null || value === undefined || value === '') {
      return new VariantSingleValue(key, null);
    }
    return new VariantSingleValue(key, Number(value));
  }
  return new VariantSingleValue(key, value);
};

FilterParameter.loadAll = (urlString) => {
  const queryParams = new URLSearchParams(urlString);
  const paramDefs = [];
  for (const [globalParamKey, paramValue] of queryParams.entries()) {
    const [prefOrKey, keyOrNone] = globalParamKey.split('.');
    if (keyOrNone === undefined) {
      const paramDef = FilterParameter.fromValue(paramValue, prefOrKey);
      paramDefs.push(paramDef);
    } else {
      const paramDef = FilterParameter.fromValue(paramValue, keyOrNone);
      paramDef.setParamPrefix(`${prefOrKey}.`);
      paramDefs.push(paramDef);
    }
  }
  return paramDefs;
};


FilterParameter.load = (urlString, declaredParams, urlParamPrefix) => {
  const allParamDefs = FilterParameter.loadAll(urlString);
  const localParamDefs = allParamDefs.filter(x => x.paramPrefix === urlParamPrefix);
  const paramDefs = {};
  for (const [decParamKey, decParamValue] of Object.entries(declaredParams)) {
    const localParamDef = localParamDefs.find(x => (x.key === decParamKey));
    if (localParamDef) {
      paramDefs[decParamKey] = localParamDef;
    } else {
      const newParamDef = FilterParameter.fromValue(decParamValue, decParamKey);
      newParamDef.setParamPrefix(urlParamPrefix);
      paramDefs[decParamKey] = newParamDef;
    }
  }

  for (const localParamDef of localParamDefs) {
    if (!(localParamDef.key in paramDefs)) {
      paramDefs[localParamDef.key] = localParamDef;
    }
  }

  return paramDefs;
};


export class BoolFilter extends FilterParameter {
  constructor(key, inValue) {
    super(key, null);
    this.name = 'BoolFilter';
    if (inValue === 'true') this.paramValue = true;
    else if (inValue === 'false') this.paramValue = false;
    else this.paramValue = null;
  }

  updateUrl = () => {
    super.updateUrl();
  }

  setValue = (value) => {
    this.paramValue = value;
  }

  packValueForUrl = () => `${this.paramValue}`
}


export class ArrayStringFilter extends FilterParameter {
  constructor(key, inValue) {
    super(key, null);
    this.name = 'ArrayStringFilter';
    if (Array.isArray(inValue)) {
      this.paramValue = inValue;
    } else {
      this.paramValue = inValue.substr(1, inValue.length - 2).split(',');
    }
  }

  updateUrl = () => {
    super.updateUrl();
  }

  setValue = (value) => {
    this.paramValue = value;
  }

  packValueForUrl = () => `[${this.paramValue.join(',')}]`

  valid = () => (this.paramValue !== null)
    && (this.paramValue !== undefined) && (this.paramValue.length > 0);
}

export class VariantSingleValue extends FilterParameter {
  constructor(key, inValue) {
    super(key, null);
    this.name = 'VariantSingleValue';
    this.paramValue = (inValue === undefined) ? null : inValue;
  }

  updateUrl = () => {
    super.updateUrl();
  }

  setValue = (value) => {
    this.paramValue = value;
  }

  packValueForUrl = () => `${this.paramValue}`

  valid = () => (this.paramValue !== null) && (this.paramValue !== undefined)
}
