import * as React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { toCurrencyFormat } from '../../common/helpers';

function Price(props) {
  const {
    children,
    currency,
    decimalPlaces,
    tooltipPrefix,
  } = props;

  const tooltip = `${tooltipPrefix || ''}${children}`;
  return (
    <Tooltip title={tooltip}>
      <span>
        <React.Fragment>
          { children && toCurrencyFormat(children, currency, decimalPlaces) }
        </React.Fragment>
      </span>
    </Tooltip>
  );
}

Price.propTypes = {
  currency: PropTypes.string,
  decimalPlaces: PropTypes.number,
  tooltipPrefix: PropTypes.string,
};

Price.defaultProps = {
  currency: 'AUD',
  decimalPlaces: 4, // or 2
  tooltipPrefix: null,
};

export default Price;
