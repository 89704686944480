import withDebounce from '../debounce';
import { FilterParameter } from './FilterParameter';

export const onPrePackConcreteValues = (prePack) => {
  const modified = {};
  for (const [key, val] of Object.entries(prePack)) {
    if (Array.isArray(val) && val.length === 0) {
      modified[key] = null;
    } else {
      modified[key] = val;
    }
  }
  return modified;
};

const debounceRefresh = withDebounce(
  (_relayFilter, postRefresh) => {
    if (_relayFilter.relay) {
      if (_relayFilter.onPreFetch) _relayFilter.onPreFetch();
      _relayFilter.relay.refetch(onPrePackConcreteValues(_relayFilter.params), null, () => {
        if (_relayFilter.onPostFetch) _relayFilter.onPostFetch();
        if (postRefresh) postRefresh();
      });
    }
  },
  300,
);

export class BrowserInterface {
  getSearch = () => window.location.search;


  setUrl = (urlPath) => { window.history.pushState('', '', urlPath); }
}

export default class RelayFilterV2 {
  constructor({
    params,
    debounceFields,
    props,
    relay,
    browserInterface,
  }) {
    this.onPreFetch = null;
    this.onPostFetch = null;
    this.relay = relay;
    this.debounceFields = debounceFields || [];
    this.urlParamPrefix = null;
    this.browserInterface = browserInterface;
    this.paramDefs = {};
    if (props) {
      const { location, history, urlParamPrefix } = props;
      this.urlParamPrefix = urlParamPrefix || null;
      if (location && history) {
        this.pathName = location.pathname;
        this.history = history;
        this.paramDefs = FilterParameter.load(location.search, params, this.urlParamPrefix);
      } else {
        this.paramDefs = FilterParameter.load('', params, this.urlParamPrefix);
      }
    }

    this.params = this.resolvedParamObject(); // for backward compatibility
  }

  setRelay = (relay) => {
    this.relay = relay;
  };

  setPreFetchListener = (listener) => {
    this.onPreFetch = listener;
  };

  setPostFetchListener = (listener) => {
    this.onPostFetch = listener;
  };

  getOrCreateParamDef = (paramKey, paramValue) => {
    let defToUpdate;
    if (paramKey in this.paramDefs) {
      defToUpdate = this.paramDefs[paramKey];
    } else {
      defToUpdate = FilterParameter.fromValue(paramValue, paramKey);
      defToUpdate.setParamPrefix(this.urlParamPrefix);
    }
    this.paramDefs[paramKey] = defToUpdate;
    return defToUpdate;
  }

  resolvedParamObject = () => {
    const finalObject = {};
    for (const [paramKey, paramDef] of Object.entries(this.paramDefs)) {
      finalObject[paramKey] = paramDef.paramValue;
    }
    return finalObject;
  }

  updateVar = (paramKey, paramValue, postRefresh) => {
    if (paramKey !== 'offset') {
      this.getOrCreateParamDef('offset', 0).setValue(0);
    }
    this.getOrCreateParamDef(paramKey, paramValue).setValue(paramValue);
    if (this.debounceFields.includes(paramKey)) {
      debounceRefresh(this, postRefresh);
    } else if (this.relay) {
      if (this.onPreFetch) this.onPreFetch();
      this.relay.refetch(() => onPrePackConcreteValues(this.resolvedParamObject()), null, () => {
        if (this.onPostFetch) this.onPostFetch();
        if (postRefresh) postRefresh();
      });
    }
    this.params = this.resolvedParamObject(); // for backward compatibility
    this.updateUrl();
  };

  updateVars = (varsObject, postRefresh) => {
    let instantUpdates = false;
    for (const [paramKey, paramValue] of Object.entries(varsObject)) {
      this.getOrCreateParamDef(paramKey, paramValue).setValue(paramValue);
      instantUpdates = instantUpdates || !this.debounceFields.includes(paramKey);
    }

    if (instantUpdates) {
      if (this.relay) {
        if (this.onPreFetch) this.onPreFetch();
        this.relay.refetch(() => onPrePackConcreteValues(this.resolvedParamObject()), null, () => {
          if (this.onPostFetch) this.onPostFetch();
          if (postRefresh) postRefresh();
        });
      }
    } else {
      debounceRefresh(this, postRefresh);
    }

    this.params = this.resolvedParamObject(); // for backward compatibility
    this.updateUrl();
  };

  updateUrl = () => {
    const allParamDefs = FilterParameter.loadAll(this.browserInterface.getSearch());
    const foreignEntries = allParamDefs.filter(
      paramDef => (paramDef.paramPrefix !== this.urlParamPrefix),
    );

    if (this.pathName && this.history) {
      let bothParamDefsValid = Object.values(
        this.paramDefs,
      ).concat(foreignEntries).filter(x => x.valid());
      bothParamDefsValid = bothParamDefsValid.sort((a, b) => {
        const aKey = `${a.paramPrefix || 'aaaaa.'}`;
        const bKey = `${b.paramPrefix || 'aaaaa.'}`;
        if (aKey > bKey) return 1;
        if (aKey < bKey) return -1;
        return 0;
      });
      const stringed = bothParamDefsValid.map(y => `${y.paramPrefix || ''}${y.key}=${y.packValueForUrl()}`);
      const varString = stringed.join('&');
      this.browserInterface.setUrl(`${this.pathName}?${varString}`);
    }
  };

  update = (postRefresh) => {
    if (this.relay) {
      if (this.onPreFetch) this.onPreFetch();
      this.relay.refetch(() => onPrePackConcreteValues(this.resolvedParamObject()), null, () => {
        if (this.onPostFetch) this.onPostFetch();
        if (postRefresh) postRefresh();
      });
    }
    this.params = this.resolvedParamObject(); // for backward compatibility
  };

  fetchParams = () => this.resolvedParamObject();

  setDebounceFields = (fields) => {
    this.debounceFields = fields;
  }
}
