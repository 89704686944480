import { graphql } from 'react-relay';
import React from 'react';
import EventDetails from './EventDetails';
// import QueryRendererProgress from '../../common/QueryRendererProgress';


// const EventDetailsContainerQuery = graphql`
//   query EventDetailsContainerQuery (
//     $eventId: ID,
//   ) {
//     viewer {
//       applicationStatus: __type(name: "ApplicationStatus") { enumValues { description, name} }
//       title: __type(name: "Title") { enumValues { description, name} }
//       greenIdStatus: __type(name: "GreenIDStatus") { enumValues { description, name} }
//       applicantApplicantType: __type(name: "ApplicantType") { enumValues { description, name} }
//       country: __type(name: "Country") {enumValues { description, name} }
//       gender: __type(name: "Gender") { enumValues { description, name} }
//       industry: __type(name: "ANZSIC") {enumValues { description, name} }
//       documentType: __type(name: "DocumentType") {enumValues { description, name} }
//       sourceOfFunds: __type(name: "SourceOfFunds") {enumValues { description, name} }
//       sourceOfWealth: __type(name: "SourceOfWealth") {enumValues { description, name} }
//       annualHouseholdIncome: __type(name: "AnnualIncome") {enumValues { description, name} }
//       tradingApplications(
//         id: $applicationId
//       ) {
//         edges {
//           node {
//             hasPendingRevisionRequest
//           }
//         }
//       }
//       applications(
//         id: $applicationId
//       ) {
//         edges {
//           node {
//             ...EventDetails_application
//           }
//         }
//         count
//       }
//     }
//   }
// `;

// function propsFromViewer(viewer) {
//   return { application: viewer.applications.edges[0].node };
// }

function EventDetailsContainer() {
  return (
    <div>
      <EventDetails />
      {/* <QueryRendererProgress
        componentClass={EventDetails}
        queryClass={EventDetailsContainerQuery}
        propsToChild={propsFromViewer}
        initQueryParams={{
          applicationId,
        }}
      /> */}
    </div>
  );
}

export default EventDetailsContainer;
