import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { graphql } from 'react-relay';
import { commonStyles } from '../common/Styles';
import createAdviserRoleMutation from '../../mutations/CreateAdviserRoleMutation';
import AdviserPermissions from './AdviserPermissions';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton, UpdateButton } from '../common/buttons/BaseButton';
import { doCommitPromise } from '../../common/commit';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { isRecurNull } from '../../common/helpers';
import updateAdviserRoleMutation from '../../mutations/UpdateAdviserRoleMutation';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  drawerRootExtended: {
    flexGrow: 1,
    width: 600,
    padding: theme.spacing(2),
  },
}));

export function AdviserRoleDrawerContainer(props) {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.drawerRootExtended}>
      {children}
    </div>
  );
}


export function AdviserRoleAddForm(props) {
  const {
    close,
    onAddAdviserRole,
    adviserRole,
  } = props;
  const onModify = !!adviserRole;
  const initialPermissions = onModify ? adviserRole.rolePermissions.edges.map(
    x => ({ ...(x.node) }), // Need a copy of read-only objects
  ) : [];
  const [members] = React.useState({
    roleName: onModify ? adviserRole.name : '',
  });
  const [rolePermissions, setRolePermissions] = React.useState(initialPermissions);
  const [defaultPermissions, setDefaultPermissions] = React.useState('custom');
  const isCustom = defaultPermissions === 'custom';

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading={`${onModify ? 'Modify' : 'Add'} Adviser Role`}
        />

        <DrawerGridItemTextField
          label="Role name"
          disabled={onModify}
          defaultValue={members.roleName}
          onChange={(e) => { members.roleName = e.target.value; }}
        />

        {
          !onModify && (
            <DrawerGridItemSelectField
              enumType={[
                { name: 'default', description: 'Default Adviser' },
                { name: 'admin', description: 'Admin Adviser' },
                { name: 'custom', description: 'Custom Permissions' },
              ]}
              label="Permission Set"
              value={defaultPermissions}
              onChange={e => setDefaultPermissions(e.target.value)}
            />
          )
        }

        <Grid item xs={12}>
          { isCustom && (
            <AdviserPermissions
              permissions={rolePermissions}
              onSetPermissions={e => setRolePermissions(e)}
            />
          )}
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            {
              onModify ? (
                <UpdateButton
                  onClickPromise={() => doCommitPromise(
                    updateAdviserRoleMutation,
                    {
                      adviserRoleId: adviserRole.id,
                      rolePermissions,
                    },
                    () => (close && close()),
                    () => null,
                  )}
                />
              ) : (
                <AddButton
                  onClickPromise={() => doCommitPromise(
                    createAdviserRoleMutation,
                    {
                      name: members.roleName,
                      defaultPermissions,
                      rolePermissions,
                    },
                    () => {
                      onAddAdviserRole && onAddAdviserRole();
                      close && close();
                    },
                    () => null,
                  )}
                />
              )
            }

          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}

const query = graphql`
  query AdviserRoleFormQuery (
    $adviserRoleId: ID!
  ) {
    viewer {
      adviserRoles(id: $adviserRoleId) {
        edges {
          node {
            id
            name
            rolePermissions(orderBy: "resource,verb") {
              edges {
                node {
                  resource
                  verb
                  scope
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AdviserRoleModifyForm = createQueryRendererProgressV2(
  query,
  AdviserRoleAddForm,
  (outerProps, props) => ({
    adviserRole: isRecurNull(props, ['viewer', 'adviserRoles', 'edges', 0, 'node']),
  }),
  props => ({
    adviserRoleId: props.adviserRole.id,
    // Trying to get empty data rather than non-filter-all data if role not specified
  }),
);
