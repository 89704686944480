import React from 'react';
import PropTypes from 'prop-types';
import PopupEntitySelectorField from './PopupEntitySelectorField';
import UserSelectionDlg from './dialogs/UserSelectionDlg';

function PopupUserSelectorField(props) {
  const {
    children,
    caption,
    ...otherProps
  } = props;
  return (
    <PopupEntitySelectorField
      {...otherProps}
      caption={caption}
      searchDlgComp={UserSelectionDlg}
      valueDescriber={user => (user && user.fullName)}
      helperTextDescriber={(user) => {
        if (!user) return 'Select User...';
        if (user.adviser) return `${user.fullName} : ${user.adviser.parentTenant.name}`;
        return user.fullName;
      }}
    />
  );
}

PopupUserSelectorField.propTypes = {
  caption: PropTypes.string,
};

PopupUserSelectorField.defaultProps = {
  caption: 'User',
};


export default PopupUserSelectorField;
