import * as React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/core/SvgIcon';
import { Grid } from '@material-ui/core';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { createFragmentContainer, graphql } from 'react-relay';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import TableFieldMeta, { Gid, priceFigureComp } from '../../common/TableFieldMeta';
import VerticalTable from '../common/VerticalTable';
import { useCommonStyles } from '../common/Styles';
import { toLocal } from '../../helpers/ID';
import Price from '../common/Price';
import BrokerOrderStatus from '../common/BrokerOrderStatus';
import TitledWidgetItem from '../common/TitledWidgetItem';
import DataTableWidget from '../common/DataTableWidget';
import StockOrderBrokerOrderDetails from './stockOrder/StockOrderBrokerOrderDetails';
import StockOrderQuantityStatus from './stockOrder/StockOrderQuantityStatus';
import TableStatus from '../common/TableStatus';


function OrderPanelSummaryContent(props) {
  const {
    fxOrder,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item>
        <TableStatus customStyle={{ backgroundColor: 'red' }}>FX</TableStatus>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          {fxOrder.contract.localSymbol}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <BrokerOrderStatus stockOrder={fxOrder.brokerOrder} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          <StockOrderQuantityStatus stockOrder={fxOrder} />
        </Typography>
      </Grid>
    </Grid>);
}

const accountChargesInfo = fxOrder => fxOrder.orderPtr.transactions.edges.reduce((at, vt) => {
  const vtRed = vt.node.transactionaccountchargeSet.edges.reduce(
    (a, v) => {
      const m = v.node.accountchargePtr.accountchargetenantportionSet.edges.reduce(
        (a1, v1) => {
          const tenId = v1.node.tenant.id;
          return {
            tenantInfo: { ...a1.tenantInfo, [tenId]: v1.node.tenant.name },
            tenantQty: ({
              ...a1.tenantQty,
              [tenId]: (a1.tenantQty[tenId] || 0) + v1.node.quantity,
            }),
          };
        },
        { tenantInfo: {}, tenantQty: {} },
      );
      return {
        charges: [...a.charges, { ...v.node, txnId: vt.node.id, tenantQty: m.tenantQty }],
        tenantInfo: { ...a.tenantInfo, ...m.tenantInfo },
      };
    },
    { charges: [], tenantInfo: {} },
  );
  return {
    charges: at.charges.concat(vtRed.charges),
    tenantInfo: { ...at.tenantInfo, ...vtRed.tenantInfo },
  };
}, { charges: [], tenantInfo: {} });


OrderPanelSummaryContent.propTypes = {
  fxOrder: PropTypes.object.isRequired,
};

function FxOrderDetails(props) {
  const orderColumns = [
    ['Id', TableFieldMeta.localId.accessor],
    ['Price', priceFigureComp('price', ['contract', 'currency'])],
    ['PendingStatus', node => (node.pendingStatus === 'CA' ? 'Pending Cancel' : 'None')],
    ['Total Quantity', node => (node.quantity !== null ? Math.abs(node.quantity) : null)],
    ['Cash Quantity', priceFigureComp('cashQuantity', ['contract', 'currency'])],
    ['Quantity Filled (ToDate)', node => Math.abs(node.quantityToDate)],
    ['Side', ['brokerOrder', 'side']],
    ['Order Type', ['brokerOrder', 'orderType']],
    ['Matched', ['brokerOrder', 'matched']],
    // ['Total Proceeds', priceFigureComp('proceedsToDate', ['contract', 'currency'])],
    // ['Total Proceeds Base', priceFigureComp('proceedsToDateBase')],
    ['Gid', node => <Gid gid={node.id} />],
  ];

  const contractColumns = [
    ['Id', node => toLocal(node.contract.id)],
    ['Currency', ['contract', 'currency']],
    ['Symbol', ['contract', 'localSymbol']],
    ['IB Contract ID', ['contract', 'ibConId']],
  ];

  const { fxOrder, expanded } = props;
  const classes = useCommonStyles();

  const accChrInfo = accountChargesInfo(fxOrder);

  const chargeColumns = [
    TableFieldMeta.field('txnId', 'Txn.',
      { accessor: node => toLocal(node.txnId) }),
    TableFieldMeta.field('feeType', 'Type'),
    TableFieldMeta.localId,
  ];

  Object.keys(accChrInfo.tenantInfo).forEach((tenantId) => {
    chargeColumns.push(TableFieldMeta.priceField(
      `ten${accChrInfo.tenantInfo[tenantId]}`,
      accChrInfo.tenantInfo[tenantId],
      node => node.tenantQty[tenantId],
      null,
      4,
      {
        reducer: {
          initiator: nodes => 0,
          accumulator: (node, a) => a + (node.value.tenantQty[tenantId] || 0),
          accessor: (nodes, a) => <Price>{a}</Price>,
        },
      },
    ));
  });

  chargeColumns.push(TableFieldMeta.priceField(
    'chr',
    'Total',
    'amount',
    'currency',
    4,
    {
      reducer: {
        initiator: nodes => 0,
        accumulator: (node, a) => a + (node.value.amount || 0),
        accessor: (nodes, a) => <Price>{a}</Price>,
      },
    },
  ));

  return (
    <ExpansionPanel
      defaultExpanded={expanded}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <OrderPanelSummaryContent
          fxOrder={fxOrder}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expandedDetails}>
        <div style={{ width: '100%' }}>
          <Grid container spacing={2}>

            {/* Order Details */}
            <Grid item xs={12} md={6} lg={3}>
              <Paper className={classes.tableHolderRoot}>
                <div className={classes.widgetHeadingContainer}>
                  <Typography
                    component="h6"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Order Details
                  </Typography>
                </div>
                <VerticalTable
                  showHeadings={false}
                  columnMeta={orderColumns}
                  dataNode={fxOrder}
                />

              </Paper>
            </Grid>


            <Grid item xs={12} md={6} lg={3}>
              <Grid container spacing={2}>
                <TitledWidgetItem
                  xs={12}
                  title="Contract Details"
                >
                  <VerticalTable
                    showHeadings={false}
                    columnMeta={contractColumns}
                    dataNode={fxOrder}
                  />
                </TitledWidgetItem>

                <StockOrderBrokerOrderDetails brokerOrder={fxOrder.brokerOrder} />


              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>

                <DataTableWidget
                  xs={12}
                  title="Executions"
                  columnMeta={[
                    TableFieldMeta.priceField('price', 'Price', 'price', 'currency'),
                    TableFieldMeta.dateTimeField('execTime', 'ExecTime', node => node.executionDatetime),
                    TableFieldMeta.quantityField('quantity', 'Quantity'),
                    TableFieldMeta.field('transaction', 'Transaction',
                      {
                        accessor: node => node.transaction && toLocal(node.transaction.id),
                        align: 'right',
                      }),
                  ]}
                  dataNodes={fxOrder.orderPtr.executionSet.edges.map(node => node.node)}
                />

                <DataTableWidget
                  xs={12}
                  title="Charges"
                  columnMeta={chargeColumns}
                  dataNodes={accChrInfo.charges}
                  showFooter
                />

                <DataTableWidget
                  xs={12}
                  title="Amendments"
                  columnMeta={[
                    TableFieldMeta.createdAt,
                    TableFieldMeta.fieldCell('quantity', 'Qty', row => (
                      row.value.quantity !== row.value.previousQuantity
                      && (
                        <span>
                          {row.value.previousQuantity}
                          &nbsp;&rarr;&nbsp;
                          {row.value.quantity}
                        </span>
                      )
                    ), { align: 'center' }),
                    TableFieldMeta.fieldCell('limitPrice', 'Lmt', row => (
                      row.value.limitPrice !== row.value.previousLimitPrice
                      && (
                        <span>
                          {row.value.previousLimitPrice}
                          &nbsp;&rarr;&nbsp;
                          {row.value.limitPrice}
                        </span>
                      )
                    ), { align: 'center' }),
                    TableFieldMeta.enum('AmendStatus', 'Status', node => node.status),
                  ]}
                  dataNodes={fxOrder.brokerOrder.brokerorderamendmentSet.edges.map(
                    node => node.node,
                  )}
                />

              </Grid>
            </Grid>

          </Grid>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default createFragmentContainer(
  FxOrderDetails,
  {
    fxOrder: graphql`
      fragment FxOrderDetails_fxOrder on FxOrderNode {
        id
        price
        quantity
        createdAt
        updatedAt
        quantityToDate
        
        contract {
          id
          currency
          symbol
          localSymbol
          ibConId
        }

        orderPtr {
          account {
            id
            name
          }
          orderType
          
          executionSet {
            edges {
              node {
                id
                transaction {
                  id
                }
                price
                proceeds
                executionDatetime
                quantity
                currency
              }
            }
          }

          transactions {
            edges {
              node {
                id
                commission
                tax
                transactionaccountchargeSet {
                  edges {
                    node {
                      id
                      amount
                      currency
                      feeType
                      accountchargePtr {
                        id
                        amount
                        currency
                        accountchargetenantportionSet {
                          edges {
                            node {
                              id
                              quantity
                              tenant {
                                name
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        
        brokerOrder {
          id
          twsOrderId
          twsPermId
          cashQuantity
          quantityFilled
          quantityLeft
          stopPrice
          trailStopRatio
          trailStopOffset
          lastSyncTime
          status
          orderRef
          side
          orderType
          ibExchange {
            id
            symbol
          }
          
          brokerorderamendmentSet {
            edges {
              node {
                id
                quantity
                previousQuantity
                previousLimitPrice
                limitPrice
                amendmentRef
              }
            }
          }
        }
        
      }
    `,
  },
);

FxOrderDetails.propTypes = {
  fxOrder: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
};

FxOrderDetails.defaultProps = {
  expanded: false,
};
