/**
 * @flow
 * @relayHash 378c0c4efda2fa657990150fb870ec87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeTemplateType = "DEF" | "OVR" | "%future added value";
export type FeeTemplateMutationInput = {|
  id?: ?string,
  name: string,
  description: string,
  type: FeeTemplateType,
  currency?: ?Currency,
  clientMutationId?: ?string,
|};
export type FeeTemplateMutationVariables = {|
  input: FeeTemplateMutationInput
|};
export type FeeTemplateMutationResponse = {|
  +feeTemplate: ?{|
    +clientMutationId: ?string,
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +obj: ?{|
      +id: string,
      +name: string,
      +createdAt: any,
      +updatedAt: any,
      +description: string,
      +type: FeeTemplateType,
      +tenantSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: string,
          |}
        |}>
      |},
    |},
  |}
|};
export type FeeTemplateMutation = {|
  variables: FeeTemplateMutationVariables,
  response: FeeTemplateMutationResponse,
|};
*/


/*
mutation FeeTemplateMutation(
  $input: FeeTemplateMutationInput!
) {
  feeTemplate(input: $input) {
    clientMutationId
    errors {
      field
      messages
    }
    obj {
      id
      name
      createdAt
      updatedAt
      description
      type
      tenantSet {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FeeTemplateMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeTemplate",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FeeTemplateMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "obj",
        "storageKey": null,
        "args": null,
        "concreteType": "FeeTemplateNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenantSet",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "TenantNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "TenantNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeTemplateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeTemplateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeTemplateMutation",
    "id": null,
    "text": "mutation FeeTemplateMutation(\n  $input: FeeTemplateMutationInput!\n) {\n  feeTemplate(input: $input) {\n    clientMutationId\n    errors {\n      field\n      messages\n    }\n    obj {\n      id\n      name\n      createdAt\n      updatedAt\n      description\n      type\n      tenantSet {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1ef1c9982b823882e908ee7a6b22ec4';
module.exports = node;
