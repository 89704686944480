import { graphql } from 'react-relay';

export const trialCancelBrokerOrder = graphql`
  mutation CancelBrokerOrderTrialMutation (
    $brokerOrderId: ID
  ) {
    trademanTrialOrderCancel(
      brokerOrderId: $brokerOrderId
    ) {
      brokerOrder {
        id 
        status
        updatedAt
        
        orderSet {
          edges {
            node {
              id
              stockorder {
                id
                status
                ...ActionLogs_cancelUpdate
              }
            }
          }
        }
        
      }
    }
  }
`;

export const confirmCancelBrokerOrder = graphql`
  mutation CancelBrokerOrderConfirmMutation (
    $brokerOrderId: ID
  ) {
    trademanConfirmOrderCancel(
      brokerOrderId: $brokerOrderId
    ) {
      brokerOrder {
        id 
        status
        updatedAt
        
        orderSet {
          edges {
            node {
              id
              stockorder {
                id
                ...ActionLogs_cancelUpdate
              }
            }
          }
        }
        
      }
    }
  }
`;
