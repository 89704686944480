import React from 'react';
import { graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { UpdateButton } from '../common/buttons/BaseButton';
import notiStack from '../../common/notiStack';
import { useCommonStyles } from '../common/Styles';
import UserRoleLoader from '../common/fieldLoaders/UserRoleLoader';
import { fromGlobalId, toGlobalId } from '../../helpers/ID';
import updateUserPermissionMutation from '../../mutations/UpdateUserPermissionMutation';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import UserPermissions from '../permission/UserPermissions';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { doCommitPromise } from '../../common/commit';

function UserPermissionUpdateForm(props) {
  const {
    viewer,
    clientAccount,
    close,
  } = props;
  const classes = useCommonStyles();

  const userModel = viewer.authUsers.edges[0].node.usermodel;
  const userModelId = userModel.id;
  const clientAccountId = clientAccount.id;
  const currentUserClientAccountPermissions = (
    userModel.userclientaccountpermissionSet.edges.map(edge => ({ ...(edge.node) }))
  );
  const currentUserClientAccountRoles = (
    userModel.userclientaccountroleSet.edges.map(edge => edge.node.userRole)
  );
  const currentUserClientAccountRoleIds = currentUserClientAccountRoles.map(role => role.id);
  
  const [userRoleIds, setUserRoleIds] = React.useState(currentUserClientAccountRoleIds);
  const [userPermissions, setUserPermissions] = React.useState(currentUserClientAccountPermissions);

  const doUpdateUserPromise = () => {
    if (userRoleIds.length === 0) {
      notiStack.error('Specify role(s) for the user');
      return null;
    }
    return doCommitPromise(
      updateUserPermissionMutation,
      {
        userId: userModelId,
        clientAccountId,
        userRoleIds,
        userClientAccountPermissions: userPermissions,
      },
      () => (close && close()),
    );
  };

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading heading="Update User Account Permissions" />
        <DrawerGridItemTextField
          label="First Name"
          disabled
          defaultValue={userModel.firstName}
        />
        <DrawerGridItemTextField
          label="Last name"
          disabled
          defaultValue={userModel.lastName}
        />
        <DrawerGridItemTextField
          label="Last name"
          disabled
          defaultValue={userModel.username}
          xs={12}
        />
        <DrawerGridItemTextField
          label="email"
          disabled
          defaultValue={userModel.email}
          xs={12}
        />
        <Grid item xs={12}>
          <UserRoleLoader
            multiple
            className={classes.fromControlDrawer}
            label="Roles"
            onChange={(userRoles) => {
              setUserRoleIds(userRoles.map(role => role.id));
            }}
            defaultValue={currentUserClientAccountRoles}
          />
        </Grid>

        <Grid item xs={12}>
          <UserPermissions
            title="Extra Permissions"
            permissions={currentUserClientAccountPermissions}
            onSetPermissions={e => setUserPermissions(e)}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <UpdateButton
              onClickPromise={doUpdateUserPromise}
              buttonText="Update"
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </>
  );
}

const query = graphql`
  query UserPermissionUpdateFormQuery (
    $userId: ID!
    $clientAccountId: ID
  ) {
    viewer {
      authUsers(id: $userId) {
        edges {
          node {
            id
            usermodel {
              id
              username
              email
              firstName
              lastName
              userclientaccountpermissionSet(clientAccountId: $clientAccountId) {
                edges {
                  node {
                    resource
                    verb
                  }
                }
              }
              userclientaccountroleSet(clientAccountId: $clientAccountId) {
                edges {
                  node {
                    userRole {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createQueryRendererProgressV2(
  query,
  UserPermissionUpdateForm,
  {},
  props => ({
    userId: toGlobalId('UserNode', fromGlobalId(props.userModel.id)[1]),
    clientAccountId: props.clientAccount.id,
  }),
);
