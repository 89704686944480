import React from 'react';
import Grid from '@material-ui/core/Grid';
import DrawerSubGroupDivider from '../common/drawer/DrawerSubGroupDivider';
import DrawerSubGroupHeading from '../common/drawer/DrawerSubGroupHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemNumberField from '../common/drawer/DrawerGridItemNumberField';

function PositionTransferCashQuantity(props) {
  const { heading, updateCashQuantity, cashQuantity } = props;
  const [currency, setCurrency] = React.useState(cashQuantity.currency);
  const [quantity, setQuantity] = React.useState(cashQuantity.quantity);

  const doUpdateCashQuantity = () => {
    if (updateCashQuantity) {
      updateCashQuantity(
        cashQuantity,
        {
          currency,
          quantity,
        },
      );
    }
  };

  React.useEffect(() => {
    doUpdateCashQuantity();
  }, [currency, quantity]);

  return (
    <React.Fragment>

      <DrawerSubGroupDivider />

      <Grid item xs={12}>
        <DrawerSubGroupHeading>
          {heading}
        </DrawerSubGroupHeading>
      </Grid>

      <DrawerGridItemSelectField
        enumType="Currency"
        onChange={e => setCurrency(e.target.value)}
        value={currency}
      />

      <DrawerGridItemNumberField
        label="Quantity"
        defaultValue={quantity}
        onChange={e => setQuantity(e.target.value)}
      />
    </React.Fragment>
  );
}

export default PositionTransferCashQuantity;
