import { fromGlobalId } from '../helpers/ID';

const CSV_HEADING = 'Type,BrokerActivityID,BrokerActivityTransactionID,Account,'
  + 'Portfolio,Currency,Amount,Contract,Symbol,Quantity,Sequence,Price,CostBasis,'
  + 'SplitFactor,SplitResult,Commission,OldContract,NewContract,SpinoffContract,Dummied\n';
export default class BrokerActivity {
}

function addRecords(brokerActivity, addPerformance = false) {
  const baId = fromGlobalId(brokerActivity.id)[1];
  let cashEntry = '';
  let holdingEntry = '';
  let perfEntry = '';
  let currency = '';
  let symbol = '';

  if (brokerActivity.ibcorporateaction) {
    const { currency: currencyIn, symbol: symbolIn } = brokerActivity.ibcorporateaction;
    currency = currencyIn;
    symbol = symbolIn;
  }

  brokerActivity.brokeractivitytransactionSet.edges.forEach((bat) => {
    if (bat.node) {
      const accId = fromGlobalId(bat.node.account.id)[1];
      const batID = fromGlobalId(bat.node.id)[1];
      bat.node.brokeractivitytransactioncashdeltaSet.edges.forEach((cashDelta) => {
        if (cashDelta.node) {
          const cdn = cashDelta.node;
          const portfolioId = cdn.portfolio ? fromGlobalId(cdn.portfolio.id)[1] : '';
          // eslint-disable-next-line prefer-destructuring
          currency = cdn.currency;
          cashEntry = cashEntry.concat(
            `CASH,${baId},${batID},${accId},${portfolioId},${currency},${cdn.quantity},,,,,,\n`,
          );
        }
      });

      bat.node.brokeractivitytransactioncontractdeltaSet.edges.forEach((contractDelta) => {
        if (contractDelta.node) {
          const cdn = contractDelta.node;
          const portfolioId = fromGlobalId(cdn.portfolio.id)[1];
          const contractId = fromGlobalId(cdn.contract.id)[1];
          holdingEntry = holdingEntry.concat(
            `HOLDING,${baId},${batID},${accId},${portfolioId},,,${contractId},${symbol},${cdn.quantity},,,\n`,
          );
        }
      });

      if (addPerformance) {
        bat.node.brokeractivitytransactionperformanceSet.edges.forEach((batPerf) => {
          if (batPerf.node) {
            const perf = batPerf.node;
            const portfolioId = fromGlobalId(perf.portfolio.id)[1];
            const contractId = fromGlobalId(perf.contract.id)[1];
            const costBasis = perf.costBasis === null ? '' : perf.costBasis;
            const splitFactor = perf.splitFactor === null ? '' : perf.splitFactor;
            const splitResult = perf.splitResult === null ? '' : perf.splitResult;
            const oldContractId = perf.oldContract ? fromGlobalId(perf.oldContract.id)[1] : '';
            const newContractId = perf.newContract ? fromGlobalId(perf.newContract.id)[1] : '';
            const spinoffContractId = perf.spinoffContract ? fromGlobalId(perf.spinoffContract.id)[1] : '';
            perfEntry = perfEntry.concat(
              `PERF,${baId},${batID},${accId},${portfolioId},${perf.currency},,`
              + `${contractId},${symbol},${perf.quantity},${perf.sequence},`
              + `${perf.price},${costBasis},${splitFactor},${splitResult},${perf.commission},`
              + `${oldContractId},${newContractId},${spinoffContractId},${perf.dummied}\n`,
            );
          }
        });
      }
    }
  });

  return [cashEntry, holdingEntry, perfEntry];
}


BrokerActivity.toCSV = (brokerActivity, addPerformance = false) => {
  const [cashEntry, holdingEntry, perfEntry] = addRecords(brokerActivity, addPerformance);
  return CSV_HEADING + cashEntry + holdingEntry + perfEntry;
};


BrokerActivity.arrayToCSV = (brokerActivityArray, addPerformance = false) => {
  let cashEntries = '';
  let holdingEntries = '';
  let perfEntries = '';
  brokerActivityArray.forEach((brokerActivity) => {
    const [cashEntry, holdingEntry, perfEntry] = addRecords(brokerActivity.node, addPerformance);
    cashEntries += cashEntry;
    holdingEntries += holdingEntry;
    perfEntries += perfEntry;
  });
  return CSV_HEADING + cashEntries + holdingEntries + perfEntries;
};

BrokerActivity.getRelevantBatIds = (brokerActivitySet) => {
  const entries = [];
  brokerActivitySet.map((ba) => {
    if (ba.node) {
      ba.node.brokeractivitytransactionSet.edges.map((bat) => {
        if (bat.node) {
          entries.push(bat.node.id);
        }
        return null;
      });
    }
    return null;
  });
  return entries;
};

BrokerActivity.unpackPerfEntries = (brokerActivitySet) => {
  const entries = [];
  let includedBatCount = 0;
  let totalBatCount = 0;
  brokerActivitySet.map((ba) => {
    if (ba.node) {
      includedBatCount += ba.node.brokeractivitytransactionSet.edges.length;
      totalBatCount += ba.node.brokeractivitytransactionSet.count;
      ba.node.brokeractivitytransactionSet.edges.map((bat) => {
        if (bat.node) {
          const perfSet = bat.node.brokeractivitytransactionperformanceSet.edges;
          perfSet.map((batPerf) => {
            if (batPerf.node) {
              const perf = batPerf.node;
              entries.push({
                brokerActivityId: ba.node.id,
                brokerActivityTransactionId: bat.node.id,
                accountId: bat.node.account.id,
                portfolioId: perf.portfolio.id,
                contractId: perf.contract.id,
                currency: perf.currency,
                sequence: perf.sequence,
                quantity: perf.quantity,
                price: perf.price,
                costBasis: perf.costBasis,
                splitFactor: perf.splitFactor,
                splitResult: perf.splitResult,
                commission: perf.commission,
                oldContractId: perf.oldContract ? perf.oldContract.id : null,
                newContractId: perf.newContract ? perf.newContract.id : null,
                spinoffContractId: perf.spinoffContract ? perf.spinoffContract.id : null,
                dummied: perf.dummied,
              });
            }
            return null;
          });
        }
        return null;
      });
    }
    return null;
  });
  return {
    entries,
    includedBatCount,
    totalBatCount,
  };
};
