/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Country = "AD" | "AE" | "AF" | "AG" | "AI" | "AL" | "AM" | "AO" | "AQ" | "AR" | "AS" | "AT" | "AU" | "AW" | "AX" | "AZ" | "BA" | "BB" | "BD" | "BE" | "BF" | "BG" | "BH" | "BI" | "BJ" | "BL" | "BM" | "BN" | "BO" | "BQ" | "BR" | "BS" | "BT" | "BV" | "BW" | "BY" | "BZ" | "CA" | "CC" | "CD" | "CF" | "CG" | "CH" | "CI" | "CK" | "CL" | "CM" | "CN" | "CO" | "CR" | "CU" | "CV" | "CW" | "CX" | "CY" | "CZ" | "DE" | "DJ" | "DK" | "DM" | "DO" | "DZ" | "EC" | "EE" | "EG" | "EH" | "ER" | "ES" | "ET" | "FI" | "FJ" | "FK" | "FM" | "FO" | "FR" | "GA" | "GB" | "GD" | "GE" | "GF" | "GG" | "GH" | "GI" | "GL" | "GM" | "GN" | "GP" | "GQ" | "GR" | "GS" | "GT" | "GU" | "GW" | "GY" | "HK" | "HM" | "HN" | "HR" | "HT" | "HU" | "ID" | "IE" | "IL" | "IM" | "IN" | "IO" | "IQ" | "IR" | "IS" | "IT" | "JE" | "JM" | "JO" | "JP" | "KE" | "KG" | "KH" | "KI" | "KM" | "KN" | "KP" | "KR" | "KW" | "KY" | "KZ" | "LA" | "LB" | "LC" | "LI" | "LK" | "LR" | "LS" | "LT" | "LU" | "LV" | "LY" | "MA" | "MC" | "MD" | "ME" | "MF" | "MG" | "MH" | "MK" | "ML" | "MM" | "MN" | "MO" | "MP" | "MQ" | "MR" | "MS" | "MT" | "MU" | "MV" | "MW" | "MX" | "MY" | "MZ" | "NA" | "NC" | "NE" | "NF" | "NG" | "NI" | "NL" | "NO" | "NP" | "NR" | "NU" | "NZ" | "OM" | "PA" | "PE" | "PF" | "PG" | "PH" | "PK" | "PL" | "PM" | "PN" | "PR" | "PS" | "PT" | "PW" | "PY" | "QA" | "RE" | "RO" | "RS" | "RU" | "RW" | "SA" | "SB" | "SC" | "SD" | "SE" | "SG" | "SH" | "SI" | "SJ" | "SK" | "SL" | "SM" | "SN" | "SO" | "SR" | "SS" | "ST" | "SV" | "SX" | "SY" | "SZ" | "TC" | "TD" | "TF" | "TG" | "TH" | "TJ" | "TK" | "TL" | "TM" | "TN" | "TO" | "TR" | "TT" | "TV" | "TW" | "TZ" | "UA" | "UG" | "UM" | "US" | "UY" | "UZ" | "VA" | "VC" | "VE" | "VG" | "VI" | "VN" | "VU" | "WF" | "WS" | "XK" | "YE" | "YT" | "ZA" | "ZM" | "ZW" | "%future added value";
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type InvestmentTimeFrame = "FROM_1_TO_3" | "FROM_3_TO_5" | "GT_3" | "GT_5" | "LT_1" | "%future added value";
export type PortfolioType = "HALO" | "NONE" | "SHARESIGHT" | "%future added value";
export type SharingStrategy = "DEEP" | "EXTERNAL" | "SELF" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type VueDetails_vueDetails$ref: FragmentReference;
declare export opaque type VueDetails_vueDetails$fragmentType: VueDetails_vueDetails$ref;
export type VueDetails_vueDetails = {|
  +id: string,
  +name: string,
  +description: ?string,
  +investmentObjective: ?string,
  +investmentStrategy: ?string,
  +investorProfile: ?string,
  +timeFrame: ?InvestmentTimeFrame,
  +sharingStrategy: ?SharingStrategy,
  +portfolioType: ?PortfolioType,
  +riskFactor: ?number,
  +visible: boolean,
  +published: ?boolean,
  +createdAt: any,
  +updatedAt: any,
  +sharesightportfolio: ?{|
    +id: string
  |},
  +haloportfolio: ?{|
    +id: string
  |},
  +vuecontractSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +weight: number,
        +description: ?string,
        +researchPdfUrl: ?string,
        +contract: {|
          +id: string,
          +symbol: string,
          +name: ?string,
          +currency: Currency,
          +exchange: {|
            +symbol: string,
            +country: Country,
            +name: string,
          |},
        |},
      |}
    |}>
  |},
  +vueSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +description: ?string,
        +investmentObjective: ?string,
        +investmentStrategy: ?string,
        +investorProfile: ?string,
        +timeFrame: ?InvestmentTimeFrame,
        +sharingStrategy: ?SharingStrategy,
        +portfolioType: ?PortfolioType,
        +riskFactor: ?number,
        +visible: boolean,
        +published: ?boolean,
        +createdAt: any,
        +updatedAt: any,
        +sharesightportfolio: ?{|
          +id: string
        |},
        +haloportfolio: ?{|
          +id: string
        |},
        +vuecontractSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +weight: number,
              +description: ?string,
              +researchPdfUrl: ?string,
              +contract: {|
                +id: string,
                +symbol: string,
                +name: ?string,
                +currency: Currency,
                +exchange: {|
                  +symbol: string,
                  +country: Country,
                  +name: string,
                |},
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +parentVue: ?{|
    +id: string
  |},
  +$refType: VueDetails_vueDetails$ref,
|};
export type VueDetails_vueDetails$data = VueDetails_vueDetails;
export type VueDetails_vueDetails$key = {
  +$data?: VueDetails_vueDetails$data,
  +$fragmentRefs: VueDetails_vueDetails$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investmentObjective",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investmentStrategy",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "investorProfile",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "timeFrame",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sharingStrategy",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "portfolioType",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "riskFactor",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "visible",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "published",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v14 = [
  (v0/*: any*/)
],
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "sharesightportfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "SharesightPortfolioNode",
  "plural": false,
  "selections": (v14/*: any*/)
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "haloportfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "HaloPortfolioNode",
  "plural": false,
  "selections": (v14/*: any*/)
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "vuecontractSet",
  "storageKey": null,
  "args": null,
  "concreteType": "VueContractNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "VueContractNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "VueContractNode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "weight",
              "args": null,
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "researchPdfUrl",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "contract",
              "storageKey": null,
              "args": null,
              "concreteType": "ContractNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v17/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "currency",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "exchange",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExchangeNode",
                  "plural": false,
                  "selections": [
                    (v17/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "country",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "VueDetails_vueDetails",
  "type": "VueNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v15/*: any*/),
    (v16/*: any*/),
    (v18/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "vueSet",
      "storageKey": "vueSet(orderBy:\"-id\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "-id"
        }
      ],
      "concreteType": "VueNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "VueNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "VueNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v18/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "parentVue",
      "storageKey": null,
      "args": null,
      "concreteType": "VueNode",
      "plural": false,
      "selections": (v14/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd147ef991509d3ec97a5804f2f161b2b';
module.exports = node;
