import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import { Drawer } from '@material-ui/core';
import PaginatedTable from '../common/PaginatedTable';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import TableFieldMeta from '../../common/TableFieldMeta';
import { useCommonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import PositionTransferOutForm from './PositionTransferOutForm';
import cancelPositionTransfer from '../../mutations/CancelPositionTransfer';
import TableStatus from '../common/TableStatus';
import TableButton from '../common/buttons/TableButton';
import approvePositionTransfers from '../../mutations/ApprovePositionTransfers';
import PositionTransferUpdateForm from './PositionTransferUpdateForm';
import RelayFilter from '../../common/RelayFilter';
import ContractLink from '../common/links/ContractLink';
import { doCommitPromise } from '../../common/commit';

function ApproveCancelCell(props) {
  const { transferRequest, brokerTransfer } = props;
  const [selectedBrokerTransfer] = React.useState(brokerTransfer);
  if (transferRequest.status === 'APPROVED') {
    return <TableStatus primary>Approved</TableStatus>;
  }

  if (transferRequest.status === 'CANCELLED') {
    return <TableStatus secondary>Cancelled</TableStatus>;
  }

  const purchases = transferRequest.positionTransferPurchases;
  const requestQuantity = purchases.edges.reduce((v, element) => v + element.node.quantity, null);
  const requestConId = purchases.edges.reduce((_, element) => element.node.contract.ibConId, null);

  const enableApproval = selectedBrokerTransfer
    && (selectedBrokerTransfer.quantity === requestQuantity)
    && (selectedBrokerTransfer.ibConId === requestConId);
  const buttonKey = `${transferRequest.id}-${transferRequest.updatedAt}`;

  return (
    <Grid container spacing={1} justify="center">
      {
        enableApproval && (
          <Grid item>
            <TableButton
              color="primary"
              key={`approve${buttonKey}`}
              onClickPromise={e => doCommitPromise(
                approvePositionTransfers,
                {
                  brokerTransferId: selectedBrokerTransfer.id,
                  transferRequestIds: [transferRequest.id],
                },
                () => null,
                () => null,
              )}
            >
              Approve
            </TableButton>
          </Grid>
        )
      }
      <Grid item>
        <TableButton
          color="secondary"
          key={`cancel${buttonKey}`}
          disabled={transferRequest.status !== 'PENDING'}
          onClick={e => doCommitPromise(
            cancelPositionTransfer,
            {
              positionTransferRequestId: transferRequest.id,
            },
            () => null,
            () => null,
          )}
        >
          Cancel
        </TableButton>
      </Grid>
    </Grid>
  );
}

const contractSymbolCurrency = node => node.positionTransferPurchases.edges.reduce(
  (_, element) => (
    <span>
      {element.node.contract.symbol}
      &bull;
      {element.node.contract.currency}
    </span>
  ),
  '',
);

const contractLink = node => node.positionTransferPurchases.edges.reduce(
  (_, element) => <ContractLink contract={element.node.contract} />,
  '',
);


function PositionTransferRequestList(props) {
  const {
    relay,
    viewer,
    msgRouter,
  } = props;
  const [showTransferOutDrawer, setShowTransferOutDrawer] = React.useState(false);
  const [selectedBrokerTransfer, setSelectedBrokerTransfer] = React.useState(null);
  const [cbEditTransfer, setCbEditTransfer] = React.useState(null);
  const [showEditTransfer, setShowEditTransfer] = React.useState(null);
  const [relayFilter] = React.useState(new RelayFilter({
    search: '',
  }, ['search']));

  const classes = useCommonStyles();

  React.useEffect(() => {
    const onPublishState = (client, state) => {
      const { selectedBrokerTransfer: bts } = state;
      setSelectedBrokerTransfer(bts);
    };
    msgRouter.addStateListener(onPublishState);
    return function cleanup() {
      msgRouter.removeStateListener(onPublishState);
    };
  }, []);

  const columns = [
    TableFieldMeta.localId,
    {
      Header: 'Quantity',
      accessor: node => node.positionTransferPurchases.edges.reduce(
        (accVar, val) => accVar + val.node.quantity,
        0,
      ),
      id: 'quantity',
      ...TableFieldMeta.rightAlignStyle,
    },
    TableFieldMeta.field(contractSymbolCurrency, 'Contract', { align: 'center' }),
    TableFieldMeta.field(contractLink, '', { align: 'center' }),
    {
      id: 'portfolio',
      accessor: node => node.positionTransferPurchases.edges.map(
        element => element.node.portfolio.trackingVueName,
      ).join(','),
      Header: 'Portfolio/s',
    },
    {
      id: 'action',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <ApproveCancelCell
          key={`${row.value.id}-${row.value.updatedAt}-${selectedBrokerTransfer 
          && selectedBrokerTransfer.id}`}
          transferRequest={row.value}
          brokerTransfer={selectedBrokerTransfer}
          msgRouter={msgRouter}
        />
      ),
    },
    {
      id: 'edit_cost_basis',
      accessor: node => node,
      Header: '',
      Cell: (row) => {
        const transfer = row.value;
        // const quantity = transfer.positionTransferPurchases.edges.reduce(
        //   (accVar, val) => accVar + val.node.quantity,
        //   0,
        // );

        const maxUpdatedAt = transfer.positionTransferPurchases.edges.reduce(
          (accVar, purchaseEdge) => {
            const purchaseUpdated = purchaseEdge.updatedAt;
            return (purchaseUpdated > accVar) ? purchaseUpdated : accVar;
          },
          '',
        );
        if (transfer.status !== 'APPROVED') return null;
        return (
          <TableButton
            key={maxUpdatedAt}
            color="secondary"
            onClick={() => {
              setCbEditTransfer(row.value);
              setShowEditTransfer(true);
            }}
          >
            Costbasis
          </TableButton>
        );
      },
    },
    TableFieldMeta.account(node => node.account, 'Account', { charLimit: 20 }),
    TableFieldMeta.createdAt,
  ];

  return (
    <div>
      <Grid container spacing={2}>

        {/*Filters and Deposit/Withdraw row*/}
        <Grid item xs={12}>

          {/*Filters*/}
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <FormGroup row>
                <TextField
                  id="standard-helperText"
                  label="Custom Search"
                  defaultValue=""
                  className={classes.formControl}
                  helperText="Account name, ID"
                  margin="normal"
                  onChange={e => relayFilter.updateVar('search', e.target.value)}
                  style={{ marginRight: '30px' }}
                />
              </FormGroup>
            </Grid>

            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <AddButton
                    buttonText="Transfer-out"
                    onClick={() => setShowTransferOutDrawer(true)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PaginatedTable
            ref={relayFilter.tableRef}
            columns={columns}
            getDataNode={() => (viewer ? viewer.positionTransferRequests : null)}
            getFetchParams={relayFilter.fetchParams}
            relay={relay}
            viewer={viewer}
          />
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={showEditTransfer}
        onClose={() => {
          setShowEditTransfer(false);
        }}
      >
        <PositionTransferUpdateForm
          transferRequest={cbEditTransfer}
          onClose={() => setShowEditTransfer(false)}
        />
      </Drawer>

      <Drawer
        anchor="right"
        open={showTransferOutDrawer}
        onClose={() => setShowTransferOutDrawer(false)}
      >
        <PositionTransferOutForm
          transferRequest={null}
          onClose={() => setShowTransferOutDrawer(false)}
          onAdd={() => relayFilter.update()}
        />
      </Drawer>
    </div>
  );
}

const query = graphql`
  query PositionTransferRequestListQuery (
    $first: Int
    $offset: Int
    $search: String
    $accountId: ID
    $accountType: String
  ) {
    viewer {
      ...PositionTransferRequestList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        accountId: $accountId
        accountType: $accountType
      )
    }
  }
`;

const reFetched = createRefetchContainer(
  PositionTransferRequestList,
  {
    viewer: graphql`
      fragment PositionTransferRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID}
        search: {type: String, defaultValue: null }
        accountType: {type: String, defaultValue: null }
      ) {
        positionTransferRequests (
          first: $first
          offset: $offset
          search: $search
          orderBy: "-createdAt"
          account: $accountId
          accountType: $accountType
        ) {
          edges {
            node {
              id
              createdAt
              updatedAt
              status
              account {
                id
                name
              }
              
              positionTransferPurchases {
                edges {
                  node {
                    id
                    updatedAt
                    quantity
                    reportDate
                    contract {
                      id
                      name
                      symbol
                      ibConId
                      currency
                    }
                    portfolio {
                      id
                      trackingVueName
                    }
                    updatedAt
                    costBasis
                    commission
                    dummy
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgress(
  query,
  reFetched,
  (viewer, { msgRouter, accountId, accountType }) => ({ msgRouter, accountId, accountType }),
  ({ accountId, accountType }) => ({ accountId, accountType }),
);
