import * as React from 'react';
import Link from '@material-ui/core/Link';
import { fromGlobalId } from '../../../helpers/ID';

const UserLink = (props) => {
  const { user } = props;
  if (!user) return null;
  const userId = fromGlobalId(user.id)[1];
  return (
    <Link href={`/user/${userId}`}>
      {userId}
    </Link>
  );
};

export default UserLink;
