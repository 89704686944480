/**
 * @flow
 * @relayHash 452e41c73d49205e26479172d6ee6cb9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenerateDividendPayoutsMutationVariables = {|
  forDate: any
|};
export type GenerateDividendPayoutsMutationResponse = {|
  +generateDividendPayoutCashTransfers: ?{|
    +csvText: ?string
  |}
|};
export type GenerateDividendPayoutsMutation = {|
  variables: GenerateDividendPayoutsMutationVariables,
  response: GenerateDividendPayoutsMutationResponse,
|};
*/


/*
mutation GenerateDividendPayoutsMutation(
  $forDate: Date!
) {
  generateDividendPayoutCashTransfers(createCtrs: false, forDate: $forDate, skipFailing: true) {
    csvText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "forDate",
    "type": "Date!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateDividendPayoutCashTransfers",
    "storageKey": null,
    "args": [
      {
        "kind": "Literal",
        "name": "createCtrs",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "forDate",
        "variableName": "forDate"
      },
      {
        "kind": "Literal",
        "name": "skipFailing",
        "value": true
      }
    ],
    "concreteType": "GenerateDividendPayoutCashTransferRequests",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "csvText",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateDividendPayoutsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateDividendPayoutsMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateDividendPayoutsMutation",
    "id": null,
    "text": "mutation GenerateDividendPayoutsMutation(\n  $forDate: Date!\n) {\n  generateDividendPayoutCashTransfers(createCtrs: false, forDate: $forDate, skipFailing: true) {\n    csvText\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '139af9812dead0118be2516b721d6f0c';
module.exports = node;
