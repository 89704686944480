/**
 * @flow
 * @relayHash 21750b151ea0082eca49393db386c1db
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrderApproval_viewer$ref = any;
export type OrderApprovalQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  approvalId?: ?string,
|};
export type OrderApprovalQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: OrderApproval_viewer$ref
  |}
|};
export type OrderApprovalQuery = {|
  variables: OrderApprovalQueryVariables,
  response: OrderApprovalQueryResponse,
|};
*/


/*
query OrderApprovalQuery(
  $first: Int
  $offset: Int
  $approvalId: ID
) {
  viewer {
    ...OrderApproval_viewer_2qTbsX
    id
  }
}

fragment OrderApproval_viewer_2qTbsX on Query {
  orderApprovals(first: $first, offset: $offset, id: $approvalId) {
    edges {
      node {
        id
        portfolioOrder {
          id
          status
        }
        createdAt
        updatedAt
        approvedAt
        notifiedAt
        remindedAt
        proposal {
          id
          type
        }
        approvedBy {
          fullName
          id
        }
        status
        portfolio {
          id
          trackingVueName
          account {
            name
            id
          }
        }
        actionLogs {
          edges {
            node {
              id
              actionDescription
              actionId
              actionTime
              actionType
              user {
                id
                fullName
              }
            }
          }
        }
        orderclientapprovalSet {
          edges {
            node {
              id
              pdfUrl
            }
          }
        }
        orderapprovalcontractSet {
          edges {
            node {
              id
              createdAt
              updatedAt
              contract {
                id
                symbol
              }
              quantity
              orderStatus
              order {
                id
                brokerOrder {
                  status
                  matched
                  id
                }
              }
            }
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approvalId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "OrderApprovalQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "OrderApproval_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "approvalId",
                "variableName": "approvalId"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "OrderApprovalQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "orderApprovals",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "approvalId"
              },
              (v2/*: any*/)
            ],
            "concreteType": "OrderApprovalNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OrderApprovalNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "OrderApprovalNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolioOrder",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioOrderNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ]
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "approvedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "notifiedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "remindedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "proposal",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderProposalNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "approvedBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "portfolio",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "trackingVueName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "account",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "actionLogs",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ActionLogUserRequestNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ActionLogUserRequestNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ActionLogUserRequestNode",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionDescription",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionId",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionTime",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "actionType",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "user",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v7/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "orderclientapprovalSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderClientApprovalNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OrderClientApprovalNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "OrderClientApprovalNode",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "pdfUrl",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "orderapprovalcontractSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "OrderApprovalContractNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "OrderApprovalContractNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "OrderApprovalContractNode",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "contract",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ContractNode",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "symbol",
                                        "args": null,
                                        "storageKey": null
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "quantity",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "orderStatus",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "order",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "OrderNode",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "brokerOrder",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerOrderNode",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "matched",
                                            "args": null,
                                            "storageKey": null
                                          },
                                          (v3/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "OrderApprovalQuery",
    "id": null,
    "text": "query OrderApprovalQuery(\n  $first: Int\n  $offset: Int\n  $approvalId: ID\n) {\n  viewer {\n    ...OrderApproval_viewer_2qTbsX\n    id\n  }\n}\n\nfragment OrderApproval_viewer_2qTbsX on Query {\n  orderApprovals(first: $first, offset: $offset, id: $approvalId) {\n    edges {\n      node {\n        id\n        portfolioOrder {\n          id\n          status\n        }\n        createdAt\n        updatedAt\n        approvedAt\n        notifiedAt\n        remindedAt\n        proposal {\n          id\n          type\n        }\n        approvedBy {\n          fullName\n          id\n        }\n        status\n        portfolio {\n          id\n          trackingVueName\n          account {\n            name\n            id\n          }\n        }\n        actionLogs {\n          edges {\n            node {\n              id\n              actionDescription\n              actionId\n              actionTime\n              actionType\n              user {\n                id\n                fullName\n              }\n            }\n          }\n        }\n        orderclientapprovalSet {\n          edges {\n            node {\n              id\n              pdfUrl\n            }\n          }\n        }\n        orderapprovalcontractSet {\n          edges {\n            node {\n              id\n              createdAt\n              updatedAt\n              contract {\n                id\n                symbol\n              }\n              quantity\n              orderStatus\n              order {\n                id\n                brokerOrder {\n                  status\n                  matched\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b52a638f144cf6ad4b08ed817b8f774e';
module.exports = node;
