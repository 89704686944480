import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { QueryRenderer } from 'react-relay';
import environment from '../../Environment';
import { ROWS_PER_PAGE } from '../../common/constants';
import RelayFilter from '../../common/RelayFilter';

export function queryRenderWrapper(propsToChild, render, outerProps, relayFilter) {
  return function qrw({ error, props, retry }) {
    if (error) {
      return (
        <div
          style={{
            margin: '100px 0px',
            textAlign: 'center',
            color: 'grey',
          }}
        >
          {error.message}
          :
          <br />
          Make sure backend is available, and there are no connectivity problems
        </div>
      );
    }
    const RenderClass = render;
    if (props) {
      const { viewer } = props;

      let finalChildProps = {};
      if (typeof propsToChild === 'function') {
        finalChildProps = { relayFilter, retry, ...propsToChild(viewer, outerProps) };
      } else {
        finalChildProps = { relayFilter, retry, ...propsToChild };
      }

      if (viewer) {
        return <RenderClass viewer={props.viewer} {...finalChildProps} />;
      }
    }
    return <LinearProgress />;
  };
}

export default function QueryRendererProgress(props) {
  const {
    queryClass,
    componentClass,
    propsToChild,
    initQueryParams,
  } = props;

  let queryParams = {};
  if (typeof initQueryParams === 'function') {
    queryParams = initQueryParams(props);
  } else {
    queryParams = initQueryParams;
  }

  return (
    <QueryRenderer
      environment={environment}
      query={queryClass}
      render={
        queryRenderWrapper(propsToChild, componentClass, props)
      }
      variables={{
        offset: 0,
        first: ROWS_PER_PAGE,
        ...queryParams,
      }}
    />
  );
}

QueryRendererProgress.propTypes = {
  propsToChild: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  initQueryParams: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  queryClass: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  componentClass: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

QueryRendererProgress.defaultProps = {
  propsToChild: {},
  initQueryParams: {},
};

export const createQueryRendererProgress = (
  queryClass,
  componentClass,
  propsToChild = {},
  initQueryParams = {},
  otherOptions = {},
) => (props) => {
  let queryParams = {};
  if (typeof initQueryParams === 'function') {
    queryParams = initQueryParams(props);
  } else {
    queryParams = initQueryParams;
  }

  let debounceFields = [];
  if (otherOptions) {
    const { debounceFields: debounceFieldsIn } = otherOptions;
    if (debounceFieldsIn) debounceFields = debounceFieldsIn;
  }

  const relayFilter = new RelayFilter(queryParams, debounceFields, props);
  return (
    <QueryRenderer
      environment={environment}
      query={queryClass}
      render={
        queryRenderWrapper(propsToChild, componentClass, props, relayFilter)
      }
      variables={{
        offset: 0,
        first: ROWS_PER_PAGE,
        ...relayFilter.params,
      }}
    />
  );
};
