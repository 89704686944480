import { Grid } from '@material-ui/core';
import React from 'react';
import { createRefetchContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { useCommonStyles } from '../common/Styles';
import ApprovableStatus from './ApprovableStatus';
import UserLink from '../common/links/UserLink';
import UserProvisionRequestListForm from './UserProvisionRequestListForm';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';


function UserProvisionRequestList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.createdBy,
    TableFieldMeta.account(node => node.clientAccount),
    TableFieldMeta.field(node => <UserLink user={node.userModel} />, 'User ID'),
    TableFieldMeta.field(node => node.userModel.email, 'Email'),
    TableFieldMeta.field(node => <ApprovableStatus approvable={node} />,
      'Status', { align: 'center' }),
    TableFieldMeta.field(node => (
      <UserProvisionRequestListForm userProvisionRequest={node} />
    ),
    '', { align: 'center' }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterSelectField
          enumType="ApprovableStatus"
          label="Status"
          value={filter.params.status}
          helperText="Approved/Pending/Cancelled"
          onChange={e => filter.updateVar('status', e.target.value)}
        />
        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.userProvisionRequests : null)}
            filter={filter}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query UserProvisionRequestListQuery(
    $first: Int
    $offset: Int
    $status: String
  ) {
    viewer {
      ...UserProvisionRequestList_viewer
      @arguments(
        first: $first
        offset: $offset
        status: $status
      )
    }
  }
`;

const container = createRefetchContainer(
  UserProvisionRequestList,
  {
    viewer: graphql`
      fragment UserProvisionRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        status: {type: String}
      ) {
        userProvisionRequests(
          first: $first
          offset: $offset
          status: $status
          orderBy: "-createdAt"
        ) {
          count
          edges {
            node {
              id
              status
              createdAt
              createdBy {
                id
                fullName
              }
              
              clientAccount {
                id
                name
              }
              userModel {
                id
                email
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
  }),
));
