/**
 * @flow
 * @relayHash 764232251a70ceeb313c85a201ec9d93
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DailyReconcileList_viewer$ref = any;
export type DailyReconcileListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
|};
export type DailyReconcileListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: DailyReconcileList_viewer$ref
  |}
|};
export type DailyReconcileListQuery = {|
  variables: DailyReconcileListQueryVariables,
  response: DailyReconcileListQueryResponse,
|};
*/


/*
query DailyReconcileListQuery(
  $first: Int
  $offset: Int
) {
  viewer {
    ...DailyReconcileList_viewer_21Sbsv
    id
  }
}

fragment DailyReconcileList_viewer_21Sbsv on Query {
  ibStatementOfFunds(first: $first, offset: $offset, orderBy: "-fromDate") {
    count
    edges {
      node {
        id
        file
        fileUrl
        createdAt
        updatedAt
        fromDate
        toDate
        ibdailyreconciliation {
          id
          createdAt
          updatedAt
          excelFile
          excelFileUrl
          excelFileName
          ibdailyreconciliationendbalanceSet {
            edges {
              node {
                createdAt
                updatedAt
                currency
                quantity
                id
              }
            }
          }
          ibdailyreconciliationdeltaSet {
            edges {
              node {
                createdAt
                updatedAt
                currency
                quantity
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "DailyReconcileListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DailyReconcileList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DailyReconcileListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibStatementOfFunds",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-fromDate"
              }
            ],
            "concreteType": "IBStatementOfFundsNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "IBStatementOfFundsNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBStatementOfFundsNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "file",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fileUrl",
                        "args": null,
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "fromDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "toDate",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "ibdailyreconciliation",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBDailyReconciliationNode",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "excelFile",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "excelFileUrl",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "excelFileName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "ibdailyreconciliationendbalanceSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IBDailyReconciliationEndBalanceNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "IBDailyReconciliationEndBalanceNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IBDailyReconciliationEndBalanceNode",
                                    "plural": false,
                                    "selections": (v6/*: any*/)
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "ibdailyreconciliationdeltaSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "IBDailyReconciliationDeltaNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "IBDailyReconciliationDeltaNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "IBDailyReconciliationDeltaNode",
                                    "plural": false,
                                    "selections": (v6/*: any*/)
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "DailyReconcileListQuery",
    "id": null,
    "text": "query DailyReconcileListQuery(\n  $first: Int\n  $offset: Int\n) {\n  viewer {\n    ...DailyReconcileList_viewer_21Sbsv\n    id\n  }\n}\n\nfragment DailyReconcileList_viewer_21Sbsv on Query {\n  ibStatementOfFunds(first: $first, offset: $offset, orderBy: \"-fromDate\") {\n    count\n    edges {\n      node {\n        id\n        file\n        fileUrl\n        createdAt\n        updatedAt\n        fromDate\n        toDate\n        ibdailyreconciliation {\n          id\n          createdAt\n          updatedAt\n          excelFile\n          excelFileUrl\n          excelFileName\n          ibdailyreconciliationendbalanceSet {\n            edges {\n              node {\n                createdAt\n                updatedAt\n                currency\n                quantity\n                id\n              }\n            }\n          }\n          ibdailyreconciliationdeltaSet {\n            edges {\n              node {\n                createdAt\n                updatedAt\n                currency\n                quantity\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2d511b7439f0cc192827627bbae17ff';
module.exports = node;
