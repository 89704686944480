import { graphql } from 'react-relay';

// language=GraphQL
const removeUserFromAccount = graphql`
  mutation RemoveUserFromAccountMutation(
    $clientAccountId: ID!,
    $userId: ID!
  ) {
    removeUserFromAccount (
      clientAccountId: $clientAccountId,
      userId: $userId
    ) {
      clientAccount {
        id
        name
        userModels {
          edges {
            node {
              id
              username
              email
              firstName
              lastName
              username
            }
          }
        }
      }
    }
  }
`;

export default removeUserFromAccount;
