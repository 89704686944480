import { Grid } from '@material-ui/core';
import React from 'react';
import { createRefetchContainer } from 'react-relay';
import { graphql } from 'relay-runtime';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../common/TableFieldMeta';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { useCommonStyles } from '../common/Styles';
import ApprovableStatus from './ApprovableStatus';
import CurrencyConversionListForm from './CurrencyConversionListForm';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';


function CurrencyConversionRequestList(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.createdBy,
    TableFieldMeta.account(node => node.account),
    TableFieldMeta.field(node => -1 * node.quantity, 'Quantity'),
    TableFieldMeta.field('baseCurrency', 'From Currency'),
    TableFieldMeta.field('quoteCurrency', 'To Currency'),
    TableFieldMeta.field(node => <ApprovableStatus approvable={node} />,
      'Status', { align: 'center' }),
    TableFieldMeta.field(node => (
      <CurrencyConversionListForm currencyConversionRequest={node} />
    ),
    '', { align: 'center' }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterSelectField
          enumType="ApprovableStatus"
          label="Status"
          value={filter.params.status}
          helperText="Approved/Pending/Cancelled"
          onChange={e => filter.updateVar('status', e.target.value)}
        />
        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            getDataNode={() => (viewer ? viewer.currencyConversionRequests : null)}
            filter={filter}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query CurrencyConversionRequestListQuery(
    $first: Int
    $offset: Int
    $status: String
    $accountId: ID
  ) {
    viewer {
      ...CurrencyConversionRequestList_viewer
      @arguments(
        first: $first
        offset: $offset
        status: $status
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  CurrencyConversionRequestList,
  {
    viewer: graphql`
      fragment CurrencyConversionRequestList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        status: {type: String}
        accountId: {type: ID}
      ) {
        currencyConversionRequests(
          first: $first
          offset: $offset
          status: $status
          accountId: $accountId
          orderBy: "-createdAt"
        ) {
          count
          edges {
            node {
              id
              baseCurrency
              quoteCurrency
              quantity
              status
              createdAt
              createdBy {
                id
                fullName
              }
              account {
                id
                name
              }
            }
          }
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({
    accountId,
  }) => ({
    accountId,
  }),
));
