import React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { createRefetchContainer, graphql } from 'react-relay';
import AccountLink from '../common/links/AccountLink';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { fromGlobalId } from '../../helpers/ID';
import { ExportButton } from '../common/buttons/BaseButton';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import Block from '../../icons/Block';
import NoBlock from '../../icons/NoBlock';
import { doCommitPromise } from '../../common/commit';
import dummyTransaction from '../../mutations/DummyTransaction';
import TableStatus from '../common/TableStatus';

function BrokerTransactionDetails(props) {
  const { viewer } = props;
  const brokerTransaction = viewer.ibBrokerTransactions
    && viewer.ibBrokerTransactions.edges && viewer.ibBrokerTransactions.edges[0].node;
  const data = brokerTransaction.brokertransactionPtr.transactions.edges.flatMap(
    txn => [{
      transaction: txn.node,
      account: txn.node.account,
      currency: txn.node.currency,
      dummy: txn.node.dummy,
      proceeds: txn.node.proceeds,
      quantity: txn.node.quantity,
    }],
  );

  data.sort((a, b) => {
    const aLocal = fromGlobalId(a.account.id)[1];
    const bLocal = fromGlobalId(b.account.id)[1];
    return aLocal - bLocal;
  });

  const baIdSplit = fromGlobalId(brokerTransaction.id);
  const exportEntries = () => {
    // const blob = new Blob(
    //   [BrokerActivity.toCSV(brokerActivity.brokeractivityPtr, false)],
    //   { type: 'text/plain;charset=utf-8' },
    // );
    // FileSaver.saveAs(blob, `${baIdSplit[0]}.${baIdSplit[1]}.csv`);
  };

  return (
    <Grid container spacing={2}>
      <DrawerGridItemHeading
        heading={`Details for ${baIdSplit[0]} : ${baIdSplit[1]}`}
      />
      <Grid item xs={12}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Txn ID</TableCell>
              <TableCell>Acc. ID</TableCell>
              <TableCell>Account</TableCell>
              <TableCell align="right">Proceeds</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="center">Dummy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map(dataRow => (
                <TableRow>
                  <TableCell>
                    {fromGlobalId(dataRow.transaction.id)[1]}
                  </TableCell>
                  <TableCell>
                    {fromGlobalId(dataRow.account.id)[1]}
                  </TableCell>
                  <TableCell>
                    <AccountLink account={dataRow.account} charLimit={20} showAppLink />
                  </TableCell>
                  <TableCell align="right">{dataRow.proceeds}</TableCell>
                  <TableCell align="right">{dataRow.quantity}</TableCell>
                  <TableCell>
                    <Grid
                      container
                      spacing={1}
                      justify="center"
                    >
                      {
                        dataRow.dummy && (
                          <Grid item>
                            <TableStatus>Dummied</TableStatus>
                          </Grid>
                        )
                      }
                      <TableCompactGridItem>
                        <TableIconButton
                          key={0}
                          tooltip={dataRow.dummy ? 'Undummy' : 'dummy'}
                          iconClass={dataRow.dummy ? NoBlock : Block}
                          onClickPromise={() => doCommitPromise(
                            dummyTransaction,
                            {
                              transactionId: dataRow.transaction.id,
                              dummy: !dataRow.dummy,
                            },
                            () => null,
                            () => null,
                          )}
                        />
                      </TableCompactGridItem>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <ExportButton
              buttonText="Export Entries"
              onClick={() => exportEntries()}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}

const query = graphql`
  query BrokerTransactionDetailsQuery (
    $first: Int
    $offset: Int
    $brokerTransactionId: ID
  ) {
    viewer {
      ...BrokerTransactionDetails_viewer
      @arguments (
        first: $first
        offset: $offset
        brokerTransactionId: $brokerTransactionId
      )
    }
  }
`;

const container = createRefetchContainer(
  BrokerTransactionDetails,
  {
    viewer: graphql`
      fragment BrokerTransactionDetails_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10}
        offset: {type: Int}
        brokerTransactionId: { type: ID}
      ) {
        ibBrokerTransactions(
          id: $brokerTransactionId
        ) {
          edges {
            node {
              id
              brokertransactionPtr {
                id
                matched
                updatedAt
                createdAt
                transactions {
                  edges {
                    node {
                      id
                      currency
                      dummy
                      proceeds
                      quantity
                      account {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ brokerTransactionId }) => ({ brokerTransactionId }),
);
