import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { createFragmentContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { commonStyles } from '../common/Styles';
import StockOrderDetails from './stockOrder/StockOrderDetails';
import VerticalTable from '../common/VerticalTable';
import TableFieldMeta from '../../common/TableFieldMeta';
import AccountLink from '../common/links/AccountLink';
import VueLink from '../common/links/VueLink';
import EnumValue from '../common/EnumValue';
import { toLocal } from '../../helpers/ID';

function PortfolioOrderDetails(props) {
  const {
    classes, 
    portfolioOrder, 
    exchanges, 
    viewer,
  } = props;

  const columnsLeft = [
    ['Created At', TableFieldMeta.createdAt.accessor],
    ['Updated At', TableFieldMeta.updatedAt.accessor],
    ['Bulk Order', (node) => {
      if (!node.bulkOrder) return null;
      const orderId = toLocal(node.bulkOrder.id);
      return <Link href={`/bulk-oder/${orderId}`}>{orderId}</Link>;
    }],
  ];

  const columnsRight = [
    ['Account', node => <AccountLink account={node.account} />],
    ['Vue', node => (node.portfolio.trackingVue
      ? <VueLink vue={node.portfolio.trackingVue} />
      : node.portfolio.trackingVueName)],
    ['Order Status', node => <EnumValue enumType="PortfolioOrderStatus" enumValue={node.status} />],
  ];

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <Paper className={classes.tableHolderRoot}>
            <VerticalTable
              showHeadings={false}
              columnMeta={columnsLeft}
              dataNode={portfolioOrder}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper className={classes.tableHolderRoot}>
            <VerticalTable
              showHeadings={false}
              columnMeta={columnsRight}
              dataNode={portfolioOrder}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <div>
            {
              portfolioOrder.stockOrders.edges.map(
                stockOrder => (
                  <StockOrderDetails
                    stockOrder={stockOrder.node}
                    exchanges={exchanges}
                    viewer={viewer}
                  />
                ),
              )
            }
          </div>
        </Grid>

      </Grid>
    </div>

  );
}

export default createFragmentContainer(
  withStyles(commonStyles)(PortfolioOrderDetails),
  {
    portfolioOrder: graphql`
      fragment PortfolioOrderDetails_portfolioOrder on PortfolioOrderNode {
        id
        side
        createdAt
        updatedAt
        status

        bulkOrder {
          id
        }

        portfolio {
          id
          trackingVueName
          trackingVue {
            id
            name
          }
        }

        account {
          id
          name
        }

        tradeInvoice {
          pdf
          pdfUrl
          createdAt
          updatedAt
          state
        }

        stockOrders(orderBy: "createdAt") {
          edges {
            node {
              ...StockOrderDetails_stockOrder
            }
          }
        }
      }
    `,
  },
);
