import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useCommonStyles } from '../Styles';
import { EnumValues } from '../EnumValue';

const SIZE_HEIGHTS = { small: '24px', medium: '32px', large: '40px' };

export function ToggleButtonGroupWrapped(props) {
  const {
    onChange,
    enumMetaArray,
    defaultValue,
    size,
  } = props;
  const [value, setValue] = React.useState(defaultValue);
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      size={size}
      onChange={(e, newValue) => {
        onChange && onChange(newValue);
        setValue(newValue);
      }}
    >
      {
        enumMetaArray.map(enumEntry => (
          <ToggleButton value={enumEntry.name} size={size} style={{ height: SIZE_HEIGHTS[size] }}>
            {enumEntry.description}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  );
}

function InnerTabFilterToggleButtonGroup(props) {
  const classes = useCommonStyles();
  const { 
    onChange,
    enumType,
    defaultValue,
    label,
    helperText,
  } = props;

  return (
    <Grid item>
      <FormControl className={classes.formControlToggleButtonGroup}>
        <InputLabel htmlFor="accountType-helper">{label}</InputLabel>
        <EnumValues
          enumType={enumType}
          render={(enumMeta, enumMetaArray) => (
            <ToggleButtonGroupWrapped
              enumMetaArray={enumMetaArray}
              defaultValue={defaultValue}
              onChange={onChange}
            />
          )}
        />

        {helperText && (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
}

InnerTabFilterToggleButtonGroup.propTypes = {
  defaultValue: PropTypes.any.isRequired,
  enumType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
};

InnerTabFilterToggleButtonGroup.defaultProps = {
  helperText: null,
  onChange: null,
};

export default InnerTabFilterToggleButtonGroup;
