import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useCommonStyles } from '../Styles';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function DrawerGridItemNumberField(props) {
  const {
    inputRef,
    defaultValue,
    onChange,
    helperText,
    label,
    id,
    ...otherProps
  } = props;
  const classes = useCommonStyles();
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <TextField
        placeholder={otherProps.placeholder}
        fullWidth
        className={classes.fromControlDrawer}
        id={id || (label && label.toLowerCase())}
        label={label}
        type="number"
        inputRef={inputRef}
        helperText={helperText}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </Grid>
  );
}

export default DrawerGridItemNumberField;
