import { graphql } from 'react-relay';

const createPositionTransfersOut = graphql`
  mutation CreatePositionTransfersOutMutation (
    $accountId: ID!
    $positionTransfers: [PositionTransferPurchaseInput],
  ) {
    createPositionTransfersOut (
      accountId: $accountId
      positionTransfers: $positionTransfers
    ){
      positionTransferRequests {
        id
        account {
          id
        }
      }
    } 
  }
`;

export default createPositionTransfersOut;
