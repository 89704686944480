import Grid from '@material-ui/core/Grid';
import React from 'react';
import { commitMutation } from 'react-relay';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CancelIcon from '@material-ui/icons/Cancel';
import { Decimal } from 'decimal.js';
import ApproveIcon from '@material-ui/icons/CheckCircle';
import ReconcileIcon from '@material-ui/icons/CallMerge';
import TableButton from './buttons/TableButton';
import environment from '../../Environment';
import Console from '../../common/log';
import TableFieldMeta from '../../common/TableFieldMeta';
import TableStatus from './TableStatus';
import DateTime from './DateTimeMoment';
import blockCashTransferRequest from '../../mutations/BlockCashTransferRequest';
import approveCashTransferRequest from '../../mutations/ApproveCashTransferRequest';
import cancelCashTransferRequests from '../../mutations/CancelCashTransferRequest';
import TableTwoStateIconButton from './table/TableTwoStateIconButton';
import TableIconButton from './table/TableIconButton';
import EnumValue from './EnumValue';
import reconcileCashTransferRequests from '../../mutations/ReconcileCashTransferRequests';
import { maxOfTime } from '../../common/time';
import { doCommitPromise } from '../../common/commit';


const blockTransfer = (transferRequest, block) => {
  const variables = {
    cashTransferRequestId: transferRequest.id,
    clientMutationId: `muUpdateTransfer:${transferRequest.id}`,
    block,
  };

  commitMutation(environment, {
    mutation: blockCashTransferRequest,
    variables,
    onCompleted: (response, errors) => {
    },
    onError: (err) => {
      Console.error(err);
    },
  });
};

function TransferApprovalCell(props) {
  const { transferRequest, ibCashTransfer } = props;

  if (transferRequest.status === 'CAN') {
    return <TableStatus secondary>Cancelled</TableStatus>;
  }

  if (transferRequest.cashTransfer) {
    return <TableStatus primary>Reconciled</TableStatus>;
  }

  const { cashtransferrequestapprovalSet: approvalSet } = transferRequest;
  const approvedCount = approvalSet.edges.length;
  const approvedUsers = approvalSet.edges.map(edge => edge.node.approvedBy.fullName).join(', ');
  const buttonKey = `${transferRequest.id}-${transferRequest.updatedAt}`;
  
  const matchWithBrokerTransfer = ibCashTransfer && new Decimal(ibCashTransfer.amount).equals(
    new Decimal(transferRequest.amount),
  );

  return (
    <Grid container spacing={1} justify="center">
      <Grid item>
        <TableButton
          disabled
        >
          <EnumValue enumType="CashTransferStatus" enumValue={transferRequest.status} />
        </TableButton>
      </Grid>
      <Grid item>
        <TableIconButton
          tooltip="Cancel"
          color="secondary"
          disabled={transferRequest.status !== 'VER' && transferRequest.status !== 'PEN'}
          iconClass={CancelIcon}
          onClickPromise={() => doCommitPromise(
            cancelCashTransferRequests,
            {
              transferRequestId: transferRequest.id,
            },
          )}
        />
      </Grid>

      {
        transferRequest.amount < 0 && (
          <Grid item>
            <TableTwoStateIconButton
              tooltip="Block/Unblock"
              key={buttonKey}
              states={{
                locked: ['primary', LockIcon],
                unlocked: ['secondary', LockOpenIcon],
              }}
              state={transferRequest.blocked ? 'locked' : 'unlocked'}
              onClick={e => blockTransfer(transferRequest, !transferRequest.blocked)}
            />
          </Grid>
        )
      }

      {
        transferRequest.amount > 0 && (
          <Grid item>
            <TableIconButton
              tooltip={approvedCount > 0 ? `Approved by ${approvedUsers}` : 'Approve'}
              color="primary"
              count={approvedCount}
              disabled={transferRequest.amount < 0 || transferRequest.status !== 'VER'}
              iconClass={ApproveIcon}
              onClickPromise={() => doCommitPromise(
                approveCashTransferRequest,
                {
                  transferRequestId: transferRequest.id,
                },
              )}
            />
          </Grid>
        )
      }
      <Grid item>
        <TableIconButton
          tooltip="Reconcile"
          color="primary"
          disabled={!matchWithBrokerTransfer
          || (transferRequest.amount > 0 && transferRequest.status === 'REC')
          || (transferRequest.amount < 0 && transferRequest.status !== 'VER')}
          iconClass={ReconcileIcon}
          // onClick={() => reconcileCashTransfer(ibCashTransfer.id, [transferRequest.id])}
          onClickPromise={() => doCommitPromise(
            reconcileCashTransferRequests,
            {
              transferRequestIds: [transferRequest.id],
              brokerTransferId: ibCashTransfer.id,
            },
          )}

        />
      </Grid>
    </Grid>
  );
}

TransferApprovalCell.updatedAtMeta = {
  accessor: (node) => {
    if (node.cancelled) {
      return <DateTime>{node.updatedAt}</DateTime>;
    }

    if (node.cashtransferrequestapprovalSet.edges.length > 0) {
      const first = node.cashtransferrequestapprovalSet.edges[0].node.approvedAt;
      const latest = node.cashtransferrequestapprovalSet.edges.reduce(
        (v, c) => maxOfTime(v, c.node.approvedAt),
        first,
      );
      return <DateTime>{latest}</DateTime>;
    }

    // if (node.cashTransfer) {
    //   const { brokerActivity } = node.cashTransfer;
    //   const brokerActivityTransactionSet = brokerActivity.brokeractivitytransactionSet;
    //   return <DateTime>{brokerActivityTransactionSet.edges[0].node.createdAt}</DateTime>;
    // }
    return <DateTime>{node.createdAt}</DateTime>;
  },
  Header: ' Approved/Cancelled At',
  id: 'updatedAt',
  ...TableFieldMeta.rightAlignStyle,
};

TransferApprovalCell.updatedAtResolver = (node) => {
  if (node.cancelled) {
    return <DateTime>{node.createdAt}</DateTime>;
  }

  if (node.cashTransfer) {
    return <DateTime>{node.cashTransfer.createdAt}</DateTime>;
  }

  return <DateTime>{node.createdAt}</DateTime>;
};

export default TransferApprovalCell;
