/**
 * @flow
 * @relayHash 4b80ab0a0215e691e7e7792c2227e75f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChargeInfoFormPane_viewer$ref = any;
export type ChargeInfoFormPaneQueryVariables = {|
  chargeId?: ?string
|};
export type ChargeInfoFormPaneQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ChargeInfoFormPane_viewer$ref
  |}
|};
export type ChargeInfoFormPaneQuery = {|
  variables: ChargeInfoFormPaneQueryVariables,
  response: ChargeInfoFormPaneQueryResponse,
|};
*/


/*
query ChargeInfoFormPaneQuery(
  $chargeId: ID
) {
  viewer {
    ...ChargeInfoFormPane_viewer_2sVK67
    id
  }
}

fragment ChargeInfoFormPane_viewer_2sVK67 on Query {
  manualAccountCharges(id: $chargeId) {
    edges {
      node {
        accountchargePtr {
          currency
          accountchargetenantportionSet {
            edges {
              node {
                id
                quantity
                tenantAccount {
                  id
                  name
                  accountPtr {
                    id
                    name
                  }
                }
              }
            }
          }
          id
        }
        id
        amount
        description
        createdAt
        updatedAt
        feeType
        currency
        account {
          id
          name
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "chargeId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChargeInfoFormPaneQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ChargeInfoFormPane_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "chargeId",
                "variableName": "chargeId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ChargeInfoFormPaneQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "manualAccountCharges",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "chargeId"
              }
            ],
            "concreteType": "ManualAccountChargeNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "ManualAccountChargeNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ManualAccountChargeNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "accountchargePtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountChargeNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "accountchargetenantportionSet",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AccountChargeTenantPortionNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "AccountChargeTenantPortionNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "AccountChargeTenantPortionNode",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "quantity",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "tenantAccount",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "TenantAccountNode",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          (v3/*: any*/),
                                          {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "accountPtr",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "AccountNode",
                                            "plural": false,
                                            "selections": (v4/*: any*/)
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "description",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "feeType",
                        "args": null,
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": (v4/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ChargeInfoFormPaneQuery",
    "id": null,
    "text": "query ChargeInfoFormPaneQuery(\n  $chargeId: ID\n) {\n  viewer {\n    ...ChargeInfoFormPane_viewer_2sVK67\n    id\n  }\n}\n\nfragment ChargeInfoFormPane_viewer_2sVK67 on Query {\n  manualAccountCharges(id: $chargeId) {\n    edges {\n      node {\n        accountchargePtr {\n          currency\n          accountchargetenantportionSet {\n            edges {\n              node {\n                id\n                quantity\n                tenantAccount {\n                  id\n                  name\n                  accountPtr {\n                    id\n                    name\n                  }\n                }\n              }\n            }\n          }\n          id\n        }\n        id\n        amount\n        description\n        createdAt\n        updatedAt\n        feeType\n        currency\n        account {\n          id\n          name\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a83db59049c1f81ae160e0f58216f803';
module.exports = node;
