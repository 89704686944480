import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import PropTypes from 'prop-types';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function DrawerGridItemTextField(props) {
  const {
    defaultValue,
    onChange,
    label,
    value,
    id,
    type,
    disabled,
    ...otherProps
  } = props;
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <TextField
        fullWidth
        id={id || (label && label.toLowerCase())}
        label={label}
        type={type}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...otherProps}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  );
}

DrawerGridItemTextField.propTypes = {
  type: PropTypes.string,
  xs: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

DrawerGridItemTextField.defaultProps = {
  type: 'text',
  xs: 6,
  label: '',
  disabled: false,
};

export default DrawerGridItemTextField;
