import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import withRouter from 'react-router-dom/es/withRouter';
import {
  Button,
  Link,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import SendIcon from '@material-ui/icons/Send';
import makeStyles from '@material-ui/styles/makeStyles';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import TableStatus from '../common/TableStatus';
import { doCommitPromise } from '../../common/commit';
import executeCron from '../../mutations/Cron';
// import CronExecutionForm from './CronExecutionForm';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';
import MoreButton from '../common/buttons/MoreButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
// import CronOutputForm from './CronOutputForm';
import mvMutationErrors from '../../common/errorReport';
import { AddButton } from '../common/buttons/BaseButton';
import EventAddForm from './EventAddForm';
import EventEditForm from './EventEditForm';
import TopGridContainer from '../common/containers/TopGridContainer';
import BottomGridContainer from '../common/containers/BottomGridContainer';
import { fromGlobalId } from '../../helpers/ID';

function EventsList(props) {
  const { viewer } = props;
  const classes = useCommonStyles();
  // const localStyles = useStyles();
  const [showEditEvent, setShowEditEvent] = React.useState(false);
  const [showAddEvent, setShowAddEvent] = React.useState(false);

  return (
    <div className={classes.pageRoot}>
      <BottomGridContainer extraStyles={{ marginBottom: '10px' }}>
        <Grid item>
          <AddButton
            onClick={() => setShowAddEvent(true)}
            buttonText="Create"
          />
        </Grid>
      </BottomGridContainer>
      <Drawer
        anchor="right"
        variant=""
        open={showAddEvent}
        onClose={() => setShowAddEvent(null)}
      >
        <EventAddForm close={() => setShowAddEvent(false)} onAddUser={() => {}} />
      </Drawer>


      <Grid container>
        <DataTableWidget
          xs={12}
          // dataNodes={viewer.crons}
          dataNodes={[{
            id: 'Q2xpZW50QWNjb3VudE5vZGU6OTM2Mg==',
            contract: '1234',
            event: 'This is an event',
            announcementDate: 'March 4, 1997',
            description: '12312351 1212612',
          }]}
          columnMeta={[
            TableFieldMeta.fieldCell('contract', 'Contract',
              node => (node.contract || <TableStatus>Not Enabled</TableStatus>),
              { align: 'center' }),
            TableFieldMeta.fieldCell('event', 'Event', node => (node.value.event)),
            TableFieldMeta.fieldCell('announcementDate', 'Announcement date', node => (node.value.announcementDate)),
            TableFieldMeta.fieldCell('description', 'Description / Additional text', node => (node.value.description)),
            TableFieldMeta.fieldCell('more', '', node => (
              <MoreButton href={`/event/${fromGlobalId(node.value.id)[1]}`} />
            )),
          ]}
        />
      </Grid>
    </div>
  );
}

/**
 * {
      id: 'more',
      accessor: node => node,
      Header: '',
      Cell: row => (
        <MoreButton href={`/account/${fromGlobalId(row.value.id)[1]}`} />),
    },
 */
// const query = graphql`
//   query CronListQuery {
//     viewer {
//       ...CronList_viewer
//     }
//   }
// `;

// const container = createFragmentContainer(
//   EventsList,
//   {
//     viewer: graphql`
//       fragment CronList_viewer on Query {
//         adminMeta {
//           systemName
//         }
//         crons {
//           expression
//           functionUrl
//           awsLogUrl
//           awsEventUrl
//           paramDefs {
//             defaultValue
//             id
//             type
//             description
//           }
//         }
//       }
//     `,
//   },
// );

// export default withRouter(
//   createQueryRendererProgress(
//     query,
//     container,
//   ),
// );

export default EventsList;
