/**
 * @flow
 * @relayHash 295fe2263893ceafd76c56cc79fea9a9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeCalculationType = "MAX" | "MIN" | "SUM" | "%future added value";
export type UpsertFeeComponentMutationVariables = {|
  feeId: string,
  feeComponentId?: ?string,
  tenantId: string,
  minChargePoint?: ?any,
  maxChargePoint?: ?any,
  flatChargeAmount?: ?any,
  percentChargeAmount?: ?any,
  calculationType: FeeCalculationType,
  minPriceChargePoint?: ?any,
  maxPriceChargePoint?: ?any,
|};
export type UpsertFeeComponentMutationResponse = {|
  +upsertFeeComponent: ?{|
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +feeComponent: ?{|
      +id: string,
      +createdAt: any,
      +updatedAt: any,
      +calculationType: FeeCalculationType,
      +flatChargeAmount: ?number,
      +minChargePoint: ?number,
      +maxChargePoint: ?number,
      +minPriceChargePoint: ?number,
      +maxPriceChargePoint: ?number,
      +percentChargeAmount: ?number,
      +tenant: {|
        +id: string,
        +name: string,
      |},
      +fee: {|
        +id: string,
        +feeComponents: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type UpsertFeeComponentMutation = {|
  variables: UpsertFeeComponentMutationVariables,
  response: UpsertFeeComponentMutationResponse,
|};
*/


/*
mutation UpsertFeeComponentMutation(
  $feeId: ID!
  $feeComponentId: ID
  $tenantId: ID!
  $minChargePoint: Decimal
  $maxChargePoint: Decimal
  $flatChargeAmount: Decimal
  $percentChargeAmount: Decimal
  $calculationType: FeeCalculationType!
  $minPriceChargePoint: Decimal
  $maxPriceChargePoint: Decimal
) {
  upsertFeeComponent(feeComponentId: $feeComponentId, minChargePoint: $minChargePoint, maxChargePoint: $maxChargePoint, flatChargeAmount: $flatChargeAmount, percentChargeAmount: $percentChargeAmount, tenantId: $tenantId, calculationType: $calculationType, feeId: $feeId, minPriceChargePoint: $minPriceChargePoint, maxPriceChargePoint: $maxPriceChargePoint) {
    errors {
      field
      messages
    }
    feeComponent {
      id
      createdAt
      updatedAt
      calculationType
      flatChargeAmount
      minChargePoint
      maxChargePoint
      minPriceChargePoint
      maxPriceChargePoint
      percentChargeAmount
      tenant {
        id
        name
      }
      fee {
        id
        feeComponents {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "feeId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "feeComponentId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "minChargePoint",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "maxChargePoint",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "flatChargeAmount",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "percentChargeAmount",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "calculationType",
    "type": "FeeCalculationType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "minPriceChargePoint",
    "type": "Decimal",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "maxPriceChargePoint",
    "type": "Decimal",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "upsertFeeComponent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "calculationType",
        "variableName": "calculationType"
      },
      {
        "kind": "Variable",
        "name": "feeComponentId",
        "variableName": "feeComponentId"
      },
      {
        "kind": "Variable",
        "name": "feeId",
        "variableName": "feeId"
      },
      {
        "kind": "Variable",
        "name": "flatChargeAmount",
        "variableName": "flatChargeAmount"
      },
      {
        "kind": "Variable",
        "name": "maxChargePoint",
        "variableName": "maxChargePoint"
      },
      {
        "kind": "Variable",
        "name": "maxPriceChargePoint",
        "variableName": "maxPriceChargePoint"
      },
      {
        "kind": "Variable",
        "name": "minChargePoint",
        "variableName": "minChargePoint"
      },
      {
        "kind": "Variable",
        "name": "minPriceChargePoint",
        "variableName": "minPriceChargePoint"
      },
      {
        "kind": "Variable",
        "name": "percentChargeAmount",
        "variableName": "percentChargeAmount"
      },
      {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
      }
    ],
    "concreteType": "UpsertFeeComponent",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "feeComponent",
        "storageKey": null,
        "args": null,
        "concreteType": "FeeComponentNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "calculationType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "flatChargeAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "minChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "minPriceChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxPriceChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "percentChargeAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenant",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fee",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feeComponents",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeComponentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeComponentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeComponentNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpsertFeeComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "UpsertFeeComponentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpsertFeeComponentMutation",
    "id": null,
    "text": "mutation UpsertFeeComponentMutation(\n  $feeId: ID!\n  $feeComponentId: ID\n  $tenantId: ID!\n  $minChargePoint: Decimal\n  $maxChargePoint: Decimal\n  $flatChargeAmount: Decimal\n  $percentChargeAmount: Decimal\n  $calculationType: FeeCalculationType!\n  $minPriceChargePoint: Decimal\n  $maxPriceChargePoint: Decimal\n) {\n  upsertFeeComponent(feeComponentId: $feeComponentId, minChargePoint: $minChargePoint, maxChargePoint: $maxChargePoint, flatChargeAmount: $flatChargeAmount, percentChargeAmount: $percentChargeAmount, tenantId: $tenantId, calculationType: $calculationType, feeId: $feeId, minPriceChargePoint: $minPriceChargePoint, maxPriceChargePoint: $maxPriceChargePoint) {\n    errors {\n      field\n      messages\n    }\n    feeComponent {\n      id\n      createdAt\n      updatedAt\n      calculationType\n      flatChargeAmount\n      minChargePoint\n      maxChargePoint\n      minPriceChargePoint\n      maxPriceChargePoint\n      percentChargeAmount\n      tenant {\n        id\n        name\n      }\n      fee {\n        id\n        feeComponents {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4af2e6666fb5b07e3cfbb022a6dc832';
module.exports = node;
