import { graphql } from 'react-relay';

const updateVueResearchMutation = graphql`
  mutation UpdateVueResearchMutation (
    $vueResearchId: ID!
    $title: String
    $researchPdf: Upload
  ) {
    updateVueResearch (
      vueResearchId: $vueResearchId
      title: $title
      researchPdf: $researchPdf
    ) {
      vueResearch {
        id
        title
        researchPdfUrl
        updatedAt
      }
    }
  }
`;

export default updateVueResearchMutation;
