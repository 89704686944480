import { graphql } from 'react-relay';

export const createVueExclusivePermission = graphql`
  mutation VueExclusivePermissionCreateMutation (
    $accountId: ID
    $vueId: ID
  ) {
    createVueExclusivePermission(
      accountId: $accountId
      vueId: $vueId
    ) {
      vueExclusivePermission {
        account {
          id
        }
        vue {
          id
        }
      }
    }
  }
`;

export const deleteVueExclusivePermission = graphql`
  mutation VueExclusivePermissionDeleteMutation (
    $vueExclusivePermissionId: ID
  ) {
    deleteVueExclusivePermission(
      vueExclusivePermissionId: $vueExclusivePermissionId
    ) {
      account {
        id
      }
    }
  }
`;
