import React from 'react';
import { Grid } from '@material-ui/core';
import TableFieldMeta from '../../common/TableFieldMeta';
import UserLink from '../common/links/UserLink';
import SubscriptionStatus from './SubscriptionStatus';
import TableStatus from '../common/TableStatus';

const userSubscriptionColumns = showUserColumn => [
  ...(showUserColumn ? [
    TableFieldMeta.field(node => <UserLink user={node.user} />, 'User'),
  ] : []),
  TableFieldMeta.field(node => node.subscriptionTemplate.code, 'Code'),
  TableFieldMeta.fieldCell(
    'status', '',
    row => (
      <Grid container spacing={1} justify="center">
        <Grid item>
          <SubscriptionStatus userSubscription={row.value} />
        </Grid>
        {row.value.pendingCancel
          && (
            <Grid item>
              <TableStatus secondary> Pending Cancel </TableStatus>
            </Grid>
          )
        }
      </Grid>
    ),
  ),
  TableFieldMeta.dateField('billPeriodStart', 'Bill Start', node => node.actionTime),
  TableFieldMeta.dateField('billPeriodEnd', 'Bill End', node => node.actionTime),
  TableFieldMeta.account(
    node => node.paymentAccount,
    'Payment Account',
    {
      showNumber: true,
      charLimit: 40,
    },
  ),
  TableFieldMeta.moreButtonLink('user-subscription', node => node.id),
];

export default userSubscriptionColumns;
