/**
 * @flow
 * @relayHash 0d554e5504d6c4734a0f19bd09356ccf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeCalculationType = "MAX" | "MIN" | "SUM" | "%future added value";
export type FeeComponentMutationInput = {|
  id?: ?string,
  minChargePoint?: ?any,
  maxChargePoint?: ?any,
  flatChargeAmount?: ?any,
  percentChargeAmount?: ?any,
  tenant?: ?string,
  calculationType?: ?FeeCalculationType,
  fee?: ?string,
  minPriceChargePoint?: ?any,
  maxPriceChargePoint?: ?any,
  clientMutationId?: ?string,
|};
export type FeeComponentMutationVariables = {|
  input: FeeComponentMutationInput
|};
export type FeeComponentMutationResponse = {|
  +feeComponent: ?{|
    +clientMutationId: ?string,
    +errors: ?$ReadOnlyArray<?{|
      +field: string,
      +messages: $ReadOnlyArray<string>,
    |}>,
    +obj: ?{|
      +id: string,
      +createdAt: any,
      +updatedAt: any,
      +calculationType: FeeCalculationType,
      +flatChargeAmount: ?number,
      +minChargePoint: ?number,
      +maxChargePoint: ?number,
      +minPriceChargePoint: ?number,
      +maxPriceChargePoint: ?number,
      +percentChargeAmount: ?number,
      +tenant: {|
        +id: string,
        +name: string,
      |},
      +fee: {|
        +id: string,
        +feeComponents: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type FeeComponentMutation = {|
  variables: FeeComponentMutationVariables,
  response: FeeComponentMutationResponse,
|};
*/


/*
mutation FeeComponentMutation(
  $input: FeeComponentMutationInput!
) {
  feeComponent(input: $input) {
    clientMutationId
    errors {
      field
      messages
    }
    obj {
      id
      createdAt
      updatedAt
      calculationType
      flatChargeAmount
      minChargePoint
      maxChargePoint
      minPriceChargePoint
      maxPriceChargePoint
      percentChargeAmount
      tenant {
        id
        name
      }
      fee {
        id
        feeComponents {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FeeComponentMutationInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "feeComponent",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FeeComponentMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "ErrorType",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "field",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "messages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "obj",
        "storageKey": null,
        "args": null,
        "concreteType": "FeeComponentNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "calculationType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "flatChargeAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "minChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "minPriceChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxPriceChargePoint",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "percentChargeAmount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tenant",
            "storageKey": null,
            "args": null,
            "concreteType": "TenantNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fee",
            "storageKey": null,
            "args": null,
            "concreteType": "FeeNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feeComponents",
                "storageKey": null,
                "args": null,
                "concreteType": "FeeComponentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeeComponentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeeComponentNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "FeeComponentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "FeeComponentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "FeeComponentMutation",
    "id": null,
    "text": "mutation FeeComponentMutation(\n  $input: FeeComponentMutationInput!\n) {\n  feeComponent(input: $input) {\n    clientMutationId\n    errors {\n      field\n      messages\n    }\n    obj {\n      id\n      createdAt\n      updatedAt\n      calculationType\n      flatChargeAmount\n      minChargePoint\n      maxChargePoint\n      minPriceChargePoint\n      maxPriceChargePoint\n      percentChargeAmount\n      tenant {\n        id\n        name\n      }\n      fee {\n        id\n        feeComponents {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40c960bbca2c333f68e7821659c52e22';
module.exports = node;
