/**
 * @flow
 * @relayHash ae33f9e5fc997bdb5dcb22b858840d46
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApprovableStatus = "APPROVED" | "CANCELLED" | "PENDING" | "%future added value";
export type MFADeactivationRequestListFormQueryVariables = {|
  mfaDeactivationRequestId: string
|};
export type MFADeactivationRequestListFormQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +mfaDeactivationRequests: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +status: ApprovableStatus,
          +forUser: {|
            +id: string,
            +email: string,
          |},
          +approvals: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +approvedBy: {|
                  +id: string,
                  +username: string,
                |},
                +approvedAt: any,
              |}
            |}>
          |},
          +hasCompleteApprovals: ?boolean,
        |}
      |}>
    |},
  |}
|};
export type MFADeactivationRequestListFormQuery = {|
  variables: MFADeactivationRequestListFormQueryVariables,
  response: MFADeactivationRequestListFormQueryResponse,
|};
*/


/*
query MFADeactivationRequestListFormQuery(
  $mfaDeactivationRequestId: ID!
) {
  viewer {
    id
    mfaDeactivationRequests(id: $mfaDeactivationRequestId) {
      edges {
        node {
          id
          status
          forUser {
            id
            email
          }
          approvals {
            edges {
              node {
                id
                approvedBy {
                  id
                  username
                }
                approvedAt
              }
            }
          }
          hasCompleteApprovals
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "mfaDeactivationRequestId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "viewer",
    "storageKey": null,
    "args": null,
    "concreteType": "Query",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "mfaDeactivationRequests",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "mfaDeactivationRequestId"
          }
        ],
        "concreteType": "MFADeactivationRequestNodeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MFADeactivationRequestNodeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "MFADeactivationRequestNode",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "status",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "forUser",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "approvals",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ApprovalNode",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "approvedBy",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "username",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "approvedAt",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasCompleteApprovals",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "MFADeactivationRequestListFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "MFADeactivationRequestListFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "MFADeactivationRequestListFormQuery",
    "id": null,
    "text": "query MFADeactivationRequestListFormQuery(\n  $mfaDeactivationRequestId: ID!\n) {\n  viewer {\n    id\n    mfaDeactivationRequests(id: $mfaDeactivationRequestId) {\n      edges {\n        node {\n          id\n          status\n          forUser {\n            id\n            email\n          }\n          approvals {\n            edges {\n              node {\n                id\n                approvedBy {\n                  id\n                  username\n                }\n                approvedAt\n              }\n            }\n          }\n          hasCompleteApprovals\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '781a45e763717ea6133850de4edd28ca';
module.exports = node;
