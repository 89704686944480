/**
 * @flow
 * @relayHash 834691040f0a2f4839f753b00ed2006e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AccountStockHoldingPortfolios_portfolios$ref = any;
export type PortfolioCreateMutationVariables = {|
  accountId: string,
  vueId: string,
|};
export type PortfolioCreateMutationResponse = {|
  +createPortfolio: ?{|
    +portfolio: ?{|
      +id: string,
      +account: {|
        +$fragmentRefs: AccountStockHoldingPortfolios_portfolios$ref
      |},
    |}
  |}
|};
export type PortfolioCreateMutation = {|
  variables: PortfolioCreateMutationVariables,
  response: PortfolioCreateMutationResponse,
|};
*/


/*
mutation PortfolioCreateMutation(
  $accountId: ID!
  $vueId: ID!
) {
  createPortfolio(accountId: $accountId, vueId: $vueId) {
    portfolio {
      id
      account {
        ...AccountStockHoldingPortfolios_portfolios
        id
      }
    }
  }
}

fragment AccountStockHoldingPortfolios_portfolios on AccountNode {
  portfolios {
    edges {
      node {
        id
        trackingVue {
          id
          name
        }
      }
    }
  }
  defaultPortfolio
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "vueId",
    "variableName": "vueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PortfolioCreateMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPortfolio",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePortfolio",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "portfolio",
            "storageKey": null,
            "args": null,
            "concreteType": "PortfolioNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "AccountStockHoldingPortfolios_portfolios",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PortfolioCreateMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createPortfolio",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePortfolio",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "portfolio",
            "storageKey": null,
            "args": null,
            "concreteType": "PortfolioNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "portfolios",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PortfolioNodeConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PortfolioNodeEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PortfolioNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "trackingVue",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "VueNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "defaultPortfolio",
                    "args": null,
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "PortfolioCreateMutation",
    "id": null,
    "text": "mutation PortfolioCreateMutation(\n  $accountId: ID!\n  $vueId: ID!\n) {\n  createPortfolio(accountId: $accountId, vueId: $vueId) {\n    portfolio {\n      id\n      account {\n        ...AccountStockHoldingPortfolios_portfolios\n        id\n      }\n    }\n  }\n}\n\nfragment AccountStockHoldingPortfolios_portfolios on AccountNode {\n  portfolios {\n    edges {\n      node {\n        id\n        trackingVue {\n          id\n          name\n        }\n      }\n    }\n  }\n  defaultPortfolio\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '41b5fdada9c00533abaa0ee536b1e604';
module.exports = node;
