/**
 * @flow
 * @relayHash fecdfc8087f82fc23bb15bb6ab55662a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateNoteEntryMutationVariables = {|
  ownerId: string,
  text: string,
  attachments?: ?$ReadOnlyArray<?any>,
|};
export type CreateNoteEntryMutationResponse = {|
  +createNoteEntry: ?{|
    +notes: ?{|
      +id: string,
      +noteentrySet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +updatedAt: any,
            +createdAt: any,
            +createdBy: ?{|
              +id: string,
              +fullName: ?string,
            |},
            +text: string,
            +noteentryattachmentSet: {|
              +edges: $ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +file: string,
                  +fileUrl: ?string,
                  +name: string,
                |}
              |}>
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateNoteEntryMutation = {|
  variables: CreateNoteEntryMutationVariables,
  response: CreateNoteEntryMutationResponse,
|};
*/


/*
mutation CreateNoteEntryMutation(
  $ownerId: ID!
  $text: String!
  $attachments: [Upload]
) {
  createNoteEntry(ownerId: $ownerId, text: $text, attachments: $attachments) {
    notes {
      id
      noteentrySet {
        edges {
          node {
            id
            updatedAt
            createdAt
            createdBy {
              id
              fullName
            }
            text
            noteentryattachmentSet {
              edges {
                node {
                  id
                  file
                  fileUrl
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "ownerId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "text",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "attachments",
    "type": "[Upload]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createNoteEntry",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attachments",
        "variableName": "attachments"
      },
      {
        "kind": "Variable",
        "name": "ownerId",
        "variableName": "ownerId"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      }
    ],
    "concreteType": "CreateNoteEntry",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "notes",
        "storageKey": null,
        "args": null,
        "concreteType": "NotesNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "noteentrySet",
            "storageKey": null,
            "args": null,
            "concreteType": "NoteEntryNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "NoteEntryNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "NoteEntryNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "createdBy",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserNode",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fullName",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "text",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "noteentryattachmentSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NoteEntryAttachmentNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "NoteEntryAttachmentNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "NoteEntryAttachmentNode",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "file",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fileUrl",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateNoteEntryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateNoteEntryMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateNoteEntryMutation",
    "id": null,
    "text": "mutation CreateNoteEntryMutation(\n  $ownerId: ID!\n  $text: String!\n  $attachments: [Upload]\n) {\n  createNoteEntry(ownerId: $ownerId, text: $text, attachments: $attachments) {\n    notes {\n      id\n      noteentrySet {\n        edges {\n          node {\n            id\n            updatedAt\n            createdAt\n            createdBy {\n              id\n              fullName\n            }\n            text\n            noteentryattachmentSet {\n              edges {\n                node {\n                  id\n                  file\n                  fileUrl\n                  name\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ec066d87455c7da7d814b25443c692d';
module.exports = node;
