import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useCommonStyles } from '../Styles';
import { MOMENT_DATE_FORMAT_GRAPHQL } from '../../../common/constants';

function InnerTabFilterGridItemDateField(props) {
  const classes = useCommonStyles();
  const {
    id,
    value,
    onChange,
    label,
  } = props;
  return (
    <Grid item>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/*Report Data*/}
        <KeyboardDatePicker
          className={classes.formControlDate}
          disableToolbar
          variant="inline"
          format="yyyy-MM-dd"
          margin="normal"
          id={id}
          label={label}
          value={value}
          disableFuture
          onChange={(reportDateTime) => {
            if (reportDateTime === null) {
              if (onChange) onChange(null);
              return;
            }
            const newReportDate = moment(reportDateTime);
            if (newReportDate.isValid()) {
              if (onChange) onChange(newReportDate.format(MOMENT_DATE_FORMAT_GRAPHQL));
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}

InnerTabFilterGridItemDateField.propTypes = {
  ...TextField.propTypes,
  compact: PropTypes.bool,
};

InnerTabFilterGridItemDateField.defaultProps = {
  ...TextField.defaultProps,
  compact: false,
};

export default InnerTabFilterGridItemDateField;
