import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';


function OrderProposalList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.field(node => (node.createdBy && node.createdBy.fullName), 'Created By'),
    TableFieldMeta.enum('OrderProposalType', 'Type', node => node.type),
    TableFieldMeta.field('stocks', 'Stocks',
      {
        accessor: node => node.stocks.map(x => x.symbol).join(' , '),
      }),
    TableFieldMeta.moreButtonLink('order-proposal', node => node.id),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>

        <InnerTabFilterSelectField
          enumType="OrderProposalType"
          helperText="Proposal Type"
          withEmpty={false}
          value={filter.params.type}
          onChange={(e) => {
            filter.updateVar('type', e.target.value);
          }}
        />

        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.orderProposals : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query OrderProposalListQuery (
    $first: Int
    $offset: Int
    $type: String
  ) {
    viewer {
      ...OrderProposalList_viewer
      @arguments (
        first: $first
        offset: $offset
        type: $type
      )
    }
  }
`;

const container = createRefetchContainer(
  OrderProposalList,
  {
    viewer: graphql`
      fragment OrderProposalList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        type: {type: String}
      ) {
        orderProposals(
          first: $first
          offset: $offset
          type: $type
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              id
              type
              createdAt
              createdBy {
                fullName
              }
              stocks {
                symbol
              }
              action
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  {},
));
