/**
 * @flow
 * @relayHash 3c333beb004b9a0ed6aabcf8a93a78e3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type AddVueContractMutationVariables = {|
  vueId: string,
  contractId: string,
  weight: any,
  description: string,
|};
export type AddVueContractMutationResponse = {|
  +addVueContract: ?{|
    +vue: ?{|
      +id: string,
      +vuecontractSet: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +weight: number,
            +description: ?string,
            +contract: {|
              +id: string,
              +symbol: string,
              +currency: Currency,
              +name: ?string,
              +exchange: {|
                +symbol: string
              |},
            |},
          |}
        |}>
      |},
    |}
  |}
|};
export type AddVueContractMutation = {|
  variables: AddVueContractMutationVariables,
  response: AddVueContractMutationResponse,
|};
*/


/*
mutation AddVueContractMutation(
  $vueId: ID!
  $contractId: ID!
  $weight: Decimal!
  $description: String!
) {
  addVueContract(vueId: $vueId, contractId: $contractId, weight: $weight, description: $description) {
    vue {
      id
      vuecontractSet {
        edges {
          node {
            id
            weight
            description
            contract {
              id
              symbol
              currency
              name
              exchange {
                symbol
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "vueId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "contractId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "weight",
    "type": "Decimal!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractId",
    "variableName": "contractId"
  },
  {
    "kind": "Variable",
    "name": "description",
    "variableName": "description"
  },
  {
    "kind": "Variable",
    "name": "vueId",
    "variableName": "vueId"
  },
  {
    "kind": "Variable",
    "name": "weight",
    "variableName": "weight"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "weight",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AddVueContractMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addVueContract",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVueContractAdmin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vue",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vuecontractSet",
                "storageKey": null,
                "args": null,
                "concreteType": "VueContractNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueContractNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exchange",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ExchangeNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AddVueContractMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "addVueContract",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVueContractAdmin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "vue",
            "storageKey": null,
            "args": null,
            "concreteType": "VueNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "vuecontractSet",
                "storageKey": null,
                "args": null,
                "concreteType": "VueContractNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "VueContractNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "VueContractNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "contract",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ContractNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "exchange",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ExchangeNode",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v2/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "AddVueContractMutation",
    "id": null,
    "text": "mutation AddVueContractMutation(\n  $vueId: ID!\n  $contractId: ID!\n  $weight: Decimal!\n  $description: String!\n) {\n  addVueContract(vueId: $vueId, contractId: $contractId, weight: $weight, description: $description) {\n    vue {\n      id\n      vuecontractSet {\n        edges {\n          node {\n            id\n            weight\n            description\n            contract {\n              id\n              symbol\n              currency\n              name\n              exchange {\n                symbol\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd604be985fca3180adfa9d04a2a8ce43';
module.exports = node;
