import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DataTableWidget from '../common/DataTableWidget';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TableFieldMeta from '../../common/TableFieldMeta';
import Price from '../common/Price';

const ifPrice = key => node => (node[key] ? (
  <Price currency={node[key].currency}>
    {node[key].quantity}
  </Price>) : null);

function PairTable(props) {
  const {
    availableEdges,
    actualEdges,
    heading,
  } = props;

  const allDeltaCurrencySet = new Set([
    ...(actualEdges.map(x => x.node.currency)),
    ...(availableEdges.map(x => x.node.currency)),
  ]);

  const availableMap = Object.fromEntries(
    availableEdges.map(x => [x.node.currency, x.node]),
  );
  const actualMap = Object.fromEntries(
    actualEdges.map(x => [x.node.currency, x.node]),
  );
  const allCurrencyList = [...allDeltaCurrencySet].sort();
  const allCurrencyData = allCurrencyList.map(x => ({
    actual: actualMap[x],
    available: availableMap[x],
  }));


  return (
    <DataTableWidget
      title={heading}
      xs={12}
      columnMeta={[
        TableFieldMeta.field('actual', 'Actual', {
          accessor: ifPrice('actual'),
          align: 'right',
        }),
        TableFieldMeta.field('available', 'Available', {
          accessor: ifPrice('available'),
          align: 'right',
        }),
      ]}
      dataNodes={allCurrencyData}
    />
  );
}

function CashActivityDetails(props) {
  const { viewer } = props;
  const cashSummary = viewer.cashSummaries.edges[0].node;
  return (
    <DrawerGridItemContainer>
      <DrawerGridItemHeading
        heading="Cash Summary Details"
      />
      <PairTable
        heading="Balance"
        actualEdges={cashSummary.cashBalances.edges}
        availableEdges={cashSummary.availableCashBalances.edges}
      />
      <PairTable
        heading="Delta"
        actualEdges={cashSummary.cashDeltas.edges}
        availableEdges={cashSummary.availableCashDeltas.edges}
      />
    </DrawerGridItemContainer>

  );
}

const query = graphql`
  query CashActivityDetailsQuery(
    $cashSummaryId: ID
  )  {
    viewer {
      ...CashActivityDetails_viewer
      @arguments(
        cashSummaryId: $cashSummaryId
      )
    }
  }
`;

const container = createFragmentContainer(
  CashActivityDetails,
  {
    viewer: graphql`
      fragment CashActivityDetails_viewer on Query
      @argumentDefinitions(
        cashSummaryId: {type: ID}
      )
      {
        cashSummaries(
          id: $cashSummaryId
        ) {
          edges {
            node {
              id
              summaryDatetime
              reportDate
              description
              
              cashDeltas {
                edges {
                  node {
                    quantity
                    currency
                    createdAt
                    updatedAt
                  }
                }
              }
              
              availableCashDeltas {
                edges {
                  node {
                    quantity
                    currency
                    createdAt
                    updatedAt
                  }
                }
              }
              
              cashBalances {
                edges {
                  node {
                    id
                    currency
                    quantity
                  }
                }
              }
              
              availableCashBalances {
                edges {
                  node {
                    id
                    currency
                    quantity
                  }
                }
              }
              
              
              endingCashBase
            }
          }
          count
        }
      }
    `,
  },
);


export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ cashSummaryId }) => ({ cashSummaryId }),
);
