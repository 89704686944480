/**
 * @flow
 * @relayHash 741de9f14b8803cb738d1d914e754f47
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PositionTransferOutFormQueryVariables = {|
  accountId?: ?string
|};
export type PositionTransferOutFormQueryResponse = {|
  +viewer: ?{|
    +accounts: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +availableHoldings: ?$ReadOnlyArray<?{|
            +contract: {|
              +id: string,
              +name: ?string,
              +symbol: string,
            |},
            +quantity: number,
            +portfolio: {|
              +id: string,
              +trackingVueName: ?string,
            |},
          |}>
        |}
      |}>
    |}
  |}
|};
export type PositionTransferOutFormQuery = {|
  variables: PositionTransferOutFormQueryVariables,
  response: PositionTransferOutFormQueryResponse,
|};
*/


/*
query PositionTransferOutFormQuery(
  $accountId: ID
) {
  viewer {
    accounts(id: $accountId) {
      edges {
        node {
          availableHoldings {
            contract {
              id
              name
              symbol
            }
            quantity
            portfolio {
              id
              trackingVueName
            }
            id
          }
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "accountId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "contract",
  "storageKey": null,
  "args": null,
  "concreteType": "ContractNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "symbol",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "portfolio",
  "storageKey": null,
  "args": null,
  "concreteType": "PortfolioNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "trackingVueName",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PositionTransferOutFormQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "availableHoldings",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AvailableHoldingBalanceNode",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PositionTransferOutFormQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "accounts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AccountNode",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "availableHoldings",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AvailableHoldingBalanceNode",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PositionTransferOutFormQuery",
    "id": null,
    "text": "query PositionTransferOutFormQuery(\n  $accountId: ID\n) {\n  viewer {\n    accounts(id: $accountId) {\n      edges {\n        node {\n          availableHoldings {\n            contract {\n              id\n              name\n              symbol\n            }\n            quantity\n            portfolio {\n              id\n              trackingVueName\n            }\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '34b3798622e6251f04073af12d473708';
module.exports = node;
