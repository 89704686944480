import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import { fromGlobalId } from '../../helpers/ID';
import Price from '../common/Price';
import BrokerOrderStatus from '../common/BrokerOrderStatus';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import ContractLink from '../common/links/ContractLink';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import TablePaginator from '../common/TablePaginator';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import StockOrderInvoiceActions from './StockOrderInvoiceActions';
import StockOrderQuantityStatus from './stockOrder/StockOrderQuantityStatus';


const blockedCashDesc = stockOrder => (
  <span>
    {
      stockOrder.blockedBaseCharges && (
        <Price
          currency="AUD"
          tooltipPrefix="Base Charges: "
        >
          {stockOrder.blockedBaseCharges}
        </Price>
      )
    }
    &nbsp;&bull;&nbsp;
    {
      stockOrder.blockedLocalCharges && (
        <Price
          currency={stockOrder.contract.currency}
          tooltipPrefix="Local Charges: "
        >
          {stockOrder.blockedLocalCharges}
        </Price>
      )
    }
    &nbsp;&bull;&nbsp;
    {
      stockOrder.blockedProceeds && (
        <Price
          currency={stockOrder.contract.currency}
          tooltipPrefix="Proceeds: "
        >
          {stockOrder.blockedProceeds}
        </Price>
      )
    }
  </span>
);

const blockedHoldingsDesc = (stockOrder) => {
  if (stockOrder.blockedHoldings !== 0) {
    return stockOrder.blockedHoldings;
  }
  return '';
};

function StockOrderList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;

  filter.setRelay(relay);
  const [statusIn, setStatusIn] = React.useState(filter.params.statusIn);
  const [orderType, setOrderType] = React.useState(filter.params.orderType);
  const [algo, setAlgo] = React.useState(filter.params.algo);
  const [aot, setAOT] = React.useState(filter.params.aot);

  const columns = [
    TableFieldMeta.linkField(node => node.id, 'stock-order', 'ID'),
    TableFieldMeta.createdAt,
    TableFieldMeta.field(
      node => `${node.contract.exchange.symbol} • ${node.contract.symbol}`,
      'Ex.Symbol',
    ),
    TableFieldMeta.field(node => <ContractLink contract={node.contract} />, 'Contract', { align: 'right' }),
    TableFieldMeta.field(node => node.contract.currency, 'Currency'),
    TableFieldMeta.field(node => node.orderType, 'Type'),
    TableFieldMeta.fieldCell('Quantity', 'Quantity',
      row => <StockOrderQuantityStatus stockOrder={row.value} />,
      { align: 'right' }),
    TableFieldMeta.field(node => fromGlobalId(node.brokerOrder.id)[1], 'Bo.ID', { align: 'right' }),
    TableFieldMeta.field(node => (
      <BrokerOrderStatus stockOrder={node} />
    ), 'Status', { align: 'center' }),
    TableFieldMeta.field(node => blockedCashDesc(node), 'Blc. Cash'),
    TableFieldMeta.field(node => blockedHoldingsDesc(node), 'Blc. Holdings'),
    TableFieldMeta.fieldCell('Invoice', '', row => <StockOrderInvoiceActions stockOrder={row.value} />),
    TableFieldMeta.field(node => (
      <Link href={`/order/${fromGlobalId(node.portfolioOrder.id)[1]}`}>
        {fromGlobalId(node.portfolioOrder.id)[1]}
      </Link>
    ), 'Vue Order'),
    TableFieldMeta.account(node => node.account, 'Account', { charLimit: 40 }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Custom Search"
          helperText="symbol, description"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
        />

        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Exchange"
          helperText="exchange"
          defaultValue={filter.params.exchange}
          onChange={e => filter.updateVar('exchange', e.target.value)}
          halfWidth
        />

        <InnerTabFilterSelectField
          enumType="OrderStatus"
          helperText="Status-In"
          multiple
          withEmpty={false}
          value={statusIn}
          onChange={(e) => {
            setStatusIn(e.target.value);
            filter.updateVar('statusIn', e.target.value);
          }}
        />

        <InnerTabFilterSelectField
          enumType="OrderType"
          helperText="Order Type"
          value={orderType}
          onChange={(e) => {
            setOrderType(e.target.value);
            filter.updateVar('orderType', e.target.value);
          }}
        />

        <InnerTabFilterSelectField
          enumType="OrderAlgo"
          helperText="Algorighm"
          value={algo}
          onChange={(e) => {
            setAlgo(e.target.value);
            filter.updateVar('algo', e.target.value);
          }}
        />

        <InnerTabFilterSelectField
          enumType="AdvancedOrderType"
          helperText="Bracket/Simple/.."
          value={aot}
          onChange={(e) => {
            setAOT(e.target.value);
            filter.updateVar('aot', e.target.value);
          }}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.stockOrders : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query StockOrderListQuery (
    $first: Int
    $offset: Int
    $search: String
    $side: String
    $statusIn: [String]
    $exchange: String
    $accountId: ID
    $contractId: ID
    $orderType: String
    $algo: String
    $aot:String
  ) {
    viewer {
      ...StockOrderList_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        side: $side
        statusIn: $statusIn
        exchange: $exchange
        accountId: $accountId
        contractId: $contractId
        orderType: $orderType
        algo: $algo
        aot: $aot
      )
    }
  }
`;

const container = createRefetchContainer(
  StockOrderList,
  {
    viewer: graphql`
      fragment StockOrderList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        side: {type: String}
        statusIn: {type: "[String]"}
        exchange: {type: String}
        accountId: {type: ID}
        contractId: {type: ID}
        orderType: {type: String}
        algo: {type: String}
        aot: {type: String}
      ) {
        stockOrders(
          first: $first
          offset: $offset
          side: $side
          search: $search
          statusIn: $statusIn
          exchange: $exchange
          accountId: $accountId
          contractId: $contractId
          orderType: $orderType
          algo: $algo
          advancedOrderType: $aot
          orderBy: "-createdAt"
        ) {
          edges {
            node {
              
              orderPtr {
                orderapprovalcontractSet {
                  edges {
                    node {
                      id
                      orderStatus
                    }
                  }
                }
              }
              
              id
              orderType
              side
              updatedAt
              createdAt
              quantityToDate
              matched
              quantity
              cashQuantity
              blockedBaseCharges
              blockedHoldings
              blockedLocalCharges
              blockedProceeds
              advancedOrderType
              portfolioOrder {
                id
                portfolio {
                  trackingVue {
                    name
                  }
                }
              }
              contract {
                symbol
                currency
                id
                exchange {
                  symbol
                }
              }
              
              account {
                id
                name
                clientaccount {
                  id
                  accountType
                }
              }
              status
              matched
              brokerOrder {
                id
                matched
                quantity
                quantityFilled
                quantityLeft
                side
              }
              
              ...StockOrderInvoiceActions_invoice @relay(mask: false)
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({
    accountId,
    contractId,
  }) => ({
    statusIn: [],
    accountId,
    contractId,
  }),
  {
    debounceFields: ['search', 'exchange'],
  },
));
