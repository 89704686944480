import React from 'react';
import { Decimal } from 'decimal.js';
import { commitMutation, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FileDropZone } from 'mui-dropzone';
import Papa from 'papaparse';
import { fromGlobalId, toGlobalId } from '../../../helpers/ID';
import { createQueryRendererProgress } from '../../common/QueryRendererProgress';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import TableIconButton from '../../common/table/TableIconButton';
import { UpdateButton } from '../../common/buttons/BaseButton';
import DrawerSubGroupHeading from '../../common/drawer/DrawerSubGroupHeading';
import BrokerActivity from '../../../common/BrokerActivity';
import notiStack from '../../../common/notiStack';
import { getColumnIndices } from '../../../common/csv';
import environment from '../../../Environment';
import updateBrokerActivityPerformance from '../../../mutations/UpdateBrokerActivityPerformance';
import autoProcessBrokerActivityPerformance from '../../../mutations/AutoProcessBrokerActivityPerformance';
import BottomGridContainer from '../../common/containers/BottomGridContainer';
import { CSV_MIME_TYPES } from '../../../common/constants';
import StatelessDownloadButton from '../../common/buttons/StatelessDownloadButton';
import { doCommitPromise } from '../../../common/commit';

function CostBasisActions(props) {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <TableIconButton
          iconClass={AddCircleIcon}
        />
      </Grid>
      <Grid item>
        ...
      </Grid>
    </Grid>
  );
}

function BrokerActivityPerformanceForm(props) {
  const { brokerActivityGroup, viewer, onClose } = props;
  const baseExportUrl = `${process.env.REACT_APP_API}/api/admin/exports`;
  const groupPerformanceUrl = `${baseExportUrl}/group-performance?group_id=${fromGlobalId(brokerActivityGroup.id)[1]}`;
  const brokerActivitySet = viewer.brokerActivityGroups.edges[0].node.brokeractivitySet.edges;
  const {
    includedBatCount,
    totalBatCount,
    entries: loadedPerfEntries,
  } = BrokerActivity.unpackPerfEntries(brokerActivitySet);
  const [perfEntries, setPerfEntries] = React.useState(loadedPerfEntries);
  const [isForUpload, setIsForUpload] = React.useState(false);
  const { possiblePerformanceTypes } = viewer.brokerActivityGroups.edges[0].node;
  const performanceTypeMapping = viewer.performanceTypeEnum.enumValues.reduce(
    (mapping, performanceType) => (
      { ...mapping, [performanceType.name]: performanceType.description }
    ), {},
  );

  const proceedSum = brokerActivitySet.reduce(
    (v, c) => v.plus(new Decimal(c.node.ibcorporateaction.proceeds)),
    new Decimal(0),
  );
  const proceedSumPerf = perfEntries.reduce(
    (v, c) => {
      if (c === null) {
        return v;
      }
      const cashMove = (
        c.costBasis
          ? new Decimal(c.costBasis)
          : new Decimal(c.price).mul(new Decimal(c.quantity)).neg()
      );
      return v.plus(cashMove);
    },
    new Decimal(0),
  );

  const proceedMatch = proceedSum.equals(proceedSumPerf);

  const importEntries = (files) => {
    files.map(file => Papa.parse(file, {
      complete: (results) => {
        if (results.errors.length > 0) {
          results.errors.map(error => notiStack.error(error));
          return;
        }
        const basToUpdate = new Set();
        const importPerfEntries = [];

        const [
          baIndex,
          typeIndex,
          batIndex,
          pfIndex,
          cnIndex,
          acIndex,
          curIndex,
          qtyIndex,
          priceIndex,
          costBasisIndex,
          sequenceIndex,
          sfIndex,
          splitResultIndex,
          commissionIndex,
          oldContractIndex,
          newContractIndex,
          spinoffContractIndex,
          dummiedIndex,
        ] = getColumnIndices(results, [
          [['Broker Activity ID'], true],
          [['Entry Type'], true],
          [['Broker Activity Transaction ID'], true],
          [['Portfolio ID'], true],
          [['Contract ID'], true],
          [['Account ID'], true],
          [['Currency'], true],
          [['Contract Quantity'], true],
          [['Price'], true],
          [['Cost Basis'], true],
          [['Sequence'], true],
          [['Split Factor'], true],
          [['Split Result'], true],
          [['Commission'], true],
          [['Old Contract ID'], true],
          [['New Contract ID'], true],
          [['Spinoff Contract ID'], true],
          [['Dummied'], true],
        ]);


        const dataOnlyEntries = results.data.slice(1);
        dataOnlyEntries.forEach((dataRow) => {
          if (dataRow[typeIndex] !== 'PERF') {
            return;
          }
          let costBasis = null;
          if (dataRow[costBasisIndex].trim() !== '' && dataRow[costBasisIndex].trim() !== '0') {
            costBasis = dataRow[costBasisIndex];
          }
          let splitFactor = null;
          if (dataRow[sfIndex].trim() !== '' && dataRow[sfIndex].trim() !== '0') {
            splitFactor = dataRow[sfIndex];
          }
          let splitResult = null;
          if (dataRow[splitResultIndex].trim() !== '' && dataRow[splitResultIndex].trim() !== '0') {
            splitResult = dataRow[splitResultIndex];
          }
          let oldContractId = null;
          if (dataRow[oldContractIndex].trim() !== '') {
            oldContractId = toGlobalId('ContractNode', dataRow[oldContractIndex]);
          }
          let newContractId = null;
          if (dataRow[newContractIndex].trim() !== '') {
            newContractId = toGlobalId('ContractNode', dataRow[newContractIndex]);
          }
          let spinoffContractId = null;
          if (dataRow[spinoffContractIndex].trim() !== '') {
            spinoffContractId = toGlobalId('ContractNode', dataRow[spinoffContractIndex]);
          }
          let dummied = false;
          if (dataRow[dummiedIndex].toLowerCase().trim() === 'true') {
            dummied = true;
          }

          basToUpdate.add(toGlobalId('BrokerActivityNode', dataRow[baIndex]));
          importPerfEntries.push({
            portfolioId: toGlobalId('PortfolioNode', dataRow[pfIndex]),
            contractId: toGlobalId('ContractNode', dataRow[cnIndex]),
            accountId: toGlobalId('AccountNode', dataRow[acIndex]),
            brokerActivityTransactionId: toGlobalId('BrokerActivityTransactionNode', dataRow[batIndex]),
            brokerActivityId: toGlobalId('BrokerActivityNode', dataRow[baIndex]),
            currency: dataRow[curIndex],
            sequence: dataRow[sequenceIndex] ? Number(dataRow[sequenceIndex]) : 0,
            quantity: dataRow[qtyIndex] ? dataRow[qtyIndex] : 0,
            price: dataRow[priceIndex] ? dataRow[priceIndex] : 0,
            commission: dataRow[commissionIndex] ? dataRow[commissionIndex] : 0,
            costBasis,
            splitFactor,
            splitResult,
            oldContractId,
            newContractId,
            spinoffContractId,
            dummied,
          });
        });

        setPerfEntries(importPerfEntries);
        setIsForUpload(true);
      },
    }));
  };

  const commitEntries = () => {
    commitMutation(environment, {
      mutation: updateBrokerActivityPerformance,
      variables: {
        entries: perfEntries.map((perfEntry) => {
          const {
            brokerActivityId,
            accountId,
            ...rest
          } = perfEntry;
          return rest;
        }),
        brokerActivityGroupId: brokerActivityGroup.id,
      },
      onCompleted: (response, errors) => {
        if (!errors) {
          if (onClose) onClose();
        }
      },
    });
  };

  const getPerformanceEntriesTitle = () => {
    if (isForUpload) return 'Performance Entries [For Upload]';
    if (includedBatCount === totalBatCount) {
      return 'Performance Entries';
    }
    return `Performance Entries [Showing for ${includedBatCount} out of ${totalBatCount} BAT's]`;
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading={`Corporate Action Performance: ${brokerActivityGroup.description}`}
        />

        <Grid item xs={12}>
          <DrawerSubGroupHeading>Broker Transactions</DrawerSubGroupHeading>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Symbol</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Proceeds</TableCell>
                <TableCell>Costbasis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                brokerActivitySet.map((brokerActivityEdge) => {
                  const { node } = brokerActivityEdge;
                  return (
                    <TableRow>
                      <TableCell>{fromGlobalId(node.id)[1]}</TableCell>
                      <TableCell>{node.ibcorporateaction.symbol}</TableCell>
                      <TableCell align="right">{node.ibcorporateaction.quantity}</TableCell>
                      <TableCell align="right">{node.ibcorporateaction.proceeds}</TableCell>
                      <TableCell><CostBasisActions brokerActivity={node} /></TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2} align="right">Sum: </TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">{proceedSum.toString()}</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">Sum(Perf):</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell
                  align="right"
                  style={{ color: proceedMatch ? 'green' : 'red' }}
                >
                  {proceedSumPerf.toString()}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justify="flex-end">
            { possiblePerformanceTypes.map(performanceType => (
              <Grid item>
                <UpdateButton
                  buttonText={`Perform ${performanceTypeMapping[performanceType]}`}
                  onClickPromise={() => doCommitPromise(
                    autoProcessBrokerActivityPerformance,
                    {
                      brokerActivityGroupId: brokerActivityGroup.id,
                      performanceType,
                    },
                    (response, errors) => {
                      if (!errors) {
                        if (onClose) onClose();
                      }
                    },
                    () => null,
                  )}
                />
              </Grid>
            ))}

            <Grid item>
              <StatelessDownloadButton
                downloadUrl={groupPerformanceUrl}
                buttonText="Export Entries"
                buttonType="export"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Import file */}
        <Grid item xs={12}>
          <DrawerSubGroupHeading>
            Import file
          </DrawerSubGroupHeading>
          <FileDropZone
            acceptedMimeTypes={CSV_MIME_TYPES}
            onFilesAdded={files => importEntries(files)}
            onFilesRejected={() => null}
            elevation={2}
            dragOverElevation={10}
            className="file-drop-zone"
          >
            My Text
          </FileDropZone>
        </Grid>

        <Grid item xs={12}>
          <DrawerSubGroupHeading>
            {getPerformanceEntriesTitle()}
          </DrawerSubGroupHeading>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>BA ID</TableCell>
                {/*<TableCell>BAT ID</TableCell>*/}
                <TableCell>Account</TableCell>
                <TableCell>Portfolio</TableCell>
                {/*<TableCell>Contract</TableCell>*/}
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Cost Basis</TableCell>
                <TableCell align="right">Sequence</TableCell>
                <TableCell align="right">Split Fc.</TableCell>
                <TableCell align="right">Split Result</TableCell>
                <TableCell>Commission</TableCell>
                <TableCell>Old Contract</TableCell>
                <TableCell>New Contract</TableCell>
                <TableCell>Spinoff Contract</TableCell>
                <TableCell>Dummied</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                perfEntries.map(perf => (
                  <TableRow>
                    <TableCell>{fromGlobalId(perf.brokerActivityId)[1]}</TableCell>
                    {/*<TableCell>{fromGlobalId(perf.brokerActivityTransactionId)[1]}</TableCell>*/}
                    <TableCell>{fromGlobalId(perf.accountId)[1]}</TableCell>
                    <TableCell>{fromGlobalId(perf.portfolioId)[1]}</TableCell>
                    {/*<TableCell>{contractId}</TableCell>*/}
                    <TableCell align="right">{perf.quantity}</TableCell>
                    <TableCell align="right">{perf.price}</TableCell>
                    <TableCell align="right">{perf.costBasis}</TableCell>
                    <TableCell align="right">{perf.sequence}</TableCell>
                    <TableCell>{perf.splitFactor}</TableCell>
                    <TableCell>{perf.splitResult}</TableCell>
                    <TableCell>{perf.commission}</TableCell>
                    <TableCell>{fromGlobalId(perf.oldContractId)[1]}</TableCell>
                    <TableCell>{fromGlobalId(perf.newContractId)[1]}</TableCell>
                    <TableCell>{fromGlobalId(perf.spinoffContractId)[1]}</TableCell>
                    <TableCell>{perf.dummied ? 'Yes' : ''}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <BottomGridContainer>
            <Grid item>
              <UpdateButton
                disabled={!isForUpload}
                onClick={() => commitEntries()}
              />
            </Grid>
          </BottomGridContainer>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}

const query = graphql`
  query BrokerActivityPerformanceFormQuery (
    $brokerActivityGroupId: ID!
  ){
    viewer {
      performanceTypeEnum: __type(name: "PerformanceType") {
        enumValues {
          description
          name
        }
      }
      brokerActivityGroups (
        id: $brokerActivityGroupId
      ){
        edges {
          node {
            possiblePerformanceTypes
            brokeractivitySet {
              edges {
                node {
                  id
                  ibcorporateaction {
                    amount
                    symbol
                    proceeds
                    quantity
                    currency
                  }
                  brokeractivitytransactionSet {
                    count
                    edges {
                      node {
                        id
                        
                        account {
                          id 
                          name
                        }
                        
                        brokeractivitytransactionperformanceSet {
                          edges {
                            node {
                              ...Performance_batPerformance @relay(mask: false)
                            }
                          }
                        }
                        
                        brokeractivitytransactioncashdeltaSet {
                          edges {
                            node {
                              id
                              quantity
                              currency
                              portfolio {
                                id
                              }
                            }
                          }
                        }
                        
                        brokeractivitytransactioncontractdeltaSet {
                          edges {
                            node {
                              id
                              quantity
                              portfolio {
                                id
                                trackingVueName
                              }
                              contract {
                                id
                                symbol
                                currency
                              }
                            }
                          }
                        }
                        
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default createQueryRendererProgress(
  query,
  BrokerActivityPerformanceForm,
  (viewer, outerProps) => ({
    brokerActivityGroup: outerProps.brokerActivityGroup,
    onClose: outerProps.onClose,
  }),
  props => ({
    brokerActivityGroupId: props.brokerActivityGroup.id,
  }),
);
