/**
 * @flow
 * @relayHash 937457c0f1ff5f8bcd204097114294e2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type GenerateDailyReconciliationMutationVariables = {|
  statementOfFundsId: string
|};
export type GenerateDailyReconciliationMutationResponse = {|
  +generateDailyReconciliation: ?{|
    +statementOfFunds: ?{|
      +id: string,
      +fromDate: any,
      +toDate: any,
      +fileUrl: ?string,
      +file: string,
      +ibdailyreconciliation: ?{|
        +id: string,
        +createdAt: any,
        +excelFile: ?string,
        +excelFileUrl: ?string,
        +updatedAt: any,
        +ibdailyreconciliationendbalanceSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +currency: Currency,
              +quantity: number,
            |}
          |}>
        |},
        +ibdailyreconciliationdeltaSet: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +currency: Currency,
              +quantity: number,
            |}
          |}>
        |},
      |},
    |}
  |}
|};
export type GenerateDailyReconciliationMutation = {|
  variables: GenerateDailyReconciliationMutationVariables,
  response: GenerateDailyReconciliationMutationResponse,
|};
*/


/*
mutation GenerateDailyReconciliationMutation(
  $statementOfFundsId: ID!
) {
  generateDailyReconciliation(statementOfFundsId: $statementOfFundsId) {
    statementOfFunds {
      id
      fromDate
      toDate
      fileUrl
      file
      ibdailyreconciliation {
        id
        createdAt
        excelFile
        excelFileUrl
        updatedAt
        ibdailyreconciliationendbalanceSet {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
        ibdailyreconciliationdeltaSet {
          edges {
            node {
              id
              currency
              quantity
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "statementOfFundsId",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "currency",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "quantity",
    "args": null,
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "generateDailyReconciliation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "statementOfFundsId",
        "variableName": "statementOfFundsId"
      }
    ],
    "concreteType": "GenerateDailyReconciliation",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "statementOfFunds",
        "storageKey": null,
        "args": null,
        "concreteType": "IBStatementOfFundsNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fromDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "toDate",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "fileUrl",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "file",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "ibdailyreconciliation",
            "storageKey": null,
            "args": null,
            "concreteType": "IBDailyReconciliationNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "excelFile",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "excelFileUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ibdailyreconciliationendbalanceSet",
                "storageKey": null,
                "args": null,
                "concreteType": "IBDailyReconciliationEndBalanceNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBDailyReconciliationEndBalanceNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBDailyReconciliationEndBalanceNode",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "ibdailyreconciliationdeltaSet",
                "storageKey": null,
                "args": null,
                "concreteType": "IBDailyReconciliationDeltaNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "IBDailyReconciliationDeltaNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "IBDailyReconciliationDeltaNode",
                        "plural": false,
                        "selections": (v2/*: any*/)
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "GenerateDailyReconciliationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "GenerateDailyReconciliationMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v3/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "GenerateDailyReconciliationMutation",
    "id": null,
    "text": "mutation GenerateDailyReconciliationMutation(\n  $statementOfFundsId: ID!\n) {\n  generateDailyReconciliation(statementOfFundsId: $statementOfFundsId) {\n    statementOfFunds {\n      id\n      fromDate\n      toDate\n      fileUrl\n      file\n      ibdailyreconciliation {\n        id\n        createdAt\n        excelFile\n        excelFileUrl\n        updatedAt\n        ibdailyreconciliationendbalanceSet {\n          edges {\n            node {\n              id\n              currency\n              quantity\n            }\n          }\n        }\n        ibdailyreconciliationdeltaSet {\n          edges {\n            node {\n              id\n              currency\n              quantity\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c78c341b91d0e93bfd1066ae2eef9bff';
module.exports = node;
