/**
 * @flow
 * @relayHash 5e255b28baf182b1149c79cdcd385e78
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSubscriptionInvoices_viewer$ref = any;
export type UserSubscriptionInvoicesSetQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  userSubscriptionId?: ?string,
|};
export type UserSubscriptionInvoicesSetQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserSubscriptionInvoices_viewer$ref
  |}
|};
export type UserSubscriptionInvoicesSetQuery = {|
  variables: UserSubscriptionInvoicesSetQueryVariables,
  response: UserSubscriptionInvoicesSetQueryResponse,
|};
*/


/*
query UserSubscriptionInvoicesSetQuery(
  $first: Int
  $offset: Int
  $userSubscriptionId: ID
) {
  viewer {
    ...UserSubscriptionInvoices_viewer_5UfCD
    id
  }
}

fragment UserSubscriptionInvoices_viewer_5UfCD on Query {
  userSubscriptions(id: $userSubscriptionId) {
    count
    edges {
      node {
        id
        subscriptionPtr {
          subscriptioninvoiceSet(first: $first, offset: $offset, orderBy: "-billStart") {
            edges {
              node {
                id
                subscription {
                  id
                }
                chargeAmount
                billEnd
                billStart
                billTo {
                  id
                  name
                }
              }
            }
            count
          }
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userSubscriptionId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSubscriptionInvoicesSetQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSubscriptionInvoices_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "userSubscriptionId",
                "variableName": "userSubscriptionId"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSubscriptionInvoicesSetQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "userSubscriptions",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userSubscriptionId"
              }
            ],
            "concreteType": "UserSubscriptionNodeConnection",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserSubscriptionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserSubscriptionNode",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "subscriptionPtr",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SubscriptionNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "subscriptioninvoiceSet",
                            "storageKey": null,
                            "args": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "kind": "Literal",
                                "name": "orderBy",
                                "value": "-billStart"
                              }
                            ],
                            "concreteType": "SubscriptionInvoiceNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "SubscriptionInvoiceNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "SubscriptionInvoiceNode",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "subscription",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "SubscriptionNode",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/)
                                        ]
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "chargeAmount",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "billEnd",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "billStart",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "billTo",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "AccountNode",
                                        "plural": false,
                                        "selections": [
                                          (v4/*: any*/),
                                          {
                                            "kind": "ScalarField",
                                            "alias": null,
                                            "name": "name",
                                            "args": null,
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v3/*: any*/)
                            ]
                          },
                          (v4/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSubscriptionInvoicesSetQuery",
    "id": null,
    "text": "query UserSubscriptionInvoicesSetQuery(\n  $first: Int\n  $offset: Int\n  $userSubscriptionId: ID\n) {\n  viewer {\n    ...UserSubscriptionInvoices_viewer_5UfCD\n    id\n  }\n}\n\nfragment UserSubscriptionInvoices_viewer_5UfCD on Query {\n  userSubscriptions(id: $userSubscriptionId) {\n    count\n    edges {\n      node {\n        id\n        subscriptionPtr {\n          subscriptioninvoiceSet(first: $first, offset: $offset, orderBy: \"-billStart\") {\n            edges {\n              node {\n                id\n                subscription {\n                  id\n                }\n                chargeAmount\n                billEnd\n                billStart\n                billTo {\n                  id\n                  name\n                }\n              }\n            }\n            count\n          }\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31b7fc5ddb82fbd0b7f3bfa2b2daf8e2';
module.exports = node;
