import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useCommonStyles } from './Styles';
import { extractKeys } from '../../common/helpers';
import { muiGridSizes } from '../../common/constants';

export default function TitledWidgetItem(props) {
  const {
    title,
    children,
    rightTopComp,
    showTitle,
    ...otherProps
  } = props;
  const [sizeProps] = extractKeys(otherProps, muiGridSizes);
  const classes = useCommonStyles();
  const TitleComp = title && (typeof title !== 'string') && title;

  return (
    <Grid
      item
      {...sizeProps}
    >
      <Paper className={classes.tableHolderRoot}>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0px' }}>
            { rightTopComp }
          </div>
        </div>

        <div className={classes.widgetHeadingContainer}>
          {showTitle && (typeof title === 'string') && (
            <Typography
              component="h6"
              variant="subtitle2"
              gutterBottom
            >
              {title}
            </Typography>
          )}

          {TitleComp && <TitleComp />}
          {children}
        </div>
      </Paper>
    </Grid>
  );
}

TitledWidgetItem.propTypes = {
  title: PropTypes.string,
  rightTopComp: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showTitle: PropTypes.bool,
};

TitledWidgetItem.defaultProps = {
  title: null,
  rightTopComp: null,
  showTitle: true,
};
