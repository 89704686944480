import { MVA_AUTH_TOKEN_TAG } from './common/constants';
import notiStack from './common/notiStack';
import refreshToken from './TokenRefresh';

const {
  Environment,
  Network,
  RecordSource,
  Store,
} = require('relay-runtime');

const store = new Store(new RecordSource());

function onFetchFulfilled(response) {
  return response.json().then((jsonPayload) => {
    if ('errors' in jsonPayload && jsonPayload.errors.length > 0) {
      if (jsonPayload.errors[0].message === 'Signature has expired') {
        localStorage.removeItem(MVA_AUTH_TOKEN_TAG);
        window.location.href = '/login';
        return jsonPayload;
      }

      jsonPayload.errors.forEach((error) => {
        notiStack.error(error.message);
      });

      return jsonPayload;
    }
    refreshToken();
    return jsonPayload;
  }, (error) => {
    notiStack.error(error.message);
  });
}

function onFetchRejected(error) {
  notiStack.error(`Error: ${error.message}`);
}

const network = Network.create((operation, variables, cacheConfig, uploadables) => {
  const jwtToken = localStorage.getItem(MVA_AUTH_TOKEN_TAG);

  const queryString = JSON.stringify({
    query: operation.text,
    variables,
  });

  let callParams = null;
  const authHeaders = jwtToken ? { Authorization: `JWT ${jwtToken}` } : {};

  if (uploadables) {
    const formData = new FormData();
    formData.append('operations', queryString);
    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });
    /*
     Do not override the 'headers' here.
     Because it's required by the library to set the 'boundary'
     Along with Content-Type. eg...
      Content-Type: multipart/form-data; boundary=--------WebkitXXXX
     */
    callParams = {
      method: 'POST',
      mode: 'cors',
      body: formData,
      headers: { ...authHeaders },
    };
  } else {
    callParams = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...authHeaders,
      },
      body: queryString,
    };
  }

  const apiUrl = `${process.env.REACT_APP_API}/admin-graphql/`;
  return fetch(apiUrl, callParams).then(onFetchFulfilled, onFetchRejected);
});


const environment = new Environment({
  network,
  store,
});


export default environment;
