import { graphql } from 'react-relay';

export const offMarketTradeMutation = graphql`
  mutation OffMarketTradeMutation(
    $accountId: ID
    $portfolioId: ID
    $contractId: ID
    $counterpartyAccountId: ID
    $counterpartyPortfolioId: ID
    $currency: String
    $price: Float
    $quantity: Float
  ) {
    offMarketTrade(
      accountId: $accountId
      portfolioId: $portfolioId
      contractId: $contractId
      counterpartyAccountId: $counterpartyAccountId
      counterpartyPortfolioId: $counterpartyPortfolioId
      currency: $currency
      price: $price
      quantity: $quantity
    ) {
      account {
        id
      }
      counterpartyAccount {
        id
      }
    }
  }
`;


export const offMarketHoldingTransferMutation = graphql`
  mutation OffMarketHoldingTransferMutation(
    $accountId: ID
    $portfolioId: ID
    $contractId: ID
    $counterpartyAccountId: ID
    $counterpartyPortfolioId: ID
    $quantity: Float
  ) {
    offMarketHoldingTransfer(
      accountId: $accountId
      portfolioId: $portfolioId
      contractId: $contractId
      counterpartyAccountId: $counterpartyAccountId
      counterpartyPortfolioId: $counterpartyPortfolioId
      quantity: $quantity
    ) {
      account {
        id
      }
      counterpartyAccount {
        id
      }
    }
  }
`;

export const setOffMarketHoldingCostBasis = graphql`
  mutation OffMarketHoldingCostBasisSetMutation(
    $omtHoldingQtyId: ID
    $costBasis: Float
    $commission: Float
    $dummy: Boolean
  ) {
    setOffMarketHoldingCostBasis(
      omtHoldingQtyId: $omtHoldingQtyId
      costBasis: $costBasis
      commission: $commission
      dummy: $dummy
    ) {
      omtHoldingQty {
        updatedAt
        id
        commission
        costBasis
        dummy
      }
    }
  }
`;


export const offMarketCashTransferMutation = graphql`
  mutation OffMarketCashTransferMutation(
    $accountId: ID,
    $counterPartyAccountId: ID,
    $currency: String,
    $amount: String
  ) {
    offMarketCashTransfer(
      accountId: $accountId,
      counterpartyAccountId: $counterPartyAccountId,
      currency: $currency,
      amount: $amount,
    ) {
      offMarketTransfer {
        account {
          actualCashBase
          availableCashBase
          stockValueBase
          updatedAt
          actualCashBalances{
            currency
            balance
          }
          availableCashBalances {
            currency
            balance
          }
        }
      }
    }
  }
`;
