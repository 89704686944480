import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { MVA_AUTH_TOKEN_TAG } from '../common/constants';

const jwtDecode = require('jwt-decode');

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => {
        const jwtToken = localStorage.getItem(MVA_AUTH_TOKEN_TAG);
        let loginRequired = true;
        if (jwtToken && jwtToken !== 'null') {
          const decoded = jwtDecode(jwtToken);
          if (decoded.exp > Date.now() / 1000) {
            loginRequired = false;
          }
        }
        if (!loginRequired) {
          return <Component onToggleTheme={rest.onToggleTheme} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: props.location,
            }}
          />
        );
      }
    }
  />
);

export default PrivateRoute;
