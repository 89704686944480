import * as React from 'react';
import {
  COLOR_MATCHED,
  COLOR_UNDEFINED,
  orderStatusColorMap,
  orderStatusForeColorMap,
} from '../../common/constants';
import TableStatus from './TableStatus';
import EnumValue from './EnumValue';

export default function BrokerOrderStatus(props) {
  const {
    stockOrder,
  } = props;
  let statusColor = orderStatusColorMap[stockOrder.status] || COLOR_UNDEFINED;
  const statusForeColor = orderStatusForeColorMap[stockOrder.status] || 'white';
  if (stockOrder.matched) {
    statusColor = COLOR_MATCHED;
  }
  return (
    <TableStatus customStyle={{ backgroundColor: statusColor, color: statusForeColor }}>
      <EnumValue
        enumType="OrderStatus"
        enumValue={stockOrder.status}
        captionOverrides={{
          'Pending Execution': 'pen. ex.',
          'Partially Filled': 'p-fill',
        }}
      />
    </TableStatus>
  );
}
