import * as React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PortfolioOrderDetailsContainer from './PortfolioOrderDetailsContainer';
import { useCommonStyles } from '../common/Styles';


const Transition = React.forwardRef(
  (props, ref) => (
    <Slide direction="up" ref={ref} {...props} />
  ),
);

export default function PortfolioOrderDlg(props) {
  const { portfolioOrder, onClose } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useCommonStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        if (onClose) onClose();
      }}
      TransitionComponent={Transition}
    >
      <AppBar className={classNames(classes.dialogAppBar, classes.appBarShift)}>
        <Toolbar style={{ flexDirection: 'row-reverse' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false);
              if (onClose) onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />

          </IconButton>
          {/*<Typography variant="h6">*/}
          {/*  {`Portfolio Order ${fromGlobalId(portfolioOrder.id)[1]}  `}*/}
          {/*</Typography>*/}
        </Toolbar>
      </AppBar>

      <div className={classes.innerTabRoot}>
        <PortfolioOrderDetailsContainer portfolioOrderId={portfolioOrder.id} />
      </div>
    </Dialog>
  );
}
