import React from 'react';
import { useCommonStyles } from '../common/Styles';
import { AddButton, UpdateButton } from '../common/buttons/BaseButton';
import feeMutation from '../../mutations/Fee';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemSwitch from '../common/drawer/DrawerGridItemSwitch';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { doCommitPromise } from '../../common/commit';
import mvMutationErrors from '../../common/errorReport';

const FEE_TYPE_PERIOD_RESTRICTIONS = {
  STB: ['ONE'],
  FXB: ['ONE'],
  VHF: ['MON', 'ANU'],
  RTP: ['MON', 'ANU'],
  RTPA: ['MON', 'ANU'],
};

export default function FeeForm(props) {
  const {
    fee,
    onClose,
    onAdd,
    feeTemplate,
  } = props;
  const classes = useCommonStyles();
  const isUpdate = !!fee;
  const [feeType, setFeeType] = React.useState(isUpdate ? fee.feeType : 'STB');
  const [paymentPeriod, setPaymentPeriod] = React.useState(
    isUpdate ? fee.paymentPeriod : 'ONE',
  );
  const [simpleTranche, setSimpleTranche] = React.useState(isUpdate ? fee.simpleTranche : true);
  const [enabled, setEnabled] = React.useState(isUpdate ? fee.enabled : true);
  const ButtonClass = isUpdate ? UpdateButton : AddButton;

  return (
    <div className={classes.drawerRoot}>

      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading={isUpdate ? 'Update Fee' : 'Add Fee'}
        />
        <DrawerGridItemSelectField
          enumType="FeeType"
          label="Fee Type"
          value={feeType}
          onChange={(e) => {
            const selectedFeeType = e.target.value;
            setFeeType(selectedFeeType);
            if (selectedFeeType in FEE_TYPE_PERIOD_RESTRICTIONS) {
              setPaymentPeriod(FEE_TYPE_PERIOD_RESTRICTIONS[selectedFeeType][0]);
            }
          }}
        />
        <DrawerGridItemSelectField
          enumType="PaymentPeriod"
          label="Payment Period"
          value={paymentPeriod}
          onChange={e => setPaymentPeriod(e.target.value)}
        />

        <DrawerGridItemSwitch
          label="Simple Tranche"
          checked={simpleTranche}
          onChange={e => setSimpleTranche(e.target.checked)}
        />

        <DrawerGridItemSwitch
          label="Enabled"
          checked={enabled}
          onChange={e => setEnabled(e.target.checked)}
        />

        <DrawerGridItemBottomButtonContainer>
          <ButtonClass
            disabled={enabled === null}
            onClickPromise={() => doCommitPromise(
              feeMutation,
              {
                input: {
                  id: isUpdate ? fee.id : null,
                  simpleTranche,
                  paymentPeriod,
                  feeType,
                  enabled,
                  template: feeTemplate.id,
                },
              },
              (response, error) => {
                if (!mvMutationErrors(response, 'fee')) {
                  onClose && onClose();
                  onAdd && onAdd();
                }
              },
            )}
          />
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}
