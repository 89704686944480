/**
 * @flow
 * @relayHash e8836cb62602297ecfb858774eb67188
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Resource = "ACC" | "ACTIVITY" | "ADVISER" | "APP" | "BANK_ACCT" | "INFO" | "PERF" | "PERM" | "PRICING" | "REPORTS" | "TENANT" | "TRANS" | "VUE" | "%future added value";
export type Verb = "MUTATE" | "READ" | "%future added value";
export type UserClientAccountPermissionInput = {|
  resource?: ?Resource,
  verb?: ?Verb,
|};
export type CreateUserRoleMutationVariables = {|
  name: string,
  defaultPermissions?: ?string,
  rolePermissions?: ?$ReadOnlyArray<?UserClientAccountPermissionInput>,
|};
export type CreateUserRoleMutationResponse = {|
  +createUserRole: ?{|
    +userRole: ?{|
      +id: string,
      +name: string,
      +rolePermissions: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +resource: Resource,
            +verb: Verb,
          |}
        |}>
      |},
    |}
  |}
|};
export type CreateUserRoleMutation = {|
  variables: CreateUserRoleMutationVariables,
  response: CreateUserRoleMutationResponse,
|};
*/


/*
mutation CreateUserRoleMutation(
  $name: String!
  $defaultPermissions: String
  $rolePermissions: [UserClientAccountPermissionInput]
) {
  createUserRole(name: $name, defaultPermissions: $defaultPermissions, rolePermissions: $rolePermissions) {
    userRole {
      id
      name
      rolePermissions {
        edges {
          node {
            id
            resource
            verb
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "name",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "defaultPermissions",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "rolePermissions",
    "type": "[UserClientAccountPermissionInput]",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createUserRole",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "defaultPermissions",
        "variableName": "defaultPermissions"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "rolePermissions",
        "variableName": "rolePermissions"
      }
    ],
    "concreteType": "CreateUserRole",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userRole",
        "storageKey": null,
        "args": null,
        "concreteType": "UserRoleNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "rolePermissions",
            "storageKey": null,
            "args": null,
            "concreteType": "UserRolePermissionNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserRolePermissionNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserRolePermissionNode",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "resource",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "verb",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateUserRoleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateUserRoleMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateUserRoleMutation",
    "id": null,
    "text": "mutation CreateUserRoleMutation(\n  $name: String!\n  $defaultPermissions: String\n  $rolePermissions: [UserClientAccountPermissionInput]\n) {\n  createUserRole(name: $name, defaultPermissions: $defaultPermissions, rolePermissions: $rolePermissions) {\n    userRole {\n      id\n      name\n      rolePermissions {\n        edges {\n          node {\n            id\n            resource\n            verb\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5640f5cc0f8c442f7ade4be84f2e4910';
module.exports = node;
