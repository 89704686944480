import { graphql } from 'react-relay';

// language=GraphQL
const tokeAuthMutation = graphql`
  mutation TokenAuthMutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      user {
          id
          firstName
          lastName
          email
          username
      }
    }
  }
`;

export default tokeAuthMutation;
