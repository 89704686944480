/**
 * @flow
 * @relayHash 0cd3f3fddf3f57f971dc936d8aabcd00
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CashTransferStatus = "APR" | "CAN" | "PEN" | "REC" | "VER" | "%future added value";
export type CancelCashTransferRequestMutationVariables = {|
  transferRequestId?: ?string
|};
export type CancelCashTransferRequestMutationResponse = {|
  +cancelCashTransfer: ?{|
    +cashTransferRequest: ?{|
      +id: string,
      +status: ?CashTransferStatus,
      +createdAt: any,
      +updatedAt: any,
    |}
  |}
|};
export type CancelCashTransferRequestMutation = {|
  variables: CancelCashTransferRequestMutationVariables,
  response: CancelCashTransferRequestMutationResponse,
|};
*/


/*
mutation CancelCashTransferRequestMutation(
  $transferRequestId: ID
) {
  cancelCashTransfer(cashTransferRequestId: $transferRequestId) {
    cashTransferRequest {
      id
      status
      createdAt
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "transferRequestId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "cancelCashTransfer",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "cashTransferRequestId",
        "variableName": "transferRequestId"
      }
    ],
    "concreteType": "CancelCashTransferRequestAdmin",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "cashTransferRequest",
        "storageKey": null,
        "args": null,
        "concreteType": "CashTransferRequestNode",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CancelCashTransferRequestMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CancelCashTransferRequestMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CancelCashTransferRequestMutation",
    "id": null,
    "text": "mutation CancelCashTransferRequestMutation(\n  $transferRequestId: ID\n) {\n  cancelCashTransfer(cashTransferRequestId: $transferRequestId) {\n    cashTransferRequest {\n      id\n      status\n      createdAt\n      updatedAt\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bfa16045abb9d05ceeafa8cc09c4159f';
module.exports = node;
