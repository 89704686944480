import React from 'react';
import PropTypes from 'prop-types';
import './ProgressTick.css';

export default function ProgressTick(props) {
  const {
    backColor,
    maxLevel,
    minLevel,
    layers,
  } = props;

  const height = 100;
  const width = 30;
  const halfWidth = width / 2;
  let path = '';
  path += `M 0 ${halfWidth} `;
  path += `A ${halfWidth} ${halfWidth} 0 0 1 ${width} ${halfWidth} `;
  path += `L ${width} ${height - halfWidth} `;
  path += `A ${halfWidth} ${halfWidth} 0 0 1 0 ${height - halfWidth} `;
  path += `M 0 ${halfWidth}`;

  return (
    <div className="progress-tick-body">
      <svg
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {
          layers.map(
            (layerDef, i) => {
              const clipHeight = (layerDef.progress - minLevel) * height / (maxLevel - minLevel);
              const layerClipId = `progressClip-${i}-${clipHeight}`;
              return (
                <clipPath id={layerClipId}>
                  <rect
                    x="0"
                    y={height - clipHeight}
                    width={width}
                    height={clipHeight}
                  />
                </clipPath>
              );
            },
          )
        }
        <path
          d={path}
          stroke="transparent"
          fill={backColor}
        />

        {
          layers.map(
            (layerDef, i) => {
              const clipHeight = (layerDef.progress - minLevel) * height / (maxLevel - minLevel);
              const layerClipId = `progressClip-${i}-${clipHeight}`;
              return (
                <path
                  d={path}
                  fill={layerDef.color}
                  clipPath={`url(#${layerClipId})`}
                />
              );
            },
          )
        }
      </svg>
    </div>
  );
}

ProgressTick.propTypes = {
  backColor: PropTypes.string,
  maxLevel: PropTypes.number,
  minLevel: PropTypes.number,
  layers: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    progress: PropTypes.number,
  })),
};

ProgressTick.defaultProps = {
  backColor: '#a2a2a2',
  maxLevel: 100,
  minLevel: 0,
  layers: {},
};
