import React from 'react';
import FileSaver from 'file-saver';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import AccountLink from '../common/links/AccountLink';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import { useCommonStyles } from '../common/Styles';
import { fromGlobalId } from '../../helpers/ID';
import { ExportButton } from '../common/buttons/BaseButton';
import BrokerActivity from '../../common/BrokerActivity';

function BrokerActivityDetails(props) {
  const { brokerActivity } = props;
  const classes = useCommonStyles();
  const data = brokerActivity.brokeractivityPtr.brokeractivitytransactionSet.edges.flatMap(
    bat => bat.node.brokeractivitytransactioncontractdeltaSet.edges.map(
      contractDelta => ({
        brokerActivityTransaction: bat.node,
        account: bat.node.account,
        portfolio: contractDelta.node.portfolio,
        contract: contractDelta.node.contract,
        quantity: contractDelta.node.quantity,
      }),
    ).concat(
      bat.node.brokeractivitytransactioncashdeltaSet.edges.map(
        cashDelta => ({
          brokerActivityTransaction: bat.node,
          account: bat.node.account,
          portfolio: cashDelta.node.portfolio,
          currency: cashDelta.node.currency,
          quantity: cashDelta.node.quantity,
        }),
      ),
    ),
  );

  data.sort((a, b) => {
    const aLocal = fromGlobalId(a.account.id)[1];
    const bLocal = fromGlobalId(b.account.id)[1];
    return aLocal - bLocal;
  });

  const baIdSplit = fromGlobalId(brokerActivity.id);
  const exportEntries = () => {
    const blob = new Blob(
      [BrokerActivity.toCSV(brokerActivity.brokeractivityPtr, false)],
      { type: 'text/plain;charset=utf-8' },
    );
    FileSaver.saveAs(blob, `${baIdSplit[0]}.${baIdSplit[1]}.csv`);
  };

  return (
    <div className={classes.drawerRootExtended}>
      <Grid container spacing={2}>
        <DrawerGridItemHeading
          heading={`Details for ${baIdSplit[0]} : ${baIdSplit[1]}`}
        />
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>BAT ID</TableCell>
                <TableCell>Acc. ID</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Portfolio</TableCell>
                {/*<TableCell>Contract</TableCell>*/}
                <TableCell>Currency</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.map(dataRow => (
                  <TableRow>
                    <TableCell>
                      {fromGlobalId(dataRow.brokerActivityTransaction.id)[1]}
                    </TableCell>
                    <TableCell>
                      {fromGlobalId(dataRow.account.id)[1]}
                    </TableCell>
                    <TableCell>
                      <AccountLink account={dataRow.account} charLimit={20} showAppLink />
                    </TableCell>
                    <TableCell>
                      {dataRow.portfolio ? fromGlobalId(dataRow.portfolio.id)[1] : '-'}
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  {dataRow.contract ? fromGlobalId(dataRow.contract.id)[1] : '-'}*/}
                    {/*</TableCell>*/}
                    <TableCell>
                      {dataRow.currency ? dataRow.currency : '-'}
                    </TableCell>
                    <TableCell>
                      {dataRow.quantity}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <ExportButton
                buttonText="Export Entries"
                onClick={() => exportEntries()}
              />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

export default BrokerActivityDetails;
//export default withStyles(styles)(BrokerActivityDetails);
