/**
 * @flow
 * @relayHash 5d286d1bfa4a9df1790007d16ae1c37b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CashTransferRequestList_viewer$ref = any;
export type CashTransferRequestListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  accountId?: ?string,
  statusIn?: ?$ReadOnlyArray<?string>,
  approved?: ?number,
  accountType?: ?string,
  custodian?: ?string,
|};
export type CashTransferRequestListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: CashTransferRequestList_viewer$ref
  |}
|};
export type CashTransferRequestListQuery = {|
  variables: CashTransferRequestListQueryVariables,
  response: CashTransferRequestListQueryResponse,
|};
*/


/*
query CashTransferRequestListQuery(
  $first: Int
  $offset: Int
  $search: String
  $accountId: ID
  $statusIn: [String]
  $approved: Float
  $accountType: String
  $custodian: String
) {
  viewer {
    ...CashTransferRequestList_viewer_40VCef
    id
  }
}

fragment CashTransferRequestList_viewer_40VCef on Query {
  cashTransferRequests(first: $first, offset: $offset, search: $search, orderBy: "-createdAt", account: $accountId, statusIn: $statusIn, approved: $approved, accountType: $accountType, custodian: $custodian) {
    edges {
      node {
        id
        batch {
          id
          name
          inverseBatch {
            id
            name
          }
        }
        currency
        amount
        createdAt
        updatedAt
        status
        blocked
        custodian
        bankAccount {
          accountNumber
          bsbNumber
          holderName
          id
        }
        account {
          id
          name
          clientaccount {
            referenceNumber
            id
          }
        }
        cashtransferrequestapprovalSet {
          edges {
            node {
              approvedAt
              approvedBy {
                fullName
                id
              }
              id
            }
          }
        }
        cashTransfer {
          id
          brokerActivity {
            brokeractivitytransactionSet {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
            id
          }
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "statusIn",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "approved",
    "type": "Float",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "accountType",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "custodian",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "accountType",
  "variableName": "accountType"
},
v2 = {
  "kind": "Variable",
  "name": "approved",
  "variableName": "approved"
},
v3 = {
  "kind": "Variable",
  "name": "custodian",
  "variableName": "custodian"
},
v4 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v5 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = {
  "kind": "Variable",
  "name": "statusIn",
  "variableName": "statusIn"
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CashTransferRequestListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CashTransferRequestList_viewer",
            "args": [
              {
                "kind": "Variable",
                "name": "accountId",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CashTransferRequestListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "cashTransferRequests",
            "storageKey": null,
            "args": [
              {
                "kind": "Variable",
                "name": "account",
                "variableName": "accountId"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-createdAt"
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "concreteType": "CashTransferRequestNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "CashTransferRequestNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CashTransferRequestNode",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "batch",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestBatchNode",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "inverseBatch",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashTransferRequestBatchNode",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "currency",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "amount",
                        "args": null,
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "updatedAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "blocked",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "custodian",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bankAccount",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountNode",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "accountNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "bsbNumber",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "holderName",
                            "args": null,
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "clientaccount",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ClientAccountNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "referenceNumber",
                                "args": null,
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashtransferrequestapprovalSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferRequestApprovalNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "CashTransferRequestApprovalNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "CashTransferRequestApprovalNode",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "approvedAt",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "approvedBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "fullName",
                                        "args": null,
                                        "storageKey": null
                                      },
                                      (v8/*: any*/)
                                    ]
                                  },
                                  (v8/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "cashTransfer",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "CashTransferNode",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "brokerActivity",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BrokerActivityNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "brokeractivitytransactionSet",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BrokerActivityTransactionNodeConnection",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "edges",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "BrokerActivityTransactionNodeEdge",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "alias": null,
                                        "name": "node",
                                        "storageKey": null,
                                        "args": null,
                                        "concreteType": "BrokerActivityTransactionNode",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/),
                                          (v10/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v8/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "CashTransferRequestListQuery",
    "id": null,
    "text": "query CashTransferRequestListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $accountId: ID\n  $statusIn: [String]\n  $approved: Float\n  $accountType: String\n  $custodian: String\n) {\n  viewer {\n    ...CashTransferRequestList_viewer_40VCef\n    id\n  }\n}\n\nfragment CashTransferRequestList_viewer_40VCef on Query {\n  cashTransferRequests(first: $first, offset: $offset, search: $search, orderBy: \"-createdAt\", account: $accountId, statusIn: $statusIn, approved: $approved, accountType: $accountType, custodian: $custodian) {\n    edges {\n      node {\n        id\n        batch {\n          id\n          name\n          inverseBatch {\n            id\n            name\n          }\n        }\n        currency\n        amount\n        createdAt\n        updatedAt\n        status\n        blocked\n        custodian\n        bankAccount {\n          accountNumber\n          bsbNumber\n          holderName\n          id\n        }\n        account {\n          id\n          name\n          clientaccount {\n            referenceNumber\n            id\n          }\n        }\n        cashtransferrequestapprovalSet {\n          edges {\n            node {\n              approvedAt\n              approvedBy {\n                fullName\n                id\n              }\n              id\n            }\n          }\n        }\n        cashTransfer {\n          id\n          brokerActivity {\n            brokeractivitytransactionSet {\n              edges {\n                node {\n                  id\n                  createdAt\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6cbdeee3ad6a768332989a9b43d0882a';
module.exports = node;
