import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import * as React from 'react';


export const renderMUITableRows = (rowData, columns, selection = {}, aggregateData = []) => {
  const {
    selectedRowKey,
    handleSelect,
    onRowSelectInfo,
  } = selection;

  return rowData.map(
    (dataRow) => {
      const inData = {
        value: dataRow,
      };

      let selectProps = {};
      if (onRowSelectInfo) {
        const selectInfo = onRowSelectInfo(inData);
        if (selectInfo.selectEnabled) {
          selectProps = {
            selected: selectedRowKey === selectInfo.rowKey,
            onClick: handleSelect(dataRow, selectInfo),
          };
        }
      }

      return (
        <TableRow key={dataRow.id} {...selectProps}>
          {
            columns.map(
              (columnMeta, i) => {
                let cellStyle = {};
                let align = 'inherit';
                if (columnMeta.style) {
                  ({ cellStyle } = columnMeta.style);
                  align = columnMeta.style.textAlign || 'inherit';
                }

                const {
                  accessor,
                  Cell,
                  id,
                  reducer,
                } = columnMeta;
                let content;
                if (typeof accessor === 'function') {
                  content = accessor(dataRow);
                } else {
                  content = dataRow[accessor];
                }

                if (reducer) {
                  // eslint-disable-next-line no-param-reassign
                  aggregateData[i] = reducer.accumulator(inData, aggregateData[i]);
                }

                const cellKey = `${columnMeta.id}-${id}`;
                if (Cell) {
                  return (
                    <TableCell key={cellKey} align={align} style={cellStyle}>
                      {Cell(inData)}
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={cellKey} align={align} style={cellStyle}>
                    {content}
                  </TableCell>
                );
              },
            )
          }
        </TableRow>
      );
    },
  );
};

export const renderMUITableHeaders = columns => (
  <TableRow key="headerTableRow">
    {
      columns.map(
        (columnMeta) => {
          let align = 'inherit';
          if (columnMeta.style) {
            align = columnMeta.style.textAlign || 'inherit';
          }
          return (
            <TableCell key={columnMeta.id} align={align}>
              {columnMeta.Header}
            </TableCell>
          );
        },
      )
    }
  </TableRow>
);


export const renderMUITableFooters = (columns, aggregateData = []) => (
  <TableRow>
    {
      columns.map(
        (columnMeta, i) => {
          let align = 'inherit';
          if (columnMeta.style) {
            align = columnMeta.style.textAlign || 'inherit';
          }
          const { reducer } = columnMeta;
          if (!reducer) {
            return <TableCell>&nbsp;</TableCell>;
          }
          return (
            <TableCell key={columnMeta.id} align={align}>
              {reducer.accessor([], aggregateData[i])}
            </TableCell>
          );
        },
      )
    }
  </TableRow>
);
