import { graphql } from 'react-relay';

const cancelPositionTransfer = graphql`
  mutation CancelPositionTransferMutation (
   $positionTransferRequestId: ID
  ) {
    cancelPositionTransfer (
      positionTransferRequestId: $positionTransferRequestId
    ) {
      positionTransferRequest {
        id
        status
      }
    }
  }
`;

export default cancelPositionTransfer;
