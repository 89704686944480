import { graphql } from 'react-relay';

const createManualAccountChargeMutation = graphql`
  mutation CreateManualAccountChargeMutation (
    $clientAccountId: ID!
    $description: String!
    $tenantCharges: [TenantChargeInputType]!
    $feeType: FeeType!
    $currency: String!
  ) {
    createManualAccountCharge(
      clientAccountId: $clientAccountId,
      description: $description,
      tenantCharges: $tenantCharges
      feeType: $feeType
      currency: $currency
    )
    {
      manualAccountCharge {
        id
        amount
        description
        createdAt
        updatedAt
        feeType
        account {
          id
          name
        }
      }
    }
  }
`;

export default createManualAccountChargeMutation;
