import { graphql } from 'react-relay';

const publishVueCommunicationMutation = graphql`
  mutation PublishVueCommunicationMutation(
    $communicationId: ID!
  ) {
    publishVueCommunication(
      communicationId: $communicationId
    ){
      vueCommunication {
        id
        published
        publishedAt
        createdAt
        updatedAt
        message
        heading
      }
    }
  }
`;
export default publishVueCommunicationMutation;
