import Grid from '@material-ui/core/Grid';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { extractKeys } from '../../../common/helpers';
import { muiGridSizes } from '../../../common/constants';


function DrawerGridItemCheckBox(props) {
  const {
    onChange,
    label,
    labelPlacement,
    value,
  } = props;

  const [sizeProps] = extractKeys(props, muiGridSizes);
  return (
    <Grid item {...sizeProps}>
      <FormControlLabel
        control={(
          <Checkbox
            checked={value}
            onChange={onChange}
            name={label}
            color="primary"
          />
        )}
        label={label}
        labelPlacement={labelPlacement}
      />
    </Grid>
  );
}

export default DrawerGridItemCheckBox;
