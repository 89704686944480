import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import PropTypes from 'prop-types';
import environment from '../../Environment';
import { customEnumDefs } from './CustomEnumDefs';


const enumStore = { ...customEnumDefs };
const enumStoreSubscribed = {};

const query = graphql`
  query EnumValueQuery (
    $enumType: String!
  ) {
    enumMetaInfo: __type(name: $enumType) {
      enumValues {
        description
        name
      }
    }
  }
`;

const rendererFunction = (enumType, render) => ({ error, props }) => {
  if (error) {
    return <React.Fragment />;
  }
  if (props) {
    const { enumMetaInfo } = props;
    if (enumMetaInfo) {
      const enumMeta = {};
      enumMetaInfo.enumValues.map((enumValueEntry, i) => {
        enumMeta[enumValueEntry.name] = {
          description: enumValueEntry.description,
          index: i,
        };
        return 0;
      });

      const subscriptionList = enumStoreSubscribed[enumType];
      if (subscriptionList) {
        subscriptionList.map(subscription => subscription(enumMeta));
        enumStore[enumType] = enumMeta;
      }

      return render(enumMeta);
    }
    return <React.Fragment />;
  }
  return <React.Fragment />;
};


export function EnumValues(props) {
  const {
    enumType,
    render,
  } = props;

  if (Array.isArray(enumType)) {
    const enumMeta = Object.fromEntries(enumType.map(t => [t.name, t.description]));
    return render(enumMeta, enumType);
  }

  const [enumMeta, setEnumMeta] = React.useState(enumStore[enumType] || null);
  const [nsVar] = React.useState({
    subscribed: false,
  });

  if (enumMeta) {
    return render(enumMeta);
  }

  if (nsVar.subscribed) {
    return <React.Fragment />;
  }

  nsVar.subscribed = true;
  const subscriberList = enumStoreSubscribed[enumType] || [];
  subscriberList.push((enumMetaIn) => {
    nsVar.subscribed = false;
    setEnumMeta(enumMetaIn);
  });
  enumStoreSubscribed[enumType] = subscriberList;

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={rendererFunction(enumType, render)}
      variables={{
        enumType,
      }}
    />
  );
}

EnumValues.propTypes = {
  enumType: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};


function EnumValue(props) {
  const {
    enumType,
    enumValue,
    captionOverrides,
  } = props;

  return (
    <EnumValues
      enumType={enumType}
      render={(enumMeta) => {
        if (enumMeta[enumValue]) {
          let { description } = enumMeta[enumValue];
          if (description in captionOverrides) {
            description = captionOverrides[description];
          }
          return <React.Fragment>{description}</React.Fragment>;
        }

        const findRes = Object.values(enumMeta).find(enumDef => enumValue === enumDef.enumValue);
        if (findRes !== undefined) {
          let { description } = findRes;
          if (description in captionOverrides) {
            description = captionOverrides[description];
          }
          return <React.Fragment>{description}</React.Fragment>;
        }

        return null;
      }}
    />
  );
}

EnumValue.propTypes = {
  enumType: PropTypes.string.isRequired,
  enumValue: PropTypes.string.isRequired,
  captionOverrides: PropTypes.object,
};

EnumValue.defaultProps = {
  captionOverrides: {},
};


export default EnumValue;
