/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExchangeSession_viewer$ref: FragmentReference;
declare export opaque type ExchangeSession_viewer$fragmentType: ExchangeSession_viewer$ref;
export type ExchangeSession_viewer = {|
  +exchangeSessions: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +exchangeSession: ?{|
        +id: string,
        +marketOpen: any,
        +marketClose: ?any,
        +exchange: {|
          +symbol: string,
          +name: string,
        |},
      |},
      +all: ?number,
      +cancelled: ?number,
      +rejected: ?number,
      +created: ?number,
      +partiallyFilled: ?number,
      +pendingExecution: ?number,
      +inMarket: ?number,
      +queued: ?number,
      +posted: ?number,
      +filled: ?number,
    |}>,
    +count: ?number,
  |},
  +$refType: ExchangeSession_viewer$ref,
|};
export type ExchangeSession_viewer$data = ExchangeSession_viewer;
export type ExchangeSession_viewer$key = {
  +$data?: ExchangeSession_viewer$data,
  +$fragmentRefs: ExchangeSession_viewer$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ExchangeSession_viewer",
  "type": "Query",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 10
    },
    {
      "kind": "LocalArgument",
      "name": "offset",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "exchange",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "exchangeSessions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "exchange",
          "variableName": "exchange"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "offset",
          "variableName": "offset"
        }
      ],
      "concreteType": "ExchangeSessionInfoNode",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ExchangeSessionInfo",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "exchangeSession",
              "storageKey": null,
              "args": null,
              "concreteType": "ExchangeSessionNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "marketOpen",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "marketClose",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "exchange",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ExchangeNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "symbol",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "all",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cancelled",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "rejected",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "created",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "partiallyFilled",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "pendingExecution",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "inMarket",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "queued",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "posted",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "filled",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bf79609bbb8f4253c1d98aceafa778dc';
module.exports = node;
