import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Drawer } from '@material-ui/core';
import { useCommonStyles } from '../common/Styles';
import { toGlobalId } from '../../helpers/ID';
import Fee from './Fee';
import FeeComponentForm from './FeeComponentForm';
import FeeForm from './FeeForm';
import { AddButton } from '../common/buttons/BaseButton';
import TopGridContainer from '../common/containers/TopGridContainer';
import MoreButton from '../common/buttons/MoreButton';
import FeeTemplateForm from './FeeTemplateForm';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';

function FeeTemplate(props) {
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);
  const feeTemplate = viewer.feeTemplates.edges[0].node;
  const classes = useCommonStyles();
  const [editedFeeTemplate, setEditedFeeTemplate] = React.useState(null);
  const [editedFee, setEditedFee] = React.useState(null);
  const [editedFeeComp, setEditedFeeComp] = React.useState(null);

  return (
    <div className={classes.pageRoot}>

      <Drawer
        open={editedFeeTemplate}
        anchor="right"
        onClose={e => setEditedFeeTemplate(null)}
      >
        <FeeTemplateForm
          key={`${feeTemplate.id}-${feeTemplate.updatedAt}`}
          feeTemplate={feeTemplate}
          onClose={e => setEditedFeeTemplate(null)}
        />
      </Drawer>

      <TopGridContainer>
        <Grid item>
          <Grid container spacing={1} alignItems="baseline">
            <Grid item>
              <Typography
                variant="h6"
              >
                {feeTemplate.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
              >
                {feeTemplate.description}
              </Typography>
            </Grid>
            <Grid item>
              <MoreButton
                onClick={e => setEditedFeeTemplate({ feeTemplate })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <AddButton
            buttonText="fee"
            onClick={e => setEditedFee({ fee: null, feeTemplate })}
          />
        </Grid>
      </TopGridContainer>

      <Drawer
        open={!!editedFeeComp}
        anchor="right"
        onClose={() => setEditedFeeComp(null)}
      >
        <FeeComponentForm
          key={editedFeeComp && editedFeeComp.id}
          feeComponent={editedFeeComp && editedFeeComp.component}
          fee={editedFeeComp && editedFeeComp.fee}
          onClose={() => setEditedFeeComp(null)}
        />
      </Drawer>

      <Drawer
        open={editedFee}
        anchor="right"
        onClose={e => setEditedFee(null)}
      >
        <FeeForm
          key={(editedFee && editedFee.fee && editedFee.fee.id) || 0}
          fee={editedFee && editedFee.fee}
          feeTemplate={feeTemplate}
          onClose={() => setEditedFee(null)}
          onAdd={() => filter.update()}
        />
      </Drawer>

      <Grid container spacing={2}>

        { feeTemplate.fees.edges.map(x => x.node).sort(x => x.name).map(
          fee => (
            <Fee
              fee={fee}
              onEditFee={() => setEditedFee({
                fee,
                feeTemplate,
              })}
              onEditFeeComponent={(fc) => {
                setEditedFeeComp({ fee, component: fc });
              }}
              onDeleteFee={() => filter.update()}
            />
          ),
        )}

      </Grid>
    </div>
  );
}

const query = graphql`
  query FeeTemplateQuery (
    $feeTemplateId: ID,
  ) {
    viewer {
      ...FeeTemplate_viewer
      @arguments (
        feeTemplateId: $feeTemplateId
      )
    }
  }
`;

const container = createRefetchContainer(
  FeeTemplate,
  {
    viewer: graphql`
      fragment FeeTemplate_viewer on Query
      @argumentDefinitions(
        feeTemplateId: {type: ID}
      ){
        feeTemplates(
          id: $feeTemplateId
        ) {
          edges {
            node {
              id
              name
              description
              createdAt
              updatedAt
              type
              notes {
                id
              }
              fees (
                orderBy: "-createdAt"
              ){
                edges {
                  node {
                    ...Fee_fee @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      }

    `,
  },
  query,
);

export default withRouter(
  createQueryRendererProgressV2(
    query,
    container,
    {},
    outerProps => ({
      feeTemplateId: toGlobalId('FeeTemplateNode', outerProps.match.params.feeTemplateId),
    }),
  ),
);
