import { graphql } from 'react-relay';

// language=GraphQL
const approveCashTransferRequest = graphql`
  mutation ApproveCashTransferRequestMutation (
    $transferRequestId: ID
  ) {
    approveCashTransfer(
      cashTransferRequestId: $transferRequestId
    ) {
      cashTransferRequest {
        id
        status
        createdAt
        updatedAt
        cashtransferrequestapprovalSet {
          edges {
            node {
              id
              approvedAt
              approvedBy {
                id
                fullName
              }
            }
          }
        }
        cashTransfer {
          createdAt
          updatedAt
          id
          brokerActivity {
            brokeractivitytransactionSet {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default approveCashTransferRequest;
